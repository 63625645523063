import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CadastroContribuinteComponent } from "./cadastro-contribuinte/cadastro-contribuinte.component";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { EntradaContribuinteComponent } from "./entrada-contribuinte/entrada-contribuinte.component";
import { MatCardModule, MatRadioModule, MatToolbar, MatToolbarModule } from "@angular/material";
import { NgxCpfCnpjModule } from "ngx-cpf-cnpj";
import { MaterialModule } from "../../material";
import {NgxMaskModule} from "ngx-mask";
import { DialogContribuinteSigetComponent } from "./dialog-procurador-siget/dialog-contribuinte-siget.component";
import { PopoverModule } from "ngx-smart-popover";
import { DialogSelecaoFilialComponent } from "./dialog-selecao-filial/dialog-selecao-filial.component";
import { CadastroContribuinteRepresentanteComponent } from "./cadastro-contribuinte-representante/cadastro-contribuinte-representante.component";


const routes: Routes = [
  { path: "contribuinte", component: CadastroContribuinteComponent },
  { path: "entrada-contribuinte", component: EntradaContribuinteComponent },
  { path: "cadastro-contribuinte-representante", component: CadastroContribuinteRepresentanteComponent }
];

@NgModule({
  declarations: [
    CadastroContribuinteComponent,
    CadastroContribuinteRepresentanteComponent,
    EntradaContribuinteComponent,
    DialogContribuinteSigetComponent,
    DialogSelecaoFilialComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MatCardModule,
    FormsModule,
    MatRadioModule,
    NgxCpfCnpjModule,
    MatToolbarModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    PopoverModule,
  ],
  entryComponents: [DialogContribuinteSigetComponent, DialogSelecaoFilialComponent]
})
export class ContribuinteModule { }
