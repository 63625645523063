// tslint:disable-next-line: max-line-length
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef, OnChanges, SimpleChanges, SimpleChange } from "@angular/core";
import { MatDialog, MatPaginator, MatStepper, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { LacunaWebPKI } from "web-pki";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { Certificate } from "../../../global/models/certificate.model";
import { ArquivoService } from "../../api/arquivo.service";
import { AssuntoService } from "../../api/assunto.service";
import { DocumentoService } from "../../api/documento.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { Documento } from "../../models/documento.model";
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from "ngx-loading";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { Utils } from "src/app/global/util/utils-component";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { Assinatura } from "../../models/assinatura.model";
import { DialogIndicarAssinadoresComponent } from "../dialog-indicar-assinadores/dialog-indicar-assinadores.component";
import { Assinador } from "../../models/assinador.model";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";

const PrimaryWhite = "#ffffff";
const SecondaryGrey = "#ccc";

@Component({
  selector: "app-anexo-processo",
  templateUrl: "./anexo-processo.component.html",
  styleUrls: ["./anexo-processo.component.css"]
})
export class AnexoProcessoComponent implements OnInit, OnChanges {

  public assunto: any;
  public dataSource: MatTableDataSource<Documento>;
  public responsavel: any;
  public documentos: Documento[] = [];
  public documentosEnvio: DocumentoDTO[] = [];
  displayedColumns: string[] = ["id", "nomeDocumento", "descricao", "arquivo", "acoes"];
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate: Certificate;
  public tiposDocumento: TipoDocumento[] = [];
  public tipoDocumento: TipoDocumento;
  public listaAssinador: Assinador[] = [];

  @ViewChild("ngxLoading") ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild("customLoadingTemplate") customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: "3px" };

  @Output() documentoEnvioEvent = new EventEmitter<DocumentoDTO[]>();
  @Output() removerDocumentoEnvioEvent = new EventEmitter<number>();
  @Input() docDAEgerado: Documento;
  @Input() xlsNaoSimilaridade: Documento;
  @Input() stepper: MatStepper;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public infoAssuntoAutor = new OpcaoAssuntoInterno();
  public colunaAssinadores = false;

  constructor(protected route: ActivatedRoute,
    public router: Router,
    public assuntoService: AssuntoService,
    public tipoDocumentoService: TipoDocumentoService,
    public arquivoService: ArquivoService,
    public documentoService: DocumentoService,
    private chRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public messageService: MessageService,
    public sharedService: SharedService) {
    this.certificate = JSON.parse(this.sharedService.getKeyStoreCertificate());
  }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.getAssunto(id);

    this.infoAssuntoAutor.tipo = this.route.snapshot.params.tipoAutor;
    this.infoAssuntoAutor.infoAutor = this.route.snapshot.params.infoAutor;
    this.infoAssuntoAutor.infoAssinador = this.route.snapshot.params.infoAssinador;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.docDAEgerado) {
      const change: SimpleChange = changes.docDAEgerado;
      const documento = change.currentValue;
      if (documento != null) {
        documento.geradoPeloForm = true;
        this.documentos.unshift(documento);
      } else if (documento === null) { // Apaga DAE quando campo "nosso numero" é limpo no compoente salvar-processo
        this.documentos = this.documentos.filter(documentos => documentos.descricao !== "DAE");
      }
    }

    if(changes.xlsNaoSimilaridade) {
      const changeXls: SimpleChange = changes.xlsNaoSimilaridade;
      const documentoXls = changeXls.currentValue;
      if (documentoXls != null) {
        this.documentos = this.documentos.filter(doc => doc.descricao !== "Itens de não similaridade");
        documentoXls.geradoPeloForm = true;
        this.documentos.unshift(documentoXls);
      }
    }

    this.dataSource = new MatTableDataSource<Documento>(this.documentos);
    this.dataSource.paginator = this.paginator;
  }

  public getAssunto(id: number): void {
    this.assuntoService.get(id).subscribe( assunto => {
      this.assunto = assunto;
      this.criaListaDocumentos(this.assunto);
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  criaListaDocumentos(assunto: any){
        this.tipoDocumentoService.list().subscribe(listaDocumentos => {
          listaDocumentos
            .filter(documento => documento.ativo)
              .map(documentoAtivo => {
                assunto.listTipoDocumento.forEach(documentoProcesso => {
                  if (documentoProcesso.tipoDocumento == documentoAtivo.id) {
                    if (documentoProcesso.obrigatorio) {
                      const documento: Documento = new Documento();
                      documento.tipoDocumento = documentoAtivo;
                      this.documentos.push(documento);
                    }else{
                      this.tiposDocumento.push(documentoAtivo);
                    }
                  }
                })
          })

          this.documentos.push(this.criaDocumentoGenerico());
          this.dataSource = new MatTableDataSource<Documento>(this.documentos);
          this.dataSource.paginator = this.paginator;
        }, error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        })
  }

  criaDocumentoGenerico(): Documento{
    const documentoGenerico: Documento = new Documento();
    const tipoDocumentoGenerico: TipoDocumento = new TipoDocumento();
    tipoDocumentoGenerico.id = 0;
    tipoDocumentoGenerico.nomeDocumento = "Selecione um Tipo de Documento";
    documentoGenerico.tipoDocumento = tipoDocumentoGenerico;
    documentoGenerico.generico = true;
    return documentoGenerico;
  }

  validarExtensao(arquivo) {
    let extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    let extensoes = !this.assunto.extensao ? [ ".pdf" ] : this.assunto.extensao.split(",");

    return extensoes.filter(e => e.trim().indexOf(extensao) > -1).length > 0;
  }

  uploadFile(event, descricao, tipoDocumento, documento: Documento) {
    if (documento.generico) {
      tipoDocumento = this.tipoDocumento;
      if (this.tipoDocumento == null || this.tipoDocumento === undefined) {
        this.messageService.messageErro(`Selecione um tipo de documento.`);
        return;
      }
    }

    if (event.target.files.length === 0) {
      this.messageService.messageErro(`O arquivo não foi selecionado corretamente, repita a operação.`);
      return;
    }

    const fileListArquivoEnviando: FileList = event.target.files;

    let cert = null;
    if (environment.VISAO === this.sharedService.VISAO_BENEFICIARIO) {
      cert = this.certificate;
      event.preventDefault();

      if (this.validarExtensao(fileListArquivoEnviando[0].name)) {
        const fileName = fileListArquivoEnviando[0].name;

        if (fileName.substring(fileName.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1) {
          this.assinarDocumento(cert, fileListArquivoEnviando, descricao, tipoDocumento, documento);
        } else {
          this.salvarDocumentoNaoPDF(fileListArquivoEnviando[0], descricao, tipoDocumento, documento);
        }
      } else {
        const extensoes = !this.assunto.extensao ? ".pdf" : this.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }
    } else if (environment.VISAO === this.sharedService.VISAO_ANALISTA) {
      if (this.validarExtensao(fileListArquivoEnviando[0].name)) {
        const fileName = fileListArquivoEnviando[0].name;

        if (fileName.substring(fileName.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1) {
           // Pessoa logada igual assinador
          if (JSON.parse(this.sharedService.getDocumentoAstra()) === this.infoAssuntoAutor.infoAssinador) {
            this.openDialogSelecionarCertificado(fileListArquivoEnviando, descricao, tipoDocumento, documento);
          } else {
            this.incluirDocumentoAnexo(event, documento, tipoDocumento);
          }
        } else {
          this.salvarDocumentoNaoPDF(fileListArquivoEnviando[0], descricao, tipoDocumento, documento);
        }
      } else {
        const extensoes = !this.assunto.extensao ? ".pdf" : this.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }
    }
  }

  incluirDocumentoAnexo(event, documento: Documento, tipodocumento) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const formData: FormData = new FormData();
      const file: File = fileList[0];
      formData.append("file", file);

      formData.append("fileName", file.name);
      formData.append("descricao", documento.descricao);
      formData.append("descricaoAssunto", this.assunto.descricao);
      formData.append("tipoDocumentoId", tipodocumento.id.toString());
      formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
      formData.append("docAssinantes", this.listaAssinador.map(function (assinador) {
        return assinador.documento.toString();
      }).join(","));

      this.documentoService.post(formData).subscribe((documentoSalvo: Documento) => {
        this.receberArquivoSalvo(documento, documentoSalvo, file, documento.descricao);
      }, (error) => {
        this.messageService.messageErro(error.error.message);
        documento.loading = false;
        throw error;
      });
    }
  }

  openDialogSelecionarCertificado(fileListArquivoEnviando, descricao, tipoDocumento, documento: Documento): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate
    });

    openDialogSelecionarCertificado.afterClosed().subscribe(certParam => {
      if (certParam != null && certParam.cert64 != null) {
        try {
          this.assinarDocumento(certParam, fileListArquivoEnviando, descricao, tipoDocumento, documento);
        } catch {
          documento.loading = false;
          this.messageService.messageErro(`Erro ao assinar documento`);
        }
      }
    }, error => {
      documento.loading = false;
      this.messageService.messageErro(`Erro ao assinar documento`);
    });
  }

  // Depreciado
  openDialogIndicarAssinadores(documento: Documento): void {
    documento.listaAssinador = [];
    if (documento.listaAssinador.length > 0) {
      documento.listaAssinador.forEach(assinador => {
        const novaAssinatura = new Assinatura();
        novaAssinatura.assinador = assinador;
        documento.listAssinatura.push(novaAssinatura);
      }, this);
    }

    const dialogIndicarAssinadores = this.dialog.open(DialogIndicarAssinadoresComponent, {
      width: "600px",
      data: {
        documento: documento
      }
    });

    dialogIndicarAssinadores.afterClosed().subscribe((assinadores: Assinador[]) => {
      if (assinadores) { // Caso cancele no dialog
        const lista: any[] = [];
        for (const entry of assinadores) {
          lista.push(entry);
        }
        if (documento.listAssinatura) {
          for (const assinador of documento.listAssinatura) {
            lista.push(assinador);
          }
        }
        documento.listaAssinador = lista;
      }
    });
  }

  salvarDocumentoNaoPDF(arquivo, descricao, tipoDocumento, documento): void {
    documento.loading = true;

    const formData: FormData = new FormData();
    formData.append("file", arquivo);
    formData.append("tipoDocumentoId", tipoDocumento.id);
    formData.append("descricao", descricao);
    if (environment.VISAO === this.sharedService.VISAO_ANALISTA) {
      if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO) {
        formData.append("docAutor", JSON.parse(this.sharedService.getDocumentoAstra()));
      } else {
        formData.append("docAutor", this.infoAssuntoAutor.infoAutor);
      }
    } else {
      formData.append("docAutor", JSON.parse(this.sharedService.getDocumentoRepresentado()));
    }

    this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
      (documentoSalvo: Documento) => {
        this.receberArquivoSalvo(documento, documentoSalvo, arquivo, descricao);
      }, (error) => {
        this.messageService.messageErro(error.error.message);
        documento.loading = false;
        throw error;
      }
    );
  }

  receberArquivoSalvo(documento: Documento, documentoSalvo: Documento, arquivo: any, descricao: string) {
    // tslint:disable-next-line:triple-equals
    if (documento.generico == true) {
      const novoDocumento: Documento = new Documento();
      novoDocumento.generico = true;
      novoDocumento.tipoDocumento = this.tipoDocumento;
      novoDocumento.descricao = descricao;
      novoDocumento.id = documentoSalvo.id;
      novoDocumento.file = arquivo;
      novoDocumento.assinado = false;

      this.clean(documento);
      documento.idUploadFile =  Math.random();

      this.documentos = this.documentos.slice(0, this.documentos.length - 1);
      this.documentos.push(novoDocumento);
      this.documentos.push(documento);
      this.dataSource = new MatTableDataSource<Documento>(this.documentos);
      this.dataSource.paginator = this.paginator;

      this.tipoDocumento = null;

    } else {
      documento.assinado = false;
      documento.id = documentoSalvo.id;
      documento.file = arquivo;
    }
    //this.documentoArquivoEnviando
    documento.loading = false;

    this.chRef.detectChanges();
    this.messageService.messageSuccess("Arquivo salvo com sucesso!");
  }

  assinarDocumento(cert: Certificate, fileListArquivoEnviando, descricao, tipoDocumento, documento: Documento): void {
    let autor = this.infoAssuntoAutor.infoAutor;
    documento.loading = true;
    const formData: FormData = new FormData();

    if (environment.VISAO === this.sharedService.VISAO_BENEFICIARIO) {
      autor = JSON.parse(this.sharedService.getDocumentoRepresentado());
    } else if (environment.VISAO === this.sharedService.VISAO_ANALISTA) {
      autor = JSON.parse(this.sharedService.getDocumentoAstra());
    }

    if (fileListArquivoEnviando.length > 0) {
      const file: File = fileListArquivoEnviando[0];
      formData.append("file", file);
      formData.append("certContent", cert.cert64);
      formData.append("processoId", "");
      formData.append("docAutor", autor);

      this.documentoService.start(formData, SharedService.CARREGAR_LOADING_ASSINATURA).subscribe(
        sigParams => {
          this.pki.signHash({thumbprint: cert.thumbprint, hash: sigParams.toSignHash, digestAlgorithm: sigParams.digestAlgorithm})
          .success(
            signature => {
            try {
              // throw Error("erro");
              this.documentoService
              .complete(sigParams.fileName, sigParams.transferFileId, signature, descricao, this.assunto.descricao,
                tipoDocumento.id, autor, file.name, sigParams.descricaoAssinatura, sigParams.hashDocumento, null,
                null, SharedService.CARREGAR_LOADING_ASSINATURA)
                .subscribe(
                  documentoDTO => {
                    // tslint:disable-next-line:triple-equals
                    if (documento.generico == true) {
                      documento.assinado = false;
                      documento.descricao = "";

                      const novoDocumento: Documento = new Documento();
                      novoDocumento.generico = true;
                      novoDocumento.tipoDocumento = this.tipoDocumento;
                      novoDocumento.descricao = descricao;
                      novoDocumento.id = documentoDTO.id;
                      novoDocumento.file = file;
                      novoDocumento.assinado = true;

                      this.clean(documento);
                      documento.idUploadFile =  Math.random();

                      this.documentos = this.documentos.slice(0, this.documentos.length - 1);
                      this.documentos.push(novoDocumento);
                      this.documentos.push(documento);
                      this.dataSource = new MatTableDataSource<Documento>(this.documentos);
                      this.dataSource.paginator = this.paginator;

                      this.tipoDocumento = null;

                    } else {
                      documento.assinado = true;
                      documento.id = documentoDTO.id;
                      documento.file = file;
                    }
                    //this.documentoArquivoEnviando
                    documento.loading = false;
                    this.chRef.detectChanges();
                    this.messageService.messageSuccess("Arquivo salvo com sucesso!");
                  }, error => {
                    documento.loading = false;
                    this.messageService.messageErro("Erro no upload do arquivo.");
                    this.chRef.detectChanges();
                  }
                );
            } catch (error) {
              documento.loading = false;
              this.chRef.detectChanges();
              this.messageService.messageErro("Erro no upload do arquivo.");
            }
        }).fail(erro => {
          documento.loading = false;
          this.messageService.messageErro(erro.message);
          this.chRef.detectChanges();
        });
      }, error => {
        documento.loading = false;
        this.messageService.messageErro(error.error.message);
        this.chRef.detectChanges();
        throw error;
        }
      );
    }
  }

  clean(documento: Documento) {
    this.removerDocumentoEnvioEvent.emit(documento.id);
    if (documento.generico) {
      if (documento.id) {
        this.documentos = this.documentos.filter(function (doc) {
          // tslint:disable-next-line: triple-equals
          return documento != doc;
        });
        this.dataSource = new MatTableDataSource<Documento>(this.documentos);
        this.dataSource.paginator = this.paginator;
      }
    }
    documento.descricao = "";
    documento.emitido = false;
    documento.id = null;
    documento.file = null;
    // this.documentos.push(documento);
  }

  public validarDescricaoDuplicada(): boolean {
    const descricoes: string[] = [];
    this.dataSource.filteredData.forEach(documento => {
      if (documento.descricao !== undefined && documento.descricao !== "") {
        descricoes.push(documento.descricao);
      }
    });
    return new Set(descricoes).size !== descricoes.length;
  }

  public validarAssinatura(documento: Documento) {
    if (documento.descricao === undefined || documento.descricao === "") {
      if (documento.generico && this.tipoDocumento != null && this.tipoDocumento.nomeDocumento != null) {
        // tslint:disable-next-line: max-line-length
        this.messageService.messageWarning("Descrição obrigatória para incluir documento do tipo '" + this.tipoDocumento.nomeDocumento + "'");
        return false;
      }
      // tslint:disable-next-line: max-line-length
      this.messageService.messageWarning("Descrição obrigatória para incluir documento do tipo '" + documento.tipoDocumento.nomeDocumento + "'");
      return false;
    }

    if (this.validarDescricaoDuplicada()) {
      this.messageService.messageErro("Descrição não pode ser duplicada.");
      this.clean(documento);
      return false;
    }

    return true;
  }

  private validar() {
    let validacaoMetadado = true;
    this.dataSource.filteredData.forEach((documento) => {
      // tslint:disable-next-line:triple-equals
      if (documento.generico != true && (documento.id === null || documento.id === undefined || documento.descricao === "")) {
        this.messageService.messageErro("Documento obrigatório " + documento.tipoDocumento.nomeDocumento);
        validacaoMetadado = false;
      }
    });
    return validacaoMetadado;
  }

  goForward(stepper: MatStepper) {
    if (this.validar()) {
      this.preencherDocumentosEnvio();
      stepper.next();
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  public preencherDocumentosEnvio() {
    this.documentosEnvio = [];
    this.dataSource.filteredData.forEach((documento) => {
      // tslint:disable-next-line:triple-equals
      if (documento.tipoDocumento.id != 0 && !documento.emitido) {
        documento.emitido = true;
        const documentoDTO = new DocumentoDTO();
        documentoDTO.id = documento.id;
        documentoDTO.nomeDocumento = documento.tipoDocumento.nomeDocumento;
        this.documentosEnvio.push(documentoDTO);
      }
    });
    this.documentoEnvioEvent.emit(this.documentosEnvio);
  }

  downloadDocumento($event, documento: Documento) {
    $event.preventDefault(); $event.stopPropagation();
    let isPDF;
    if (documento.nomeDocumento != null) {
      isPDF = documento.nomeDocumento.substring(documento.nomeDocumento.lastIndexOf(".")).indexOf("pdf") > -1;
    } else {
      isPDF = documento.file.name.substring(documento.file.name.lastIndexOf(".")).indexOf("pdf") > -1;
    }
    
    if (isPDF) {
      this.documentoService.download(documento.id).subscribe(blob => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    } else {
      this.documentoService.download(documento.id, "octet/stream").subscribe(blob => {
        if (!Utils.downloadBlob(blob, documento.nomeDocumento)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    }
  }
}
