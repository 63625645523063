import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginCertificateComponent } from "./login-certificate/login-certificate.component";
import { LoginMainComponent } from "./login-main/login-main.component";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutenticacaoService } from "../api/seguranca/autenticacao.service";
import { CertificateResolver } from "../resolver/certificate-resolver";
import { MaterialModule } from "../../material";
import { DirectivesModule } from "../../global/directives/directives.module";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { LoginUsuarioSenhaComponent } from "./login-usuario-senha/login-usuario-senha.component";
import { LoginGuard } from "../api/seguranca/login.guard";
import { NgxMaskModule } from "ngx-mask";
import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { SisSegService } from "src/app/core/api/sis-seg.service";

const routes: Routes = [
  { path: "login", component: LoginMainComponent, canActivate: [LoginGuard] },
  { path: "login-us", component: LoginUsuarioSenhaComponent, canActivate: [LoginGuard] },
];

@NgModule({
  declarations: [
    LoginCertificateComponent,
    LoginMainComponent,
    LoginUsuarioSenhaComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    DirectivesModule,
    NgxMaskModule,
    NgxSmartModalModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [AutenticacaoService, SisSegService, CertificateResolver, LoginGuard],
})
export class AutenticacaoModule {}
