import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChange } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriaAssuntoService } from "../../api/categoria-assunto.service";
import { AssuntoService } from "../../api/assunto.service";
import { Assunto } from "../../models/assunto.model";
import { AssuntoCategoria } from "../../models/assunto-categoria.model";
import { FormControl } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { MessageService } from "src/app/global/message/message.service";
import { AssuntoEAssuntoCategoriaDto } from "../../models/assunto-e-assunto-categoria-dto";
import { AssuntoCategoriaDTO } from "../../models/assunto-categoria-dto.model";
import { AssuntoDTO } from "../../models/assunto-dto.model";

@Component({
  selector: "app-servicos",
  templateUrl: "./servicos.component.html",
  styleUrls: ["./servicos.component.css"]
})
export class ServicosComponent implements OnInit, OnChanges {

   public assuntos: AssuntoDTO[] = [];

   public categoriaAssuntos: AssuntoCategoriaDTO[] = [];
   public assuntosCategoriaFilhos: AssuntoCategoriaDTO[] = [];
   public assuntoEAssuntoCategoriaDtoHistorico: AssuntoEAssuntoCategoriaDto[] = [];


   public assuntosFiltrados: AssuntoDTO[];
   public categoriaAssuntosFiltrados: AssuntoCategoriaDTO[];
   public assuntoCategoria: AssuntoCategoriaDTO;
   public resourceCategoriaAssunto = environment.CONTEXTO + "/listar-categoria-assunto";
   public idPai: number;
   public visao = environment.VISAO;
   filtroControl = new FormControl();
   filtro = "";

   @Input() assuntoEAssuntoCategoriaPaiDto: AssuntoEAssuntoCategoriaDto;
   @Input() mostrarServicos: boolean;

   @Output() mostrarServicosPai = new EventEmitter<boolean>();

   public assuntoCategoriaDtoPai: AssuntoCategoriaDTO;
   public assuntoEAssuntoCategoriaAtualDto: AssuntoEAssuntoCategoriaDto;

  constructor(protected route: ActivatedRoute,
    public categoriaAssuntoService: CategoriaAssuntoService,
    public assuntoService: AssuntoService,
    public messageService: MessageService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.assuntoEAssuntoCategoriaPaiDto !== undefined) {
      this.assuntosCategoriaFilhos = [];
      this.categoriaAssuntos = [];
      this.assuntoCategoria = this.assuntoEAssuntoCategoriaPaiDto.assuntoCategoriaDtoPai;
      this.assuntoEAssuntoCategoriaAtualDto = this.assuntoEAssuntoCategoriaPaiDto;
      this.assuntoEAssuntoCategoriaAtualDto.listaAssuntoCategoriaDtoFilhos.forEach(assuntoAssuntoCategoria => {
        this.categoriaAssuntos.push(assuntoAssuntoCategoria.assuntoCategoriaDtoPai);
      });
      this.listarAssunto(this.assuntoCategoria.id);
      this.autoSearchFilter();
    }
  }

  private autoSearchFilter() {
    this.filtroControl.valueChanges.subscribe(
      data => {
        this.filtro = data;
        this.filtrarAssuntoECategoriaAssunto();
      });
  }

  private filtrarAssuntoECategoriaAssunto(): void {
    this.atualizarListaAssuntoCategoriaPeloFiltro(this.categoriaAssuntos);
    this.atualizarListaAssuntoPeloFiltro(this.assuntos);
  }

  public listarCategoriaAssunto(assuntoCategoriaDTO: AssuntoCategoriaDTO): void {
    this.categoriaAssuntos = [];
    this.assuntoEAssuntoCategoriaAtualDto.listaAssuntoCategoriaDtoFilhos.forEach(assuntoAssuntoCategoriaDTO => {
      if (assuntoAssuntoCategoriaDTO.assuntoCategoriaDtoPai.id === assuntoCategoriaDTO.id) {
        this.assuntoEAssuntoCategoriaDtoHistorico.push(this.assuntoEAssuntoCategoriaAtualDto);
        this.assuntoEAssuntoCategoriaAtualDto = assuntoAssuntoCategoriaDTO;
        this.assuntoEAssuntoCategoriaAtualDto.listaAssuntoCategoriaDtoFilhos.forEach(assuntoAssuntoCategoriaFilhoDTO => {
          this.categoriaAssuntos.push(assuntoAssuntoCategoriaFilhoDTO.assuntoCategoriaDtoPai);
        });
        this.atualizarListaAssuntoCategoriaPeloFiltro(this.categoriaAssuntos);
        return;
      }
    });
  }

  public  atualizarListaAssuntoCategoriaPeloFiltro(assuntoCategoriaList: AssuntoCategoriaDTO[]): void {
    this.categoriaAssuntosFiltrados = assuntoCategoriaList.filter(catAssunto =>
      catAssunto.descricao.toUpperCase().indexOf(this.filtro.toUpperCase()) !== -1
    ).sort((a, b) => {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      return 0;
    });
  }

  public listarAssunto(id: number): void {
    this.assuntoService.getAssuntoByAssuntoCategoria(id).subscribe(
      (assuntoDTO: AssuntoDTO[]) => {
        this.assuntos = assuntoDTO;
        this.atualizarListaAssuntoPeloFiltro(assuntoDTO);
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public atualizarListaAssuntoPeloFiltro(assuntoList: AssuntoDTO[]): void {
    this.assuntosFiltrados = assuntoList.filter(assunto =>
      assunto.descricao.toUpperCase().indexOf(this.filtro.toUpperCase()) !== -1
    ).sort((a, b) => {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      return 0;
    });
  }

  voltar(): void {
    if (this.assuntoEAssuntoCategoriaAtualDto === this.assuntoEAssuntoCategoriaPaiDto) {
      this.mostrarServicosPai.emit(true);
    } else {
      this.assuntosCategoriaFilhos.pop();
      this.assuntoEAssuntoCategoriaAtualDto = this.assuntoEAssuntoCategoriaDtoHistorico.pop();
      this.categoriaAssuntos = [];
      this.assuntoEAssuntoCategoriaAtualDto.listaAssuntoCategoriaDtoFilhos.forEach(assuntoAssuntoCategoria => {
        this.categoriaAssuntos.push(assuntoAssuntoCategoria.assuntoCategoriaDtoPai);
      });

      this.listarAssunto(this.assuntoEAssuntoCategoriaAtualDto.assuntoCategoriaDtoPai.id);

      this.filtrarAssuntoECategoriaAssunto();

    }
  }

  // Ao selecionar uma categoriaAssuntoDTO (Filho)
  carregarFilhosCategoriaAssunto(assuntoCategoriaDTO: AssuntoCategoriaDTO) {
    this.assuntosCategoriaFilhos.push(assuntoCategoriaDTO);
    this.listarCategoriaAssunto(assuntoCategoriaDTO);
    this.listarAssunto(assuntoCategoriaDTO.id);

    this.filtrarAssuntoECategoriaAssunto();
  }

  private atualizarListaAssuntoEAssuntoCategoria(): void {
    this.listarCategoriaAssunto(this.assuntoEAssuntoCategoriaAtualDto.assuntoCategoriaDtoPai);
    this.listarAssunto(this.assuntoCategoria.id);

    // this.atualizarDescricaoBreadcrumb();
  }

  private atualizarDescricaoBreadcrumb(): void {
    let descricaoBreadcrumb = this.assuntoCategoria.descricao;
    this.assuntosCategoriaFilhos.forEach(assCat => {
      descricaoBreadcrumb = descricaoBreadcrumb.concat(" > ").concat(assCat.descricao);
    });
    this.router.navigate([`${this.resourceCategoriaAssunto}/${this.assuntoCategoria.id}/${descricaoBreadcrumb.replace("/", "\\")}`], {});
  }
}
