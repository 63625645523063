import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { EmailTemplate } from 'src/app/core/models/email-template.model';
import { ParametroDiverso } from 'src/app/core/models/parametro-diverso.model';
import { TipoValorParametroEnumAll } from 'src/app/core/models/tipo-valor-parametro-enum.model';
import { ParametroDiversoService } from 'src/app/core/api/parametro-diverso.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import ListarGenericsComponent from 'src/app/global/generics/listar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';
import { SharedService } from 'src/app/global/api/shared.service';

@Component({
  selector: 'app-listar-parametro-diverso',
  templateUrl: './listar-parametro-diverso.component.html',
  styleUrls: ['./listar-parametro-diverso.component.css']
})
export class ListarParametroDiversoComponent extends ListarGenericsComponent<ParametroDiverso> {

  public dataSource: MatTableDataSource<ParametroDiverso>;
  public listTipoValorParametro: any[] = TipoValorParametroEnumAll;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public apiService:  ParametroDiversoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "parametro-diverso",
      ["chave", "valor", "descricao", "tipoValorParametro", "removerOpcao"]);
  }

  findEnumTipoValorParametro(tipoValor: any) : string {
    return this.listTipoValorParametro[tipoValor].descricao;
  }

}
