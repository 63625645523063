import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "src/app/global/api/shared.service";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { AssuntoService } from "../../api/assunto.service";
import { DocumentoService } from "../../api/documento.service";
import { Documento } from "../../models/documento.model";
import { DocumentosRegra } from "../../models/pensao/documentos-regra";
import { UploadDocumento } from "../../models/pensao/upload-documento.model";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";
import { Utils } from "src/app/global/util/utils-component";
import { TipoDocumentoSistemaFunctions } from "../../models/tipo-documento-sistema.enum";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { Requerente } from "../../models/pensao/requerente.model";
import { formatDate } from "@angular/common";
import { FalecidoEmEnumAll } from "../../models/pensao/falecido-em.enum";
import { OrgaoService } from "../../api/orgao.service";
import { Assinador } from "../../models/assinador.model";
import { Processo } from "../../models/processo.model";

@Component({
  selector: "app-upload-documento",
  templateUrl: "./upload-documento.component.html",
  styleUrls: ["./upload-documento.component.css"],
})
export class UploadDocumentoComponent extends ListarGenericsComponent<Documento> implements OnInit {
  data = this.transfereService.getData();
  @Input() listTipoDocumentoMotorRegras: DocumentosRegra[];
  @Input() public processo: Processo;
  public listTipoDocumento: UploadDocumento[] = [];
  public listDocumentos: Documento[] = [];
  public listDocumentosNaoObrigatorios: Documento[] = [];
  public tiposDocumento: TipoDocumento[] = [];
  public listDocumentosPorMatricula: any[] = [];
  public formGroupDocumentos: FormGroup;
  public tipoDocumento: TipoDocumento = new TipoDocumento();
  public documentos: Documento[] = [];
  public regrasProcesso: RegrasProcesso;
  public instituidor: Instituidor = new Instituidor();
  public solicitante: Solicitante = new Solicitante();
  public requerente: Requerente = new Requerente();
  format: any = "dd/MM/yyyy";
  formatHour: any = "HH:mm:ss";
  locale: any = "en-US";
  public listFalecidoEm: any[] = FalecidoEmEnumAll;
  public ipCliente: string;
  public documentoSubstituir: Documento;
  public natureza: number;
  public exibicaoEditor = false;
  public documentoNovo: Documento = new Documento();
  public isDocumentoAssinado = false;
  public listaAssinador: Assinador[] = [];
  public documentoSelecionado: Documento;
  public ckeConfig: any;
  public idTipoPensao;
  public tipoDocumentoNaoObrigatorio: TipoDocumento;
  clickItem: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    private transfereService: TransfereService,
    public apiService: DocumentoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService,
    public orgaoService: OrgaoService,
    public assuntoService: AssuntoService,
    public documentoService: DocumentoService,
    private fb: FormBuilder,
    private chRef: ChangeDetectorRef,
    public tipoDocumentoService: TipoDocumentoService
  ) {
    super(apiService, messageService, sharedService, toastr, router, "tipo-documento", [
      "tipoDocumento",
      "descricao",
      "visualizar",
      "arquivo",
      "acoes",
    ]);
    this.inicializarValidacoesForm(this.listTipoDocumentoMotorRegras);
    if (this.data) {
      this.regrasProcesso = this.data;
    }
  }

  ngOnInit() {
    // this.inicializarValidacoesForm(this.listTipoDocumentoMotorRegras);
    this.idTipoPensao = this.route.snapshot.queryParams.idTipoPensao;
    this.dataSource = new MatTableDataSource<Documento>(this.listDocumentos);
    this.dataSource.paginator = this.paginator;
  }

  async update(listTipoDocumentoMotorRegras: DocumentosRegra[]) {
    this.resetDataTable();
    this.listTipoDocumentoMotorRegras = listTipoDocumentoMotorRegras;
    let documentos = await this.criarListaDocumentos(listTipoDocumentoMotorRegras);
    this.dataSource = new MatTableDataSource<Documento>(documentos);
    this.dataSource._updateChangeSubscription;
  }

  inicializarValidacoesForm(tiposDocumento: DocumentosRegra[]) {
    if (tiposDocumento == undefined) {
      this.formGroupDocumentos = this.fb.group({});
      return;
    }

    let group = {};
    this.formGroupDocumentos.reset();

    tiposDocumento.forEach((tipo) => {
      let nomeFormControl = tipo.id.replace(/[^a-zA-Z ]/g, "");

      if (tipo.obrigatorio) {
        group[nomeFormControl] = new FormControl({ value: "" }, Validators.required);
        this.formGroupDocumentos.addControl(nomeFormControl, new FormControl({ value: "" }, Validators.required));
      } else {
        group[nomeFormControl] = new FormControl({ value: "" }, Validators.nullValidator);
        this.formGroupDocumentos.addControl(nomeFormControl, new FormControl({ value: "" }, Validators.nullValidator));
      }
    });
  }

  atribuirTipoDocumentoNaoObrigatorio(tipoDocumento: TipoDocumento) {
    this.tipoDocumentoNaoObrigatorio = tipoDocumento;
  }

  uploadFile(event, documentoUpload: Documento) {
    if (documentoUpload.generico) {
      if (this.tipoDocumentoNaoObrigatorio == null || this.tipoDocumentoNaoObrigatorio == undefined) {
        this.messageService.messageWarning(`Selecione um tipo de documento.`);
        return;
      }
      documentoUpload.assinado = false;
      documentoUpload.descricao = this.tipoDocumentoNaoObrigatorio.nomeDocumento;
      documentoUpload.tipoDocumento = this.tipoDocumentoNaoObrigatorio;
      this.tipoDocumentoNaoObrigatorio = null;
    }

    event.preventDefault();

    if (event.target.files.length === 0) {
      this.messageService.messageErro(`O arquivo não foi selecionado corretamente, repita a operação.`);
      return;
    }

    const fileListArquivoEnviando: FileList = event.target.files;

    if (this.validarExtensao(fileListArquivoEnviando[0].name)) {
      this.salvarDocumentoNaoPDF(fileListArquivoEnviando[0], documentoUpload.descricao, documentoUpload);
    } else {
      this.messageService.messageErro("Extensão de arquivo não permitida.");
      return;
    }
  }

  salvarDocumentoNaoPDF(arquivo, descricao, documento: Documento): void {
    if (documento.generico) {
      documento.file = arquivo;
      documento.nomeDocumento = arquivo.name;
      documento.obrigatorio = false;
      documento.tipoDocumentoId = documento.tipoDocumento.id;
      documento.tipoDocumentoMotorRegras = documento.tipoDocumento.idTipoDocumentoMotorRegras;
    }
    documento.loading = true;
    const formData: FormData = new FormData();
    formData.append("file", arquivo);
    formData.append("tipoDocumentoId", documento.tipoDocumentoId.toString());
    formData.append("descricao", descricao.substring(0, 254));
    formData.append("descricaoAssunto", "Processo de Pensão");
    formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);

    this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
      (documentoSalvo: Documento) => {
        this.receberArquivoSalvo(documento, documentoSalvo, arquivo, descricao);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        documento.loading = false;
        throw error;
      }
    );
  }

  receberArquivoSalvo(documento: Documento, documentoSalvo: Documento, arquivo: any, descricao: string) {
    const novoDocumento: Documento = new Documento();
    documento.idUploadFile = Math.random();

    let indice = this.listDocumentos.indexOf(documento);

    this.listDocumentos[indice].generico = documentoSalvo.generico;
    this.listDocumentos[indice].tipoDocumentoId = documentoSalvo.tipoDocumentoId;
    this.listDocumentos[indice].descricao = descricao;
    this.listDocumentos[indice].id = documentoSalvo.id;
    this.listDocumentos[indice].file = arquivo;
    this.listDocumentos[indice].assinado = false;
    this.listDocumentos[indice].obrigatorio = documento.obrigatorio;
    this.listDocumentos[indice].geradoPeloSistema = documento.geradoPeloSistema;
    this.listDocumentos[indice].nomeDocumento = documentoSalvo.nomeDocumento;
    this.listDocumentos[indice].tipoDocumentoMotorRegras = documento.tipoDocumentoMotorRegras;
    if (!documento.obrigatorio) {
      this.listDocumentos.push(this.criaDocumentoGenerico());
    }
    this.dataSource = new MatTableDataSource<Documento>(this.listDocumentos);
    this.dataSource.paginator = this.paginator;
    this.dataSource._updateChangeSubscription;

    this.tipoDocumento = new TipoDocumento();

    documento.loading = false;

    this.chRef.detectChanges();

    if (!this.listDocumentos[indice].geradoPeloSistema) this.messageService.messageSuccess("Arquivo salvo com sucesso!");
  }

  /**
   * Utilizado por um componente externo para recarregar os arquivos que foram feito upload
   */
  carregarArquivosSalvos(documento: Documento, documentoSalvo: Documento, arquivo: any, descricao: string) {
    documento.idUploadFile = Math.random();
    let indice = this.listDocumentos.indexOf(documento);

    this.listDocumentos[indice].generico = true;
    this.listDocumentos[indice].tipoDocumentoId = documentoSalvo.tipoDocumentoId;
    this.listDocumentos[indice].descricao = descricao;
    this.listDocumentos[indice].id = documentoSalvo.id;
    this.listDocumentos[indice].file = arquivo;
    this.listDocumentos[indice].assinado = false;
    this.listDocumentos[indice].obrigatorio = documento.obrigatorio;
    this.listDocumentos[indice].geradoPeloSistema = documento.geradoPeloSistema;
    this.listDocumentos[indice].nomeDocumento = this.tipoDocumento.nomeDocumento;
    this.listDocumentos[indice].tipoDocumentoMotorRegras = documento.tipoDocumentoMotorRegras;

    this.dataSource = new MatTableDataSource<Documento>(this.listDocumentos);
    this.dataSource.paginator = this.paginator;
    this.dataSource._updateChangeSubscription;
    this.tipoDocumento = new TipoDocumento();
    documento.loading = false;

    this.chRef.detectChanges();
  }

  clean(documento: Documento) {
    this.listDocumentosPorMatricula = this.listDocumentosPorMatricula.filter((doc) => doc.id !== documento.id);

    if (documento.generico) {
      if (documento.id) {
        this.documentos = this.documentos.filter(function (doc) {
          return documento != doc;
        });
        this.dataSource = new MatTableDataSource<Documento>(this.documentos);
        this.dataSource.paginator = this.paginator;
      }
    }
    let indice = this.listDocumentos.indexOf(documento);

    this.listDocumentos[indice].generico = null;
    //this.listDocumentos[indice].tipoDocumento = null;
    this.listDocumentos[indice].id = null;
    this.listDocumentos[indice].file = null;
    this.listDocumentos[indice].assinado = null;
    this.listDocumentos[indice].emitido = false;
    this.listDocumentos[indice].idUploadFile = null;

    delete documento.id;

    this.dataSource = new MatTableDataSource<Documento>(this.listDocumentos);
    this.dataSource.paginator = this.paginator;
  }

  validarExtensao(arquivo) {
    let extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    // let extensoes = !this.assunto.extensao ? [ ".pdf" ] : this.assunto.extensao.split(",");

    // return extensoes.filter(e => e.trim().indexOf(extensao) > -1).length > 0;
    return true;
  }

  async criarListaDocumentos(tiposDocumento: DocumentosRegra[]) {
    this.resetDataTable();
    this.listDocumentosNaoObrigatorios = [];
    for (const tipoDocMotor of tiposDocumento) {
      let doc = new Documento();
      doc.descricao = tipoDocMotor.descricao;
      doc.tipoDocumentoMotorRegras = tipoDocMotor.id;
      doc.obrigatorio = tipoDocMotor.obrigatorio;
      doc.geradoPeloSistema = tipoDocMotor.geradoPeloSistema;
      doc.tipoDocumentoId = 1;
      doc.generico = false;
      doc.tipoDocumento = new TipoDocumento();
      const tipoDocumento = await this.setTipoDocumento(tipoDocMotor.id);

      if (tipoDocumento === null) {
        this.resetDataTable();
        this.messageService.messageErro("Tipo de documento do motor de regras não cadastrado: " + tipoDocMotor.id);
        throw new Error("Tipo de documento do motor de regras não cadastrado: " + tipoDocumento.id);
      } else {
        doc.tipoDocumentoId = tipoDocumento.id;
        if (!doc.obrigatorio) {
          doc.generico = true;
          this.listDocumentosNaoObrigatorios.push(doc);
        } else if (doc.obrigatorio) {
          this.listDocumentos.push(doc);
        }
      }
    }
    //criar lista de Tipos de Documentos não obrigatorios
    this.tipoDocumentoService.list().subscribe((listaDocumentos) => {
      this.tiposDocumento = [];
      listaDocumentos
        .filter((documento) => documento.ativo)
        .map((documentoAtivo) => {
          this.listDocumentosNaoObrigatorios.forEach((documentosNaoObrigatorio) => {
            if (documentosNaoObrigatorio.tipoDocumentoId == documentoAtivo.id) {
              this.tiposDocumento.push(documentoAtivo);
            }
          });
        });
    });
    this.listDocumentos.push(this.criaDocumentoGenerico());
    return this.listDocumentos;
  }
  criaDocumentoGenerico(): Documento {
    const documentoGenerico: Documento = new Documento();
    const tipoDocumentoGenerico: TipoDocumento = new TipoDocumento();
    tipoDocumentoGenerico.id = 0;
    //tipoDocumentoGenerico.nomeDocumento = "Selecione um Tipo de Documento";
    //documentoGenerico.descricao = "Selecione um Tipo de Documento";
    documentoGenerico.tipoDocumento = tipoDocumentoGenerico;
    documentoGenerico.generico = true;
    documentoGenerico.geradoPeloSistema = false;
    return documentoGenerico;
  }

  async setTipoDocumento(idMotor: string) {
    const response = await this.tipoDocumentoService
      .getTipoDocumentoMotorRegra(idMotor)
      .toPromise()
      .catch((error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos do motor de regra. " + error.error.message);
        throw error;
      });
    return response;
  }

  resetDataTable() {
    this.listDocumentos = [];
    this.listDocumentosPorMatricula = [];
    this.dataSource = new MatTableDataSource<Documento>([]);
    this.dataSource._updateChangeSubscription;
  }

  validarDocumentos() {
    if (this.listDocumentos === undefined || this.listDocumentos.length === 0) return false;

    let valido = true;

    for (let doc of this.listDocumentos) {
      if (doc.obrigatorio === true && (doc.id === undefined || doc.id === null || doc.id === 0)) {
        valido = false;
        break;
      }
    }
    return valido;
  }

  downloadDocumento(documento: Documento, clickText: string) {
    this.documentoService.download(documento.id).subscribe((blob) => {
      if (!Utils.downloadBlob(blob)) {
        this.messageService.messageWarning("Documento ainda não existe.");
      } else {
        if (clickText === "download") {
          this.clean(documento);
          this.clickItem = true;
        }
      }
    });
  }

  preencherDocumentosComDados(documentoAGerar: any) {
    this.tipoDocumento.modeloDocumento = documentoAGerar;
    this.preencherDadosInstituidor();
    this.preencherDadosSolicitante();
    this.preencherDadosRequerente();
    this.selecionarTextoConformeTipoRequerente();

    let cpfCodigo = this.requerente.cpf.slice(0, 4);
    let nomeCodigo = this.requerente.nome[0];
    let horaCodigo = formatDate(new Date(), this.formatHour, this.locale).replace(/\:/g, "");
    let dataCodigo = formatDate(new Date(), this.format, this.locale).replace(/\//g, "").split("").reverse().join("");
    function navegadorGet() {
      var agente = navigator.userAgent;
      var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
      for (var i in navegadores) {
        if (agente.indexOf(navegadores[i]) != -1) {
          return navegadores[i].toLowerCase();
        }
      }
    }
    let navegadorCodigo = navegadorGet();
    let teste = this.tipoDocumento.nomeDocumento;
    let sigla = teste.split(" ");
    let a = 0;
    let siglaCodigo = "";
    while (sigla[a] != sigla[sigla.length]) {
      if (sigla[a].charAt(0) == sigla[a].charAt(0).toUpperCase()) {
        sigla[a] = sigla[a].charAt(0);
      } else {
        sigla[a] = "";
      }
      siglaCodigo = siglaCodigo + sigla[a];
      a++;
    }
    let resultadoCodigo = cpfCodigo + nomeCodigo + "-" + horaCodigo + "-" + dataCodigo + "-" + siglaCodigo + "-" + navegadorCodigo;

    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[logoDocumento]/g, "/images/logo-cearaprev-p&b.png")
      .replace(/\[codigo]/g, resultadoCodigo) //0001112122554564564XXXXXX
      .replace(/\[cidadeDocumento]/g, "FORTALEZA")
      .replace(/\[ufDocumento]/g, "CE")
      .replace(/\[dataDocumento]/g, formatDate(new Date(), this.format, this.locale))
      .replace(/\[horaDocumento]/g, formatDate(new Date(), this.formatHour, this.locale));
  }

  selecionarTextoConformeTipoRequerente() {
    let inicioTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.indexOf("[textoConjugeOuCompanheiro]");
    let fimTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.lastIndexOf("[textoConjugeOuCompanheiro]");
    let blocoTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.substring(
      inicioTextoConjugeOuCompanheiro,
      fimTextoConjugeOuCompanheiro
    );

    let inicioTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.indexOf("[textoOutrosDependentes]");
    let fimTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.lastIndexOf("[textoOutrosDependentes]");
    let blocoTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.substring(inicioTextoOutrosDependentes, fimTextoOutrosDependentes);

    this.requerente.solicitanteTipo === "conjuge" || this.requerente.solicitanteTipo === "companheiro"
      ? (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(blocoTextoOutrosDependentes, "")
          .replace(/\[textoConjugeOuCompanheiro]/g, "")
          .replace(/\[textoOutrosDependentes]/g, ""))
      : (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(blocoTextoConjugeOuCompanheiro, "")
          .replace(/\[textoConjugeOuCompanheiro]/g, "")
          .replace(/\[textoOutrosDependentes]/g, ""));
  }

  preencherDadosInstituidor() {
    this.instituidor = this.regrasProcesso.instituidor;
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[instituidor.nome]/g, this.instituidor.nome != undefined ? this.instituidor.nome : "")
      .replace(/\[instituidor.matricula]/g, this.instituidor.matricula != undefined ? this.instituidor.matricula : "")
      .replace(/\[instituidor.cpf]/g, this.instituidor.cpf != undefined ? this.instituidor.cpf : "")
      .replace(/\[instituidor.sexo]/g, this.instituidor.sexo != undefined ? this.instituidor.sexo : "")
      .replace(/\[instituidor.rg]/g, this.instituidor.rg != undefined ? this.instituidor.rg : "")
      .replace(/\[instituidor.orgaoEmissor]/g, this.instituidor.rgOrgaoEmissor != undefined ? this.instituidor.rgOrgaoEmissor : "")
      .replace(/\[instituidor.uf]/g, this.instituidor.rgUf != undefined ? this.instituidor.rgUf : "")
      .replace(/\[instituidor.estadoCivil]/g, this.instituidor.estadoCivil != undefined ? this.instituidor.estadoCivil : "")
      .replace(/\[instituidor.orgaoOrigem]/g, this.instituidor.orgaoOrigem != undefined ? this.instituidor.orgaoOrigem : "")
      .replace(/\[instituidor.cargo]/g, this.instituidor.cargo != undefined ? this.instituidor.cargo : "")
      .replace(/\[instituidor.grupo]/g, this.instituidor.grupo != undefined ? this.instituidor.grupo : "")
      .replace(
        /\[instituidor.obitoRelacionadoServico]/g,
        this.instituidor.obitoRelacionadoServico != undefined ? (this.instituidor.obitoRelacionadoServico == true ? "Sim" : "Não") : ""
      )
      .replace(
        /\[instituidor.situacaoFuncional]/g,
        this.instituidor.falecidoEm != undefined ? this.listFalecidoEm[this.instituidor.falecidoEm].descricao : ""
      )
      .replace(
        /\[instituidor.dataNascimento]/g,
        this.instituidor.dataNascimento != undefined ? formatDate(this.instituidor.dataNascimento, this.format, this.locale) : ""
      )
      .replace(
        /\[instituidor.dataObito]/g,
        this.instituidor.dataObito != undefined ? formatDate(this.instituidor.dataObito, this.format, this.locale) : ""
      )
      .replace(
        /\[instituidor.mesesContribuicao]/g,
        this.instituidor.mesesContribuicao != undefined ? this.instituidor.mesesContribuicao.toString() : ""
      )
      .replace(
        /\[instituidor.quantidadeDependentes]/g,
        this.instituidor.quantidadeDependentes != undefined ? this.instituidor.quantidadeDependentes.toString() : ""
      )
      .replace(
        /\[instituidor.ultimoProvento]/g,
        this.instituidor.ultimoProvento != undefined ? this.instituidor.ultimoProvento.toString() : ""
      );
  }

  preencherDadosSolicitante() {
    this.solicitante = this.regrasProcesso.solicitante;
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[solicitante.nome]/g, this.solicitante.nome != undefined ? this.solicitante.nome : "")
      .replace(/\[solicitante.cpf]/g, this.solicitante.cpf != undefined ? this.solicitante.cpf : "");
  }

  preencherDadosRequerente() {
    this.requerente = this.regrasProcesso.requerentes[0];
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[requerente.nome]/g, this.requerente.nome != undefined ? this.requerente.nome : "")
      .replace(/\[requerente.sexo]/g, this.requerente.sexo != undefined ? this.requerente.sexo : "")
      .replace(
        /\[requerente.dataNascimento]/g,
        this.requerente.dataNascimento != undefined ? formatDate(this.requerente.dataNascimento, this.format, this.locale) : ""
      )
      .replace(/\[requerente.cpf]/g, this.requerente.cpf != undefined ? this.requerente.cpf : "")
      .replace(/\[requerente.rg]/g, this.requerente.rg != undefined ? this.requerente.rg : "")
      .replace(/\[requerente.orgaoEmissor]/g, this.requerente.rgOrgaoEmissor != undefined ? this.requerente.rgOrgaoEmissor : "")
      .replace(/\[requerente.uf]/g, this.requerente.rgUf != undefined ? this.requerente.rgUf : "")
      .replace(/\[requerente.pis]/g, this.requerente.pisPasepNit != undefined ? this.requerente.pisPasepNit : "")
      .replace(/\[requerente.estadoCivil]/g, this.requerente.estadoCivil != undefined ? this.requerente.estadoCivil : "")
      .replace(
        /\[requerente.dataCasamento]/g,
        this.requerente.dataCasamento != undefined ? formatDate(this.requerente.dataCasamento, this.format, this.locale) : ""
      )
      .replace(/\[requerente.endereco]/g, this.requerente.endereco.logradouro != undefined ? this.requerente.endereco.logradouro : "")
      .replace(/\[requerente.nEndereco]/g, this.requerente.endereco.numero != undefined ? this.requerente.endereco.numero : "")
      .replace(
        /\[requerente.complementoEndereco]/g,
        this.requerente.endereco.complemento != undefined ? this.requerente.endereco.complemento : ""
      )
      .replace(/\[requerente.bairro]/g, this.requerente.endereco.bairro != undefined ? this.requerente.endereco.bairro : "")
      .replace(/\[requerente.cep]/g, this.requerente.endereco.cep != undefined ? this.requerente.endereco.cep : "")
      .replace(/\[requerente.cidade]/g, this.requerente.endereco.cidade != undefined ? this.requerente.endereco.cidade : "")
      .replace(/\[requerente.ufEndereco]/g, this.requerente.endereco.uf != undefined ? this.requerente.endereco.uf : "")
      .replace(/\[requerente.telefone1]/g, this.requerente.telefone != undefined ? this.requerente.telefone : "")
      .replace(/\[requerente.telefone2]/g, this.requerente.telefone != undefined ? this.requerente.telefone : "")
      .replace(/\[requerente.tipoSolicitante]/g, this.requerente.tipoSolicitante != undefined ? this.requerente.tipoSolicitante : "")
      .replace(/\[requerente.solicitanteTipo]/g, this.requerente.solicitanteTipo != undefined ? this.requerente.solicitanteTipo : "")
      .replace(/\[requerente.email]/g, this.requerente.email != undefined ? this.requerente.email : "")
      .replace(/\[requerente.agenciaBradesco]/g, this.requerente.agencia != undefined ? this.requerente.agencia.toString() : "")
      .replace(/\[requerente.contaCBradesco]/g, this.requerente.conta != undefined ? this.requerente.conta.toString() : "")
      .replace(
        /\[requerente.nomeRepresentante]/g,
        this.requerente.representanteLegal.nome != undefined ? this.requerente.representanteLegal.nome : ""
      )
      .replace(
        /\[requerente.sexoRepresentante]/g,
        this.requerente.representanteLegal.sexo != undefined ? this.requerente.representanteLegal.sexo : ""
      )
      .replace(
        /\[requerente.tipoRepresentante]/g,
        this.requerente.representanteLegal.tipoRepresentante != undefined
          ? this.requerente.representanteLegal.tipoRepresentante.toString()
          : ""
      )
      .replace(
        /\[requerente.cpfRepresentante]/g,
        this.requerente.representanteLegal.cpf != undefined ? this.requerente.representanteLegal.cpf : ""
      )
      .replace(
        /\[requerente.rgRepresentante]/g,
        this.requerente.representanteLegal.rg != undefined ? this.requerente.representanteLegal.rg : ""
      )
      .replace(
        /\[requerente.orgaoRepresentante]/g,
        this.requerente.representanteLegal.orgaoEmissor != undefined ? this.requerente.representanteLegal.orgaoEmissor : ""
      )
      .replace(
        /\[requerente.ufRepresentante]/g,
        this.requerente.representanteLegal.uf != undefined ? this.requerente.representanteLegal.uf : ""
      )
      .replace(
        /\[requerente.estadoCivilRepresentante]/g,
        this.requerente.representanteLegal.estadoCivil != undefined ? this.requerente.representanteLegal.estadoCivil : ""
      )
      .replace(
        /\[requerente.enderecoRepresentante]/g,
        this.requerente.representanteLegal.endereco.logradouro != undefined ? this.requerente.representanteLegal.endereco.logradouro : ""
      )
      .replace(
        /\[requerente.nEnderecoRepresentante]/g,
        this.requerente.representanteLegal.endereco.numero != undefined ? this.requerente.representanteLegal.endereco.numero : ""
      )
      .replace(
        /\[requerente.complementoEnderecoRepresentante]/g,
        this.requerente.representanteLegal.endereco.complemento != undefined ? this.requerente.representanteLegal.endereco.complemento : ""
      )
      .replace(
        /\[requerente.bairroRepresentante]/g,
        this.requerente.representanteLegal.endereco.bairro != undefined ? this.requerente.representanteLegal.endereco.bairro : ""
      )
      .replace(
        /\[requerente.cepRepresentante]/g,
        this.requerente.representanteLegal.endereco.cep != undefined ? this.requerente.representanteLegal.endereco.cep : ""
      )
      .replace(
        /\[requerente.cidadeRepresentante]/g,
        this.requerente.representanteLegal.endereco.cidade != undefined ? this.requerente.representanteLegal.endereco.cidade : ""
      )
      .replace(
        /\[requerente.ufEnderecoRepresentante]/g,
        this.requerente.representanteLegal.endereco.uf != undefined ? this.requerente.representanteLegal.endereco.uf : ""
      )
      .replace(
        /\[requerente.telefone1Representante]/g,
        this.requerente.representanteLegal.telefone != undefined ? this.requerente.representanteLegal.telefone : ""
      )
      .replace(
        /\[requerente.telefone2Representante]/g,
        this.requerente.representanteLegal.telefone != undefined ? this.requerente.representanteLegal.telefone : ""
      )
      .replace(/\[requerente.emailRepresentante]/g, this.requerente.email != undefined ? this.requerente.email : "")
      .replace(/\[requerente.caixaDeTexto]/g, this.requerente.observacao != undefined ? this.requerente.observacao : "")
      .replace(/\[requerente.beneficioUf]/g, this.requerente.beneficioUf != undefined ? this.requerente.beneficioUf : "")
      .replace(/\[beneficio]/g, this.requerente.beneficio != undefined ? this.requerente.beneficio : "")
      .replace(/\[requerente.beneficio]/g, this.requerente.beneficio != undefined ? this.requerente.beneficio : "");

    let inicio = this.tipoDocumento.modeloDocumento.indexOf("[representante]");
    let final = this.tipoDocumento.modeloDocumento.lastIndexOf("[representante]");

    let representanteBloco = this.tipoDocumento.modeloDocumento.substring(inicio, final);

    this.requerente.necessitaRepresentante && this.requerente.representanteLegal != undefined
      ? (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento.replace(/\[representante]/g, ""))
      : (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(representanteBloco, "")
          .replace(/\[representante]/g, ""));
  }

  carregarTiposDocumentos(documento: Documento) {
    let idTipoDocumento = TipoDocumentoSistemaFunctions.getDescricaoPorId(documento.tipoDocumentoMotorRegras);

    this.tipoDocumentoService.pesquisarTipoDocumentoSistema(idTipoDocumento).subscribe(
      (tipoDocumento) => {
        this.tipoDocumento = tipoDocumento;
        this.regrasProcesso = this.transfereService.getData();

        let documentoOriginal = this.tipoDocumento.modeloDocumento;

        // this.regrasProcesso.instituidor.matriculas.forEach( matricula => {
        // this.regrasProcesso.instituidor.matricula = matricula;

        this.preencherDocumentosComDados(documentoOriginal);

        const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
        gerarPdfUploadAlfrescoRequest.descricao = this.tipoDocumento.nomeDocumento;
        gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
        gerarPdfUploadAlfrescoRequest.descricaoAssunto = null;
        gerarPdfUploadAlfrescoRequest.html = this.tipoDocumento.modeloDocumento;
        gerarPdfUploadAlfrescoRequest.nomeDocumento =
          this.tipoDocumento.nomeDocumento + " MAT" + this.regrasProcesso.instituidor.matricula + ".pdf";
        gerarPdfUploadAlfrescoRequest.docAssinantes = null;
        gerarPdfUploadAlfrescoRequest.tipoDocumentoId = documento.tipoDocumentoId;
        gerarPdfUploadAlfrescoRequest.processoId = null;
        gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = true;

        this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
          (documentoSalvo) => {
            this.registrarDocsGerados(documento, documentoSalvo, documentoSalvo.fileByte, documento.descricao);
            this.receberArquivoSalvo(documento, documentoSalvo, documentoSalvo.fileByte, documento.descricao);
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
        // })

        this.messageService.messageSuccess("Arquivo salvo com sucesso!");
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  registrarDocsGerados(documento: Documento, documentoSalvo: Documento, arquivo: any, descricao: string) {
    let documentoRegistrado: Documento = new Documento();
    documentoRegistrado.generico = true;
    documentoRegistrado.tipoDocumento = this.tipoDocumento;
    documentoRegistrado.descricao = descricao;
    documentoRegistrado.id = documentoSalvo.id;
    documentoRegistrado.file = arquivo;
    documentoRegistrado.assinado = false;
    documentoRegistrado.obrigatorio = documento.obrigatorio;
    documentoRegistrado.geradoPeloSistema = documento.geradoPeloSistema;
    documentoRegistrado.nomeDocumento = documentoSalvo.nomeDocumento;
    documentoRegistrado.tipoDocumentoMotorRegras = documento.tipoDocumentoMotorRegras;
    documentoRegistrado.tipoDocumentoId = documento.tipoDocumentoId;

    this.listDocumentosPorMatricula.push(documentoRegistrado);
  }

  public adicionarDocumentoEditor() {
    this.novoDocumento();
    this.exibicaoEditor = true;
  }

  public novoDocumento() {
    this.limparDocumentoSelecionado();
    this.confiCkEditor();
    this.isDocumentoAssinado = false;
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;
    this.listaAssinador = [];
    this.processo.modeloAssunto = "";
  }

  private limparDocumentoSelecionado() {
    this.documentoSelecionado = null;
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  public validarAssinatura(documento: Documento) {
    if (!this.tipoDocumentoNaoObrigatorio) {
      this.messageService.messageWarning("Selecione um tipo de Documento");
      return false;
    }

    if (documento.descricao === undefined || documento.descricao === "") {
      if (documento.generico && this.tipoDocumento != null && this.tipoDocumento.nomeDocumento != null) {
        // tslint:disable-next-line: max-line-length
        this.messageService.messageWarning(
          "Descrição obrigatória para incluir documento do tipo '" + this.tipoDocumento.nomeDocumento + "'"
        );
        return false;
      }
      // tslint:disable-next-line: max-line-length
      this.messageService.messageWarning(
        "Descrição obrigatória para incluir documento do tipo '" + documento.tipoDocumento.nomeDocumento + "'"
      );
      return false;
    }

    if (this.validarDescricaoDuplicada()) {
      this.messageService.messageErro("Descrição não pode ser duplicada.");
      this.clean(documento);
      return false;
    }

    return true;
  }

  public validarDescricaoDuplicada(): boolean {
    const descricoes: string[] = [];
    this.dataSource.filteredData.forEach((documento) => {
      if (documento.descricao !== undefined && documento.descricao !== "") {
        descricoes.push(documento.descricao);
      }
    });
    return new Set(descricoes).size !== descricoes.length;
  }
}
