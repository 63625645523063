import { OrgaoService } from "./../../api/orgao.service";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatTableDataSource, MatExpansionPanelDescription } from "@angular/material";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { MessageService } from "src/app/global/message/message.service";
import { SharedService } from "src/app/global/api/shared.service";
import { Orgao } from "../../models/orgao.model";
import { UsuarioService } from "../../api/usuario.service";
import { TokenTO } from "src/app/global/models/token.model";

@Component({
  selector: "app-dialog-tipo-documento",
  templateUrl: "./dialog-tipo-documento.component.html",
  styleUrls: ["./dialog-tipo-documento.component.css"],
})
export class DialogTipoDocumentoComponent implements OnInit {
  public listaTipoDocumento: TipoDocumento[] = [];
  public tipoDocumento: TipoDocumento;
  buscaNome: string;

  public displayedTipoDocumentoColumns: string[] = ["nome"];
  public dataSourceTipoDocumento: MatTableDataSource<TipoDocumento>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public highlightedTipoDocumentoRows: any[] = [];
  public lotacoesUsuario: Orgao[];

  constructor(
    private orgaoService: OrgaoService,
    public dialogRef: MatDialogRef<DialogTipoDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tipoDocumentoService: TipoDocumentoService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data.anexo === undefined || this.data.anexo === null) {
      this.messageService.messageErro("Informe o tipo de documento(anexo ou editor)");
      throw new Error("Informe o tipo de documento(anexo ou editor)");
    }
    this.listaTipoDocumento = [];
    this.carregarUsuario();
  }

  private buscaTipoDocumento() {
    this.lotacoesUsuario.forEach((lotacao) => {
      if (lotacao) {
        this.tipoDocumentoService.listarTipoDocumentoPorLotacao(lotacao.id).subscribe((listaDocumentosLotacao) => {
          this.filtrarTipoDocumentoPorAssunto(listaDocumentosLotacao.filter((documento) => documento.somenteAnexo === this.data.anexo));
        });
      } else {
        this.messageService.messageWarning("Não possui documentos cadastrados para sua lotação.");
        throw new Error("Não possui documentos cadastrados para sua lotação.)");
      }
    });
  }

  filtrarTipoDocumentoPorAssunto(listTipoDocumento: TipoDocumento[]) {
    // Filtra os tipos de documentos ativos e não gerados pelo sistema associados ao Assunto
    listTipoDocumento
      .filter((doc) => doc.ativo)
      .filter((doc) => doc.geradoSistema === false)
      .forEach((tipoDocumento) => {
        // this.data.assunto.listTipoDocumento.forEach((tipoDocumentoAssunto) => {
          // if (tipoDocumento.id === tipoDocumentoAssunto.id) {
            this.listaTipoDocumento.push(tipoDocumento);
          // }
        // });
      });

    if (this.data.relacionadoEnvolvido) {
      this.listaTipoDocumento = this.listaTipoDocumento.filter((tipoDocumento) => tipoDocumento.envolvidoPensao === true);
    } else {
      this.listaTipoDocumento = this.listaTipoDocumento.filter((tipoDocumento) => tipoDocumento.envolvidoPensao === false);
    }

    this.listaTipoDocumento = this.listaTipoDocumento.sort((a, b) => a.nomeDocumento.localeCompare(b.nomeDocumento));

    if (this.listaTipoDocumento.length == 0) {
      this.messageService.messageInfo("Não possui Tipo de Documento associado a este assunto");
      this.dialogRef.close();
    }

    this.dataSourceTipoDocumento = new MatTableDataSource<TipoDocumento>(this.listaTipoDocumento);
    this.dataSourceTipoDocumento.paginator = this.paginator;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public getTipoDocumento(tipoDocumento: TipoDocumento): void {
    this.tipoDocumento = tipoDocumento;
    this.data = this.tipoDocumento;
    this.highlightedTipoDocumentoRows.pop();
    this.highlightedTipoDocumentoRows.push(tipoDocumento);
  }

  public filtrarTipoDocumento(): void {
    if (this.buscaNome != undefined && this.buscaNome != "") {
      var nome = this.buscaNome;
      this.listaTipoDocumento = this.listaTipoDocumento.filter(function (tipoDocumento) {
        return tipoDocumento.nomeDocumento.toUpperCase().includes(nome.toUpperCase());
      });
      this.listaTipoDocumento = this.listaTipoDocumento
        .filter((doc) => doc.ativo)
        .sort((a, b) => a.nomeDocumento.localeCompare(b.nomeDocumento));
      (this.dataSourceTipoDocumento = new MatTableDataSource<TipoDocumento>(this.listaTipoDocumento)),
        (this.dataSourceTipoDocumento.paginator = this.paginator);
    } else {
      this.buscaTipoDocumento();
    }
  }

  public selecionar(): void {
    if (this.tipoDocumento == null) {
      this.messageService.messageWarning("Nenhum tipo de documento selecionado!");
      return;
    }
  }

  public cancelar(): void {
    this.dialogRef.close();
  }

  private carregarUsuario(): void {
    const token: TokenTO = this.sharedService.decodePayloadJWT();
    this.carregarOrgaosUsuario(token.sub);
  }

  private carregarOrgaosUsuario(documentoUsuario): void {
    this.orgaoService.getOrgaoUsuario(documentoUsuario).subscribe(
      (orgaos) => {
        this.lotacoesUsuario = orgaos;
        this.buscaTipoDocumento();
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
