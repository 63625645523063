import { Domain } from "../../global/models/domain.model";
import { Assinatura } from "./assinatura.model";
import { TipoDocumento } from "./tipo-documento.model";
import { Assinador } from "./assinador.model";
import { MetadadoValor } from "./metadado-valor.model";
import { Usuario } from "./usuario.model";
import { StatusDocumento } from "./status-documento.enum";

export class DocumentoSaveRequest extends Domain {
  public fileName: string;
  public fileByte: any;
  public file: File;
  public descricao: string;
  public descricaoAssunto: string;
  public tipoDocumentoId: number;
  public docAutor: string;
  public lotacaoAutor: string;
  public docAssinantes: string[];
  public descricaoAssinatura: string;
  public processoId: number;
  public downloadUsuarioExterno: boolean;
  public listMetadadoValor: MetadadoValor[];
  public isPedido: boolean;

}
