import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { MaterialModule } from "../material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HomeComponent } from "./home";
import { CertificateResolver } from "./resolver/certificate-resolver";
import { AutenticacaoModule } from "./autenticacao/autenticacao.module";
import { Ng7MatBreadcrumbModule } from "ng7-mat-breadcrumb";
import { MetadadoModule } from "./metadado/metadado.module";
import { TitleViewModule } from "./title-view/title-view.module";
import { ContribuinteModule } from "./contribuinte/contribuinte.module";
import { SharedService } from "./api/shared.service";
import { MessageContainerComponent } from "./message/message-container/message-container.component";
import { MessageService } from "./message/message.service";
import { MessageComponent } from "./message/message.component";
import { NgxMaskModule } from "ngx-mask";
import { HomeModule } from "./home/home.module";
import { LoadingScreenComponent } from "./loading-screen/loading-screen.component";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AutenticacaoModule,
    ContribuinteModule,
    Ng7MatBreadcrumbModule,
    TitleViewModule,
    MetadadoModule,
    HomeModule,
    ToastrModule.forRoot({ onActivateTick: true, positionClass: "toast-bottom-right" }),
    NgxMaskModule.forRoot(),
  ],
  declarations: [MessageComponent, MessageContainerComponent, LoadingScreenComponent],
  providers: [CertificateResolver, SharedService],
  exports: [MetadadoModule, TitleViewModule, MessageContainerComponent, LoadingScreenComponent],
})
export class GlobalModule {}
