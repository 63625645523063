import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissaoService } from 'src/app/core/api/seguranca/permissao.service';
import { Permissao } from 'src/app/core/models/seguranca/permissao.model';
import { SharedService } from 'src/app/global/api/shared.service';
import ListarGenericsComponent from 'src/app/global/generics/listar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';
import { comparaCaseInsensitive } from 'src/app/global/util/funcoes';

@Component({
  selector: 'sgeap-listar-permissao',
  templateUrl: './listar-permissao.component.html',
  styleUrls: ['./listar-permissao.component.css']
})
export class ListarPermissaoComponent extends ListarGenericsComponent<Permissao> {

  nomeFiltro = new FormControl('');
  descricaoFiltro = new FormControl('');
  codigoFiltro = new FormControl('');

  filtroPermissao = {
    nome: "",
    descricao: "",
    codigo: ""
  }

  constructor(
    public apiService: PermissaoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "permissao",
      ["nome", "descricao", "codigo", "editar"]);
  }

  afterResponseListar() {
    this.configurarFiltros();
    this.dataSource.filterPredicate = this.createFilter();
  }

  private configurarFiltros(): void {
    this.nomeFiltro.valueChanges
      .subscribe(
        nome => {
          this.filtroPermissao.nome = nome;
          this.dataSource.filter = JSON.stringify(this.filtroPermissao);
        }
      );
    this.descricaoFiltro.valueChanges
      .subscribe(
        descricao => {
          this.filtroPermissao.descricao = descricao;
          this.dataSource.filter = JSON.stringify(this.filtroPermissao);
        }
      );
    this.codigoFiltro.valueChanges
      .subscribe(
        codigo => {
          this.filtroPermissao.codigo = codigo;
          this.dataSource.filter = JSON.stringify(this.filtroPermissao);
        }
      );
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let pesquisa = JSON.parse(filter);
      return comparaCaseInsensitive(data.nome, pesquisa.nome)
        && comparaCaseInsensitive(data.descricao, pesquisa.descricao)
        && comparaCaseInsensitive(data.codigoIdentificacao, pesquisa.codigo);
    }
    return filterFunction;
  }

}
