import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ProcessoService } from "../../api/processo.service";
import { SituacaoService } from "../../api/situacao.service";
import { MessageService } from "../../../global/message/message.service";
import { Situacao } from "../../models/situacao.model";
import { Responsavel } from "../../models/responsavel.model";
import { MovimentacaoDTO } from "../../models/movimentacao-dto.model";
import { Fase } from "../../models/fase.model";
import { FaseService } from "../../api/fase.service";
import { SisSegService } from "../../api/sis-seg.service";
import { DialogLotacaoComponent } from "../dialog-lotacao/dialog-lotacao.component";
import { OrgaoLocal } from "../../models/orgao-local.model";
import { ProcessoMovimentacao } from "../../models/processo-movimentacao.model";
import { LotacaoSecundariaService } from "../../api/lotacao-secundaria.service";
import { Processo } from "../../models/processo.model";
import { Utils } from "src/app/global/util/utils-component";
import { SharedService } from "src/app/global/api/shared.service";
import { OrgaoService } from "../../api/orgao.service";

@Component({
  selector: "app-dialog-movimentacao-processo",
  templateUrl: "./dialog-movimentacao-processo.component.html",
  styleUrls: ["./dialog-movimentacao-processo.component.css"],
})
export class DialogMovimentacaoProcessoComponent implements OnInit {
  public situacoes: Situacao[] = [];
  public situacao: Situacao;
  public responsaveis: Responsavel[] = [];
  public fases: Fase[] = [];
  public fase: Fase;
  public autor: any;
  public observacao: string;
  public responsavel: Responsavel;
  public nenhumResponsavel: Responsavel;
  public movimentacaoDTO: MovimentacaoDTO;
  public orgaoLocalList: any[];
  public tipoDocumento: any;
  public orgaoLocal: OrgaoLocal;
  public disableResponsavel = true;
  public disableSetor = true;
  public disableTipoArquivamento = true;
  public tipoArquivamento: any;
  public isEmLote: boolean = false;

  // FIXME Criar enum
  public tipoArquivamentoList = [
    { id: 0, descricao: "Aguardando publicação de novo processo" },
    { id: 1, descricao: "Deferido" },
    { id: 2, descricao: "Desistência" },
    { id: 3, descricao: "Finalizado" },
    { id: 4, descricao: "Indeferido" },
    { id: 5, descricao: "Parcialmente deferido" },
    { id: 6, descricao: "Perda do objeto" },
  ];
  public isAnalistaSetorial: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogMovimentacaoProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProcessoMovimentacao,
    private processoService: ProcessoService,
    private situacaoService: SituacaoService,
    private messageService: MessageService,
    private faseService: FaseService,
    private orgaoService: OrgaoService,
    private lotacaoSecundariaService: LotacaoSecundariaService,
    private SisSegService: SisSegService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.situacao = new Situacao();
    this.fase = new Fase();
  }

  ngOnInit() {
    if ((this.isAnalistaSetorial = this.sharedService.decodePayloadJWT().perfil === "ANALISTA SETORIAL")) {
      this.isAnalistaSetorial = true;
    }

    this.situacaoService.list().subscribe(
      (situacoes) => {
        this.situacoes = situacoes.filter((situacao) => {
          return situacao.ativo;
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.faseService.list().subscribe(
      (fases) => {
        this.fases = fases.filter(function (fase) {
          return fase.situacaoPosVisualizacao.situacaoFinal === false && fase.ativo;
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.orgaoService.getOrgaoLocal().subscribe(
      (orgaoLocal) => {
        this.orgaoLocalList = orgaoLocal;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.nenhumResponsavel = new Responsavel();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectSetor(): void {
    //this.messageService.messageWarning("Implementar consulta de servidores por orgão.");
    this.SisSegService.listServidoresPorOrgao(this.orgaoLocal.id.toString()).subscribe(
      (responsaveis) => {
        this.atualizarResponsaveisServidores(this.orgaoLocal.id);

        if (responsaveis) {
          this.responsaveis = responsaveis.sort((obj1, obj2) => {
            if (obj1.nome > obj2.nome) {
              return 1;
            }
            if (obj1.nome < obj2.nome) {
              return -1;
            }
            return 0;
          });
          this.disableResponsavel = false;
        }
      },
      (error) => {
        this.messageService.messageErro("Erro ao localizar servidores" + error.error.message);
        this.disableResponsavel = true;
        throw error;
      }
    );
  }

  private atualizarResponsaveisServidores(lotacao: number) {
    let countServidores = 0;
    this.lotacaoSecundariaService.consultaServidores(lotacao).subscribe(
      (servidores) => {
        servidores.forEach((documento) => {
          //this.messageService.messageWarning("Implementar consulta de servidores por documento.");
          this.SisSegService.consultarServidorByDocumento(documento.usuario).subscribe((servidor) => {
            countServidores++;
            let responsavel = new Responsavel();
            responsavel.cgf = servidor.cpf;
            responsavel.cpfCnpj = servidor.cpf;
            responsavel.documento = servidor.documento;
            responsavel.nome = servidor.nome;
            responsavel.matricula = servidor.matricula;
            this.responsaveis.push(responsavel);
            if (countServidores === servidores.length) {
              Utils.ordernarNomeOrdemAlfabetica(this.responsaveis);
            }
          });
        });
        this.disableResponsavel = false;
      },
      (error) => {
        countServidores++;
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  selectFase(): void {
    if (this.fase.situacaoInicial.situacaoFinal) {
      this.disableTipoArquivamento = false;
    } else {
      this.disableTipoArquivamento = true;
      this.tipoArquivamento = null;
    }
  }

  public movimentar(): void {
    if (this.validar()) {
      const movimentacaoDTO = new MovimentacaoDTO();
      movimentacaoDTO.faseId = this.fase.id;

      if (this.responsavel !== undefined && this.responsavel != null && this.responsavel.documento) {
        movimentacaoDTO.responsavel = this.responsavel.documento;
      }
      movimentacaoDTO.docAutor = this.sharedService.decodePayloadJWT().sub;
      movimentacaoDTO.observacao = this.observacao;

      if (this.orgaoLocal !== undefined) {
        movimentacaoDTO.orgaoLocal = this.orgaoLocal.id;
      }

      if (this.fase && this.fase.situacaoInicial && this.fase.situacaoInicial.situacaoFinal && this.tipoArquivamento) {
        movimentacaoDTO.tipoArquivamentoId = this.tipoArquivamento.id;
      }
      this.data.processos.forEach((processo) => {
        if (this.data.isEmLote) {
          this.processoService.movimentarEmLote(movimentacaoDTO, processo.id).subscribe(
            (res) => {
              this.messageService.messageSuccess("Movimentação realizada com sucesso", res.etiquetaProcessoViproc);
              if (this.data.processos.length === 1) {
                this.dialogRef.close(this.data.processos);
              }
            },
            (error) => {
              this.messageService.message(
                error.error.level,
                "Movimentação do processo " + processo.etiquetaProcessoViproc + ": " + error.error.message
              );
              if (this.data.processos.length == 1) {
                this.dialogRef.close(this.data.processos);
              }
              throw error;
            }
          );
        } else {
          this.processoService.movimentar(movimentacaoDTO, processo.id).subscribe(
            (res) => {
              this.messageService.messageSuccess("Movimentação realizada com sucesso", res.etiquetaProcessoViproc);
              if (this.data.processos.length === 1) {
                this.dialogRef.close(this.data.processos);
              }
            },
            (error) => {
              this.messageService.messageErro(
                "Erro na movimentação do processo " + processo.etiquetaProcessoViproc + ": " + error.error.message
              );
              if (this.data.processos.length == 1) {
                this.dialogRef.close(this.data.processos);
              }
              throw error;
            }
          );
        }
      });
      if (this.data.processos.length > 1) {
        this.dialogRef.close();
      }
    }
  }

  validar(): boolean {
    let validacao = true;
    if (!this.orgaoLocal) {
      this.messageService.messageErro("Selecione o Setor.");
      validacao = false;
    }
    return validacao;
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "720px",
      data: { ehSelect: false, processoId: this.getProcessoId() },
    });
    dialogLotacao.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.orgaoLocal = processoParam;
        this.selectSetor();
      }
    });
  }

  private getProcessoId() {
    if (this.data && this.data.processos && this.data.processos[0]) {
      return this.data.processos[0].id;
    }
  }
}
