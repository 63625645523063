export enum SituacaoItemNaoSimilaridadeEnum {
    EM_ANALISE = ("Em Análise"),
	DEFERIDO = ("Deferido"),
	DEFERIDO_CERTIFICADO = ("Deferido com Certificado"),
    INDEFERIDO = ("Indeferido")
}

export const SituacaoItemNaoSimilaridadeEnumAll = [
    { id: 0, descricao: SituacaoItemNaoSimilaridadeEnum.EM_ANALISE },
    { id: 1, descricao: SituacaoItemNaoSimilaridadeEnum.DEFERIDO },
    { id: 2, descricao: SituacaoItemNaoSimilaridadeEnum.DEFERIDO_CERTIFICADO },
    { id: 3, descricao: SituacaoItemNaoSimilaridadeEnum.INDEFERIDO }
  ];