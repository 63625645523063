import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import { CompletarCadastroComponent } from "./core/cadastro/usuario/completar-cadastro/completar-cadastro.component";
import { AuthGuard } from "./global/api/seguranca/auth.guard";
import { HomeComponent } from "./global/home";
import { RegisterComponent } from "./global/autenticacao/register/register.component";
import { ForgotPasswordComponent } from "./global/autenticacao/forgot-password/forgot-password.component";
import { SalvarCidadaoComponent } from "./core/seguranca/cidadao/salvar-cidadao/salvar-cidadao.component";

const routes: Routes = [
  {
    path: environment.CONTEXTO,
    component: HomeComponent,
    data: {
      title: "Menu de Serviços",
      breadcrumb: [
        {
          label: "Menu de Serviços",
          url: "",
        },
      ],
    },
    children: [
      { path: "entidade", loadChildren: "./global/entidade/entidade.module#EntidadeModule" },
      { path: "", loadChildren: "./core/servicos/servicos.module#ServicosModule" },
      { path: "", loadChildren: "./core/processo/processo.module#ProcessoModule" },
      { path: "", loadChildren: "./core/documento/documento.module#DocumentoModule" },
      { path: "", loadChildren: "./global/contribuinte/contribuinte.module#ContribuinteModule" },
      { path: "", loadChildren: "./core/cadastro/cadastro.module#CadastroModule" },
      { path: "", loadChildren: "./core/master/master.module#MasterModule" },
      { path: "", loadChildren: "./core/modulo-assinador/modulo-assinador.module#ModuloAssinadorModule" },
      { path: "", loadChildren: "./core/seguranca/seguranca.module#SegurancaModule" },
      { path: "", loadChildren: "./core/pensao/pensao.module#PensaoModule" },
    ],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: "completar-cadastro", component: CompletarCadastroComponent, canActivate: [AuthGuard] },
  { path: "register", component: RegisterComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "salvar-cidadao/editar/:id", component: SalvarCidadaoComponent },

  {
    path: "**",
    redirectTo: environment.CONTEXTO,
  },

  { path: "", redirectTo: `login`, pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
