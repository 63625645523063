export class TipoCampo {

    static ALFANUMERICO() { return 0; }

    static NUMERICO() { return 1; }

    static DATA() { return 2; }

    static BOTAO() { return 3; }

    static isAlfanumerico(tipo: number): boolean {
        return tipo === TipoCampo.ALFANUMERICO();
    }

    static isNumerico(tipo: number): boolean {
        return tipo === TipoCampo.NUMERICO();
    }

    static isData(tipo: number): boolean {
        return tipo === TipoCampo.DATA();
    }

    static isBotao(tipo: number): boolean {
        return tipo === TipoCampo.BOTAO();
    }
}
