import { Domain } from "src/app/global/models/domain.model";
import { Documento } from "../documento.model";
import { Endereco } from "./endereco.model";
import { TipoRepresentante } from "./tipo-representante.enum";
import { TipoSolicitante } from "./tipo-solicitante.enum";


export class Representante extends Domain {
    tipoRepresentante: string;
    tipoSolicitante: string;
    nome: string;
    cpf: string;
    sexo: string;
    rg: string;
    orgaoEmissor: string;
    uf: string;
    estadoCivil: string;
    orgaoOrigem: string;
    dataCasamento: Date;
    endereco: Endereco;
    email: string;
    telefone: string;
    telefone2: string;
    
}