import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { TipoEditorTextoEnumAll, TipoEditorTextoEnum } from "src/app/core/models/tipo-editor-texto-enum.model";
import { FormControl } from "@angular/forms";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { DialogDesativiarTipoDocumento } from "src/app/core/processo/dialog-desativar-tipo-documento/dialog-desativar-tipo-documento.component";
import { SharedService } from "src/app/global/api/shared.service";
import { OrgaoService } from "src/app/core/api/orgao.service";

@Component({
  selector: "app-listar-tipo-documento",
  templateUrl: "./listar-tipo-documento.component.html",
  styleUrls: ["./listar-tipo-documento.component.css"]
})
export class ListarTipoDocumentoComponent extends ListarGenericsComponent<TipoDocumento> implements OnInit {
  public dataSource: MatTableDataSource<TipoDocumento>;
  public columns = [];
  public buttons = [];
  public sections = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('child') child: any;

  // ngAfterViewInit() {
  //   this.paginator = this.child.paginator
  // }

  public filtroTipoDocumento = "ANEXO";
  public anexo = true;
  public tipoEditorTextoList: any[] = TipoEditorTextoEnumAll;
  public tipoEditorTexto: any = TipoEditorTextoEnum.GERAL;

  nomeDocumentoFilter = new FormControl("");
  filterValues = {
    nomeDocumento: ""
  };
  public orgaoLocalList: any[];
  
  constructor(public apiService: TipoDocumentoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService,
    public orgaoService: OrgaoService,
    public assuntoService: AssuntoService) {
    super(apiService, messageService, sharedService, toastr, router, "tipo-documento",
      // tslint:disable-next-line: max-line-length
      ["idTipoDocumentoMotorRegras","nomeDocumento", "qtdeMinimaAssinatura", "qtdeMaximaAssinatura", "qtdeExataAssinatura", "visibilidadeExterna", "visibilidadeLotacao", "ativo", "removerOpcao"]);

      
      this.columns =  [
        {
          key: 'nomeDocumento',
          label: 'Nome Documento'
        },
        {
          key: 'qtdeMinimaAssinatura',
          label: 'Qtd Min Assinat.',
        },
        {
          key: 'qtdeMaximaAssinatura',
          label: 'Qtd Max Assinat.',
        },
        {
          key: 'qtdeExataAssinatura',
          label: 'Qtd Exata Assinat.',
        },
        {
          key: 'visibilidadeExterna',
          label: 'Visib. Ext.',
          parseItem: (e) => e ? 'Sim' : 'Não',
        },
        {
          key: 'visibilidadeLotacao',
          label: 'Visib. Lot.',
          parseItem: (e) => e ? 'Sim' : 'Não',
        },
        {
          key: 'lotacao',
          label: 'Lotação',
        },
        {
          key: 'ativo',
          label: 'Ativo',
          type: 'toggle',
          props: {
            matTooltip:"Ativar/Desativar",
            color: "accent",
            click: (e) => this.inativar(e)
          }
        },
        {
          key: 'removerOpcao',
          label: 'Ações',
          type: 'icon',
          props: {
            icon: 'create',
            matTooltip: 'Editar',
            click: (e) => this.editValidate(e)
          }
        }
      ];
  }

  ngOnInit(): void {
    this.listar();
    this.nomeDocumentoFilter.valueChanges.subscribe(
      nomeDocumento => {
        this.filterValues.nomeDocumento = nomeDocumento.toLowerCase();
        this.dataSource.filter = JSON.stringify(this.filterValues);
      }
    );
    this.getPageState();
    this.getFilterState();
    this.sections = [
      {
        fields: [[
          {
            key: 'nomeDocumentoFilter',
            formControl: () => this.nomeDocumentoFilter,
            fieldType: 'textInput',
            label: 'Pesquisar documento',
            cols: [12,12,12,12,12]
          },
        ]]
      }
    ]

  }

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      return data.nomeDocumento.toLowerCase().indexOf(searchTerms.nomeDocumento) !== -1;
    };
    return filterFunction;
  }

  public listar() {
    this.displayedColumns = ["nomeDocumento", "qtdeMinimaAssinatura", "qtdeMaximaAssinatura", "qtdeExataAssinatura", "visibilidadeExterna", "visibilidadeLotacao", "ativo", "removerOpcao"];
    
    if (this.filtroTipoDocumento === "ANEXO") {
      this.apiService.getTipoDocumentoSomenteAnexo(true).subscribe(
        (listaTipoDocumento: TipoDocumento[]) => {
          this.atualizarDataSourcePaginatorFilter(listaTipoDocumento);
        },
        excecao => {
          this.messageService.messageErro(excecao.error.message);
          throw excecao;
        }
      );
    } else if (this.filtroTipoDocumento === "EDITOR") {
      if (this.tipoEditorTexto === TipoEditorTextoEnum.GERAL) {
        this.apiService.getTipoDocumentoSomenteAnexo(false).subscribe(
          (listaTipoDocumento: TipoDocumento[]) => {
            this.atualizarDataSourcePaginatorFilter(listaTipoDocumento);
          },
          excecao => {
            this.messageService.messageErro(excecao.error.message);
            throw excecao;
          }
        );
      } else {

        this.displayedColumns = ["nomeDocumento", "qtdeMinimaAssinatura", "qtdeMaximaAssinatura", "qtdeExataAssinatura", "visibilidadeExterna", "visibilidadeLotacao", "lotacao", "ativo", "removerOpcao"];
        this.apiService.getTipoDocumentoEditorPorSetor().subscribe(
          (listaTipoDocumento: TipoDocumento[]) => {
            this.atualizarDataSourcePaginatorFilter(listaTipoDocumento);
          },
          excecao => {
            this.messageService.messageErro(excecao.error.message);
            throw excecao;
          }
        );
      }
      this.orgaoService.getOrgaoLocal().subscribe(
        (orgao) => {
          this.orgaoLocalList = orgao;
        },
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    } else if (this.filtroTipoDocumento === "MOTOR_REGRAS") {
      this.displayedColumns = ["idTipoDocumentoMotorRegras", "nomeDocumento", "ativo", "removerOpcao"];
      this.apiService.listarTipoDocumentoMotorRegra().subscribe(
        (listaTipoDocumento: TipoDocumento[]) => {
          this.atualizarDataSourcePaginatorFilter(listaTipoDocumento);
        },
        excecao => {
          this.messageService.messageErro(excecao.error.message);
          throw excecao;
        }
      );
    }
  }

  public getDescricaoLotacao(id: number) {
    return this.orgaoLocalList.find(lotacao => lotacao.id === id).descricao;
  }

  private atualizarDataSourcePaginatorFilter(listaTipoDocumento: TipoDocumento[]) {
    var listTpDoc = listaTipoDocumento.sort((a, b) => a.nomeDocumento.localeCompare(b.nomeDocumento));
    this.dataSource = new MatTableDataSource<TipoDocumento>(listTpDoc);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.createFilter();
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  inativar(tipoDocumento: TipoDocumento) {
    if(tipoDocumento.ativo) {
      this.assuntoService.getAssuntoByTipoDocumento(tipoDocumento.id).subscribe((res) => {
        if(res) {
          this.dialogDesativar(res,tipoDocumento);
        } else {
          this.dialogConfirmar(tipoDocumento);
        }
      });
    } else {
      this.dialogConfirmar(tipoDocumento);
    }
  }

  dialogConfirmar(tipoDocumento: TipoDocumento) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.apiService.put(tipoDocumento).subscribe(
          (res) => {
              this.messageService.messageSuccess("Visibilidade alterada com sucesso.");
              this.listar();
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        tipoDocumento.ativo = !tipoDocumento.ativo;
      }
    });
  }

  dialogDesativar(assuntoList: any, tipoDocumento: TipoDocumento){
    const dialogRef = this.dialog.open(DialogDesativiarTipoDocumento, {
      maxWidth: "400px",
      data: assuntoList.sort((a,b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0))
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.apiService.put(tipoDocumento).subscribe(
          (res) => {
            this.assuntoService.deleteAssuntoTipoDocumentoByTipoDocumento(tipoDocumento.id).subscribe((res) =>{
              this.messageService.messageSuccess("Visibilidade alterada com sucesso.");
              this.listar();
            });
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        tipoDocumento.ativo = !tipoDocumento.ativo;
      }
    });
  }

  setFilterState() {
    this.sharedService.setTipoDocumentoFiltroNome(this.nomeDocumentoFilter.value);
    this.sharedService.setTipoDocumentoFiltroTipo(this.anexo.toString());
    if(this.anexo == false){
      this.sharedService.setTipoDocumentoFiltroTipoEditorTexto(this.tipoEditorTexto);
    }

    this.setPageState();
  }

   getFilterState() {

    if (this.sharedService.getTipoDocumentoTipoFiltro() != null
    && this.sharedService.getTipoDocumentoTipoFiltro() != "") {
      if(this.sharedService.getTipoDocumentoTipoFiltro() == "false"){
        this.anexo = false;
      }
      this.sharedService.setTipoDocumentoFiltroTipo("");
    }

    if (this.sharedService.getTipoDocumentoFiltroTipoEditorTexto() != null
    && this.sharedService.getTipoDocumentoFiltroTipoEditorTexto() != "") {
       if(this.sharedService.getTipoDocumentoFiltroTipoEditorTexto() == TipoEditorTextoEnum.LOTACAO.toString()){
        this.tipoEditorTexto = TipoEditorTextoEnum.LOTACAO;
      }
      this.sharedService.setTipoDocumentoFiltroTipoEditorTexto("");
    }

    this.listar();
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.sharedService.getTipoDocumentoFiltroNome() != null
        && this.sharedService.getTipoDocumentoFiltroNome() != "") {
          this.nomeDocumentoFilter.setValue(this.sharedService.getTipoDocumentoFiltroNome());
          this.sharedService.setTipoDocumentoFiltroNome("");
        } 
      }, 500);
    });

  }

  editValidate(tipoDocumento: any){
    this.setFilterState();
    this.edit(tipoDocumento);
  }
}
