import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ListarFilhosCategoriaAssuntoComponent } from "./listar-filhos-categoria-assunto/listar-filhos-categoria-assunto.component";
import { ListarCategoriaAssuntoComponent } from "./listar-categoria-assunto/listar-categoria-assunto.component";
import { CategoriaAssuntoService } from "../api/categoria-assunto.service";
import { MatListModule, MatButtonModule, MatIconModule } from "@angular/material";
import { AssuntoService } from "../api/assunto.service";
import { ListarAssuntoComponent } from "./listar-assunto/listar-assunto.component";
import { ServicosComponent } from "./servicos/servicos.component";
import { ContribuinteService } from "../api/contribuinte.service";
import { DialogProcessoAbertoComponent } from "./dialog-processo-aberto/dialog-processo-aberto.component";
import { MaterialModule } from "src/app/material";
import { MessageService } from "src/app/global/message/message.service";
import { NgxCpfCnpjModule } from "ngx-cpf-cnpj";
import { NgxMaskModule } from "ngx-mask";
// tslint:disable-next-line: max-line-length
import { DialogFiscalEscolheAutorInternoComponent } from "./dialog-fiscal-escolhe-autor-interno/dialog-fiscal-escolhe-autor-interno.component";
// tslint:disable-next-line: max-line-length
import { DialogFiscalEscolheAutorExternoComponent } from "./dialog-fiscal-escolhe-autor-externo/dialog-fiscal-escolhe-autor-externo.component";
import { UiModule } from "src/app/ui/ui.module";

const routes: Routes = [
  { path: "", component: ListarCategoriaAssuntoComponent},
];

@NgModule({
  declarations: [ListarCategoriaAssuntoComponent, ListarFilhosCategoriaAssuntoComponent,
    ListarAssuntoComponent, ServicosComponent, DialogProcessoAbertoComponent,
    DialogFiscalEscolheAutorInternoComponent, DialogFiscalEscolheAutorExternoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MaterialModule,
    NgxCpfCnpjModule,
    UiModule,
    NgxMaskModule
  ],
  providers: [CategoriaAssuntoService, AssuntoService, ContribuinteService],
  entryComponents: [DialogProcessoAbertoComponent, DialogFiscalEscolheAutorInternoComponent, DialogFiscalEscolheAutorExternoComponent]
})
export class ServicosModule { }
