import { Component, ContentChild, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-crud-list-template',
  templateUrl: './crud-list-template.component.html',
  styleUrls: ['./crud-list-template.component.css']
})
export class CrudListTemplateComponent {

  @Input() data = [];
  @Input() columns = [];

  @Input() pageSizeOptions = [5, 10, 20]
  @Input() pageSize = 10
  @Input() pageIndex = 0
  @Input() showFirstLastButtons = true

  @Input() buttons = [];
  @Input() sections: [];
  @Input() form = {};
  @Input() defaultXs?: number | 'auto';
  @Input() defaultSm?: number | 'auto';
  @Input() defaultMd?: number | 'auto';
  @Input() defaultLg?: number | 'auto';
  @Input() defaultXl?: number | 'auto';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngDoCheck() {
  
  }

  handleParam(param: unknown, paramDefault: unknown) {
    return typeof param === 'function' ? param() : param || paramDefault
  }

}
