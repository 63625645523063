import { Component, OnInit, ViewChild } from "@angular/core";
import { Modelo } from "src/app/core/models/modelo.model";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "src/app/global/message/message.service";
import { SisSegService } from "src/app/core/api/sis-seg.service";
import { ModeloService } from "src/app/core/api/modelo.service";
import { LotacaoSecundariaService } from "src/app/core/api/lotacao-secundaria.service";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";

@Component({
  selector: "app-salvar-modelo",
  templateUrl: "./salvar-modelo.component.html",
  styleUrls: ["./salvar-modelo.component.css"],
})
export class SalvarModeloComponent extends SalvarGenericsComponent<Modelo> implements OnInit {
  @ViewChild("myckeditor") ckeditor: any;

  public ckeConfig: any;
  public tipoDocumentos: any[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public apiService: ModeloService,
    public messageService: MessageService,
    // public SisSegService: SisSegService,
    public tipoDocumentoService: TipoDocumentoService,
    public lotacaoSecundariaService: LotacaoSecundariaService
  ) {
    super(formBuilder, router, apiService, messageService, route, "modelo", "");
  }

  ngOnInit() {
    this.tipoDocumentoService.getTipoDocumentoEditorPorSetor().subscribe(
      (tipoDocumentosEditorSetorUsuario) => {
        this.tipoDocumentos = tipoDocumentosEditorSetorUsuario;
        this.tipoDocumentoService.getTipoDocumentoSomenteAnexo(false).subscribe((tipoDocumentosEditorGlobal: TipoDocumento[]) => {
          if (tipoDocumentosEditorGlobal) {
            this.tipoDocumentos.push.apply(this.tipoDocumentos, tipoDocumentosEditorGlobal);

            this.tipoDocumentos.sort((obj1: TipoDocumento, obj2: TipoDocumento) => {
              if (obj1.nomeDocumento.toLocaleLowerCase() > obj2.nomeDocumento.toLocaleLowerCase()) {
                return 1;
              }
              if (obj1.nomeDocumento.toLocaleLowerCase() < obj2.nomeDocumento.toLocaleLowerCase()) {
                return -1;
              }
              return 0;
            });
          }
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.confiCkEditor();
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareObj(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  validar(domain: Modelo): boolean {
    let validacao = true;

    if (!domain.tipoDocumento) {
      this.messageService.messageErro("Preencha o campo Tipo do documento.");
      validacao = false;
    }

    if (!domain.nome || !domain.nome.trim()) {
      this.messageService.messageErro("Preencha o campo Nome do Modelo.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: Modelo): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }
}
