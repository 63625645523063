import { Domain } from "../../global/models/domain.model";

export class SolicitanteDTO extends Domain {
  documento: string;
  nome: string;

  constructor(documento: string, nome: string) {
    super();
    this.documento = documento;
    this.nome = nome;
  }

}