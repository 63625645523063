import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SharedService } from "src/app/global/api/shared.service";

@Injectable()
export class HttpBackendInterceptor implements HttpInterceptor {
    constructor(public sharedService: SharedService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Caso seja login não adiciona o token
        if (new RegExp("recuperar-senha").test(request.url) ||new RegExp("autenticar").test(request.url) || (new RegExp("sisseg/usuario").test(request.url) && request.method === 'POST')) {
            return next.handle(request);
        }

        this.sharedService.checkTokenExpirado();
        return next.handle(request.clone({
            headers: new HttpHeaders({
                "Authorization": this.sharedService.getToken()
            })
        }));
    }
}
