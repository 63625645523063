import { Domain } from "../../global/models/domain.model";
import { Assinatura } from "./assinatura.model";
import { TipoDocumento } from "./tipo-documento.model";
import { Assinador } from "./assinador.model";
import { MetadadoValor } from "./metadado-valor.model";
import { Usuario } from "./usuario.model";
import { StatusDocumento } from "./status-documento.enum";

export class Documento extends Domain {
  // Atributos em Documento do serviço Documento
  public descricao: string;
  public nomeDocumento: string;
  public numero: number;
  public ano: number;
  public status: StatusDocumento;
  public alfrescoNomeDocumento: string;
  public modeloDocumento: string;
  public alfrescoHash: string;
  public autor: Usuario;
  public assinado: boolean;
  public tipoDocumento: TipoDocumento;
  public processoId: number;
  public listAssinatura: Assinatura[];
  public dataCriacao: Date;
  public listMetadadoValor: MetadadoValor[];
  public ativo: boolean;
  public downloadUsuarioExterno: boolean;
  public ordem: number;
  public tipoDocumentoMotorRegras: string;
  
  // Atributos transientes em Documento do serviço Documento
  public file: any;
  public fileByte: any;
  public tipoDocumentoId: number;
  public descricaoAssunto: string;

  public docAutor: string;
  public docAssinantes: string[];
  public descricaoAssinatura: string;
  public numeroAno: string;
  public nossoNumero: string;

  //  Usados nos front?
  public etiquetaProcessoViproc: string;
  public listaAssinador: Assinador[];
  public loading: boolean;
  public generico: boolean;
  public idUploadFile: number;
  public emitido = false;
  public documentoEnomeAutor: string;
  public obrigatorio: boolean;
  public geradoPeloSistema: boolean;

}
