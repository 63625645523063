import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CidadaoService } from "src/app/core/api/seguranca/cidadao.service";
import { Cidadao } from "src/app/core/models/seguranca/cidadao.model";
import { SharedService } from "src/app/global/api/shared.service";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { comparaCaseInsensitive } from "src/app/global/util/funcoes";

@Component({
  selector: "sgeap-listar-cidadao",
  templateUrl: "./listar-cidadao.component.html",
  styleUrls: ["./listar-cidadao.component.css"],
})
export class ListarCidadaoComponent extends ListarGenericsComponent<Cidadao> {
  public usuarioFiltro = new Cidadao();

  nomeFiltro = new FormControl("");
  cpfFiltro = new FormControl("");
  emailFiltro = new FormControl("");
  orgaoFiltro = new FormControl("");
  perfilFiltro = new FormControl("");
  filterValues = {
    nome: "",
    cpf: "",
    email: "",
    perfil: "",
    orgao: "",
  };

  constructor(
    public apiService: CidadaoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService
  ) {
    super(apiService, messageService, sharedService, toastr, router, "usuario", ["nome", "cpf", "email", "orgao", "perfil", "editar"]);
  }

  afterResponseListar() {
    this.configurarFiltros();
    this.dataSource.filterPredicate = this.createFilter();
  }

  private configurarFiltros(): void {
    this.nomeFiltro.valueChanges.subscribe((nome) => {
      this.filterValues.nome = nome;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.cpfFiltro.valueChanges.subscribe((cpf) => {
      this.filterValues.cpf = cpf;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.emailFiltro.valueChanges.subscribe((email) => {
      this.filterValues.email = email;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.orgaoFiltro.valueChanges.subscribe((orgao) => {
      this.filterValues.orgao = orgao;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this.perfilFiltro.valueChanges.subscribe((perfil) => {
      this.filterValues.perfil = perfil;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const pesquisa = JSON.parse(filter);
      const nomeValido = !pesquisa.nome || comparaCaseInsensitive(data.nome, pesquisa.nome);
      const cpfValido = !pesquisa.cpf || comparaCaseInsensitive(data.cpf, pesquisa.cpf);
      const emailValido = !pesquisa.email || (data.email && comparaCaseInsensitive(data.email, pesquisa.email));
      const orgaoValido = !pesquisa.orgao || (data.orgao && comparaCaseInsensitive(data.orgao, pesquisa.orgao));
      const perfilValido = !pesquisa.perfil || (data.perfil && comparaCaseInsensitive(data.perfil.nome, pesquisa.perfil));

      return nomeValido && cpfValido && emailValido && orgaoValido && perfilValido;
    };

    return filterFunction;
  }
}
