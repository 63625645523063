import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { MaterialModule } from "src/app/material";
import { TitleViewComponent } from "./title-view.component";

@NgModule({
  imports: [ CommonModule, MaterialModule, ReactiveFormsModule, FormsModule ],
  declarations: [ TitleViewComponent],
  exports: [TitleViewComponent],
})
export class TitleViewModule { }
