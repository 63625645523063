import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { DocumentoService } from "../../api/documento.service";
import { MessageService } from "src/app/global/message/message.service";
import { Assinatura } from "../../models/assinatura.model";
import { Processo } from "../../models/processo.model";
import { DialogConfirmarComponent } from "../dialog-confirmar/dialog-confirmar.component";

@Component({
  selector: "app-documento-assinaturas",
  templateUrl: "./documento-assinaturas.component.html",
  styleUrls: ["./documento-assinaturas.component.css"],
})
export class DocumentoAssinaturasComponent implements OnInit, OnChanges {
  public displayedAssinaturasColumns: string[] = ["responsavel", "datahoraIndicacao", "assinado", "datahoraAssinatura", "delete"];
  @Input() processo: Processo;
  @Input() assinaturas: Assinatura[] = [];
  @Input() documento: any;
  public dataSourceAssinaturas: MatTableDataSource<any>;

  constructor(private documentoService: DocumentoService, private messageService: MessageService, public dialog: MatDialog) {}

  ngOnInit() {
    this.dataSourceAssinaturas = new MatTableDataSource<any>(this.assinaturas);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataSourceAssinaturas) {
      this.dataSourceAssinaturas.data = this.assinaturas;
    }
  }

  deleteAssinador(assinador) {
    if (assinador.dataAssinado) {
      this.messageService.messageErro("Essa pessoa já assinou o documento, não pode ser excluída.");
      return;
    }
    if (
      this.documento.tipoDocumento.qtdeMinimaAssinatura > this.assinaturas.length - 1 ||
      this.documento.tipoDocumento.qtdeExataAssinatura > this.assinaturas.length - 1
    ) {
      this.msgQuantidadeMinimaAssinadores(assinador);
    } else {
      this.deleteConfirm(assinador);
    }
  }

  deleteConfirm(assinador) {
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: "Realmente deseja excluir o assinador da lista?",
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.documento.listAssinatura = this.assinaturas.filter((obj) => obj.id != assinador.id);
        this.documentoService.put(this.documento).subscribe(
          (res) => {
            this.messageService.messageSuccess("Assinador excluído com sucesso!");
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    });
  }

  msgQuantidadeMinimaAssinadores(assinador) {
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data:
        "Necessário o mínimo de " +
        (this.documento.tipoDocumento.qtdeMinimaAssinatura
          ? this.documento.tipoDocumento.qtdeMinimaAssinatura
          : this.documento.tipoDocumento.qtdeExataAssinatura) +
        " assinador(es). Realmente deseja excluir o assinador da lista? Será necessário adicionar outro assinador na lista",
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.documento.listAssinatura = this.assinaturas.filter((obj) => obj.id != assinador.id);
        this.documentoService.put(this.documento).subscribe(
          (res) => {
            this.messageService.messageSuccess("Assinador excluído com sucesso!");
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    });
  }
}
