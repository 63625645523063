import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DialogProcessoComponent } from "../dialog-processo/dialog-processo.component";
import { Situacao } from "../../models/situacao.model";
import { SituacaoService } from "../../api/situacao.service";
import { ProcessoService } from "../../api/processo.service";
import { MessageService } from "../../../global/message/message.service";
import { MovimentacaoDTO } from "../../models/movimentacao-dto.model";
import { TipoArquivamentoEnumAll } from "../../models/tipo-arquivamento-enum.model";
import { Processo } from "../../models/processo.model";
import { Utils } from "src/app/global/util/utils-component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-dialog-alterar-situacao",
  templateUrl: "./dialog-alterar-situacao.component.html",
  styleUrls: ["./dialog-alterar-situacao.component.css"],
})
export class DialogAlterarSituacaoComponent implements OnInit {
  public situacoes: Situacao[] = [];
  public situacao: Situacao;
  public observacao: string;
  public disableTipoArquivamento: boolean = true;
  public tipoArquivamento: any;
  public tipoArquivamentoList: any[] = TipoArquivamentoEnumAll;

  constructor(
    public dialogRef: MatDialogRef<DialogProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Processo,
    private processoService: ProcessoService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {
    this.situacao = new Situacao();
  }

  ngOnInit() {
    this.situacoes = Object.assign([], this.data.faseAtual.listSituacao);

    if (this.situacoes.findIndex((sit) => sit.id === this.data.faseAtual.situacaoInicial.id) === -1) {
      this.situacoes.push(this.data.faseAtual.situacaoInicial);
    }
    if (this.situacoes.findIndex((sit) => sit.id === this.data.faseAtual.situacaoPosVisualizacao.id) === -1) {
      this.situacoes.push(this.data.faseAtual.situacaoPosVisualizacao);
    }

    this.situacoes = this.situacoes.filter((situacao) => situacao.id !== this.data.situacaoAtual.id && situacao.ativo);
    Utils.ordernarDescricaoOrdemAlfabetica(this.situacoes);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectSituacao(): void {
    if (this.situacao.situacaoFinal) {
      this.disableTipoArquivamento = false;
    } else {
      this.disableTipoArquivamento = true;
      this.tipoArquivamento = null;
    }
  }

  alterarSituacao() {
    if (this.validar()) {
      const movimentacaoDTO = new MovimentacaoDTO();
      movimentacaoDTO.situacaoId = this.situacao.id;
      movimentacaoDTO.docAutor = this.sharedService.decodePayloadJWT().sub;
      movimentacaoDTO.observacao = this.observacao;
      if (this.situacao.situacaoFinal && this.tipoArquivamento && this.tipoArquivamento) {
        movimentacaoDTO.tipoArquivamentoId = this.tipoArquivamento.id;
      } else {
        movimentacaoDTO.tipoArquivamentoId = null;
      }
      this.processoService.movimentar(movimentacaoDTO, this.data.id).subscribe(
        (res) => {
          this.messageService.messageSuccess("Situação alterada com sucesso", res.etiquetaProcessoViproc);
          this.data.listMovimentacao = res.listMovimentacao;
          this.dialogRef.close(this.data);
        },
        (error) => {
          this.messageService.messageErro(error.error.message, "Erro ao mudar situação");
          throw error;
        }
      );
    }
  }

  validar(): boolean {
    let validacao = true;

    if (!this.situacao.id) {
      this.messageService.messageErro("Selecione o campo Situação.");
      validacao = false;
    }
    return validacao;
  }
}
