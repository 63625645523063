import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { SituacaoService } from "src/app/core/api/situacao.service.js";
import { Fase } from "src/app/core/models/fase.model";
import { FaseService } from "src/app/core/api/fase.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { MessageService } from "src/app/global/message/message.service";
import { AcaoMudancaSituacaoEnumAll } from "src/app/core/models/acao-mudanca-situacao-enum.model";
import { LotacaoSecundaria } from "src/app/core/models/lotacao-secundaria.model";
import { LotacaoSecundariaService } from "src/app/core/api/lotacao-secundaria.service";
import { ContribuinteService } from "src/app/core/api/contribuinte.service";
import { Observable, EMPTY } from "rxjs";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { MatDialog } from "@angular/material";
import { SisSegService } from "src/app/core/api/sis-seg.service";
import { Usuario } from "src/app/core/models/usuario.model";
import { Pessoa } from "src/app/core/models/pessoa.model";
import { Domain } from "src/app/global/models/domain.model";
import { Utils } from "src/app/global/util/utils-component";

@Component({
  selector: "app-salvar-lotacao-secundaria",
  templateUrl: "./salvar-lotacao-secundaria.component.html",
  styleUrls: ["./salvar-lotacao-secundaria.component.css"],
})
export class SalvarLotacaoSecundariaComponent extends SalvarGenericsComponent<LotacaoSecundaria> implements OnInit {
  searchRespCtrl = new FormControl();
  isLoading = false;
  errorMsg: string;
  responsaveis: any[];
  lotacoesSelecionadas: LotacaoSecundaria[] = [];
  usuarioSelecionado: Pessoa;
  modoEditor = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: LotacaoSecundariaService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public situacaoService: SituacaoService,
    public orgaoService: OrgaoService,
    public messageService: MessageService,
    public contribuinteService: ContribuinteService,
    public SisSegService: SisSegService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "lotacao-secundaria", "");
  }

  ngOnInit() {
    this.autocompleteNomeParte();
  }

  afterResponse() {
    this.modoEditor = true;
    const matricula = this.domain[0].usuario;
    //this.messageService.messageWarning("Implementar consulta de servidores por documento.");
    this.SisSegService.consultarServidorByDocumento(matricula).subscribe(
      (res) => {
        this.usuarioSelecionado = res;
        this.searchRespCtrl.setValue(this.usuarioSelecionado.nome);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.lotacoesSelecionadas = this.domain[0].listLotacao.map((lotacao) => lotacao.orgaoLocal);
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!this.usuarioSelecionado.matricula) {
      this.messageService.messageErro("Preencha o campo nome.");
      validacao = false;
    }

    if (this.lotacoesSelecionadas.length == 0) {
      if (this.modoEditor) {
        this.messageService.messageInfo("Você atualizou o Usuário sem nenhuma lotação secundária");
      } else {
        this.messageService.messageErro("Adicione uma lotação.");
        validacao = false;
      }
    }

    if (this.usuarioSelecionado.orgaoLocal && this.checkLotacaoPrincipalPresente()) {
      this.messageService.messageErro("Não é possível adicionar Lotação Principal como Secundária.");
      validacao = false;
    }
    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.checkSalvar();
    }
  }

  private autocompleteNomeParte() {
    this.searchRespCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.responsaveis = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.buscarResponsavelPorParteNome(value.replace(/[^a-zA-Z ]/g, "")).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          this.responsaveis = data;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );

    // if(this.responsaveis){
    //   Utils.ordernarNomeOrdemAlfabetica(this.responsaveis)
    // }
  }

  private buscarResponsavelPorParteNome(parteNome: string): Observable<any> {
    if (parteNome !== undefined && parteNome !== null && parteNome.trim() !== "") {
      this.messageService.messageWarning("Implementar consulta de servidores por nome.");
      // return this.SisSegService.pesquisarServidorPorNome(parteNome.trim());
    }
    return EMPTY;
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.selecionaLotacao(processoParam);
      }
    });
  }

  selecionaLotacao(lotacaoSelecionada: any) {
    if (this.lotacoesSelecionadas.filter((lotacao) => lotacao.id == lotacaoSelecionada.id).length == 0) {
      this.lotacoesSelecionadas.push(lotacaoSelecionada);
    }
  }

  removerLotacao(lotacaoSelecionada: any) {
    this.lotacoesSelecionadas = this.lotacoesSelecionadas.filter((lotacao) => lotacao.id != lotacaoSelecionada.id);
  }

  setResponsavel(responsavel: any) {
    this.usuarioSelecionado = responsavel;
  }

  checkLotacaoPrincipalPresente() {
    if (this.lotacoesSelecionadas.filter((lotacao) => lotacao.id == this.usuarioSelecionado.orgaoLocal.id).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public salvar(): void {
    this.apiService
      .salvar(
        this.usuarioSelecionado.matricula,
        this.lotacoesSelecionadas.map(function (lotacao) {
          return lotacao.id;
        })
      )
      .subscribe(
        (res) => {
          if (res.length > 0) {
            this.messageService.messageSuccess("Registro salvo com sucesso.");
            this.router.navigate([`${this.contexto}/${this.endpoint}`]);
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public editar(): void {
    this.apiService
      .atualizar(
        this.usuarioSelecionado.matricula,
        this.lotacoesSelecionadas.map(function (lotacao) {
          return lotacao.id;
        })
      )
      .subscribe(
        (res) => {
          this.messageService.messageSuccess("Registro atualizado com sucesso.");
          this.router.navigate([`${this.contexto}/${this.endpoint}`]);
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  private checkSalvar() {
    if (this.modoEditor) {
      this.editar();
    } else {
      this.salvar();
    }
  }
}
