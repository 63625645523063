import { Domain } from "../../global/models/domain.model";
import { Assunto } from "./assunto.model";
import { DocumentoDTO } from "./documento-dto.model";
import { MetadadoValor } from "./metadado-valor.model";
import { SolicitanteDTO } from "./solicitante-dto.model";
import { Pessoa } from "./pessoa.model";
import { Solicitante } from "./pensao/solicitante.model";
import { Instituidor } from "./pensao/instituidor.model";
import { Requerente } from "./pensao/requerente.model";

// Classe não representa o mesmo objeto ProcessoDTO do backend
export class ProcessoDTO extends Domain {
  public assunto: Assunto;
  public descricao: string;
  public observacao: string;
  public representante: SolicitanteDTO;
  public autor: SolicitanteDTO;
  public solicitacaoCadastro: string;
  public listDocumento: DocumentoDTO[];
  public listMetadadoValor: MetadadoValor[];
  public modeloDocumento: string;
  public lotacaoAtual: string;
  public responsavelAtual: string;
  public metadadoValorDae: MetadadoValor;
  public lotacaoResponsavel: number;
  public pessoaReceberProcesso: Pessoa;
  public lotacaoCexatNuat: number;
  public assinadorPrincipal: string;
  public solicitante: Solicitante;
  public instituidor: Instituidor;
  public requerentes: Requerente[];
  public criadoViaIntegracao: Boolean;
  public usuarioViproc: string;
  public senhaViproc: string;
  public orgaoUsuarioLogado: string;
  public idOrgaoUsuarioLogado: number;
}