export enum TipoDistribuicaoEnum {
  ALEATORIA = ("Distribuição Aleatória"),
  GRUPO = ("Grupo"),
  RECEBEDOR_PADRAO = ("Recebedor Padrão"),
  CEXAT_NUAT = ("CEXAT NUAT")
}

export const TipoDistribuicaoEnumAll = [
  { id: 0, descricao: TipoDistribuicaoEnum.ALEATORIA },
  { id: 1, descricao: TipoDistribuicaoEnum.GRUPO },
  { id: 2, descricao: TipoDistribuicaoEnum.RECEBEDOR_PADRAO },
  { id: 3, descricao: TipoDistribuicaoEnum.CEXAT_NUAT }
];