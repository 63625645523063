
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
 import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Solicitante } from "../models/pensao/solicitante.model";
import { Instituidor } from "../models/pensao/instituidor.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class InstituidorService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "instituidor", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  salvar(instituidor: Instituidor){
    return this.http.post(`${this.API_URL}${this.resource}`, instituidor)
       .pipe(map(response => response));
  }

  pesquisarPorProcesso(idProcesso: number){
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-por-processo/${idProcesso}`)
            .pipe(map(response => response));
  }

}