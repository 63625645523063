import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class MeuPerfilService {
  private menuItemClickSubject = new Subject<string>();

  menuItemClick(itemText: string) {
    this.menuItemClickSubject.next(itemText);
  }

  getMenuClickObservable() {
    return this.menuItemClickSubject.asObservable();
  }
}
