import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { EnderecoService } from "../../api/endereco.service";
import { ProcessoService } from "../../api/processo.service";
import { SolicitanteService } from "../../api/solicitante.service";
import { Endereco } from "../../models/pensao/endereco.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { TipoRepresentanteEnumAll, TipoRepresentanteFunctions } from "../../models/pensao/tipo-representante.enum";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { Instituidor } from "../../models/pensao/instituidor.model";

@Component({
  selector: "app-visualizar-solicitante",
  templateUrl: "./visualizar-solicitante.component.html",
  styleUrls: ["./visualizar-solicitante.component.css"],
})
export class VisualizarSolicitanteComponent implements OnInit {
  data = this.transfereService.getData();
  @Output() solicitanteEventEmitter = new EventEmitter<any>();
  @Input() solicitante: Solicitante = new Solicitante();
  public instituidor: Instituidor = new Instituidor();
  public tiposRepresentante: any[] = TipoRepresentanteEnumAll;
  public formGroupSolicitante: FormGroup;
  public ufs = Utils.getUfs();
  public atualizarListaDocumentos: boolean = false;
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];

  @Input() disabledValue: true;
  myGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private transfereService: TransfereService,
    private solicitanteService: SolicitanteService,
    private processoService: ProcessoService,
    private enderecoService: EnderecoService
  ) {
    this.solicitante.endereco = new Endereco();
    this.inicializarValidacoesForm();
  }

  ngOnInit() {
    this.solicitante.endereco = new Endereco();
  }
  getDescricaoTipoRepresentante(idTipoRepresentante: string) {
    return TipoRepresentanteFunctions.getDescricaoPorId(idTipoRepresentante);
  }

  carregarDocumentosSolicitante() {
    if (this.formGroupSolicitante.invalid) return;

    if (this.solicitante.representanteLegal === false) {
      this.solicitante.tipoSolicitante = null;
      return;
    }

    if (!this.solicitante.representanteLegal || this.solicitante.tipoSolicitante === undefined) return;

    this.solicitante.documentosRegra = [];

    this.validarProcessoMotorRegras();
  }

  validarProcessoMotorRegras() {
    let regrasProcessos = this.montarRegraProcesso();

    this.processoService.regrasProcesso(regrasProcessos).subscribe(
      (res) => {
        this.solicitante.documentosRegra = res.processos[0].solicitante.documentosObrigatorios;
        this.atualizarListaDocumentos = true;
        this.emitSolicitante();
      },
      (error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos necessários do solicitante");
        throw error;
      }
    );
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regrasProcessos: RegrasProcesso[] = [];
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    this.solicitante.solicitanteTipo = this.solicitante.tipoSolicitante;
    regraProcesso.solicitante = this.solicitante;

    regrasProcessos.push(regraProcesso);

    return regrasProcessos;
  }
  validaCamposInstituidor() {}

  inicializarValidacoesForm() {
    this.formGroupSolicitante = this.fb.group({
      requerente: ["", Validators.required],
      representanteLegal: ["", Validators.required],
      tipoRepresentante: new FormControl({ value: "", disabled: true }, Validators.required),
      nome: ["", Validators.required],
      sexo: ["", Validators.required],
      dataNascimento: ["", Validators.required],
      cpf: ["", Validators.required],
      rg: ["", Validators.required],
      orgaoEmissor: ["", Validators.required],
      rgUf: ["", Validators.required],
      estadoCivil: ["", Validators.required],
      telefone: ["", Validators.required],
      email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$")]),
      logradouro: ["", Validators.required],
      numero: ["", Validators.required],
      complemento: ["", Validators.nullValidator],
      bairro: ["", Validators.required],
      cep: ["", Validators.required],
      cidade: ["", Validators.required],
      uf: ["", Validators.required],
    });
  }

  emitSolicitante() {
    let retorno = {
      // solicitante: (this.formGroupSolicitante.valid) ? this.solicitante : null,
      solicitante: this.solicitante,
      validacaoFormulario: this.formGroupSolicitante.valid,
      atualizarListaDocumentos: this.atualizarListaDocumentos,
    };

    if (this.atualizarListaDocumentos) this.atualizarListaDocumentos = false;

    this.solicitanteEventEmitter.emit(retorno);
  }

  salvar() {
    this.solicitanteService.salvar(this.solicitante).subscribe(
      (solicitanteSalvo: Solicitante) => {
        this.solicitante.id = solicitanteSalvo.id;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  pesquisarEndereco(cep: string) {
    if (cep.length === 9) {
      this.enderecoService.pesquisarEnderecoPorCep(cep).subscribe(
        (endereco: any) => {
          if (endereco != null) {
            this.solicitante.endereco.logradouro = endereco.logradouro;
            this.solicitante.endereco.bairro = endereco.bairro;
            this.solicitante.endereco.cidade = endereco.localidade;
            this.solicitante.endereco.uf = endereco.uf;
          }
          if (endereco.erro) {
            this.messageService.messageWarning("Atenção! CEP não encontrado");
          }
        },
        (error) => {
          this.messageService.messageErro("Não foi possivel carregar o endereço");
          throw error;
        }
      );
    }
  }
}
