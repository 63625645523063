import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from "@angular/material";

@Component({
  selector: "app-dialog-itens-nao-similaridade",
  templateUrl: "./dialog-itens-nao-similaridade.component.html",
  styleUrls: ["./dialog-itens-nao-similaridade.component.css"]
})
export class DialogItenNaoSimilaridadeComponent {
  
  public dataSource: MatTableDataSource<any>;
  public listItens: any[];
  public displayedColumns = ["ncm", "descricao", "custo"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public dialogRef: MatDialogRef<DialogItenNaoSimilaridadeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]) {
    this.listItens = data;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.listItens);
    this.dataSource.paginator = this.paginator;
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  getNcmFormatado(ncm){
    while (ncm.length < 8) ncm = "0" + ncm;
    return ncm;
  }s

  getFormattedPrice(price: string) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(price.replace(/\D/g,''))/100);
  }

}
