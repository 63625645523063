import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/core";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { FiscalPreferencia } from "src/app/core/models/fiscal-preferencia.model";
import { FiscalPreferenciaService } from "src/app/core/api/fiscal-preferencia.service";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { Observable, EMPTY } from "rxjs";
import { ContribuinteService } from "src/app/core/api/contribuinte.service";
import { Usuario } from "src/app/core/models/usuario.model";
import { SisSegService } from "src/app/core/api/sis-seg.service";

@Component({
  selector: "app-salvar-fiscal-preferencia",
  templateUrl: "./salvar-fiscal-preferencia.component.html",
  styleUrls: ["./salvar-fiscal-preferencia.component.css"],
})
export class SalvarFiscalPreferenciaComponent extends SalvarGenericsComponent<FiscalPreferencia> {
  searchRespCtrl = new FormControl();
  isLoading = false;
  errorMsg: string;
  responsaveis: any[];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: FiscalPreferenciaService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public contribuinteService: ContribuinteService,
    public sisSegService: SisSegService
  ) {
    super(formBuilder, router, apiService, messageService, route, "fiscal-preferencia", "");
  }

  ngOnInit() {
    this.autocompleteNomeParte();
  }

  afterResponse() {
    this.sisSegService.consultarServidorByDocumento(this.domain.documentoUsuario).subscribe(
      (res) => {
        this.domain.usuario = res;
        this.searchRespCtrl.setValue(this.domain.usuario.nome);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.usuario || !domain.usuario.trim()) {
      this.messageService.messageErro("Preencha o campo nome.");
      validacao = false;
    }
    if (domain.limiteProcessoDistribuicao == null) {
      this.messageService.messageErro("Preencha o campo Qtd. Limite.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.domain.documentoUsuario = this.domain.usuario.documento;
      this.submeter();
    }
  }

  private autocompleteNomeParte() {
    this.searchRespCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.responsaveis = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.buscarServidorPorNome(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          this.responsaveis = data;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  private buscarServidorPorNome(parteNome: string): Observable<any> {
    if (parteNome !== undefined && parteNome !== null && parteNome.trim() !== "") {
      return this.sisSegService.pesquisarServidorPorNome(parteNome.trim());
    }
    return EMPTY;
  }

  setNomeServidor(documento: string) {
    this.domain.usuario = new Usuario();
    this.domain.usuario.documento = documento;
  }
}
