
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "../../../core";
import { LacunaWebPKI } from "web-pki";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoginTO } from "../../models/login-to.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class AutenticacaoService extends ApiService {
  API_URL: string;
  // public certificates: any;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "autenticar", config);
    this.API_URL = config.API_SISSEG_URL;
  }

  // autenticar(loginTO: LoginTO): Observable<any> {
  //   // return this.post({ "username": loginTO.username, "password": loginTO.password });
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/x-www-form-urlencoded');

  //   return this.http.post(
  //     `${this.API_URL}${this.resource}`, null
  //     encodeURI(`username=${loginTO.username}&password=${loginTO.password}`),
  //     { 
  //       headers,
  //       params: {
  //         username :loginTO.username,
  //         password: loginTO.password
  //       }
  //     }
  //   );
  // }

  autenticar(loginTO: LoginTO): Observable<any> {  
    return this.http.post(
      `${this.API_URL}${this.resource}/usuario-sgeap`,
      {documento: loginTO.username,  password: loginTO.password}
    );
  }
  

  gerarToken(tokenFaceId: string): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/token`, { token: tokenFaceId });
  }


  // public getCertificates():  any {

  //   this.pki.init({
  //       ready: this.onWebPkiReady,
  //       notInstalled: this.onWebPkiNotInstalled,
  //       defaultFail: this.onWebPkiFail
  //   });

  // }

  // createAuthorizationHeader(headers: Headers, certificate: any) {
  //   headers.append("Authorization", certificate);
  // }

  // public login(certificate: any): Observable<any> {
  //   // tslint:disable-next-line: max-line-length
  //   return this.http.post(`${this.API_URL}${this.resource}/validacertificado`, { }, { headers: { "sslclientcert": certificate }, observe: "response"} )
  //     .pipe(map(response => response));
  // }

  // onWebPkiNotInstalled(status, message) {
  //   console.error("onWebPkiNotInstalled" + status + message);
  // }

  // onWebPkiFail(ex) {
  //   console.error("Web PKI error from " + ex.origin + ": " + ex.message + "(" + ex.code + ")");
  // }

  // onWebPkiReady(): any {
  //     let certificates: any;
  //     this.pki = new LacunaWebPKI(config.LAKUNA_CERT_KEY);
  //     this.pki.listCertificates({
  //   selectId: "certificateSelect"
  // }).success(function (certs) {
  //       certificates = certs;
  //     }).fail(e => console.error(e));

  //     this.certificates = certificates;
  //  }

  //  getCertText(cert) {
  //   return cert.subjectName + " (issued by " + cert.issuerName + ")";
  // }
}
