import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSlideToggleModule, MatTableModule } from "@angular/material";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MaterialModule } from "src/app/material";
import { UiModule } from "src/app/ui/ui.module";
import { CidadaoModule } from "./cidadao/cidadao.module";
import { ListarPerfilComponent } from './perfil/listar-perfil/listar-perfil.component';
import { ManterPermissoesComponent } from './perfil/manter-permissoes/manter-permissoes.component';
import { SalvarPerfilComponent } from './perfil/salvar-perfil/salvar-perfil.component';
import { ListarPermissaoComponent } from "./permissao/listar-permissao/listar-permissao.component";
import { SalvarPermissaoComponent } from "./permissao/salvar-permissao/salvar-permissao.component";
import { SegurancaHomeComponent } from "./seguranca-home/seguranca-home.component";
import { SegurancaRoutingModule } from "./seguranca-routing.module";

@NgModule({
    declarations: [
        ListarPerfilComponent,
        SalvarPerfilComponent,
        ListarPermissaoComponent,
        SalvarPermissaoComponent,
        SegurancaHomeComponent,
        ManterPermissoesComponent
    ],
    imports: [
        UiModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MaterialModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        AngularSvgIconModule,
        CidadaoModule,
        SegurancaRoutingModule
    ]
})
export class SegurancaModule { }