import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { EmailTemplate } from "src/app/core/models/email-template.model";
import { ParametroDiverso } from "src/app/core/models/parametro-diverso.model";
import { TipoValorParametroEnumAll } from "src/app/core/models/tipo-valor-parametro-enum.model";
import { ParametroDiversoService } from "src/app/core/api/parametro-diverso.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { NumeroDocumentoDetalhe } from "src/app/core/models/numero-documento-detalhe.model";
import { NumeroDocumentoDetalheService } from "src/app/core/api/numero-documento-detalhe.service";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";
import { MessageService } from "src/app/global/message/message.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-numero-documento-detalhe",
  templateUrl: "./listar-numero-documento-detalhe.component.html",
  styleUrls: ["./listar-numero-documento-detalhe.component.css"]
})
export class ListarNumeroDocumentoDetalheComponent extends ListarGenericsComponent<NumeroDocumentoDetalhe> {

  public dataSource: MatTableDataSource<NumeroDocumentoDetalhe>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public apiService:  NumeroDocumentoDetalheService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "numero-documento-detalhe",
      ["tipoDocumento", "ano", "numerosReservados", "numeroAtual", "usuarioCriacao", "dataCriacao", "acoes"]);
  }

  private formatDate(data: Date) {
    const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;
    return "" + dd + "-" + mm + "-" + y;
  }

}
