export enum TipoRequerente {
  CONJUGE = "Cônjuge",
  COMPANHEIRO = "Companheiro(a)",
  PAI = "Pai",
  MAE = "Mãe",
  TUTELADO_MENOR = "Tutelado Menor",
  ENTEADO_MENOR = "Enteado Menor",
  IRMAO_MENOR_21_ANOS = "Irmão Menor 21 anos",
  FILHO_MENOR_21_ANOS = "Filho Menor  21 anos",
  MENOR_SOB_GUARDA = "Menor Sob Guarda",
  SUCESSOR_OU_HERDEIRO = "Sucessor/Herdeiro",
  EX_CONJUGE_PENSAO_ALIMENTICIA = "Ex Cônjuge com Pensão Alimentícia",
  EX_COMPANHEIRO_PENSAO_ALIMENTICIA = "Ex Companheiro(a)  com Pensão Alimentícia",
  MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE = "Menor Sob Guarda Invalido / Com Deficiência",
  CONJUGE_INVALIDO_OU_DEFICIENTE = "Cônjuge Invalido  / Com Deficiência",
  COMPANHEIRO_INVALIDO_OU_DEFICIENTE = "Companheiro(a) Inválido / Com Deficiência",
  IRMAO_INVALIDO_OU_DEFICIENTE = "Irmão Inválido / Com Deficiência",
  FILHO_INVALIDO_OU_DEFICIENTE = "Filho Inválido",
  TUTELADO_INVALIDO_OU_DEFICIENTE = "Tutelado Inválido / Com Deficiência",
  ENTEADO_INVALIDO_OU_DEFICIENTE = "Enteado Inválido",
  MENOR_21_ANOS_SOB_GUARDA_INVALIDO = "Menor de 21 anos sob guarda inválido",
  FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Filho menor de 21 anos, ou até 24 anos se universitário",
  ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Enteado menor de 21 anos, ou até 24 anos se universitário",
  MENOR_21_ANOS_SOB_TUTELA_INVALIDO = "Menor de 21 anos sob tutela inválido",
  MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA = "Menor de 21 anos sob guarda, ou até 24 anos se universitário",
  IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Irmão órfão menor de 21 anos, ou até 24 anos se universitário",
  IRMAO_ORFAO_INVALIDO = "Irmão órfão inválido",
  MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA = "Menor de 21 anos sob tutela, ou até 24 anos se universitário",
}

export const TipoRequerenteEnumAll = [
  { id: "filho-menor-21-anos", descricao: TipoRequerente.FILHO_MENOR_21_ANOS, enum: "FILHO_MENOR_21_ANOS" },
  { id: "irmao-invalido-ou-deficiente", descricao: TipoRequerente.IRMAO_INVALIDO_OU_DEFICIENTE, enum: "IRMAO_INVALIDO_OU_DEFICIENTE" },
  { id: "filho-invalido-ou-deficiente", descricao: TipoRequerente.FILHO_INVALIDO_OU_DEFICIENTE, enum: "FILHO_INVALIDO_OU_DEFICIENTE" },
  {
    id: "tutelado-invalido-ou-deficiente",
    descricao: TipoRequerente.TUTELADO_INVALIDO_OU_DEFICIENTE,
    enum: "TUTELADO_INVALIDO_OU_DEFICIENTE",
  },
  {
    id: "enteado-invalido-ou-deficiente",
    descricao: TipoRequerente.ENTEADO_INVALIDO_OU_DEFICIENTE,
    enum: "ENTEADO_INVALIDO_OU_DEFICIENTE",
  },
  { id: "conjuge", descricao: TipoRequerente.CONJUGE, enum: "CONJUGE" },
  { id: "companheiro", descricao: TipoRequerente.COMPANHEIRO, enum: "COMPANHEIRO" },
  { id: "ex-conjuge-pensao-alimenticia", descricao: TipoRequerente.EX_CONJUGE_PENSAO_ALIMENTICIA, enum: "EX_CONJUGE_PENSAO_ALIMENTICIA" },
  {
    id: "ex-companheiro-pensao-alimenticia",
    descricao: TipoRequerente.EX_COMPANHEIRO_PENSAO_ALIMENTICIA,
    enum: "EX_COMPANHEIRO_PENSAO_ALIMENTICIA",
  },
  { id: "pai", descricao: TipoRequerente.PAI, enum: "PAI" },
  { id: "mae", descricao: TipoRequerente.MAE, enum: "MAE" },
  { id: "tutelado-menor", descricao: TipoRequerente.TUTELADO_MENOR, enum: "TUTELADO_MENOR" },
  { id: "enteado-menor", descricao: TipoRequerente.ENTEADO_MENOR, enum: "ENTEADO_MENOR" },
  { id: "irmao-menor-21-anos", descricao: TipoRequerente.IRMAO_MENOR_21_ANOS, enum: "IRMAO_MENOR_21_ANOS" },
  { id: "menor-sob-guarda", descricao: TipoRequerente.MENOR_SOB_GUARDA, enum: "MENOR_SOB_GUARDA" },
  {
    id: "menor-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE,
    enum: "MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE",
  },
  { id: "conjuge-invalido", descricao: TipoRequerente.CONJUGE_INVALIDO_OU_DEFICIENTE, enum: "CONJUGE_INVALIDO_OU_DEFICIENTE" },
  {
    id: "companheiro-invalido",
    descricao: TipoRequerente.COMPANHEIRO_INVALIDO_OU_DEFICIENTE,
    enum: "COMPANHEIRO_INVALIDO_OU_DEFICIENTE",
  },
  { id: "sucesssor-herdeiro", descricao: TipoRequerente.SUCESSOR_OU_HERDEIRO, enum: "SUCESSOR_OU_HERDEIRO" },
];

export const TipoRequerenteMenorInvalidoEnumAll = [
  { id: "filho-menor-21-anos", descricao: TipoRequerente.FILHO_MENOR_21_ANOS, enum: "FILHO_MENOR_21_ANOS" },
  { id: "irmao-invalido-ou-deficiente", descricao: TipoRequerente.IRMAO_INVALIDO_OU_DEFICIENTE, enum: "IRMAO_INVALIDO_OU_DEFICIENTE" },
  { id: "filho-invalido-ou-deficiente", descricao: TipoRequerente.FILHO_INVALIDO_OU_DEFICIENTE, enum: "FILHO_INVALIDO_OU_DEFICIENTE" },
  {
    id: "tutelado-invalido-ou-deficiente",
    descricao: TipoRequerente.TUTELADO_INVALIDO_OU_DEFICIENTE,
    enum: "TUTELADO_INVALIDO_OU_DEFICIENTE",
  },
  {
    id: "enteado-invalido-ou-deficiente",
    descricao: TipoRequerente.ENTEADO_INVALIDO_OU_DEFICIENTE,
    enum: "ENTEADO_INVALIDO_OU_DEFICIENTE",
  },

  { id: "tutelado-menor", descricao: TipoRequerente.TUTELADO_MENOR, enum: "TUTELADO_MENOR" },
  { id: "enteado-menor", descricao: TipoRequerente.ENTEADO_MENOR, enum: "ENTEADO_MENOR" },
  { id: "irmao-menor_21_anos", descricao: TipoRequerente.IRMAO_MENOR_21_ANOS, enum: "IRMAO_MENOR_21_ANOS" },
  { id: "menor-sob-guarda", descricao: TipoRequerente.MENOR_SOB_GUARDA, enum: "MENOR_SOB_GUARDA" },
  {
    id: "menor-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE,
    enum: "MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE",
  },
  {
    id: "menor-21-anos-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_GUARDA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_GUARDA_INVALIDO",
  },
  {
    id: "menor-21-anos-sob-tutela-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_TUTELA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_TUTELA_INVALIDO",
  },
  { id: "irmao-orfao-invalido", descricao: TipoRequerente.IRMAO_ORFAO_INVALIDO, enum: "IRMAO_ORFAO_INVALIDO" },
];

export const TipoRequerenteMenorEnumAll = [
  { id: "filho-menor-21-anos", descricao: TipoRequerente.FILHO_MENOR_21_ANOS, enum: "FILHO_MENOR_21_ANOS" },
  { id: "tutelado-menor", descricao: TipoRequerente.TUTELADO_MENOR, enum: "TUTELADO_MENOR" },
  { id: "enteado-menor", descricao: TipoRequerente.ENTEADO_MENOR, enum: "ENTEADO_MENOR" },
  { id: "irmao-menor-21-anos", descricao: TipoRequerente.IRMAO_MENOR_21_ANOS, enum: "IRMAO_MENOR_21_ANOS" },
  { id: "menor-sob-guarda", descricao: TipoRequerente.MENOR_SOB_GUARDA, enum: "MENOR_SOB_GUARDA" },
  {
    id: "menor-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE,
    enum: "MENOR_SOB_GUARDA_INVALIDO_OU_DEFICIENTE",
  },
  {
    id: "menor-21-anos-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_GUARDA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_GUARDA_INVALIDO",
  },
  {
    id: "filho-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  {
    id: "enteado-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  {
    id: "menor-21-anos-sob-tutela-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_TUTELA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_TUTELA_INVALIDO",
  },
  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-guarda",
    descricao: TipoRequerente.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA",
  },
  {
    id: "irmao-orfao-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-tutela",
    descricao: TipoRequerente.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA",
  },
];

export namespace TipoRequerenteEnumFunctions {
  export function verificarMenorOuInvalido(idTipo: string): boolean {
    return TipoRequerenteMenorInvalidoEnumAll.filter((tipo) => tipo.id.toUpperCase() === idTipo.toUpperCase()).length > 0 ? true : false;
  }

  export function verificarMenorEnumOuInvalido(enumValue: string): boolean {
    return TipoRequerenteMenorEnumAll.filter((tipo) => tipo.enum.toLowerCase() === enumValue.toLowerCase()).length > 0 ? true : false;
  }

  export function verificarMenor(idTipo: string): boolean {
    return TipoRequerenteMenorEnumAll.filter((tipo) => tipo.id.toLowerCase() === idTipo.toLowerCase()).length > 0 ? true : false;
  }

  export function verificarMenorPorEnum(enumValue: string): boolean {
    return TipoRequerenteMenorEnumAll.filter((tipo) => tipo.enum.toLowerCase() === enumValue.toLowerCase()).length > 0 ? true : false;
  }

  export function getDescricaoPorId(idTipoRequerente: string) {
    return TipoRequerenteEnumAll.filter(function (tipo) {
      return tipo.enum.toUpperCase() === idTipoRequerente.toUpperCase();
    })[0].descricao;
  }

  export function getDescricaoPorIdMilitar(idTipoRequerente: string) {
    return TipoRequerenteMilitar.filter(function (tipo) {
      return tipo.enum.toUpperCase() === idTipoRequerente.toUpperCase();
    })[0].descricao;
  }
}

export const TipoRequerenteMilitar = [
  { id: "filho-invalido-ou-deficiente", descricao: TipoRequerente.FILHO_INVALIDO_OU_DEFICIENTE, enum: "FILHO_INVALIDO_OU_DEFICIENTE" },
  {
    id: "enteado-invalido-ou-deficiente",
    descricao: TipoRequerente.ENTEADO_INVALIDO_OU_DEFICIENTE,
    enum: "ENTEADO_INVALIDO_OU_DEFICIENTE",
  },
  { id: "conjuge", descricao: TipoRequerente.CONJUGE, enum: "CONJUGE" },
  { id: "companheiro", descricao: TipoRequerente.COMPANHEIRO, enum: "COMPANHEIRO" },
  { id: "ex-conjuge-pensao-alimenticia", descricao: TipoRequerente.EX_CONJUGE_PENSAO_ALIMENTICIA, enum: "EX_CONJUGE_PENSAO_ALIMENTICIA" },
  {
    id: "ex-companheiro-pensao-alimenticia",
    descricao: TipoRequerente.EX_COMPANHEIRO_PENSAO_ALIMENTICIA,
    enum: "EX_COMPANHEIRO_PENSAO_ALIMENTICIA",
  },
  { id: "pai", descricao: TipoRequerente.PAI, enum: "PAI" },
  { id: "mae", descricao: TipoRequerente.MAE, enum: "MAE" },
  {
    id: "menor-21-anos-sob-guarda-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_GUARDA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_GUARDA_INVALIDO",
  },
  {
    id: "filho-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  {
    id: "enteado-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  {
    id: "menor-21-anos-sob-tutela-invalido",
    descricao: TipoRequerente.MENOR_21_ANOS_SOB_TUTELA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_TUTELA_INVALIDO",
  },
  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-guarda",
    descricao: TipoRequerente.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA",
  },
  {
    id: "irmao-orfao-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoRequerente.IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },
  { id: "irmao-orfao-invalido", descricao: TipoRequerente.IRMAO_ORFAO_INVALIDO, enum: "IRMAO_ORFAO_INVALIDO" },
  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-tutela",
    descricao: TipoRequerente.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA",
  },
  { id: "sucesssor-herdeiro", descricao: TipoRequerente.SUCESSOR_OU_HERDEIRO, enum: "SUCESSOR_OU_HERDEIRO" },
];
