import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { QueueMessage } from "src/app/core/models/queue-message.model";
import { ActiveMQService } from "src/app/core/api/activemq.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { environment } from "src/environments/environment";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-mensagens",
  templateUrl: "./listar-mensagens.component.html",
  styleUrls: ["./listar-mensagens.component.css"]
})
export class ListarMensagensComponent extends ListarGenericsComponent<QueueMessage> {

  public dataSource: MatTableDataSource<QueueMessage>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public activemqService: ActiveMQService,
    protected route: ActivatedRoute,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(activemqService, messageService, sharedService, toastr, router, "reprocessar-amq",
      ["conteudo", "falha", "filaOriginal", "acoes"]);
  }
  private nomeFila: string;

  ngOnInit() {
    this.nomeFila = this.route.snapshot.params.nomeFila;
    this.listarMensagens();
  }

  public listarMensagens() {
    this.activemqService.listarMensagens(this.nomeFila).subscribe(
      res => {
        this.dataSource = new MatTableDataSource<QueueMessage>(res);
        this.dataSource.paginator = this.paginator;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public reprocessarMensagem(messageId: string) {
    const message = "Realmente deseja reprocessar essa mensagem?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.activemqService.reprocessarMensagens(this.nomeFila, [messageId]).subscribe(
          res => {
            this.messageService.messageSuccess("Mensagem enviada para reprocessamento.");
            this.router.navigate([environment.CONTEXTO, "reprocessar-amq"]);
            return true;
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });
    return false;
  }

  public excluirMensagem(messageId: string) {
    const message = "Realmente deseja excluir essa mensagem?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.activemqService.excluirMensagens(this.nomeFila, [messageId]).subscribe(
          res => {
            this.messageService.messageSuccess("Mensagem excluída com sucesso.");
            this.router.navigate([environment.CONTEXTO, "reprocessar-amq"]);
            return true;
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });
    return false;
  }

  public isFilaDLQ() {
    return this.nomeFila.startsWith("DLQ.");
  }

}
