import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { SituacaoService } from "src/app/core/api/situacao.service.js";
import { Fase } from "src/app/core/models/fase.model";
import { FaseService } from "src/app/core/api/fase.service";
import { MessageService } from "src/app/global/message/message.service";
import { AcaoMudancaSituacaoEnumAll } from "src/app/core/models/acao-mudanca-situacao-enum.model";
import { Utils } from "src/app/global/util/utils-component";
import { OrgaoService } from "src/app/core/api/orgao.service";

@Component({
  selector: "app-salvar-fase",
  templateUrl: "./salvar-fase.component.html",
  styleUrls: ["./salvar-fase.component.css"],
})
export class SalvarFaseComponent extends SalvarGenericsComponent<Fase> implements OnInit {
  public situacaoInicialList: any[] = [];
  public situacaoPosVisualizacaoList: any[] = [];
  public situacaoList: any[] = [];
  public orgaoLocalList: any[] = [];
  public acaoMudancaSituacaoList: any[] = AcaoMudancaSituacaoEnumAll;
  public buttons = [];
  public sections = [];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: FaseService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public situacaoService: SituacaoService,
    public orgaoService: OrgaoService,
    public messageService: MessageService
  ) {
    super(formBuilder, router, apiService, messageService, route, "fase", "");
  }

  ngOnInit() {
    this.sections = [
      {
        fields: [
          [
            {
              key: "descricao",
              fieldType: "textInput",
              label: "Descrição",
              required: true,
              maxLength: 255,
            },
            {
              key: "situacaoInicial",
              fieldType: "selectInput",
              options: () => this.situacaoInicialList,
              labelKey: "descricao",
              label: "Situação Inicial",
              required: true,
              type: "text",
              maxLength: 255,
            },
            {
              key: "situacaoPosVisualizacao",
              fieldType: "selectInput",
              options: () => this.situacaoPosVisualizacaoList,
              labelKey: "descricao",
              label: "Situação Pós Ação",
              required: true,
              type: "text",
              maxLength: 255,
            },
            {
              key: "acaoMudancaSituacao",
              fieldType: "selectInput",
              options: () => this.acaoMudancaSituacaoList,
              labelKey: "descricao",
              valueKey: "id",
              label: "Ação Mudança Situação",
              required: true,
              type: "text",
              maxLength: 255,
            },
            {
              key: "listSituacao",
              fieldType: "selectInput",
              options: () => this.situacaoList,
              multiple: true,
              labelKey: "descricao",
              label: "Situações",
              required: true,
              type: "text",
              maxLength: 255,
            },
            {
              key: "tipoFase",
              fieldType: "radioInput",
              cols: [12, "auto", "auto", "auto", "auto"],
              options: [
                { label: "Interna", value: 0 },
                { label: "Externa", value: 1 },
              ],
              label: "Tipo Fase*",
            },
            {
              key: "ativo",
              fieldType: "radioInput",
              cols: [12, "auto", "auto", "auto", "auto"],
              options: [
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ],
              label: "Ativo*",
            },
          ],
        ],
      },
    ];

    this.buttons = [
      {
        label: "Salvar",
        click: () => this.salvarValidator(this.domain),
        color: "primary",
      },
      {
        label: "Cancelar",
        click: () => this.router.navigateByUrl("/sgeap/fase"),
        color: "secondary",
      },
    ];

    this.situacaoService.listarAtivas().subscribe(
      (situacoes) => {
        this.situacaoInicialList = situacoes;
        if (this.domain.situacaoInicial && !this.domain.situacaoInicial.ativo) {
          this.situacaoInicialList.push(this.domain.situacaoInicial);
        }
        Utils.ordemAlfabeticaDescricoesSemCaseSensitive(this.situacaoInicialList);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.situacaoService.listarAtivas().subscribe(
      (situacoes) => {
        this.situacaoPosVisualizacaoList = situacoes;
        if (this.domain.situacaoPosVisualizacao && !this.domain.situacaoPosVisualizacao.ativo) {
          this.situacaoPosVisualizacaoList.push(this.domain.situacaoPosVisualizacao);
        }
        Utils.ordemAlfabeticaDescricoesSemCaseSensitive(this.situacaoPosVisualizacaoList);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.situacaoService.listarAtivas().subscribe(
      (situacoes) => {
        this.situacaoList = situacoes;
        if (this.domain.listSituacao) {
          this.domain.listSituacao.forEach((situacaoSelecionada) => {
            if (!situacaoSelecionada.ativo) {
              this.situacaoList.push(situacaoSelecionada);
            }
          });
        }
        Utils.ordemAlfabeticaDescricoesSemCaseSensitive(this.situacaoList);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.orgaoService.getOrgaoLocal().subscribe(
      (orgao) => {
        this.orgaoLocalList = orgao;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareAcao(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  validar(domain: Fase): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (!domain.situacaoInicial) {
      this.messageService.messageErro("Selecione a Situação Inicial.");
      validacao = false;
    }
    if (!domain.situacaoPosVisualizacao) {
      this.messageService.messageErro("Selecione a Situação Pós Ação.");
      validacao = false;
    }

    if (domain.acaoMudancaSituacao == null || domain.acaoMudancaSituacao === undefined) {
      this.messageService.messageErro("Selecione a Ação para Mudança de Situação.");
      validacao = false;
    }
    if (!domain.listSituacao) {
      this.messageService.messageErro("Selecione a lista de Situações.");
      validacao = false;
    }
    if (domain.tipoFase == null) {
      this.messageService.messageErro("Selecione o Tipo Fase.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o status.");
      validacao = false;
    }
    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }
}
