import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { EnderecoService } from "../../api/endereco.service";
import { ProcessoService } from "../../api/processo.service";
import { SolicitanteService } from "../../api/solicitante.service";
import { Endereco } from "../../models/pensao/endereco.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { TipoRepresentanteEnumAll } from "../../models/pensao/tipo-representante.enum";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { Instituidor } from "../../models/pensao/instituidor.model";

@Component({
  selector: "app-input-dados-solicitante",
  templateUrl: "./input-dados-solicitante.component.html",
  styleUrls: ["./input-dados-solicitante.component.css"],
})
export class InputDadosSolicitanteComponent implements OnInit {
  data = this.transfereService.getData();
  @Output() solicitanteEventEmitter = new EventEmitter<any>();
  public solicitante: Solicitante = new Solicitante();
  public instituidor: Instituidor = new Instituidor();
  public tiposRepresentante: any[] = TipoRepresentanteEnumAll;
  public formGroupSolicitante: FormGroup;
  public ufs = Utils.getUfs();
  public atualizarListaDocumentos: boolean = false;
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private transfereService: TransfereService,
    private solicitanteService: SolicitanteService,
    private processoService: ProcessoService,
    private enderecoService: EnderecoService
  ) {
    this.solicitante.endereco = new Endereco();
    this.inicializarValidacoesForm();
  }

  public bar = {
    requerente: null,
  };

  public sections = [
    {
      fields: [
        [
          {
            key: "requerente",
            fieldType: "radioInput",
            label: "Você é um requerente nessa solicitação ? *",
            options: [
              { value: true, label: "Sim" },
              { value: false, label: "Não" },
            ],
            change: () => {
              this.emitSolicitante();
            },
          },
          {
            key: "representanteLegal",
            fieldType: "radioInput",
            label: "Você é representante legal de algum solicitante nessa solicitação ? *",
            options: [
              { value: true, label: "Sim" },
              { value: false, label: "Não" },
            ],
            change: () => {

              this.emitSolicitante();
            },
          },
          {
            key: "tipoSolicitante",
            fieldType: "selectInput",
            label: "Tipo de Representante",
            labelKey: "descricao",
            valueKey: "enum",
            required: true,
            // selectionChange: () => this.carregarDocumentosSolicitante(),
            options: () => this.tiposRepresentante,
            disabled: () => this.solicitante.representanteLegal === undefined || !this.solicitante.representanteLegal,
          },
          {
            key: "nome",
            fieldType: "textInput",
            label: "Nome completo",
            required: true,
            change: () => {
              this.emitSolicitante();
            
            },
            keyup: (e) => {
              this.solicitante.nome = e.target.value.toUpperCase();
            },
            focusout: (e) => {
              this.solicitante.nome = e.target.value.toUpperCase();
            },
          },
          {
            key: "sexo",
            fieldType: "radioInput",
            label: "Sexo *",
            options: [
              { value: "M", label: "M" },
              { value: "F", label: "F" },
            ],
            change: () => {
              this.emitSolicitante();
        
            },
          },
          {
            key: "dataNascimento",
            label: "Data do nascimento",
            fieldType: "textInput",
            required: true,
            appDatecheck: true,
            type: "date",
            min: this.minDate,
            max: this.maxDate,
            maxlength: 10,
            blur: () => {
            
              this.emitSolicitante();
            },
          },
          {
            key: "cpf",
            label: "CPF",
            fieldType: "textInput",
            required: true,
            type: "date",
            min: this.minDate,
            max: this.maxDate,
            maxlength: 14,
            focusout: () => {
              this.emitSolicitante();
          
            },
          },
        ],
      ],
    },
  ];

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F1") this.preencher();
  }

  ngOnInit() {}

  ngDoCheck() {}

  // carregarDocumentosSolicitante() {
  //   if (this.formGroupSolicitante.invalid) return;

  //   if (this.solicitante.representanteLegal === false) {
  //     this.solicitante.tipoSolicitante = null;
  //     return;
  //   }

  //   if (!this.solicitante.representanteLegal || this.solicitante.tipoSolicitante === undefined) return;

  //   this.solicitante.documentosRegra = [];

  //   this.validarProcessoMotorRegras();
  // }

  // validarProcessoMotorRegras() {
  //   let regrasProcessos = this.montarRegraProcesso();

  //   this.processoService.regrasProcesso(regrasProcessos).subscribe(
  //     (res) => {
  //       this.solicitante.documentosRegra = res.processos[0].solicitante.documentosObrigatorios;
  //       this.atualizarListaDocumentos = true;
  //       this.emitSolicitante();
  //     },
  //     (error) => {
  //       this.messageService.messageErro("Não foi possivel carregar os documentos necessários do solicitante");
  //       throw error;
  //     }
  //   );
  // }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regrasProcessos: RegrasProcesso[] = [];
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    this.solicitante.solicitanteTipo = this.solicitante.tipoSolicitante;
    regraProcesso.solicitante = this.solicitante;

    regrasProcessos.push(regraProcesso);

    return regrasProcessos;
  }

  inicializarValidacoesForm() {
    this.formGroupSolicitante = this.fb.group({
      requerente: ["", Validators.required],
      representanteLegal: ["", Validators.required],
      tipoRepresentante: new FormControl({ value: "", disabled: true }, Validators.required),
      nome: ["", Validators.required],
      sexo: ["", Validators.required],
      dataNascimento: ["", Validators.required],
      cpf: ["", Validators.required],
      rg: ["", Validators.required],
      orgaoEmissor: ["", Validators.required],
      rgUf: ["", Validators.required],
      estadoCivil: ["", Validators.required],
      telefone: ["", Validators.required],
      email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$")]),
      logradouro: ["", Validators.required],
      numero: ["", Validators.required],
      complemento: ["", Validators.nullValidator],
      bairro: ["", Validators.required],
      cep: ["", Validators.required],
      cidade: ["", Validators.required],
      uf: ["", Validators.required],
    });
  }

  emitSolicitante() {
    let retorno = {
      // solicitante: (this.formGroupSolicitante.valid) ? this.solicitante : null,
      solicitante: this.solicitante,
      validacaoFormulario: this.formGroupSolicitante.valid,
      atualizarListaDocumentos: this.atualizarListaDocumentos,
    };

    if (this.atualizarListaDocumentos) this.atualizarListaDocumentos = false;

    this.solicitanteEventEmitter.emit(retorno);
  }

  salvar() {
    this.solicitanteService.salvar(this.solicitante).subscribe(
      (solicitanteSalvo: Solicitante) => {
        this.solicitante.id = solicitanteSalvo.id;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  pesquisarEndereco(cep: string) {
    if (cep.length === 9) {
      this.enderecoService.pesquisarEnderecoPorCep(cep).subscribe(
        (endereco: any) => {
          if (endereco != null) {
            this.solicitante.endereco.logradouro = endereco.logradouro;
            this.solicitante.endereco.bairro = endereco.bairro;
            this.solicitante.endereco.cidade = endereco.localidade;
            this.solicitante.endereco.uf = endereco.uf;
          }
          if (endereco.erro) {
            this.messageService.messageWarning("Atenção! CEP não encontrado");
          }
        },
        (error) => {
          this.messageService.messageWarning("Não foi possivel carregar o endereço");
          throw error;
        }
      );
    }
  }

  preencher() {
    //Solicitante:
    this.solicitante.cpf = "05060255456";
    this.solicitante.dataNascimento = "1980-01-11";
    this.solicitante.email = "testesolicitante@email.com";
    this.solicitante.endereco.bairro = "CURIÓ";

    this.solicitante.endereco.cep = "60000000";
    this.solicitante.endereco.cidade = "FORTALEZA";
    this.solicitante.endereco.logradouro = "RUA A";
    this.solicitante.endereco.numero = "20";
    this.solicitante.endereco.uf = "CE";
    this.solicitante.endereco.complemento = "";

    this.solicitante.estadoCivil = "Viúvo";
    this.solicitante.nome = "TESTE SOLICITANTE";
    this.solicitante.representanteLegal = false;
    this.solicitante.requerente = true;
    this.solicitante.rg = "2545858594578";
    this.solicitante.rgOrgaoEmissor = "SSP";
    this.solicitante.rgUf = "CE";
    this.solicitante.sexo = "F";
    this.solicitante.telefone = "85789654252";
    this.solicitante.tipoSolicitante = undefined;
    this.emitSolicitante();
 
  }
  get mask() {
    if (this.solicitante.telefone && this.solicitante.telefone.length > 10) return "(00) 0 0000-0000";
    return "(00) 0000-00000";
  }

  get cellphoneValidation() {
    return !this.solicitante.telefone || this.solicitante.telefone.length < 10;
  }
}
