import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { GlobalModule } from "./global";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingScreenInterceptor } from "./global/interceptors/loading-screen.interceptor";
import { HttpBackendInterceptor } from "./global/interceptors/httpbackend.interceptor";

import { MatPaginatorIntl } from "@angular/material";
import { CustomPaginator } from "./global/util/custom-paginator-configuration";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { ServerErrorsInterceptor } from "./global/interceptors/server-errors-interceptor";
import { ErrorsHandler } from "./global/interceptors/errors-handler";
import { LoginGuard } from "./global/api/seguranca/login.guard";
import { AuthGuard } from "./global/api/seguranca/auth.guard";

//import { AppConfig, APP_CONFIG } from '../app.config'
//import { APP_INSIGHTS_CONFIG } from "ng-appinsights";

@NgModule({
    declarations: [AppComponent],
    imports: [
        RouterModule,
        GlobalModule,
        CoreModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpBackendInterceptor, multi: true },
        //{ provide: APP_INSIGHTS_CONFIG, useFactory: (config: AppConfig) => config.CONTEXTO, deps: [APP_CONFIG] },
        { provide: MatPaginatorIntl, useValue: CustomPaginator() },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorsInterceptor,
            multi: true,
        },
        AuthGuard

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
