import { Documento } from "../documento.model";
import { ProcessoDTO } from "../processo-dto.model";
import { Instituidor } from "./instituidor.model";
import { Requerente } from "./requerente.model";
import { Solicitante } from "./solicitante.model";

export class ProcessoPensaoDTO {
    processo: ProcessoDTO;
    solicitante: Solicitante;
    instituidor: Instituidor;
    requerentes: Requerente[];
}