import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/core/api/usuario.service';
import { AutenticacaoService } from '../../api/seguranca/autenticacao.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProcessoService } from 'src/app/core/api/processo.service';
import { MessageService } from '../../message/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
[x: string]: any;

public email: string;

public form: FormGroup;

  constructor(
    public router: Router,
    public messageService: MessageService,
    private processoService: ProcessoService) {

     }

  ngOnInit() {
    
  }

  submitForm() {    
    this.processoService.recuperarSenha(this.email).subscribe(
      (response) => {
        this.messageService.messageSuccess("Uma nova senha foi enviada para o seu e-mail.");
        this.router.navigateByUrl("/login");
        
      },
      (error) => {
        if (error.status == 500) {
          this.messageService.messageErro(error.error.message);
        } else {
          this.messageService.messageErro("Erro ao recuperar senha. Por favor, tente mais tarde.");
        }
        throw error;
      }
    );
  }

}
