import { Domain } from "src/app/global/models/domain.model";
import { Orgao } from "../orgao.model";
import { Perfil } from "./perfil.model";

export class Cidadao extends Domain {
    public faceId: number;
    public nome: string;
    public email: string;
    public dataNascimento: string;
    public telefone: string;
    public cpf: string;
    public senha: string;
    public documento: string;

    public perfil: Perfil;
    public orgao: Orgao;
    public orgaos: Orgao[];
    public matricula: string;

}