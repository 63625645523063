import { ListarFilhosCategoriaAssuntoComponent } from "./../../../servicos/listar-filhos-categoria-assunto/listar-filhos-categoria-assunto.component";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { CidadaoService } from "src/app/core/api/seguranca/cidadao.service";
import { Orgao } from "src/app/core/models/orgao.model";
import { Cidadao } from "src/app/core/models/seguranca/cidadao.model";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { Usuario } from "src/app/core/models/usuario.model";
import { ignoreElements } from "rxjs/operators";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-salvar-orgao",
  templateUrl: "./salvar-orgao.component.html",
  styleUrls: ["./salvar-orgao.component.css"],
})
export class SalvarOrgaoComponent extends SalvarGenericsComponent<Orgao> implements OnInit {
  searchRespCtrl = new FormControl();
  isLoading = false;
  errorMsg: string;
  responsaveis: any[];
  listOrgaos: Orgao[];
  public listUsuarios: Usuario[];
  public siglaPai: String;
  public orgaoSelecionado: any;
  public inputUsuario: boolean = true;
  public listUsuariosSelecionados: any[];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: OrgaoService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public cidadaoService: CidadaoService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "orgao", "");
  }

  ngOnInit() {
    this.carregarListaOrgaos();
  }

  private carregarUsuarios() {
    this.cidadaoService.getListarAnalista().subscribe(
      (res) => {
        this.listUsuarios = res;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  afterResponse() {
    if (this.domain.ativo == null) {
      this.domain.ativo = true;
    }
  }

  validar(domain: Orgao): boolean {
    let validacao = true;

    if (!domain.nome || !domain.nome.trim()) {
      this.messageService.messageErro("Defina um nome.");
      validacao = false;
    }

    if (!domain.sigla || !domain.sigla.trim()) {
      this.messageService.messageErro("Defina uma sigla.");
      validacao = false;
    }

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Defina uma descrição.");
      validacao = false;
    }

    /*
    if (!this.listUsuariosSelecionados || this.listUsuariosSelecionados.length == 0 ) {
      this.messageService.messageErro("Selecione os Usuários do grupo.");
      validacao = false;
    }
    */

    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      domain.listUsuarios = this.listUsuariosSelecionados;
      this.submeter();
    }
  }

  editarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.editar(domain);
    }
  }

  compareUsuario(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.nome === obj2.nome : obj1 === obj2;
  }

  private carregarListaOrgaos(): void {
    this.apiService.list().subscribe(
      (orgaos) => {
        this.listOrgaos = orgaos.filter((orgao) => orgao.id != this.domain.id);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
