import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PerfilService } from 'src/app/core/api/seguranca/perfil.service';
import { Perfil } from 'src/app/core/models/seguranca/perfil.model';
import { SalvarGenericsComponent } from 'src/app/global/generics/salvar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sgeap-salvar-perfil',
  templateUrl: './salvar-perfil.component.html',
  styleUrls: ['./salvar-perfil.component.css']
})
export class SalvarPerfilComponent extends SalvarGenericsComponent<Perfil> {
  selection = new FormControl();

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: PerfilService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
  ) {
    super(formBuilder, router, apiService, messageService, route, "perfil",
      {});
  }

  salvarValidar(domain: Perfil): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  private validar(domain: Perfil): boolean {
    let validacao = true;

    if (!domain.nome) {
      this.messageService.messageErro("Preencha o campo nome.");
      validacao = false;
    }

    return validacao;
  }

}