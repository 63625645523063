import { Component, OnInit, NgZone, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import LacunaWebPKI, { CertificateModel } from "web-pki";
import { AutenticacaoService } from "../../api/seguranca/autenticacao.service";
import { SharedService } from "../../api/shared.service";
import { MessageService } from "../../message/message.service";
import { Certificate } from "../../models/certificate.model";
import { ContribuinteService } from "src/app/core/api/contribuinte.service";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Component({
  selector: "app-login-certificate",
  templateUrl: "./login-certificate.component.html",
  styleUrls: ["./login-certificate.component.css"]
})
export class LoginCertificateComponent implements OnInit {

  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  public form: FormGroup;
  public message: string;
  public certificate: Certificate;

  certificates: CertificateModel[];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private zone: NgZone,
    private sharedService: SharedService,
    private autenticacaoService: AutenticacaoService,
    private contribuinteSevice: ContribuinteService,
    private messageService: MessageService,
    @Inject(APP_CONFIG) public config: AppConfig) {
    this.form = this.formBuilder.group({
      selectedCertificate: ["", []]
    });
  }

  ngOnInit() {
    this.getCertificates();
  }

  public getCertificates(): any {
    this.pki.init({
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled,
      defaultFail: this.onWebPkiFail
    });
  }

  onWebPkiNotInstalled(status, message) {
    const pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
    pki.redirectToInstallPage();
    console.error("onWebPkiNotInstalled" + status + message);
  }

  onWebPkiFail(ex) {
    console.error("Web PKI error from " + ex.origin + ": " + ex.message + "(" + ex.code + ")");
  }

  onWebPkiReady = () => {
    console.info("Web PKI ready, loading certificates ...");
    this.pki.listCertificates().success((certs) => {
      console.info("Certificates loaded.");
      this.certificates = certs;
    });
  }

  entrar() {
    this.sharedService.setCertificate(null);
    this.sharedService.setDocumentoRepresentante(null);

    const selectedCertificate = this.form.get("selectedCertificate").value;
    this.certificate = selectedCertificate;
    if (!selectedCertificate) {
      this.messageService.messageErro("Selecione um certificado !");
      return;
    }

    if (new Date() > selectedCertificate.validityEnd) {
      this.messageService.messageErro("O certificado escolhido expirou em " + selectedCertificate.validityEnd.toLocaleDateString());
      return;
    }
    // tslint:disable-next-line:triple-equals
    if ((selectedCertificate.pkiBrazil.cnpj == null && selectedCertificate.pkiBrazil.cnpj == undefined) &&
      (selectedCertificate.pkiBrazil.cpf == null && selectedCertificate.pkiBrazil.cpf == undefined)) {
      this.messageService.messageErro("O certificado escolhido não possui CNPJ/CPF ");
      return;
    }
    // TODO verificar carregamento do certificado hoje está tando problema ao ler o certificado
    this.pki.readCertificate({
      thumbprint: selectedCertificate.thumbprint
    }).success(certEncoding => {
      // Get ASCII encoding of text in Base64 format
      const data = btoa("REGRA DE NEGÓCIO - Autenticação com certificado digital - Contribuinte");

      this.pki.signData({
        data: data,
        digestAlgorithm: "sha256",
        thumbprint: selectedCertificate.thumbprint
      }).success((s) => {
        this.certificate.cert64 = certEncoding;
        this.sharedService.setCertificate(this.certificate);
        let documento: number;
        this.messageService.messageWarning("Implementar login.");
        // this.autenticacaoService.login(certEncoding).subscribe(
        //   (response) => {
        //     this.sharedService.logar(response.headers.get("Authorization"));

        //     if (selectedCertificate.pkiBrazil.cnpj != null && selectedCertificate.pkiBrazil.cnpj !== undefined) {
        //       documento = selectedCertificate.pkiBrazil.cnpj;
        //     } else if (selectedCertificate.pkiBrazil.cpf != null && selectedCertificate.pkiBrazil.cpf !== undefined) {
        //       documento = selectedCertificate.pkiBrazil.cpf;
        //     }
        //     this.sharedService.setDocumentoRepresentante(documento);

        //     this.routing();
        //   }, 
        //   error => {
        //     this.messageService.messageErro(error.error.message);
        //     throw error;
        //   }
        // );

      });

    }).fail((e) => {
      this.messageService.messageErro("Erro ao ler certificado !" + e);
    });
  }

  routing() {
    // tslint:disable-next-line: max-line-length
    if (this.sharedService.isDocumentoRepresentante()) {
      this.contribuinteSevice.findByDocumento(this.sharedService.documentoRepresentante)
        .subscribe(contribuinteByDocumento => {
          if (contribuinteByDocumento) {
            this.zone.run(() => this.router.navigate(["entrada-contribuinte"]));
          } else {
            this.zone.run(() => this.router.navigate(["cadastro-contribuinte-representante"]));
          }
        }
        );

    }
  }
}
