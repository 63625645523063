import { Domain } from "../../global/models/domain.model";
import { OrgaoLocal } from "./orgao-local.model";

export class Contribuinte extends Domain {

  public documento: string;
  public nome: string;
  public telefone: string;
  public celular: string;
  public email: string;
  public logradouro: string;
  public endereco: string;
  public enderecoCompleto: string;

  public cgf: number;
  public codigoUnidadeFederativa: number;
  public codigoMunicipio: number;
  public cep: string;
  public orgaoLocal: OrgaoLocal;

  public local: boolean;
  public matrizInativa: boolean;
  public buscaCNPJBase: boolean;
}
