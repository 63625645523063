import { SelectionModel } from "@angular/cdk/collections";
import { formatDate } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { SharedService } from "src/app/global/api/shared.service";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { ContribuinteService } from "../../api/contribuinte.service";
import { DocumentoService } from "../../api/documento.service";
import { InstituidorService } from "../../api/instituidor.service";
import { ProcessoService } from "../../api/processo.service";
import { SolicitanteService } from "../../api/solicitante.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { Assinador } from "../../models/assinador.model";
import { Assunto } from "../../models/assunto.model";
import { Contribuinte } from "../../models/contribuinte.model";
import { Documento } from "../../models/documento.model";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";
import { MetadadoValor } from "../../models/metadado-valor.model";
import { FalecidoEm } from "../../models/pensao/falecido-em.enum";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { Requerente } from "../../models/pensao/requerente.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { Processo } from "../../models/processo.model";
import { TipoDocumentoMetadado } from "../../models/tipo-documento-metadado.model";
import { TipoDocumentoSistemaFunctions } from "../../models/tipo-documento-sistema.enum";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { Certificate } from "../../../global/models/certificate.model";
import LacunaWebPKI from "web-pki";
import { environment } from "../../../../environments/environment";
import { Assinatura } from "../../models/assinatura.model";

import { DialogIndicarAssinadoresComponent } from "../../processo/dialog-indicar-assinadores/dialog-indicar-assinadores.component";
import { DialogSelecionarCertificadoComponent } from "../../processo/dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { DocumentoAssinaturasComponent } from "../../processo/documento-assinaturas/documento-assinaturas.component";

@Component({
  selector: "app-listar-documentos",
  templateUrl: "./listar-documentos.component.html",
  styleUrls: ["./listar-documentos.component.css"],
})
export class ListarDocumentosComponent implements OnInit, OnChanges {
  @Input() documentos: Documento[];
  @Input() assunto: Assunto;
  @Input() processo: Processo;
  @Input() tipoEnvolvido: string;
  @Input() envolvido: any;
  @Input() solicitante: Solicitante;
  @Input() instituidor: Instituidor;
  @Input() indiceRequerente: number;

  @ViewChild("pdfDocumento") pdfDocumento;
  @ViewChild("paginatorDocumento") paginatorDocumento: MatPaginator;
  @Output() substituirDocummentoEditorEventtEmitter = new EventEmitter<any>();
  @Output() atualizarDataTableDocumentoEventtEmitter = new EventEmitter<any>();

  public dataSourceDocumento: MatTableDataSource<Documento>;
  public displayedDocumentosColumns: string[] = ["select", "id", "arquivo", "numero", "tipoDocumento", "info", "dataHora", "could"];
  public selection = new SelectionModel<Documento>(true, []);
  public documentoNovo: Documento;
  public exibicaoEditor = false;
  public documentoSelecionado: Documento;
  public exibirVIsualizadorDocumento = true;
  public exibirPdfDocumento = false;
  public ckeConfig: any;
  public urlDocumentoPdf: string;
  private nomeArquivo: string;
  public documentoAstra: string;
  public listaAssinador: Assinador[] = [];
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate: Certificate;

  private format: any = "dd/MM/yyyy";
  private formatHour: any = "HH:mm:ss";
  private locale: any = "en-US";
  private tipoDocumento: any;
  // private listFalecidoEm: any[] = FalecidoEm;
  private requerente: Requerente = new Requerente();
  listDocumentosPorMatricula: any;
  public documentoSubstituir: Documento;
  public idTipoDocumentoSelecionado: number;
  public notUsuarioExterno = false;

  constructor(
    private documentoService: DocumentoService,
    private contribuinteService: ContribuinteService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private processoService: ProcessoService,
    private tipoDocumentoService: TipoDocumentoService,
    private instituidorService: InstituidorService,
    private solicitanteService: SolicitanteService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.certificate = JSON.parse(this.sharedService.getKeyStoreCertificate());
  }

  ngOnInit() {
    this.dataSourceDocumento = new MatTableDataSource<Documento>(this.documentos);
    this.dataSourceDocumento.paginator = this.paginatorDocumento;
    this.notUsuarioExterno = this.sharedService.verificaRolePessoaLogada("frontend.perfil-user-externo");
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  getDocumento(documentoRow: Documento) {
    if (this.documentoSelecionado != null && documentoRow.id === this.documentoSelecionado.id) {
      this.limparDocumentoSelecionado();
      this.cdRef.detectChanges();
      return;
    }
    this.selection.clear();
    // this.atualizarDocumentosProcesso(this.processo);

    this.documentoService.get(documentoRow.id).subscribe((documentoAtualizado: Documento) => {
      if (documentoAtualizado != null) {
        this.novoDocumento(); // Limpar informações. Conferir se tem algo escrito?
        this.exibicaoEditor = false;
        this.documentoSelecionado = documentoAtualizado;
        this.cdRef.detectChanges();
        if (this.documentoSelecionado.autor != null) {
          this.contribuinteService.findResponsavelPorDocumento(this.documentoSelecionado.autor.documento).subscribe(
            (contribuinteOuServidor: Contribuinte) => {
              if (contribuinteOuServidor != null) {
                // tslint:disable-next-line: max-line-length
                this.documentoSelecionado.documentoEnomeAutor = contribuinteOuServidor.documento
                  .concat(" - ")
                  .concat(contribuinteOuServidor.nome);
              }
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
        // this.documentoSelecionado.listAssinatura.forEach(function (assinatura) {
        //   this.contribuinteService.findResponsavelPorMatricula(assinatura.responsavel.documento).subscribe(
        //     (responsavel) => {
        //       assinatura.responsavel.nome = responsavel ? responsavel.nome : "";
        //     },
        //     error => {
        //       this.messageService.messageErro(error.error.message);
        //       throw error;
        //     }
        //   );
        // }, this);

        // var disabled :boolean = this.documentoSelecionado == null || this.documentoSelecionado.listAssinatura.filter(assinatura => (assinatura.assinado && assinatura.responsavel.documento != this.processo.autor.documento)).length > 0;
        // this.documentoSelecionado.tipoDocumento.listMetadado.forEach(tipoDocumentoMetadado => {
        //   tipoDocumentoMetadado.metadado.disabled = disabled;
        //   this.documentoSelecionado.listMetadadoValor.forEach(metadadoValor => {
        //     if (tipoDocumentoMetadado.metadado.id === metadadoValor.metadadoId) {
        //       tipoDocumentoMetadado.metadado.valor = metadadoValor.valor;
        //       tipoDocumentoMetadado.metadado.disabled = disabled;
        //       this.listMetadado.push(tipoDocumentoMetadado.metadado);
        //     }
        //   });
        // });
        this.cdRef.detectChanges();

        // this.isDocumentoAssinado = this.documentoSelecionado.listAssinatura.filter(assinatura => assinatura.assinado).length > 0;

        this.exibirVIsualizadorDocumento = this.documentoSelecionado.nomeDocumento.toLowerCase().indexOf(".pdf") > -1;
        this.nomeArquivo = this.documentoSelecionado.nomeDocumento;
        this.atualizarComponentePdf();
      } else {
        this.messageService.messageErro("Não foi possível atualizar informações do documento.");
        return;
      }
    });
  }

  downloadDocumento(documento: Documento) {
    const isPDF = documento.nomeDocumento.substring(documento.nomeDocumento.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1;
    if (isPDF) {
      this.documentoService.download(documento.id).subscribe((blob) => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    } else {
      this.documentoService.download(documento.id, "octet/stream").subscribe((blob) => {
        if (!Utils.downloadBlob(blob, documento.nomeDocumento)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    }
  }

  downloadDocumento2(documento: Documento) {
    this.documentoService.download(documento.id).subscribe((blob) => {
      this.exibirPdfDocumento = true;
      const url = Utils.getUrlBlob(blob);
      this.urlDocumentoPdf = url;
      window.open(url);

      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = documento.nomeDocumento;
      document.body.appendChild(link);
      link.click();

      window.setTimeout(function () {
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }, 0);
    });
  }

  public novoDocumento() {
    this.limparDocumentoSelecionado();
    this.confiCkEditor();
    // this.isDocumentoAssinado = false;
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;
    this.listaAssinador = [];
    this.processo.modeloAssunto = "";
  }

  private atualizarComponentePdf() {
    this.exibirPdfDocumento = false;
    if (this.documentoSelecionado.nomeDocumento.toLowerCase().indexOf(".pdf") === -1) {
      return;
    }

    if (this.documentoSelecionado.status === 2) {
      this.documentoService.download(this.documentoSelecionado.id).subscribe(
        (blob) => {
          this.exibirPdfDocumento = true;
          const url = Utils.getUrlBlob(blob);
          this.urlDocumentoPdf = url;

          if (this.pdfDocumento !== undefined) {
            if (this.exibirVIsualizadorDocumento) {
              this.pdfDocumento.pdfSrc = url;
              this.pdfDocumento.refresh();
            }
          } else {
            this.messageService.messageWarning("Não foi possível carregar o PDF");
          }
        },
        (error) => {
          const erro = Utils.converterArrayBufferParaJSON(error);
          this.messageService.messageErro(erro.error.message);
        }
      );
    } else {
      this.messageService.messageInfo("Aguardando documento ser enviado para o Alfresco.");
    }
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  private limparDocumentoSelecionado() {
    this.documentoSelecionado = null;
  }

  checkboxLabel(row?: Documento): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  isAllSelected() {
    if (this.dataSourceDocumento != null || this.dataSourceDocumento !== undefined) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSourceDocumento.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  async carregarInstituidor(idProcesso: number) {
    const response = await this.instituidorService
      .pesquisarPorProcesso(idProcesso)
      .toPromise()
      .catch((error) => {
        this.messageService.messageErro("Não foi possivel carregar o instituidor. " + error.error.message);
        throw error;
      });
    this.instituidor = <Instituidor>response;
    return response;
  }

  async carregarSolicitante(idProcesso: number) {
    const response = await this.solicitanteService
      .pesquisarPorProcesso(idProcesso)
      .toPromise()
      .catch((error) => {
        this.messageService.messageErro("Não foi possivel carregar o instituidor. " + error.error.message);
        throw error;
      });
    this.solicitante = <Solicitante>response;
    return response;
  }

  substituirDocumento(event: any, documento: Documento) {
    if (documento.tipoDocumento.somenteAnexo) {
      this.adicionarDocumento();
      this.documentoNovo.tipoDocumento = documento.tipoDocumento;
      this.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
      this.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
      this.documentoNovo.tipoDocumento.nomeDocumento = documento.tipoDocumento.nomeDocumento;
      this.documentoNovo.descricao = documento.descricao;
    } else {
      this.substituirDocummentoEditorEventtEmitter.emit({
        tipoEnvolvido: this.tipoEnvolvido,
        documentoSubstituir: documento,
        indiceRequerente: this.indiceRequerente,
      });

      let ancora = "editorHtml" + this.tipoEnvolvido;
      if (this.tipoEnvolvido === "REQUERENTE") {
        ancora += this.indiceRequerente;
      }
      Utils.rolarParaAncora(ancora);
    }

    this.documentoSubstituir = documento;
  }

  incluirDocumentoNovoAnexo(event, documentoSubstituir: Documento, index: number) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (!this.validarExtensao(fileList[0].name)) {
        const extensoes = !this.assunto.extensao ? ".pdf" : this.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }

      let fileName = fileList[0].name;
      let arquivoPdf = fileName.substring(fileName.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1;
      if (this.validar(arquivoPdf)) {
        const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
        const formData: FormData = new FormData();
        const file: File = fileList[0];
        formData.append("file", file);

        formData.append("fileName", file.name);
        formData.append("descricao", this.documentoNovo.descricao);
        formData.append("descricaoAssunto", this.processo.assunto.descricao);
        formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
        formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
        formData.append("processoId", this.processo.id.toString());
        formData.append(
          "docAssinantes",
          this.listaAssinador
            .map(function (assinador) {
              return assinador.documento.toString();
            })
            .join(",")
        );
        formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());

        const listMetadadoValor: MetadadoValor[] = [];
        var metadadoValido = true;
        this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
          if (!this.validarMetadado(tipoDocumentoMetadado)) {
            metadadoValido = false;
          }
          const metadadoValor = new MetadadoValor();
          metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
          metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
          listMetadadoValor.push(metadadoValor);
        });
        // Salvando informações digitados dos metadados
        this.documentoNovo.listMetadadoValor = listMetadadoValor;

        formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));

        if (arquivoPdf) {
          this.documentoService.post(formData).subscribe(
            (documento) => {
              this.associarDocumentoEnvolvido(documento);
              if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                this.deleteDocumentoSubstituido(this.documentoSubstituir);
                this.messageService.messageSuccess("Documento substituído com sucesso!");
              } else {
                this.messageService.messageSuccess("Documento inserido com sucesso!");
              }
              this.documentos.push(documento);
              this.ordenarDocumentos();
              this.ngOnInit();
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        } else {
          this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
            (documento) => {
              this.associarDocumentoEnvolvido(documento);
              if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                this.deleteDocumentoSubstituido(this.documentoSubstituir);
                this.messageService.messageSuccess("Documento substituído com sucesso!");
              } else {
                this.messageService.messageSuccess("Documento inserido com sucesso!");
              }
              this.documentos.push(documento);
              this.ordenarDocumentos();
              this.ngOnInit();
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
      }
    }
  }
  ordenarDocumentos() {
    this.documentos.forEach((doc) => {
      if (doc.ordem === this.documentoSubstituir.ordem) {
        doc.ativo = false;
        return;
      }
    });
    this.documentos.sort((obj1, obj2) => {
      if (obj1.ordem < obj2.ordem) {
        return 1;
      }
      if (obj1.ordem > obj2.ordem) {
        return -1;
      }
      return 0;
    });
  }

  private validarExtensao(arquivo) {
    let extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    let extensoes = !this.assunto.extensao ? [".pdf"] : this.assunto.extensao.split(",");

    return extensoes.filter((e) => e.trim().indexOf(extensao) > -1).length > 0;
  }

  private validar(arquivoPdf): boolean {
    let validacao = true;

    if (this.documentoNovo.tipoDocumento.nomeDocumento == null) {
      this.messageService.messageWarning("Selecione o tipo de documento.");
      validacao = false;
    }
    if (!this.documentoNovo.descricao) {
      this.messageService.messageWarning("Preencha o campo descrição.");
      validacao = false;
    }

    // if (!this.documentoNovo.modeloDocumento) {
    //   this.messageService.messageWarning("Preencha o campo conteúdo.");
    //   validacao = false;
    // }

    // if (arquivoPdf) {
    //   if (this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura + " assinadores.");
    //     validacao = false;
    //   }

    //   if (this.documentoNovo.tipoDocumento.qtdeExataAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeExataAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeExataAssinatura + " assinadores.");
    //     validacao = false;
    //   }
    // }

    return validacao;
  }

  private validarMetadado(tipoDocumentoMetadado: TipoDocumentoMetadado): boolean {
    if (
      tipoDocumentoMetadado.metadado.valor !== undefined &&
      tipoDocumentoMetadado.metadado.valor !== null &&
      tipoDocumentoMetadado.metadado.valor.length !== 0
    ) {
      if (
        tipoDocumentoMetadado.metadado.mascara !== undefined &&
        tipoDocumentoMetadado.metadado.mascara !== null &&
        tipoDocumentoMetadado.metadado.validarQuantidadeCaracteres &&
        tipoDocumentoMetadado.metadado.valor.length !== tipoDocumentoMetadado.metadado.mascara.length
      ) {
        this.messageService.messageErro(
          "Preencha o campo " + tipoDocumentoMetadado.metadado.nome + " conforme a máscara " + tipoDocumentoMetadado.metadado.mascara + "."
        );
        return false;
      }
    } else if (tipoDocumentoMetadado.requerido) {
      this.messageService.messageErro("Preencha o campo " + tipoDocumentoMetadado.metadado.nome);
      return false;
    }

    return true;
  }

  private deleteDocumentoSubstituido(documento: any) {
    documento.processoId = this.processo.id;
    this.documentoService.deleteDocumentoById(documento.id).subscribe(
      (resp) => {
        // this.listarDocumentosProcesso(this.processo);
        // this.messageService.messageSuccess("Documento deletado com sucesso.");
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private associarDocumentoEnvolvido(documento: Documento) {
    switch (this.tipoEnvolvido) {
      case "SOLICITANTE":
        this.setDocumentoEnvolvido("SOLICITANTE", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
      case "INSTITUIDOR":
        this.setDocumentoEnvolvido("INSTITUIDOR", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
      case "REQUERENTE":
        this.setDocumentoEnvolvido("REQUERENTE", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
    }
  }

  private setDocumentoEnvolvido(envolvido: string, idEnvolvido: number, documentoDoEnvolvido: string, idDocumento: number) {
    this.documentoService.setDocumentoEnvolvido(envolvido, idEnvolvido, documentoDoEnvolvido, idDocumento).subscribe(
      (resp) => {},
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  async substituirDocumentoSistema(documento: Documento) {
    if (this.tipoEnvolvido === "REQUERENTE") {
      this.requerente = this.envolvido;
    }

    await this.carregarSolicitante(this.processo.id);
    await this.carregarInstituidor(this.processo.id);
    let idTipoDocumento = TipoDocumentoSistemaFunctions.getDescricaoPorId(documento.tipoDocumento.idTipoDocumentoMotorRegras);

    this.tipoDocumentoService.pesquisarTipoDocumentoSistema(idTipoDocumento).subscribe(
      (tipoDocumento) => {
        this.tipoDocumento = tipoDocumento;
        let documentoOriginal = tipoDocumento.modeloDocumento;
        this.preencherDocumentosComDados(documentoOriginal);
        const gerarPdfUploadAlfrescoRequest = this.criarObjetoNovoDocumento(documento);

        this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
          (documentoSalvo) => {
            this.associarDocumentoEnvolvido(documentoSalvo);
            this.documentos.push(documentoSalvo);
            this.documentoService.deleteDocumentoById(documento.id).subscribe(
              (doc) => {
                this.ngOnInit();
              },
              (erro) => {
                this.messageService.messageErro(erro.error);
              }
            );
            this.dataSourceDocumento._updateChangeSubscription;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
        this.messageService.messageSuccess("Novo documento gerado com sucesso");
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  preencherDocumentosComDados(documentoAGerar: any): Promise<string> {
    this.tipoDocumento.modeloDocumento = documentoAGerar;
    this.requerente = this.envolvido;

    this.preencherDadosInstituidor();
    this.preencherDadosSolicitante();
    this.preencherDadosRequerente();
    this.selecionarTextoConformeTipoRequerente();

    let cpfCodigo = this.requerente.cpf.slice(0, 4);
    let nomeCodigo = this.requerente.nome[0];
    let horaCodigo = formatDate(new Date(), this.formatHour, this.locale).replace(/\:/g, "");
    let dataCodigo = formatDate(new Date(), this.format, this.locale).replace(/\//g, "").split("").reverse().join("");
    function navegadorGet() {
      var agente = navigator.userAgent;
      var navegadores = ["Chrome", "Firefox", "Safari", "Opera", "Trident", "MSIE", "Edge"];
      for (var i in navegadores) {
        if (agente.indexOf(navegadores[i]) != -1) {
          return navegadores[i].toLowerCase();
        }
      }
    }
    let navegadorCodigo = navegadorGet();
    let teste = this.tipoDocumento.nomeDocumento;
    let sigla = teste.split(" ");
    let a = 0;
    let siglaCodigo = "";
    while (sigla[a] != sigla[sigla.length]) {
      if (sigla[a].charAt(0) == sigla[a].charAt(0).toUpperCase()) {
        sigla[a] = sigla[a].charAt(0);
      } else {
        sigla[a] = "";
      }
      siglaCodigo = siglaCodigo + sigla[a];
      a++;
    }
    let resultadoCodigo = cpfCodigo + nomeCodigo + "-" + horaCodigo + "-" + dataCodigo + "-" + siglaCodigo + "-" + navegadorCodigo;

    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[logoDocumento]/g, "/images/logo-cearaprev-p&b.png")
      .replace(/\[codigo]/g, resultadoCodigo) //0001112122554564564XXXXXX
      .replace(/\[cidadeDocumento]/g, "FORTALEZA")
      .replace(/\[ufDocumento]/g, "CE")
      .replace(/\[dataDocumento]/g, formatDate(new Date(), this.format, this.locale))
      .replace(/\[horaDocumento]/g, formatDate(new Date(), this.formatHour, this.locale));

    return new Promise((resolve, reject) => {
      resolve(this.tipoDocumento.modeloDocumento);
    });
  }

  preencherDadosInstituidor() {
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[instituidor.nome]/g, this.instituidor.nome != undefined ? this.instituidor.nome : "")
      .replace(/\[instituidor.matricula]/g, this.instituidor.matricula != undefined ? this.instituidor.matricula : "")
      .replace(/\[instituidor.cpf]/g, this.instituidor.cpf != undefined ? this.instituidor.cpf : "")
      .replace(/\[instituidor.sexo]/g, this.instituidor.sexo != undefined ? this.instituidor.sexo : "")
      .replace(/\[instituidor.rg]/g, this.instituidor.rg != undefined ? this.instituidor.rg : "")
      .replace(/\[instituidor.orgaoEmissor]/g, this.instituidor.rgOrgaoEmissor != undefined ? this.instituidor.rgOrgaoEmissor : "")
      .replace(/\[instituidor.uf]/g, this.instituidor.rgUf != undefined ? this.instituidor.rgUf : "")
      .replace(/\[instituidor.estadoCivil]/g, this.instituidor.estadoCivil != undefined ? this.instituidor.estadoCivil : "")
      .replace(/\[instituidor.orgaoOrigem]/g, this.instituidor.orgaoOrigem != undefined ? this.instituidor.orgaoOrigem : "")
      .replace(/\[instituidor.cargo]/g, this.instituidor.cargo != undefined ? this.instituidor.cargo : "")
      .replace(/\[instituidor.grupo]/g, this.instituidor.grupo != undefined ? this.instituidor.grupo : "")
      .replace(
        /\[instituidor.obitoRelacionadoServico]/g,
        this.instituidor.obitoRelacionadoServico != undefined ? (this.instituidor.obitoRelacionadoServico == true ? "Sim" : "Não") : ""
      )
      .replace(
        /\[instituidor.situacaoFuncional]/g,
        this.instituidor.falecidoEm != undefined ? FalecidoEm[this.instituidor.falecidoEm].descricao : ""
      )
      .replace(
        /\[instituidor.dataNascimento]/g,
        this.instituidor.dataNascimento != undefined ? formatDate(this.instituidor.dataNascimento, this.format, this.locale) : ""
      )
      .replace(
        /\[instituidor.dataObito]/g,
        this.instituidor.dataObito != undefined ? formatDate(this.instituidor.dataObito, this.format, this.locale) : ""
      )
      .replace(
        /\[instituidor.mesesContribuicao]/g,
        this.instituidor.mesesContribuicao != undefined ? this.instituidor.mesesContribuicao.toString() : ""
      )
      .replace(
        /\[instituidor.quantidadeDependentes]/g,
        this.instituidor.quantidadeDependentes != undefined ? this.instituidor.quantidadeDependentes.toString() : ""
      )
      .replace(
        /\[instituidor.ultimoProvento]/g,
        this.instituidor.ultimoProvento != undefined ? this.instituidor.ultimoProvento.toString() : ""
      );
  }

  preencherDadosSolicitante() {
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[solicitante.nome]/g, this.solicitante.nome != undefined ? this.solicitante.nome : "")
      .replace(/\[solicitante.cpf]/g, this.solicitante.cpf != undefined ? this.solicitante.cpf : "");
  }

  preencherDadosRequerente() {
    this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
      .replace(/\[requerente.nome]/g, this.requerente.nome != undefined ? this.requerente.nome : "")
      .replace(/\[requerente.sexo]/g, this.requerente.sexo != undefined ? this.requerente.sexo : "")
      .replace(
        /\[requerente.dataNascimento]/g,
        this.requerente.dataNascimento != undefined ? formatDate(this.requerente.dataNascimento, this.format, this.locale) : ""
      )
      .replace(/\[requerente.cpf]/g, this.requerente.cpf != undefined ? this.requerente.cpf : "")
      .replace(/\[requerente.rg]/g, this.requerente.rg != undefined ? this.requerente.rg : "")
      .replace(/\[requerente.orgaoEmissor]/g, this.requerente.rgOrgaoEmissor != undefined ? this.requerente.rgOrgaoEmissor : "")
      .replace(/\[requerente.uf]/g, this.requerente.rgUf != undefined ? this.requerente.rgUf : "")
      .replace(/\[requerente.pis]/g, this.requerente.pisPasepNit != undefined ? this.requerente.pisPasepNit : "")
      .replace(/\[requerente.estadoCivil]/g, this.requerente.estadoCivil != undefined ? this.requerente.estadoCivil : "")
      .replace(
        /\[requerente.dataCasamento]/g,
        this.requerente.dataCasamento != undefined ? formatDate(this.requerente.dataCasamento, this.format, this.locale) : ""
      )
      .replace(/\[requerente.endereco]/g, this.requerente.endereco.logradouro != undefined ? this.requerente.endereco.logradouro : "")
      .replace(/\[requerente.nEndereco]/g, this.requerente.endereco.numero != undefined ? this.requerente.endereco.numero : "")
      .replace(
        /\[requerente.complementoEndereco]/g,
        this.requerente.endereco.complemento != undefined ? this.requerente.endereco.complemento : ""
      )
      .replace(/\[requerente.bairro]/g, this.requerente.endereco.bairro != undefined ? this.requerente.endereco.bairro : "")
      .replace(/\[requerente.cep]/g, this.requerente.endereco.cep != undefined ? this.requerente.endereco.cep : "")
      .replace(/\[requerente.cidade]/g, this.requerente.endereco.cidade != undefined ? this.requerente.endereco.cidade : "")
      .replace(/\[requerente.ufEndereco]/g, this.requerente.endereco.uf != undefined ? this.requerente.endereco.uf : "")
      .replace(/\[requerente.telefone1]/g, this.requerente.telefone != undefined ? this.requerente.telefone : "")
      .replace(/\[requerente.telefone2]/g, this.requerente.telefone != undefined ? this.requerente.telefone : "")
      .replace(/\[requerente.tipoSolicitante]/g, this.requerente.tipoSolicitante != undefined ? this.requerente.tipoSolicitante : "")
      .replace(/\[requerente.solicitanteTipo]/g, this.requerente.solicitanteTipo != undefined ? this.requerente.solicitanteTipo : "")
      .replace(/\[requerente.email]/g, this.requerente.email != undefined ? this.requerente.email : "")
      .replace(/\[requerente.agenciaBradesco]/g, this.requerente.agencia != undefined ? this.requerente.agencia.toString() : "")
      .replace(/\[requerente.contaCBradesco]/g, this.requerente.conta != undefined ? this.requerente.conta.toString() : "")
      .replace(/\[requerente.emailRepresentante]/g, this.requerente.email != undefined ? this.requerente.email : "")
      .replace(/\[requerente.caixaDeTexto]/g, this.requerente.observacao != undefined ? this.requerente.observacao : "");

    if (this.requerente.representanteLegal != undefined) {
      this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
        .replace(
          /\[requerente.nomeRepresentante]/g,
          this.requerente.representanteLegal.nome != undefined ? this.requerente.representanteLegal.nome : ""
        )
        .replace(
          /\[requerente.sexoRepresentante]/g,
          this.requerente.representanteLegal.sexo != undefined ? this.requerente.representanteLegal.sexo : ""
        )
        .replace(
          /\[requerente.tipoRepresentante]/g,
          this.requerente.representanteLegal.tipoRepresentante != undefined
            ? this.requerente.representanteLegal.tipoRepresentante.toString()
            : ""
        )
        .replace(
          /\[requerente.cpfRepresentante]/g,
          this.requerente.representanteLegal.cpf != undefined ? this.requerente.representanteLegal.cpf : ""
        )
        .replace(
          /\[requerente.rgRepresentante]/g,
          this.requerente.representanteLegal.rg != undefined ? this.requerente.representanteLegal.rg : ""
        )
        .replace(
          /\[requerente.orgaoRepresentante]/g,
          this.requerente.representanteLegal.orgaoEmissor != undefined ? this.requerente.representanteLegal.orgaoEmissor : ""
        )
        .replace(
          /\[requerente.ufRepresentante]/g,
          this.requerente.representanteLegal.uf != undefined ? this.requerente.representanteLegal.uf : ""
        )
        .replace(
          /\[requerente.estadoCivilRepresentante]/g,
          this.requerente.representanteLegal.estadoCivil != undefined ? this.requerente.representanteLegal.estadoCivil : ""
        )
        .replace(
          /\[requerente.enderecoRepresentante]/g,
          this.requerente.representanteLegal.endereco.logradouro != undefined ? this.requerente.representanteLegal.endereco.logradouro : ""
        )
        .replace(
          /\[requerente.nEnderecoRepresentante]/g,
          this.requerente.representanteLegal.endereco.numero != undefined ? this.requerente.representanteLegal.endereco.numero : ""
        )
        .replace(
          /\[requerente.complementoEnderecoRepresentante]/g,
          this.requerente.representanteLegal.endereco.complemento != undefined
            ? this.requerente.representanteLegal.endereco.complemento
            : ""
        )
        .replace(
          /\[requerente.bairroRepresentante]/g,
          this.requerente.representanteLegal.endereco.bairro != undefined ? this.requerente.representanteLegal.endereco.bairro : ""
        )
        .replace(
          /\[requerente.cepRepresentante]/g,
          this.requerente.representanteLegal.endereco.cep != undefined ? this.requerente.representanteLegal.endereco.cep : ""
        )
        .replace(
          /\[requerente.cidadeRepresentante]/g,
          this.requerente.representanteLegal.endereco.cidade != undefined ? this.requerente.representanteLegal.endereco.cidade : ""
        )
        .replace(
          /\[requerente.ufEnderecoRepresentante]/g,
          this.requerente.representanteLegal.endereco.uf != undefined ? this.requerente.representanteLegal.endereco.uf : ""
        )
        .replace(
          /\[requerente.telefone1Representante]/g,
          this.requerente.representanteLegal.telefone != undefined ? this.requerente.representanteLegal.telefone : ""
        )
        .replace(
          /\[requerente.telefone2Representante]/g,
          this.requerente.representanteLegal.telefone != undefined ? this.requerente.representanteLegal.telefone : ""
        );
    }

    let inicio = this.tipoDocumento.modeloDocumento.indexOf("[representante]");
    let final = this.tipoDocumento.modeloDocumento.lastIndexOf("[representante]");
    let representanteBloco = this.tipoDocumento.modeloDocumento.substring(inicio, final);

    this.requerente.necessitaRepresentante && this.requerente.representanteLegal != undefined
      ? (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento.replace(/\[representante]/g, ""))
      : (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(representanteBloco, "")
          .replace(/\[representante]/g, ""));
  }

  selecionarTextoConformeTipoRequerente() {
    let inicioTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.indexOf("[textoConjugeOuCompanheiro]");
    let fimTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.lastIndexOf("[textoConjugeOuCompanheiro]");
    let blocoTextoConjugeOuCompanheiro = this.tipoDocumento.modeloDocumento.substring(
      inicioTextoConjugeOuCompanheiro,
      fimTextoConjugeOuCompanheiro
    );

    let inicioTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.indexOf("[textoOutrosDependentes]");
    let fimTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.lastIndexOf("[textoOutrosDependentes]");
    let blocoTextoOutrosDependentes = this.tipoDocumento.modeloDocumento.substring(inicioTextoOutrosDependentes, fimTextoOutrosDependentes);

    this.requerente.solicitanteTipo === "conjuge" || this.requerente.solicitanteTipo === "companheiro"
      ? (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(blocoTextoOutrosDependentes, "")
          .replace(/\[textoConjugeOuCompanheiro]/g, "")
          .replace(/\[textoOutrosDependentes]/g, ""))
      : (this.tipoDocumento.modeloDocumento = this.tipoDocumento.modeloDocumento
          .replace(blocoTextoConjugeOuCompanheiro, "")
          .replace(/\[textoConjugeOuCompanheiro]/g, "")
          .replace(/\[textoOutrosDependentes]/g, ""));
  }

  private criarObjetoNovoDocumento(documento: Documento): GerarPdfUploadAlfrescoRequest {
    let matricula = this.sharedService.decodePayloadJWT().sub;
    const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
    gerarPdfUploadAlfrescoRequest.descricao = this.tipoDocumento.nomeDocumento;
    gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
    gerarPdfUploadAlfrescoRequest.descricaoAssunto = null;
    gerarPdfUploadAlfrescoRequest.html = this.tipoDocumento.modeloDocumento;
    gerarPdfUploadAlfrescoRequest.nomeDocumento = this.tipoDocumento.nomeDocumento + " MAT" + matricula + ".pdf";
    gerarPdfUploadAlfrescoRequest.docAssinantes = null;
    gerarPdfUploadAlfrescoRequest.tipoDocumentoId = documento.tipoDocumentoId;
    gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
    gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = true;
    gerarPdfUploadAlfrescoRequest.tipoDocumentoId = documento.tipoDocumento.id;

    return gerarPdfUploadAlfrescoRequest;
  }

  getClasse(documento: Documento) {
    if (!documento.ativo) return "bg-disabled";
  }

  isAssinado(documento: Documento) {
    if (documento.assinado || documento.nomeDocumento.toLocaleLowerCase().indexOf(".pdf") > -1) return false;

    return true;
  }

  public adicionarDocumento() {
    this.novoDocumento();
    this.exibicaoEditor = false;
  }

  assinarDocumento(): void {
    this.openDialogSelecionarCertificado();
  }

  openDialogSelecionarCertificado(): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate,
    });

    openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
      if (certParam != null && certParam.cert64 != null) {
        this.certificate = certParam;
        this.assinarMarcados();
      }
    });
  }

  private assinarMarcados() {
    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
    const formData: FormData = new FormData();
    formData.append("certContent", this.certificate.cert64);
    formData.append("documento", this.documentoSelecionado.docAutor); //TO DO - PORUCAR O CERTIFICADO DE QUEM TA LOGADO

    let countDocumentosAtualizados = 0;

    this.documentoService.startWithIdDocumento(this.documentoSelecionado.id, formData, SharedService.CARREGAR_LOADING).subscribe(
      (sigParams) => {
        this.pki
          .signHash({
            thumbprint: cert.thumbprint,
            hash: sigParams.toSignHash,
            digestAlgorithm: sigParams.digestAlgorithm,
          })
          .success((signature) => {
            this.documentoService
              .completeWithIdDocumento(
                this.documentoSelecionado.id,
                sigParams.fileName,
                sigParams.transferFileId,
                signature,
                sigParams.descricaoAssinatura,
                SharedService.CARREGAR_LOADING
              )
              .subscribe(
                (documentoAssinado) => {
                  countDocumentosAtualizados++;
                  if (countDocumentosAtualizados === this.selection.selected.length) {
                    // this.natureza = null;
                    // this.listarDocumentosProcesso(this.processo);
                  }
                  this.messageService.messageSuccess("Documento assinado com sucesso!");
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
          });
      },
      (msgExcecao) => {
        countDocumentosAtualizados++;
        if (msgExcecao.error != null && msgExcecao.error.message != null) {
          this.messageService.messageErro(msgExcecao.error.message);
          throw msgExcecao;
        } else {
          this.messageService.messageErro("Erro na assinatura do arquivo.");
        }
      }
    );
  }

  openDialogIndicarAssinadores(): void {
    let documentoData = new Documento();
    if (this.documentoSelecionado != null) {
      documentoData = this.documentoSelecionado;
    }

    if (this.listaAssinador.length > 0) {
      documentoData.listAssinatura = [];
      this.listaAssinador.forEach((assinador) => {
        const novaAssinatura = new Assinatura();
        novaAssinatura.assinador = assinador;
        documentoData.listAssinatura.push(novaAssinatura);
      }, this);
    }
    const dialogIndicarAssinadores = this.dialog.open(DialogIndicarAssinadoresComponent, {
      width: "600px",
      data: {
        processo: this.processo,
        documento: documentoData,
      },
    });

    dialogIndicarAssinadores.afterClosed().subscribe((documentoParam) => {
      if (documentoParam) {
        // Caso cancele no dialog
        if (this.documentoSelecionado != null) {
          // Caso o documento já exista
          this.documentoService.put(this.documentoSelecionado).subscribe(
            (documentoAtualizado) => {
              this.messageService.messageSuccess("Assinador adicionado com sucesso!");

              documentoAtualizado.listAssinatura.forEach(function (assinatura) {
                this.contribuinteService.findResponsavelPorDocumento(assinatura.responsavel.documento).subscribe(
                  (responsavel) => {
                    assinatura.responsavel.nome = responsavel ? responsavel.nome : "";
                  },
                  (error) => {
                    this.messageService.messageErro(error.error.message);
                    throw error;
                  }
                );
              }, this);
              this.documentoSelecionado.listAssinatura = documentoAtualizado.listAssinatura;
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        } else {
          // Para o novo documento
          const lista: any[] = [];
          for (const entry of documentoParam) {
            lista.push(entry);
          }

          for (const assinador of this.listaAssinador) {
            lista.push(assinador);
          }
          this.listaAssinador = lista;
        }
      }
    });
  }
  listaAssinadorChange(listaAssinador: any[]) {
    this.listaAssinador = listaAssinador;
  }
}
