import { Router, ActivatedRoute } from "@angular/router";
import { MatChipInputEvent } from "@angular/material/chips";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatDialog, MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material";

import { ToastrService } from "ngx-toastr";
import { map, startWith } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs/internal/Observable";
import { Situacao } from "src/app/core/models/situacao.model";
import { Assunto } from "src/app/core/models/assunto.model.js";
import { FaseService } from "src/app/core/api/fase.service.js";
import { ViprocService } from "src/app/core/api/viproc.service";
import { Metadado } from "src/app/global/models/metadado.model";
import { SisSegService } from "src/app/core/api/sis-seg.service";
import { TipoMetadadoEnum } from "../../../models/tipo-metadado.enum";
import { MetadadoService } from "src/app/core/api/metadado.service";
import { AssuntoService } from "src/app/core/api/assunto.service.js";
import { SituacaoService } from "src/app/core/api/situacao.service.js";
import { MessageService } from "src/app/global/message/message.service";
import { NivelSigiloEnumAll } from "src/app/core/models/nivel-sigilo.enum";
import { AssuntoMetadado } from "src/app/core/models/assunto-metadado.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { CategoriaAssuntoService } from "src/app/core/api/categoria-assunto.service";
import { PublicoAssuntoEnumAll } from "src/app/core/models/publico-assunto-enum.model";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { TipoDistribuicaoEnumAll, TipoDistribuicaoEnum } from "src/app/core/models/tipo-distribuicao-enum.model";
import { Utils } from "src/app/global/util/utils-component";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";
import { OrgaoLocal } from "src/app/core/models/orgao-local.model";
import { LotacaoSecundariaService } from "src/app/core/api/lotacao-secundaria.service";
import { Responsavel } from "src/app/core/models/responsavel.model";
import { OrgaoService } from "src/app/core/api/orgao.service";

@Component({
  selector: "app-salvar-assunto",
  templateUrl: "./salvar-assunto.component.html",
  styleUrls: ["./salvar-assunto.component.css"],
})
export class SalvarAssuntoComponent extends SalvarGenericsComponent<Assunto> implements OnInit {
  public icons: any[];
  public ngForm: FormGroup;
  public todosMetadados = [];
  public faseList: any[] = [];
  public logicaMetadado = false;
  public usuarioList: any[] = [];
  public tipoDocumentoModelo: any;
  public listTipoDocumentoModelo: OrgaoLocal[];
  public orgaoLocalList: any[] = [];
  public metadadosSelecionados = [];
  public metadadoDaeList: any[] = [];
  public metadadoList: any[] = [];
  public assuntoViprocList: string[];
  public logicaTipoDocumento = false;
  public situacaoList: Situacao[] = [];
  public tipoDocumentoList: any[] = [];
  public assuntoCategoriaList: any[] = [];
  public listTipoDocumentoAnexo: any[] = [];
  public mostraUsuarioRecebedorPadrao = false;
  public publicoList: any[] = PublicoAssuntoEnumAll;
  public nivelSigiloList: any[] = NivelSigiloEnumAll;
  public listTipoDocumentoAnexoNaoObrigatorio: any[] = [];
  public listTipoDocumentoEditor: any[] = [];
  public listTipoDistribuicao: any[] = TipoDistribuicaoEnumAll;
  public RECEBEDOR_PADRAO_ID: number = TipoDistribuicaoEnumAll.filter(function (tipoDistribuicao) {
    return tipoDistribuicao.descricao === TipoDistribuicaoEnum.RECEBEDOR_PADRAO;
  })[0].id;
  public buttons = [];
  public sections = [];

  extensoes: string[] = [".pdf"];
  extensaoCtrl = new FormControl();
  extRegex: string = "^\\.[a-zA-Z]{3,4}$";

  // CHIPS
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("extensaoInput") extensaoInput: ElementRef<HTMLInputElement>;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public faseService: FaseService,
    public orgaoService: OrgaoService,
    public apiService: AssuntoService,
    public SisSegService: SisSegService,
    public viprocService: ViprocService,
    public messageService: MessageService,
    public situacaoService: SituacaoService,
    public metadadoService: MetadadoService,
    public tipoDocumentoService: TipoDocumentoService,
    public assuntoCategoriaService: CategoriaAssuntoService,
    public lotacaoSecundariaService: LotacaoSecundariaService
  ) {
    super(formBuilder, router, apiService, messageService, route, "assunto", "");
    this.logicaMetadado = false;
    this.logicaTipoDocumento = false;
    Utils.ordernarDescricaoOrdemAlfabetica(this.listTipoDistribuicao);
  }

  ngOnInit() {
    this.logicaMetadado = false;
    this.logicaTipoDocumento = false;

    this.situacaoService.list().subscribe(
      (situacoes) => {
        this.situacaoList = situacoes.filter((situacao) => situacao.ativo);
        if (this.domain.situacaoInicial && !this.domain.situacaoInicial.ativo) {
          this.situacaoList.push(this.domain.situacaoInicial);
        }
        Utils.ordemAlfabeticaDescricoesSemCaseSensitive(this.situacaoList);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.faseService.list().subscribe(
      (fases) => {
        this.faseList = fases.filter((fase) => fase.ativo);
        if (this.domain.faseInicial && !this.domain.faseInicial.ativo) {
          this.faseList.push(this.domain.faseInicial);
        }
        Utils.ordemAlfabeticaDescricoesSemCaseSensitive(this.faseList);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assuntoCategoriaService.listarAtivas().subscribe((assuntoCategoria) => {
      this.assuntoCategoriaList = assuntoCategoria;
      this.assuntoCategoriaList.forEach(
        (assunto) => {
          assunto.assuntoCategoria = null;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    });

    this.tipoDocumentoService.getTipoDocumentoSomenteAnexo(false).subscribe(
      (tipoDucmento) => {
        this.tipoDocumentoService.getTipoDocumentoEditorPorSetor().subscribe(
          (tipoDucmentoSetor) => {
            tipoDucmento.push(...tipoDucmentoSetor);
            this.listTipoDocumentoModelo = tipoDucmento;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.tipoDocumentoService.list().subscribe(
      (tipoDocumento) => {
        this.tipoDocumentoList = tipoDocumento;
        if (!this.logicaTipoDocumento) {
          this.logicaTipoDocumento = true;
        } else {
          this.logicaTipoDocumentos();
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.orgaoService.getOrgaoLocal().subscribe(
      (orgao) => {
        this.orgaoLocalList = orgao;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    const metadado = new Metadado();
    metadado.tipo = TipoMetadadoEnum.APENAS_NUMERO;
    metadado.sistema = false;
    metadado.ativo = true;
    this.metadadoService.pesquisar(metadado, 0).subscribe((metadados) => {
      this.metadadoDaeList = metadados;
    });

    this.metadadoService.sistema().subscribe((metadados) => {
      this.todosMetadados = metadados;
      if (!this.logicaMetadado) {
        this.logicaMetadado = true;
      } else {
        this.logicaMetadados();
      }
    });

    // this.viprocService.listarAssuntoViproc().subscribe(
    //   (res) => {
    //     this.assuntoViprocList = res;
    //   },
    //   (error) => {
    //     this.messageService.messageErro(error.error.message);
    //     throw error;
    //   }
    // );

    this.domain.gerarProcessoViproc = true;

    this.sections = [
      {
        fields: [
          [
            {
              key: "pensao",
              fieldType: "radioInput",
              label: "Pensão?",
              options: [
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ],
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "descricao",
              fieldType: "textInput",
              label: "Descrição",
              required: true,
              hidden: () => this.domain.pensao,
              disabled: () => false,
              maxLength: 255,
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "descricao",
              fieldType: "selectInput",
              hidden: () => !this.domain.pensao,
              options: () => ["Pensão Civil", "Pensão Militar"],
              label: "Descrição",
              required: true,
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "faseInicial",
              fieldType: "selectInput",
              options: () => this.faseList,
              labelKey: "descricao",
              label: "Fase Inicial",
              required: true,
              cols: [12, 12, 12, 6, 6],
              infoHTML: () => `
                    <b>Situação Inicial:</b> ${
                      this.domain.faseInicial && this.domain.faseInicial.situacaoInicial
                        ? `<span>${this.domain.faseInicial.situacaoInicial.descricao}</span>`
                        : ""
                    } 
                    <br/>
                    <b>Situação Pós Ação:</b> ${
                      this.domain.faseInicial && this.domain.faseInicial.situacaoPosVisualizacao
                        ? `<span>${this.domain.faseInicial.situacaoPosVisualizacao.descricao}</span>`
                        : ""
                    }
                    <br/>
                    <b>Tipo Fase:</b>
                    ${this.domain.faseInicial != null && this.domain.faseInicial.tipoFase == 0 ? " <span>INTERNO</span>" : ""}
                    ${this.domain.faseInicial != null && this.domain.faseInicial.tipoFase == 1 ? " <span>EXTERNO</span>" : ""}
                    <br />
                  `,
            },
            {
              key: "situacaoInicial",
              fieldType: "selectInput",
              options: () => this.situacaoList,
              labelKey: "descricao",
              label: "Situação Inicial",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "assuntoCategoria",
              fieldType: "selectInput",
              options: () => this.assuntoCategoriaList,
              labelKey: "descricao",
              label: "Assunto Categoria",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "lotacaoInicial",
              fieldType: "textInput",
              rightIcon: "search",
              label: "Lotação Inicial",
              required: true,
              input: (e) => this.limparLotacaoDigitada(e.target),
              click: () => this.openDialogLotacao(),
              value: () => {
                if (this.domain.lotacaoInicial) {
                  const lotacao = this.getLotacao(this.domain.lotacaoInicial);
                  if (lotacao && lotacao.descricao) return lotacao.descricao;
                }
                return "Nenhuma";
              },
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "nivelSigilo",
              fieldType: "selectInput",
              options: () => this.nivelSigiloList,
              labelKey: "descricao",
              valueKey: "id",
              label: "Nível Sigilo",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "tipoDistribuicao",
              fieldType: "selectInput",
              noneOption: { value: "null", label: "" },
              options: () => this.listTipoDistribuicao,
              selectionChange: (e) => this.tipoDistribuicaoSelecionado(e.value),
              labelKey: "descricao",
              valueKey: "id",
              label: "Tipo de Distribuição",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "usuarioRecebedorPadrao",
              fieldType: "selectInput",
              options: () => this.usuarioList,
              hidden: () => !this.mostraUsuarioRecebedorPadrao,
              selectionChange: (e) => this.recebedorPadraoSelecionado(e.value),
              labelKey: "nome",
              label: "Usuário Recebedor Padrão",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "tipoDocumentoModelo",
              fieldType: "selectInput",
              options: () => this.filtrarAnexo(this.tipoDocumentoList, false),
              labelKey: "nomeDocumento",
              ngModel: () => this.tipoDocumentoModelo,
              ngChange: (e) => (this.tipoDocumentoModelo = e),
              label: "Modelo Documento",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "listTipoDocumentoAnexo",
              multiple: true,
              ngModel: () => this.listTipoDocumentoAnexo,
              ngChange: (e) => (this.listTipoDocumentoAnexo = e),
              hidden: () => !(this.domain.pensao === false || this.domain.pensao === undefined),
              fieldType: "selectInput",
              options: () => this.filtrarAnexo(this.tipoDocumentoList, true),
              labelKey: "nomeDocumento",
              label: "Anexo Obrigatório",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "listTipoDocumentoAnexoNaoObrigatorio",
              multiple: true,
              label: () => (this.domain.pensao ? "Documentos Associados" : "Anexo Não Obrigatório"),
              ngModel: () => this.listTipoDocumentoAnexoNaoObrigatorio,
              ngChange: (e) => (this.listTipoDocumentoAnexoNaoObrigatorio = e),
              fieldType: "selectInput",
              options: () => this.filtrarAnexo(this.tipoDocumentoList, true),
              labelKey: "nomeDocumento",
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "listTipoDocumentoEditor",
              multiple: true,
              label: "Documentos Editáveis (exibe editor de texto)",
              ngModel: () => this.listTipoDocumentoEditor,
              ngChange: (e) => (this.listTipoDocumentoEditor = e),
              fieldType: "selectInput",
              options: () => this.filtrarAnexo(this.tipoDocumentoList, false),
              labelKey: "nomeDocumento",
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "publico",
              fieldType: "selectInput",
              options: () => this.publicoList,
              labelKey: "descricao",
              valueKey: "id",
              label: "Público",
              required: true,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "extensoes",
              fieldType: "chipInput",
              options: () => this.extensoes,
              selectable: (e) => ".pdf" !== e,
              removable: (e) => ".pdf" !== e,
              removed: (e) => this.removeExtensao(e),
              label: "Extensões do Arquivo",
              infoMessage: "As extensões devem ser separadas por vírgula, seguindo o formato .XXX ou .XXXX",
              placeholder: "Extensões...",
              cantExclude: [".pdf"],
              formControl: this.extensaoCtrl,
              matChipInputTokenEnd: (e) => this.addExtensao(e),
              matChipInputSeparatorKeyCodes: this.separatorKeysCodes,
              required: true,
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "enviaNotificacao",
              fieldType: "radioInput",
              label: "Enviar Notificação*",
              cols: [12, "auto", "auto", "auto", "auto"],
              options: [
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ],
            },
            {
              key: "ativo",
              fieldType: "radioInput",
              label: "Ativo*",
              cols: [12, "auto", "auto", "auto", "auto"],
              options: [
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ],
            },
          ],
        ],
      },
      {
        title: "Metadados",
        fields: [
          [
            {
              key: "metadados",
              fieldType: "dropList",
              cols: [12, 12, 12, 12, 12],
              lists: [
                {
                  title: "Todos",
                  cdkDropListData: () => this.todosMetadados,
                  cdkDropListDropped: (e) => this.drop(e),
                },
                {
                  title: "Selecionados",
                  cdkDropListData: () => this.metadadosSelecionados,
                  cdkDropListDropped: (e) => this.drop(e),
                  toggle: {
                    change: (e) => this.onChangeSlideToggle(e),
                    label: "Requerido",
                  },
                },
              ],
            },
          ],
        ],
      },
    ];

    this.buttons = [
      {
        label: "Salvar",
        click: () => this.salvarValidator(this.domain),
        color: "primary",
      },
      {
        label: "Cancelar",
        click: () => this.router.navigateByUrl("/sgeap/assunto"),
        color: "secondary",
      },
    ];
  }

  getLotacao(id: number): any {
    if (this.orgaoLocalList.length === 0) {
      return { descricao: "" };
    }
    return this.orgaoLocalList.find((lotacao) => lotacao.id === id);
  }

  openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.selecionaLotacao(processoParam);
      }
    });
  }

  selecionaLotacao(lotacao: any) {
    this.domain.lotacaoInicial = lotacao.id;
    this.lotacaoSelecionado();
  }

  afterResponse() {
    if (this.domain != null) {
      this.extensoes = [];
      this.domain.extensao.split(",").forEach((extensao) => {
        this.extensoes.push(extensao);
      });
    }

    if (!this.logicaMetadado) {
      this.logicaMetadado = true;
    } else {
      this.logicaMetadados();
    }
    if (!this.logicaTipoDocumento) {
      this.logicaTipoDocumento = true;
    } else {
      this.logicaTipoDocumentos();
    }

    this.lotacaoSelecionado();
  }

  // Executar após receber lista de tipo de documento e o assunto.
  logicaTipoDocumentos() {
    this.tipoDocumentoModelo = this.tipoDocumentoList
      .filter((obj) => !obj.somenteAnexo)
      .filter((obj) => this.compareTipoDocumento(obj, this.domain.listTipoDocumento))[0];
    this.listTipoDocumentoAnexo = this.tipoDocumentoList
      .filter((obj) => obj.somenteAnexo)
      .filter((obj) =>
        this.compareTipoDocumento(
          obj,
          this.domain.listTipoDocumento.filter((obj) => obj.obrigatorio === true)
        )
      );
    this.listTipoDocumentoAnexoNaoObrigatorio = this.tipoDocumentoList
      .filter((obj) => obj.somenteAnexo)
      .filter((obj) =>
        this.compareTipoDocumento(
          obj,
          this.domain.listTipoDocumento.filter((obj) => obj.obrigatorio === false)
        )
      );
    this.listTipoDocumentoEditor = this.tipoDocumentoList
      .filter((obj) => obj.somenteAnexo === false)
      .filter((obj) => this.compareTipoDocumento(obj, this.domain.listTipoDocumento));
  }

  // Executar após receber lista de metadados e o assunto.
  logicaMetadados() {
    this.domain.listMetadado.forEach((assuntoMetadado) => {
      const metadado = this.todosMetadados.filter((met) => met.id === assuntoMetadado.metadado.id);
      if (metadado.length > 0) {
        this.todosMetadados.splice(this.todosMetadados.indexOf(metadado[0]), 1);
        assuntoMetadado.metadado.requerido = assuntoMetadado.requerido;
        this.metadadosSelecionados[assuntoMetadado.ordem - 1] = assuntoMetadado.metadado;
      }
    });
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }
  compareObj(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  compareUsuario(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.documento === obj2.documento : obj1 === obj2;
  }

  compareTipoDistribuicao(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  compareAssuntoViproc(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  compareTipoDocumento(obj1: any, obj2: any): boolean {
    if (Array.isArray(obj2)) {
      for (let i = 0; i < obj2.length; i++) {
        if (obj1.id === obj2[i].tipoDocumento) {
          return true;
        }
      }
      return false;
    }
    return obj1 && obj2 ? obj1.id === obj2.tipoDocumento : obj1 === obj2;
  }

  filtrarAnexo(listaDoc: TipoDocumento[], listarSomenteAnexo: boolean): any {
    return listaDoc.filter((doc) => doc.ativo).filter((doc) => doc.somenteAnexo === listarSomenteAnexo);
  }

  addExtensao(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (!new RegExp(this.extRegex).test(value)) {
      this.messageService.messageErro("Extensão inválida.");
      return;
    }
    if (this.extensoes.indexOf(value) > -1) {
      this.messageService.messageErro("Extensão já adicionada na lista.");
      return;
    }

    if ((value || "").trim()) {
      this.extensoes.push(value.trim());
    }

    if (input) {
      input.value = "";
    }

    this.extensaoCtrl.setValue(null);
  }

  removeExtensao(extensao: string): void {
    const index = this.extensoes.indexOf(extensao);

    if (index >= 0) {
      this.extensoes.splice(index, 1);
    }
  }

  extensaoSelecionada(event: MatAutocompleteSelectedEvent): void {
    this.extensoes.push(event.option.viewValue);

    this.extensaoInput.nativeElement.value = "";

    this.extensaoCtrl.setValue(null);
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (!domain.faseInicial) {
      this.messageService.messageErro("Selecione a Fase Inicial.");
      validacao = false;
    }
    if (!domain.assuntoCategoria) {
      this.messageService.messageErro("Selecione o Assunto Categoria.");
      validacao = false;
    }
    if (!this.tipoDocumentoModelo) {
      this.messageService.messageErro("Selecione um Modelo Documento.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    if (domain.situacaoInicial == null) {
      this.messageService.messageErro("Selecione o campo Situação Inicial.");
      validacao = false;
    }

    if (!domain.lotacaoInicial) {
      this.messageService.messageErro("Selecione a Lotação inicial.");
      validacao = false;
    }

    if (domain.publico == null) {
      this.messageService.messageErro("Selecione o campo Público.");
      validacao = false;
    }

    if (domain.nivelSigilo == null) {
      this.messageService.messageErro("Selecione o Nível de Sigilo.");
      validacao = false;
    }

    if (domain.tipoDistribuicao == null) {
      this.messageService.messageErro("Selecione o campo Tipo de Distribuição.");
      validacao = false;
    }

    if (domain.enviaNotificacao == null) {
      this.messageService.messageErro("Preencha o campo Enviar Notificação.");
      validacao = false;
    }

    if (domain.tipoDistribuicao === this.RECEBEDOR_PADRAO_ID && !domain.usuarioRecebedorPadrao) {
      this.messageService.messageErro("Selecione o campo Usuário Recebedor Padrão.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      domain.extensao = this.extensoes
        .map((e) => {
          return e.startsWith(".") ? e : "." + e;
        })
        .map((i) => {
          return i;
        })
        .join(",");

      domain.listTipoDocumento = this.listTipoDocumentoAnexo.map((documento) => {
        return { tipoDocumento: documento.id, obrigatorio: 1 };
      });
      this.listTipoDocumentoAnexoNaoObrigatorio.map((documento) => {
        domain.listTipoDocumento.push({ tipoDocumento: documento.id, obrigatorio: 0 });
      });
      this.listTipoDocumentoEditor.map((documento) => {
        domain.listTipoDocumento.push({ tipoDocumento: documento.id, obrigatorio: 0 });
      });
      domain.listTipoDocumento.unshift({ tipoDocumento: this.tipoDocumentoModelo.id, obrigatorio: 1 });
      // Criando relacionamento entre Assunto e AssuntoMetadado
      let index = 1;
      domain.listMetadado = [];
      this.metadadosSelecionados.forEach((metadado) => {
        const assuntoMetadado = new AssuntoMetadado();
        assuntoMetadado.ordem = index++;
        assuntoMetadado.metadado = metadado;
        assuntoMetadado.requerido = !!metadado.requerido;
        domain.listMetadado.push(assuntoMetadado);
      });
      if (domain.tipoDistribuicao !== this.RECEBEDOR_PADRAO_ID) {
        domain.usuarioRecebedorPadrao = null;
      }
      this.submeter();
    }
  }

  onChangeSlideToggle(metadado: Metadado) {
    metadado.requerido = !metadado.requerido;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  tipoDistribuicaoSelecionado(tipo: any) {
    if (tipo === this.RECEBEDOR_PADRAO_ID && this.domain.lotacaoInicial) {
      //this.messageService.messageWarning("Implementar consulta de servidores por orgão.");
      this.SisSegService.listServidoresPorOrgao(this.domain.lotacaoInicial).subscribe(
        (res) => {
          this.mostraUsuarioRecebedorPadrao = true;
          this.usuarioList = res;
          if (res) {
            Utils.ordernarNomeOrdemAlfabetica(this.usuarioList);
          }

          // Consulta os servidores com a lotacao secundaria igual a selecionada
          this.lotacaoSecundariaService.consultaServidoresPorOrgao(this.domain.lotacaoInicial).subscribe(
            (servidores) => {
              if (servidores) {
                if (this.usuarioList) {
                  this.usuarioList.push(...servidores);
                } else {
                  this.usuarioList = servidores;
                }
                Utils.ordernarNomeOrdemAlfabetica(this.usuarioList);
              }
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    } else {
      this.mostraUsuarioRecebedorPadrao = false;
    }
  }

  lotacaoSelecionado() {
    if (this.domain.tipoDistribuicao === this.RECEBEDOR_PADRAO_ID) {
      //this.messageService.messageWarning("Implementar consulta de servidores por orgão.");
      this.SisSegService.listServidoresPorOrgao(this.domain.lotacaoInicial).subscribe(
        (res) => {
          this.mostraUsuarioRecebedorPadrao = true;
          this.usuarioList = res;

          // Consulta os servidores com a lotacao secundaria igual a selecionada
          this.lotacaoSecundariaService.consultaServidoresPorOrgao(this.domain.lotacaoInicial).subscribe(
            (servidores) => {
              if (servidores) {
                if (this.usuarioList) {
                  this.usuarioList.push(...servidores);
                } else {
                  this.usuarioList = servidores;
                }
                Utils.ordernarNomeOrdemAlfabetica(this.usuarioList);
              }
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  recebedorPadraoSelecionado(recebedorPadrao: any) {
    this.domain.usuarioRecebedorPadrao.documento = recebedorPadrao.documento;
  }

  private limparLotacaoDigitada(event: any) {
    event.value = "";
  }

  limparFatorMultiplicador() {
    if (!this.domain.gerarDae) {
      this.domain.fatorMultiplicador = new Metadado();
    }
  }
}
