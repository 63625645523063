import { Domain } from "src/app/global/models/domain.model";
import { SituacaoItemNaoSimilaridadeEnum } from "./situacao-item-nao-similaridade-enum.enum";
import { Processo } from "./processo.model";

export class ItemNaoSimilaridade extends Domain {
    public id: number;
    public numeroItem: number;
    public ncm: string;
    public descricao: string;
    public custo: number;
    public processo: Processo;
    public situacao: number;
    public justificativa: string;
}