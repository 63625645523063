import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class PermissaoService extends ApiService {

    API_URL: string;

    constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
        super(http, "sisseg/permissao", config);
        this.API_URL = config.API_SISSEG_URL;
    }

    pesquisarDisponiveisPorPerfil(idPerfil: number): Observable<any> {
        return this.http.get(`${this.API_URL}${this.resource}/disponivel-perfil/${idPerfil}`);
    }

    pesquisarPorPerfil(idPerfil: number): Observable<any> {
        return this.http.get(`${this.API_URL}${this.resource}/perfil/${idPerfil}`);
    }
}