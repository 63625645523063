export enum TipoValorParametroEnum {
  NUMERO = ("Número"),
  DATA =  ("Data"),
  TEXTO = ("Texto"),
  TEMPLATE_HTML = ("Template HTML")
}
  
export const TipoValorParametroEnumAll = [
  { id: 0, descricao: TipoValorParametroEnum.NUMERO },
  { id: 1, descricao: TipoValorParametroEnum.DATA },
  { id: 2, descricao: TipoValorParametroEnum.TEXTO },
  { id: 3, descricao: TipoValorParametroEnum.TEMPLATE_HTML },
];