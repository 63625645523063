import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, ElementRef } from "@angular/core";
import { FaseService } from "../../api/fase.service";
import { Fase } from "../../models/fase.model";
import { Situacao } from "../../models/situacao.model";
import { SituacaoService } from "../../api/situacao.service";
import { ProcessoPequisa } from "../../models/processo-pesquisa.model";
import { ProcessoService } from "../../api/processo.service";
import { Processo } from "../../models/processo.model";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { AssuntoService } from "../../api/assunto.service";
import { Assunto } from "../../models/assunto.model";
import { DocumentoService } from "../../api/documento.service";
import { Documento } from "../../models/documento.model";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { Subject, Observable, EMPTY } from "rxjs";
import { MatDialog, MatTabGroup, MatTableDataSource, MatPaginator, MatDatepickerInputEvent, MatSort } from "@angular/material";
import { MovimentacaoService } from "../../api/movimentacao.service";
import { Movimentacao } from "../../models/movimentacao.model";
import { DialogAlterarFaseComponent } from "../dialog-alterar-fase/dialog-alterar-fase.component";
import { DialogAlterarSituacaoComponent } from "../dialog-alterar-situacao/dialog-alterar-situacao.component";
import { DialogMovimentacaoProcessoComponent } from "../dialog-movimentacao-processo/dialog-movimentacao-processo.component";
import { MessageService } from "../../../global/message/message.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { SelectionModel } from "@angular/cdk/collections";
import { DialogIndicarAssinadoresComponent } from "../dialog-indicar-assinadores/dialog-indicar-assinadores.component";
import { DialogTipoDocumentoComponent } from "../dialog-tipo-documento/dialog-tipo-documento.component";
import { Metadado } from "../../../global/models/metadado.model";
import { SharedService } from "../../../global/api/shared.service";
import LacunaWebPKI from "web-pki";
import { environment } from "../../../../environments/environment";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Assinador } from "../../models/assinador.model";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { Certificate } from "../../../global/models/certificate.model";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { SisSegService } from "../../api/sis-seg.service";
import { MovimentacaoDTO } from "../../models/movimentacao-dto.model";
import { Responsavel } from "../../models/responsavel.model";
import { NivelSigiloEnumAll } from "../../models/nivel-sigilo.enum";
import { LoadingScreenService } from "src/app/global/api/loading-screen";
import { DialogArquivarComponent } from "../dialog-arquivar/dialog-arquivar.component";
import { DialogConfirmarComponent } from "../dialog-confirmar/dialog-confirmar.component";
import { Pessoa } from "../../models/pessoa.model";
import { Assinatura } from "../../models/assinatura.model";
import { Utils } from "../../../global/util/utils-component";
import { CategoriaAssuntoService } from "../../api/categoria-assunto.service";
import { OrgaoService } from "../../api/orgao.service";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { ProcessoMovimentacao } from "../../models/processo-movimentacao.model";
import { LotacaoSecundariaService } from "../../api/lotacao-secundaria.service";
import moment, * as _moment from "moment";
import { ProcessoTabelaDto } from "../../models/processo-tabela-dto.model";
import { DialogSelecionarModeloComponent } from "../dialog-selecionar-modelo/dialog-selecionar-modelo.component";
import { Modelo } from "../../models/modelo.model";
import { DialogJustificativaComponent } from "../dialog-justificativa/dialog-justificativa.component";
import { Contribuinte } from "../../models/contribuinte.model";
import { LotacaoSecundariaDto } from "../../models/lotacao-secundaria-dto.model";
import { MetadadoValor } from "../../models/metadado-valor.model";
import { DocumentoSaveRequest } from "../../models/documento-save-request.model";
import { TipoArquivamentoEnumAll } from "../../models/tipo-arquivamento-enum.model";
import { TipoDocumentoMetadado } from "../../models/tipo-documento-metadado.model";
import { DialogListarPendenciasProcesso } from "../dialog-listar-pendencias-processo/dialog-listar-pendencias-processo.component";
import { PendenciaProcessoDTO } from "../../models/pendencia-processo-dto.model";
import { DialogAdicionarOcorrenciaProcesso } from "../dialog-adicionar-ocorrencias/dialog-adicionar-ocorrencias-processo.component";
import { OcorrenciaService } from "../../api/ocorrencia.service";
import { OcorrenciaListarDTO } from "../../models/ocorrencia-listar-dto";
import { DialogIndicarEdowebComponent } from "../dialog-indicar-edoweb/dialog-indicar-edoweb.component";
import { DialogVincularProcessosRelacionadosComponent } from "../dialog-vincular-processos-relacionados/dialog-vincular-processos-relacionados.component";
import { Publicacao } from "../../models/publicacao-dto.model";
import { PublicacaoService } from "../../api/publicacao.service";
import { ViprocService } from "../../api/viproc.service";

@Component({
  selector: "app-consultar-processo",
  templateUrl: "./consultar-processo.component.html",
  styleUrls: ["./consultar-processo.component.css"],
})
export class ConsultarProcessoComponent implements OnInit, OnDestroy {
  @ViewChild("tabs") tabs: MatTabGroup;
  @ViewChild("myckeditor") ckeditor: any;
  @ViewChild("pdfDocumento") pdfDocumento;
  @ViewChild("file") file;
  @ViewChild("selectResponsavel") selectResponsavel;
  @ViewChild("invisibleButton") invisibleButton: ElementRef;
  @ViewChild("fileInput") fileUpload: ElementRef;

  public PAGE_SIZE = SharedService.PAGE_SIZE;

  public fases: Fase[] = [];
  public situacoes: Situacao[] = [];
  public processoPesquisa: ProcessoPequisa;
  public dataInicioInput: string;
  public dataFinalInput: string;
  public dataInicioArquivamentoInput: string;
  public dataFimArquivamentoInput: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public dataInicialArquivamento: Date;
  public dataFinalArquivamento: Date;
  public processos: Processo[] = [];
  public pendenciaProcesso: PendenciaProcessoDTO;
  public categoriaAssuntoList: any[] = [];
  public processo: Processo;
  public titleProcesso: string;
  public isEnviarViproc: boolean;
  public usuarioViproc: string;
  public senhaViproc: string;

  public titleDadosEnvolvidos: string;
  public titleDocumento: string;
  public titleMovimentacao: string;
  public titleOcorrencia: string;
  public titleItemNaoSimilaridade: string;
  public assunto: Assunto;
  public assuntos: Assunto[] = [];
  public documentos: Documento[] = [];
  public documentoNovo: Documento;
  public documentoSelecionado: Documento;
  public tiposDocumentos: TipoDocumento[] = [];
  public movimentacao: Movimentacao;
  public responsavel: Contribuinte;
  public forcarOrdemAssinatura = false;
  public natureza: number;
  public exibicaoEditor = false;
  public isDocumentoAssinado = false;
  public listaMetadado: Metadado[] = [];
  public autor: string;
  public exibirPdfDocumento = false;
  public exibirVIsualizadorDocumento = true;
  public urlDocumentoPdf: string;
  public listaAssinador: Assinador[] = [];
  public safeUrl: SafeResourceUrl;
  private certificate: Certificate;
  public documentoAssinado: DocumentoDTO;
  public listMetadadoId: number[] = [];
  public listMetadado: Metadado[] = [];
  public orgaoLocalList: any[] = [];
  public responsaveisList: Responsavel[] = [];
  public documentoUsuarioLogado: string;
  public validaInputResponsavelAtual = false;
  public updateEventosItemNaoSimilaridade = false;
  public isAnexo = Boolean;

  public podeMovimentar: boolean = false;

  searchRespCtrl = new FormControl();
  searchRespAtualCtrl = new FormControl();
  isLoading = false;
  responsaveis: any[];
  responsaveisAtual: any[];
  responsavelAtualObjeto: Responsavel;
  nenhumResponsavelAtualObjeto: Responsavel;

  searchRespArquivamentoCtrl = new FormControl();
  isLoadingArquivamento = false;
  responsaveisArquivamento: any[];

  ocorrencias: OcorrenciaListarDTO[];

  private nomeArquivo: string;

  public displayedProcessosColumns: string[] = [
    "select",
    "id",
    "numero",
    "informacoes",
    "autor",
    "relacionamentos",
    "lotacao",
    "dataCriacao",
    "observado",
  ];
  public displayedDocumentosColumns: string[] = [
    "select",
    "id",
    "arquivo",
    "numero",
    "tipoDocumento",
    "descricao",
    "dataHora",
    "substituir",
    "delete",
    "visibilidade",
    "could",
  ];
  public displayedMovimentacoesColumns: string[] = ["localizacao", "informacoes", "fase", "situacao", "permanencia", "datahora"];
  //public displayedMovimentacoesColumns: string[] = ["lotacao", "localizacao", "informacoes", "fase", "situacao", "permanencia", "datahora"];

  public selection = new SelectionModel<Documento>(true, []);
  public selectionProcesso = new SelectionModel<ProcessoTabelaDto>(true, []);

  public abaAtiva = new FormControl(0);
  public style = "tabela-processo";

  public dataSourceDocumento: MatTableDataSource<Documento>;
  @ViewChild("paginatorDocumento") paginatorDocumento: MatPaginator;

  public dataSourceMovimentacao: MatTableDataSource<Movimentacao>;
  @ViewChild("paginatorMovimentacao") paginatorMovimentacao: MatPaginator;

  public dataSourceOcorrencias: MatTableDataSource<OcorrenciaListarDTO>;
  @ViewChild("paginatorOcorrencias") paginatorOcorrencias: MatPaginator;

  public dataSourceProcessosRelacionados: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessosRelacionados") paginatorProcessosRelacionados: MatPaginator;

  public dataSourcePublicacao: MatTableDataSource<Publicacao>;
  @ViewChild("dataSourcePublicacao") paginatorPublicacao: MatPaginator;

  public displayedOcorrenciasColumns: string[] = [
    "processo",
    "documento",
    "pessoa",
    "tipoRelacionamento",
    "situacao",
    "datahora",
    "visibilidade",
  ];

  public displayedProcessosRelacionadosColumns: string[] = [
    "processo",
    "faseAtual",
    "autor",
    "situacaoAtual",
    "representante",
    "dataCriacao",
  ];

  public displayedPublicacaoColumns: string[] = ["numeroViproc", "dataPublicacao", "tipoBeneficio", "local"];

  public dataSourceProcesso: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild(MatPaginator, { read: false }) paginatorProcesso: MatPaginator;

  public resultsLengthProcesso = 0;
  public hiddenResultsProcesso = true;
  public isRateLimitReachedProcesso = false;

  public highlightedMovimentacaoRows: any[] = [];
  public highlighteProcessoRows: any[] = [];
  public highlightedOcorrenciasRows: any[] = [];
  public highlightedProcessosRelacionadosRows: any[] = [];
  public highlightedPublicacaoRows: any[] = [];
  protected _onDestroy = new Subject<void>();
  public notUsuarioExterno: boolean;

  public ckeConfig: any;
  public mycontent: string;
  public abaConsultaProcesso = true;

  public nivelSigiloEnumAll: any[] = NivelSigiloEnumAll;

  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);

  form: FormGroup;
  public nivelSigiloProcesso: any;

  public tipoArquivamento: any;
  public tipoArquivamentoList: any[] = TipoArquivamentoEnumAll;
  private documentoSubstituir: Documento;
  titleProcessosRelacionados: string;
  titlePublicacao: string;
  public isPossuiPermissaoParaAlterarFaseOuSituacao: boolean;

  constructor(
    private faseService: FaseService,
    private situacaoService: SituacaoService,
    private assuntoService: AssuntoService,
    private documentoService: DocumentoService,
    private processoService: ProcessoService,
    private viprocService: ViprocService,
    private categoriaService: CategoriaAssuntoService,
    private formBuilder: FormBuilder,
    private tipoDocumentoService: TipoDocumentoService,
    public movimentacaoService: MovimentacaoService,
    public contribuinteService: ContribuinteService,
    public orgaoService: OrgaoService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private contribuinteSevice: ContribuinteService,
    private sisSegService: SisSegService,
    private lotacaoSecundariaService: LotacaoSecundariaService,
    private sharedService: SharedService,
    protected route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private loadingScreenService: LoadingScreenService,
    private _elementRef: ElementRef,
    public ocorrenciaService: OcorrenciaService,
    public publicacaoService: PublicacaoService
  ) {
    this.processoPesquisa = new ProcessoPequisa();
    this.processoPesquisa.tipoArquivamento = null;
    this.form = this.formBuilder.group({});
    this.certificate = new Certificate();
    this.dataSourceProcesso = new MatTableDataSource<ProcessoTabelaDto>([]);

    this.dataSourceDocumento = new MatTableDataSource<Documento>(this.documentos);
    this.dataSourceDocumento.paginator = this.paginatorDocumento;
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
  }

  ngOnInit() {
    this.notUsuarioExterno = this.sharedService.verificaRolePessoaLogada("frontend.perfil-user-externo");

    this.nenhumResponsavelAtualObjeto = new Responsavel();
    this.nenhumResponsavelAtualObjeto.nome = "NENHUM";
    this.nenhumResponsavelAtualObjeto.id = 0;
    this.nenhumResponsavelAtualObjeto.documento = "0";

    this.dataSourceProcesso.paginator = this.paginatorProcesso;
    const id = this.route.snapshot.params.id;
    this.documentoUsuarioLogado = JSON.parse(this.sharedService.getDocumentoAstra());
    // tslint:disable-next-line:triple-equals
    if (id != null || id != undefined) {
      this.atualizarInformacoesComProcesso(id, 0);
      this.abaConsultaProcesso = false;
    } else {
      this.abaAtiva.setValue(0);
    }

    this.faseService.list().subscribe(
      (fases) => {
        this.fases = fases;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.situacaoService.list().subscribe(
      (situacoes) => {
        this.situacoes = situacoes;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.tipoDocumentoService.list().subscribe(
      (tiposDocumentos) => {
        this.tiposDocumentos = tiposDocumentos;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assuntoService.list().subscribe(
      (assuntos) => {
        this.assuntos = assuntos;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.categoriaService.listarAtivas().subscribe(
      (categoriaAssunto) => {
        this.categoriaAssuntoList = categoriaAssunto;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.orgaoService.getOrgaoLocal().subscribe(
      (orgaoLocal) => {
        this.orgaoLocalList = orgaoLocal;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;

    this.exibirPdfDocumento = false;

    this.confiCkEditor();

    this.autocompleteNomeParte();
    this.autocompleteNomeResponsavel();
    this.autocompleteNomeResponsavelArquivamento();
    this.isPossuiPermissaoParaAlterarFaseOuSituacao = this.sharedService.verificaRolePessoaLogada(
      "fase.atualizar-fase",
      "situacao.atualizar-situacao" // Permissao de alterar fase ou situação de um processo
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private autocompleteNomeParte() {
    this.searchRespCtrl.valueChanges
      .pipe(
        debounceTime(750),
        tap(() => {
          this.responsaveis = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.buscarPartePorNome(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          this.responsaveis = data;
        },
        (error) => {
          this.messageService.messageErro("Erro ao buscar por '" + this.searchRespCtrl.value + "' " + error.error.message);
          this.searchRespCtrl.reset();
          this.autocompleteNomeParte();
          throw error;
        }
      );
  }

  // Buscar partes envolvidas no processo
  // @param parteNome - Nome da parte

  private buscarPartePorNome(parteNome: string): Observable<any> {
    if (parteNome !== undefined && parteNome !== null && parteNome.trim() !== "" && parteNome.length > 3) {
      return this.contribuinteService.buscarPartePorNome(parteNome.trim());
    }
    return EMPTY;
  }

  private autocompleteNomeResponsavel() {
    this.searchRespAtualCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.responsaveisAtual = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.buscarResponsavelPorParteNome(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          this.responsaveisAtual = data;
        },
        (error) => {
          this.messageService.messageErro("Erro ao buscar por '" + this.searchRespAtualCtrl.value + "' " + error.error.message);
          this.searchRespAtualCtrl.reset();
          this.autocompleteNomeResponsavel();
          throw error;
        }
      );
  }

  private autocompleteNomeResponsavelArquivamento() {
    this.searchRespArquivamentoCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.responsaveisArquivamento = [];
          this.isLoadingArquivamento = true;
        }),
        switchMap((value) =>
          this.buscarResponsavelPorParteNome(value).pipe(
            finalize(() => {
              this.isLoadingArquivamento = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          this.responsaveisArquivamento = data;
        },
        (error) => {
          this.messageService.messageErro("Erro ao buscar por '" + this.searchRespArquivamentoCtrl.value + "' " + error.error.message);
          this.searchRespArquivamentoCtrl.reset();
          this.autocompleteNomeResponsavelArquivamento();
          throw error;
        }
      );
  }

  private buscarResponsavelPorParteNome(parteNome: any): Observable<any> {
    if (parteNome !== undefined && parteNome !== null && parteNome.trim() !== "") {
      //this.messageService.messageWarning("Implementar consulta de servidores por nome.");
      return this.sisSegService.pesquisarServidorPorNome(parteNome.trim());
    }
    return EMPTY;
  }

  // Chamada da função na página
  public setNomeParte(documento: string) {
    this.processoPesquisa.cpfCnpj = documento;
    this.pesquisar(0, this.PAGE_SIZE);
  }

  private setNomeResponsavel(documento: string) {
    this.processoPesquisa.cpfCnpjRepresentante = documento;
  }

  private setNomeResponsavelArquivamento(documento: string) {
    this.processoPesquisa.documentoArquivamento = documento;
  }

  private limparPesquisa() {
    this.processo = null;
    this.abaAtiva.setValue(0);
    this.exibirPdfDocumento = false;
    this.processos = [];
    this.listMetadado = [];
    this.dataSourceProcesso.data = [];
    this.resultsLengthProcesso = 0;
    this.hiddenResultsProcesso = true;
    this.selectionProcesso.clear();
  }

  // Chamada da função na página
  private limparBusca() {
    this.processoPesquisa.numeroProcessoViproc = null;
    this.processoPesquisa.nomeParte = null;
    this.processoPesquisa.cpfCnpj = null;
    this.processoPesquisa.cpfCnpjRepresentante = null;
    this.processoPesquisa.faseId = null;
    this.processoPesquisa.situacaoId = null;
    this.processoPesquisa.assuntoCategoriaId = null;
    this.processoPesquisa.assuntoId = null;
    this.dataInicial = null;
    this.dataFinal = null;
    this.dataInicialArquivamento = null;
    this.dataFinalArquivamento = null;
    this.processoPesquisa.dataInicial = null;
    this.processoPesquisa.dataFinal = null;
    this.processoPesquisa.dataInicialArquivamento = null;
    this.processoPesquisa.dataFinalArquivamento = null;
    this.searchRespCtrl.reset();
    this.searchRespAtualCtrl.reset();
    this.searchRespArquivamentoCtrl.reset();
    this.processoPesquisa.lotacaoAtual = [];
    this.processoPesquisa.lotacaoResponsavel = [];
    this.processoPesquisa.tipoArquivamento = null;
    this.processoPesquisa.documentoArquivamento = null;
    this.limparPesquisa();
  }

  public pageEventProcesso(event) {
    this.pesquisar(event.pageIndex, event.pageSize);
  }

  pesquisar(page: number, size: number) {
    this.limparPesquisa();
    this.selection.clear();
    if (page === 0) {
      if (this.paginatorProcesso != null) {
        this.paginatorProcesso.pageIndex = 0;
      }
    }
    this.processoPesquisa.consultaPrincipal = true;

    if (this.dataInicial != null) {
      this.processoPesquisa.dataInicial = Utils.formatDate(this.dataInicial);
    }

    if (this.dataFinal != null) {
      this.processoPesquisa.dataFinal = Utils.formatDate(this.dataFinal);
    }

    if (this.dataInicialArquivamento != null) {
      this.processoPesquisa.dataInicialArquivamento = Utils.formatDate(this.dataInicialArquivamento);
    }

    if (this.dataFinalArquivamento != null) {
      this.processoPesquisa.dataFinalArquivamento = Utils.formatDate(this.dataFinalArquivamento);
    }

    if (this.processoPesquisa.numeroProcessoViproc === "") {
      this.processoPesquisa.numeroProcessoViproc = null;
    }

    if (this.processoPesquisa.cpfCnpj === "") {
      this.processoPesquisa.cpfCnpj = null;
    }

    if (this.processoPesquisa.cpfCnpjRepresentante === "") {
      this.processoPesquisa.cpfCnpjRepresentante = null;
    }

    if (this.processoPesquisa.documentoArquivamento === "") {
      this.processoPesquisa.documentoArquivamento = null;
    }

    if (
      !this.processoPesquisa.assuntoId &&
      !this.processoPesquisa.cpfCnpj &&
      !this.processoPesquisa.faseId &&
      !this.processoPesquisa.numeroProcessoViproc &&
      !this.processoPesquisa.situacaoId &&
      !this.processoPesquisa.dataInicial &&
      !this.processoPesquisa.dataFinal &&
      !this.processoPesquisa.dataInicialArquivamento &&
      !this.processoPesquisa.dataFinalArquivamento &&
      !this.processoPesquisa.cpfCnpjRepresentante &&
      !this.processoPesquisa.assuntoCategoriaId &&
      this.processoPesquisa.lotacaoAtual.length === 0 &&
      this.processoPesquisa.lotacaoResponsavel.length === 0 &&
      !this.processoPesquisa.tipoArquivamento &&
      !this.processoPesquisa.documentoArquivamento
    ) {
      this.messageService.messageWarning("Necessário preencher no mínimo um filtro.");
    } else {
      this.processoService.consultar(this.processoPesquisa, page, size).subscribe(
        (processos) => {
          if (processos == null || processos.content == null) {
            this.processo = null;
            this.messageService.messageInfo("Nenhum processo encontrado!");
            this.hiddenResultsProcesso = true;
            return;
          }

          this.processos = processos.content;
          this.dataSourceProcesso.data = processos.content;
          this.resultsLengthProcesso = processos.totalElements;

          if (processos.totalElements === 0) {
            this.processo = null;
            this.messageService.messageInfo("Nenhum processo encontrado!");
            this.hiddenResultsProcesso = true;
          } else {
            this.hiddenResultsProcesso = false;
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          this.hiddenResultsProcesso = true;
          throw error;
        }
      );
    }
  }

  pesquisarComOrdenacao(sort: MatSort) {
    if (sort.direction == "") {
      this.processoService.consultar(this.processoPesquisa, this.paginatorProcesso.pageIndex, this.paginatorProcesso.pageSize).subscribe(
        (processos) => {
          if (processos == null || processos.content == null) {
            this.processo = null;
            this.messageService.messageInfo("Nenhum processo encontrado!");
            this.hiddenResultsProcesso = true;
            return;
          }
          this.processos = processos.content;
          this.dataSourceProcesso.data = processos.content;
          this.resultsLengthProcesso = processos.totalElements;

          if (processos.totalElements === 0) {
            this.processo = null;
            this.messageService.messageInfo("Nenhum processo encontrado!");
            this.hiddenResultsProcesso = true;
          } else {
            this.hiddenResultsProcesso = false;
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          this.hiddenResultsProcesso = true;
          throw error;
        }
      );
    } else {
      this.processoService
        .consultarOrdenado(
          this.processoPesquisa,
          this.paginatorProcesso.pageIndex,
          this.paginatorProcesso.pageSize,
          sort.active,
          sort.direction
        )
        .subscribe(
          (processos) => {
            if (processos == null || processos.content == null) {
              this.processo = null;
              this.messageService.messageInfo("Nenhum processo encontrado!");
              this.hiddenResultsProcesso = true;
              return;
            }
            this.processos = processos.content;
            this.dataSourceProcesso.data = processos.content;
            this.resultsLengthProcesso = processos.totalElements;

            if (processos.totalElements === 0) {
              this.processo = null;
              this.messageService.messageInfo("Nenhum processo encontrado!");
              this.hiddenResultsProcesso = true;
            } else {
              this.hiddenResultsProcesso = false;
            }
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            this.hiddenResultsProcesso = true;
            throw error;
          }
        );
    }
  }

  mudarAba($event) {
    // tslint:disable-next-line:triple-equals
    if ($event.index == 0) {
      this.abaAtiva.setValue(0);
    }
  }

  getProcesso(processoId: number) {
    this.validaInputResponsavelAtual = false;
    this.atualizarInformacoesComProcesso(processoId, 1);
  }

  atualizarInformacoesComProcesso(id: number, idAba: number) {
    this.processoService.get(id).subscribe(async (processo) => {
      this.verificarPermissaoMovimentarProcesso(id);
      this.highlighteProcessoRows = [];
      this.highlighteProcessoRows.push(processo);
      this.processo = processo;
      this.buscarOcorrenciasPorProcesso();
      this.buscarPublicacaoPorProcesso();
      if (idAba) {
        this.abaAtiva.setValue(idAba);
      }
      this.natureza = null;
      this.novoDocumento();
      this.atualizarComboResponsavelAtual(processo);
      this.buscarProcessosRelacionados();

      this.processo.listMovimentacao = this.processo.listMovimentacao.sort(function (m1, m2) {
        return m2.dataCriacao - m1.dataCriacao;
      });
      this.listMetadado = [];
      this.nivelSigiloProcesso = NivelSigiloEnumAll.filter((nivelSigilo) => nivelSigilo.id === processo.nivelSigilo)[0];

      let assuntoId: number;
      if (processo.assunto.id) {
        assuntoId = processo.assunto.id;
      } else {
        assuntoId = processo.assunto;
      }

      await this.getAssunto(assuntoId);
      this.listarDocumentosProcesso(processo);

      this.processo.listMovimentacao.forEach((movimentacao) => {
        if (movimentacao.responsavel != null && movimentacao.nomeResponsavel == undefined) {
          this.sisSegService.consultarServidorByDocumento(movimentacao.responsavel).subscribe(
            (responsavel) => {
              movimentacao.nomeResponsavel = responsavel.nome;
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
        if (movimentacao.representante != null) {
          this.contribuinteSevice.findByDocumento(movimentacao.representante.documento).subscribe(
            (responsavel) => {
              movimentacao.representante.pessoa = new Pessoa();
              movimentacao.representante.pessoa.nome = responsavel.nome;
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
        return movimentacao;
      });

      this.listarMovimentacoesProcesso(processo);

      if (processo.solicitante != null) {
        this.contribuinteSevice.findResponsavelPorDocumento(processo.solicitante.documento).subscribe(
          (responsavel) => {
            this.responsavel = responsavel;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }

      this.titleProcesso = `PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleDocumento = `DOCUMENTOS DO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleDadosEnvolvidos = `DADOS DOS ENVOLVIDOS NO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleMovimentacao = `MOVIMENTAÇÕES DO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleOcorrencia = `OCORRÊNCIAS DO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleProcessosRelacionados = `RELACIONADOS AO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titlePublicacao = `PUBLICAÇÕES AO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
      this.titleItemNaoSimilaridade = `ITENS NÃO SIMILARIDADE DO PROCESSO Nº ${processo.etiquetaProcessoViproc}`;
    });
  }

  async getAssunto(assuntoId: number) {
    const assunto = await this.assuntoService
      .get(assuntoId)
      .toPromise()
      .catch((error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });

    this.assunto = assunto;
    this.assunto.listMetadado.forEach(
      (assuntoMetadado) => {
        this.processo.listMetadadoValor.forEach((metadadoValor) => {
          if (assuntoMetadado.metadado.id === metadadoValor.metadadoId) {
            assuntoMetadado.metadado.valor = metadadoValor.valor;
            assuntoMetadado.metadado.disabled = true;
            this.listMetadado.push(assuntoMetadado.metadado);
          }
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private atualizarComboResponsavelAtual(processo: Processo) {
    this.responsavelAtualObjeto = null;
    this.responsaveisList = [];
    if (processo.ultimaMovimentacao != null && processo.ultimaMovimentacao.lotacao != null) {
      let countServidores = 0;
      //this.messageService.messageWarning("Implementar consulta de servidores por orgão.");
      this.sisSegService.listServidoresPorOrgao(processo.ultimaMovimentacao.lotacao.toString()).subscribe((responsaveis: Responsavel[]) => {
        if (responsaveis) {
          this.responsaveisList = responsaveis;
        }
        this.lotacaoSecundariaService.consultaServidores(processo.ultimaMovimentacao.lotacao).subscribe(
          (lotacaoSecundariaDto: LotacaoSecundariaDto[]) => {
            if (lotacaoSecundariaDto != null && lotacaoSecundariaDto.length > 0) {
              lotacaoSecundariaDto.forEach((documento) => {
                this.sisSegService.consultarServidorByDocumento(documento.usuario).subscribe((servidor) => {
                  countServidores++;
                  let responsavel = new Responsavel();
                  responsavel.cgf = servidor.cpf;
                  responsavel.cpfCnpj = servidor.cpf;
                  responsavel.documento = servidor.documento;
                  responsavel.nome = servidor.nome;
                  responsavel.documento = servidor.documento;
                  this.responsaveisList.push(responsavel);
                  if (countServidores === lotacaoSecundariaDto.length) {
                    Utils.ordernarNomeOrdemAlfabetica(this.responsaveisList);
                    this.selecionarObjetoResponsavelAtualPelaListaResponsaveis();
                    //this.atualizarResponsaveisServidores(processo.ultimaMovimentacao.lotacao, processo);
                    this.validaInputResponsavelAtual = true;
                  }
                });
              });
            } else {
              this.selecionarObjetoResponsavelAtualPelaListaResponsaveis();
              this.validaInputResponsavelAtual = true;
            }
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      });
    }
  }

  private selecionarObjetoResponsavelAtualPelaListaResponsaveis() {
    this.responsaveisList.forEach((servidor) => {
      if (!this.responsavelAtualObjeto && !this.processo.responsavelAtual) {
        this.responsavelAtualObjeto = this.nenhumResponsavelAtualObjeto;
        return;
      }
      if (servidor.documento === this.processo.responsavelAtual) {
        this.responsavelAtualObjeto = servidor;
      }
    });
  }

  private atualizarResponsaveisServidores(lotacao: number, processo: Processo) {
    let countServidores = 0;
    this.lotacaoSecundariaService.consultaServidores(lotacao).subscribe(
      (servidores) => {
        if (servidores.length === 0) {
          this.validaInputResponsavelAtual = true;
        }
        servidores.forEach((documento) => {
          //this.messageService.messageWarning("Implementar consulta de servidores por documento.");
          this.sisSegService.consultarServidorByDocumento(documento.usuario).subscribe((servidor) => {
            countServidores++;
            const responsavel = new Responsavel();
            responsavel.cgf = servidor.cpf;
            responsavel.cpfCnpj = servidor.cpf;
            responsavel.documento = servidor.documento;
            responsavel.nome = servidor.nome;
            responsavel.documento = servidor.documento;
            this.responsaveisList.push(responsavel);
            if (countServidores === servidores.length) {
              Utils.ordernarNomeOrdemAlfabetica(this.responsaveisList);
              if (processo.responsavelAtual != null) {
                this.selecionarObjetoResponsavelAtualPelaListaResponsaveis();
              }
              this.validaInputResponsavelAtual = true;
            }
          });
        });
      },
      (error) => {
        countServidores++;
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private limparListaDocumentos(event: any) {
    event.value = "";
  }

  private limparDataSourceDocumento() {
    this.dataSourceDocumento.data = [];
  }

  private listarDocumentosProcesso(processo: Processo) {
    this.limparDataSourceDocumento();
    this.limparListaDocumentos(this.documentos);
    this.selection.clear();
    this.novoDocumento();

    if (this.assunto.pensao) {
      this.limparDataSourceDocumento();
      this.limparListaDocumentos(this.documentos);
      this.documentoService.listarDocumentosGeraisProcessoPensao(processo.id).subscribe((documentos) => {
        this.carregarDocumentosDoRequest(documentos);
      });
    } else {
      this.documentoService.listarDocumentosByIdProcesso(processo.id).subscribe((documentos) => {
        this.carregarDocumentosDoRequest(documentos);
      });
    }
  }

  private atualizarDocumentosProcesso(processo: Processo) {
    this.novoDocumento();

    if (this.assunto.pensao) {
      this.documentoService.listarDocumentosGeraisProcessoPensao(processo.id).subscribe((documentos) => {
        this.carregarDocumentosDoRequest(documentos);
      });
    } else {
      this.documentoService.listarDocumentosByIdProcesso(processo.id).subscribe((documentos) => {
        this.carregarDocumentosDoRequest(documentos);
      });
    }
  }

  private listarMovimentacoesProcesso(processo: any) {
    this.dataSourceMovimentacao = new MatTableDataSource<Movimentacao>(processo.listMovimentacao);
    // FIXME Ver uma solução para remover o setTimeout

    setTimeout(() => (this.dataSourceMovimentacao.paginator = this.paginatorMovimentacao));
  }

  // ABA MOVIMENTAÇÃO
  getMovimentacao(row: any) {
    this.movimentacao = row;

    this.processo.ultimaMovimentacao = this.movimentacao;

    this.highlightedMovimentacaoRows.pop();
    this.highlightedMovimentacaoRows.push(row);
  }

  getOcorrencia(row: any) {
    this.highlightedOcorrenciasRows.pop();
    this.highlightedOcorrenciasRows.push(row);
  }

  getProcessosRelacionados(row: any) {
    this.highlightedProcessosRelacionadosRows.pop();
    this.highlightedProcessosRelacionadosRows.push(row);
    this.getProcesso(row.id);
  }

  getPublicacao(row: any) {
    this.highlightedPublicacaoRows.pop();
    this.highlightedPublicacaoRows.push(row);
  }

  mudarFaseProcesso(processo: Processo, arquivar: boolean): void {
    if (arquivar) {
      this.openDialogFase(processo, arquivar);
    } else {
      if (!this.processo.situacaoAtual.situacaoBloqueio) {
        this.openDialogFase(processo, arquivar);
      } else {
        this.openSituacaoBloqueadaDialog();
      }
    }
  }

  private openDialogFase(processo: Processo, arquivar: boolean) {
    const dialogFase = this.dialog.open(DialogAlterarFaseComponent, {
      width: "400px",
      data: {
        processo: processo,
        arquivar: arquivar,
      },
    });
    dialogFase.afterClosed().subscribe((processoArquivarObjeto) => {
      if (processoArquivarObjeto) {
        this.atualizarInformacoesComProcesso(processo.id, null);
      }
    });
  }

  mudarSituacaoProcesso(processo: Processo): void {
    this.openDialogSituacao(processo);
  }

  private openDialogSituacao(processo: Processo) {
    const dialogSituacao = this.dialog.open(DialogAlterarSituacaoComponent, {
      width: "400px",
      data: processo,
    });
    dialogSituacao.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.atualizarInformacoesComProcesso(processo.id, null);
      }
    });
  }

  arquivarProcesso(processo: Processo): void {
    if (this.processo.situacaoAtual.situacaoBloqueio !== true) {
      this.openDialogArquivar(processo);
    } else {
      this.openSituacaoBloqueadaDialog();
    }
  }

  private openDialogArquivar(processo: Processo) {
    const dialogArquivar = this.dialog.open(DialogArquivarComponent, {
      width: "400px",
      data: processo,
    });
    dialogArquivar.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.atualizarInformacoesComProcesso(processo.id, null);
      }
    });
  }

  compareResponsavel(obj1: Responsavel, obj2: Responsavel): boolean {
    return obj1 && obj2 ? obj1.documento === obj2.documento : obj1 === obj2;
  }

  definirResponsavel(processo: Processo, event: any): void {
    if (event != null && event.isUserInput && this.validaInputResponsavelAtual) {
      // Remoção do bug do material ao selecionar 'null'
      if (
        event.source.value != null &&
        event.source.value.documento != null &&
        (event.source.value.documento === this.processo.responsavelAtual ||
          (event.source.value.id === 0 && this.processo.responsavelAtual === null))
      ) {
        // By Luiz Lima: Precisei tirar a messagem de warn pq não fazia sentido 'ele conseguir mudar para o resp. atual'
        // e para correção da chamada duplica do mat-select quando selecionar a opção 'nula'
        return;
      }
      // TODO Verificação deve ficar no frontend?
      if (!this.processo.situacaoAtual.situacaoBloqueio) {
        this.responsavelAtualObjeto = event.source.value !== undefined && event.source.value.id !== 0 ? event.source.value : null;
        const movimentacaoDTO = new MovimentacaoDTO();
        const documentoUsuarioLogado = this.sharedService.decodePayloadJWT().sub;
        movimentacaoDTO.docAutor = documentoUsuarioLogado;
        movimentacaoDTO.responsavel =
          this.responsavelAtualObjeto !== null && this.responsavelAtualObjeto.id !== 0 ? this.responsavelAtualObjeto.documento : null;
        movimentacaoDTO.observacao =
          this.responsavelAtualObjeto !== null && this.responsavelAtualObjeto.id !== 0
            ? "Alteração de responsável"
            : "Remoção de responsável";

        this.processoService.movimentarResponsavel(movimentacaoDTO, processo.id).subscribe(
          () => {
            if (this.responsavelAtualObjeto != null && this.responsavelAtualObjeto.id !== 0) {
              this.messageService.messageSuccess("Processo associado a " + this.responsavelAtualObjeto.nome);
            } else {
              this.messageService.messageSuccess("Removido o responsável");
            }
            this.atualizarInformacoesComProcesso(processo.id, null);
          },
          (error) => {
            // this.atualizarInformacoesComProcesso(processo.id);
            this.selecionarObjetoResponsavelAtualPelaListaResponsaveis();
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        this.atualizarComboResponsavelAtual(processo);
        this.openSituacaoBloqueadaDialog();
      }
    }
  }

  private openConfirmarRetornoProcessoDialog(processo: Processo) {
    const message = "Deseja retornar o processo ao remetente?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message,
    });
    dialogRef.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.processoService.retornarProcesso(processo.id).subscribe(
          (response) => {
            this.messageService.messageSuccess("Processo associado a " + response.nomeResponsavel);
            this.processoService.get(this.processo.id).subscribe(
              (processoNovo) => {
                this.atualizarInformacoesComProcesso(processo.id, null);
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          },
          (err) => {
            this.messageService.messageErro(err.error.message);
          }
        );
      } else {
        this.openSituacaoBloqueadaDialog();
      }
    });
  }

  retornarProcesso(processo: Processo): void {
    this.openConfirmarRetornoProcessoDialog(processo);
  }

  movimentarProcesso(processo: Processo): void {
    // TODO Verificação deve ficar no frontend?
    if (this.processo.situacaoAtual != null && !this.processo.situacaoAtual.situacaoBloqueio) {
      const processoArray = [];
      processoArray.push(processo);
      this.openDialogMovimentacao(processoArray, false);
    } else {
      this.openSituacaoBloqueadaDialog();
    }
  }

  private openDialogMovimentacao(processos: Processo[], isEmLote: boolean) {
    const processoMovimentacao = new ProcessoMovimentacao();
    processoMovimentacao.isEmLote = isEmLote;
    processoMovimentacao.processos = processos;
    const dialogMovimentacao = this.dialog.open(DialogMovimentacaoProcessoComponent, {
      width: "400px",
      data: processoMovimentacao,
    });
    dialogMovimentacao.afterClosed().subscribe((processoParam) => {
      if (processoParam && this.processo) {
        this.atualizarInformacoesComProcesso(this.processo.id, null);
      }
    });
  }

  private openSituacaoBloqueadaDialog() {
    const message = "Necessário alterar a situação antes de movimentar. Deseja alterar a situação?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.openDialogSituacao(this.processo);
      }
    });
  }

  // ABA DOCUMENTO

  assinarDocumento() {
    this.openDialogSelecionarCertificado();
  }

  openDialogSelecionarCertificado(): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate,
    });

    openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
      if (certParam != null && certParam.cert64 != null) {
        this.certificate = certParam;
        this.assinarMarcados();
      }
    });
  }

  enviarDocumentoAto(processo: Processo): void {
    if (this.processo.situacaoAtual != null && !this.processo.situacaoAtual.situacaoBloqueio) {
      this.openDialogIndicarEdoweb(processo);
      let isRelacionado = this.processoService.jaExisteProcessoCriadoParaVincular(processo.id);
    } else {
      this.openSituacaoBloqueadaDialog();
    }
  }

  // private openDialogVincularAosProcessosRelacionados(processo: Processo) {
  //   let processoData[] = new Processo()[];
  //   if (this.documentoSelecionado.listAssinatura.length < 1) {
  //     if (this.documentoSelecionado != null) {
  //       processoData[] = this.documentoSelecionado;
  //     }
  //   }
  //   const dialogEdoWeb = this.dialog.open(DialogIndicarEdowebComponent, {
  //     width: "400px",
  //     data: {
  //       processo: this.processo
  //     },
  //   });
  //   dialogEdoWeb.afterClosed().subscribe((processoParam) => {
  //     if (processoParam && this.processo) {
  //       this.atualizarInformacoesComProcesso(this.processo.id, null);
  //     }
  //   });
  // }

  private openDialogIndicarEdoweb(processo: Processo) {
    let documentoData = new Documento();
    if (this.documentoSelecionado.listAssinatura.length < 1) {
      if (this.documentoSelecionado != null) {
        documentoData = this.documentoSelecionado;
      }
    }
    const dialogEdoWeb = this.dialog.open(DialogIndicarEdowebComponent, {
      width: "400px",
      data: {
        processo: this.processo,
        documento: documentoData,
      },
    });
    dialogEdoWeb.afterClosed().subscribe((processoParam) => {
      if (processoParam && this.processo) {
        this.atualizarInformacoesComProcesso(this.processo.id, null);
      }
    });
  }

  openDialogIndicarAssinadores(): void {
    let documentoData = new Documento();
    if (
      this.documentoSelecionado.listAssinatura.length < this.documentoSelecionado.tipoDocumento.qtdeMaximaAssinatura ||
      this.documentoSelecionado.tipoDocumento.qtdeMaximaAssinatura == null
    ) {
      if (this.documentoSelecionado != null) {
        documentoData = this.documentoSelecionado;
      }

      if (this.listaAssinador.length > 0) {
        documentoData.listAssinatura = [];
        this.listaAssinador.forEach((assinador) => {
          const novaAssinatura = new Assinatura();
          novaAssinatura.assinador = assinador;
          documentoData.listAssinatura.push(novaAssinatura);
        }, this);
      }
      const dialogIndicarAssinadores = this.dialog.open(DialogIndicarAssinadoresComponent, {
        width: "600px",
        data: {
          processo: this.processo,
          documento: documentoData,
        },
      });

      dialogIndicarAssinadores.afterClosed().subscribe((documentoParam) => {
        if (documentoParam) {
          // Caso cancele no dialog
          if (this.documentoSelecionado != null) {
            // Caso o documento já exista
            this.documentoService.put(this.documentoSelecionado).subscribe(
              (documentoAtualizado) => {
                this.messageService.messageSuccess("Assinador adicionado com sucesso!");

                documentoAtualizado.listAssinatura.forEach(function (assinatura) {
                  this.contribuinteService.findResponsavelPorDocumento(assinatura.responsavel.documento).subscribe(
                    (responsavel) => {
                      assinatura.responsavel.nome = responsavel ? responsavel.nome : "";
                      this.InserirAssinadorLista(assinatura);
                    },
                    (error) => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    }
                  );
                }, this);
                this.documentoSelecionado.listAssinatura = documentoAtualizado.listAssinatura;
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          } else {
            // Para o novo documento
            const lista: any[] = [];
            for (const entry of documentoParam) {
              lista.push(entry);
            }

            for (const assinador of this.listaAssinador) {
              lista.push(assinador);
            }
            this.listaAssinador = lista;
          }
        }
      });
    } else {
      this.messageService.messageWarning("O documento atingiu o limite máximo de assinadores.");
    }
  }

  openDialogTipoDocumento(natureza: any): void {
    const dialogTipoDocumento = this.dialog.open(DialogTipoDocumentoComponent, {
      width: "600px",
      height: "600px",
      data: { anexo: natureza == 2 ? true : false, assunto: this.processo.assunto, relacionadoEnvolvido: false },
    });

    dialogTipoDocumento.afterClosed().subscribe((tipoDocumentoParam) => {
      // tslint:disable-next-line:triple-equals
      if (tipoDocumentoParam == undefined) {
        this.documentoNovo.tipoDocumento = new TipoDocumento();
      } else {
        this.documentoNovo.tipoDocumento = tipoDocumentoParam;
        this.documentoNovo.modeloDocumento = tipoDocumentoParam.modeloDocumento;
        this.documentoNovo.downloadUsuarioExterno = tipoDocumentoParam.visibilidadeExterna;
      }
    });
  }

  openDialogModelo(): void {
    const dialogModelo = this.dialog.open(DialogSelecionarModeloComponent, {
      width: "500px",
      data: this.documentoNovo.tipoDocumento,
    });

    dialogModelo.afterClosed().subscribe((modelo: Modelo) => {
      if (modelo) {
        this.documentoNovo.modeloDocumento = modelo.conteudo;
      }
    });
  }

  isAllSelected() {
    if (this.dataSourceDocumento != null || this.dataSourceDocumento !== undefined) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSourceDocumento.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  isAllSelectedProcesso() {
    if (this.dataSourceProcesso != null || this.dataSourceProcesso !== undefined) {
      const numSelected = this.selectionProcesso.selected.length;
      const numRows = this.dataSourceProcesso.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  masterToggle() {
    if (this.dataSourceDocumento != null) {
      this.isAllSelected() ? this.selection.clear() : this.dataSourceDocumento.data.forEach((row) => this.selection.select(row));
    }
  }

  masterToggleProcesso() {
    if (this.dataSourceProcesso != null) {
      this.isAllSelectedProcesso()
        ? this.selectionProcesso.clear()
        : this.dataSourceProcesso.data.forEach((row) => this.selectionProcesso.select(row));
    }
  }

  checkboxLabel(row?: Documento): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  checkboxLabelProcesso(row?: ProcessoTabelaDto): string {
    if (!row) {
      return `${this.isAllSelectedProcesso() ? "select" : "deselect"} all`;
    }
    return `${this.selectionProcesso.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  getDocumento(documentoRow: Documento) {
    this.listaAssinador = [];
    if (this.documentoSelecionado != null && documentoRow.id === this.documentoSelecionado.id) {
      this.limparDocumentoSelecionado();
      this.cdRef.detectChanges();
      return;
    }
    this.selection.clear();
    this.atualizarDocumentosProcesso(this.processo);

    this.documentoService.get(documentoRow.id).subscribe((documentoAtualizado: Documento) => {
      if (documentoAtualizado != null) {
        this.novoDocumento(); // Limpar informações. Conferir se tem algo escrito?
        this.natureza = null;
        this.exibicaoEditor = false;
        this.documentoSelecionado = documentoAtualizado;
        this.cdRef.detectChanges();
        if (this.documentoSelecionado.autor != null) {
          this.contribuinteService.findByDocumento(this.documentoSelecionado.autor.documento).subscribe(
            (contribuinteOuServidor: Contribuinte) => {
              if (contribuinteOuServidor != null) {
                // tslint:disable-next-line: max-line-length
                this.documentoSelecionado.documentoEnomeAutor = contribuinteOuServidor.documento
                  .concat(" - ")
                  .concat(contribuinteOuServidor.nome);
              }
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
        // let listTemp: Assinador[] = [];
        this.documentoSelecionado.listAssinatura.forEach(function (assinatura) {
          this.contribuinteService.findResponsavelPorDocumento(assinatura.responsavel.documento).subscribe(
            (responsavel) => {
              assinatura.responsavel.nome = responsavel ? responsavel.nome : "";
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }, this);

        // this.listaAssinador = listTemp;

        var disabled: boolean =
          this.documentoSelecionado == null ||
          this.processo.autor == null ||
          this.documentoSelecionado.listAssinatura.filter(
            (assinatura) => assinatura.assinado && assinatura.responsavel.documento != this.processo.autor.documento
          ).length > 0;
        this.documentoSelecionado.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
          tipoDocumentoMetadado.metadado.disabled = disabled;
          this.documentoSelecionado.listMetadadoValor.forEach((metadadoValor) => {
            if (tipoDocumentoMetadado.metadado.id === metadadoValor.metadadoId) {
              tipoDocumentoMetadado.metadado.valor = metadadoValor.valor;
              tipoDocumentoMetadado.metadado.disabled = disabled;
              this.listMetadado.push(tipoDocumentoMetadado.metadado);
            }
          });
        });
        this.cdRef.detectChanges();

        this.isDocumentoAssinado = this.documentoSelecionado.listAssinatura.filter((assinatura) => assinatura.assinado).length > 0;

        this.exibirVIsualizadorDocumento = this.documentoSelecionado.nomeDocumento.toLowerCase().indexOf(".pdf") > -1;
        this.nomeArquivo = this.documentoSelecionado.nomeDocumento;
        this.atualizarComponentePdf();
      } else {
        this.messageService.messageErro("Não foi possível atualizar informações do documento.");
        return;
      }
    });
  }

  public possuiMetadadoParaAtualizar(): boolean {
    var disabled: boolean =
      this.documentoSelecionado == null ||
      this.processo.autor == null ||
      this.documentoSelecionado.listAssinatura.filter(
        (assinatura) => assinatura.assinado && assinatura.responsavel.documento != this.processo.autor.documento
      ).length > 0;

    var possuiMetadado = false;
    if (this.documentoSelecionado && this.documentoSelecionado.tipoDocumento && this.documentoSelecionado.tipoDocumento.listMetadado)
      possuiMetadado = this.documentoSelecionado.tipoDocumento.listMetadado.length > 0;

    return !disabled && possuiMetadado;
  }

  public atualizarInformacoesDoDocumento() {
    const doc = new DocumentoSaveRequest();

    const listMetadadoValor: MetadadoValor[] = [];

    var metadadoValido = true;
    this.documentoSelecionado.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
      if (!this.validarMetadado(tipoDocumentoMetadado)) {
        metadadoValido = false;
      }

      if (tipoDocumentoMetadado.metadado.valor) {
        const metadadoValor = new MetadadoValor();
        metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
        metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
        listMetadadoValor.push(metadadoValor);
      }
    });
    // Salvando informações digitados dos metadados
    doc.listMetadadoValor = listMetadadoValor;

    if (metadadoValido)
      this.documentoService.atualizarMetadados(doc, this.documentoSelecionado.id).subscribe(
        (documento) => {
          this.messageService.messageSuccess("Documento Atualizado");
        },
        (error) => {
          this.messageService.messageErro("Erro ao atualizar, " + error.error.message);
        }
      );
  }

  downloadArquivo() {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = this.urlDocumentoPdf;
    a.download = this.nomeArquivo;
    a.click();
  }

  public toggleExibicao() {
    if (this.exibicaoEditor === false) {
      this.exibicaoEditor = true;
    } else {
      this.exibicaoEditor = false;
    }
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  public adicionarDocumento() {
    this.novoDocumento();
    this.exibicaoEditor = false;
  }

  public adicionarDocumentoEditor() {
    this.resetDocSubstituir();
    this.novoDocumento();
    this.exibicaoEditor = true;
  }

  public novoDocumento() {
    this.limparDocumentoSelecionado();
    this.confiCkEditor();
    this.isDocumentoAssinado = false;
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;
    this.listaAssinador = [];
    this.processo.modeloAssunto = "";
  }

  private limparDocumentoSelecionado() {
    this.documentoSelecionado = null;
  }

  afterLoadComplete(pdfData: any) {}

  private assinarMarcados() {
    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
    const formData: FormData = new FormData();
    formData.append("certContent", this.certificate.cert64);
    formData.append("documento", this.documentoSelecionado.docAutor);

    let countDocumentosAtualizados = 0;

    this.documentoService.startWithIdDocumento(this.documentoSelecionado.id, formData, SharedService.CARREGAR_LOADING).subscribe(
      (sigParams) => {
        this.pki
          .signHash({
            thumbprint: cert.thumbprint,
            hash: sigParams.toSignHash,
            digestAlgorithm: sigParams.digestAlgorithm,
          })
          .success((signature) => {
            this.documentoService
              .completeWithIdDocumento(
                this.documentoSelecionado.id,
                sigParams.fileName,
                sigParams.transferFileId,
                signature,
                sigParams.descricaoAssinatura,
                SharedService.CARREGAR_LOADING
              )
              .subscribe(
                (documentoAssinado) => {
                  countDocumentosAtualizados++;
                  if (countDocumentosAtualizados === this.selection.selected.length) {
                    this.natureza = null;
                    this.selection.clear();
                    this.listarDocumentosProcesso(this.processo);
                  }
                  this.messageService.messageSuccess("Documento assinado com sucesso!");
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
          });
      },
      (msgExcecao) => {
        countDocumentosAtualizados++;
        if (msgExcecao.error != null && msgExcecao.error.message != null) {
          this.messageService.messageErro(msgExcecao.error.message);
          throw msgExcecao;
        } else {
          this.messageService.messageErro("Erro na assinatura do arquivo.");
        }
      }
    );
  }

  getNivelSigilo(nivel) {
    if (nivel != null) {
      return NivelSigiloEnumAll[nivel].descricao;
    } else {
      return "Nivel de Sigilo nulo";
    }
  }

  public atualizarModeloDocumentoEditor() {
    const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
    gerarPdfUploadAlfrescoRequest.html = this.documentoSelecionado.modeloDocumento;
    // tslint:disable-next-line: max-line-length
    this.documentoService.atualizarPdfUploadAlfresco(this.documentoSelecionado.id, gerarPdfUploadAlfrescoRequest).subscribe(
      (documento) => {
        this.limparDocumentoSelecionado();
        this.atualizarDocumentosProcesso(this.processo);
        this.messageService.messageSuccess("Documento atualizado com sucesso!");
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private atualizarComponentePdf() {
    this.exibirPdfDocumento = false;
    if (this.documentoSelecionado.nomeDocumento.toLowerCase().indexOf(".pdf") === -1) {
      return;
    }

    if (this.documentoSelecionado.status === 2) {
      this.documentoService.download(this.documentoSelecionado.id).subscribe(
        (blob) => {
          this.exibirPdfDocumento = true;
          const url = Utils.getUrlBlob(blob);
          this.urlDocumentoPdf = url;

          if (this.pdfDocumento !== undefined) {
            if (this.exibirVIsualizadorDocumento) {
              this.pdfDocumento.pdfSrc = url;
              this.pdfDocumento.refresh();
            }
          } else {
            this.messageService.messageWarning("Não foi possível carregar o PDF");
          }
        },
        (error) => {
          const erro = Utils.converterArrayBufferParaJSON(error);
          this.messageService.messageErro(erro.error.message);
        }
      );
    } else {
      this.messageService.messageInfo("Aguardando documento ser enviado para o Alfresco.");
    }
  }

  public incluirDocumentoNovoEditor() {
    if (this.validar(true)) {
      if (
        this.documentoNovo.tipoDocumento.id === 346 ||
        this.documentoNovo.tipoDocumento.id === 344 ||
        this.documentoNovo.tipoDocumento.id === 196 ||
        this.documentoNovo.tipoDocumento.id === 319 ||
        this.documentoNovo.tipoDocumento.id === 322 ||
        this.documentoNovo.tipoDocumento.id === 181 ||
        this.documentoNovo.tipoDocumento.id === 278 ||
        this.documentoNovo.tipoDocumento.id === 347 ||
        this.documentoNovo.tipoDocumento.id === 345 ||
        this.documentoNovo.tipoDocumento.id === 197 ||
        this.documentoNovo.tipoDocumento.id === 192 ||
        this.documentoNovo.tipoDocumento.id === 191 ||
        this.documentoNovo.tipoDocumento.id === 284 ||
        this.documentoNovo.tipoDocumento.id === 285 ||
        this.documentoNovo.tipoDocumento.id === 287 ||
        this.documentoNovo.tipoDocumento.id === 288 ||
        this.documentoNovo.tipoDocumento.id === 289 ||
        this.documentoNovo.tipoDocumento.id === 290 ||
        this.documentoNovo.tipoDocumento.id === 291 ||
        this.documentoNovo.tipoDocumento.id === 292 ||
        this.documentoNovo.tipoDocumento.id === 286 ||
        this.documentoNovo.tipoDocumento.id === 226
      ) {
        let processosRelacionados = [];
        this.processoService
          .jaExisteProcessoCriadoParaVincular(this.processo.id)
          .toPromise()
          .then((dados) => {
            processosRelacionados = dados;
            if (processosRelacionados.length > 0) {
              this.messageService.messageWarning(`Existem processos relacionados, anexe o Ato de Pensão em todos os relacionados.`);
              const dialogRef = this.dialog.open(DialogVincularProcessosRelacionadosComponent, {
                data: {
                  processosRelacionados: processosRelacionados,
                  documento: this.documentoNovo,
                  sharedService: this.sharedService,
                  isAnexo: false,
                },
              });
              dialogRef
                .afterClosed()
                .toPromise()
                .then((result) => {
                  const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
                  gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
                  gerarPdfUploadAlfrescoRequest.descricao = this.documentoNovo.descricao;
                  gerarPdfUploadAlfrescoRequest.descricaoAssunto = this.processo.assunto.descricao;
                  gerarPdfUploadAlfrescoRequest.html = this.documentoNovo.modeloDocumento;
                  gerarPdfUploadAlfrescoRequest.nomeDocumento = "documento_anexo_editor.pdf";
                  gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.documentoNovo.tipoDocumento.id;
                  gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
                  gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
                    return assinador.documento.toString();
                  });
                  gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = this.documentoNovo.downloadUsuarioExterno;

                  const listMetadadoValor: MetadadoValor[] = [];
                  var metadadoValido = true;
                  this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
                    const metadadoValor = new MetadadoValor();
                    if (!this.validarMetadado(tipoDocumentoMetadado)) {
                      metadadoValido = false;
                    }

                    metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
                    metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
                    listMetadadoValor.push(metadadoValor);
                  });
                  // Salvando informações digitados dos metadados
                  this.documentoNovo.listMetadadoValor = listMetadadoValor;

                  gerarPdfUploadAlfrescoRequest.listMetadadoValor = listMetadadoValor;

                  if (metadadoValido)
                    this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
                      (documento) => {
                        this.processoService.get(this.processo.id).subscribe(
                          (processoAtualizado: Processo) => {
                            this.natureza = null;
                            this.exibicaoEditor = false;
                            this.listarDocumentosProcesso(processoAtualizado);
                            if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                              this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                              this.messageService.messageSuccess("Documento substituído com sucesso!");
                            } else {
                              this.messageService.messageSuccess("Documento inserido com sucesso!");
                            }
                          },
                          (error) => {
                            this.messageService.messageErro(error.error.message);
                            throw error;
                          }
                        );
                      },
                      (error) => {
                        this.messageService.messageErro(error.error.message);
                        throw error;
                      }
                    );
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
              gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
              gerarPdfUploadAlfrescoRequest.descricao = this.documentoNovo.descricao;
              gerarPdfUploadAlfrescoRequest.descricaoAssunto = this.processo.assunto.descricao;
              gerarPdfUploadAlfrescoRequest.html = this.documentoNovo.modeloDocumento;
              gerarPdfUploadAlfrescoRequest.nomeDocumento = "documento_anexo_editor.pdf";
              gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.documentoNovo.tipoDocumento.id;
              gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
              gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
                return assinador.documento.toString();
              });
              gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = this.documentoNovo.downloadUsuarioExterno;

              const listMetadadoValor: MetadadoValor[] = [];
              var metadadoValido = true;
              this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
                const metadadoValor = new MetadadoValor();
                if (!this.validarMetadado(tipoDocumentoMetadado)) {
                  metadadoValido = false;
                }

                metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
                metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
                listMetadadoValor.push(metadadoValor);
              });
              // Salvando informações digitados dos metadados
              this.documentoNovo.listMetadadoValor = listMetadadoValor;

              gerarPdfUploadAlfrescoRequest.listMetadadoValor = listMetadadoValor;

              if (metadadoValido)
                this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
                  (documento) => {
                    this.processoService.get(this.processo.id).subscribe(
                      (processoAtualizado: Processo) => {
                        this.natureza = null;
                        this.exibicaoEditor = false;
                        this.listarDocumentosProcesso(processoAtualizado);
                        if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                          this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                          this.messageService.messageSuccess("Documento substituído com sucesso!");
                        } else {
                          this.messageService.messageSuccess("Documento inserido com sucesso!");
                        }
                      },
                      (error) => {
                        this.messageService.messageErro(error.error.message);
                        throw error;
                      }
                    );
                  },
                  (error) => {
                    this.messageService.messageErro(error.error.message);
                    throw error;
                  }
                );
            }
          });
      } else {
        const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
        gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
        gerarPdfUploadAlfrescoRequest.descricao = this.documentoNovo.descricao;
        gerarPdfUploadAlfrescoRequest.descricaoAssunto = this.processo.assunto.descricao;
        gerarPdfUploadAlfrescoRequest.html = this.documentoNovo.modeloDocumento;
        gerarPdfUploadAlfrescoRequest.nomeDocumento = "documento_anexo_editor.pdf";
        gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.documentoNovo.tipoDocumento.id;
        gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
        gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
          return assinador.documento.toString();
        });
        gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = this.documentoNovo.downloadUsuarioExterno;

        const listMetadadoValor: MetadadoValor[] = [];
        var metadadoValido = true;
        this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
          const metadadoValor = new MetadadoValor();
          if (!this.validarMetadado(tipoDocumentoMetadado)) {
            metadadoValido = false;
          }

          metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
          metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
          listMetadadoValor.push(metadadoValor);
        });
        // Salvando informações digitados dos metadados
        this.documentoNovo.listMetadadoValor = listMetadadoValor;

        gerarPdfUploadAlfrescoRequest.listMetadadoValor = listMetadadoValor;

        if (metadadoValido)
          this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
            (documento) => {
              this.processoService.get(this.processo.id).subscribe(
                (processoAtualizado: Processo) => {
                  this.natureza = null;
                  this.exibicaoEditor = false;
                  this.listarDocumentosProcesso(processoAtualizado);
                  if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                    this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                    this.messageService.messageSuccess("Documento substituído com sucesso!");
                  } else {
                    this.messageService.messageSuccess("Documento inserido com sucesso!");
                  }
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
      }
    }
  }

  validarExtensao(arquivo) {
    let extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    let extensoes = !this.assunto.extensao ? [".pdf"] : this.assunto.extensao.split(",");

    return extensoes.filter((e) => e.trim().indexOf(extensao) > -1).length > 0;
  }

  incluirDocumentoNovoAnexo(event, substituir: boolean, documentoSubstituir: Documento) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (!this.validarExtensao(fileList[0].name)) {
        const extensoes = !this.assunto.extensao ? ".pdf" : this.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }

      let fileName = fileList[0].name;
      let arquivoPdf = fileName.substring(fileName.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1;
      if (this.validar(arquivoPdf)) {
        if (
          this.documentoNovo.tipoDocumento.id === 308 ||
          this.documentoNovo.tipoDocumento.id === 248 ||
          this.documentoNovo.tipoDocumento.id === 226 ||
          this.documentoNovo.tipoDocumento.id === 191
        ) {
          let processosRelacionados = [];
          this.processoService
            .jaExisteProcessoCriadoParaVincular(this.processo.id)
            .toPromise()
            .then((dados) => {
              processosRelacionados = dados;
              const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
              if (processosRelacionados.length > 0) {
                // CHAMAR O DIALOG PARA SELECIONAR OS PROCESSOS VINCULADOS E ANEXAR O ATO NELES TAMBÉM.
                this.messageService.messageWarning(`Existem processos relacionados, anexe o Ato de Pensão em todos os relacionados.`);
                const dialogRef = this.dialog.open(DialogVincularProcessosRelacionadosComponent, {
                  data: {
                    processosRelacionados: processosRelacionados,
                    documento: this.documentoNovo,
                    sharedService: this.sharedService,
                    fileList,
                    isAnexo: true,
                  },
                });
                dialogRef
                  .afterClosed()
                  .toPromise()
                  .then((result) => {
                    const formData: FormData = new FormData();
                    const file: File = fileList[0];
                    formData.append("file", file);

                    formData.append("fileName", file.name);
                    formData.append("descricao", this.documentoNovo.descricao);
                    formData.append("descricaoAssunto", this.processo.assunto.descricao);
                    formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
                    formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
                    formData.append("processoId", this.processo.id.toString());
                    formData.append(
                      "docAssinantes",
                      this.listaAssinador
                        .map(function (assinador) {
                          return assinador.documento.toString();
                        })
                        .join(",")
                    );
                    formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());

                    const listMetadadoValor: MetadadoValor[] = [];
                    var metadadoValido = true;
                    this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
                      if (!this.validarMetadado(tipoDocumentoMetadado)) {
                        metadadoValido = false;
                      }
                      const metadadoValor = new MetadadoValor();
                      metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
                      metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
                      listMetadadoValor.push(metadadoValor);
                    });
                    // Salvando informações digitados dos metadados
                    this.documentoNovo.listMetadadoValor = listMetadadoValor;

                    formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));

                    if (metadadoValido)
                      if (arquivoPdf) {
                        this.documentoService.post(formData).subscribe(
                          (documento) => {
                            this.processoService.get(this.processo.id).subscribe(
                              (resp) => {
                                if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                                  this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                                  this.messageService.messageSuccess("Documento substituído com sucesso!");
                                } else {
                                  this.messageService.messageSuccess("Documento inserido com sucesso!");
                                }
                                this.natureza = null;
                                this.listarDocumentosProcesso(resp);
                              },
                              (error) => {
                                this.messageService.messageErro(error.error.message);
                                throw error;
                              }
                            );
                          },
                          (error) => {
                            this.messageService.messageErro(error.error.message);
                            throw error;
                          }
                        );
                      } else {
                        this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
                          (documento) => {
                            this.processoService.get(this.processo.id).subscribe(
                              (resp) => {
                                if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                                  this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                                  this.messageService.messageSuccess("Documento substituído com sucesso!");
                                } else {
                                  this.messageService.messageSuccess("Documento inserido com sucesso!");
                                }
                                this.natureza = null;
                                this.listarDocumentosProcesso(resp);
                              },
                              (error) => {
                                this.messageService.messageErro(error.error.message);
                                throw error;
                              }
                            );
                          },
                          (error) => {
                            this.messageService.messageErro(error.error.message);
                            throw error;
                          }
                        );
                      }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                const formData: FormData = new FormData();
                const file: File = fileList[0];
                formData.append("file", file);

                formData.append("fileName", file.name);
                formData.append("descricao", this.documentoNovo.descricao);
                formData.append("descricaoAssunto", this.processo.assunto.descricao);
                formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
                formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
                formData.append("processoId", this.processo.id.toString());
                formData.append(
                  "docAssinantes",
                  this.listaAssinador
                    .map(function (assinador) {
                      return assinador.documento.toString();
                    })
                    .join(",")
                );
                formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());

                const listMetadadoValor: MetadadoValor[] = [];
                var metadadoValido = true;
                this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
                  if (!this.validarMetadado(tipoDocumentoMetadado)) {
                    metadadoValido = false;
                  }
                  const metadadoValor = new MetadadoValor();
                  metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
                  metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
                  listMetadadoValor.push(metadadoValor);
                });
                // Salvando informações digitados dos metadados
                this.documentoNovo.listMetadadoValor = listMetadadoValor;

                formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));

                if (metadadoValido)
                  if (arquivoPdf) {
                    this.documentoService.post(formData).subscribe(
                      (documento) => {
                        this.processoService.get(this.processo.id).subscribe(
                          (resp) => {
                            if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                              this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                              this.messageService.messageSuccess("Documento substituído com sucesso!");
                            } else {
                              this.messageService.messageSuccess("Documento inserido com sucesso!");
                            }
                            this.natureza = null;
                            this.listarDocumentosProcesso(resp);
                          },
                          (error) => {
                            this.messageService.messageErro(error.error.message);
                            throw error;
                          }
                        );
                      },
                      (error) => {
                        this.messageService.messageErro(error.error.message);
                        throw error;
                      }
                    );
                  } else {
                    this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
                      (documento) => {
                        this.processoService.get(this.processo.id).subscribe(
                          (resp) => {
                            if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                              this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                              this.messageService.messageSuccess("Documento substituído com sucesso!");
                            } else {
                              this.messageService.messageSuccess("Documento inserido com sucesso!");
                            }
                            this.natureza = null;
                            this.listarDocumentosProcesso(resp);
                          },
                          (error) => {
                            this.messageService.messageErro(error.error.message);
                            throw error;
                          }
                        );
                      },
                      (error) => {
                        this.messageService.messageErro(error.error.message);
                        throw error;
                      }
                    );
                  }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          const formData: FormData = new FormData();
          const file: File = fileList[0];
          formData.append("file", file);

          formData.append("fileName", file.name);
          formData.append("descricao", this.documentoNovo.descricao);
          formData.append("descricaoAssunto", this.processo.assunto.descricao);
          formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
          formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
          formData.append("processoId", this.processo.id.toString());
          formData.append(
            "docAssinantes",
            this.listaAssinador
              .map(function (assinador) {
                return assinador.documento.toString();
              })
              .join(",")
          );
          formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());

          const listMetadadoValor: MetadadoValor[] = [];
          var metadadoValido = true;
          this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
            if (!this.validarMetadado(tipoDocumentoMetadado)) {
              metadadoValido = false;
            }
            const metadadoValor = new MetadadoValor();
            metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
            metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
            listMetadadoValor.push(metadadoValor);
          });
          // Salvando informações digitados dos metadados
          this.documentoNovo.listMetadadoValor = listMetadadoValor;

          formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));

          if (metadadoValido)
            if (arquivoPdf) {
              this.documentoService.post(formData).subscribe(
                (documento) => {
                  this.processoService.get(this.processo.id).subscribe(
                    (resp) => {
                      if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                        this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                        this.messageService.messageSuccess("Documento substituído com sucesso!");
                      } else {
                        this.messageService.messageSuccess("Documento inserido com sucesso!");
                      }
                      this.natureza = null;
                      this.listarDocumentosProcesso(resp);
                    },
                    (error) => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    }
                  );
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
            } else {
              this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(
                (documento) => {
                  this.processoService.get(this.processo.id).subscribe(
                    (resp) => {
                      if (this.documentoSubstituir != null || this.documentoSubstituir != undefined) {
                        this.deleteDocumentoMarcaDagua(this.documentoSubstituir);
                        this.messageService.messageSuccess("Documento substituído com sucesso!");
                      } else {
                        this.messageService.messageSuccess("Documento inserido com sucesso!");
                      }
                      this.natureza = null;
                      this.listarDocumentosProcesso(resp);
                    },
                    (error) => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    }
                  );
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
            }
        }
      }
    }
  }

  validarMetadado(tipoDocumentoMetadado: TipoDocumentoMetadado): boolean {
    if (
      tipoDocumentoMetadado.metadado.valor !== undefined &&
      tipoDocumentoMetadado.metadado.valor !== null &&
      tipoDocumentoMetadado.metadado.valor.length !== 0
    ) {
      if (
        tipoDocumentoMetadado.metadado.mascara !== undefined &&
        tipoDocumentoMetadado.metadado.mascara !== null &&
        tipoDocumentoMetadado.metadado.validarQuantidadeCaracteres &&
        tipoDocumentoMetadado.metadado.valor.length !== tipoDocumentoMetadado.metadado.mascara.length
      ) {
        this.messageService.messageErro(
          "Preencha o campo " + tipoDocumentoMetadado.metadado.nome + " conforme a máscara " + tipoDocumentoMetadado.metadado.mascara + "."
        );
        return false;
      }
    } else if (tipoDocumentoMetadado.requerido) {
      this.messageService.messageErro("Preencha o campo " + tipoDocumentoMetadado.metadado.nome);
      return false;
    }

    return true;
  }

  private validar(arquivoPdf): boolean {
    let validacao = true;

    if (this.documentoNovo.tipoDocumento.nomeDocumento == null) {
      this.messageService.messageWarning("Selecione o tipo de documento.");
      validacao = false;
    }
    if (!this.documentoNovo.descricao) {
      this.messageService.messageWarning("Preencha o campo descrição.");
      validacao = false;
    }

    if (!this.documentoNovo.modeloDocumento && this.natureza === 1) {
      this.messageService.messageWarning("Preencha o campo conteúdo.");
      validacao = false;
    }

    // if (arquivoPdf) {
    //   if (this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura + " assinadores.");
    //     validacao = false;
    //   }

    //   if (this.documentoNovo.tipoDocumento.qtdeExataAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeExataAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeExataAssinatura + " assinadores.");
    //     validacao = false;
    //   }
    // }

    return validacao;
  }

  downloadDocumento(documento: Documento) {
    const isPDF = documento.nomeDocumento.substring(documento.nomeDocumento.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1;
    if (isPDF) {
      this.documentoService.download(documento.id).subscribe((blob) => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    } else {
      this.documentoService.download(documento.id, "octet/stream").subscribe((blob) => {
        if (!Utils.downloadBlob(blob, documento.nomeDocumento)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    }
  }

  validaDeleteDocumento(documento: Documento, index: number) {
    if (this.processo.ultimaMovimentacao.situacao.situacaoFinal == true) {
      this.messageService.messageWarning("Não é permitido deletar documento de um processo em situação final.");
      return false;
    }

    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: "Realmente deseja tornar sem efeito o documento?",
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.deleteDocumentoMarcaDagua(documento);
        //TODO: Rever o desentranhamento do documento com Roberto e substituir o delete pelo desentranhaDocumento
        //this.desentranhaDocumento(documento);
      }
    });
  }

  desentranhaDocumento(documento: Documento) {
    const mensagem = "Justificativa do desentranhamento";
    const dialogRef = this.dialog.open(DialogJustificativaComponent, {
      maxWidth: "450px",
      height: "250px",
      data: mensagem,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult != null) {
        const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
          width: "400px",
          data: this.certificate,
        });

        openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
          if (certParam != null && certParam.cert64 != null) {
            this.certificate = certParam;
            const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
            this.documentoService.desentranhaDocumento(documento.id, dialogResult, this.processo.etiquetaProcessoViproc).subscribe(
              (res) => {
                this.documentoService
                  .startWithHtml(
                    res.html,
                    cert.cert64,
                    this.processo.id,
                    this.documentoUsuarioLogado,
                    res.quantidadePaginasParaCriarNoDocumento,
                    SharedService.CARREGAR_LOADING
                  )
                  .subscribe(
                    (sigParams) => {
                      this.pki
                        .signHash({
                          thumbprint: cert.thumbprint,
                          hash: sigParams.toSignHash,
                          digestAlgorithm: sigParams.digestAlgorithm,
                        })
                        .success((signature) => {
                          this.completeDesentranhamento(sigParams, signature, res, documento.id);
                        });
                    },
                    (error) => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    }
                  );
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          }
        });
      }
    });
  }

  private completeDesentranhamento(sigParams: any, signature: string, certificadoResponse: any, documentoDesentranhado: number) {
    this.documentoService
      .complete(
        sigParams.fileName,
        sigParams.transferFileId,
        signature,
        certificadoResponse.documentoDescricao,
        this.processo.assunto.descricao,
        certificadoResponse.tipoDocumentoId,
        certificadoResponse.autorDoc,
        certificadoResponse.nomeArquivo,
        sigParams.descricaoAssinatura,
        sigParams.hashDocumento,
        this.processo.id,
        certificadoResponse.html,
        SharedService.CARREGAR_LOADING
      )
      .subscribe(
        (documento) => {
          this.documentoAssinado = new DocumentoDTO();
          this.documentoAssinado.documentoId = documento.id;
          const processoNovasInformacoes = new ProcessoDTO();
          processoNovasInformacoes.id = this.processo.id;
          processoNovasInformacoes.listDocumento = [];
          processoNovasInformacoes.listDocumento.push(this.documentoAssinado);
          this.processoService
            .atualizaProcessoDocumentoDesentranhado(processoNovasInformacoes.id, documentoDesentranhado, processoNovasInformacoes)
            .subscribe(
              (resp) => {
                this.listarDocumentosProcesso(resp);
                this.messageService.messageSuccess("Documento desentranhado com sucesso.");
                this.loadingScreenService.stopLoading();
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
        },
        (error) => {
          this.messageService.messageErro("Erro no upload do arquivo.");
        }
      );
  }

  listaAssinadorChange(listaAssinador: any[]) {
    this.listaAssinador = listaAssinador;
  }

  selectionProcessoChange(selectionProcesso: any[]) {
    this.selectionProcesso = new SelectionModel<ProcessoTabelaDto>(true, selectionProcesso);
  }

  setVisibilidadeDocumento(visibilidade: boolean, documento: Documento) {
    const message = "Deseja alterar a visibilidade do documento selecionado?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.documentoService.atualizarDownloadExternoDocumento(documento.id, visibilidade).subscribe(
          (res) => {
            documento.downloadUsuarioExterno = visibilidade;
            this.messageService.messageSuccess("Visibilidade do Documento " + documento.descricao + " alterado com sucesso!");
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    });
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((lotacao) => {
      if (lotacao) {
        this.processoPesquisa.lotacaoAtual = [];
        this.processoPesquisa.lotacaoAtual.push(lotacao.id);
      }
    });
  }

  private openDialogLotacaoOrigem() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((lotacao) => {
      if (lotacao) {
        this.processoPesquisa.lotacaoResponsavel = [];
        this.processoPesquisa.lotacaoResponsavel.push(lotacao.id);
      }
    });
  }

  getLotacao(id: number[]): any {
    return this.orgaoLocalList.find((lotacao) => lotacao.id === id[0]);
  }

  private limparLotacaoDigitada(event: any) {
    event.value = "";
  }

  private limparorigemDigitada(event: any) {
    event.value = "";
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  alterarNivelSigilo(nivel: any) {
    this.processoService.alterarNivelSigilo(this.processo.id, nivel.id).subscribe(
      (resp) => {
        this.processo.nivelSigilo = nivel;
        this.messageService.messageSuccess("Nível de sigilo alterado com sucesso!");
      },
      (error) => {
        this.nivelSigiloProcesso = NivelSigiloEnumAll.filter((nivelSigilo) => nivelSigilo.id == this.processo.nivelSigilo)[0];
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private setDataInicioInput(type: string, event: string) {
    this.dataInicioInput = event;
  }

  private setDataInicioChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataInicial = event.value;
  }

  private setDataInicioBlur() {
    this.dataInicial = moment(this.dataInicioInput, "DD/MM/YYYY").toDate();
  }

  private setDataFinalInput(type: string, event: string) {
    this.dataFinalInput = event;
  }

  private setDataFinalChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataFinal = event.value;
  }

  private setDataFinalBlur() {
    this.dataFinal = moment(this.dataFinalInput, "DD/MM/YYYY").toDate();
  }

  private setDataInicioArquivamentoInput(type: string, event: string) {
    this.dataInicioArquivamentoInput = event;
  }

  private setDataInicioArquivamentoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataInicialArquivamento = event.value;
  }

  private setDataInicioArquivamentoBlur() {
    this.dataInicialArquivamento = moment(this.dataInicioArquivamentoInput, "DD/MM/YYYY").toDate();
  }

  private setDataFinalArquivamentoInput(type: string, event: string) {
    this.dataFimArquivamentoInput = event;
  }

  private setDataFinalArquivamentoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataFinalArquivamento = event.value;
  }

  private setDataFinalArquivamentoBlur() {
    this.dataFinalArquivamento = moment(this.dataFimArquivamentoInput, "DD/MM/YYYY").toDate();
  }

  atualizarEventosItemNaoSimilaridade($event) {
    this.updateEventosItemNaoSimilaridade = !this.updateEventosItemNaoSimilaridade;
  }

  substituirDocumento(event: any, documento: Documento) {
    if (documento.tipoDocumento.somenteAnexo) {
      this.adicionarDocumento();
      this.documentoNovo.tipoDocumento = documento.tipoDocumento;
      this.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
      this.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
      this.documentoNovo.tipoDocumento.nomeDocumento = documento.tipoDocumento.nomeDocumento;
      this.documentoNovo.descricao = documento.descricao;
    } else {
      this.natureza = 1;
      this.exibicaoEditor == true;
      this.novoDocumento();
      this.adicionarDocumentoEditor();
      this.documentoNovo.tipoDocumento = documento.tipoDocumento;
      this.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
      this.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
      this.documentoNovo.descricao = documento.descricao;
      Utils.rolarParaAncora("editorHtml");
    }
    this.documentoSubstituir = documento;
  }

  deleteDocumentoMarcaDagua(documento: any) {
    // if (documento.autor.documento != this.sharedService.decodePayloadJWT().sub) {
    //   this.messageService.messageWarning("Não é permitido deletar documento de um outro autor.");
    //   throw new Error();
    // }

    this.documentoService.deleteDocumentoById(documento.id).subscribe(
      (resp) => {
        this.listarDocumentosProcesso(this.processo);
        this.resetDocSubstituir();
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  isDocMesmoAutor(documento: Documento): boolean {
    if (this.sharedService.decodePayloadJWT().sub === documento.autor.documento) return true;
    else return false;
  }

  private resetDocSubstituir() {
    this.documentoSubstituir = null;
  }

  public cancelarSubstituicao() {
    this.novoDocumento();
    if (!this.documentoSubstituir.tipoDocumento.somenteAnexo) this.adicionarDocumentoEditor();
    this.ngOnInit();
  }

  getClasse(documento: Documento) {
    if (documento != null && !documento.ativo) return "bg-disabled";
  }

  isAssinado(documento: Documento) {
    if (documento.assinado || documento.nomeDocumento.toLocaleLowerCase().indexOf(".pdf") > -1) return false;

    return true;
  }
  private openDialogListarPendenciasProcesso() {
    const dialogArquivar = this.dialog.open(DialogListarPendenciasProcesso, {
      width: this.pendenciaProcesso.resultado.aptoAbertura ? "150px" : "700px",
      data: this.pendenciaProcesso,
    });
    dialogArquivar.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
      }
    });
  }

  habilitarEnvioViproc() {
    this.isEnviarViproc = true;
  }

  enviarParaViproc(usuarioViproc: string, senhaViproc: string) {
    let autor = this.processo.autor.nome;
    let etiqueta = this.processo.etiquetaProcessoViproc.split("/")[0];
    let ano = this.processo.etiquetaProcessoViproc.split("/")[1];
    let orgaoOrigem = this.sharedService.decodePayloadJWT().orgaoSigla;

    this.viprocService.enviarProcessoParaViproc(autor, etiqueta, ano, orgaoOrigem, usuarioViproc, senhaViproc).subscribe(
      (response) => {
        this.messageService.messageSuccess("Processo enviado para o Viproc com sucesso.");
        return;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private async carregarDocumentosDoRequest(documentos: Documento[]) {
    if (documentos != null) {
      documentos.forEach((documento) => {
        const qtdAssinatura = documento.listAssinatura.filter((assinador) => assinador.assinado).length;
        if (
          (documento.tipoDocumento.qtdeMinimaAssinatura != null && qtdAssinatura >= documento.tipoDocumento.qtdeMinimaAssinatura) ||
          (documento.tipoDocumento.qtdeExataAssinatura != null && qtdAssinatura === documento.tipoDocumento.qtdeExataAssinatura)
        ) {
          documento.assinado = true;
        } else {
          documento.assinado = false;
        }
      });
      this.documentos = documentos;
      this.dataSourceDocumento.data = this.documentos;
      this.dataSourceDocumento.paginator = this.paginatorDocumento;

      this.invisibleButton.nativeElement.click();
    }
  }

  public InserirAssinadorLista(assinatura: Assinatura) {
    const novaAssinatura = new Assinador();
    novaAssinatura.nome = assinatura.responsavel.nome;
    novaAssinatura.documento = assinatura.responsavel.documento;
    novaAssinatura.dataHoraIndicacao = assinatura.dataCriacao;
    novaAssinatura.dataHoraAssinatura = assinatura.dataAssinado;
    novaAssinatura.assinado = assinatura.assinado;

    this.listaAssinador.push(novaAssinatura);
    this.cdRef.detectChanges();
  }

  verificarPermissaoMovimentarProcesso(id: number) {
    this.processoService.verificarProcessoPertenceLotacaoUsuario(id, this.sharedService.decodePayloadJWT().sub).subscribe(
      (response) => {
        if (response == null) {
          this.messageService.messageInfo("Não foi possível consultar motor de regras");
          return;
        }
        this.podeMovimentar = response;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  buscarOcorrenciasPorProcesso() {
    this.ocorrenciaService.listarOcorrenciaPorProcessoId(this.processo.id).subscribe(
      (ocorrencias) => {
        this.dataSourceOcorrencias = new MatTableDataSource<OcorrenciaListarDTO>(ocorrencias);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  buscarPublicacaoPorProcesso() {
    this.publicacaoService.buscarPublicacoesPorProcessoId(this.processo.id).subscribe(
      (publicacao) => {
        this.dataSourcePublicacao = new MatTableDataSource<Publicacao>(publicacao);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  buscarProcessosRelacionados() {
    this.processoService.jaExisteProcessoCriadoParaVincular(this.processo.id).subscribe(
      (processos) => {
        this.dataSourceProcessosRelacionados = new MatTableDataSource<ProcessoTabelaDto>(processos);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  cadastrarOcorrencia() {
    const dialogCadastrarOcorrencia = this.dialog.open(DialogAdicionarOcorrenciaProcesso, {
      width: "1000px",
      data: this.processo.id,
    });
    dialogCadastrarOcorrencia.afterClosed().subscribe(() => {
      this.buscarOcorrenciasPorProcesso();
    });
  }

  alterarVisibilidade(ocorrencia: OcorrenciaListarDTO) {
    if (this.podeMovimentar) {
      const novaVisbilidade = ocorrencia.ativo ? " desativar " : " ativar ";
      const message = "Deseja" + novaVisbilidade + "ocorrência?";
      const dialogRef = this.dialog.open(DialogConfirmarComponent, {
        maxWidth: "400px",
        data: message,
      });
      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.ocorrenciaService.alterarVisibilidade(ocorrencia.id).subscribe(
            (res) => {
              this.buscarOcorrenciasPorProcesso();
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        } else {
          this.buscarOcorrenciasPorProcesso();
        }
      });
    }
  }
}
