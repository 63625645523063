import { TIPO_LOGIN } from "src/app/global/api/seguranca/tipo-login.enum";

export const environment = {
  production: false,
  CONTEXTO: "sgeap",
  LAKUNA_CERT_KEY:
    "Ai4AKi5jZWFyYXByZXYuY2UuZ292LmJyLGFwcHN3ZWIuc2VwbGFnLmNlLmdvdi5icnQAaXA0OjEwLjAuMC4wLzgsaXA0OjEyNy4wLjAuMC84LGlwNDoxNzIuMTYuMC4wLzEyLGlwNDoxOTIuMTY4LjAuMC8xNixzaXN0ZW1hczUuc2VwbGFnLmNlLmdvdi5icix3ZWI1LnNlcGxhZy5jZS5nb3YuYnICAENBCAAAWJo2b2TcCAAB227by3zsx0LhTPoQ+2tlI2mF6hght0R/6BHbJ8JrRit/s/dGZ3xU3VN7BxzYhq1CUDpDTDqRta9WUIS5m8HUXUw4seVo/BCsZGBRLomSUzvidHgyxBIk37KY13sG17syQtwMjGM6FMXbLl2vgcbwx4FyK5N+fsR+sZy4hfbffbS7A5gz65Fib7DgBh+FJ3ix0diNQuTxcJiE0bz4uWG6XSVchkNgZeqK1eaLxYzkxXFluBRqmkHbbi1cZMC2bDpp6bOCwj7/04D7KfcxPFXWOzyVxDpiuoDMV7axDlQtc98eyeDquQ0IUM9CtNiU9zUTp4JjGc/uBX9rW5+0BQzXzQ==",
  VISAO: 2,
  TIPO_LOGIN: TIPO_LOGIN.USUARIO,
};
