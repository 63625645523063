import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTreeNestedDataSource } from "@angular/material";
import { DialogProcessoComponent } from "../dialog-processo/dialog-processo.component";
import { UsuarioService } from "../../api/usuario.service";
import { NestedTreeControl } from "@angular/cdk/tree";
import { OrgaoService } from "../../api/orgao.service";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { SelectionModel } from "@angular/cdk/collections";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-dialog-lotacao",
  templateUrl: "./dialog-lotacao.component.html",
  styleUrls: ["./dialog-lotacao.component.css"]
})
export class DialogLotacaoComponent implements OnInit {

  treeControl = new NestedTreeControl<any>(node => node.filhos);
  dataSource = new MatTreeNestedDataSource<any>();
  public lotacaoSelecionada: any;
  public lotacoesSelecionadas = new SelectionModel<any>(true, []);
  public lotacoesFavoritas: MatTableDataSource<any>;
  public idsLotacoesFavoritas: Number[];
  public idsLotacoesFiltradas: Number[];
  public displayedLotacaoColumns: string[] = ["descricao", "favorito"];
  public filtro: String;
  public multiSelect: boolean = false;
  public processoId: number;

  constructor(
    public dialogRef: MatDialogRef<DialogProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orgaoService: OrgaoService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private sharedService: SharedService) {
      if (data) {
        if(data.ehSelect) {
          this.multiSelect = data.multiSelect;
          if (data.selected)
            this.lotacoesSelecionadas = new SelectionModel<any>(true, data.selected);
        } else {
          this.processoId =  data.processoId;
        }  
      }
    }

  ngOnInit() {

    const token = this.sharedService.decodePayloadJWT();
   
    this.orgaoService.getOrgaoLocalArvore(this.processoId, token.perfil).subscribe(
      (res) =>{
        res.filhos.sort((a,b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));
        const lotacoesArvore = [];
        lotacoesArvore.push(res);
        this.dataSource.data = lotacoesArvore;        
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    
    this.usuarioService.pesquisarLotacoesFavoritas().subscribe(
      idsLotacoesFavoritas => {
        this.prepararTabelaLotacoesFavoritas(idsLotacoesFavoritas);
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  alternarLotacaoFavorita(id: Number): void {
    this.usuarioService.alternarLotacaoFavorita(id).subscribe(
      idsLotacoesFavoritas => {
        this.prepararTabelaLotacoesFavoritas(idsLotacoesFavoritas)
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  prepararTabelaLotacoesFavoritas(idsLotacoesFavoritas: any) : void {
    let favoritas = [];
    this.idsLotacoesFavoritas = idsLotacoesFavoritas;
    this.orgaoService.getOrgaoLocal().subscribe(
      lotacoes => {
        lotacoes.forEach(lotacao => {
          if (this.idsLotacoesFavoritas != null && this.idsLotacoesFavoritas.length != 0 && idsLotacoesFavoritas.includes(lotacao.id))
          favoritas.push(lotacao);
        });
        this.lotacoesFavoritas = new MatTableDataSource<any>(favoritas);
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  isFavorito(idLotacao: Number) : Boolean {
    return this.idsLotacoesFavoritas != null && this.idsLotacoesFavoritas.length != 0 && this.idsLotacoesFavoritas.includes(idLotacao);
  }


  hasChild = (_: number, node: any) => !!node.filhos && node.filhos.length > 0;

  onNoClick(): void {
    this.dialogRef.close();
  }

  selecionaLotacao(lotacao: any) {
    if (!this.multiSelect)
    this.lotacaoSelecionada = lotacao;
  }

  confirmarLotacao() {    
      this.dialogRef.close(this.multiSelect ? this.lotacoesSelecionadas.selected : this.lotacaoSelecionada);
  }

  isFiltrada(id: number): boolean {
    if (this.filtro != null && this.filtro != "" && this.filtro.length > 3){
      return this.idsLotacoesFiltradas != null && this.idsLotacoesFiltradas.length != 0 && this.idsLotacoesFiltradas.includes(id);
    }
    return true;
  }

  filtrarLotacoes(): void {
    this.idsLotacoesFiltradas = [];
    if (this.filtro != null && this.filtro != "" && this.filtro.length > 3)
      this.filtroRecursivoTree(this.dataSource.data);
  }

  filtroRecursivoTree(lotacoes: any[]): boolean {
    let isFiltroEncontrado = false;
    lotacoes.forEach(lotacao => {
      let isfilhosEncontrado = this.filtroRecursivoTree(lotacao.filhos);
      if (isfilhosEncontrado || lotacao.descricao.toUpperCase().match(this.filtro.toUpperCase())) {
        isFiltroEncontrado = true;
        this.idsLotacoesFiltradas.push(lotacao.id);
      }
    });
    return isFiltroEncontrado;
  }

  isAllSelectedLotacao() {
    if (this.lotacoesSelecionadas != null || this.lotacoesSelecionadas !== undefined) {
      const numSelected = this.lotacoesSelecionadas.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  masterToggleLotacao() {
    if (this.dataSource.data != null) {
      this.isAllSelectedLotacao() ?
        this.lotacoesSelecionadas.clear() :
        this.dataSource.data.forEach(row => this.lotacoesSelecionadas.select(row));
    }
    // this.atualizarListaSelecionados();
  }

  checkboxLabelLotacao(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedLotacao() ? "select" : "deselect"} all`;
    }
    return `${this.lotacoesSelecionadas.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

}
