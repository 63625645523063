import { D, I } from "@angular/cdk/keycodes";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatStepper } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { SharedService } from "src/app/global/api/shared.service";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { MessageService } from "src/app/global/message/message.service";
import { DocumentoService } from "../../api/documento.service";
import { ProcessoService } from "../../api/processo.service";
import { Assunto } from "../../models/assunto.model";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { Documento } from "../../models/documento.model";
import { CondicoesParaTrazerDocs } from "../../models/pensao/condicoes-para-trazer-docs.model";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { ProcessoPensaoDTO } from "../../models/pensao/processo-pensao-dto.model";
import { Representante } from "../../models/pensao/representante.model";
import { Requerente } from "../../models/pensao/requerente.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { TipoSolicitante, TipoSolicitanteEnumAll } from "../../models/pensao/tipo-solicitante.enum";
import { UploadDocumento } from "../../models/pensao/upload-documento.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { Processo } from "../../models/processo.model";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { SolicitanteDTO } from "../../models/solicitante-dto.model";
import { ConsultarProcessoComponent } from "../../processo/consultar-processo/consultar-processo.component";
import { InputDadosInstituidorComponent } from "../input-dados-instituidor/input-dados-instituidor.component";
import { InputDadosRequerenteComponent } from "../input-dados-requerente/input-dados-requerente.component";
import { InputDadosSolicitanteComponent } from "../input-dados-solicitante/input-dados-solicitante.component";
import { UploadDocumentoComponent } from "../upload-documento/upload-documento.component";

@Component({
  selector: "app-salvar-processo-pensao",
  templateUrl: "./salvar-processo-pensao.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./salvar-processo-pensao.component.css"],
})
export class SalvarProcessoPensaoComponent implements OnInit {
  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("uploadDocumentoSolicitanteComponent") uploadDocumentoSolicitanteComponent: UploadDocumentoComponent;
  @ViewChild("uploadDocumentoInstituidorComponent") uploadDocumentoInstituidorComponent: UploadDocumentoComponent;
  @ViewChild("uploadDocumentoRequerenteComponent") uploadDocumentoRequerenteComponent: UploadDocumentoComponent;
  @ViewChild("inputDadosSolicitante") inputDadosSolicitante: InputDadosSolicitanteComponent;
  @ViewChild("inputDadosInstituidor") inputDadosInstituidor: InputDadosInstituidorComponent;
  @ViewChild("inputDadosRequerente") inputDadosRequerente: InputDadosRequerenteComponent;

  private solicitante: Solicitante = new Solicitante();
  private condicoesParaTrazerDocs: CondicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
  private formSolicitanteValido = false;
  private instituidor: Instituidor = new Instituidor();
  private requerentes: Requerente[] = [];
  public listTipoDocumento: UploadDocumento[] = [];
  public selectedIndex: number;
  public isCompleted: boolean = false;
  public exibirBtnSalvar: boolean = false;
  public atualizarListaDocumentos: boolean = false;
  public assunto: Assunto = new Assunto();
  public numeroProcesso: string = "";
  public totalMatriculas: number = 0;
  public totalDocs: number = 0;
  public idTipoPensao;
  private usuarioViproc: string;
  private senhaViproc: string;

  public nowDate = new Date();
  senhaVisivel: boolean;
  constructor(
    private transfereService: TransfereService,
    private processoService: ProcessoService,
    private documentoService: DocumentoService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private changeDetector: ChangeDetectorRef,
    public ngxSmartModalService: NgxSmartModalService,
    private ngZone: NgZone,
    protected route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.idTipoPensao = this.route.snapshot.queryParams.idTipoPensao;
    this.assunto.id = this.idTipoPensao;
  }

  selectionChange(event: StepperSelectionEvent) {
    if (event.selectedIndex == 2) this.exibirBtnSalvar = true;
    else this.exibirBtnSalvar = false;
  }

  getSolicitanteEventEmitter($event) {
    this.solicitante = $event.solicitante;
    this.formSolicitanteValido = $event.validacaoFormulario;
    this.atualizarListaDocumentos = $event.atualizarListaDocumentos;

    if (this.solicitante.requerente != undefined && this.solicitante.requerente === true) {
      this.inputDadosRequerente.preencherComDadosSolicitante(this.solicitante);
    } else {
      this.inputDadosRequerente.inicializarRequerente();
    }

    if (this.atualizarListaDocumentos) this.carregarDocumentos();
  }

  carregarDocumentos() {
    this.changeDetector.detectChanges();
    this.uploadDocumentoSolicitanteComponent.update(this.solicitante.documentosRegra);
  }

  getInstituidorEventEmitter($event) {
    this.instituidor = $event;
    this.uploadDocumentoInstituidorComponent.update(this.instituidor.documentosRegra);
  }

  getRequerenteEventEmitter($event) {
    // this.requerentes = ($event);
  }

  goForward(stepper: MatStepper) {
    // stepper.next();
    if (stepper.selectedIndex === 0 && this.validarDadosSolicitante()) {
      this.solicitante = this.inputDadosSolicitante.solicitante;
      this.montarDadosParaGerarDoc();
      if (this.uploadDocumentoSolicitanteComponent != undefined)
        this.solicitante.documentos = this.uploadDocumentoSolicitanteComponent.listDocumentos;
      stepper.next();
      return;
    }

    if (stepper.selectedIndex === 1 && this.validarDadosInstituidor()) {
      this.instituidor = this.inputDadosInstituidor.instituidor;
      this.montarDadosParaGerarDoc();
      this.instituidor.documentos = this.inputDadosInstituidor.uploadDocumentoInstituidorComponent.listDocumentos;
      stepper.next();
      return;
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  validaMaiorIdadeSolicitante() {
    var nasciData = this.solicitante.dataNascimento.toString();
    var nascimento = nasciData.split("-");

    var ano = parseInt(nascimento[0]);
    var mes = parseInt(nascimento[1]);
    var dia = parseInt(nascimento[2]);
    var verificaMaior = new Date(ano + 18, mes - 1, dia);

    if (this.nowDate < verificaMaior) {
      this.messageService.messageWarning("O Solicitante precisa ser maior de idade.");
      return false;
    }

    return true;
  }

  validarDadosSolicitante() {
    if (this.inputDadosSolicitante.formGroupSolicitante.invalid) {
      const invalid = [];
      const controls = this.inputDadosSolicitante.formGroupSolicitante.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.messageWarning("Preencha todos os campos obrigatórios (*) do solicitante.");
      return false;
    }

    if (!this.validaMaiorIdadeSolicitante()) return false;

    if (!this.solicitante.requerente && !this.solicitante.representanteLegal) {
      this.messageService.messageWarning("Não é possível abrir um processo se o solicitante não for requerente ou representante legal.");
      return false;
    }

    // if (
    //   this.solicitante.representanteLegal === true &&
    //   (this.uploadDocumentoSolicitanteComponent === undefined || !this.uploadDocumentoSolicitanteComponent.validarDocumentos())
    // ) {
    //   this.messageService.messageWarning("Anexe todos os documentos obrigatórios do solicitante");
    //   return false;
    // }

    return true;
  }

  validarDadosInstituidor() {
    if (this.inputDadosInstituidor.formGroupInstituidor.invalid) {
      const invalid = [];
      const controls = this.inputDadosInstituidor.formGroupInstituidor.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.messageWarning("Preencha todos os campos obrigatórios (*) do instituidor");
      return false;
    }

    if (this.inputDadosInstituidor.uploadDocumentoInstituidorComponent === undefined)
      this.messageService.messageWarning("Selecione uma matrícula para carregar carregar os arquivos obrigatórios.");

    if (!this.inputDadosInstituidor.uploadDocumentoInstituidorComponent.validarDocumentos()) {
      this.messageService.messageWarning("Anexe todos os documentos obrigatórios do instituidor");
      return false;
    }

    return true;
  }

  validarDadosRequerente() {
    if (this.inputDadosRequerente === undefined && this.requerentes.length === 0) {
      this.messageService.messageWarning("Erro ao carregar o módulo de upload de ducumentos");
      return false;
    }

    if (this.inputDadosRequerente != undefined) {
      if (this.inputDadosRequerente.formGroupRequerente.invalid) {
        const invalid = [];
        const controls = this.inputDadosRequerente.formGroupRequerente.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }

        this.messageService.messageWarning("Preencha todos os campos do requerente. <br/> " + invalid);
        return false;
      }
    }

    if (!this.inputDadosRequerente.uploadDocumentoRequerenteComponent.validarDocumentos()) {
      this.messageService.messageWarning("Anexe todos os documentos obrigatórios do requerente");
      return false;
    }

    return true;
  }

  separaDocsPorMatricula() {
    let documentosSeparados = [];

    this.requerentes.forEach((requerente) => {
      const documentosFiltrados = requerente.documentos.filter((doc) => {
        return doc.geradoPeloSistema === true;
      });

      documentosFiltrados.forEach((doc) => {
        if (
          doc.nomeDocumento &&
          doc.nomeDocumento.search &&
          this.instituidor.matricula &&
          doc.nomeDocumento.search("MAT" + this.instituidor.matricula) !== -1
        ) {
          documentosSeparados.push(doc);
        }
      });
    });

    return documentosSeparados;
  }

  async salvarProcessoPorMatricula(usuarioViproc: string, senhaViproc: string) {
    this.usuarioViproc = usuarioViproc;
    this.senhaViproc = senhaViproc;

    if (this.inputDadosRequerente.requerentes.length === 0) this.inputDadosRequerente.addRequerente();

    this.requerentes = this.inputDadosRequerente.requerentes;

    new Promise(() => {
      this.validarProcessoMotorRegras(
        this.usuarioViproc,
        this.senhaViproc,
        this.sharedService.decodePayloadJWT().idOrgaoDescricao,
        this.sharedService.decodePayloadJWT().orgaoSigla
      );
    });
  }

  private async salvarProcessoPensao(processoPensaoDTO: ProcessoPensaoDTO) {
    this.totalMatriculas = 0;
    
    // todos os documentos do processo que NAO foram gerados pelo sistema
    let docs = this.replicaDocsPorMatricula();
    this.instituidor.matriculas = [];
    this.instituidor.matriculas.push(this.instituidor.matricula);
    this.instituidor.matriculas.forEach((matricula) => {
      let documentosProcesso: Documento[] = [];
      docs.forEach(
        (documentoSalvo) => {
          // this.documentoService.salvarDocumentoNaoPDF(this.documentoASerSalvo(doc)).subscribe(
          //   (documentoSalvo) => {

          documentosProcesso = documentosProcesso.concat(documentoSalvo);

          if (docs.length === documentosProcesso.length) {
            this.instituidor.matricula = matricula;
            processoPensaoDTO.processo.listDocumento = this.montarDocumentoDTO(documentosProcesso);
            documentosProcesso = [];
            processoPensaoDTO.requerentes = this.requerentes;
            processoPensaoDTO.requerentes[0].documentos.map((documento, index) => {
              if (documento.descricao == "Selecione um Tipo de Documento") {
                processoPensaoDTO.requerentes[0].documentos.splice(index, 1);
              }
            });
            processoPensaoDTO.processo.criadoViaIntegracao = false;
            this.processoService.salvarProcessoPensao(processoPensaoDTO).subscribe(
              (response) => {
                this.totalMatriculas = this.totalMatriculas + 1;
                this.numeroProcesso = this.numeroProcesso.concat(response.numeroProcesso + "<br/>");
                if (this.instituidor.matriculas.length === this.totalMatriculas) {
                  this.abriDialogNumeroProcesso();
                }
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    });
  }

  private montarProcessoDTO(processo: Processo): ProcessoDTO {
    var autor: string;
    if (this.sharedService.getDocumentoAstra()) {
      autor = JSON.parse(this.sharedService.getDocumentoAstra());
    } else {
      autor = JSON.parse(this.sharedService.getDocumentoRepresentado());
    }

    const processoDTO = new ProcessoDTO();
    processoDTO.assunto = new Assunto();
    processoDTO.assunto.id = this.assunto.id;
    processoDTO.descricao = "Processo de pensão";
    processoDTO.representante = new SolicitanteDTO(this.sharedService.decodePayloadJWT().sub, this.sharedService.decodePayloadJWT().name);
    processoDTO.autor = new SolicitanteDTO(this.requerentes[0].cpf, this.requerentes[0].nome);

    return processoDTO;
  }

  private replicaDocsPorMatricula(): Documento[] {
    
    let documentos: Documento[] = [];
    if (this.solicitante.documentos != undefined) documentos = this.solicitante.documentos;
    if (this.instituidor.documentos != undefined) documentos = documentos.concat(this.instituidor.documentos);
    this.requerentes.forEach((requerente) => {
      if (requerente.documentos != undefined)
        documentos = documentos.concat(requerente.documentos);
    });

    return documentos;
  }

  private documentoASerSalvo(documento: Documento): FormData {
    const formData: FormData = new FormData();
    formData.append("file", documento.file);
    formData.append(
      "tipoDocumentoId",
      documento.tipoDocumento != undefined ? documento.tipoDocumento.id.toString() : documento.tipoDocumentoId.toString()
    );
    formData.append("descricao", documento.descricao);
    formData.append("descricaoAssunto", documento.descricaoAssunto);
    formData.append("docAutor", documento.docAutor != undefined ? documento.docAutor : this.sharedService.decodePayloadJWT().sub);
    formData.append("fileName", "MAT" + this.instituidor.matricula + " " + documento.nomeDocumento + " ");

    return formData;
  }

  /*** Recebe uma lista de documentos NAO gerados pelo sistema e contatena com os documentos gerados */
  private montarDocumentoDTO(documentos: Documento[]): DocumentoDTO[] {
    let ordem = 1;
    const documentosDTO: DocumentoDTO[] = [];
    //let documentosSeparados = [];

    // Documentos gerados
    // documentosSeparados = this.separaDocsPorMatricula();

    // documentos = documentos.concat(documentosSeparados);

    documentos.forEach((documento) => {
      if (documento.id != null) {
        const documentoDTO = new DocumentoDTO();
        documentoDTO.ordem = ordem;
        documentoDTO.documentoId = documento.id;
        documentosDTO.push(documentoDTO);
        ordem++;
      }
    });

    return documentosDTO;
  }

  validarProcessoMotorRegras(usuarioViproc: string, senhaViproc: string, idOrgaoUsuarioLogado: number, orgaoUsuarioLogado: string) {
    let regrasProcessos = this.montarRegraProcesso();

    this.processoService.regrasProcesso(regrasProcessos).subscribe(async (res) => {
      const processoPensaoDTO: ProcessoPensaoDTO = new ProcessoPensaoDTO();
      const processoDTO: ProcessoDTO = await this.montarProcessoDTO(new Processo());
      processoDTO.usuarioViproc = usuarioViproc.trim();
      processoDTO.senhaViproc = senhaViproc.trim();
      processoDTO.idOrgaoUsuarioLogado = idOrgaoUsuarioLogado;
      processoDTO.orgaoUsuarioLogado = orgaoUsuarioLogado;
      processoPensaoDTO.processo = processoDTO;
      processoPensaoDTO.solicitante = this.solicitante;
      processoPensaoDTO.instituidor = this.instituidor;

      processoPensaoDTO.instituidor.documentos.map((documento, index) => {
        if (documento.descricao == "Selecione um Tipo de Documento") {
          processoPensaoDTO.instituidor.documentos.splice(index, 1);
        }
      });
      if (res.processos != null) {
        if (res.tipo === "sucesso") {
          res.processos.forEach(async (processo) => {
            if (processo.resultado.aptoAbertura) {
              await this.salvarProcessoPensao(processoPensaoDTO);
            } else {
              if (processo.resultado.mensagemErroDocumentos != null) {
                this.messageService.messageWarning(processo.resultado.mensagemErroDocumentos);
                throw new Error(processo.resultado.mensagemErroDocumentos);
              } else if (processo.resultado.mensagemErroRepresentante != null) {
                this.messageService.messageWarning(processo.resultado.mensagemErroRepresentante);
                throw new Error(processo.resultado.mensagemErroRepresentante);
              } else {
                this.messageService.messageWarning("Ocorreu um erro na validação das informações. Contate o administrador do sistema.");
                throw new Error("Ocorreu um erro na validação das informações. Contate o administrador do sistema.");
              }
            }
          });
        } else {
          this.messageService.messageWarning(res.mensagem);
          throw new Error(res.mensagem);
        }
      } else {
        this.messageService.messageWarning("Ocorreu um erro na validação das informações. Contate o administrador do sistema.");
        throw new Error("Ocorreu um erro na validação das informações. Contate o administrador do sistema.");
      }
    }),
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw new Error(error.error.message);
      };
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regrasProcessos: RegrasProcesso[] = [];
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    regraProcesso.solicitante = this.solicitante;
    regraProcesso.instituidor = this.instituidor;
    regraProcesso.requerentes = this.requerentes;

    if (
      regraProcesso.requerentes &&
      regraProcesso.requerentes.length > 0 &&
      this.solicitante &&
      this.solicitante.cpf &&
      this.solicitante.nome
    ) {
      regraProcesso.requerentes.forEach((req) => {
        let representante = new Representante();
        representante.cpf = this.solicitante.cpf;
        representante.nome = this.solicitante.nome;
        req.representanteLegal = representante;
      });
    }

    regrasProcessos.push(regraProcesso);

    return regrasProcessos;
  }

  private montarDadosParaGerarDoc() {
    this.transfereService.clearData();

    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.solicitante = this.solicitante;
    regraProcesso.instituidor = this.instituidor;
    regraProcesso.requerentes = [];
    regraProcesso.requerentes.push(this.inputDadosRequerente.requerenteAtual());

    this.transfereService.setData(regraProcesso);
  }

  private async abriDialogNumeroProcesso() {
    this.ngxSmartModalService.setModalData(this.numeroProcesso, "modalProcesso");
    this.ngxSmartModalService.create("modalProcesso", "content").open();
  }

  public close(): void {
    this.ngxSmartModalService.close("modalProcesso");
    this.ngZone.run(() => this.router.navigate(["sgeap/consultar-processo"])).then();
  }

  toggleSenhaVisivel() {
    this.senhaVisivel = !this.senhaVisivel;
  }
}
