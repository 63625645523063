import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SalvarProcessoPensaoComponent } from "./salvar-processo-pensao/salvar-processo-pensao.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatListModule, MatButtonModule, MatIconModule, MatCardModule, MatSelectModule, MatFormFieldModule } from "@angular/material";
import { CKEditorModule } from "ng2-ckeditor";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { NgxLoadingModule } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { PopoverModule } from "ngx-smart-popover";
import { DirectivesModule } from "src/app/global/directives/directives.module";
import { MetadadoModule } from "src/app/global/metadado/metadado.module";
import { TitleViewModule } from "src/app/global/title-view/title-view.module";
import { MaterialModule } from "src/app/material";
import { InputDadosSolicitanteComponent } from "./input-dados-solicitante/input-dados-solicitante.component";
import { UploadDocumentoComponent } from "./upload-documento/upload-documento.component";
import { InputDadosInstituidorComponent } from "./input-dados-instituidor/input-dados-instituidor.component";
import { InputDadosRequerenteComponent } from "./input-dados-requerente/input-dados-requerente.component";
import { VisualizarProcessoPensaoComponent } from "./visualizar-processo-pensao/visualizar-processo-pensao.component";
import { ListarDocumentosComponent } from "./listar-documentos/listar-documentos.component";
import { VisualizarInstituidorComponent } from "./visualizar-instituidor/visualizar-instituidor.component";
import { VisualizarRequerenteComponent } from "./visualizar-requerente/visualizar-requerente.component";
import { VisualizarSolicitanteComponent } from "./visualizar-solicitante/visualizar-solicitante.component";
import { ValidadorCpfDirective } from "./directives/validador-cpf.directive";
import { UploadDocumentoEnvolvidosComponent } from './upload-documento-envolvidos/upload-documento-envolvidos.component';
import { UiModule } from 'src/app/ui/ui.module';
import { DocumentoAssinaturasEnvolvidosComponent } from "./documento-assinaturas-envolvidos/documento-assinaturas-envolvidos.component";

const routes: Routes = [
  {
    path: "salvar-processo-pensao",
    component: SalvarProcessoPensaoComponent,
    data: {
      title: "Salvar Processo de Pensão",
      breadcrumb: [
        {
          label: "Salvar Processo de Pensão",
          url: "/salvar-processo-pensao",
        },
      ],
    },
  },
  {
    path: "visualizar-processo-pensao/:idProcesso",
    component: VisualizarProcessoPensaoComponent,
    data: {
      title: "Visualizar Processo de Pensão",
      breadcrumb: [
        {
          label: "Visualizar Processo de Pensão",
          url: "/visualizar-processo-pensao/:idProcesso",
        },
      ],
    },
  },
];
@NgModule({
  declarations: [
    ValidadorCpfDirective,
    DocumentoAssinaturasEnvolvidosComponent,
    SalvarProcessoPensaoComponent,
    InputDadosSolicitanteComponent,
    UploadDocumentoComponent,
    InputDadosInstituidorComponent,
    InputDadosRequerenteComponent,
    VisualizarProcessoPensaoComponent,
    ListarDocumentosComponent,
    VisualizarInstituidorComponent,
    VisualizarRequerenteComponent,
    VisualizarSolicitanteComponent,
    UploadDocumentoEnvolvidosComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    CKEditorModule,
    MatCardModule,
    MetadadoModule,
    TitleViewModule,
    MaterialModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxSmartModalModule.forRoot(),
    PopoverModule,
    NgxMaskModule.forRoot(),
    PdfJsViewerModule,
    NgxLoadingModule.forRoot({}),
    DirectivesModule,
    UiModule,
  ],
  exports: [VisualizarProcessoPensaoComponent],
})
export class PensaoModule {}
