import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import LacunaWebPKI, { CertificateModel } from "web-pki";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { Certificate } from "../../../global/models/certificate.model";
import { Modelo } from "../../models/modelo.model";
import { ModeloService } from "../../api/modelo.service";

@Component({
  selector: "app-dialog-selecionar-modelo",
  templateUrl: "./dialog-selecionar-modelo.component.html",
  styleUrls: ["./dialog-selecionar-modelo.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelecionarModeloComponent implements OnInit {

  public modeloSelecionado: Modelo;
  modelos: Modelo[];
  public form: FormGroup;

  @ViewChild("simularClick") simularClick: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogSelecionarModeloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
          private formBuilder: FormBuilder,
          private modeloService: ModeloService) {
            this.form = this.formBuilder.group({
              modeloSelecionado: ["", []]
            });
    }

  ngOnInit() {
    this.getModelos();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public getModelos(): any {
    const modeloPesquisar = new Modelo;
    modeloPesquisar.tipoDocumento = this.data;
    this.modeloService.pesquisar(modeloPesquisar, 0, 0).subscribe((modelos: any) => {
      if (modelos && modelos.content) {
        this.modelos = modelos.content;
      }
    });
  }

  selecionar() {
    this.dialogRef.close(this.modeloSelecionado);
  }

  semAssinatura() {
    this.dialogRef.close(1);
  }

  cancel() {
    this.dialogRef.close(0);
  }
}
