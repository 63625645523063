import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TransfereService } from 'src/app/global/interceptors/transfer.service';
import { MessageService } from 'src/app/global/message/message.service';
import { Utils } from 'src/app/global/util/utils-component';
import { ProcessoService } from '../../api/processo.service';
import { SgprevService } from '../../api/sgprev.service';
import { CondicoesParaTrazerDocs } from '../../models/pensao/condicoes-para-trazer-docs.model';
import { DocumentosRegra } from '../../models/pensao/documentos-regra';
import { Instituidor } from '../../models/pensao/instituidor.model';
import { ProcessoPensaoDTO } from '../../models/pensao/processo-pensao-dto.model';
import { Vinculo } from '../../models/pensao/vinculo.model';
import { RegrasProcesso } from '../../models/regras-processo.model';
import { UploadDocumentoComponent } from '../upload-documento/upload-documento.component';

@Component({
  selector: 'app-visualizar-instituidor',
  templateUrl: './visualizar-instituidor.component.html',
  styleUrls: ['./visualizar-instituidor.component.css']
})
export class VisualizarInstituidorComponent implements OnInit {
  @ViewChild("uploadDocumentoInstituidorComponent") uploadDocumentoInstituidorComponent: UploadDocumentoComponent;

  @Input() instituidor : Instituidor;
  @Input() disabledValue: true;
  public ufs: string[] = Utils.getUfs();
  public formGroupInstituidor: FormGroup;
  orgaosOrigem: any[] = [];
  orgaosOrigemVindoDoEndpoint: any[] = [];
  public nowDate = new Date();
  public documentosRegra: DocumentosRegra[] = [];
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];
  myGroup: FormGroup;

  constructor(
    private sgprevService: SgprevService,
    private messageService: MessageService,
    private transfereService: TransfereService,
    private processoService: ProcessoService,
  ) { }

  ngOnInit() {
    this.myGroup = new FormGroup({
      firstName: new FormControl()
   });
  }

  verificarMudancaCpf(cpf: string) {
    cpf = cpf.replace(/[.*+\-?^${}()|[\]\\]/g, "").trim();

    if (cpf.length < 11) this.instituidor.matriculas = [];

    if (cpf.length === 11 && !this.formGroupInstituidor.controls["cpf"].invalid) {
      this.obterOrgaosPorCpf(cpf);
      this.validacaoCPFInstituidorSolicitante();
      this.getDocumentosRegra();
    }
  }

  obterOrgaosPorCpf(cpf: string) {
    this.orgaosOrigem = [];

    if (cpf != undefined && cpf != "" && cpf.length > 10) {
      this.sgprevService.getMatriculaPorCpf(cpf).subscribe(
        (vinculos: Vinculo[]) => {
          this.instituidor.vinculo = vinculos;
          vinculos.forEach((v) => {
            let siglaJaAdicionada = this.orgaosOrigem.filter((siglaOrgao) => v.localSigla === siglaOrgao);
            if (siglaJaAdicionada === undefined || siglaJaAdicionada.length === 0) this.orgaosOrigem.push({ localSigla: v.localSigla });
          });
          if (vinculos.length === 0) {
            this.instituidor.matriculas = [];
            this.orgaosOrigem = this.orgaosOrigemVindoDoEndpoint;
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  validacaoCPFInstituidorSolicitante() {
    let solicitanteCPF = this.transfereService.getData().solicitante.cpf;

    if (solicitanteCPF === this.instituidor.cpf) {
      this.instituidor.cpf = "";

      this.messageService.messageWarning(
        "Não é possível adicionar o mesmo CPF do Instituidor como Solicitante nesta solicitação. Digite outro CPF."
      );
      return false;
    }
  }

  getDocumentosRegra() {
    if (!this.formGroupInstituidor.invalid && this.instituidor.matricula.length > 0) {
      if (this.validaCamposInstituidor()) {
        this.instituidor.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
        this.instituidor.condicoesParaTrazerDocs = this.instituidor.condicoesParaTrazerDocs.copiarAtributosInstituidor(this.instituidor);
        this.instituidor.documentosRegra = [];
        this.instituidor.documentosFuncionais = [];
        this.validarProcessoMotorRegras();
      }
    }
  }

  validaCamposInstituidor() {
    if (this.instituidor.dataObito < this.instituidor.dataNascimento.toString()) {
      this.messageService.messageErro("A data de obito não deve anteceder a data de nascimento.");

      return false;
    }

    var nasciData = this.instituidor.dataNascimento.toString();
    var nascimento = nasciData.split("-");

    var ano = parseInt(nascimento[0]);
    var mes = parseInt(nascimento[1]);
    var dia = parseInt(nascimento[2]);
    var verificaMaior = new Date(ano + 18, mes - 1, dia);

    if (this.nowDate < verificaMaior) {
      this.messageService.messageWarning("O Instituidor precisa ser maior de idade.");
      return false;
    }

    return true;
  }

  validarProcessoMotorRegras() {
    let regrasProcessos = this.montarRegraProcesso();
    this.documentosRegra = [];

    this.processoService.regrasProcesso(regrasProcessos).subscribe(
      (res) => {
        this.instituidor.documentosRegra = res.processos[0].instituidor.documentosObrigatorios;
        this.instituidor.documentosFuncionais = res.processos[0].instituidor.documentosFuncionaisObrigatorios;
        // this.emitInstituidor();
        this.instituidor.documentosRegra.forEach((doc) => {
          this.documentosRegra.push(doc);
        });

        this.instituidor.documentosFuncionais.forEach((docFun) => {
          this.documentosRegra.push(docFun);
        });

        this.uploadDocumentoInstituidorComponent.update(this.documentosRegra);
      },
      (error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos necessários do instituidor");
        throw error;
      }
    );
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    regraProcesso.instituidor = this.instituidor;

    let regrasProcessos: RegrasProcesso[] = [regraProcesso];

    return regrasProcessos;
  }

  verificaProcessoExistente(){
    const processoPensaoDTO: ProcessoPensaoDTO = new ProcessoPensaoDTO();
    processoPensaoDTO.solicitante = this.transfereService.getData().solicitante;
    processoPensaoDTO.instituidor = this.instituidor;
    this.processoService.verificaExistenciaProcesso(processoPensaoDTO).subscribe(() => {      
      this.getDocumentosRegra();
    }, (error) => {
        this.messageService.messageErro(error.error);
        throw error;
    });
  }

}
