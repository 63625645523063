import {Directive, ElementRef, HostListener} from "@angular/core";
import {NgControl} from "@angular/forms";

@Directive({
  selector: "[validarCpf]"
})
export class ValidadorCpfDirective {
  
  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener("input", ["$event"]) onInputChange(event) {
    const cpf = this.el.nativeElement.value;
    const abstractControl = this.control.control;
    // Fix para forçar o css quando inválido
    const input = (<HTMLInputElement>this.el.nativeElement);
    input.parentElement.parentElement.parentElement.parentElement.classList.remove("mat-form-field-invalid")

    if (
      cpf === "000.000.000-00" ||
      cpf === "111.111.111-11" ||
      cpf === "222.222.222-22" ||
      cpf === "333.333.333-33" ||
      cpf === "444.444.444-44" ||
      cpf === "555.555.555-55" ||
      cpf === "666.666.666-66" ||
      cpf === "777.777.777-77" ||
      cpf === "888.888.888-88" ||
      cpf === "999.999.999-99"
    ) {
      abstractControl.setErrors(event);
      input.parentElement.parentElement.parentElement.parentElement.classList.add("mat-form-field-invalid")
    } else {
      let cpfNoDot, cpfNoSlash, cpfNoMask;
      cpfNoDot = cpf.replace(".", "");
      cpfNoSlash = cpfNoDot.replace("-", "");
      cpfNoMask = cpfNoSlash.replace(".", "");
      if (cpfNoMask) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        for (i = 0; i < cpfNoMask.length - 1; i++) {
          if (cpfNoMask.charAt(i) !== cpfNoMask.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpfNoMask.substring(0, 9);
          digits = cpfNoMask.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            abstractControl.setErrors(event);
            input.parentElement.parentElement.parentElement.parentElement.classList.add("mat-form-field-invalid")
            return {  };
          }
          numbers = cpfNoMask.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            abstractControl.setErrors(event);
            input.parentElement.parentElement.parentElement.parentElement.classList.add("mat-form-field-invalid")
            return { };
          }
        } else {
          abstractControl.setErrors(event);
          input.parentElement.parentElement.parentElement.parentElement.classList.add("mat-form-field-invalid")
        }

      }
    }

    event.stopPropagation();
  }
}
