import {Directive, ElementRef, HostListener, ViewChild} from "@angular/core";
import {NgControl} from "@angular/forms";

@Directive({
  selector: "[appDatecheck]"
})
export class DatecheckDirective {

  constructor(private el: ElementRef, private control: NgControl, @ViewChild("dataObito") private dataObito: ElementRef) { }

  @HostListener("input", ["$event"]) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;
    const obitoValue = this.dataObito.nativeElement.value;
    const abstractControl = this.control.control;
    const today = new Date();
    const dateInput = new Date(initialValue);
    const minDate = new Date(1900, 1 , 1);
    const dieDate = new Date(obitoValue);
    if ((dateInput > today) || (dateInput < minDate) ||(dieDate > dateInput)) {
      abstractControl.setErrors(event);
    }
    event.stopPropagation();
  }
}
