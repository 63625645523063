import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CKEditorModule } from "ng2-ckeditor";
import { PopoverModule } from "ngx-smart-popover";
import { MaterialModule } from "src/app/material";
import { ParametroDiversoService } from "../api/parametro-diverso.service";
import { DaeService } from "../api/dae.service";
import { ListarDaeComponent } from "./listar-dae/listar-dae.component";
import { ListarFilasComponent } from "./reprocessar-amq/listar-filas/listar-filas.component";
import { ListarMensagensComponent } from "./reprocessar-amq/listar-mensagens/listar-mensagens.component";
import { MasterHomeComponent } from "./master-home/master-home.component";
import { ListarParametroDiversoComponent } from "./parametro-diverso/listar-parametro-diverso/listar-parametro-diverso.component";
import { SalvarParametroDiversoComponent } from "./parametro-diverso/salvar-parametro-diverso/salvar-parametro-diverso.component";
import { NgxMaskModule } from "ngx-mask";
import { MatListModule, MatButtonModule, MatIconModule, MatSelectModule, MatFormFieldModule, MAT_DATE_LOCALE } from "@angular/material";
import { MatCardModule } from "@angular/material/card";
import { DialogInputComponent } from "src/app/global/dialogs/dialog-input/dialog-input.component";
import { ListarNumeroDocumentoDetalheComponent } from "./numeracao-documento/listar-numeracao-documento/listar-numero-documento-detalhe.component";
import { SalvarNumeroDocumentoDetalheComponent } from "./numeracao-documento/salvar-numeracao-documento/salvar-numero-documento-detalhe.component";
import { NumeroDocumentoDetalheService } from "../api/numero-documento-detalhe.service";
import { AngularSvgIconModule } from "angular-svg-icon";

const routes: Routes = [
  {
    path: "master-home", component: MasterHomeComponent, data: {
      title: "Master",
      breadcrumb: [
        {
          label: "Master",
          url: "/master-home"
        }
      ]
    }
  },
  {
    path: "parametro-diverso", component: ListarParametroDiversoComponent, data: {
      title: "Parâmetro Diverso",
      breadcrumb: [
        {
          label: "Parâmetro Diverso",
          url: "/parametro-diverso"
        }
      ]
    }
  },
  {
    path: "parametro-diverso/salvar", component: SalvarParametroDiversoComponent, data: {
      title: "Salvar Parâmetro Diverso",
      breadcrumb: [
        {
          label: "Salvar Parâmetro Diverso",
          url: "/parametro-diverso/salvar"
        }
      ]
    }
  },
  {
    path: "parametro-diverso/editar/:id", component: SalvarParametroDiversoComponent, data: {
      title: "Editar Parâmetro Diverso",
      breadcrumb: [
        {
          label: "Editar Parâmetro Diverso",
          url: "/parametro-diverso/editar/:id"
        }
      ]
    }
  },
  {
    path: "numero-documento-detalhe", component: ListarNumeroDocumentoDetalheComponent, data: {
      title: "Numeração Documento",
      breadcrumb: [
        {
          label: "Numeração Documento",
          url: "/numero-documento-detalhe"
        }
      ]
    }
  },
  {
    path: "numero-documento-detalhe/salvar", component: SalvarNumeroDocumentoDetalheComponent, data: {
      title: "Salvar Numeração Documento",
      breadcrumb: [
        {
          label: "Salvar Numeração Documento",
          url: "/numero-documento-detalhe/salvar"
        }
      ]
    }
  },
  {
    path: "numero-documento-detalhe/editar/:id", component: SalvarNumeroDocumentoDetalheComponent, data: {
      title: "Editar Numeração Documento",
      breadcrumb: [
        {
          label: "Editar Numeração Documento",
          url: "/numero-documento-detalhe/editar/:id"
        }
      ]
    }
  },
  { path: "lista-dae", component: ListarDaeComponent, data: {
    title: "Lista DAE",
    breadcrumb: [
      {
        label: "Lista DAE",
        url: "/lista-dae"
      }
    ]}
  },
  {
    path: "reprocessar-amq", component: ListarFilasComponent, data: {
      title: "Listar filas ActiveMQ",
      breadcrumb: [
        {
          label: "Listar filas ActiveMQ",
          url: "/reprocessar-amq"
        }
      ]
    }
  },
  {
    path: "reprocessar-amq/fila/:nomeFila", component: ListarMensagensComponent, data: {
      title: "Reprocessar mensagens",
      breadcrumb: [
        {
          label: "Reprocessar mensagens",
          url: "/reprocessar-amq/fila/:nomeFila"
        }
      ]
    }
  },
];

@NgModule({
  declarations: [
    ListarParametroDiversoComponent,
    SalvarParametroDiversoComponent,
    ListarNumeroDocumentoDetalheComponent,
    SalvarNumeroDocumentoDetalheComponent,
    MasterHomeComponent,
    ListarDaeComponent,
    DialogInputComponent,
    ListarMensagensComponent,
    ListarFilasComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PopoverModule,
    CKEditorModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatListModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    ParametroDiversoService,
    NumeroDocumentoDetalheService,
    DaeService,
    {provide: MAT_DATE_LOCALE, useValue: "pt-BR"}
  ],
  entryComponents: [
    DialogInputComponent
  ]
})
export class MasterModule { }
