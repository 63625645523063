import { Domain } from "../../global/models/domain.model";

export class AssuntoCategoria extends Domain {
  public descricao: string;
  public icone: string;
  public assuntoCategoriaPaiDescricao: string;
  public assuntoCategoriaId: number;
  public ativo: boolean;

  constructor(descricao: string) {
    super();
    this.descricao = descricao;
  }
}