import { Injectable, Inject } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class AvisoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "aviso", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  listarAvisoAtualPorTelaTipoExibicao(telaId: Number,tipoExibicaoId: Number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/tela/${telaId}/${tipoExibicaoId}`).pipe(map(response => response));
  }

  listarAvisoAtualPorTipoExibicao(tipoExibicaoId: Number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/tipo-exibicao/${tipoExibicaoId}`).pipe(map(response => response));
  }

  countAvisoAtualPorTipoExibicao(tipoExibicaoId: Number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/count-tipo-exibicao/${tipoExibicaoId}`).pipe(map(response => response));
  }

}