import { Documento } from "../documento.model";
import { CondicoesParaTrazerDocs } from "./condicoes-para-trazer-docs.model";
import { DocumentosRegra } from "./documentos-regra";
import { FalecidoEm } from "./falecido-em.enum";
import { Vinculo } from "./vinculo.model";

export class Instituidor {
    id: number;
    processoId: number;
    matricula: string;
    matriculas: string[];
    cpf: string;
    nome: string;
    sexo: string;
    rg: string;
    rgOrgaoEmissor: string;
    rgUf: string;
    estadoCivil: string;
    orgaoOrigem: string;
    cargo: string;
    grupo: string;
    dataNascimento: Date;
    dataObito: string;
	mesesContribuicao: BigInteger;
    falecidoEm: string;
    obitoRelacionadoServico: boolean;
    tiposDocumentosMotorRegras: DocumentosRegra[];
    quantidadeDependentes: Number;
    dependenteInvalido: boolean;
    dependenteNaFormaArtigo4Inciso3: boolean;
    ultimoProvento: Number;
    ultimoProventoInformado: string;
    percentualBaseCalculo: string;
    valorPensaoDefinitiva: string;
    percentualCotas: string;
    documentos : Documento[];
    documentosRegra: DocumentosRegra[];
    documentosObrigatorios: DocumentosRegra[];
    documentosRequeridosAposAbertura: DocumentosRegra[];
    documentosFuncionais: DocumentosRegra[];
    documentosFuncionaisObrigatorios: DocumentosRegra[];
    documentosFuncionaisFacultativos: DocumentosRegra[];
    condicoesParaTrazerDocs: CondicoesParaTrazerDocs;
    pensaoMilitar: boolean;
    mortePresumida: boolean;
	morteAcidental: boolean;
    vinculo: Vinculo[];
  siglaOrgao: string;
}