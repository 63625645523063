import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NumeroDocumentoDetalhe } from '../models/numero-documento-detalhe.model';
import { ApiService } from './api.service';
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({
  providedIn: 'root'
})
export class NumeroDocumentoDetalheService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "numero-documento-detalhe", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }

  public pesquisa(numeroDocumentoDetalhe: NumeroDocumentoDetalhe, page: number, size: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/paginacao?page=${page}&size=${size}`, numeroDocumentoDetalhe).pipe(map(response => response));
  }

}
