import { AppConfig, APP_CONFIG } from "src/app.config";
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SituacaoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "situacao", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  listarAtivas(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/ativas`).pipe(map(response => response));
  }

}