import { Component, OnInit } from "@angular/core";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "src/app/global/message/message.service";
import { NumeroDocumentoDetalheService } from "src/app/core/api/numero-documento-detalhe.service";
import { NumeroDocumentoDetalhe } from "src/app/core/models/numero-documento-detalhe.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";

@Component({
  selector: "app-salvar-numero-documento-detalhe",
  templateUrl: "./salvar-numero-documento-detalhe.component.html",
  styleUrls: ["./salvar-numero-documento-detalhe.component.css"]
})
export class SalvarNumeroDocumentoDetalheComponent extends SalvarGenericsComponent<NumeroDocumentoDetalhe> implements OnInit {

  public tiposDocumento: any[];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: NumeroDocumentoDetalheService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public tipoDocumentoService: TipoDocumentoService,
  ) {
    super(formBuilder, router, apiService, messageService, route, "numero-documento-detalhe", "");
  }

  ngOnInit() {
    this.tipoDocumentoService.list().subscribe(
      (listTipos) => {
        this.tiposDocumento = listTipos.filter(tpDoc => tpDoc.ativo && !tpDoc.somenteAnexo)
                            .sort((a, b) => a.nomeDocumento.localeCompare(b.nomeDocumento));
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.tipoDocumento) {
      this.messageService.messageErro("Preencha o campo Tipo Documento.");
      validacao = false;
    }
    if (!domain.ano) {
      this.messageService.messageErro("Preencha o campo Ano.");
      validacao = false;
    }
    if (!domain.numerosReservados) {
      this.messageService.messageErro("Preencha o campo Números Reservados.");
      validacao = false;
    }

    return validacao;
  }

  validarEsalvar(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  compareTipo(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1.id === obj2.id ? true : false;
  }
}
