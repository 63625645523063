import { Domain } from "src/app/global/models/domain.model";
import { Documento } from "../documento.model";
import { DocumentosRegra } from "./documentos-regra";
import { Endereco } from "./endereco.model";
import { TipoSolicitante, TipoSolicitanteFunctions } from "./tipo-solicitante.enum";


export class Solicitante extends Domain {
  id: number;
  processoId: number;
  requerente: boolean;
  representanteLegal: boolean;
  solicitanteTipo: string;
  tipoSolicitante: string;
  nome: string;
  sexo: string;
  dataNascimento: string;
  cpf: string;
  rg: string;
  rgOrgaoEmissor: string;
  rgUf: string;
  estadoCivil: string;
  telefone: string;
  email: string;
  endereco: Endereco;
  documentos: Documento[];
  documentosRegra: DocumentosRegra[];
  documentosObrigatorios: DocumentosRegra[];
  documentosRequeridosAposAbertura: DocumentosRegra[];
}