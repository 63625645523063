export enum TipoEmailEnum {
  ABERTURA_PROCESSO = ("Abertura de processo"),
  ARQUIVAMENTO_PROCESSO = ("Arquivamento de processo"),
  MOVIMENTACAO_PROCESSO = ("Informativo de movimentação em processos"),
  ATRIBUICAO_RESPONSAVEL = ("Atribuição de responsável de processo"),
  DISPONIBILIZACAO_DOCUMENTO = ("Disponibilização de documento ao contribuinte em processo"),
  ACEITE_CANCELAMENTO_AUTOMATICO_PROCESSO = ("Aceite de cancelamento automático de processo"),
  CONCLUSAO_AUTOMATICA_PROCESSO = ("Conclusão automática de processo"),
  CIENCIA_CONTRIBUINTE = ("Dar ciência ao contribuinte"),
  CANCELAMENTO_AUTOMATICA_PROCESSO = ("Cancelamento Automático de Processo"),
  ENVIO_PENDENCIA_PROCESSO = ("Envio de Pendência em processo"),
  RECEBIMENTO_SOLUCAO_PENDENCIA_PROCESSO = ("Recebimento de solução de pendências de processo"),
  REJEICAO_CANCELAMENTO_PROCESSO = ("Rejeição de cancelamento de processo"),
  SOLICITACAO_CANCELAMENTO_PROCESSO = ("Solicitação de cancelamento de processo"),
  ATRIBUICAO_RESPONSAVEL_LOTACAO = ("Atribuição para Lotação Responsável")
}

export const TipoEmailEnumAll = [
  { id: 0, descricao: TipoEmailEnum.ABERTURA_PROCESSO },
  { id: 1, descricao: TipoEmailEnum.ARQUIVAMENTO_PROCESSO },
  { id: 2, descricao: TipoEmailEnum.MOVIMENTACAO_PROCESSO },
  { id: 3, descricao: TipoEmailEnum.ATRIBUICAO_RESPONSAVEL },
  { id: 13, descricao: TipoEmailEnum.ATRIBUICAO_RESPONSAVEL_LOTACAO},
  { id: 4, descricao: TipoEmailEnum.DISPONIBILIZACAO_DOCUMENTO},
  { id: 5, descricao: TipoEmailEnum.ACEITE_CANCELAMENTO_AUTOMATICO_PROCESSO},
  { id: 6, descricao: TipoEmailEnum.CONCLUSAO_AUTOMATICA_PROCESSO},
  { id: 7, descricao: TipoEmailEnum.CIENCIA_CONTRIBUINTE},
  { id: 8, descricao: TipoEmailEnum.CANCELAMENTO_AUTOMATICA_PROCESSO},
  { id: 9, descricao: TipoEmailEnum.ENVIO_PENDENCIA_PROCESSO},
  { id: 10, descricao: TipoEmailEnum.RECEBIMENTO_SOLUCAO_PENDENCIA_PROCESSO},
  { id: 11, descricao: TipoEmailEnum.REJEICAO_CANCELAMENTO_PROCESSO},
  { id: 12, descricao: TipoEmailEnum.SOLICITACAO_CANCELAMENTO_PROCESSO},
];