import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MessageService } from "../../../global/message/message.service";
import { Processo } from "../../models/processo.model";
import { Documento } from "../../models/documento.model";
import { SgprevService } from "../../api/sgprev.service";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { ProcessoService } from "../../api/processo.service";
import { DocumentoService } from "../../api/documento.service";
import { LongDateFormatKey } from "moment";
import { Utils } from "src/app/global/util/utils-component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-dialog-indicar-edoweb",
  templateUrl: "./dialog-indicar-edoweb.component.html",
  styleUrls: ["./dialog-indicar-edoweb.component.css"],
})
export class DialogIndicarEdowebComponent implements OnInit {
  public numeroEdoweb: string;
  indicaEdoweb: string;
  numeroViproc: string;
  numeroBeneficio: string;
  numeroMatricula: string;
  siglaOrgao: string;
  edoWeb: string;
  processoId: number;
  documentoId: number;
  public processo: Processo;
  public instituidor: Instituidor;
  public documento: Documento;

  constructor(
    public dialogRef: MatDialogRef<DialogIndicarEdowebComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService,
    private sgprevService: SgprevService,
    private documentoService: DocumentoService
  ) {}

  ngOnInit() {
    this.processoId = this.data.processo.id;
    this.documentoId = this.data.documento.id;
    this.sgprevService.getNumeroEdoweb().subscribe((numero: string) => {
      this.numeroEdoweb = numero;
    });
  }
  
  

  onNoClick(): void {
    this.dialogRef.close();
  }

  

  incluirMarcados() {
    if (this.numeroEdoweb != null) {
      this.sgprevService.sendAto(this.processoId, this.numeroEdoweb, this.documentoId).subscribe(res => {
        this.messageService.messageSuccess("Enviado com sucesso");
        this.dialogRef.close();
      },
      error => {
        this.messageService.messageErro("Vinculo não encontrado para matricula e orgão");
        throw error;
        });
    }
  }
}
