export enum AcaoMudancaSituacao {
    VISUALIZACAO = ("Visualização"),
    ASSINATURA =  ("Assinatura"),
    ATRIBUICAO =  ("Atribuição"),
    INCLUIR_DOCUMENTO =  ("Incluir documento"),
    NENHUMA_ACAO = ("Nenhuma Acao")
  }

  export const AcaoMudancaSituacaoEnumAll = [
    {id: 4, descricao: AcaoMudancaSituacao.NENHUMA_ACAO},
    {id: 0, descricao: AcaoMudancaSituacao.VISUALIZACAO},
    {id: 1, descricao: AcaoMudancaSituacao.ASSINATURA},
    {id: 2, descricao: AcaoMudancaSituacao.ATRIBUICAO},
    {id: 3, descricao: AcaoMudancaSituacao.INCLUIR_DOCUMENTO}
  ];