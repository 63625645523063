
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { PesquisaDaeRequest } from "../models/pesquisa-dae-request";
import { DaeAtualizarStatus } from "../models/dae-atualizar-status.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class DaeService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "dae", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  public atualizarStatusDae(id: number, daeAtualizarStatus: DaeAtualizarStatus): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/${id}`, daeAtualizarStatus).pipe(map(response => {
        return response;
      }));
  }

  public verificarNossoNumeroUtilizado(nossoNumero: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar/${nossoNumero}`).
      pipe(map(response => {
        return response;
      }));
  }

  public pesquisarDae(pesquisaDaeRequest: PesquisaDaeRequest, page: number, size: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, pesquisaDaeRequest)
      .pipe(map(response => {
        return response;
      }));
  }
}