import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";



@Component({
  selector: "app-dialog-confirmar-certificado",
  templateUrl: "./dialog-confirmar.component.html",
  styleUrls: ["./dialog-confirmar.component.css"]
})
export class DialogConfirmarComponent {

  message: string;

  constructor(public dialogRef: MatDialogRef<DialogConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.message = data;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
