import { SelectionModel } from '@angular/cdk/collections';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/global/api/shared.service';
import { MessageService } from 'src/app/global/message/message.service';
import { DocumentoService } from '../../api/documento.service';
import { InstituidorService } from '../../api/instituidor.service';
import { RequerenteService } from '../../api/requerente.service';
import { SolicitanteService } from '../../api/solicitante.service';
import { Assunto } from '../../models/assunto.model';
import { Documento } from '../../models/documento.model';
import { Instituidor } from '../../models/pensao/instituidor.model';
import { Requerente } from '../../models/pensao/requerente.model';
import { Solicitante } from '../../models/pensao/solicitante.model';
import { Processo } from '../../models/processo.model';
import { DialogConfirmarComponent } from '../../processo/dialog-confirmar/dialog-confirmar.component';
import { UploadDocumentoEnvolvidosComponent } from '../upload-documento-envolvidos/upload-documento-envolvidos.component';
import { ProcessoService } from '../../api/processo.service';

@Component({
  selector: 'app-visualizar-processo-pensao',
  templateUrl: './visualizar-processo-pensao.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./visualizar-processo-pensao.component.css']
})
export class VisualizarProcessoPensaoComponent implements OnInit, OnChanges {
  
  @Input() processo: Processo;
  @Input() assunto: Assunto;
  private idProcesso: number;
  public solicitante: Solicitante = new Solicitante;
  public instituidor: Instituidor = new Instituidor;
  public requerentes: Requerente[] = [];
  @ViewChild("uploadDocumentoSolicitanteComponent") uploadDocumentoSolicitanteComponent: UploadDocumentoEnvolvidosComponent;
  @ViewChild("uploadDocumentoInstituidorComponent") uploadDocumentoInstituidorComponent: UploadDocumentoEnvolvidosComponent;
  public isPossuiPermissaoAnalistaCearaprev: boolean;
  public disabledValueS = true;
  public disabledValueEditarS = false;
  public disabledValueI = true;
  public disabledValueEditarI = false;
  public disabledValueR = true;
  public disabledValueEditarR = false;
  public podeMovimentar: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    private messageService: MessageService,
    private changeDetector : ChangeDetectorRef,
    private solicitanteService: SolicitanteService,
    private instituidorService: InstituidorService,
    private requerenteService: RequerenteService,
    private documentoService: DocumentoService,
    public sharedService: SharedService,
    private processoService: ProcessoService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.idProcesso = this.route.snapshot.params.idProcesso;
    
    if (this.idProcesso === undefined || this.idProcesso === null) {
      this.idProcesso = this.processo.id;
      this.carregarSolicitante(this.idProcesso);
      this.carregarInstituidor(this.idProcesso);
      this.carregarRequerentes(this.idProcesso);
    }

    this.isPossuiPermissaoAnalistaCearaprev = this.sharedService.verificaRolePessoaLogada(
      "Analista Cearaprev" // Unica permissão para analistas cearaprev
    );

    this.verificarPermissaoMovimentarProcesso(this.idProcesso);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  verificarPermissaoMovimentarProcesso(id: number) {
    this.processoService.verificarProcessoPertenceLotacaoUsuario(id, this.sharedService.decodePayloadJWT().sub).subscribe(
      (response) => {
        if (response == null) {
          this.messageService.messageInfo("Não foi possível consultar motor de regras");
          return;
        }
        this.podeMovimentar = response;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
  
  editarS() {
    this.disabledValueS = false; 
    this.disabledValueEditarS = true;
  }

  editarI() {
    this.disabledValueI = false; 
    this.disabledValueEditarI = true;
  }

  editarR() {
    this.disabledValueR = false; 
    this.disabledValueEditarR = true;
  }

  cancelarS() {
    const message = "Deseja cancelar as alterações do solicitante?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.disabledValueS = true; 
        this.disabledValueEditarS = false;
        this.carregarSolicitante(this.idProcesso);
      } else {
        this.disabledValueS = false; 
      }
    });
    return false;
  }

  cancelarI() {
    const message = "Deseja cancelar as alterações do instituidor?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.disabledValueI = true;
        this.disabledValueEditarI = false;
        this.carregarInstituidor(this.idProcesso);
      } else {
        this.disabledValueI = false; 
      }
    });
    return false;
  }

  cancelarR() {
    const message = "Deseja cancelar as alterações dos requerentes?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        this.disabledValueR = true;
        this.disabledValueEditarR = false;
        this.carregarRequerentes(this.idProcesso);
      } else {
        this.disabledValueR = false; 
      }
    });
    return false;
  }

  private carregarSolicitante(idProcesso: number) {
    this.solicitanteService.pesquisarPorProcesso(idProcesso).subscribe((solicitante : Solicitante) => {
      if(solicitante != null) {
        this.solicitante = solicitante;
        this.documentoService.pesquisarDocumentosSolicitante(solicitante.id).subscribe((docs : Documento[]) => {
          if (docs != null && docs.length > 0) {
            this.solicitante.documentos = docs;
          }
        }, error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        });
        
      } else {
        
      }

    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }
  
  private carregarInstituidor(idProcesso: number) {
    this.instituidorService.pesquisarPorProcesso(idProcesso).subscribe((instituidor : Instituidor) => {
            
      if(instituidor != null) {
        this.instituidor = instituidor;
        this.documentoService.pesquisarDocumentosInstituidor(instituidor.id).subscribe((docs : Documento[]) => {
          if (docs != null && docs.length > 0) {
            this.instituidor.documentos = docs;
          }

        }, error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        });
        
      } else {
        
      }

    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }
  
  private carregarRequerentes(idProcesso: number) {
    this.requerenteService.pesquisarPorProcesso(idProcesso).subscribe((requerentes : Requerente[]) => {
      
      if(requerentes != null && requerentes.length > 0) {
        requerentes.forEach(
          requerente => {
            this.documentoService.pesquisarDocumentosRequerente(requerente.id).subscribe((docs : Documento[]) => {
              if (docs != null && docs.length > 0)
                requerente.documentos = docs;
            }, error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            });
        });
        this.requerentes = requerentes;
      } else {
        
      }
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  atualizarDataTableDocumentosReceiverEventEmitter($event) {
    if ($event.atualizar) {
      switch ($event.envolvido) {
        case "SOLICITANTE":
          this.carregarSolicitante(this.idProcesso);
          break;
        case "INSTITUIDOR":
          this.carregarInstituidor(this.idProcesso);
          break;
        case "REQUERENTE":
          this.carregarRequerentes(this.idProcesso);
          break;
      }
    }
  }

  salvarS() {
    this.solicitanteService.salvar(this.solicitante).subscribe(
      (solicitanteSalvo: Solicitante) => {
        this.solicitante.id = solicitanteSalvo.id;
        this.disabledValueS = true; 
        this.disabledValueEditarS = false;
        this.carregarSolicitante(this.idProcesso);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  salvarI() {
    this.instituidorService.salvar(this.instituidor).subscribe(
      (instituidorSalvo: Instituidor) => {
        this.instituidor.id = instituidorSalvo.id;
        this.disabledValueI = true; 
        this.disabledValueEditarI = false;
        this.carregarInstituidor(this.idProcesso);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  salvarR() {
    this.requerentes.forEach((requerente) => {
      this.requerenteService.salvar(requerente).subscribe(
        (requerenteSalvo: Requerente) => {
          requerente.id = requerenteSalvo.id;
          this.disabledValueR = true; 
          this.disabledValueEditarR = false;
          this.carregarRequerentes(this.idProcesso);
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    })
  }

  

  substituirDocummentoEditorEventReceiver($event) {
    let documento = $event.documentoSubstituir;
    if ($event.tipoEnvolvido === 'SOLICITANTE'){
      this.uploadDocumentoSolicitanteComponent.documentoSubstituir = documento;
      this.uploadDocumentoSolicitanteComponent.natureza = 1;
      this.uploadDocumentoSolicitanteComponent.exibicaoEditor == true;
      this.uploadDocumentoSolicitanteComponent.novoDocumento();
      this.uploadDocumentoSolicitanteComponent.adicionarDocumentoEditor();      
      this.uploadDocumentoSolicitanteComponent.documentoNovo.tipoDocumento = documento.tipoDocumento;
      this.uploadDocumentoSolicitanteComponent.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
      this.uploadDocumentoSolicitanteComponent.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
      this.uploadDocumentoSolicitanteComponent.documentoNovo.descricao = documento.descricao;
    }
    if ($event.tipoEnvolvido === 'INSTITUIDOR'){
      this.uploadDocumentoInstituidorComponent.documentoSubstituir = documento;
      this.uploadDocumentoInstituidorComponent.natureza = 1;
      this.uploadDocumentoInstituidorComponent.exibicaoEditor == true;
      this.uploadDocumentoInstituidorComponent.adicionarDocumentoEditor();      
      this.uploadDocumentoInstituidorComponent.documentoNovo.tipoDocumento = documento.tipoDocumento;
      this.uploadDocumentoInstituidorComponent.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
      this.uploadDocumentoInstituidorComponent.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
      this.uploadDocumentoInstituidorComponent.documentoNovo.descricao = documento.descricao;
    }
  }
}