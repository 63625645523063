import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { DocumentoService } from "../../api/documento.service";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { MatTableDataSource, MatDialog, MatSort } from "@angular/material";
import { ProcessoTabelaDto } from "../../models/processo-tabela-dto.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ProcessoService } from "../../api/processo.service";
import { DialogConfirmarComponent } from "../dialog-confirmar/dialog-confirmar.component";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-tabela-processo",
  templateUrl: "./tabela-processo.component.html",
  styleUrls: ["./tabela-processo.component.css"],
})
export class TabelaProcessoComponent {
  @Input() dataSourceProcesso: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild(MatSort) matSort: MatSort;

  @Input() displayedProcessosColumns: string[];
  @Input() style: string = "tabela-processo";

  @Input() highlighteProcessoRows: any[] = [];

  @Output() selectedProcesso = new EventEmitter<number>();
  @Output() selectionProcesso = new EventEmitter<ProcessoTabelaDto[]>();
  @Input() processosSelecionados = new SelectionModel<ProcessoTabelaDto>(true, []);

  public resourceConsultarProcesso = environment.CONTEXTO + "/consultar-processo";
  @Output() sort = new EventEmitter<MatSort>();

  constructor(
    private documentoService: DocumentoService,
    private messageService: MessageService,
    private processoService: ProcessoService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  sortData(sort: MatSort) {
    this.sort.emit(sort);
  }

  atualizarSelecionado(row: number) {
    this.selectedProcesso.emit(row);
  }

  atualizarListaSelecionados() {
    this.selectionProcesso.emit(this.processosSelecionados.selected);
  }

  downloadDocumentoProcesso($event, idProcesso: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.documentoService.downloadDocumentoProcesso(idProcesso).subscribe(
      (blob) => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Não existe documento no processo.");
        }
      },
      (error) => {
        const erro = Utils.converterArrayBufferParaJSON(error);
        this.messageService.messageErro(erro.error.message);
      }
    );
  }

  setObservarProcesso(visibilidade: boolean, processo: ProcessoTabelaDto) {
    if (visibilidade) {
      // tslint:disable-next-line: max-line-length
      const message =
        "Você começou a acompanhar esse processo (" + processo.etiquetaProcessoViproc + "). Deseja receber e-mails das movimentações?";
      const dialogRef = this.dialog.open(DialogConfirmarComponent, {
        maxWidth: "400px",
        data: message,
      });
      dialogRef.afterClosed().subscribe((dialogResult) => {
        this.processoService.observarProcesso(processo.id, dialogResult, visibilidade).subscribe(
          (res) => {
            this.dataSourceProcesso.data = this.dataSourceProcesso.data.map((proc) => {
              if (proc.id === res.id) {
                proc.observando = res.observando;
              }
              return proc;
            });
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      });
    } else {
      this.processoService.observarProcesso(processo.id, false, visibilidade).subscribe(
        (res) => {
          this.dataSourceProcesso.data = this.dataSourceProcesso.data.map((proc) => {
            if (proc.id === res.id) {
              proc.observando = res.observando;
            }
            return proc;
          });
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  isAllSelectedProcesso() {
    if (this.dataSourceProcesso != null || this.dataSourceProcesso !== undefined) {
      const numSelected = this.processosSelecionados.selected.length;
      const numRows = this.dataSourceProcesso.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  masterToggleProcesso() {
    if (this.dataSourceProcesso != null) {
      this.isAllSelectedProcesso()
        ? this.processosSelecionados.clear()
        : this.dataSourceProcesso.data.forEach((row) => this.processosSelecionados.select(row));
    }
    this.atualizarListaSelecionados();
  }

  checkboxLabelProcesso(row?: ProcessoTabelaDto): string {
    if (!row) {
      return `${this.isAllSelectedProcesso() ? "select" : "deselect"} all`;
    }
    return `${this.processosSelecionados.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  getClasse(processo: ProcessoTabelaDto) {
    if (!processo.viprocValidado) return "text";
  }
}
