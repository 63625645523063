export enum NivelSigilo {
  PÚBLICO_EXTERNO = ("PÚBLICO EXTERNO: Visualização de todo o órgão e o publico externo de documentos e processos."),
  PÚBLICO_INTERNO = ("PÚBLICO INTERNO: Visualização apenas do órgão para documentos e processos. Usuários externos não tem acesso."),
  SIGILOSO = ("SIGILOSO: Visualização apenas de quem está de posse do processo e o para o autor e favorecidos dos documentos e processos."),
  RESERVADO = ("RESERVADO: Visualização apenas de quem está de posse do processo e para o autor. Favorecido não tem acesso.")
}

export const NivelSigiloEnumAll = [
  { id: 0, descricao: NivelSigilo.PÚBLICO_EXTERNO },
  { id: 1, descricao: NivelSigilo.PÚBLICO_INTERNO },
  { id: 2, descricao: NivelSigilo.SIGILOSO },
  { id: 3, descricao: NivelSigilo.RESERVADO }
];
