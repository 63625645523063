import { Component, Inject, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-dialog-processo",
  templateUrl: "./dialog-processo.component.html",
  styleUrls: ["./dialog-processo.component.css"]
})
export class DialogProcessoComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private ngZone: NgZone) {
    }

  close(): void {
    this.dialogRef.close();
    this.ngZone.run(() => this.router.navigate(["home"])).then();
  }
}