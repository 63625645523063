import { Domain } from "../models/domain.model";
import { OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../core";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { MessageService } from "../message/message.service";

/**
 * Generic responsável pelas operações de salvar e editar da tela
 *
 * @author Carlos Bezerra
 * @version 1.0
 * @since 29/04/2019
 */
export class SalvarGenericsComponent<T extends Domain> implements OnInit {

  public form: FormGroup;
  domain: any = new Domain;
  public contexto = environment.CONTEXTO;

  constructor(
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected apiService: ApiService,
    protected messageService: MessageService,
    protected route: ActivatedRoute,
    protected endpoint: string,
    protected validators: any
  ) {
    this.form = this.formBuilder.group(validators);
    const id = this.route.snapshot.params.id;
    if (id) {
      this.get(id);
    }
  }

  ngOnInit() { }

  afterResponse() { }

  afterSubmit() { }

  public get f() {
    return this.form.controls;
  }

  public submeter(): void {
    if (this.form.invalid) {
      return;
    }
    if (this.domain.id) {
      this.editar(this.domain);
    } else {
      this.salvar(this.domain);
    }
  }

  public get(id: number): void {
    this.apiService.get(id).subscribe(res => {
      this.domain = res;
      this.afterResponse();
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

  public editar(domain: Domain): void {
    this.apiService.put(domain).subscribe(() => {
      this.messageService.messageSuccess("Registro editado com sucesso.");
      this.afterSubmit();
      this.router.navigate([`${this.contexto}/${this.endpoint}`]);
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

  public salvar(domain: Domain): void {
    this.apiService.post(domain).subscribe(res => {
      if (res.id) {
        this.messageService.messageSuccess("Registro salvo com sucesso.");
        domain.id = res.id;
        this.afterSubmit();
        this.router.navigate([`${this.contexto}/${this.endpoint}`]);
      }
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

}