import { Domain } from "../models/domain.model";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { ViewChild, OnInit } from "@angular/core";
import { ApiService } from "../../core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SharedService } from "../api/shared.service";
import { MessageService } from "../message/message.service";

/**
 * Generic responsável pela tela de listagem (tabela)
 *
 * @author Carlos Bezerra
 * @version 1.0
 * @since 29/04/2019
 */
export default class ListarGenericsComponent<T extends Domain> implements OnInit {

  public dataSource: MatTableDataSource<T>;
  public contexto = environment.CONTEXTO;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public PAGE_INITIAL = SharedService.PAGE_INDEX;

  constructor(protected apiService: ApiService,
    protected messageService: MessageService,
    protected sharedService: SharedService,
    protected toastr: ToastrService,
    protected router: Router,
    protected endpoint: string,
    public displayedColumns: string[]) { }

  ngOnInit(): void {
    this.listar();
    this.getPageState();
  }

  afterResponseListar() { }

  public listar() {
    this.apiService.list().subscribe(
      res => {
        this.dataSource = new MatTableDataSource<T>(res);
        this.dataSource.paginator = this.paginator;
        this.afterResponseListar();
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public remove(domain: T): void {
    this.apiService.delete(domain.id).subscribe(
      () => {
        const data = this.dataSource.data;
        data.splice(data.indexOf(domain), 1);
        this.dataSource = new MatTableDataSource<T>(data);
        this.toastr.success("Registro removido com sucesso.");
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public edit(domain: T): void {
    this.setPageState();
    this.router.navigate([`${this.contexto}/${this.endpoint}/editar/${domain.id}`]);
  }

  setPageState() {
    this.sharedService.setPageIndex(this.paginator.pageIndex);
    this.sharedService.setPageSize(this.paginator.pageSize);
  }

  getPageState() {
    if ((this.sharedService.getPageSize() !== null
      && this.sharedService.getPageSize() !== SharedService.PAGE_SIZE)) {
      this.PAGE_SIZE = this.sharedService.getPageSize();
      this.sharedService.setPageSize(SharedService.PAGE_SIZE);
    }

    if (this.sharedService.getPageIndex() !== SharedService.PAGE_INDEX) {
      this.PAGE_INITIAL = this.sharedService.getPageIndex();
      this.sharedService.setPageIndex(SharedService.PAGE_INDEX);
    }
  }

}