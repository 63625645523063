import { ApiService } from "./api.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Processo } from "../models/processo.model";
import { ConsultaPublicaDto } from "../models/documento/consulta-publica-dto.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class ConsultaPublicaService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "consulta-publica", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }

  // Criação do arquivo e 'start' da assinatura
  gerarDocumentoConsultaPublica(processo: Processo, dataInicial: string, dataFinal: string, certContent: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${this.API_URL}${this.resource}/gerar-documento/${processo.id}/${dataInicial}/${dataFinal}`,
      certContent)
      .pipe(map(response => response));
  }

  // 'Complete' da assinatura e publicação do documento
  publicarDocumento(consultaPublicaDto: ConsultaPublicaDto): Observable<any> {

    return this.http
      .post(`${this.API_URL}${this.resource}/publicar-documento`, consultaPublicaDto)
      .pipe(
        map(response => response)
      );
  }

  public verificaConsultaPublica(processo: Processo): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/verificar-se-possui-publicacao/${processo.id}`).pipe(map(response => response));
   }

}
