export enum StatusDAE {
  UTILIZADO = ("Utilizado"),
  LIBERADO = ("Liberado")
}

export const StatusDaeEnumAll = [
  { id: 0, descricao: StatusDAE.UTILIZADO },
  { id: 1, descricao: StatusDAE.LIBERADO }
];

export namespace StatusDAEFunctions {
  export function getIndice(status: StatusDAE): number {
    return StatusDaeEnumAll.filter(function (sts) {
      return sts.descricao === status;
    })[0].id;
  }
}
