import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { MaterialModule } from "src/app/material";
import { MetadadoComponent } from "./metadado.component";
import { InputComponent } from "../input/input.component";
import { NgxMaskModule } from "ngx-mask";
import { InputModule } from "../input/input.module";
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  imports: [ CommonModule, MaterialModule, ReactiveFormsModule, FormsModule,  NgxMaskModule.forRoot(), InputModule, CurrencyMaskModule ],
  declarations: [ MetadadoComponent ],
  exports: [MetadadoComponent, InputModule],
})
export class MetadadoModule { }
