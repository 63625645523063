import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "../../message/message.service";
import { ContribuinteService } from "src/app/core/api/contribuinte.service";
import { DialogContribuinteSigetComponent } from "../dialog-procurador-siget/dialog-contribuinte-siget.component";
import { MatDialog } from "@angular/material";
import { SharedService } from "../../api/shared.service";
import { TelaEnumAll, Tela } from "src/app/core/models/tela.enum";
import { TipoExibicaooEnumAll, TipoExibicao } from "src/app/core/models/tipo-exibicao.enum";
import { DialogAvisoComponent } from "src/app/core/processo/dialog-aviso/dialog-aviso.component";
import { AvisoService } from "src/app/core/api/aviso.service";
import { DialogSelecaoFilialComponent } from "../dialog-selecao-filial/dialog-selecao-filial.component";
import { Contribuinte } from "src/app/core/models/contribuinte.model";


@Component({
  selector: "app-entrada-contribuinte",
  templateUrl: "./entrada-contribuinte.component.html",
  styleUrls: ["./entrada-contribuinte.component.css"]
})
export class EntradaContribuinteComponent implements OnInit {

  opcao: string;
  contribuinte: any;

  constructor(private router: Router,
    private messageService: MessageService,
    private contribuinteSevice: ContribuinteService,
    private sharedService: SharedService,
    private avisoService: AvisoService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.checkAvisos();
  }

  private checkAvisos() {
    const tela = TelaEnumAll.filter(tela => tela.descricao === Tela.LOGIN).map(tela => tela.id)[0];
    const tipoExibicao = TipoExibicaooEnumAll.filter(te => te.descricao === TipoExibicao.PUBLICO_EXTERNO).map(te => te.id)[0];

    this.avisoService.listarAvisoAtualPorTelaTipoExibicao(tela, tipoExibicao).subscribe(
      (res) => {
        if (res.length > 0) {
          const dialogRef = this.dialog.open(DialogAvisoComponent, {
            maxWidth: "600px",
            data: res
          });
        }
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public entrar() {

    switch (this.opcao) {
      case "1":
        this.contribuinteSevice
          .loginContribuinte(this.opcao, JSON.parse(this.sharedService.getDocumentoRepresentante()))
          .subscribe(
            (contribuintes: Contribuinte[]) => {
              this.contribuinte = contribuintes;

              if (this.contribuinte) {
                if (contribuintes.length > 1 || (contribuintes.length === 1 && contribuintes[0].buscaCNPJBase)) {
                  if ( !this.verificarMatrizAtiva(contribuintes) ) {
                    contribuintes = [this.criarContribuinteMatrizInativa(contribuintes[0].nome), ...contribuintes];
                  }

                  if (contribuintes.length === 1) {
                    this.redirecionarParaHome(contribuintes[0]);
                  } else {
                    this.dialog.open( DialogSelecaoFilialComponent, {
                      width: "400px",
                      data: { opcao: this.opcao, contribuinte: contribuintes }
                    }).afterClosed().subscribe((contribuinteSelecionado: Contribuinte) => {
                      if (contribuinteSelecionado) {
                        if (contribuinteSelecionado.matrizInativa) {
                          this.contribuinteSevice.findByDocumento(contribuinteSelecionado.documento)
                            .subscribe(
                              contribuinteByDocumento => {
                                if ( contribuinteByDocumento ) {
                                  this.redirecionarParaHome(contribuinteByDocumento);
                                } else {
                                  this.router.navigate(
                                    ["contribuinte"],
                                    { state: { data: { opcao: this.opcao, contribuinte: contribuinteSelecionado } } 
                                  });
                                }
                            }
                          );
                        } else {
                          this.redirecionarParaHome(contribuinteSelecionado);
                        }
                      }
                    });
                  }
                } else {
                  this.router.navigate(["home"]);
                  this.sharedService.setDocumentoRepresentado(JSON.parse(this.sharedService.getDocumentoRepresentante()));
                  this.sharedService.setDocumentoRepresentante(null);
                }

              } else {
                this.router.navigate(["contribuinte"], { state: { data: { opcao: this.opcao } } });
              }
            }, error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        break;
      case "2":
        this.contribuinteSevice.loginContribuinte(this.opcao, JSON.parse(this.sharedService.getDocumentoRepresentante())).
          subscribe(
            (contribuinte) => {
              if (contribuinte.length > 0) {
                this.dialog.open(DialogContribuinteSigetComponent, {
                  width: "400px",
                  data: contribuinte
                });
              } else {
                this.messageService.messageInfo("Nenhum contribuinte encontrado.");
              }
            },
            error => {
              this.messageService.messageInfo(error.error.localizedMessage);
            });
        break;
      case "3":
        this.router.navigate(["contribuinte"], { state: { data: { opcao: this.opcao } } });
        break;
      default:
        this.messageService.messageErro("Selecione uma opção!");
    }
  }

  verificarMatrizAtiva(contribuintes: Contribuinte[]) {
    const cnpjCertificado = this.sharedService.getDocumentoRepresentante();
    let matrizAtiva = false;
    contribuintes.forEach(contribuinte => {
      if (cnpjCertificado === JSON.stringify(contribuinte.documento)) {
        matrizAtiva = true;
      }
    });

    return matrizAtiva;
  }

  criarContribuinteMatrizInativa(nome: string) {
    const contribuinteMatrizInativa = new Contribuinte;
    contribuinteMatrizInativa.documento = JSON.parse(this.sharedService.getDocumentoRepresentante());
    contribuinteMatrizInativa.matrizInativa = true;
    contribuinteMatrizInativa.nome = nome;

    return contribuinteMatrizInativa;
  }

  redirecionarParaHome(contribuinteSelecionado) {
    this.contribuinte = contribuinteSelecionado;
    this.sharedService.setDocumentoRepresentado(contribuinteSelecionado.documento);
    this.sharedService.setDocumentoRepresentante(null);
    this.router.navigate(["home"]);
  }
}
