import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from '../message/message.service';



@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private messageService: MessageService
  ) {}

  handleError(error: Error | HttpErrorResponse) {    

    const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
       
      if (!navigator.onLine) {       
        this.messageService.messageErro('Sem Conexão com a Internet');
      }

      if (error.status == 0) {
        this.messageService.messageErroServiceOffline(`${error.message}`);
      }      
    }
    console.error(error);
  }
}
