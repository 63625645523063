
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Cidadao } from "../models/seguranca/cidadao.model";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { Orgao } from "../models/orgao.model";

@Injectable({ providedIn: "root" })
export class OrgaoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "orgao", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  getOrgaoLocal(): any {
    return this.http.get(`${this.API_URL}${this.resource}`).pipe(map(response => response));
  }

  getOrgaoLocalById(id: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/${id}`).pipe(map(response => response));
  }

  getOrgaoLocalArvore(processoId: number, perfil: string): any {
    return this.http.get(`${this.API_URL}${this.resource}/arvore/?processoId=${processoId}&perfil=${perfil}`).pipe(map(response => response));
  }

  getOrgaoUsuario(documentoUsuario: string): any {    
    return this.http.get(`${this.API_URL}${this.resource}/usuario/${documentoUsuario}`).pipe(map(response => response));
  }

  atualizarOrgaosUsuario(documentoUsuario: string, orgaos: Orgao[]): any {
    return this.http.put(`${this.API_URL}${this.resource}/usuario/${documentoUsuario}`, orgaos).pipe(map(response => response));
  }

}