import { Injectable, EventEmitter } from "@angular/core";
import { Message } from "./message";
import { Level, LevelEnumAll } from "src/app/core/models/level.enum";

@Injectable({
  providedIn: "root"
})
export class MessageService {

  event: EventEmitter<Message> = new EventEmitter();
  constructor() { }

  messageErro(mensagem: string, titulo: string = "Erro!") {
    if (mensagem != '' && mensagem != undefined) {
      this.messageEmit("alert-danger", "icon fa fa-ban", titulo, mensagem);
    }
  }

  messageErroServiceOffline(mensagem: string, titulo: string = "Serviço Offline") {
    this.messageEmit("alert-danger", "icon fa fa-ban", titulo, mensagem);
  }

  messageWarning(mensagem: string, titulo: string = "Atenção!") {
    this.messageEmit("alert-warning", "icon fa fa-warning", titulo, mensagem);
  }

  messageSuccess(mensagem: string, titulo: string = "Ok!") {
    this.messageEmit("alert-success", "icon fa fa-check", titulo, mensagem);
  }

  messageInfo(mensagem: string, titulo: string = "Informação!") {
    this.messageEmit("alert-info", "icon fa fa-info", titulo, mensagem);
  }

  messageEmit(tipo: string, icon: string, titulo: string, mensagem: string) {
    this.event.emit({tipo, icon, titulo: titulo, mensagem: mensagem});
  }

  message(msgLevel:any, mensagem: string) {
    const logLevel = LevelEnumAll.filter(function (level) {
      return level.nome == msgLevel;
    })[0];
    this.event.emit({tipo: logLevel.descricao, icon: logLevel.icon, titulo: logLevel.titulo, mensagem: mensagem});
  }

}
