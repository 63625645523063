import { Domain } from "../../global/models/domain.model";
import { Metadado } from "../../global/models/metadado.model";
import { TipoDocumentoMetadado } from "./tipo-documento-metadado.model";

export class TipoDocumento extends Domain {
  public nomeDocumento: string;
  public qtdeMinimaAssinatura: number;
  public qtdeMaximaAssinatura: number;
  public qtdeExataAssinatura: number;
  public somenteAnexo: boolean;
  public visibilidadeExterna: boolean;
  public visibilidadeLotacao: boolean;
  public visibilidadeTodasAsLotacoes: boolean = true;
  public ativo: boolean;
  public geraNumeroDocumento: boolean;
  public modeloDocumento: string;
  public extencoesPermitidas: string;
  public listMetadado: TipoDocumentoMetadado[];
  // Tipo de numeracao de documento esta fixo para Anual
  public tipoNumeracaoDocumento: number = 0;
  public lotacao: number;
  public lotacoes: number[];
  public requerimento: boolean;
  public idTipoDocumentoMotorRegras: string;
  public geradoSistema: boolean = false;
  public envolvidoPensao: boolean = false;
  public ordemPrioridade : number;
  public observacao: string;
}