import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, throwMatDialogContentAlreadyAttachedError } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { LotacaoSecundariaService } from "src/app/core/api/lotacao-secundaria.service";
import { MetadadoService } from "src/app/core/api/metadado.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { ProcessoService } from "src/app/core/api/processo.service";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { OrgaoLocal } from "src/app/core/models/orgao-local.model";
import { TipoDocumentoMetadado } from "src/app/core/models/tipo-documento-metadado.model";
import { TipoDocumentoSistema, TipoDocumentoSistemaEnumAll } from "src/app/core/models/tipo-documento-sistema.enum";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";
import { DialogDesativiarTipoDocumento } from "src/app/core/processo/dialog-desativar-tipo-documento/dialog-desativar-tipo-documento.component";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { SharedService } from "src/app/global/api/shared.service";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { Metadado } from "src/app/global/models/metadado.model";
import { Utils } from "src/app/global/util/utils-component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-salvar-tipo-documento",
  templateUrl: "./salvar-tipo-documento.component.html",
  styleUrls: ["./salvar-tipo-documento.component.css"],
})
export class SalvarTipoDocumentoComponent extends SalvarGenericsComponent<TipoDocumento> implements OnInit {
  @ViewChild("myckeditor") ckeditor: any;

  public ckeConfig: any;
  public assuntoList: any[] = [];
  public todosMetadados = [];
  public logicaMetadado = false;
  public metadadosSelecionados = [];
  public tipoDocumentoList: any[] = [];
  public lotacaoSelecionada: number;
  public resourceTipoDocumento = environment.CONTEXTO + "/tipo-documento";
  public documentoAstra: string;
  public listaTipoDocumentoSistema: any[] = TipoDocumentoSistemaEnumAll;

  public nivelSigiloList = [
    // TODO :: Formatar para enum para ficar conforme AcaoMudancaSituacao.
    { id: 0, descricao: "PÚBLICO EXTERNO: Visualização de todo o órgão e o publico externo de documentos e processos." },
    { id: 1, descricao: "PÚBLICO INTERNO: Visualização apenas do órgão para documentos e processos. Usuários externos não tem acesso." },
    // tslint:disable-next-line: max-line-length
    {
      id: 2,
      descricao:
        "SIGILOSO: Visualização apenas de quem está de posse do processo e o para o autor e favorecidos dos documentos e processos.",
    },
    { id: 3, descricao: "RESERVADO: Visualização apenas de quem está de posse do processo e para o autor. Favorecido não tem acesso." },
  ];
  public tipoNumeracaoDocumentoList = [
    // TODO :: Formatar para enum para ficar conforme AcaoMudancaSituacao.
    { id: 0, tipo: "Renovação Anual", descricao: "Inicia uma nova sequência de numeração a cada ano." },
    { id: 1, tipo: "Sequência Única", descricao: "Sequência da numeração não é renovada a cada ano." },
  ];
  public lotacoes: OrgaoLocal[] = [];
  public isPermicaoOutrasLotacoes: boolean;

  public lotacoesDocumento: OrgaoLocal[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public dialog: MatDialog,
    public apiService: TipoDocumentoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public assuntoService: AssuntoService,
    public processoService: ProcessoService,
    public metadadoService: MetadadoService,
    public orgaoService: OrgaoService,
    public tipoDocumentoService: TipoDocumentoService,
    public lotacaoSecundariaService: LotacaoSecundariaService,
    public sharedService: SharedService
  ) {
    super(formBuilder, router, apiService, messageService, route, "tipo-documento", "");
    this.logicaMetadado = false;
    this.domain.visibilidadeLotacao = false; // Padrão
    this.domain.tipoNumeracaoDocumento = 0; // Padrão
    this.isPermicaoOutrasLotacoes = true;
    // Permissão padrão do token cearáprev.
    // this.isPermicaoOutrasLotacoes = this.sharedService.verificaRolePessoaLogada("tipo-documento.salvar-tipodocumento", "pp");
    //this.domain.visibilidadeTodasAsLotacoes = true; // Mock
  }

  ngOnInit() {
    this.logicaMetadado = false;
    this.documentoAstra = JSON.parse(this.sharedService.getDocumentoAstra());
    Utils.ordernarDescricaoOrdemAlfabetica(this.nivelSigiloList);

    this.assuntoService.list().subscribe(
      (assunto) => {
        this.assuntoList = assunto;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.metadadoService.sistema().subscribe((metadados) => {
      this.todosMetadados = metadados;
      if (!this.logicaMetadado) {
        this.logicaMetadado = true;
      } else {
        this.logicaMetadados();
      }
    });

    this.tipoDocumentoService.getTipoDocumentoSomenteAnexo(false).subscribe(
      (tipoDucmento) => {
        this.tipoDocumentoList = tipoDucmento.filter((tipoDoc) => tipoDoc.modeloDocumento != null);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    if (this.isPermicaoOutrasLotacoes) {
      this.orgaoService.getOrgaoLocal().subscribe(
        (orgao) => {
          this.lotacoes = orgao;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    } else {
      this.lotacaoSecundariaService.consultaTodasLotacoesPorDocumento(this.documentoAstra).subscribe(
        (res) => {
          if (res && res.length > 0) {
            this.lotacoes = res[0].listLotacao
              .sort((a, b) => a.orgaoLocal.descricao.localeCompare(b.orgaoLocal.descricao))
              .map((lot) => lot.orgaoLocal);
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }

    this.confiCkEditor();
  }

  // Executar após receber lista de metadados e o assunto.
  logicaMetadados() {
    this.domain.listMetadado.forEach((docMetadado) => {
      const metadado = this.todosMetadados.filter((met) => met.id === docMetadado.metadado.id);
      if (metadado.length > 0) {
        this.todosMetadados.splice(this.todosMetadados.indexOf(metadado[0]), 1);
        docMetadado.metadado.requerido = docMetadado.requerido;
        this.metadadosSelecionados[docMetadado.ordem - 1] = docMetadado.metadado;
      }
    });
  }

  afterResponse() {
    if (this.domain && !this.domain.somenteAnexo && this.domain.visibilidadeLotacao) {
      this.apiService.verificarPermissaoEditarTipoDocumentoPorLotacao().subscribe(
        (lotacao) => {},
        (error) => {
          this.messageService.messageErro(error.error.message);
          this.router.navigate([`${this.resourceTipoDocumento}`], {});
        }
      );
    }
    if (!this.logicaMetadado) {
      this.logicaMetadado = true;
    } else {
      this.logicaMetadados();
    }
    if (this.domain.lotacao) {
      this.lotacaoSelecionada = this.domain.lotacao;
    }

    if (this.domain.id) {
      this.tipoDocumentoService.listarLotacoesTipoDocumento(this.domain.id).subscribe(
        (lotacoes) => {
          this.domain.lotacoes = lotacoes;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          this.router.navigate([`${this.resourceTipoDocumento}`], {});
        }
      );
    }
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  hasChild = (_: number, node: any) => !!node.filhos && node.filhos.length > 0;

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  compareObj(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1 === obj2 : obj1 === obj2;
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.nomeDocumento || !domain.nomeDocumento.trim()) {
      this.messageService.messageErro("Preencha o campo Nome do Documento.");
      validacao = false;
    }
    if (!domain.observacao || !domain.observacao.trim()) {
      this.messageService.messageErro("Preencha o campo Informação adicional.");
      validacao = false;
    }
    if (
      (domain.qtdeMinimaAssinatura == null && domain.qtdeMaximaAssinatura != null) ||
      (domain.qtdeMinimaAssinatura == null && domain.qtdeExataAssinatura == null)
    ) {
      this.messageService.messageErro("Preencha o campo Qtd Mínima de Assinaturas.");
      validacao = false;
    }
    if (
      (domain.qtdeMaximaAssinatura == null && domain.qtdeMinimaAssinatura != null) ||
      (domain.qtdeMaximaAssinatura == null && domain.qtdeExataAssinatura == null)
    ) {
      this.messageService.messageErro("Preencha o campo Qtd. Máxima de Assinaturas.");
      validacao = false;
    }
    if (domain.qtdeExataAssinatura == null && domain.qtdeMaximaAssinatura == null && domain.qtdeMinimaAssinatura == null) {
      this.messageService.messageErro("Preencha o campo Qtd. Exata de Assinaturas.");
      validacao = false;
    }
    if (domain.somenteAnexo == null) {
      this.messageService.messageErro("Selecione o campo Somente Anexo.");
      validacao = false;
    }
    if (domain.visibilidadeExterna == null) {
      this.messageService.messageErro("Selecione o campo Visibilidade Externa.");
      validacao = false;
    }
    if (domain.somenteAnexo != null && !domain.somenteAnexo && domain.visibilidadeLotacao == null) {
      this.messageService.messageErro("Selecione o campo Visível somente na sua Lotação.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    if (domain.somenteAnexo != null && !domain.somenteAnexo && domain.geraNumeroDocumento == null) {
      this.messageService.messageErro("Selecione o campo Gera Número de Documento.");
      validacao = false;
    }
    if (domain.somenteAnexo != null && !domain.somenteAnexo && domain.requerimento == null) {
      this.messageService.messageErro("Selecione o campo Requerimento.");
      validacao = false;
    }
    //Verificar tipo de numeracao do documento
    //if (domain.geraNumeroDocumento && (domain.tipoNumeracaoDocumento === null || domain.tipoNumeracaoDocumento === undefined)) {
    //  this.messageService.messageErro("Selecione o campo Tipo Numeração Documento.");
    //  validacao = false;
    //}

    if (domain.somenteAnexo) {
      domain.modeloDocumento = null;
      domain.listMetadado = null;
      domain.requerimento = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      if (domain.qtdeExataAssinatura) {
        this.domain.qtdeMaximaAssinatura = null;
        this.domain.qtdeMinimaAssinatura = null;
      } else {
        this.domain.qtdeExataAssinatura = null;
      }

      let index = 1;
      domain.listMetadado = [];
      this.metadadosSelecionados.forEach((metadado) => {
        const docMetadado = new TipoDocumentoMetadado();
        docMetadado.ordem = index++;
        docMetadado.metadado = metadado;
        docMetadado.requerido = !!metadado.requerido;
        domain.listMetadado.push(docMetadado);
      });

      if (domain.id && domain.ativo == false) {
        this.assuntoService.getAssuntoByTipoDocumento(domain.id).subscribe((res) => {
          if (res) {
            this.dialogDesativar(res, domain);
          } else {
            this.submeter();
          }
        });
      } else {
        if (!this.domain.lotacoes || this.domain.lotacoes.length === 0) {
          this.domain.visibilidadeLotacao = false;
          this.domain.lotacoes = [];
        } else {
          this.domain.visibilidadeLotacao = true;
        }

        if (this.domain.id) {
          this.editar(this.domain);
        } else {
          this.salvar(this.domain);
        }
      }
    }
  }

  retornarParaLista(sim: boolean) {
    if (sim) {
      this.messageService.messageSuccess("Registro salvo com sucesso.");
      this.router.navigate([`${this.contexto}/${this.endpoint}`]);
    }
  }

  onChangeSlideToggle(metadado: Metadado) {
    metadado.requerido = !metadado.requerido;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  alterarTipo() {
    if (this.domain.somenteAnexo) {
      this.domain.visibilidadeLotacao = false;
      this.domain.geraNumeroDocumento = false;
      this.domain.lotacao = "";
    }
  }

  importarModeloDocumento(modelo: string) {
    this.domain.modeloDocumento = modelo;
  }

  compareTipoDocumentoSistema(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1 === obj2 ? true : false;
  }

  dialogDesativar(assuntoList: any, tipoDocumento: TipoDocumento) {
    const dialogRef = this.dialog.open(DialogDesativiarTipoDocumento, {
      maxWidth: "400px",
      data: assuntoList.sort((a, b) => (a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0)),
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.assuntoService.deleteAssuntoTipoDocumentoByTipoDocumento(tipoDocumento.id).subscribe((res) => {
          this.submeter();
        });
      }
    });
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
      data: { multiSelect: true, selected: this.domain.lotacoes, ehSelect: true}
    });

    dialogLotacao.afterClosed().subscribe((lotacoes) => {
      if (lotacoes) {
        this.domain.lotacoes = lotacoes;
      }
    });
  }

  private limparLotacaoDigitada(event: any) {
    event.value = "";
  }

  setTipoMotorRegras() {
    this.domain.idTipoDocumentoMotorRegras = this.domain.idTipoDocumentoMotorRegras
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\-]/g, "");

    if (this.domain.idTipoDocumentoMotorRegras != "") {
      this.domain.somenteAnexo = false;
      this.domain.geraNumeroDocumento = true;
      this.domain.requerimento = false;
    } else {
      this.domain.somenteAnexo = undefined;
    }
  }

  consultarIdTipoDocumentoMotorRegras(consultarIdTipoDocumentoMotorRegras: string) {
    if (consultarIdTipoDocumentoMotorRegras.trim() === "") return;

    this.domain.idTipoDocumentoMotorRegras = this.domain.idTipoDocumentoMotorRegras
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\-]/g, "");

    this.apiService.getTipoDocumentoMotorRegra(consultarIdTipoDocumentoMotorRegras).subscribe(
      (tipo) => {
        if (tipo != null) {
          this.domain.idTipoDocumentoMotorRegras = "";
          this.messageService.messageWarning("ID do tipo de documento do motor já cadastrado");
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
