import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GrupoService } from "src/app/core/api/grupo.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { Grupo } from "../../../models/grupo.model";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-grupo",
  templateUrl: "./listar-grupo.component.html",
  styleUrls: ["./listar-grupo.component.css"]
})
export class ListarGrupoComponent extends ListarGenericsComponent<Grupo> {
  public dataSource: MatTableDataSource<Grupo>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public apiService:  GrupoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public orgaoService: OrgaoService,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "grupo",
      ["descricao", "lotacao","ativo", "removerOpcao"]);
  }

  pesquisaLotacao(lotacao: number){
    this.orgaoService.getOrgaoLocalById(lotacao).subscribe(
      (res) =>{
        lotacao = res;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  inativar(grupo: Grupo) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        grupo.ativo = !grupo.ativo;
        this.apiService.put(grupo).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
            return true;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

}