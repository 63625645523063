import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LacunaWebPKI } from "web-pki";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { Certificate } from "../../../global/models/certificate.model";
import { ArquivoService } from "../../api/arquivo.service";
import { DocumentoService } from "../../api/documento.service";
import { ProcessoService } from "../../api/processo.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { Documento } from "../../models/documento.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { Processo } from "../../models/processo.model";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { LoadingScreenService } from "src/app/global/api/loading-screen";
import { ModeloService } from "../../api/modelo.service";
import { Modelo } from "../../models/modelo.model";

@Component({
  selector: "app-incluir-documento",
  templateUrl: "./incluir-documento.component.html",
  styleUrls: ["./incluir-documento.component.css"],
})
export class IncluirDocumentoComponent implements OnInit {
  private NATUREZA_ARQUIVO_EDITOR = 1;
  private NATUREZA_ARQUIVO_ANEXO = 2;
  @ViewChild("myckeditor") ckeditor: any;
  public ckeConfig: any;
  // tslint:disable-next-line:member-ordering
  private static formDataFile = "file";
  public conteudo: any;
  public form: FormGroup;
  public documento: Documento;
  public processo: Processo;
  public nomeDocumento: string;
  public tipoDocumento: TipoDocumento;
  public idProcesso: number;
  public fileName: string;
  public natureza = this.NATUREZA_ARQUIVO_EDITOR;
  public listDocumento: DocumentoDTO[] = [];
  public documentoAssinado: DocumentoDTO;
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate: Certificate;
  public arquivoAssinado = false;
  public nomeArquivo = "";
  public nomeArquivoAssinado = "";
  public listTipoDocumento: any[];
  public formData: FormData = new FormData();
  public file: File;

  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("btnCancelar") btnCancelar: ElementRef;
  
  public tiposDocumento: TipoDocumento[];
  public modeloSelecionado: Modelo;
  public modelos: Modelo[];

  constructor(public tipoDocumentoService: TipoDocumentoService,
    public modeloService: ModeloService,
    public processoService: ProcessoService,
    public documentoService: DocumentoService,
    public router: Router,
    protected route: ActivatedRoute,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<IncluirDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService,
    private chRef: ChangeDetectorRef,
    public arquivoService: ArquivoService,
    public loadingScreenService: LoadingScreenService,
    private eleRef: ElementRef,
    private sharedService: SharedService) {
    this.documento = new Documento();
    this.idProcesso = data;

    this.certificate = JSON.parse(this.sharedService.getKeyStoreCertificate());
  }


  ngOnInit() {
    this.confiCkEditor();
    this.processoService.get(this.idProcesso).subscribe(
      processo => {
        this.processo = processo;
        this.carregarTiposDocumentos();
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  carregarTiposDocumentos() {
    this.tipoDocumento = null;

    if (this.natureza === this.NATUREZA_ARQUIVO_EDITOR) {
      if (environment.VISAO === this.sharedService.VISAO_BENEFICIARIO) {
        this.tipoDocumentoService.getTipoDocumentoSomenteAnexo(false).subscribe(
          (tiposDocumento: TipoDocumento[]) => {
            this.tiposDocumento = tiposDocumento.filter(tipoDoc => tipoDoc.visibilidadeExterna && tipoDoc.ativo)
            .filter(doc => doc.somenteAnexo || doc.requerimento === false);

            this.tipoDocumentoService.getTipoDocumentoEditorPorLotacao(this.processo.lotacaoAtual, false, true, true).subscribe(
                (tipoDoc) => {
                  if(tipoDoc != null && tipoDoc.requerimento === false && tipoDoc.ativo){
                    this.tiposDocumento.push( ...tipoDoc );
                  }
                  if (this.tiposDocumento.length == 0) {
                    this.messageService.messageInfo("Não possui Tipo de Documento (Editor de Texto) cadastrado para esse assunto");
                  }
                },
                error => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );

          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        this.tipoDocumentoService.getTipoDocumentoEditorPorSetor().subscribe(
          (tiposDocumento: TipoDocumento[]) => {
            this.tiposDocumento = tiposDocumento.filter(tipoDoc => !tipoDoc.somenteAnexo && tipoDoc.ativo)
            .filter(doc => doc.somenteAnexo || doc.requerimento === false);
            if (this.tiposDocumento.length == 0) {
              this.messageService.messageInfo("Não possui Tipo de Documento (Editor de Texto) cadastrado para esse assunto");
            }
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    } else {
      this.listTipoDocumento = this.processo.assunto.listTipoDocumento.filter(tipoDoc => tipoDoc.obrigatorio == false)
        .map(listTipoDocumento => listTipoDocumento.tipoDocumento);
      this.tipoDocumentoService.getByListTipoDocumentoId(this.listTipoDocumento).subscribe(
        (tiposDocumento: TipoDocumento[]) => {
          this.tiposDocumento = tiposDocumento.filter(tipoDoc => tipoDoc.somenteAnexo && tipoDoc.ativo);
          if (JSON.parse(this.sharedService.getDocumentoRepresentado())) {
            this.tiposDocumento = this.tiposDocumento.filter(tipoDoc => tipoDoc.visibilidadeExterna === true && tipoDoc.ativo) ;
          }
          const tipoDocumentoNew = new TipoDocumento();
          tipoDocumentoNew.nomeDocumento = "";
          this.tiposDocumento.unshift(tipoDocumentoNew);
          if (this.tiposDocumento.length == 1) {
            this.messageService.messageInfo("Não possui Tipo de Documento (Anexo) cadastrado para esse assunto");
          }
        },
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language : "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "240px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] }
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons: "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About"
    };
  }

  voltar(): void {
    this.dialogRef.close();
  }

  confirmar(): void {
    if (this.validar()) {
      // tslint:disable-next-line:triple-equals
      if (this.natureza == this.NATUREZA_ARQUIVO_ANEXO) {
        this.startFile(this.file);
      } else {
        this.startEditorTexto();
      }
    }
  }

  private updateProcessoWithNewDocument() {
    this.processoService.get(this.processo.id).subscribe(
      (resp) => {
        this.messageService.messageSuccess("Novo documento inserido.");
        const btnCancelar = this.eleRef.nativeElement.querySelector("#btnCancelar");
        btnCancelar.dispatchEvent(new Event("click"));
        this.loadingScreenService.stopLoading();
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  validarExtensao(arquivo) {
    const extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    const extensoes = !this.processo.assunto.extensao ? [ ".pdf" ] : this.processo.assunto.extensao.split(",");

    return extensoes.filter(e => e.trim().indexOf(extensao) > -1).length > 0;
  }

  selectFile(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (!this.validarExtensao(fileList[0].name)) {
        const extensoes = !this.processo.assunto.extensao ? ".pdf" : this.processo.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }

      this.file = fileList[0];
      this.fileName = fileList[0].name;
      this.formData.append(IncluirDocumentoComponent.formDataFile, this.file);
      this.formData.append("certContent", this.certificate.cert64);
      this.formData.append("processoId", this.processo.id.toString());
      this.formData.append("docAutor", JSON.parse(this.sharedService.getDocumentoRepresentado()));
    }
  }

  private startFile(file: File) {
    this.documentoService.start(this.formData, SharedService.CARREGAR_LOADING).subscribe(sigParams => {
      this.pki.signHash({
        thumbprint: this.certificate.thumbprint,
        hash: sigParams.toSignHash,
        digestAlgorithm: sigParams.digestAlgorithm
      }).success(signature => {
        this.chRef.detectChanges();
        this.completeFile(sigParams, signature, file);
      });
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  private completeFile(sigParams: any, signature: string, file: File) {
    this.documentoService.complete(sigParams.fileName,
      sigParams.transferFileId,
      signature,
      this.documento.descricao,
      this.processo.assunto.descricao,
      this.tipoDocumento.id,
      JSON.parse(this.sharedService.getDocumentoRepresentado()),
      file.name, sigParams.descricaoAssinatura,
      sigParams.hashDocumento,
      this.processo.id,
      null,
       SharedService.CARREGAR_LOADING).subscribe(documento => {
      this.documentoAssinado = new DocumentoDTO();
      this.documentoAssinado.documentoId = documento.id;
      this.updateProcessoWithNewDocument();
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  validar(): boolean {
    let validacao = true;

    if (!this.tipoDocumento || this.tipoDocumento.nomeDocumento == '') {
      this.messageService.messageErro("Selecione o tipo de documento.");
      validacao = false;
    }
    if (!this.documento.descricao) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (!this.conteudo && this.natureza === this.NATUREZA_ARQUIVO_EDITOR) {
      this.messageService.messageErro("Preencha o editor de texto.");
      validacao = false;
    }
    if (this.natureza === this.NATUREZA_ARQUIVO_ANEXO) {
      if (this.file == null) {
        this.messageService.messageErro("Escolha um arquivo para enviar.");
        validacao = false;
      }
    }

    return validacao;
  }

  public alterarTipoDocumento() {
    const modeloPesquisar = new Modelo;
    modeloPesquisar.nome = "";
    modeloPesquisar.tipoDocumento = this.tipoDocumento;
    this.conteudo = this.tipoDocumento.modeloDocumento;
    this.modeloService.pesquisar(modeloPesquisar, 0, 0).subscribe((modelos: any) => {
      if (modelos && modelos.content) {
        this.modelos = modelos.content;
      }
    });
  }

  public alterarModelo() {
    this.conteudo = this.modeloSelecionado.conteudo;
  }

   public startEditorTexto() {
    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
      // tslint:disable-next-line: max-line-length
      this.documentoService.startWithHtml(this.conteudo, cert.cert64, this.processo.id, JSON.parse(this.sharedService.getDocumentoRepresentado()), 1, SharedService.CARREGAR_LOADING)
      .subscribe(
        sigParams => {
          this.pki.signHash({
            thumbprint: cert.thumbprint,
            hash: sigParams.toSignHash,
            digestAlgorithm: sigParams.digestAlgorithm
          }
        ).success(signature => {
          this.completeEditorTexto(sigParams, signature);
        }).fail(error => {
            this.messageService.messageErro("Erro no upload do arquivo.");
            throw error;
        });
    });
}

  private completeEditorTexto(sigParams: any, signature: string) {
    this.documentoService.complete(sigParams.fileName,
      sigParams.transferFileId,
      signature,
      this.documento.descricao,
      this.processo.assunto.descricao,
      this.tipoDocumento.id,
      JSON.parse(this.sharedService.getDocumentoRepresentado()),
      "documento_anexo_editor.pdf",
      sigParams.descricaoAssinatura,
      sigParams.hashDocumento,
      this.processo.id,
      this.conteudo,
      SharedService.CARREGAR_LOADING).subscribe(documento => {
      this.documentoAssinado = new DocumentoDTO();
      this.documentoAssinado.documentoId = documento.id;
      this.updateProcessoWithNewDocument();
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }
}
