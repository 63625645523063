import { ApiService } from "./api.service";
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CategoriaAssuntoPesquisa } from "../models/categoriaAssuntoPesquisa";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class CategoriaAssuntoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "assunto-categoria", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  getFilhos(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/parent/${id}`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  public consultar(categoriaAssuntoPesquisa: CategoriaAssuntoPesquisa, page: number, size: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, categoriaAssuntoPesquisa)
      .pipe(map(response => response));
  }

  public listarAtivas(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/ativas`).pipe(map(response => response));
  }

  public listarServicos(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/listar-servicos`).pipe(map(response => {
      return response;
    }));
  }

}