import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MessageService } from "../../../global/message/message.service";
import { SharedService } from "../../api/shared.service";
import { Router } from "@angular/router";
import { Contribuinte } from "src/app/core/models/contribuinte.model";


@Component({
  selector: "app-dialog-selecao-filial",
  templateUrl: "./dialog-selecao-filial.component.html",
  styleUrls: ["./dialog-selecao-filial.component.css"]
})

export class DialogSelecaoFilialComponent implements OnInit {
  public contribuintes: Contribuinte[];
  public contribuinte: Contribuinte;
  public opcao: string;

  constructor(
    public dialogRef: MatDialogRef<DialogSelecaoFilialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private messageService: MessageService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.opcao = this.data.opcao;
    this.contribuintes = this.data.contribuinte;
  }

  onConfirm(): void {
    this.dialogRef.close(this.contribuinte);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}