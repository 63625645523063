import { Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";

import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { Orgao } from "src/app/core/models/orgao.model";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { comparaCaseInsensitive } from "src/app/global/util/funcoes";

@Component({
  selector: "app-listar-orgao",
  templateUrl: "./listar-orgao.component.html",
  styleUrls: ["./listar-orgao.component.css"]
})
export class ListarOrgaoComponent extends ListarGenericsComponent<Orgao> {

  public dataSource: MatTableDataSource<Orgao>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  nomeFiltro = new FormControl('');
  siglaFiltro = new FormControl('');

  filtro = {
    nome: "",
    sigla: ""
  }

  constructor(
    public apiService: OrgaoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService

  ) {
    super(apiService, messageService, sharedService, toastr, router, "orgao",
      ["orgao", "sigla", "orgaoPai", "ativo", "editarOpcao"]);
  }

  afterResponseListar() {
    if (this.dataSource != null && this.dataSource.data != null) {
      this.dataSource.data = this.ordernarDescricaoOrdemAlfabetica(this.dataSource.data);
    }
    this.configurarFiltros();
    this.dataSource.filterPredicate = this.createFilter();
  }

  public ordernarDescricaoOrdemAlfabetica(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.descricao > obj2.descricao) {
        return 1;
      }
      if (obj1.descricao < obj2.descricao) {
        return -1;
      }
      return 0;
    });
    return list;
  }

  inativar(orgao: Orgao) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        orgao.ativo = !orgao.ativo;
        this.apiService.put(orgao).subscribe(
          (res) => {
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
            return true;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

  private configurarFiltros(): void {
    this.nomeFiltro.valueChanges
      .subscribe(
        nome => {
          this.filtro.nome = nome;
          this.dataSource.filter = JSON.stringify(this.filtro);
        }
      );
    this.siglaFiltro.valueChanges
      .subscribe(
        sigla => {
          this.filtro.sigla = sigla;
          this.dataSource.filter = JSON.stringify(this.filtro);
        }
      );
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let pesquisa = JSON.parse(filter);
      return comparaCaseInsensitive(data.nome, pesquisa.nome)
        && comparaCaseInsensitive(data.sigla, pesquisa.sigla);
    }
    return filterFunction;
  }

}
