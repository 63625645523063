import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface TextInputComponentProps {
  label: string;
  placeholder: string;
  type: undefined | 'cpf' | 'date';
  min: number | undefined;
  max: number | undefined;
  name: string;
  ngModel: unknown;
  change:  Function;
  disabled: boolean;
  required: boolean;
  hintText: string | undefined;
  errorMessage: string | undefined;
  maxlength: number | undefined;
  keyup: Function;
  focusout: Function;
  formControl: any;
  input: any;
  click: any;
  value: string;
  infoHTML: string;
  infoMessage: string;
  rightIcon: string;
}

type Props = TextInputComponentProps

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {
  @Input() label: Props['label'] = '';
  @Input() placeholder: Props['placeholder'];
  @Input() type: Props['type'];
  @Input() min: Props['min'];
  @Input() errorMessage: Props['errorMessage'];
  @Input() hintText: Props['hintText'];
  @Input() max: Props['max'];
  @Input() name: Props['name'];
  @Input() ngModel: Props['ngModel'];
  @Input() change: Props['change'] = () => {};
  @Input() disabled: Props['disabled'];
  @Input() required: Props['required'];
  @Input() maxlength: Props['maxlength'];
  @Input() keyup: Props['keyup'] = () => {};
  @Input() focusout: Props['focusout'] = () => {};
  @Input() formControl: Props['formControl'];
  @Input() input: Props['input'];
  @Input() click: Props['click'];
  @Input() value: Props['value'];
  @Input() infoHTML: Props['infoHTML']
  @Input() infoMessage: Props['infoMessage'] = ''
  @Input() rightIcon?: Props['rightIcon'] = ''

  @Output() ngModelChange = new EventEmitter<string>();

  
  constructor() { }

  ngOnInit() {}

  onChange(e) {
    this.ngModel = e;
    this.ngModelChange.emit(this.ngModel as string)
  }

}
