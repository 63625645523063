import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/core";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { Situacao } from "src/app/core/models/situacao.model.js";
import { SituacaoService } from "src/app/core/api/situacao.service.js";
import { MessageService } from "src/app/global/message/message.service";

@Component({
  selector: "app-salvar-situacao",
  templateUrl: "./salvar-situacao.component.html",
  styleUrls: ["./salvar-situacao.component.css"],
})
export class SalvarSituacaoComponent extends SalvarGenericsComponent<Situacao> {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: SituacaoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService
  ) {
    super(formBuilder, router, apiService, messageService, route, "situacao", "");
  }

  ngOnInit() {}

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (domain.somenteStatus == null) {
      this.messageService.messageErro("Selecione o campo Somente Status.");
      validacao = false;
    }
    if (domain.pendenciaAutor == null) {
      this.messageService.messageErro("Selecione ao campo Pendência Autor.");
      validacao = false;
    }
    if (domain.situacaoFinal == null) {
      this.messageService.messageErro("Selecione o campo Situação Final.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    if (domain.situacaoBloqueio == null) {
      this.messageService.messageErro("Selecione o campo Situação Bloqueante.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }
}
