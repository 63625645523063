import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class AnexoAssuntoService extends ApiService {
  public API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "anexo-assunto", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  getByAssunto(id: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/assunto/${id}`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

}