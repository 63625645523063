import { Domain } from "src/app/global/models/domain.model";
import { Documento } from "../documento.model";
import { Endereco } from "./endereco.model";
import { Representante } from "./representante.model";
import { DocumentosRegra } from "./documentos-regra";
import { Pensao } from "./pensao.model";
import { CondicoesParaTrazerDocs } from "./condicoes-para-trazer-docs.model";

export class Requerente extends Domain {
    id: number;
    processoId: number;
    tipoSolicitante: string;
    solicitanteTipo: string;
    nome: string;
    cpf: string;
    dataNascimento: string;
    emancipado: boolean;
    sexo: string;
    pisPasepNit: string;
    rg: string;
    rgOrgaoEmissor: string;
    rgUf: string;
    estadoCivil: string;
    orgaoOrigem: string;
    dataCasamento: string;
    endereco: Endereco;
    email: string;
    telefone: string;
    telefone2: string;
    agencia: number;
    conta: number;
	percentualPensaoAlimenticia: Number;
    representanteLegal: Representante;
    pensao: Pensao;
    necessitaRepresentante: boolean;
    documentos: Documento[];
    documentosRegra: DocumentosRegra[];
    documentosObrigatorios: DocumentosRegra[];
    documentosRequeridosAposAbertura: DocumentosRegra[];
    procedente: boolean;
    doencaEspecial: boolean;
    condicoesParaTrazerDocs: CondicoesParaTrazerDocs;
    recebeOutroBeneficio: boolean;
    beneficioRgpsInss: boolean;
    beneficio: string;
    beneficioUf: string;
    pensaoDividida: boolean;
    universitario: boolean;
    possuiOutroDependente: boolean;
    possuiRelacionamento: boolean;
    tipoRelacionamentoCasamento: boolean;
    observacao: string;
    
    representNome: String;
    representTipo: String;
    representSexo: String;
    representCpf: String;
    representRg: String;
    representOrgaoEmissor: String;
    representRgUf: String;
    representEstadoCivil: String;
    representTelefone: String;
    representEmail: String;
    representEndereco: Endereco
}