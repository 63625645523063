import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salvar-documento-motor-regras',
  templateUrl: './salvar-documento-motor-regras.component.html',
  styleUrls: ['./salvar-documento-motor-regras.component.css']
})
export class SalvarDocumentoMotorRegrasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
