import { formatDate } from "@angular/common";

export const limpaFormatacao = (cpf: string) => {
    return cpf.replace(/\D/g, "");
}

export const comparaCaseInsensitive = (str1: string, str2: string): boolean => {
    return str1.toLowerCase().indexOf(str2.toLowerCase()) !== -1;
}

export const formatarData = (data: string): string => {
    return formatDate(data, "dd/MM/yyyy", "en-US");
}

export const formatarCPF = (cpf: string | number): string => {
    let cpfFormatado: string = "";
    if (typeof cpf === "number") {
        cpfFormatado = cpf.toString();
    } else {
        cpfFormatado = cpf;
    }

    return cpfFormatado.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const formatarTelefone = (telefone: string | number): string => {
    let telefoneFormatado: string = "";
    if (typeof telefone === "number") {
        telefoneFormatado = telefone.toString();
    } else {
        telefoneFormatado = telefone;
    }
    telefoneFormatado = telefoneFormatado.length > 11 ? telefoneFormatado.slice(6, telefoneFormatado.length) : telefoneFormatado;

    return telefoneFormatado.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
}