import { Component, Input, OnInit, OnChanges, SimpleChanges, NgZone } from "@angular/core";
import { MatDialog, MatStepper } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { DocumentoService } from "../../api/documento.service";
import { ProcessoService } from "../../api/processo.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { Assunto } from "../../models/assunto.model";
import { Contribuinte } from "../../models/contribuinte.model";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { Processo } from "../../models/processo.model";
import { SolicitanteDTO } from "../../models/solicitante-dto.model";
import { LacunaWebPKI } from "web-pki";
import { MetadadoValor } from "../../models/metadado-valor.model";
import { AssuntoService } from "../../api/assunto.service";
import { Utils } from "src/app/global/util/utils-component";
import { NgxSmartModalService } from "ngx-smart-modal";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { Certificate } from "../../../global/models/certificate.model";
import { DialogProcessoComponent } from "../dialog-processo/dialog-processo.component";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";
import { Pessoa } from "../../models/pessoa.model";
import { ItemNaoSimilaridade } from "../../models/item-nao-similaridade.model";
import { Documento } from "../../models/documento.model";
import { Assinador } from "../../models/assinador.model";
import { Usuario } from "../../models/usuario.model";

@Component({
  selector: "app-resumo-anexo-processo",
  templateUrl: "./resumo-anexo-processo.component.html",
  styleUrls: ["./resumo-anexo-processo.component.css"]
})
export class ResumoAnexoProcessoComponent implements OnInit, OnChanges {

  @Input() processo: Processo;
  @Input() assunto: Assunto;
  @Input() documentos: DocumentoDTO[];
  @Input() itensNaoSimilaridade: ItemNaoSimilaridade[];

  @Input() stepper: MatStepper;

  private representante: Contribuinte;
  private representado: Contribuinte;
  private tipoDocumentoRequerimentoId: number;
  public numeroProcesso: string;
  public dialogRetorno: any;


  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate = new Certificate();

  public infoAssuntoAutor = new OpcaoAssuntoInterno();
  public listaAssinador: Assinador[] = [];

  constructor(public documentoService: DocumentoService,
    public processoService: ProcessoService,
    public contribuinteSevice: ContribuinteService,
    public tipoDocumentoService: TipoDocumentoService,
    public dialog: MatDialog,
    public assuntoService: AssuntoService,
    protected route: ActivatedRoute,
    public messageService: MessageService,
    public ngxSmartModalService: NgxSmartModalService,
    private ngZone: NgZone,
    private router: Router,
    public sharedService: SharedService
    ) {

  }

  ngOnInit() {
    this.infoAssuntoAutor.tipo = this.route.snapshot.params.tipoAutor;
    this.infoAssuntoAutor.infoAutor = this.route.snapshot.params.infoAutor;
    this.infoAssuntoAutor.infoAssinador = this.route.snapshot.params.infoAssinador;
    this.representante = new Contribuinte();
    this.representante.documento = "1";
    this.representante.nome = "Mock Nome Contribuinte Representante";

    if (JSON.parse(this.sharedService.getDocumentoRepresentante()) != null) {
      this.contribuinteSevice.findByDocumento(JSON.parse(this.sharedService.getDocumentoRepresentante())).
        subscribe(
          (representante) => {
             this.representante = representante;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
    }

    this.representado = new Contribuinte();
    this.representado.documento = "2";
    this.representado.nome = "Mock Nome Contribuinte Representado";
    if (JSON.parse(this.sharedService.getDocumentoRepresentado()) != null) {
      this.contribuinteSevice.findByDocumento(JSON.parse(this.sharedService.getDocumentoRepresentado())).
        subscribe(
          (representado) => {
            this.representado = representado;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
    }

    if (this.assunto && this.assunto.listTipoDocumento) {
      this.carregarTiposDocumentos(this.assunto.listTipoDocumento);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assunto && this.assunto && this.assunto.listTipoDocumento) {
      this.carregarTiposDocumentos(this.assunto.listTipoDocumento);
    }
  }

  carregarTiposDocumentos(listTipoDocumento: any) {
    const tipoDocumentoIds = listTipoDocumento.map(function (tipoDocumento) {
      return tipoDocumento.tipoDocumento;
    });
    this.tipoDocumentoService.getByListTipoDocumentoId(tipoDocumentoIds).subscribe(
        res => {
          res.filter(tipoDocumento => !tipoDocumento.somenteAnexo)
            .forEach(tipoDocumento => {
              this.tipoDocumentoRequerimentoId = tipoDocumento.id;
            });
        },
        error => {
            this.messageService.messageErro(error.error.message);
            throw error;
        });
  }

  public verificarCertificadoESalvarProcesso(processo: Processo, documentos: DocumentoDTO[]) {
    if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO) {
      const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
      this.enviarProcesso(processo, documentos, cert);
    // tslint:disable-next-line: max-line-length
    } else if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE ||
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO ||
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL ||
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO) {
        if (JSON.parse(this.sharedService.getDocumentoAstra()) === this.infoAssuntoAutor.infoAssinador) {
          this.openDialogSelecionarCertificado(processo, documentos);
        } else {
          this.enviarProcesso(processo, documentos, null);
        }
    }
  }

  openDialogSelecionarCertificado(processo: Processo, documentos: DocumentoDTO[]): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate
    });

    openDialogSelecionarCertificado.afterClosed().subscribe(certParam => {
      if (certParam != null && certParam.cert64 != null) {
        this.enviarProcesso(processo, documentos, certParam);
      }
    }, error => {
      this.messageService.messageErro("Erro para selecionar o certificado.");
    });
  }

  public enviarProcesso(processo: Processo, documentos: DocumentoDTO[], cert: any) {

    const processoDTO: ProcessoDTO = this.montarProcessoDTO(processo, documentos);

    let autor = this.sharedService.decodePayloadJWT().sub;

    if (cert != null) {
      // tslint:disable-next-line: max-line-length
      this.documentoService.startWithHtml(this.processo.modeloAssunto, cert.cert64, null, autor, 1, SharedService.CARREGAR_LOADING).subscribe(sigParams => {
        this.pki.signHash({
          thumbprint: cert.thumbprint,
          hash: sigParams.toSignHash,
          digestAlgorithm: sigParams.digestAlgorithm
        }).success(signature => {
          this.assinaturaDocumentoComplete(this.processo.modeloAssunto, sigParams, signature, autor, processoDTO);
        });
      });
    } else {
      this.incluirDocumentoNovoEditor(autor, processoDTO);
    }
  }

  public incluirDocumentoNovoEditor(autor: string, processoDTO: ProcessoDTO) {
    const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
    gerarPdfUploadAlfrescoRequest.descricao = this.processo.descricao;
    gerarPdfUploadAlfrescoRequest.autor = autor;
    gerarPdfUploadAlfrescoRequest.descricaoAssunto = this.assunto.descricao;
    gerarPdfUploadAlfrescoRequest.html = this.processo.modeloAssunto;
    gerarPdfUploadAlfrescoRequest.nomeDocumento = "Documento requerimento do processo.pdf";
    gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
      return assinador.documento.toString();
    });
    gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.tipoDocumentoRequerimentoId;
    gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
    gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = true;
    this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(documento => {
      this.adicionarRequerimentoECriarProcesso(documento, processoDTO);
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

  private async assinaturaDocumentoComplete(modeloDocumento: string, sigParams: any, signature: any, autor: any, processoDTO: ProcessoDTO) {
    this.documentoService.complete(sigParams.fileName, sigParams.transferFileId, signature, this.processo.descricao,
      this.assunto.descricao, this.tipoDocumentoRequerimentoId,
      autor, "Documento requerimento do processo.pdf",
      sigParams.descricaoAssinatura,
      sigParams.hashDocumento,
      null,
      modeloDocumento,
      SharedService.CARREGAR_LOADING).subscribe(async documento => {
        this.adicionarRequerimentoECriarProcesso(documento, processoDTO);
      },
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        });
  }

  private async adicionarRequerimentoECriarProcesso(documento: DocumentoDTO, processoDTO: ProcessoDTO){
    const documentoDTO = new DocumentoDTO();
    documentoDTO.documentoId = documento.id;
    documentoDTO.ordem = 1;
    processoDTO.listDocumento.push(documentoDTO);

    await this.salvarProcesso(processoDTO);

    await this.abriDialogNumeroProcesso();
  }

  private async abriDialogNumeroProcesso() {
    this.ngxSmartModalService.setModalData(this.numeroProcesso, "modalProcesso");
    this.ngxSmartModalService.create("modalProcesso", "content").open();
  }

  public close(): void {
    this.ngxSmartModalService.close("modalProcesso");
    this.ngZone.run(() => this.router.navigate(["home"])).then();
  }

  private async salvarProcesso(processoDTO: ProcessoDTO) {
    processoDTO.assinadorPrincipal = this.infoAssuntoAutor.infoAssinador;
     const response = await this.processoService.post(processoDTO).toPromise().catch(
        error => {
            this.messageService.messageErro(error.error.message);
            throw error;
        }
    );
    if (this.itensNaoSimilaridade != null) {
      this.itensNaoSimilaridade = this.itensNaoSimilaridade.map(v => ({...v, processoId: response.processoId,
        etiquetaProcessoViproc: response.numeroProcesso}));
      this.processoService.salvarItemNaoSimilaridade(response.processoId, this.itensNaoSimilaridade).subscribe();
    }

    this.numeroProcesso = response.numeroProcesso;
    return response.numeroProcesso;
  }

  private montarProcessoDTO(processo: Processo, documentos: DocumentoDTO[]): ProcessoDTO {

    const processoDTO = new ProcessoDTO();
    processoDTO.assunto = new Assunto();
    processoDTO.assunto.id = this.assunto.id;
    processoDTO.assunto.usuarioRecebedorPadrao = new Usuario();
    processoDTO.assunto.usuarioRecebedorPadrao.documento = this.sharedService.decodePayloadJWT().sub;

    const listMetadadoValor: MetadadoValor[] = [];

    this.assunto.listMetadado.forEach(assuntoMetadado => {
        const metadadoValor = new MetadadoValor();
        metadadoValor.metadadoId = assuntoMetadado.metadado.id;
        metadadoValor.valor = assuntoMetadado.metadado.valor;
        listMetadadoValor.push(metadadoValor);

        if (assuntoMetadado.metadado.acao === "nossoNumero") {
          processoDTO.metadadoValorDae = metadadoValor;
        }
    });

    // Salvando informações digitados dos metadados
    processoDTO.listMetadadoValor = listMetadadoValor;

    processoDTO.descricao = processo.descricao;
    processoDTO.observacao = processo.observacoes;
    processoDTO.modeloDocumento = processo.modeloAssunto;

    // Caso seja escolhida a primeira opção "Estou abrindo um processo para mim ou para uma de minhas filiais do Estado do Ceará"
    if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO) {
      if (this.representante == null) {
        this.representante = this.representado;
      }
      processoDTO.representante = new SolicitanteDTO(this.representante.documento, this.representante.nome);
      processoDTO.autor = new SolicitanteDTO(this.sharedService.decodePayloadJWT().sub, this.sharedService.decodePayloadJWT().name);

      if (this.assunto.tipoDistribuicao == 3) { // CEXAT/NUAT
        processoDTO.lotacaoCexatNuat = processo.lotacaoCexatNuat;
      }
    } else if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL) {
        processoDTO.representante = new SolicitanteDTO(this.infoAssuntoAutor.infoAutor, this.sharedService.decodePayloadJWT().name);
        processoDTO.autor = new SolicitanteDTO(this.infoAssuntoAutor.infoAutor, this.sharedService.decodePayloadJWT().name);

    } else if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO) {
      processoDTO.representante = new SolicitanteDTO(JSON.parse(this.sharedService.getDocumentoAstra()), this.sharedService.decodePayloadJWT().name);
      processoDTO.lotacaoResponsavel = +this.infoAssuntoAutor.infoAutor;
      // tslint:disable-next-line: max-line-length
    } else if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE) {
      processoDTO.representante = new SolicitanteDTO(JSON.parse(this.sharedService.getDocumentoAstra()), this.sharedService.decodePayloadJWT().name);
      processoDTO.autor = new SolicitanteDTO(this.infoAssuntoAutor.infoAutor, this.sharedService.decodePayloadJWT().name);

      if (this.assunto.tipoDistribuicao == 3) { // CEXAT/NUAT
        processoDTO.lotacaoCexatNuat = processo.lotacaoCexatNuat;
      }

    }

    // Se o assinador não for o usuário logado, precisa ter a movimentação para o fiscal e para sua lotação.
    if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO) {
      if (JSON.parse(this.sharedService.getDocumentoAstra()) !== this.infoAssuntoAutor.infoAssinador) {
        const pessoaReceberProcesso = new Pessoa();
        pessoaReceberProcesso.matricula = this.infoAssuntoAutor.infoAssinador;
        processoDTO.pessoaReceberProcesso = pessoaReceberProcesso;
      }
    }

    processoDTO.listDocumento = this.montarDocumentoDTO(documentos);

    return processoDTO;
  }

  private montarDocumentoDTO(documentos: DocumentoDTO[]): DocumentoDTO[] {
    let ordem = 2;
    const documentosDTO: DocumentoDTO[] = [];
    documentos.forEach(documento => {
      const documentoDTO = new DocumentoDTO();
      documentoDTO.ordem = ordem;
      documentoDTO.documentoId = documento.id;
      documentosDTO.push(documentoDTO);
      ordem++;
    });

    return documentosDTO;
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  downloadDocumento(id: number) {
    this.documentoService.get(id).subscribe(documento => {
      let isPDF;
      if (documento.nomeDocumento != null) {
        isPDF = documento.nomeDocumento.substring(documento.nomeDocumento.lastIndexOf(".")).indexOf("pdf") > -1;
      } else {
        isPDF = documento.file.name.substring(documento.file.name.lastIndexOf(".")).indexOf("pdf") > -1;
      }
      if (isPDF) {
        this.documentoService.download(documento.id).subscribe(blob => {
          if (!Utils.downloadBlob(blob)) {
            this.messageService.messageWarning("Documento ainda não existe.");
          }
        });
      } else {
        this.documentoService.download(documento.id, "octet/stream").subscribe(blob => {
          if (!Utils.downloadBlob(blob, documento.nomeDocumento)) {
            this.messageService.messageWarning("Documento ainda não existe.");
          }
        });
      }
    },
    error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }
}