import { AppConfig, APP_CONFIG } from "src/app.config";
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Requerente } from "../models/pensao/requerente.model";

@Injectable({ providedIn: "root" })
export class RequerenteService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "requerente", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  salvar(requerente: Requerente){
    return this.http.post(`${this.API_URL}${this.resource}`, requerente)
       .pipe(map(response => response));
  }
  
  pesquisarPorProcesso(idProcesso: number){
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-por-processo/${idProcesso}`)
            .pipe(map(response => response));
  } 
}