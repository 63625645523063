import { Component, OnInit, Inject, Input, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MessageService } from "../../../global/message/message.service";
import { Processo } from "../../models/processo.model";
import { Documento } from "../../models/documento.model";
import { DocumentoService } from "../../api/documento.service";
import { ProcessoService } from "../../api/processo.service";
import { map, switchMap } from "rxjs/operators";
import { forkJoin, Observable, throwError } from "rxjs";
import { SharedService } from "src/app/global/api/shared.service";
import { Assinador } from "../../models/assinador.model";
import { MetadadoValor } from "../../models/metadado-valor.model";
import { TipoDocumentoMetadado } from "../../models/tipo-documento-metadado.model";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";

@Component({
  selector: 'app-dialog-vincular-processos-relacionados',
  templateUrl: './dialog-vincular-processos-relacionados.component.html',
  styleUrls: ['./dialog-vincular-processos-relacionados.component.css']
})
export class DialogVincularProcessosRelacionadosComponent {
  processosRelacionados: Processo[] = []; // Lista de processos relacionados
  processosSelecionados: Processo[] = [];
  listaAssinador: Assinador[] = [];
  documentoNovo: Documento;
  formData: FormData = new FormData();
  fileList: FileList;
  processo: Processo;
  isAnexo: Boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogVincularProcessosRelacionadosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService,
    private processoService: ProcessoService,
    private documentoService: DocumentoService,
    private sharedService: SharedService,
  ) {
    this.processosRelacionados = this.data.processosRelacionados;
    this.documentoNovo = this.data.documento;
    this.fileList = this.data.fileList;
    this.isAnexo = this.data.isAnexo;
  }

  onProcessoChange(event: any, processo: any) {
    if (event.target.checked) {
      this.processosSelecionados.push(processo);
    } else {
      this.processosSelecionados = this.processosSelecionados.filter(p => p.id !== processo.id);
    }
  }
  
  onCancelarClick() {
    this.dialogRef.close();
  }
  
  onVincularClick() {
    if (this.isAnexo) {
      const processoObservables = this.processosSelecionados.map(processo => this.incluirDocumentoNovoAnexo(processo));
      forkJoin(processoObservables).toPromise()
      .then(() => {
        this.messageService.messageSuccess("Documento inserido com sucesso em todos os processos selecionados!");
        this.dialogRef.close();
      })
      .catch((error) => {
        this.messageService.messageErro("Ocorreu um erro ao vincular o documento: " + error.message);
        console.error(error);
        this.dialogRef.close();
      });
    } else {
      const processoObservables = this.processosSelecionados.map(processo => this.incluirDocumentoNovoEditor(processo));
      forkJoin(processoObservables).toPromise()
      .then(() => {
        this.messageService.messageSuccess("Documento inserido com sucesso em todos os processos selecionados!");
        this.dialogRef.close();
      })
      .catch((error) => {
        this.messageService.messageErro("Ocorreu um erro ao vincular o documento: " + error.message);
        console.error(error);
        this.dialogRef.close();
      });
    }
    
  }

  incluirDocumentoNovoAnexo(processo: Processo): Observable<any> {
    const file: File = this.fileList[0];
    this.formData.append("file", file);
    this.formData.append("fileName", file.name);
    this.formData.append("descricao", this.documentoNovo.descricao);
    this.formData.append("descricaoAssunto", processo.assunto.descricao);
    this.formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
    this.formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
    this.formData.append("processoId", processo.id.toString());
    this.formData.append(
      "docAssinantes",
      this.listaAssinador
        .map(function (assinador) {
          return assinador.documento.toString();
        })
        .join(",")
    );
    this.formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());
    const listMetadadoValor: MetadadoValor[] = [];
    var metadadoValido = true;
    this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
      if (!this.validarMetadado(tipoDocumentoMetadado)) {
        metadadoValido = false;
      }
      const metadadoValor = new MetadadoValor();
      metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
      metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
      listMetadadoValor.push(metadadoValor);
    });
    // Salvando informações digitados dos metadados
    this.documentoNovo.listMetadadoValor = listMetadadoValor;

    this.formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));
    
    return this.documentoService.post(this.formData).pipe(
      switchMap(documentoSalvo => this.processoService.get(processo.id).pipe(map(() => documentoSalvo)))
    );
  }

  public incluirDocumentoNovoEditor(processo: Processo): Observable<any> {
    const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
    gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
    gerarPdfUploadAlfrescoRequest.descricao = this.documentoNovo.descricao;
    gerarPdfUploadAlfrescoRequest.descricaoAssunto = processo.assunto.descricao;
    gerarPdfUploadAlfrescoRequest.html = this.documentoNovo.modeloDocumento;
    gerarPdfUploadAlfrescoRequest.nomeDocumento = "documento_anexo_editor.pdf";
    gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.documentoNovo.tipoDocumento.id;
    gerarPdfUploadAlfrescoRequest.processoId = processo.id;
    gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
      return assinador.documento.toString();
    });
      gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = this.documentoNovo.downloadUsuarioExterno;
  
      const listMetadadoValor: MetadadoValor[] = [];
      var metadadoValido = true;
      this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
        const metadadoValor = new MetadadoValor();
        if (!this.validarMetadado(tipoDocumentoMetadado)) {
          metadadoValido = false;
        }
  
        metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
        metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
        listMetadadoValor.push(metadadoValor);
      });
      // Salvando informações digitados dos metadados
      this.documentoNovo.listMetadadoValor = listMetadadoValor;

      gerarPdfUploadAlfrescoRequest.listMetadadoValor = listMetadadoValor;

      if (metadadoValido)
        return this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).pipe(
          switchMap(documentoSalvo => this.processoService.get(processo.id).pipe(map(() => documentoSalvo)))
        );      
  }

  validarMetadado(tipoDocumentoMetadado: TipoDocumentoMetadado): boolean {
    if (
      tipoDocumentoMetadado.metadado.valor !== undefined &&
      tipoDocumentoMetadado.metadado.valor !== null &&
      tipoDocumentoMetadado.metadado.valor.length !== 0
    ) {
      if (
        tipoDocumentoMetadado.metadado.mascara !== undefined &&
        tipoDocumentoMetadado.metadado.mascara !== null &&
        tipoDocumentoMetadado.metadado.validarQuantidadeCaracteres &&
        tipoDocumentoMetadado.metadado.valor.length !== tipoDocumentoMetadado.metadado.mascara.length
      ) {
        this.messageService.messageErro(
          "Preencha o campo " + tipoDocumentoMetadado.metadado.nome + " conforme a máscara " + tipoDocumentoMetadado.metadado.mascara + "."
        );
        return false;
      }
    } else if (tipoDocumentoMetadado.requerido) {
      this.messageService.messageErro("Preencha o campo " + tipoDocumentoMetadado.metadado.nome);
      return false;
    }

    return true;
  }
  
}
