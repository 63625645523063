import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { APP_CONFIG, AppConfig } from "src/app.config";

@Injectable({ providedIn: "root" })
export class RegisterService {
  API_URL: string;
  headers: any;
  token: any = null;
  endpoint = "sisseg/usuario";

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    this.API_URL = config.API_SISSEG_URL;
  }

  salvarUsuario(data: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
  
    const options = { headers: headers };
  
    return this.http.post(`${this.API_URL}${this.endpoint}`, data, options);

  }
  
  
  
}
