import { Orgao } from "src/app/core/models/orgao.model";
import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { Grupo } from "../../../models/grupo.model";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource, MatDialog } from "@angular/material";
import { GrupoService } from "src/app/core/api/grupo.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { Assunto } from "src/app/core/models/assunto.model";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { Usuario } from "src/app/core/models/usuario.model";
import { GrupoAssunto } from "src/app/core/models/grupo-assunto.model";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { FiscalService } from "src/app/core/api/fiscal.service";

@Component({
  selector: "app-salvar-grupo",
  templateUrl: "./salvar-grupo.component.html",
  styleUrls: ["./salvar-grupo.component.css"],
})
export class SalvarGrupoComponent extends SalvarGenericsComponent<Grupo> {
  treeControl = new NestedTreeControl<any>((node) => node.filhos);
  dataSource = new MatTreeNestedDataSource<any>();
  public grupo = new Grupo();
  public lotacaoSelecionada: any;
  public listAssuntos: Assunto[];
  public listUsuarios: Usuario[];
  public inputUsuario: boolean = true;
  public listAssuntosSelecionado: Assunto[];
  public listUsuariosSelecionados: any[];

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: GrupoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public orgaoService: OrgaoService,
    public assuntoService: AssuntoService,
    public fiscalService: FiscalService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "grupo", "");
  }

  ngOnInit() {
    this.orgaoService.getOrgaoLocalArvore(null, null).subscribe(
      (res) => {
        res.filhos.sort((a, b) => (a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0));
        const lotacoesArvore = [];
        lotacoesArvore.push(res);
        this.dataSource.data = lotacoesArvore;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assuntoService.list().subscribe(
      (res) => {
        this.listAssuntos = res;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  afterResponse() {
    this.orgaoService.getOrgaoLocalById(this.domain.lotacao).subscribe(
      (res) => {
        this.lotacaoSelecionada = res;
        this.selecionaLotacao(this.lotacaoSelecionada);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    this.listAssuntosSelecionado = this.domain.listGrupoAssunto;
  }

  hasChild = (_: number, node: any) => !!node.filhos && node.filhos.length > 0;

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.nome || !domain.nome.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (domain.lotacao == null) {
      this.messageService.messageErro("Selecione a Lotação.");
      validacao = false;
    }
    if (!this.domain.listUsuarios || this.domain.listUsuarios.length == 0) {
      this.messageService.messageErro("Selecione os Usuários do grupo.");
      validacao = false;
    }
    if (!this.listAssuntosSelecionado || this.listAssuntosSelecionado.length == 0) {
      this.messageService.messageErro("Selecione os Assutos do grupo.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      domain.listGrupoAssunto = [];
      this.listAssuntosSelecionado.forEach((assunto) => {
        const grupoAssunto = new GrupoAssunto();
        if (assunto.assuntoId) {
          grupoAssunto.assuntoId = assunto.assuntoId;
        } else {
          grupoAssunto.assuntoId = assunto.id;
        }
        domain.listGrupoAssunto.push(grupoAssunto);
      });
      this.submeter();
    }
  }

  selecionaLotacao(lotacao: Orgao) {
    this.domain.lotacao = lotacao.id;
    this.lotacaoSelecionada = lotacao;
    this.fiscalService.listarUsuarioPorLotacaoPrincipalSecundaria(lotacao.id).subscribe(
      (res) => {
        this.listUsuarios = res.sort((obj1, obj2) => {
          if (obj1.nome > obj2.nome) {
            return 1;
          }
          if (obj1.nome < obj2.nome) {
            return -1;
          }
          return 0;
        });
        this.inputUsuario = false;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  compareAssunto(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.assuntoId : obj1 === obj2;
  }

  compareUsuario(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.documento === obj2.documento : obj1 === obj2;
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.selecionaLotacao(processoParam);
      }
    });
  }
}
