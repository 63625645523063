import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Fase } from "src/app/core/models/fase.model";
import { FaseService } from "src/app/core/api/fase.service";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-fase",
  templateUrl: "./listar-fase.component.html",
  styleUrls: ["./listar-fase.component.css"]
})
export class ListarFaseComponent extends ListarGenericsComponent<Fase> {
  public dataSource: MatTableDataSource<Fase>;
  public columns = [];
  public sections = [];
  public buttons = [];


  @ViewChild('child') child: any;

  ngAfterViewInit() {
    this.paginator = this.child.paginator
  }

  constructor(public apiService: FaseService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "fase",
      ["descricao", "situacaoInicial", "situacaoPosVisualizacao", "tipoFase", "ativo", "removerOpcao"]);

      this.columns =  [
        {
          key: 'descricao',
          label: 'Descrição'
        },
        {
          key: 'situacaoInicial',
          label: 'Situação Inicial',
          parseItem: ({descricao}) => descricao,
        },
        {
          key: 'situacaoPosVisualizacao',
          label: 'Situação Pós Ação',
          parseItem: ({descricao}) => descricao,
        },
        {
          key: 'tipoFase',
          label: 'Tipo Fase',
          parseItem: (e) => e ? 'Externo' : 'Interno'
        },
        {
          key: 'ativo',
          label: 'Ativo',
          type: 'toggle',
          props: {
            matTooltip:"Ativar/Desativar",
            color: "accent",
            click: (e) => this.inativar(e)
          }
        },
        {
          key: 'removerOpcao',
          label: 'Ações',
          type: 'icon',
          props: {
            icon: 'create',
            matTooltip: 'Editar',
            click: (e) => this.edit(e)
          }
        }
      ];

      this.buttons = [ 
        {
          label: 'Nova Fase',
          click: () => {
            this.setPageState();
            this.router.navigateByUrl('/sgeap/fase/salvar')
          }
        },
      ]
  }

  inativar(fase: Fase) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        fase.ativo = !fase.ativo;
        this.apiService.put(fase).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

}