import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListarAssuntoCategoriaComponent } from "./assunto-categoria/listar-assunto-categoria";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material";
import { CategoriaAssuntoService } from "../api/categoria-assunto.service";
import { ToastrService } from "ngx-toastr";
import { SalvarAssuntoCategoriaComponent } from "./assunto-categoria/salvar-assunto-categoria";
import { ListarSituacaoComponent } from "./situacao/listar-situacao";
import { SituacaoService } from "../api/situacao.service";
import { SalvarSituacaoComponent } from "././situacao/salvar-situacao";
import { ListarBasicoAssuntoComponent } from "./assunto/listar-assunto/listar-basico-assunto.component";
import { SalvarAssuntoComponent } from "./assunto/salvar-assunto/salvar-assunto.component";
import { AssuntoService } from "../api/assunto.service";
import { OrgaoService } from "../api/orgao.service";
import { PopoverModule } from "ngx-smart-popover";
import { FaseService } from "../api/fase.service";
import { ListarFaseComponent } from "./fase/listar-fase";
import { SalvarFaseComponent } from "./fase/salvar-fase";
import { ListarMetadadoComponent } from "./metadado/listar-metadado/listar-metadado.component";
import { MetadadoService } from "../api/metadado.service";
import { SalvarMetadadoComponent } from "./metadado/salvar-metadado/salvar-metadado.component";
import { ListarTipoDocumentoComponent } from "./tipo-documento/listar-tipo-documento";
import { TipoDocumentoService } from "../api/tipo-documento.service";
import { SalvarTipoDocumentoComponent } from "./tipo-documento/salvar-tipo-documento";
import { CKEditorModule } from "ng2-ckeditor";
import { CadastroHomeComponent } from "./cadastro-home/cadastro-home.component";
import { ListarGrupoComponent } from "./grupo/listar-grupo/listar-grupo.component"
import { SalvarGrupoComponent } from "./grupo/salvar-grupo";
import { GrupoService } from "../api/grupo.service";
import { ListarEmailTemplateComponent } from "./email-template/listar-email-template";
import { SalvarEmailTemplateComponent } from "./email-template/salvar-email-template";
import { EmailTemplateService } from "../api/email-template.service";
import { DialogListarTagsComponent } from "../processo/dialog-listar-tags/dialog-listar-tags.component";
import { ListarFiscalPreferenciaComponent } from "./fiscal-prefencia/listar-fiscal-prefencia";
import { SalvarFiscalPreferenciaComponent } from "./fiscal-prefencia/salvar-fiscal-prefencia/salvar-fiscal-preferencia.component";
import { FiscalPreferenciaService } from "../api/fiscal-preferencia.service";
import { ListarLotacaoSecundariaComponent } from "./lotacao-secundaria/listar-lotacao-secundaria";
import { LotacaoSecundariaService } from "../api/lotacao-secundaria.service";
import { SalvarLotacaoSecundariaComponent } from "./lotacao-secundaria/salvar-lotacao-secundaria";
import { ListarOrgaoComponent } from "./orgao/listar-orgao/listar-orgao.component";
import { SalvarOrgaoComponent } from "./orgao/salvar-orgao/salvar-orgao.component";
import { LotacaoCexatService } from "../api/lotacao-cexat.service";
import { ListarAvisoComponent } from "./aviso/listar-aviso";
import { SalvarAvisoComponent } from "./aviso/salvar-aviso/salvar-aviso.component";
import { AvisoService } from "../api/aviso.service";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ViprocService } from "../api/viproc.service";
import { DirectivesModule } from "src/app/global/directives/directives.module";
import { NgxMaskModule } from "ngx-mask";
import { FiscalService } from "../api/fiscal.service";
import { ListarModeloComponent } from "./modelo/listar-modelo/listar-modelo.component";
import { SalvarModeloComponent } from "./modelo/salvar-modelo/salvar-modelo.component";
import { CidadaoModule } from "../seguranca/cidadao/cidadao.module";
import { CompletarCadastroComponent } from "./usuario/completar-cadastro/completar-cadastro.component";
import { ListarDocumentoMotorRegrasComponent } from './documentos-motor-regras/listar-documento-motor-regras/listar-documento-motor-regras.component';
import { SalvarDocumentoMotorRegrasComponent } from './documentos-motor-regras/salvar-documento-motor-regras/salvar-documento-motor-regras.component';
import { UiModule } from "src/app/ui/ui.module";

const routes: Routes = [
  { path: "assunto-categoria", component: ListarAssuntoCategoriaComponent, data: {
    title: "Assunto Categoria",
    breadcrumb: [
      {
        label: "Assunto Categoria",
        url: "/assunto-categoria"
      }
    ]}},
  { path: "assunto-categoria/salvar", component: SalvarAssuntoCategoriaComponent, data: {
    title: "Salvar Assunto Categoria",
    breadcrumb: [
      {
        label: "Salvar Assunto Categoria",
        url: "/assunto-categoria/salvar"
      }
    ]}},
  { path: "assunto-categoria/editar/:id", component: SalvarAssuntoCategoriaComponent, data: {
    title: "Editar Assunto Categoria",
    breadcrumb: [
      {
        label: "Editar Assunto Categoria",
        url: "/assunto-categoria/editar/:id"
      }
    ]}},
  { path: "situacao", component: ListarSituacaoComponent, data: {
    title: "Situação",
    breadcrumb: [
      {
        label: "Situação",
        url: "/situacao"
      }
    ]}},
  { path: "situacao/salvar", component: SalvarSituacaoComponent, data: {
    title: "Salvar Situação",
    breadcrumb: [
      {
        label: "Salvar Situação",
        url: "/situacao/salvar"
      }
    ]}},
  { path: "situacao/editar/:id", component: SalvarSituacaoComponent, data: {
    title: "Editar Situação",
    breadcrumb: [
      {
        label: "Editar Situação",
        url: "/situacao/editar/:id"
      }
    ]}},
  { path: "assunto", component: ListarBasicoAssuntoComponent, data: {
    title: "Assunto",
    breadcrumb: [
      {
        label: "Assunto",
        url: "/assunto"
      }
    ]}},
  { path: "assunto/salvar", component: SalvarAssuntoComponent, data: {
    title: "Salvar Assunto",
    breadcrumb: [
      {
        label: "Salvar Assunto",
        url: "/assunto/salvar"
      }
    ]}},
  { path: "assunto/editar/:id", component: SalvarAssuntoComponent, data: {
    title: "Editar Assunto",
    breadcrumb: [
      {
        label: "Editar Assunto",
        url: "/assunto/editar/:id"
      }
    ]}},
  { path: "fase", component: ListarFaseComponent, data: {
    title: "Fase",
    breadcrumb: [
      {
        label: "Fase",
        url: "/fase"
      }
    ]}},
  { path: "fase/salvar", component: SalvarFaseComponent, data: {
    title: "Salvar Fase",
    breadcrumb: [
      {
        label: "Salvar Fase",
        url: "/fase/salvar"
      }
    ]}},
  { path: "fase/editar/:id", component: SalvarFaseComponent, data: {
    title: "Editar Fase",
    breadcrumb: [
      {
        label: "Editar Fase",
        url: "/fase/editar/:id"
      }
    ]},
  },
  { path: "metadado", component: ListarMetadadoComponent, data: {
    title: "Metadado",
    breadcrumb: [
      {
        label: "Metadado",
        url: "/fase"
      }
    ]}},
  { path: "metadado/salvar", component: SalvarMetadadoComponent, data: {
    title: "Salvar Metadado",
    breadcrumb: [
      {
        label: "Salvar Metadado",
        url: "/fase/salvar"
      }
    ]}},
  { path: "metadado/editar/:id", component: SalvarMetadadoComponent, data: {
    title: "Editar Metadado",
    breadcrumb: [
      {
        label: "Editar Metadado",
        url: "/fase/editar/:id"
      }
    ]}},
  { path: "tipo-documento", component: ListarTipoDocumentoComponent, data: {
    title: "Tipo Documento",
    breadcrumb: [
      {
        label: "Tipo Documento",
        url: "/tipo-documento"
      }
    ]}},
  { path: "tipo-documento/salvar", component: SalvarTipoDocumentoComponent, data: {
    title: "Salvar Tipo Documento",
    breadcrumb: [
      {
        label: "Salvar Tipo Documento",
        url: "/tipo-documento/salvar"
      }
    ]}},
  { path: "tipo-documento/editar/:id", component: SalvarTipoDocumentoComponent, data: {
    title: "Editar Tipo Documento",
    breadcrumb: [
      {
        label: "Editar Tipo Documento",
        url: "/tipo-documento/editar/:id"
      }
    ]}},
    { path: "cadastro-home", component: CadastroHomeComponent, data: {
      title: "Cadastros",
      breadcrumb: [
        {
          label: "Cadastros",
          url: "/cadastro-home"
        }
      ]}},
  { path: "modelo", component: ListarModeloComponent, data: {
    title: "Modelo",
    breadcrumb: [
      {
        label: "Modelo",
        url: "/modelo"
      }
    ]}},
  { path: "modelo/salvar", component: SalvarModeloComponent, data: {
    title: "Salvar Modelo",
    breadcrumb: [
      {
        label: "Salvar Modelo",
        url: "/modelo/salvar"
      }
    ]}},
  { path: "modelo/editar/:id", component: SalvarModeloComponent, data: {
    title: "Editar Modelo",
    breadcrumb: [
      {
        label: "Editar Modelo",
        url: "/modelo/editar/:id"
      }
    ]}},
  { path: "cadastro-home", component: CadastroHomeComponent, data: {
    title: "Cadastros",
    breadcrumb: [
      {
        label: "Cadastros",
        url: "/cadastro-home"
      }
    ]}},
  { path: "grupo", component: ListarGrupoComponent, data: {
    title: "Grupo",
    breadcrumb: [
      {
        label: "Grupo",
        url: "/grupo"
      }
    ]}},
  { path: "grupo/salvar", component: SalvarGrupoComponent, data: {
    title: "Salvar Grupo",
    breadcrumb: [
      {
        label: "Salvar Grupo",
        url: "/grupo/salvar"
      }
    ]}},
  { path: "grupo/editar/:id", component: SalvarGrupoComponent, data: {
    title: "Editar Grupo",
    breadcrumb: [
      {
        label: "Editar Grupo",
        url: "/grupo/editar/:id"
      }
    ]}},
    { path: "email-template", component: ListarEmailTemplateComponent, data: {
      title: "E-mail Template",
      breadcrumb: [
        {
          label: "E-mail Template",
          url: "/email-template"
        }
      ]}},
    { path: "email-template/salvar", component: SalvarEmailTemplateComponent, data: {
      title: "Salvar E-mail Template",
      breadcrumb: [
        {
          label: "Salvar E-mail Template",
          url: "/email-template/salvar"
        }
      ]
    }
  },
  {
    path: "grupo/salvar", component: SalvarGrupoComponent, data: {
      title: "Salvar Grupo",
      breadcrumb: [
        {
          label: "Salvar Grupo",
          url: "/grupo/salvar"
        }
      ]
    }
  },
  {
    path: "grupo/editar/:id", component: SalvarGrupoComponent, data: {
      title: "Editar Grupo",
      breadcrumb: [
        {
          label: "Editar Grupo",
          url: "/grupo/editar/:id"
        }
      ]
    }
  },
  {
    path: "email-template", component: ListarEmailTemplateComponent, data: {
      title: "E-mail Template",
      breadcrumb: [
        {
          label: "E-mail Template",
          url: "/email-template"
        }
      ]
    }
  },
  {
    path: "email-template/salvar", component: SalvarEmailTemplateComponent, data: {
      title: "Salvar E-mail Template",
      breadcrumb: [
        {
          label: "Salvar E-mail Template",
          url: "/email-template/salvar"
        }
      ]
    }
  },
  {
    path: "email-template/editar/:id", component: SalvarEmailTemplateComponent, data: {
      title: "Editar E-mail Template",
      breadcrumb: [
        {
          label: "Editar E-mail Template",
          url: "/email-template/editar/:id"
        }
      ]}},
    { path: "email-template", component: ListarEmailTemplateComponent, data: {
      title: "E-mail Template",
      breadcrumb: [
        {
          label: "E-mail Template",
          url: "/email-template"
        }
      ]}},
    { path: "email-template/salvar", component: SalvarEmailTemplateComponent, data: {
      title: "Salvar E-mail Template",
      breadcrumb: [
        {
          label: "Salvar E-mail Template",
          url: "/email-template/salvar"
        }
      ]}},
    { path: "email-template/editar/:id", component: SalvarEmailTemplateComponent, data: {
      title: "Editar E-mail Template",
      breadcrumb: [
        {
          label: "Editar E-mail Template",
          url: "/email-template/editar/:id"
        }
      ]}},
    { path: "fiscal-preferencia", component: ListarFiscalPreferenciaComponent, data: {
      title: "Gerenciamento de Distribuição",
      breadcrumb: [
        {
          label: "Gerenciamento de Distribuição",
          url: "/fiscal-preferencia"
        }
      ]}},
    { path: "fiscal-preferencia/salvar", component: SalvarFiscalPreferenciaComponent, data: {
      title: "Salvar Limite de Distribuição",
      breadcrumb: [
        {
          label: "Salvar Limite de Distribuição",
          url: "/fiscal-preferencia/salvar"
        }
      ]}},
    { path: "fiscal-preferencia/editar/:id", component: SalvarFiscalPreferenciaComponent, data: {
      title: "Editar Limite de Distribuição",
      breadcrumb: [
        {
          label: "Editar Limite de Distribuição",
          url: "/fiscal-preferencia/editar/:id"
        }
      ]}},
    { path: "lotacao-secundaria", component: ListarLotacaoSecundariaComponent, data: {
      title: "Lotação Secundária",
      breadcrumb: [
        {
          label: "Lotação Secundária",
          url: "/lotacao-secundaria"
        }
      ]}},
    { path: "lotacao-secundaria/salvar", component: SalvarLotacaoSecundariaComponent, data: {
      title: "Salvar Lotação Secundária",
      breadcrumb: [
        {
          label: "Salvar Lotação Secundária",
          url: "/lotacao-secundaria/salvar"
        }
      ]}},
    { path: "lotacao-secundaria/editar/:id", component: SalvarLotacaoSecundariaComponent, data: {
      title: "Editar Lotação Secundária",
      breadcrumb: [
        {
          label: "Editar Lotação Secundária",
          url: "/lotacao-secundaria/editar/:id"
        }
      ]
    }
  },
  {
    path: "orgao", component: ListarOrgaoComponent, data: {
      title: "Orgão",
      breadcrumb: [
        {
          label: "Órgão",
          url: "/orgao"
        }
      ]
    }
  },
  {
    path: "orgao/salvar", component: SalvarOrgaoComponent, data: {
      title: "Salvar Orgão",
      breadcrumb: [
        {
          label: "Salvar Órgão",
          url: "/orgao/salvar"
        }
      ]
    }
  },
  {
    path: "orgao/editar/:id", component: SalvarOrgaoComponent, data: {
      title: "Editar Orgão",
      breadcrumb: [
        {
          label: "Editar Órgão",
          url: "/orgao/editar/:id"
        }
      ]
    }
  },
  {
    path: "aviso", component: ListarAvisoComponent, data: {
      title: "Aviso",
      breadcrumb: [
        {
          label: "Aviso",
          url: "/aviso"
        }
      ]}},
    { path: "aviso/salvar", component: SalvarAvisoComponent, data: {
      title: "Salvar Aviso",
      breadcrumb: [
        {
          label: "Salvar Aviso",
          url: "/aviso/salvar"
        }
      ]}},
    { path: "aviso/editar/:id", component: SalvarAvisoComponent, data: {
      title: "Editar Aviso",
      breadcrumb: [
        {
          label: "Editar Aviso",
          url: "/aviso/editar/:id"
        }
      ]}},
    { path: "documento-motor-regras", component: ListarDocumentoMotorRegrasComponent, data: {
      title: "Documento Motor Regras",
      breadcrumb: [
        {
          label: "Documento Motor Regras",
          url: "/documento-motor-regras"
        }
      ]}},
    { path: "documento-motor-regras/salvar", component: SalvarAvisoComponent, data: {
      title: "Salvar Documento Motor Regras",
      breadcrumb: [
        {
          label: "Salvar Documento Motor Regras",
          url: "/documento-motor-regras/salvar"
        }
      ]}},
    { path: "documento-motor-regras/editar/:id", component: SalvarAvisoComponent, data: {
      title: "Editar Documento Motor Regras",
      breadcrumb: [
        {
          label: "Editar Documento Motor Regras",
          url: "/documento-motor-regras/editar/:id"
        }
    ]}},
];

@NgModule({
  declarations: [
    ListarAssuntoCategoriaComponent, SalvarAssuntoCategoriaComponent, ListarBasicoAssuntoComponent, SalvarAssuntoComponent,
    ListarSituacaoComponent, SalvarSituacaoComponent, ListarFaseComponent, SalvarFaseComponent,
    ListarMetadadoComponent, SalvarMetadadoComponent, ListarTipoDocumentoComponent, SalvarTipoDocumentoComponent,
    CadastroHomeComponent, ListarGrupoComponent, SalvarGrupoComponent, ListarEmailTemplateComponent,
    SalvarEmailTemplateComponent, ListarFiscalPreferenciaComponent, SalvarFiscalPreferenciaComponent,
    ListarLotacaoSecundariaComponent, SalvarLotacaoSecundariaComponent, ListarOrgaoComponent,
    SalvarOrgaoComponent, ListarAvisoComponent, SalvarAvisoComponent, ListarModeloComponent, SalvarModeloComponent,
    CompletarCadastroComponent, ListarDocumentoMotorRegrasComponent, SalvarDocumentoMotorRegrasComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PopoverModule,
    CKEditorModule,
    CidadaoModule,
    UiModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    DirectivesModule
  ],
  providers: [CategoriaAssuntoService, ToastrService, AssuntoService, OrgaoService, SituacaoService,
    FaseService, MetadadoService, TipoDocumentoService, GrupoService, EmailTemplateService, FiscalPreferenciaService,
    LotacaoSecundariaService, LotacaoCexatService, AvisoService, ViprocService, FiscalService]
})
export class CadastroModule { }
