import { Component, OnInit, OnChanges, Input, ViewChild, SimpleChanges } from "@angular/core";
import { EventoDto } from "../../models/evento-dto.model";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { EventoService } from "../../api/evento.service";
import { SituacaoItemNaoSimilaridadeEnumAll } from "src/app/core/models/situacao-item-nao-similaridade-enum.enum";
import { AcaoEventoItemNaoSimilaridadeEnumAll } from "src/app/core/models/acao-evento-item-nao-similaridade-enum.enum";
import { Processo } from "../../models/processo.model";


@Component({
  selector: "app-item-nao-similaridade-evento",
  templateUrl: "./item-nao-similaridade-evento.component.html",
  styleUrls: ["./item-nao-similaridade-evento.component.css"]
})
export class ItemNaoSimilaridadeEventoComponent implements OnInit, OnChanges {

  @Input() processo: Processo;
  @Input() atualizarEventos: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["eventos"];
  dataSource = new MatTableDataSource<EventoDto>();
  public listSituacaoItemNaoSimilaridade: any[] = SituacaoItemNaoSimilaridadeEnumAll;
  public listAcaoEventoItemNaoSimilaridade: any[] = AcaoEventoItemNaoSimilaridadeEnumAll;

  constructor(
    private eventoService: EventoService
  ) { }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<EventoDto>();
    this.pesquisar();
  }

  ngOnInit() {
  }

  private pesquisar() {
    this.dataSource.data = [];
    this.eventoService.getEventosItemNaoSimilaridade(this.processo).subscribe((eventos) => {
      if (eventos != null) {
        this.dataSource = new MatTableDataSource<EventoDto>(eventos);
        this.dataSource.paginator = this.paginator;
      }
    });
    this.dataSource._updateChangeSubscription;
  }

  findEnumSituacaoItemNaoSimilaridade(valor: any): string {
    return this.listSituacaoItemNaoSimilaridade[valor].descricao;
  }

  findEnumAcaoEvendoItemNaoSimilaridade(valor: any): string {
    let id = 1;
    if (valor === "ADD") {
      id = 0;
    } else if (valor === "MOD") {
      id = 1;
    } else if (valor === "DEL") {
      id = 2;
    }
    return this.listAcaoEventoItemNaoSimilaridade[id].descricao;
  }

}
