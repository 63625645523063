import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListarCidadaoComponent } from "./listar-cidadao/listar-cidadao.component";
import { SalvarCidadaoComponent } from "./salvar-cidadao/salvar-cidadao.component";

const routes: Routes = [
  {
    path: "usuario",
    component: ListarCidadaoComponent,
    data: {
      title: "Usuário",
      breadcrumb: [
        {
          label: "Usuário",
          url: "/usuario",
        },
      ],
    },
  },
  {
    path: "usuario/salvar",
    component: SalvarCidadaoComponent,
    data: {
      title: "Salvar Usuário",
      breadcrumb: [
        {
          label: "Salvar Usuário",
          url: "/usuario/salvar",
        },
      ],
    },
  },
  {
    path: "usuario/editar/:id",
    component: SalvarCidadaoComponent,
    data: {
      title: "Editar Usuário",
      breadcrumb: [
        {
          label: "Editar Usuário",
          url: "/usuario/editar/:id",
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CidadaoRoutingModule {}
