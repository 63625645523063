import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { catchError, map } from "rxjs/operators";
import { Pessoa } from "../models/pessoa.model";

@Injectable({ providedIn: "root" })
export class SisSegService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "sisseg", config);
    this.API_URL = config.API_SISSEG_URL;
  }
  
  listServidores(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/lista-servidores`).pipe(map(response => response));
  }

  listServidoresPorOrgao(orgao: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/usuario/orgao/${orgao}`).pipe(map(response => response));
  }

  consultarServidorByDocumento(documento: string): Observable<Pessoa> {
    return this.http.get(`${this.API_URL}${this.resource}/servidor/${documento}`).pipe(map((response: Pessoa) => response));
  }

  pesquisarServidorPorNome(nome: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/lista-servidores/${nome}`).pipe(map(response => response));
  }

  salvarUsuario(data: any) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
  
    return this.http.post(`${this.API_URL}${this.resource}/usuario`, data, { headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw new Error("Erro ao salvar usuário.");
      })
    );
  }

}
