import { Component, OnInit, ViewChild, Output, ElementRef,NgZone } from "@angular/core";
import { Location } from "@angular/common";
import { NgForm } from "@angular/forms";
import { MatDialog, MatStepper } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Utils } from "src/app/global/util/utils-component";
import { LacunaWebPKI } from "web-pki";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { AssuntoService } from "../../api/assunto.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { DocumentoService } from "../../api/documento.service";
import { ResponsavelService } from "../../api/responsavel.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { Documento } from "../../models/documento.model";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { Processo } from "../../models/processo.model";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { DadosDae } from "../../models/dados-dae.model";
import { AssuntoMetadado } from "../../models/assunto-metadado.model";
import { LotacaoCexatService } from "src/app/core/api/lotacao-cexat.service";
import { Metadado } from "src/app/global/models/metadado.model";
import { DaeService } from "../../api/dae.service";
import { LotacaoCexat } from "src/app/core/models/lotacao-cexat.model";
import { TelaEnumAll, Tela } from "../../models/tela.enum";
import { AvisoService } from "../../api/aviso.service";
import { DialogItenNaoSimilaridadeComponent } from "../dialog-itens-nao-similaridade/dialog-itens-nao-similaridade.component";
import { TipoDocumentoSistema } from "../../models/tipo-documento-sistema.enum";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { TipoExibicao, TipoExibicaooEnumAll } from "../../models/tipo-exibicao.enum";
import { TipoMetadadoEnum } from "../../models/tipo-metadado.enum";
import { DialogAvisoComponent } from "../dialog-aviso/dialog-aviso.component";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { Certificate } from "../../../global/models/certificate.model";
import { ItemNaoSimilaridade } from "../../models/item-nao-similaridade.model";
import { OrgaoService } from "../../api/orgao.service";

@Component({
  selector: "app-salvar-processo",
  templateUrl: "./salvar-processo.component.html",
  styleUrls: ["./salvar-processo.component.css"]
})
export class SalvarProcessoComponent implements OnInit {

  @ViewChild(NgForm) form: NgForm;
  @ViewChild("descricao") descricao;
  @ViewChild("arquivoXls") arquivoXls: ElementRef;

  selectedIndex = 0;

  public assunto: any;
  public tipoDocumento: TipoDocumento;
  public responsavel: any;
  private routerAnexoAssunto = environment.CONTEXTO +  "/anexo-processo";
  public documentos: Documento[] = [];
  public processo: Processo;
  public documentosEnvio: DocumentoDTO[] = [];
  @Output() docDAEgerado: Documento;
  @Output() xlsNaoSimilaridade: Documento;
  private fatorMultiplicadorPadrao = 1;
  private listItensNaoSimilaridade: ItemNaoSimilaridade[];
  public assuntoMetadado: AssuntoMetadado;

  conteudoCkEditor: string;
  ckeConfig: any;
  @ViewChild("myckeditor") ckeditor: any;


  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  public informarCexatNuat = false;
  public lotacoesCexats: any[];

  public infoAssuntoAutor = new OpcaoAssuntoInterno();

  constructor(protected route: ActivatedRoute,
    public router: Router,
    public assuntoService: AssuntoService,
    public responsavelService: ResponsavelService,
    public tipoDocumentoService: TipoDocumentoService,
    public documentoService: DocumentoService,
    // public receitaService: ReceitaService,
    private messageService: MessageService,
    private location: Location,
    private contribuinteService: ContribuinteService,
    private lotacaoCexatService: LotacaoCexatService,
    private orgaoService: OrgaoService,
    private daeService: DaeService,
    private avisoService: AvisoService,
    private dialog: MatDialog,
    private sharedService: SharedService) {
      this.conteudoCkEditor = "";
  }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    this.processo = new Processo();

    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language : "pt",
      font_names: "Arial;Times New Roman;Verdana",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] }
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons: "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About"
    };

    this.infoAssuntoAutor.tipo = this.route.snapshot.params.tipoAutor;
    this.infoAssuntoAutor.infoAutor = this.route.snapshot.params.infoAutor;
    this.infoAssuntoAutor.infoAssinador = this.route.snapshot.params.infoAssinador;

    this.getAssunto(id);
  }

  // FIXME Testar onde é usado esse metodo.
  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  private checkAvisos() {
    const tela = TelaEnumAll.filter(tela => tela.descricao === Tela.ABERTURA).map(tela => tela.id)[0];
    let tipoExibicao: number;

    if (JSON.parse(this.sharedService.getDocumentoAstra())) {
      tipoExibicao = TipoExibicaooEnumAll.filter(te => te.descricao === TipoExibicao.PUBLICO_INTERNO).map(te => te.id)[0];
    } else {
      tipoExibicao = TipoExibicaooEnumAll.filter(te => te.descricao === TipoExibicao.PUBLICO_EXTERNO).map(te => te.id)[0];
    }

    this.avisoService.listarAvisoAtualPorTelaTipoExibicao(tela, tipoExibicao).subscribe(
      (res) => {
        if (res.length > 0) {
          const dialogRef = this.dialog.open(DialogAvisoComponent, {
            maxWidth: "600px",
            data: res
          });
        }
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public getAssunto(id: number): void {
    this.assuntoService.get(id).subscribe(
      res => {
        this.assunto = res;
        this.validarProcessoCexat();
        this.carregarTiposDocumentos(res.listTipoDocumento);
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

  }

  carregarCampoCexat() {
    this.lotacaoCexatService.list().subscribe(
      cexats => {
        this.orgaoService.getOrgaoLocal().subscribe(
          lotacoes => {
            cexats.forEach(cexat => {
              lotacoes.forEach(lotacao => {
                if (String(lotacao.id) == String(cexat.lotacao)) {
                  if (cexat.usuarioResponsavel != null) {
                    lotacao.responsavel = cexat.usuarioResponsavel;
                  }
                  this.lotacoesCexats.push(lotacao);
                }
              });
            });

            this.lotacoesCexats.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0));
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  validarProcessoCexat() {
    if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE) {
        const autor = this.infoAssuntoAutor.infoAutor;
        if (this.assunto.tipoDistribuicao == 3) { //"CEXAT_NUAT"
        this.informarCexatNuat = true;
        this.lotacoesCexats = [];
          this.contribuinteService.pesquisarCadastroContribuinte(autor).subscribe(
            contribuinte => {
              if (contribuinte != null) {
                if (contribuinte.orgaoLocal != null && contribuinte.orgaoLocal.id != null) {
                  this.processo.lotacaoCexatNuat = contribuinte.orgaoLocal.id;
                  this.informarCexatNuat = false;
                  this.lotacaoCexatService.pesquisarUsuarioPorLotacao(contribuinte.orgaoLocal.id).subscribe(
                    usuario => {
                      if (usuario != null) {
                        this.processo.responsavelAtual = usuario.documento;
                      }
                    },
                    error => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    }
                  );
                }
              }
            },
            error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
          this.carregarCampoCexat();
        }
    }
  }

  selecionarCexat(lotacao: any) {
    this.processo.responsavelAtual = lotacao.responsavel.documento;
    this.processo.lotacaoCexatNuat = lotacao.id;
  }

  carrregarAnexoAssunto(id: number) {
    this.router.navigate([`${this.routerAnexoAssunto}/${id}`]);
  }

  carregarTiposDocumentos(listTipoDocumento: any) {
    const tipoDocumentoIds = listTipoDocumento.map(function(tipoDocumento) {
      return tipoDocumento.tipoDocumento;
    });

    this.tipoDocumentoService.getByListTipoDocumentoId(tipoDocumentoIds).subscribe(
        res => {
          res.forEach(tipoDocumento => {
                // Se for anexo e modelo documento vazio
                if (tipoDocumento.somenteAnexo && tipoDocumento.modeloDocumento === null) {
                  const documento: Documento = new Documento();
                  documento.tipoDocumento = tipoDocumento;
                  this.documentos.push(documento);
                } else {
                  this.tipoDocumento = tipoDocumento;
                  this.conteudoCkEditor = tipoDocumento.modeloDocumento;
                }
            });
        },
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  recuperarDocumentosEnvio($event) {
    this.documentosEnvio = this.documentosEnvio.concat($event);
  }

  removerDocumentoEnvio($event) {
    this.documentosEnvio = this.documentosEnvio.filter(doc => doc.id !== $event);
  }

  isValidDate(d: string) {
    var date = new Date(d.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"))
    return !isNaN(date.getTime()) || date <= new Date('01/01/1753') ? true : false;
  }

  validar(): boolean {
    let validacaoMetadado: boolean;
    if (this.processo.descricao === "" || this.processo.descricao === undefined) {
      this.messageService.messageErro("Preencha o campo descrição");
      return false;
    }

    if ((this.informarCexatNuat) && (this.processo.lotacaoCexatNuat === undefined)) {
      this.messageService.messageErro("Preencha o campo CEXAT/NUAT");
      return false;
    }

    if (this.assunto.naoSimilaridade && !this.processo.qtdItensNaoSimilaridade) {
      this.messageService.messageErro("Necessário inserir planilha com itens.");
      return false;
    }

    this.assunto.listMetadado.forEach((assuntoMetadado) => {
      if (assuntoMetadado.requerido && !assuntoMetadado.metadado.valor) {
        this.messageService.messageErro("Preencha o campo " + assuntoMetadado.metadado.nome);
        validacaoMetadado = true;
      }

      if ( (assuntoMetadado.requerido && assuntoMetadado.metadado.valor) ||
        (!assuntoMetadado.requerido && assuntoMetadado.metadado.valor) ) {
          if ( (assuntoMetadado.metadado.mascara != null) && 
            (assuntoMetadado.metadado.tipo !== TipoMetadadoEnum.APENAS_NUMERO) &&
            (assuntoMetadado.metadado.tipo !== TipoMetadadoEnum.MONETARIO) &&
            (assuntoMetadado.metadado.tipo !== TipoMetadadoEnum.BOTAO) &&
            (assuntoMetadado.metadado.validarQuantidadeCaracteres && assuntoMetadado.metadado.valor.length !== assuntoMetadado.metadado.mascara.length) ) {
              this.messageService.messageErro("Formato do campo '" + assuntoMetadado.metadado.nome + "' inválido");
              validacaoMetadado = true;
            }

            if (
              (assuntoMetadado.metadado.mascara != null) && 
              (assuntoMetadado.metadado.tipo === TipoMetadadoEnum.APENAS_NUMERO) &&
              (assuntoMetadado.metadado.validarQuantidadeCaracteres && assuntoMetadado.metadado.valor.length > assuntoMetadado.metadado.mascara.length) ) {
                this.messageService.messageErro("Formato do campo '" + assuntoMetadado.metadado.nome + "' inválido");
                validacaoMetadado = true;
            }
      }

      if (assuntoMetadado.metadado.tipo == TipoMetadadoEnum.DATA
        && assuntoMetadado.metadado.valor !== undefined
        && !this.isValidDate(assuntoMetadado.metadado.valor)) {
          this.messageService.messageErro("Formato do campo '" + assuntoMetadado.metadado.nome + "' inválido");
          validacaoMetadado = true;
      }
    });

    if (this.isBlank(this.conteudoCkEditor)) {
      this.messageService.messageErro("Preencha o conteúdo no editor");
      return false;
    } else {
      this.processo.modeloAssunto = this.conteudoCkEditor;
    }

    if (validacaoMetadado) {
      return false;
    }
    return true;
  }

  validarMetadado(metadado: Metadado): boolean {
    if (metadado.valor !== undefined) {
      if (metadado.validarQuantidadeCaracteres && metadado.valor.length !== metadado.mascara.length) {
        this.messageService.messageErro("Preencha o campo " + metadado.nome + " conforme a máscara " + metadado.mascara + ".");
        return false;
      } else {
        if (metadado.acao === "nossoNumero") {
          this.isNumberNossoNumeroJaUtilizado(metadado.valor).then(jaUtilizado => {
            if (jaUtilizado) {
              this.messageService.messageErro("Campo " + metadado.nome + " já cadastrado.");
            }
            return jaUtilizado;
          }).catch(error => console.error(error));
        }
      }
    } else {
      this.messageService.messageErro("Preencha o campo " + metadado.nome);
      return false;
    }

    return false;
  }

  async isNumberNossoNumeroJaUtilizado(nossoNumero: string) {
    const responseBoolean = await this.daeService.verificarNossoNumeroUtilizado(nossoNumero).toPromise();
    return responseBoolean;
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  goForward(stepper: MatStepper) {
    if (this.validar()) {
      stepper.next();
    } else {
      stepper.selectedIndex = 0;
      stepper.previous();
    }
  }

  voltar(): void {
    this.location.back();
  }

  // Metodo que registra o metodo que será passado como parametro para o componente filho.
  get funcaoParaFilho() {
    return this.acaoMetadado.bind(this);
  }

  // Metodo que vai receber a ação do componente filho.
  acaoMetadado(metadado: Metadado): void {
    if (metadado.acao === "gerarDae") { // Ação gravada no metadado botao
      this.gerarDae();
    } else {
      if (metadado.acao === "nossoNumero") { // Ação gravada no metadado
        if (metadado.valor === "") {
          this.docDAEgerado = null;
        }
      }
    }
  }

  gerarDae(): void {
    const dadosDae = new DadosDae;
    dadosDae.codigoProduto = this.assunto.codigoProduto;
    dadosDae.codigoReceita = this.assunto.codigoReceita;
    dadosDae.cpfcnpj = this.infoAssuntoAutor.infoAutor;
    dadosDae.docAssinantes = this.infoAssuntoAutor.infoAssinador;
    if (this.assunto.fatorMultiplicador != null) {
      // FIXME Campo deve está preenchido obrigatoriamente
      const metadado = this.assunto.listMetadado.filter(
        assuntoMetadado => assuntoMetadado.metadado.id === this.assunto.fatorMultiplicador.id
      )[0].metadado;
      dadosDae.fatorMultiplicador = +metadado.valor;
      if (!dadosDae.fatorMultiplicador) {
        // tslint:disable-next-line: max-line-length
        this.messageService.messageErro("O fator multiplicador (".concat(this.assunto.fatorMultiplicador.titulo).concat(") é obrigatório."));
        return;
      }
    } else {
      if (this.assunto.naoSimilaridade) {
        if (this.processo.qtdItensNaoSimilaridade) {
          dadosDae.fatorMultiplicador = this.processo.qtdItensNaoSimilaridade;
        } else {
          this.messageService.messageErro("Necessário preencher a quantidade de itens.");
          return;
        }
      } else {
        dadosDae.fatorMultiplicador = this.fatorMultiplicadorPadrao;
      }
    }

    this.tipoDocumentoService.getTipoDocumentoDaeId().subscribe(
      tipoDocumentoDaeId => {
        dadosDae.tipoDocumento = tipoDocumentoDaeId;

        // Gerar DAE sem assina-lo
        if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO) {
          const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
          this.executarGeracaoDAE(dadosDae, tipoDocumentoDaeId, cert);
        } else {
          if (JSON.parse(this.sharedService.getDocumentoAstra()) !== this.infoAssuntoAutor.infoAssinador) {
          //   this.messageService.messageErro("Implementar geração de dae.");
          //   this.receitaService.gerarDaeSemAssinatura(dadosDae).subscribe((documento: Documento) => {
          //   documento.descricao = "DAE";
          //   this.atualizarMetadadosDAE(documento.nossoNumero);
          //   this.docDAEgerado = documento;
          //   this.messageService.messageSuccess("DAE gerado com sucesso. Faça download na aba anexos ou resumo ou após criar o processo.");

          // }, error => {
          //   this.messageService.messageErro(error.error.message);
          //   throw error;
          // });
          } else {
            this.openDialogSelecionarCertificado(dadosDae, tipoDocumentoDaeId);
          }
        }
      },
      error => { // Erro ao buscar o tipo de documento DAE
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  openDialogSelecionarCertificado(dadosDae: DadosDae, tipoDocumentoDaeId: number): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: ""
    });

    openDialogSelecionarCertificado.afterClosed().subscribe(certParam => {
      if (certParam != null && certParam.cert64 != null) {
        try {
          this.executarGeracaoDAE(dadosDae, tipoDocumentoDaeId, certParam);
        } catch {
          this.messageService.messageErro(`Erro gerar DAE`);
        }
      }
    }, error => {
      this.messageService.messageErro(`Erro gerar DAE`);
    });
  }

  private executarGeracaoDAE(dadosDae: DadosDae, tipoDocumentoDaeId: number, cert: Certificate) {
    dadosDae.certContent = cert.cert64;
    // this.messageService.messageErro("Implementar geração de dae.");
    // this.receitaService.gerarDae(dadosDae).subscribe(
    //   res => {
    //     this.atualizarMetadadosDAE(res.documentoIdentificador);

    //     const autor = this.infoAssuntoAutor.infoAssinador;

    //     this.pki.signHash({
    //       thumbprint: cert.thumbprint,
    //       hash: res.toSignHash,
    //       digestAlgorithm: res.digestAlgorithm
    //     }).success(signature => {
    //       this.documentoService.complete(res.fileName, res.transferFileId, signature, "DAE",
    //         "", tipoDocumentoDaeId,
    //         autor, "DAE".concat(res.documentoIdentificador).concat(".pdf"),
    //         res.descricaoAssinatura,
    //         res.hashDocumento,
    //         null,
    //         null,
    //         SharedService.CARREGAR_LOADING).subscribe(documento => {
    //           this.docDAEgerado = documento;
    //           // tslint:disable-next-line: max-line-length
    //           this.messageService.messageSuccess("DAE gerado com sucesso. Faça download na aba anexos ou resumo ou após criar o processo.");
    //         },
    //           error => {
    //             this.messageService.messageErro(error.error.message);
    //             throw error;
    //           });
    //     }).fail(fail => {
    //       this.docDAEgerado = null;
    //       this.atualizarMetadadosDAE("");
    //       this.messageService.messageInfo(fail.userMessage);
    //     });
    //   },
    //   error => {
    //     this.messageService.messageErro(error.error.message);
    //     throw error;
    //   }
    // );
  }

  atualizarMetadadosDAE(nossoNumero: String) {
    const documentoIdentificador = nossoNumero;
    this.assunto.listMetadado.forEach(assuntoMetadado => {
      // Nome do metadado (gravado no banco) que receberá a informação do gerarDAE
      if (assuntoMetadado.metadado.nome === "Nosso Número") {
        assuntoMetadado.metadado.valor = documentoIdentificador;
      } else if (assuntoMetadado.metadado.nome === "Gerar DAE") {
        if (nossoNumero === "") {
          assuntoMetadado.metadado.disabled = false;
        } else {
          assuntoMetadado.metadado.disabled = true;
        }
      }
    });
  }

  downloadDocumento(id: number) {
    if (id > 0) {
      this.documentoService.download(id).subscribe(blob => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Documento ainda não existe.");
        }
      });
    } else {
      this.messageService.messageWarning("Documento ainda não existe.");
    }
  }

  uploadFile(event) {
    this.processo.qtdItensNaoSimilaridade = null;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
        const formData: FormData = new FormData();
        const file: File = fileList[0];
        formData.append("file", file);
        formData.append("fileName", file.name);
        this.documentoService.validaXlsNaoSimilaridade(formData).subscribe(res => {
          if (res && res.length > 0) {
            const dialogRef = this.dialog.open(DialogItenNaoSimilaridadeComponent, {
              width: "800px",
              data: res
            });
            dialogRef.afterClosed().subscribe(processoParam => {
              if (processoParam) {
                this.listItensNaoSimilaridade = res;
                this.processo.qtdItensNaoSimilaridade = res.length;
                this.tipoDocumentoService.pesquisarTipoDocumentoSistema(TipoDocumentoSistema.OUTROS).subscribe((res) =>{
                  const formData: FormData = new FormData();
                  formData.append("file", file);
                  formData.append("fileName", file.name);
                  formData.append("descricao", "Itens de não similaridade");
                  formData.append("descricaoAssunto", this.assunto.descricao);
                  formData.append("tipoDocumentoId", res.id);
                  formData.append("docAutor",  JSON.parse(this.sharedService.getDocumentoRepresentado()));
                  formData.append("downloadUsuarioExterno", "true");
                  formData.append("isPedido", "false");
                  this.documentoService.salvarDocumentoNaoPDF(formData).subscribe((res) =>{
                    this.xlsNaoSimilaridade = res;
                  }, erro => {
                    this.processo.qtdItensNaoSimilaridade = null;
                    this.messageService.messageErro("Erro ao salvar planilha.");
                  });
                });
              }
            });
          } else {
            this.messageService.messageWarning("Planilha vazia.");
          }
        }, erro => {
          this.messageService.messageErro(erro.error.message);
        });
        this.arquivoXls.nativeElement.value = "";
    }
  }
}


