import { Domain } from "../../global/models/domain.model";
import { Contribuinte } from "./contribuinte.model";

export class Responsavel extends Domain {
  public nome: string;
  public cpfCnpj: string;
  public cgf: string;
  public domicilio: string;
  public endereco: string;
  public enderecoCompleto: string;
  public telefone: string;
  public email: string;
  public matricula: string;
  public documento: string;
  public assinadorFavorito: boolean;

  public static contribuinteToResponsavel(contribuinte: Contribuinte): Responsavel {
    const responsavel = new Responsavel();
    responsavel.documento = contribuinte.documento;
    responsavel.matricula = contribuinte.documento;
    responsavel.nome = contribuinte.nome;
    responsavel.telefone = contribuinte.telefone;
    responsavel.email = contribuinte.email;
    responsavel.endereco = contribuinte.logradouro; // FIXME Verificar concordancia
    responsavel.enderecoCompleto = contribuinte.enderecoCompleto; 

    return responsavel;

  }
}