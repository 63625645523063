import { Component, OnInit, Input } from "@angular/core";
import { TipoArquivamentoEnumAll } from "../../models/tipo-arquivamento-enum.model";
import { ContribuinteService } from "../../api/contribuinte.service";
import { MessageService } from "src/app/global/message/message.service";
import { Processo } from "../../models/processo.model";
import { SolicitanteService } from "../../api/solicitante.service";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { ProcessoService } from "../../api/processo.service";
import { ProcessoPensaoDTO } from "../../models/pensao/processo-pensao-dto.model";

@Component({
  selector: "app-detalhe-processo",
  templateUrl: "./detalhe-processo.component.html",
  styleUrls: ["./detalhe-processo.component.css"],
})
export class DetalheProcessoComponent implements OnInit {
  @Input() processo: Processo;
  public tipoArquivamentoList: any[] = TipoArquivamentoEnumAll;
  public solicitanteProcesso: Solicitante;
  public enderecoLinha1: String;
  public enderecoLinha2: String;

  constructor(
    private contribuinteService: ContribuinteService,
    private solicitanteService: SolicitanteService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.solicitanteService.pesquisarPorProcesso(this.processo.id).subscribe((solicitante) => {
      this.enderecoSolicitante(solicitante);
    });
  }

  private enderecoSolicitante(solicitante: Solicitante) {
    this.enderecoLinha1 = `${solicitante.endereco.logradouro}, nº ${solicitante.endereco.numero}, ${solicitante.endereco.complemento}`;
    this.enderecoLinha2 = `${solicitante.endereco.bairro}, ${solicitante.endereco.cidade}-${solicitante.endereco.uf}, ${solicitante.endereco.cep}`;
  }
}
