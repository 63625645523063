import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MessageService } from "../../message/message.service";

@Component({
  selector: "app-dialog-input",
  templateUrl: "./dialog-input.component.html",
  styleUrls: ["./dialog-input.component.css"]
})
export class DialogInputComponent implements OnInit {
  public input: string;

  // TODO deixar o 'maxlength' dinamico.

  constructor(
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService) {
  }

  ngOnInit() {
  }

  onConfirm(): void {
    if (this.input != null) {
      this.dialogRef.close(this.input);
    } else {
      this.messageService.messageInfo("Escreva a justificativa.");
    }
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}
