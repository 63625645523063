import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Situacao } from "src/app/core/models/situacao.model";
import { SituacaoService } from "src/app/core/api/situacao.service";
import { EmailTemplate } from "src/app/core/models/email-template.model";
import { EmailTemplateService } from "src/app/core/api/email-template.service";
import { SentidoEmailEnumAll } from "src/app/core/models/sentido-email-enum.model";
import { TipoEmailEnumAll } from "src/app/core/models/tipo-email-enum.model";
import { MessageService } from "src/app/global/message/message.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-email-template",
  templateUrl: "./listar-email-template.component.html",
  styleUrls: ["./listar-email-template.component.css"]
})
export class ListarEmailTemplateComponent extends ListarGenericsComponent<EmailTemplate> {
  public dataSource: MatTableDataSource<EmailTemplate>;
  public listSentido: any[] = SentidoEmailEnumAll;
  public listTipoEmail: any[] = TipoEmailEnumAll;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public apiService:  EmailTemplateService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService,  toastr, router, "email-template",
      ["descricao", "tipo", "sentido", "removerOpcao"]);
  }

  findEnumSentido(sentido: any): string {
    return this.listSentido.filter(sent => sent.id === sentido)[0].descricao;
  }

  findEnumTipoEmail(tipo: any): string {
    return this.listTipoEmail.filter(tipoEmail => tipoEmail.id === tipo)[0].descricao;
  }

}
