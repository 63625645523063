import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatTableDataSource } from "@angular/material";
import { PesquisaDaeRequest } from "src/app/core/models/pesquisa-dae-request";
import { SharedService } from "src/app/global/api/shared.service";
import { DialogInputComponent } from "src/app/global/dialogs/dialog-input/dialog-input.component";
import { MessageService } from "src/app/global/message/message.service";
import { DaeService } from "../../api/dae.service";
import { DaeAtualizarStatus } from "../../models/dae-atualizar-status.model";
import { StatusDAE, StatusDaeEnumAll, StatusDAEFunctions } from "../../models/status-dae.enum";
import { DaeDto } from "../../models/dae-dto.model";



@Component({
  selector: "app-listar-dae",
  templateUrl: "./listar-dae.component.html",
  styleUrls: ["./listar-dae.component.css"]
})
export class ListarDaeComponent implements OnInit {

  public PAGE_SIZE = SharedService.PAGE_SIZE;

  public pesquisarDaeRequest = new PesquisaDaeRequest();
  public statusDaeEnumAll: any[] = StatusDaeEnumAll;
  public daes: DaeDto[] = [];

  public maskDae = "0000.00.0000000-00"; // FIXME Pesquisar a máscara desse campo ???

  public dataSource: MatTableDataSource<DaeDto>;
  @ViewChild(MatPaginator, { read: false }) paginator: MatPaginator;
  public resultsLength = 0;
  public hiddenResults = true;

  public highlighteRows: any[] = [];

  public displayedColumns: string[] = ["numeroProcesso", "razaoSocial", "nossoNumero", "status", "informacoes", "acao"];

  constructor(private messageService: MessageService,
    private daeService: DaeService,
    public dialog: MatDialog) {

    this.dataSource = new MatTableDataSource<DaeDto>([]);
  }

  ngOnInit() {
    this.pesquisar();
  }

  limparBusca() {
    this.pesquisarDaeRequest = new PesquisaDaeRequest();
  }

  setNullOnEmptyInPesquisarDaeRequest() {
    this.pesquisarDaeRequest.nossoNumero = this.pesquisarDaeRequest.nossoNumero === "" ? null : this.pesquisarDaeRequest.nossoNumero;
    // tslint:disable-next-line: max-line-length
    this.pesquisarDaeRequest.numeroProcesso = this.pesquisarDaeRequest.numeroProcesso === "" ? null : this.pesquisarDaeRequest.numeroProcesso;
  }
  public pageEvent(event) {
    this.pesquisar(event.pageIndex, event.pageSize);
  }

  pesquisar(page: number = 0, size: number = SharedService.PAGE_SIZE) {
    this.setNullOnEmptyInPesquisarDaeRequest();
    if (page === 0) {
      if (this.paginator != null) {
        this.paginator.pageIndex = 0;
      }
    }

    this.daeService.pesquisarDae(this.pesquisarDaeRequest, page, size).subscribe(
      daes => {
        this.daes = daes.content;
        this.dataSource.data = daes.content;
        this.resultsLength = daes.totalElements;

        if (daes.totalElements === 0) {
          this.messageService.messageInfo("Nenhum dae encontrado!");
          this.hiddenResults = true;
        } else {
          this.hiddenResults = false;
        }
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        this.hiddenResults = true;
        throw error;
      }
    );
  }

  alterarStatus(dae: DaeDto) {
    let status = StatusDAE.UTILIZADO;
    let message = "Qual a justificativa para a bloquear?";

    if (dae.status === StatusDAEFunctions.getIndice(status)) {
      status = StatusDAE.LIBERADO;
      message = "Qual a justificativa para a liberar?";
    }

    const dialogRef = this.dialog.open(DialogInputComponent, {
      maxWidth: "450px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult != null) {
        const daeAtualizarStatus = new DaeAtualizarStatus();
        daeAtualizarStatus.status = StatusDAEFunctions.getIndice(status);
        daeAtualizarStatus.justificativa = dialogResult;
        this.atualizarStatusDae(dae, daeAtualizarStatus);
      }
    });
  }

  private atualizarStatusDae(dae: DaeDto, daeAtualizarStatus: DaeAtualizarStatus) {
    this.daeService.atualizarStatusDae(dae.id, daeAtualizarStatus).subscribe(
      daeResponse => {
        this.messageService.messageSuccess("Dae alterado com sucesso!");

        this.dataSource.data = this.dataSource.data.map(daeDataSource => {
          if (daeDataSource.id === daeResponse.id) {
            daeDataSource = daeResponse;
          }
          return daeDataSource;
        });
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
