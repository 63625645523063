import { elementStyleProp } from "@angular/core/src/render3";

export enum TipoSolicitante {
  REPRESENTANTE = "Representante",
  CURADOR = "Curador",
  TUTOR = "Tutor",
  PROCURADOR = "Procurador",
  MAE = "mae",
  PAI = "pai",
  RESPONSAVEL = "Responsável Legal",
  DEPENDENTE = "Dependente",
  FILHO_MENOR = "Filho Menor",
  IRMAO_INVALIDO_OU_DEFICIENTE = "Irmão Inválido ou Deficiente",
  FILHO_INVALIDO_OU_DEFICIENTE = "Filho Inválido",
  TUTELADO_INVALIDO_OU_DEFICIENTE = "Tutelado Inválido ou Deficiente",
  ENTEADO_INVALIDO_OU_DEFICIENTE = "Enteado Inválido",
  CONJUGE = "Cônjuge",
  UNIAO_ESTAVEL = "União Estável",
  EX_CONJUGE_PENSAO_ALIMENTICIA = "Ex cônjuge pensão alimentícia",
  EX_COMPANHEIRO_PENSAO_ALIMENTICIA = "Ex companheiro pensão alimentícia",
  PAI_MAE_INSTITUIDOR = "Pai ou Mãe do Instituidor",
  IRMAOS = "Irmãos",
  TUTELADO_MENOR = "Tutelado Menor",
  ENTEADO_MENOR = "Enteado Menor",
  IRMAO_MENOR = "Irmão Menor",
  MENOR_SOB_GUARDA = "Menor Sob Guarda",
  MENOR_SOB_GUARDA_INVALIDO = "Menor Sob Guarda Invalido",
  CONJUGE_INVALIDO = "Cônjuge Invalido",
  UNIAO_ESTAVEL_INVALIDO = "União Estável Invalido",
  GUARDA = "Guarda Judicial",
  MENOR_21_ANOS_SOB_GUARDA_INVALIDO = "Menor de 21 anos sob guarda inválido",
  FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Filho menor de 21 anos, ou até 24 anos se universitário",
  ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Enteado menor de 21 anos, ou até 24 anos se universitário",
  MENOR_21_ANOS_SOB_TUTELA_INVALIDO = "Menor de 21 anos sob tutela inválido",
  MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA = "Menor de 21 anos sob guarda, ou até 24 anos se universitário",
  IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO = "Irmão órfão menor de 21 anos, ou até 24 anos se universitário",
  MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA = "Menor de 21 anos sob tutela, ou até 24 anos se universitário",
}

export const TipoSolicitanteEnumAll = [
  { id: "representante", descricao: TipoSolicitante.REPRESENTANTE, enum: "REPRESENTANTE" },
  { id: "curador", descricao: TipoSolicitante.CURADOR, enum: "CURADOR" },
  { id: "tutor", descricao: TipoSolicitante.TUTOR, enum: "TUTOR" },
  { id: "procurador", descricao: TipoSolicitante.PROCURADOR, enum: "PROCURADOR" },
  { id: "mae", descricao: TipoSolicitante.MAE, enum: "MAE" },
  { id: "pai", descricao: TipoSolicitante.PAI, enum: "PAI" },
  { id: "responsavel", descricao: TipoSolicitante.RESPONSAVEL, enum: "RESPONSAVEL" },
  { id: "dependente", descricao: TipoSolicitante.DEPENDENTE, enum: "DEPENDENTE" },
  { id: "filho-menor", descricao: TipoSolicitante.FILHO_MENOR, enum: "FILHO_MENOR" },
  { id: "irmao-invalido-ou-deficiente", descricao: TipoSolicitante.IRMAO_INVALIDO_OU_DEFICIENTE, enum: "IRMAO_INVALIDO_OU_DEFICIENTE" },
  { id: "filho-invalido-ou-deficiente", descricao: TipoSolicitante.FILHO_INVALIDO_OU_DEFICIENTE, enum: "FILHO_INVALIDO_OU_DEFICIENTE" },
  {
    id: "tutelado-invalido-ou-deficiente",
    descricao: TipoSolicitante.TUTELADO_INVALIDO_OU_DEFICIENTE,
    enum: "TUTELADO_INVALIDO_OU_DEFICIENTE",
  },
  {
    id: "enteado-invalido-ou-deficiente",
    descricao: TipoSolicitante.ENTEADO_INVALIDO_OU_DEFICIENTE,
    enum: "ENTEADO_INVALIDO_OU_DEFICIENTE",
  },
  { id: "conjuge", descricao: TipoSolicitante.CONJUGE, enum: "CONJUGE" },
  { id: "uniao-estavel", descricao: TipoSolicitante.UNIAO_ESTAVEL, enum: "UNIAO_ESTAVEL" },
  { id: "ex-conjuge-pensao-alimenticia", descricao: TipoSolicitante.EX_CONJUGE_PENSAO_ALIMENTICIA, enum: "EX_CONJUGE_PENSAO_ALIMENTICIA" },
  {
    id: "ex-companheiro-pensao-alimenticia",
    descricao: TipoSolicitante.EX_COMPANHEIRO_PENSAO_ALIMENTICIA,
    enum: "EX_COMPANHEIRO_PENSAO_ALIMENTICIA",
  },
  { id: "pai-mae-instituidor", descricao: TipoSolicitante.PAI_MAE_INSTITUIDOR, enum: "PAI_MAE_INSTITUIDOR" },
  { id: "irmaos", descricao: TipoSolicitante.IRMAOS, enum: "IRMAOS" },
  { id: "tutelado-menor", descricao: TipoSolicitante.TUTELADO_MENOR, enum: "TUTELADO_MENOR" },
  { id: "enteado-menor", descricao: TipoSolicitante.ENTEADO_MENOR, enum: "ENTEADO_MENOR" },
  { id: "irmao-menor", descricao: TipoSolicitante.IRMAO_MENOR, enum: "IRMAO_MENOR" },
  { id: "menor-sob-guarda", descricao: TipoSolicitante.MENOR_SOB_GUARDA, enum: "MENOR_SOB_GUARDA" },
  { id: "menor-sob-guarda-invalido", descricao: TipoSolicitante.MENOR_SOB_GUARDA_INVALIDO, enum: "MENOR_SOB_GUARDA_INVALIDO" },
  { id: "conjuge-invalido", descricao: TipoSolicitante.CONJUGE_INVALIDO, enum: "CONJUGE_INVALIDO" },
  { id: "uniao-estavel-invalido", descricao: TipoSolicitante.UNIAO_ESTAVEL_INVALIDO, enum: "UNIAO_ESTAVEL_INVALIDO" },
  { id: "guarda", descricao: TipoSolicitante.GUARDA, enum: "GUARDA" },
  //Pensao Militar=o ngIf so esta funcioanndo com o TipoSolicitante
  {
    id: "filho-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoSolicitante.FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "FILHO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },

  {
    id: "menor-21-anos-sob-guarda-invalido",
    descricao: TipoSolicitante.MENOR_21_ANOS_SOB_GUARDA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_GUARDA_INVALIDO",
  },

  {
    id: "enteado-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoSolicitante.ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "ENTEADO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },

  {
    id: "menor-21-anos-sob-tutela-invalido",
    descricao: TipoSolicitante.MENOR_21_ANOS_SOB_TUTELA_INVALIDO,
    enum: "MENOR_21_ANOS_SOB_TUTELA_INVALIDO",
  },

  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-guarda",
    descricao: TipoSolicitante.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_GUARDA",
  },

  {
    id: "irmao-orfao-menor-21-anos-ou-ate-24-universitario",
    descricao: TipoSolicitante.IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO,
    enum: "IRMAO_ORFAO_MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO",
  },

  {
    id: "menor-21-anos-ou-ate-24-universitario-sob-tutela",
    descricao: TipoSolicitante.MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA,
    enum: "MENOR_21_ANOS_OU_ATE_24_UNIVERSITARIO_SOB_TUTELA",
  },
];

export namespace TipoSolicitanteFunctions {
  export function getDescricaoPorId(idTipoSolicitante: string) {
    return TipoSolicitanteEnumAll.filter(function (tipo) {
      return tipo.id.toUpperCase() === idTipoSolicitante.toUpperCase();
    })[0].descricao;
  }

  export function getDescricaoPorEnum(enumValue: string) {
    return TipoSolicitanteEnumAll.filter(function (tipo) {
      return tipo.enum.toUpperCase() === enumValue.toUpperCase();
    })[0].descricao;
  }
}
