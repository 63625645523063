import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissaoService } from 'src/app/core/api/seguranca/permissao.service';
import { Permissao } from 'src/app/core/models/seguranca/permissao.model';
import { SalvarGenericsComponent } from 'src/app/global/generics/salvar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';

@Component({
  selector: 'sgeap-salvar-permissao',
  templateUrl: './salvar-permissao.component.html',
  styleUrls: ['./salvar-permissao.component.css']
})
export class SalvarPermissaoComponent extends SalvarGenericsComponent<Permissao> {

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: PermissaoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
  ) {
    super(formBuilder, router, apiService, messageService, route, "permissao",
      [
        { nome: ['', Validators.required] },
        { descricao: ['', Validators.required] },
        { codigoIdentificacao: ['', Validators.required] }
      ]);
  }

  salvarValidar(domain: Permissao): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  private validar(domain: Permissao): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.nome || !domain.codigoIdentificacao) {
      this.messageService.messageErro("Preencha todos os campos obrigatórios.");
      validacao = false;
    }

    return validacao;
  }
}
