import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";


@Injectable({ providedIn: "root" })
export class UsuarioService extends ApiService {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "usuario", config);
  }

  public pesquisarLotacoesFavoritas(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/lotacoes-favoritas`).pipe(map(response => response));
  }
  
  public alternarLotacaoFavorita(lotacao: Number): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/lotacoes-favoritas/alternar/${lotacao}`, null).pipe(map(response => response));
  }

}