import { Domain } from "../../global/models/domain.model";
import { TipoDocumento } from "./tipo-documento.model";

export class Modelo extends Domain {
    public nome: string;
    public instrucoes: string;
    public conteudo: string;
    public tipoDocumento: TipoDocumento;
    public publicoLocal: boolean;
    public publicoGlobal: boolean;
    public lotacao: number;
}
