import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { CKEditorModule } from "ng2-ckeditor";
import { PopoverModule } from "ngx-smart-popover";
import { MaterialModule } from "src/app/material";
import { AssinaturaService } from "../api/assinatura.service";
import { ModuloAssinadorComponent } from "./modulo/modulo-assinador.component";


const routes: Routes = [
  {
    path: "modulo-assinador", component: ModuloAssinadorComponent, data: {
      title: "Modulo assinador",
      breadcrumb: [
        {
          label: "Modulo assinador",
          url: "/modulo-assinador"
        }
      ]
    }
  }
];

@NgModule({
  declarations: [
    ModuloAssinadorComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PopoverModule,
    CKEditorModule
  ],
  providers: [
    AssinaturaService
  ]
})
export class ModuloAssinadorModule { }
