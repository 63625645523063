import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { ProcessoService } from "../../api/processo.service";
import { Ocorrencia } from "../../models/ocorrencia-dto.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { OcorrenciaService } from "../../api/ocorrencia.service";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { EnvolvidoProcessoDTO } from "../../models/envolvido-processo-dto.model";
@Component({
  selector: "app-dialog-adicionar-ocorrencias-processo",
  templateUrl: "./dialog-adicionar-ocorrencias-processo.component.html",
  styleUrls: ["./dialog-adicionar-ocorrencias-processo.component.css"],
})
export class DialogAdicionarOcorrenciaProcesso extends SalvarGenericsComponent<Ocorrencia> implements OnInit {
  public buttons = [];
  public sections = [];
  public titleOcorrencia = "OCORRÊNCIA";
  public form: FormGroup;

  public envolvidos: EnvolvidoProcessoDTO[] = [];
  public tipoDocumentos: TipoDocumento[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogAdicionarOcorrenciaProcesso>,
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: OcorrenciaService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public processoService: ProcessoService,
    public tipoDocumentoService: TipoDocumentoService,
    @Inject(MAT_DIALOG_DATA) public processoId: string
  ) {
    super(formBuilder, router, apiService, messageService, route, "ocorrencia", "");
    this.getDocumentos();
    this.getPessoasEnvolvidas();
    this.domain.processoId = processoId;
  }

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.sections = [
      {
        title: "OCORRÊNCIAS",
        fields: [
          [
            {
              key: "envolvidos",
              fieldType: "selectInput",
              options: () => this.envolvidos,
              multiple: true,
              labelKey: "nome",
              label: "Pessoa",
              required: true,
              type: "text",
              maxLength: 255,
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "tipoDocumento",
              fieldType: "selectInput",
              options: () => this.tipoDocumentos,
              multiple: false,
              labelKey: "nomeDocumento",
              label: "Documento",
              required: false,
              type: "text",
              maxLength: 255,
              cols: [12, 12, 12, 12, 12],
            },
            {
              key: "descricao",
              fieldType: "textInput",
              label: "Descrição",
              required: true,
              maxLength: 255,
              cols: [12, 12, 12, 12, 12],
            },
          ],
        ],
      },
    ];

    this.buttons = [
      {
        label: "Salvar",
        click: () => this.salvarOcorrencia(),
        color: "primary",
      },
      {
        label: "Cancelar",
        click: () => this.dialogRef.close(),
        color: "secondary",
      },
    ];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  salvarOcorrencia() {
    if (this.formValido()) {
      this.apiService.post(this.domain).subscribe(
        (res) => {
          this.dialogRef.close();
          this.messageService.messageSuccess("Ocorrência salva com sucesso.");
        },
        (error) => {
          this.dialogRef.close();
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    } else {
      this.messageService.messageInfo("Informe todos campos obrigatórios.");
    }
  }

  public getPessoasEnvolvidas() {
    this.processoService.buscarPessoasEnvolvidasProcesso(this.processoId).subscribe(
      (envolvidos) => {
        this.envolvidos = [];
        const solicitante = envolvidos.find((objeto) => objeto.tipo === "SOLICITANTE");

        const solicitanteNome = solicitante.nome;

        for (const objeto of envolvidos) {
          if (objeto.tipo === "INSTITUIDOR") {
            this.envolvidos.push(objeto);
          } else if (objeto.tipo === "SOLICITANTE") {
            this.envolvidos.push(objeto);
          } else if (objeto.tipo === "REQUERENTE" && objeto.nome !== solicitanteNome) {
            this.envolvidos.push(objeto);
          }
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public formValido() {
    let formValido = true;

    if (!this.domain.descricao || this.domain.descricao.length == 0) {
      formValido = false;
    }

    if (!this.domain.envolvidos || this.domain.envolvidos.length == 0) {
      formValido = false;
    }

    return formValido;
  }

  public getDocumentos() {
    this.tipoDocumentoService.listarTipoDocumento().subscribe(
      (tipos) => {
        this.tipoDocumentos = tipos.filter((tipo) => !tipo.geradoSistema);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
