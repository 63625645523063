import { Domain } from "../../global/models/domain.model";
import { Instituidor } from "./pensao/instituidor.model";
import { Requerente } from "./pensao/requerente.model";
import { Resultado } from "./pensao/resultado.model";
import { Solicitante } from "./pensao/solicitante.model";

export class RegrasProcesso extends Domain {

  public numeroProcesso: string;
  public solicitante: Solicitante;
  public instituidor: Instituidor;
  public requerentes: Requerente[];
  public buscaDocs: boolean;
  public buscaDocsFuncionais: boolean;
  public validaDocsFuncionais: boolean;
  public validaDocs: boolean;
  public resultado: Resultado;
  public dataAberturaProcesso: Date;
  public contRequerentes: number;

}