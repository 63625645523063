import { EventEmitter, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import jwt_decode from 'jwt-decode';
import { AppConfig, APP_CONFIG } from "src/app.config";
import { TokenTO } from "../models/token.model";
import { environment } from "src/environments/environment"; // TODO Remover

@Injectable()
export class SharedService {
  public static CARREGAR_LOADING = "1"; // Padrão
  public static PAGE_SIZE = 10;
  public static CARREGAR_LOADING_ASSINATURA = "2";
  public static KEY_STORE_CERTIFICATE = "vipro-web-st-certifcate";
  public static PAGE_INDEX = 0;
  public static PAGE_SIZE_ATUAL = "page-size-atual";
  public static PAGE_INDEX_ATUAL = "page-index-atual";
  public static TIPO_DOCUMENTO_FILTRO_NOME = "tipo-documento-filtro-nome";
  public static TIPO_DOCUMENTO_FILTRO_TIPO = "tipo-documento-filtro-tipo";
  public static TIPO_DOCUMENTO_FILTRO_TIPO_EDITOR_TEXTO = "tipo-documento-filtro-tipo-editor-texto";
  public VISAO_BENEFICIARIO = 1;
  public VISAO_ANALISTA = 2;
  public KEY_STORE_TOKEN = "token-seguranca-contribuinte";
  public KEY_STORE_DOCUMENTO_REPRESENTANTE = "vipro-web-st-doc-representante";
  public KEY_STORE_DOCUMENTO_REPRESENTADO = "vipro-web-st-doc-representado";
  public KEY_STORE_DOCUMENTO_ASTRA = "vipro-web-st-doc-astra";

  usuario: any;
  documentoRepresentante: any;
  showTemplate = new EventEmitter<boolean>();

  constructor(private router: Router, @Inject(APP_CONFIG) public config: AppConfig) {
  }

  getToken(): any {
    return localStorage.getItem(this.KEY_STORE_TOKEN);
  }

  setCertificate(certificate: any) {
    localStorage.setItem(SharedService.KEY_STORE_CERTIFICATE, JSON.stringify(certificate));
  }

  setDocumentoRepresentante(documentoRepresentante: any) {
    this.documentoRepresentante = documentoRepresentante != null ? documentoRepresentante.replace(/\D/g, "") : null;
    localStorage.setItem(this.KEY_STORE_DOCUMENTO_REPRESENTANTE, JSON.stringify(this.documentoRepresentante));
  }

  getDocumentoRepresentante(): any {
    return localStorage.getItem(this.KEY_STORE_DOCUMENTO_REPRESENTANTE);
  }

  setDocumentoRepresentado(documentoRepresentado: any) {
    documentoRepresentado = documentoRepresentado != null ? documentoRepresentado.replace(/\D/g, "") : null;
    localStorage.setItem(this.KEY_STORE_DOCUMENTO_REPRESENTADO, JSON.stringify(documentoRepresentado));
  }

  getDocumentoRepresentado(): any {
    return localStorage.getItem(this.KEY_STORE_DOCUMENTO_REPRESENTADO);
  }

  setDocumentoAstra(documentoAstra: any) {
    localStorage.setItem(this.KEY_STORE_DOCUMENTO_ASTRA, JSON.stringify(documentoAstra.toUpperCase()));
  }

  getDocumentoAstra(): any {
    return localStorage.getItem(this.KEY_STORE_DOCUMENTO_ASTRA);
  }

  getTipoDocumentoTipoFiltro(): any {
    return localStorage.getItem(SharedService.TIPO_DOCUMENTO_FILTRO_TIPO);
  }

  getTipoDocumentoFiltroTipoEditorTexto(): any {
    return localStorage.getItem(SharedService.TIPO_DOCUMENTO_FILTRO_TIPO_EDITOR_TEXTO);
  }

  getTipoDocumentoFiltroNome(): any {
    return localStorage.getItem(SharedService.TIPO_DOCUMENTO_FILTRO_NOME);
  }

  getKeyStoreCertificate(): any {
    return localStorage.getItem(SharedService.KEY_STORE_CERTIFICATE);
  }

  isDocumentoRepresentante() {
    return (localStorage.getItem(this.KEY_STORE_DOCUMENTO_REPRESENTANTE) !== JSON.stringify(null) &&
      localStorage.getItem(this.KEY_STORE_DOCUMENTO_REPRESENTANTE) !== undefined) ? true : false;
  }

  isLoggedIn(): boolean {
    if (!this.usuario) {
      return false;
    }
    return this.usuario.email !== "";
  }

  logar(userAuthentication: any): TokenTO {
    if(userAuthentication !== JSON.stringify(null) && userAuthentication !== undefined) {
      localStorage.setItem(this.KEY_STORE_TOKEN, userAuthentication);
      this.showTemplate.emit(true);
      return this.decodePayloadJWT();
    } 
    return;
  }

  checkTokenExpirado() {
    const helper = new JwtHelperService();
    if (!this.getToken() || helper.isTokenExpired(this.getToken())) {
      this.logout();
    }
  }

  logout() {
    this.usuario = null;
    localStorage.clear();
    this.showTemplate.emit(false);
    this.redirecionarParaLogin();
  }

  redirecionarParaLogin() {
    this.router.navigate([`login`]);
  }

  isFiscal() {
    return environment.VISAO === this.VISAO_ANALISTA ? true : false;
  }

  isContribuinte() {
    return environment.VISAO === this.VISAO_BENEFICIARIO ? true : false;
  }

  setPageIndex(pageIndex: number) {
    localStorage.setItem(SharedService.PAGE_INDEX_ATUAL, pageIndex.toString());
  }

  getPageIndex(): number {
    return Number(localStorage.getItem(SharedService.PAGE_INDEX_ATUAL));
  }

  setPageSize(pageSize: number) {
    localStorage.setItem(SharedService.PAGE_SIZE_ATUAL, pageSize.toString());
  }

  getPageSize(): number {
    return Number(localStorage.getItem(SharedService.PAGE_SIZE_ATUAL));
  }

  setTipoDocumentoFiltroNome(nome: string) {
    localStorage.setItem(SharedService.TIPO_DOCUMENTO_FILTRO_NOME, nome);
  }

  setTipoDocumentoFiltroTipo(tipo: string) {
    localStorage.setItem(SharedService.TIPO_DOCUMENTO_FILTRO_TIPO, tipo);
  }

  setTipoDocumentoFiltroTipoEditorTexto(tipoEditorTexto: string) {
    localStorage.setItem(SharedService.TIPO_DOCUMENTO_FILTRO_TIPO_EDITOR_TEXTO, tipoEditorTexto);
  }

  public decodePayloadJWT(): TokenTO {
    try {
      if (!this.getToken()) {
        this.logout();
        return null;
      }
      let token: TokenTO = jwt_decode(this.getToken());
      return token;
    } catch (error) {
      throw error;
    }
  }

  public verificaRolePessoaLogada(...roles: string[]): boolean {
    let hasRole: boolean = false;
    const token: TokenTO = this.decodePayloadJWT();
    if (token) {
      roles.forEach(role => {
        if (token.roles.includes(role)) {
          hasRole = true;
        }
      });
    }
    return hasRole;

  }
}
