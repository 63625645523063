import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { SharedService } from "src/app/global/api/shared.service";
import { Modelo } from "../models/modelo.model";
import { AppConfig, APP_CONFIG } from "src/app.config";


@Injectable({ providedIn: "root" })
export class ModeloService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "modelo", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }

  // size = 0, trazer todos.
  public pesquisar(modelo: Modelo, page: number = 0, size: number = SharedService.PAGE_SIZE): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, modelo).pipe(map(response => {
          return response;
      }));
  }
}