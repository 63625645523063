import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Message } from "../message";
import { MessageService } from "../message.service";
import { timer } from "rxjs";

@Component({
  selector: "app-message-container",
  templateUrl: "./message-container.component.html",
  styleUrls: ["./message-container.component.css"]
})
export class MessageContainerComponent implements OnInit, OnDestroy {

  lista: Message[] = [];
  constructor(private eventMessage: MessageService, private chRef: ChangeDetectorRef) { }
  ngOnInit() {
    this.eventMessage.event.subscribe(message => {
      window.scrollTo(0, 0);
      this.lista.push(message);
      this.chRef.detectChanges(); //FIXME Precisou colocar aqui para quando precisar aparecer a mensagem dentro do dialog
      timer(5000).subscribe(t => this.lista.shift());
    });
  }

  ngOnDestroy() {
    this.eventMessage.event.unsubscribe();
  }


}
