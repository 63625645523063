import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import LacunaWebPKI, { CertificateModel } from "web-pki";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { MessageService } from "../../../global/message/message.service";
import { Certificate } from "../../../global/models/certificate.model";

@Component({
  selector: "app-dialog-selecionar-certificado",
  templateUrl: "./dialog-selecionar-certificado.component.html",
  styleUrls: ["./dialog-selecionar-certificado.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelecionarCertificadoComponent implements OnInit {

  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  public certificate: Certificate;
  certificates: CertificateModel[];
  public form: FormGroup;

  @ViewChild("simularClick") simularClick: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogSelecionarCertificadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
          private formBuilder: FormBuilder,
          private messageService: MessageService,
          private sharedService: SharedService,
          private eleRef: ElementRef) {
            this.form = this.formBuilder.group({
              selectedCertificate: ["", []]
            });
    }

  ngOnInit() {
    this.getCertificates();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public getCertificates(): any {
    this.pki.init({
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled,
      defaultFail: this.onWebPkiFail
    });
  }

  onWebPkiNotInstalled(status, message) {
    const pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
    pki.redirectToInstallPage();
    console.error("onWebPkiNotInstalled" + status + message);
  }

  onWebPkiFail(ex) {
    console.error("Web PKI error from " + ex.origin + ": " + ex.message + "(" + ex.code + ")");
  }

  onWebPkiReady = () => {
    console.info("Web PKI ready, loading certificates ...");
    this.pki.listCertificates().success((certs) => {
      console.info("Certificates loaded.");
      this.certificates = certs;
    });
  }

  selecionar() {
    const selectedCertificate = this.form.get("selectedCertificate").value;
    this.certificate = selectedCertificate;

    if (!selectedCertificate) {
      this.messageService.messageErro("Selecione um certificado !");
      return;
    }

    if (new Date() > selectedCertificate.validityEnd) {
      this.messageService.messageErro("O certificado escolhido expirou em " + selectedCertificate.validityEnd.toLocaleDateString());
      return;
    }

    this.pki.readCertificate({
      thumbprint: selectedCertificate.thumbprint
    }).success(certEncoding => {
      this.certificate.cert64 = certEncoding;
      this.sharedService.setCertificate(this.certificate);
      this.dialogRef.close(this.certificate);
      this.eleRef.nativeElement.querySelector("#simularClick").dispatchEvent(new Event("click"));
    }).fail((e) => {
      this.messageService.messageErro("Erro ao ler certificado !" + e);
    });
  }

  semAssinatura() {
    this.dialogRef.close(1);
  }

  cancel() {
    this.sharedService.setCertificate(null);
    this.dialogRef.close(0);
  }
}
