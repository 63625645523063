import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {

  @Input() columns = []
  @Input() data = [];

  constructor() { }

  get columnsDisplay() {
    return this.columns.map(({ key })=> key)
  }

}
