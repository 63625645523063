
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class FiscalService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "fiscal", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  listarUsuarioPorLotacaoPrincipalSecundaria(lotacao: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/lotacao/${lotacao}`).pipe(map(response => response));
  }

}