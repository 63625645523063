export enum FalecidoEm {
	ATIVIDADE = "Em Atividade",
	APOSENTADO = "Aposentado"
}
  
export const FalecidoEmEnumAll = [
	{ id: 0, descricao: FalecidoEm.ATIVIDADE },
	{ id: 1, descricao: FalecidoEm.APOSENTADO }
];

