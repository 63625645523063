import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";
import { DialogLotacaoComponent } from "../../processo/dialog-lotacao/dialog-lotacao.component";
import { OrgaoLocal } from "../../models/orgao-local.model";
import { Responsavel } from "../../models/responsavel.model";
import { DialogListarServidoresComponent } from "../../processo/dialog-listar-servidores/dialog-listar-servidores.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-dialog-fiscal-escolhe-autor-interno",
  templateUrl: "./dialog-fiscal-escolhe-autor-interno.component.html",
  styleUrls: ["./dialog-fiscal-escolhe-autor-interno.component.css"],
})
export class DialogFiscalEscolheAutorInternoComponent implements OnInit {
  public servidorSelecionado: Responsavel;
  public opcaoAssuntoInterno = new OpcaoAssuntoInterno();
  public orgaoLocal: OrgaoLocal;

  constructor(
    public dialogRef: MatDialogRef<DialogFiscalEscolheAutorInternoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {}

  ngOnInit() {}

  confirm(): void {
    if (TipoAberturaProcessoEnumAll[this.opcaoAssuntoInterno.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO) {
      this.opcaoAssuntoInterno.infoAutor = JSON.parse(this.sharedService.getDocumentoAstra());
    }

    this.dialogRef.close(this.opcaoAssuntoInterno);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  public openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
    });
    dialogLotacao.afterClosed().subscribe((lotacaoSelecionada) => {
      if (lotacaoSelecionada) {
        this.orgaoLocal = lotacaoSelecionada;
        this.opcaoAssuntoInterno.infoAutor = lotacaoSelecionada.id;
      }
    });
  }

  public openDialogServidores() {
    const dialogServidor = this.dialog.open(DialogListarServidoresComponent, {
      width: "720px",
    });
    dialogServidor.afterClosed().subscribe((servidor: Responsavel) => {
      // FIXME o obj é Pessoa??
      if (servidor) {
        this.servidorSelecionado = servidor;
        this.opcaoAssuntoInterno.infoAutor = servidor.matricula;
      }
    });
  }
}
