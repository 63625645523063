import { AfterViewInit, Component, ElementRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CidadaoService } from "src/app/core/api/seguranca/cidadao.service";
import { Cidadao } from "src/app/core/models/seguranca/cidadao.model";
import { SalvarGenericsComponent } from "../../generics/salvar-generics-component";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "../../message/message.service";
import { RegisterService } from "./register.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent extends SalvarGenericsComponent<Cidadao> implements AfterViewInit {
  [x: string]: any;
  cpf?: ElementRef;

  constructor(
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: CidadaoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    private registerService: RegisterService
  ) {
    super(formBuilder, router, apiService, messageService, route, "usuario", {});
    //   [{ descricao: ['', Validators.required] },
    //   { codigoIdentificacao: ['', Validators.required] }]);
    // this.endpoint = "cidadao";
  }

  doNotMatch = false;
  error = false;
  errorEmailExists = false;
  errorCpfExists = false;
  success = false;

  registerForm = new FormGroup({
    nome: new FormControl("", {
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254)],
    }),
    email: new FormControl("", {
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email],
    }),
    telefone: new FormControl("", {
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254)],
    }),
    cpf: new FormControl("", {
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254)],
    }),
    senha: new FormControl("", {
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
    confirmSenha: new FormControl("", {
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
  });

  ngAfterViewInit(): void {
    if (this.cpf) {
      this.renderer.selectRootElement(this.cpf.nativeElement).focus();
    }
  }

  salvarUsuario(): void {
    this.doNotMatch = false;
    this.error = false;
    this.errorEmailExists = false;
    this.errorCpfExists = false;

    const { senha, confirmSenha } = this.registerForm.getRawValue();

    if (senha !== confirmSenha) {
      this.doNotMatch = true;
    } else {
      const { nome, telefone, cpf, email } = this.registerForm.getRawValue();

      const data: any = {
        nome,
        email,
        telefone,
        cpf,
        senha,
      };

      this.registerService.salvarUsuario(data).subscribe(
        (res) => {
          if (res) {
            this.messageService.messageSuccess("Usuário cadastrado com sucesso.");
            this.router.navigate([`${this.contexto}/${this.endpoint}`]);
          }
        },
        (error) => {
          if (error.error && error.error.message) {
            this.messageService.messageErro(error.error.message);
          } else {
            this.messageService.messageErro("Ocorreu um erro durante a solicitação.");
          }
          throw error;
        }
      );
    }
  }
  toggleSenhaVisivel() {
    this.senhaVisivel = !this.senhaVisivel;
  }

  toggleConfirmarSenhaVisivel() {
    this.confirmarSenhaVisivel = !this.confirmarSenhaVisivel;
  }
}
