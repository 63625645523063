import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/global/api/seguranca/auth.guard";
import { ListarPerfilComponent } from "./perfil/listar-perfil/listar-perfil.component";
import { ManterPermissoesComponent } from "./perfil/manter-permissoes/manter-permissoes.component";
import { SalvarPerfilComponent } from "./perfil/salvar-perfil/salvar-perfil.component";
import { ListarPermissaoComponent } from "./permissao/listar-permissao/listar-permissao.component";
import { SalvarPermissaoComponent } from "./permissao/salvar-permissao/salvar-permissao.component";
import { SegurancaHomeComponent } from "./seguranca-home/seguranca-home.component";

const routes: Routes = [
    {
        path: "seguranca-home", component: SegurancaHomeComponent, data: {
            title: "Segurança",
            breadcrumb: [
                {
                    label: "Segurança",
                    url: "/seguranca-home"
                }
            ],
            permission: "SEGURANCA"
        },
        canActivate: [AuthGuard]
    },
    {
        path: "perfil",
        component: ListarPerfilComponent,
        data: {
            title: "Perfil",
            breadcrumb: [
                {
                    label: "Perfil",
                    url: "/perfil"
                }
            ]
        },
        canActivate: [AuthGuard]
    },
    {
        path: "perfil/salvar",
        component: SalvarPerfilComponent,
        data: {
            title: "Salvar Perfil",
            breadcrumb: [
                {
                    label: "Salvar Perfil",
                    url: "/perfil/salvar"
                }
            ]
        }
    },
    {
        path: "perfil/editar/:id",
        component: SalvarPerfilComponent,
        data: {
            title: "Editar Perfil",
            breadcrumb: [
                {
                    label: "Editar Perfil",
                    url: "/perfil/editar/:id"
                }
            ]
        }
    },
    {
        path: "permissao",
        component: ListarPermissaoComponent,
        data: {
            title: "Permissão",
            breadcrumb: [
                {
                    label: "Permissão",
                    url: "/permissao"
                }
            ]
        }
    },
    {
        path: "permissao/salvar",
        component: SalvarPermissaoComponent,
        data: {
            title: "Salvar Permissão",
            breadcrumb: [
                {
                    label: "Salvar Permissão",
                    url: "/permissao/salvar"
                }
            ]
        }
    },
    {
        path: "permissao/editar/:id",
        component: SalvarPermissaoComponent,
        data: {
            title: "Editar Permissão",
            breadcrumb: [
                {
                    label: "Editar Permissão",
                    url: "/permissao/editar/:id"
                }
            ]
        }
    },
    {
        path: "perfil/manter-permissoes/:id",
        component: ManterPermissoesComponent,
        data: {
            title: "Manter Permissões",
            breadcrumb: [
                {
                    label: "Manter Permissões",
                    url: "/perfil/manter-permissoes/:id"
                }
            ]
        }
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SegurancaRoutingModule { }