export enum TipoUsuario {
    DESCONHECIDO = "Desconhecido",
    INTERNO = "Analista",
    EXTERNO = "Beneficiario"
}

export const TipoUsuarioEnumAll = [
    { id: 0, descricao: TipoUsuario.DESCONHECIDO },
    { id: 1, descricao: TipoUsuario.INTERNO },
    { id: 2, descricao: TipoUsuario.EXTERNO }
];