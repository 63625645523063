import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.css']
})
export class FormTemplateComponent {

  @Input() title: string = '';
  @Input() onSave = () => {};
  @Input() sections: [];
  @Input() buttons = [];
  @Input() form = {};
  @Input() defaultXs?: number | 'auto';
  @Input() defaultSm?: number | 'auto';
  @Input() defaultMd?: number | 'auto';
  @Input() defaultLg?: number | 'auto';
  @Input() defaultXl?: number | 'auto';

  constructor() {
  }

  handleParam(param: unknown, paramDefault: unknown) {
    return typeof param === 'function' ? param() : param || paramDefault
  }

  compare(obj1: any, obj2: any): boolean {
    if (typeof obj1 !== 'object') return obj1 === obj2.id
    if (typeof obj2 !== 'object')  return obj2 === obj1.id
    return obj1.id === obj2.id
  }
}
