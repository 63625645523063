import { Domain } from "../../global/models/domain.model";
import { OrgaoLocal } from "./orgao-local.model";

export class Pessoa extends Domain {
  public matricula: string;
  public documento: string;
  public nome: string;
  public cpf: string;
  public orgao: string;
  public orgaoLocal: OrgaoLocal;
  public msg: string;
}