import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { environment } from "../../../../environments/environment";
import { Documento } from "../../models/documento.model";
import { Processo } from "../../models/processo.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ProcessoService } from "../../api/processo.service";
import { DocumentoService } from "../../api/documento.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { ResponsavelService } from "../../api/responsavel.service";
import { SharedService } from "../../../global/api/shared.service";
import { LacunaWebPKI } from "web-pki";
import { MessageService } from "../../../global/message/message.service";
import { Movimentacao } from "../../models/movimentacao.model";
import { AssuntoService } from "../../api/assunto.service";
import { IncluirDocumentoComponent } from "../../documento/incluir-documento/incluir-documento.component";
import { LoadingScreenService } from "src/app/global/api/loading-screen";
import { Utils } from "src/app/global/util/utils-component";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum } from "../../models/tipo-abertura-processo-enum.model";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { Certificate } from "../../../global/models/certificate.model";
import { SelectionModel } from "@angular/cdk/collections";
import { OcorrenciaService } from "../../api/ocorrencia.service";
import { OcorrenciaListarDTO } from "../../models/ocorrencia-listar-dto";
import { DialogResponderOcorrenciaProcesso } from "../dialog-responder-ocorrencias/dialog-responder-ocorrencias-processo.component";

@Component({
  selector: "app-listar-pendencia-processo",
  templateUrl: "./listar-pendencia-processo.component.html",
  styleUrls: ["./listar-pendencia-processo.component.css"],
})
export class ListarPendenciaProcessoComponent implements OnInit {
  @ViewChild("paginatorMovimentacoes") paginatorMovimentacoes: MatPaginator;
  public dataSourceMovimentacao: MatTableDataSource<Movimentacao>;

  @ViewChild("paginatorDocumentos") paginatorDocumentos: MatPaginator;
  public dataSourceDocumento: MatTableDataSource<Documento>;

  @ViewChild("paginatorOcorrencias") paginatorOcorrencias: MatPaginator;

  @ViewChild("paginatorDocumentoAguardandoAssinatura") paginatorDocumentoAguardandoAssinatura: MatPaginator;
  public dataSourceDocumentoAguardandoAssinatura: MatTableDataSource<Documento>;
  public resultsLengthDocumentoAguardandoAssinatura = 0;
  public isLoadingResultsDocumentoAguardandoAssinatura = true;
  public isRateLimitReachedDocumentoAguardandoAssinatura = false;

  @ViewChild("paginatorProcessoPendencias") paginatorProcessoPendencias: MatPaginator;
  public dataSourceProcessoPendencias: MatTableDataSource<Processo>;
  public resultsLengthProcessoPendencias = 0;
  public isLoadingResultsProcessoPendencias = true;
  public isRateLimitReachedProcessoPendencias = false;

  @ViewChild("paginatorProcessoAguardandoManifestacao") paginatorProcessoAguardandoManifestacao: MatPaginator;
  public dataSourceProcessoAguardandoManifestacao: MatTableDataSource<Processo>;
  public resultsLengthAguardandoManifestacao = 0;
  public isLoadingResultsAguardandoManifestacao = true;
  public isRateLimitReachedAguardandoManifestacao = false;

  public highlightedDocumentRows: any[] = [];
  public highlightedAguardandoManifestacaoRows: any[] = [];

  public dataSourceOcorrencias: MatTableDataSource<OcorrenciaListarDTO>;
  public highlightedOcorrenciasRows: any[] = [];

  public responsavel: any;
  public step = 0;
  public abrirAccordion: boolean;
  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public PAGE_INITIAL = 0;
  public documentoRepresentante: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedDocumentosColumns: string[] = ["id", "numero", "tipoDocumento", "descricao"];
  public displayedProcessosColumns: string[] = ["id", "numero", "informacoes", "situacao", "detalhes"];
  public displayedMovimentacoesColumns: string[] = ["localizacao", "situacao", "permanencia", "datahora"];

  public processo: Processo;
  public listaAnexoDocumento: Documento[];
  public documentos: Documento[] = [];

  public hiddenDataSelectedProcesso = true;

  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);

  public displayedOcorrenciasColumns: string[] = ["processo", "documento", "pessoa", "tipoRelacionamento", "situacao", "datahora"];

  public infoAssuntoAutor = new OpcaoAssuntoInterno();

  private certificate: Certificate;
  public natureza: number;
  public selection = new SelectionModel<Documento>(true, []);
  public isPossuiPerfilAnalistaOuAdministrador: boolean;
  public countPendenciasTotal: any;
  public countPendenciasAssinatura: any;
  public countPendenciasOcorrências: any;

  constructor(
    protected route: ActivatedRoute,
    public router: Router,
    public processoService: ProcessoService,
    public documentoService: DocumentoService,
    public tipoDocumentoService: TipoDocumentoService,
    public responsavelService: ResponsavelService,
    public messageService: MessageService,
    public assuntoService: AssuntoService,
    public dialog: MatDialog,
    public loadingScreenService: LoadingScreenService,
    private sharedService: SharedService,
    public ocorrenciaService: OcorrenciaService
  ) {
    this.dataSourceProcessoAguardandoManifestacao = new MatTableDataSource<Processo>([]);
    this.certificate = new Certificate();
  }

  ngOnInit() {
    this.documentoRepresentante = this.sharedService.decodePayloadJWT().sub;

    this.infoAssuntoAutor.tipo = TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO;
    this.infoAssuntoAutor.infoAutor = this.documentoRepresentante;

    this.listarProcessoComPendencias(this.PAGE_INITIAL, this.PAGE_SIZE);
    this.listarDocumentosPendentesPorProcessos();

    this.listarProcessoAguardandoManifestracao(this.PAGE_INITIAL, this.PAGE_SIZE);
    this.buscarOcorrenciasSolicitante();

    this.isPossuiPerfilAnalistaOuAdministrador = this.sharedService.verificaRolePessoaLogada(
      "documento.assinar" // Permissao de acesso ao botão assinar em Minhas Pendências
    );
  }

  private listarProcessoAguardandoManifestracao(page: number, size: number) {
    this.processoService.getProcessoAguardandoManifestacao(this.documentoRepresentante, page, size).subscribe(
      (processos) => {
        processos.content.forEach((processo) => {
          this.assuntoService.get(processo.assunto.id).subscribe((assunto) => {
            processo.assunto = assunto;
          });
        });
        this.dataSourceProcessoAguardandoManifestacao.data = processos.content;
        this.resultsLengthAguardandoManifestacao = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private listarProcessoComPendencias(page: number, size: number) {
    /*
    Conforme ticket #206 requisito será para Entrega 2.

    this.processoService.getProcessoByContribuinteWithPendencia(
      JSON.parse(this.sharedService.getDocumentoRepresentado()), page, size).subscribe((processo) => {
        // tslint:disable-next-line:triple-equals
        if (page == 0) {
          this.dataSourceProcessoPendencias = null;
          this.dataSourceProcessoPendencias = new MatTableDataSource<Processo>(processo.content);
          this.dataSourceProcessoPendencias.paginator = this.paginatorProcessoPendencias;
          this.resultsLengthProcessoPendencias = processo.totalElements;
        } else {
          // tslint:disable-next-line:max-line-length
          this.dataSourceProcessoPendencias = new MatTableDataSource<Processo>(this.dataSourceProcessoPendencias.data.concat(processo.content));
          this.dataSourceProcessoPendencias.paginator = this.paginatorProcessoPendencias;
        }
      });
      */
  }

  private listarDocumentosPendentesPorProcessos() {
    this.documentoService.listarDocumentosPendenteAssinatura(this.documentoRepresentante).subscribe((documentos) => {
      documentos.forEach((documento) => {
        this.processoService.get(documento.processoId).subscribe(
          (processo) => {
            documento.etiquetaProcessoViproc = processo.etiquetaProcessoViproc;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      });
      this.dataSourceDocumentoAguardandoAssinatura = new MatTableDataSource<Documento>(documentos);
      this.dataSourceDocumentoAguardandoAssinatura.paginator = this.paginatorDocumentoAguardandoAssinatura;
    });
  }

  assinarDocumento() {
    if (this.highlightedDocumentRows[0] != null) {
      this.openDialogSelecionarCertificado();
    } else {
      this.messageService.messageWarning("Selecione um documento");
    }
  }

  openDialogSelecionarCertificado(): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate,
    });

    openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
      if (certParam != null && certParam.cert64 != null) {
        this.certificate = certParam;
        this.assinarMarcados();
      }
    });
  }

  private assinarMarcados() {
    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
    const formData: FormData = new FormData();
    const documentoSelecionado = this.highlightedDocumentRows[0];
    this.natureza = null;
    formData.append("certContent", this.certificate.cert64);
    formData.append("documento", documentoSelecionado.docAutor);

    let countDocumentosAtualizados = 0;

    this.documentoService.startWithIdDocumento(documentoSelecionado.id, formData, SharedService.CARREGAR_LOADING).subscribe(
      (sigParams) => {
        this.pki
          .signHash({
            thumbprint: cert.thumbprint,
            hash: sigParams.toSignHash,
            digestAlgorithm: sigParams.digestAlgorithm,
          })
          .success((signature) => {
            this.documentoService
              .completeWithIdDocumento(
                documentoSelecionado.id,
                sigParams.fileName,
                sigParams.transferFileId,
                signature,
                sigParams.descricaoAssinatura,
                SharedService.CARREGAR_LOADING
              )
              .subscribe(
                (documentoAssinado) => {
                  this.messageService.messageSuccess("Documento assinado com sucesso!");
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
          });
      },
      (msgExcecao) => {
        countDocumentosAtualizados++;
        if (msgExcecao.error != null && msgExcecao.error.message != null) {
          this.messageService.messageErro(msgExcecao.error.message);
          throw msgExcecao;
        } else {
          this.messageService.messageErro("Erro na assinatura do arquivo.");
        }
      }
    );
  }

  assinar() {
    if (this.highlightedDocumentRows.length > 0) {
      const documentoParaAssinar = this.highlightedDocumentRows[0];

      const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
      const formData: FormData = new FormData();
      formData.append("certContent", cert.cert64);
      formData.append("documento", this.documentoRepresentante);
      this.documentoService.startWithIdDocumento(documentoParaAssinar.id, formData, SharedService.CARREGAR_LOADING).subscribe(
        (sigParams) => {
          // Having received the signature algorithm parameters from the backend, we perform the signature algorithm computation
          this.pki
            .signHash({
              thumbprint: cert.thumbprint,
              hash: sigParams.toSignHash,
              digestAlgorithm: sigParams.digestAlgorithm,
            })
            .success((signature) => {
              // We send the algorithm output back to the backend, which will then use its server-side SDK to assemble the signed PDF file
              this.documentoService
                .completeWithIdDocumento(
                  documentoParaAssinar.id,
                  sigParams.fileName,
                  sigParams.transferFileId,
                  signature,
                  sigParams.descricaoAssinatura,
                  SharedService.CARREGAR_LOADING
                )
                .subscribe(
                  (documento) => {
                    this.messageService.messageSuccess("Documento assinado com sucesso.");
                    this.listarDocumentosPendentesPorProcessos();
                  },
                  (error) => {
                    this.messageService.messageErro(error.error.message);
                    throw error;
                  }
                );
            });
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    } else {
      this.messageService.messageWarning("Selecione um documento.");
    }
  }

  public pageEventProcessoComPendencias(event) {
    // tslint:disable-next-line:triple-equals
    if (event.previousPageIndex == 0) {
      this.listarProcessoComPendencias(event.pageIndex, event.pageSize);
    }
  }

  public pageEventProcessoAguardandoManifestacao(event) {
    // tslint:disable-next-line:triple-equals
    if (event.previousPageIndex == 0) {
      this.listarProcessoAguardandoManifestracao(event.pageIndex, event.pageSize);
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getRecord(rowDocumento: any) {
    this.highlightedDocumentRows.pop();
    this.highlightedDocumentRows.push(rowDocumento);
  }

  downloadDocumento(documento: Documento) {
    if (documento.downloadUsuarioExterno == false) {
      this.messageService.messageWarning("Você não possui permissão para visualizar o documento.");
    } else {
      if (documento.status == 2) {
        this.documentoService.download(documento.id).subscribe(
          (blob) => {
            if (!Utils.downloadBlob(blob)) {
              this.messageService.messageWarning("Documento ainda não existe.");
            }
          },
          (error) => {
            console.error(error);
            const erro = Utils.converterArrayBufferParaJSON(error);
            this.messageService.messageErro(erro.error.message);
          }
        );
      } else {
        this.messageService.messageWarning("Documento pendente de armazenamento interno na SEFAZ.");
      }
    }
  }

  downloadDocumentoProcesso($event, idProcesso: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.documentoService.downloadDocumentoProcesso(idProcesso).subscribe(
      (blob) => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Não existe documento no processo.");
        }
      },
      (error) => {
        console.error(error);
        const erro = Utils.converterArrayBufferParaJSON(error);
        this.messageService.messageErro(erro.error.message);
      }
    );
  }

  getRecordProcesso(rowProcesso: any) {
    this.carregarDocumentosEMovimentacoes(rowProcesso.id);
  }

  clickRow(rowProcesso: any) {
    this.selectProcesso(rowProcesso);
    this.carregarDocumentosEMovimentacoes(rowProcesso.id);
  }

  carregarDocumentosEMovimentacoes(id: number) {
    this.processoService.getProcessoContribuinte(id, this.documentoRepresentante).subscribe(
      (processo) => {
        this.processo = processo;
        this.hiddenDataSelectedProcesso = false;
        this.documentoService.listarDocumentosByIdProcesso(processo.id).subscribe((documentos) => {
          this.dataSourceDocumento = new MatTableDataSource<Documento>(documentos);
          this.dataSourceDocumento.paginator = this.paginatorDocumentos;
        });

        this.dataSourceMovimentacao = new MatTableDataSource<Movimentacao>(processo.listMovimentacao);
        this.dataSourceMovimentacao.paginator = this.paginatorMovimentacoes;

        this.assuntoService.get(processo.assunto.id).subscribe(
          (assunto) => {
            processo.assunto = assunto;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );

        this.abrirAccordion = true;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    ),
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      };
  }

  adicionarDocumento() {
    this.openDialog(this.processo.id);
  }

  adicionarDocumentoPorListagem() {
    const processso = this.highlightedAguardandoManifestacaoRows[0];
    this.openDialog(processso.id);
  }

  selectProcesso(rowProcesso) {
    this.highlightedAguardandoManifestacaoRows.pop();
    this.highlightedAguardandoManifestacaoRows.push(rowProcesso);
  }

  openDialog(id: number): void {
    this.dialog
      .open(IncluirDocumentoComponent, {
        width: "700px",
        data: id,
      })
      .afterClosed()
      .subscribe((result) => {
        // FIXME Verificar se houve mudança no dialog?
        this.carregarDocumentosEMovimentacoes(this.processo.id);
      });
  }

  buscarOcorrenciasSolicitante() {
    this.ocorrenciaService.listarOcorrenciaPorDocumentoSolicitante(this.documentoRepresentante).subscribe(
      (ocorrencias) => {
        this.dataSourceOcorrencias = new MatTableDataSource<OcorrenciaListarDTO>(ocorrencias);
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  getOcorrencia(row: any) {
    this.highlightedOcorrenciasRows.pop();
    this.highlightedOcorrenciasRows.push(row);
  }

  responderOcorrencia() {
    if (this.highlightedOcorrenciasRows[0] != null) {
      const ocorrencia = this.highlightedOcorrenciasRows[0];
      if (ocorrencia && ocorrencia.situacao && ocorrencia.situacao == "PENDENTE") {
        this.openDialogResponderOcorrencia(ocorrencia);
      } else {
        this.messageService.messageWarning("Ocorrência já foi concluída");
      }
    } else {
      this.messageService.messageWarning("Selecione uma ocorrência");
    }
  }

  openDialogResponderOcorrencia(ocorrencia: OcorrenciaListarDTO) {
    const dialogReponderOcorrencia = this.dialog.open(DialogResponderOcorrenciaProcesso, {
      width: "1000px",
      data: ocorrencia,
    });
    dialogReponderOcorrencia.afterClosed().subscribe(() => {
      this.buscarOcorrenciasSolicitante();
    });
  }
}
