export enum TipoAberturaProcessoEnum {
  CONTRIBUINTE_PROCESSO_PROPRIO,
  FISCAL_PROCESSO_CONTRIBUINTE,
  FISCAL_PROCESSO_PROPRIO,
  FISCAL_PROCESSO_OUTRO_FISCAL,
  FISCAL_PROCESSO_LOTACAO
}

export const TipoAberturaProcessoEnumAll = [
  { id: 0, descricao: TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO },
  { id: 1, descricao: TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE },
  { id: 2, descricao: TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO },
  { id: 3, descricao: TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL },
  { id: 4, descricao: TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO }
];