import { Instituidor } from "./instituidor.model";
import { Requerente } from "./requerente.model";

export class CondicoesParaTrazerDocs {
  mortePresumida: boolean;
  morteAcidental: boolean;
  recebeOutroBeneficio: boolean;
  pensaoDividida: boolean;
  pensaoMilitar: boolean;
  militarInativo: boolean;
  universitario: boolean;
  tuteladoInvalido: boolean;
  possuiOutroDependente: boolean;
  possuiRelacionamento: boolean;
  tipoRelacionamentoCasamento: boolean;
  falecidoEm: string;

  copiarAtributosInstituidor(instituidor: Instituidor) {
    this.mortePresumida = instituidor.mortePresumida;
    this.pensaoMilitar = instituidor.pensaoMilitar;
    this.morteAcidental = instituidor.morteAcidental;
    this.falecidoEm = instituidor.falecidoEm;
    this.militarInativo = this.situacaoFuncional(instituidor);
    return this;
  }

  copiarAtributosRequerente(requerente: Requerente) {
    this.recebeOutroBeneficio = requerente.recebeOutroBeneficio;
    this.pensaoDividida = requerente.pensaoDividida;
    this.universitario = requerente.universitario;
    this.possuiOutroDependente = requerente.possuiOutroDependente;
    this.possuiRelacionamento = requerente.possuiRelacionamento;
    this.tipoRelacionamentoCasamento = requerente.tipoRelacionamentoCasamento;

    return this;
  }

  situacaoFuncional(instituidor) {
    if (instituidor.falecidoEm === 0) {
      return false;
    } else {
      return true;
    }
  }
}
