import { Domain } from "../../global/models/domain.model";
import { EnvolvidoProcessoDTO } from "./envolvido-processo-dto.model";
import { TipoDocumento } from "./tipo-documento.model";

export class OcorrenciaListarDTO extends Domain {
    numeroProcesso: string;
    envolvido: EnvolvidoProcessoDTO;
    descricao: string;
    tipoDocumento: TipoDocumento;
    solicitadoEm: string;
    situacao: string;
    ativo: boolean;
}