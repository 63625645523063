export enum TipoArquivamentoEnum {
  AGUARDANDO = ("AGUARDANDO PUBLICAÇÃO DE NOVO PROCESSO"),
  DEFERIDO = ("DEFERIDO"),
  DESISTENCIA = ("DESISTÊNCIA"),
  FINALIZADO = ("FINALIZADO"),
  INDEFERIDO = ("INDEFERIDO"),
  PARCIALMENTE = ("PARCIALMENTE DEFERIDO"),
  PERDA = ("PERDA DO OBJETO")
}

export const TipoArquivamentoEnumAll = [
  { id: 0, descricao: TipoArquivamentoEnum.AGUARDANDO },
  { id: 1, descricao: TipoArquivamentoEnum.DEFERIDO },
  { id: 2, descricao: TipoArquivamentoEnum.DESISTENCIA },
  { id: 3, descricao: TipoArquivamentoEnum.FINALIZADO },
  { id: 4, descricao: TipoArquivamentoEnum.INDEFERIDO },
  { id: 5, descricao: TipoArquivamentoEnum.PARCIALMENTE },
  { id: 6, descricao: TipoArquivamentoEnum.PERDA }
];