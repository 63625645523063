import { ApiService } from "./api.service";
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class AssuntoService extends ApiService {
  public API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "assunto", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  getAssuntoByAssuntoCategoria(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/assunto-categoria/${id}`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  getAssuntoSemGrupo(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/sem-grupo`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  alternarAtivo(id: number) {
    return this.http.post(`${this.API_URL}${this.resource}/alternarAtivo/${id}`, null).pipe(map(response => response));
  }

  getAssuntoByTipoDocumento(tipoDocumentoId: number) {
    return this.http.get(`${this.API_URL}${this.resource}/assunto-tipo-documento/${tipoDocumentoId}`).pipe(map(response => response));
  }

  deleteAssuntoTipoDocumentoByTipoDocumento(tipoDocumentoId: number) {
    return this.http.get(`${this.API_URL}${this.resource}/delete-assunto-tipo-documento/${tipoDocumentoId}`).pipe(map(response => response));
  }

}