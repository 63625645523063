import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilService } from 'src/app/core/api/seguranca/perfil.service';
import { PermissaoService } from 'src/app/core/api/seguranca/permissao.service';
import { Perfil } from 'src/app/core/models/seguranca/perfil.model';
import { Permissao } from 'src/app/core/models/seguranca/permissao.model';
import { rowsAnimation } from 'src/app/global/animations/table.animation';
import { MessageService } from 'src/app/global/message/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sgeap-manter-permissoes',
  templateUrl: './manter-permissoes.component.html',
  styleUrls: ['./manter-permissoes.component.css'],
  animations: [rowsAnimation]
})
export class ManterPermissoesComponent {

  displayedColumns: string[] = ['nome', 'acoes'];
  dataSourceTodas: MatTableDataSource<Permissao>;
  dataSourceSelecionadas: MatTableDataSource<Permissao>;

  perfil: Perfil;

  constructor(private perfilService: PerfilService,
    private permissaoService: PermissaoService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService) {
    const id = this.route.snapshot.params.id;
    if (id) {
      this.get(id);
    }
    this.permissaoService.pesquisarDisponiveisPorPerfil(id).subscribe(p => {
      this.dataSourceTodas = new MatTableDataSource(p);
    });
    this.permissaoService.pesquisarPorPerfil(id).subscribe(p => {
      this.dataSourceSelecionadas = new MatTableDataSource(p);
    });
  }

  filtrarTodas(valor: string) {
    this.dataSourceTodas.filter = valor.trim().toLowerCase();
  }

  filtrarSelecionadas(valor: string) {
    this.dataSourceSelecionadas.filter = valor.trim().toLowerCase();
  }

  adicionar(permissao: Permissao) {
    if (this.dataSourceSelecionadas.data.find(p => p.id == permissao.id)) {
      this.messageService.messageInfo("Perfil já possui a permissão adicionada.");
      return;
    }
    this.dataSourceSelecionadas.data.push(permissao);
    this.dataSourceSelecionadas.filter = "";

    const index = this.dataSourceTodas.data.indexOf(permissao);
    this.dataSourceTodas.data.splice(index, 1);
    this.dataSourceTodas._updateChangeSubscription();

  }

  remover(index: number, permissao: Permissao) {
    this.dataSourceSelecionadas.data.splice(index, 1);
    this.dataSourceSelecionadas._updateChangeSubscription();

    this.dataSourceTodas.data.push(permissao);
    this.dataSourceTodas.filter = "";
  }

  salvar(): void {
    this.perfil.permissoes = [];
    this.dataSourceSelecionadas.data.forEach(p => {
      this.perfil.permissoes.push(p);
    });

    this.perfilService.put(this.perfil).subscribe(res => {
      this.messageService.messageSuccess("Permissões salvas com sucesso.");
      this.router.navigate([`${environment.CONTEXTO}/perfil`]);
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

  private get(id: number): void {
    this.perfilService.get(id).subscribe(res => {
      this.perfil = res;
    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

}
