import { Domain } from "../../global/models/domain.model";
import { Usuario } from "./usuario.model";
import { Assinador } from "./assinador.model";

export class Assinatura extends Domain {
    public descricao: string;
    public dataCriacao: Date;
    public dataAssinado: Date;
    public assinado: boolean;
    public responsavel: Usuario;

    // Usado para lógica de front
    public assinador: Assinador;
}
