import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { QueueMessage } from "src/app/core/models/queue-message.model";
import { ActiveMQService } from "src/app/core/api/activemq.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-filas",
  templateUrl: "./listar-filas.component.html",
  styleUrls: ["./listar-filas.component.css"]
})
export class ListarFilasComponent extends ListarGenericsComponent<QueueMessage> {

  public dataSource: MatTableDataSource<QueueMessage>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public activemqService: ActiveMQService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService) {
    super(activemqService, messageService, sharedService, toastr, router, "reprocessar-amq",
      ["nomeFila", "info"]);
  }

  public isFilaDLQ(nomeFila: string) {
    return nomeFila.startsWith("DLQ.");
  }
  
  public visualizarFila(nomeFila: string): void {
    this.router.navigate([`${this.contexto}/${this.endpoint}/fila/${nomeFila}`]);
  }

}
