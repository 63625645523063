import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class CidadaoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "sisseg/usuario", config);
    this.API_URL = config.API_SISSEG_URL;
  }

  getListarAnalista(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/analistas`).pipe(map((response) => response));
  }

  getUsuariosPorOrgao(idOrgao: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/orgao/${idOrgao}`).pipe(map((response) => response));
  }

  salvarUsuario(data: any): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/sisseg/usuario`, data).pipe(map((response) => response));
  }
}
