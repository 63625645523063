import { Component, OnInit, ViewChild } from "@angular/core";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { Modelo } from "src/app/core/models/modelo.model";
import { ModeloService } from "src/app/core/api/modelo.service";
import { MessageService } from "src/app/global/message/message.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { TipoDocumento } from "src/app/core/models/tipo-documento.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-modelo",
  templateUrl: "./listar-modelo.component.html",
  styleUrls: ["./listar-modelo.component.css"]
})
export class ListarModeloComponent extends ListarGenericsComponent<Modelo> implements OnInit {
  public dataSource: MatTableDataSource<Modelo>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  resultCount = 0;

  public modeloFiltro =  new Modelo();

  public tipoDocumentos: TipoDocumento[] = [];

  filterValues = {
    nome: ""
  };

  constructor(public apiService: ModeloService,
    private tipoDocumentoService: TipoDocumentoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "modelo",
      // ["nome", "tipoDocumento", "publicoLocal", "publicoGlobal", "lotacao", "removerOpcao"]);
      ["nome", "tipoDocumento", "publicoLocal", "publicoGlobal", "removerOpcao"]);
      this.dataSource = new MatTableDataSource<Modelo>([]);

  }

  ngOnInit() {
    this.tipoDocumentoService.getTipoDocumentoEditorPorSetor().subscribe(
      tipoDocumentos => {
        this.tipoDocumentos = tipoDocumentos;
        this.tipoDocumentoService.getTipoDocumentoSomenteAnexo(false).subscribe(
          (tipoDocumentosEditorGlobal: TipoDocumento[]) => {
            if (tipoDocumentosEditorGlobal) {
              this.tipoDocumentos.push.apply(this.tipoDocumentos, tipoDocumentosEditorGlobal);

              this.tipoDocumentos.sort((obj1: TipoDocumento, obj2: TipoDocumento) => {
                if (obj1.nomeDocumento.toLocaleLowerCase() > obj2.nomeDocumento.toLocaleLowerCase()) {
                  return 1;
                }
                if (obj1.nomeDocumento.toLocaleLowerCase() < obj2.nomeDocumento.toLocaleLowerCase()) {
                  return -1;
                }
                return 0;
              });
            }
          }
        );
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public pageEvent(event) {
    this.pesquisar(event.pageIndex, event.pageSize);
  }

  pesquisar(page: number, size: number) {
    this.apiService.pesquisar(this.modeloFiltro, page, size).subscribe(
      (listaModelos: any) => {
        if (listaModelos == null || listaModelos.content == null) {
          this.messageService.messageInfo("Nenhum processo encontrado!");
          return;
        }
        this.dataSource.data = listaModelos.content;
        this.resultCount = listaModelos.totalElements;
      },
      excecao => {
        this.messageService.messageErro(excecao.error.message);
        throw excecao;
      }
    );
  }

  remover(modelo: Modelo) {
    const message = "Realmente deseja deletar o modelo: ".concat(modelo.nome).concat("?");
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.apiService.delete(modelo.id).subscribe(() => {
          this.messageService.messageSuccess("Modelo deletado com sucesso.");
          this.pesquisar(0, this.paginator.pageSize);
        });
      }
    });
  }

  // Chamada da função na página
  private limparBusca() {
    this.modeloFiltro = new Modelo();
    this.dataSource.data = [];
    this.resultCount = 0;

  }
}
