import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { LoadingScreenService } from "../api/loading-screen";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.css"]
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  public loading: any;
  public loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingScreenService, private chRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
      debounceTime(200)
    ).subscribe((value) => {
      this.loading = value;
      this.chRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
