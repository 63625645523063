export class RequestCompleteNewDocPadesSignatureExpress {
    public fileToSign: string;
    public transferFileId: string;
    public signature: string;
    public hashDocumento: string;

    public descricao: string;
    public descricaoAssunto: string;
    public tipoDocumentoId: number;

    public autor: string;
    public fileName: string;

    public descricaoAssinatura: string;
    public docAssinantes: string[];

    public processoId: number;
    public downloadUsuarioExterno: boolean;
}