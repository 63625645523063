import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Contribuinte } from "../../../core/models/contribuinte.model";
import { ContribuinteService } from "../../../core/api/contribuinte.service";
import { MessageService } from "../../message/message.service";
import { Router } from "@angular/router";
import { SharedService } from "../../api/shared.service";
import { DialogSelecaoFilialComponent } from "../dialog-selecao-filial/dialog-selecao-filial.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-cadastro-contribuinte",
  templateUrl: "./cadastro-contribuinte.component.html",
  styleUrls: ["./cadastro-contribuinte.component.css"]
})
export class CadastroContribuinteComponent implements OnInit {

  @ViewChild(NgForm) form: NgForm;
  @ViewChild("cpfCnpjv") cnpfCnpjv;
  @ViewChild("email") email;

  public message: string;
  public contribuinte: Contribuinte;
  public disabled: boolean;
  public disabledDocumento: boolean = false;
  public opcao: string;

  constructor(
    private contribuinteService: ContribuinteService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private contribuinteSevice: ContribuinteService,
    private router: Router,
    public dialog: MatDialog) {
      this.contribuinte = new Contribuinte();
  }

  ngOnInit() {
    this.opcao = history.state.data.opcao;
    this.contribuinte = history.state.data.contribuinte;
    if (!this.contribuinte) {
      this.contribuinte = new Contribuinte();
    }

    if (this.opcao === "1") {
      this.setDocumento();
      if (this.contribuinte && this.contribuinte.matrizInativa) {
        this.exibirFormularioCadastro();
      }
    } else if(this.opcao === "3") {
      this.disabled = true;
    }
  }

  private setDocumento() {
    const documento = this.sharedService.getDocumentoRepresentante();

    if (documento) {
      this.contribuinte.documento = documento.replace(/['"]+/g, "");
      this.disabledDocumento = true;
      this.contribuinte.local = true;
    }
  }

  public entrar() {

    if (this.cnpfCnpjv.invalid) {
      this.messageService.messageErro("Cpf/Cnpj Inválido!");
      return;
    }

    if (this.contribuinte.local && this.email.invalid) {
      this.messageService.messageErro("Email inválido!");
      return;
    }

    if (this.contribuinte.local && this.form.invalid) {
      this.messageService.messageErro("Preencha os campos obrigatórios!");
      return;
    }

    if (this.contribuinte.local) {
      if (this.contribuinte.id === null || this.contribuinte.id === undefined) {
        this.contribuinteService.post(this.contribuinte).subscribe(
          (contribuinte) => {
            this.messageService.messageSuccess("Contribuinte cadastrado com sucesso!", contribuinte.nome);
            this.setDocumentoRepresentante(contribuinte);
            this.router.navigate(["home"]);
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        this.contribuinte.documento = this.contribuinte.documento.replace(/\D/g, "");
        this.contribuinteService.updateContribuinte(this.contribuinte).subscribe(
          (contribuinte) => {
            this.messageService.messageSuccess("Contribuinte atualizado com sucesso!", contribuinte.nome);
            this.setDocumentoRepresentante(contribuinte);
            this.router.navigate(["home"]);
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    } else {
      this.setDocumentoRepresentante(this.contribuinte);
      this.router.navigate(["home"]);
    }
  }

  private setDocumentoRepresentante(contribuinte: Contribuinte) {
    if (this.opcao === "1") {
      this.sharedService.setDocumentoRepresentante(null);
      this.sharedService.setDocumentoRepresentado(contribuinte.documento);
    } else {
      this.sharedService.setDocumentoRepresentado(contribuinte.documento);
    }
  }

  public voltar() {
    this.router.navigate(["entrada-contribuinte"]);
  }

  public verificaCNPJ() {
    // tslint:disable-next-line:triple-equals
    if (this.contribuinte.documento.length == 18) {
      // tslint:disable-next-line:quotemark
      const documento = this.contribuinte.documento.replace(/\D/g, '');
      // tslint:disable-next-line:max-line-length
      this.contribuinte.documento = documento.substr(0, 2) + "." + documento.substr(2, 3) + "." + documento.substr(5, 3) + "/" + documento.substr(8, 4) + "-" + documento.substr(12, 2);
    }
  }


  public onEnterPequisar(event) {
    if (event.key === "Enter") {
        this.pesquisar();
    }
  }

  public onEnter(event) {
    if (event.key === "Enter") {
      this.entrar();
    }
  }

  public pesquisar() {
    if (this.cnpfCnpjv.invalid) {
      this.messageService.messageErro("CPF/CNPJ Inválido");
      return;
    }

    this.contribuinteService.loginContribuinte(this.opcao, this.cnpfCnpjv.value.replace(/\D/g, "")).subscribe(
      (contribuinte) =>  {
        if (contribuinte == null) {
          this.disabled = false;
          this.contribuinte = new Contribuinte();
          this.contribuinte.local = true;
          this.contribuinte.documento = this.cnpfCnpjv.value.replace(/\D/g, "");
          this.messageService.messageWarning("Cadastro não localizado. Por favor, preencha os campos para prosseguir com o cadastro.");
          this.disabledDocumento = true;
        } else {
          this.contribuinte = contribuinte[0];
          this.contribuinte.documento = this.cnpfCnpjv.value;
          this.contribuinte.local = contribuinte[0].local;
          this.disabled = false;
          this.disabledDocumento = true;
        }
      },
      error => {
        this.messageService.messageErro(error.error.message);
        this.router.navigate(["entrada-contribuinte"]);
        throw error;
      });
    }

  public limpar(){
    this.disabled = true;
    this.disabledDocumento = false;
  }

  prepararCadastro(){
    this.disabled = false;
    this.contribuinte = new Contribuinte();
    this.contribuinte.local = true;
    this.contribuinte.documento = this.cnpfCnpjv.value.replace(/\D/g, "");
    this.messageService.messageWarning("Cadastro não localizado. Por favor, preencha os campos para prosseguir com o cadastro.");
    this.disabledDocumento = true;
  }

  verificarMatrizAtiva(contribuinte: Contribuinte[]){
    const cnpjDigitado = JSON.stringify(this.cnpfCnpjv.value.replace(/\D/g, ""));
    let matrizAtiva = false;
    contribuinte.forEach( contribuinte => {
      if (cnpjDigitado === JSON.stringify(contribuinte.documento)){
        matrizAtiva = true;
      }
    });

    return matrizAtiva;
  }

  criarContribuinteMatrizInativa(nome: string) {
    const contribuinteMatrizInativa = new Contribuinte;
    contribuinteMatrizInativa.documento = this.cnpfCnpjv.value.replace(/\D/g, "");
    contribuinteMatrizInativa.matrizInativa = true;
    contribuinteMatrizInativa.nome = nome;

    return contribuinteMatrizInativa;
  }

  redirecionarParaHome(contribuinteSelecionado){
    this.contribuinte = contribuinteSelecionado;
    this.contribuinte.documento = contribuinteSelecionado.documento;
    this.disabled = false;
    this.disabledDocumento = true;
    this.setDocumentoRepresentante(contribuinteSelecionado);
    this.router.navigate(["home"]);
  }

  exibirFormularioCadastro() {
    this.disabled = false;
    this.contribuinte.local = true;
    this.messageService.messageWarning("Cadastro não localizado. Por favor, preencha os campos para prosseguir com o cadastro.");
    this.disabledDocumento = true;
  }

}