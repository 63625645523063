
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { ResponderOcorrenciaDTO } from "../models/responder-ocorrencia-dto.model";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class OcorrenciaService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "ocorrencia", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  public listarOcorrenciaPorProcessoId(processoId: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/buscar-por-processo-id/${processoId}`).pipe((response => response));
  }

  public listarOcorrenciaPorDocumentoSolicitante(documento: string): any {
    return this.http.get(`${this.API_URL}${this.resource}/buscar-ocorrencias-por-documento-solicitante/${documento}`).pipe((response => response));
  }

  public responderOcorrencia(body: FormData): any {
    return this.http.post(`${this.API_URL}${this.resource}/responder-ocorrencia`, body).pipe((response => response));
  }

  public alterarVisibilidade(ocorrenciaId: number): Observable<any> {
    return this.http
      .put(`${this.API_URL}${this.resource}/alterar-visibilidade/${ocorrenciaId}`, null)
      .pipe((response => response));
  }

}