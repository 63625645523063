
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SharedService } from "src/app/global/api/shared.service";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class MetadadoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "metadado", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }
  public listaMetadados(listaMetadadoId: number[]): Observable<any> {
    return this.http.get(`${this.API_URL}/lista?ids=${listaMetadadoId}`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  // size = 0, trazer todos.
  public pesquisar(metadado: any, page: number = 0, size: number = SharedService.PAGE_SIZE): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, metadado).pipe(map(response => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent != null && responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      }));
  }

  public sistema(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/sistema`).pipe(map(response => response));
  }
}