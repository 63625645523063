import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { SharedService } from "./global/api/shared.service";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {

  public token = "";

  constructor(private router: Router, private sharedService: SharedService,
    private zone: NgZone, @Inject(APP_CONFIG) public config: AppConfig) {

    this.router.events.subscribe((event: any) => {

      if (event.url !== undefined && event.url === "/") {
        this.router.navigate([config.CONTEXTO]);
      }
      if (this.sharedService.getToken()) {
        this.sharedService.checkTokenExpirado();
      }
    });
  }

  ngOnInit(): void {

  }
}