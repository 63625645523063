import { Component, NgZone } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { environment } from "src/environments/environment";
import { AutenticacaoService } from "../../api/seguranca/autenticacao.service";
import { TIPO_LOGIN } from "../../api/seguranca/tipo-login.enum";
import { SharedService } from "../../api/shared.service";
import { MessageService } from "../../message/message.service";
import { LoginTO } from "../../models/login-to.model";
import { TokenTO } from "../../models/token.model";
import HttpStatusCode from "../../util/http-status-code.enum";

@Component({
  selector: "sgeap-login-usuario-senha",
  templateUrl: "./login-usuario-senha.component.html",
  styleUrls: ["./login-usuario-senha.component.css"],
})
export class LoginUsuarioSenhaComponent {
  [x: string]: any;
  public form: FormGroup;
  public message: string;
  public senha: string;
  public senhaVisivel: boolean = false;

  loginTO: LoginTO = new LoginTO();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private autenticacaoService: AutenticacaoService,
    private sharedService: SharedService,
    private messageService: MessageService,
    private zone: NgZone,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  entrar(): void {
    var self = this;
    this.autenticacaoService.autenticar(this.loginTO).subscribe(
      (response) => {
        this.sharedService.logar(response.token);
        const token: TokenTO = this.sharedService.decodePayloadJWT();
        if (environment.TIPO_LOGIN == TIPO_LOGIN.FACE_ID && !token.email) {
          this.router.navigate(["completar-cadastro"]);
        } else {
          self.sharedService.setDocumentoRepresentante(token.sub);
          self.zone.run(() => self.router.navigate([environment.CONTEXTO, "lista-processo-tabela"]));
        }
      },
      (error) => {
        if (error.status == 500) {
          this.messageService.messageErro("Usuário ou senha incorreta.");
        } else {
          this.messageService.messageErro("Erro ao autenticar. Por favor, tente mais tarde...");
        }
        throw error;
      }
    );
  }
  toggleSenhaVisivel() {
    this.senhaVisivel = !this.senhaVisivel;
  }
}
