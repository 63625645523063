import { Component, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { LotacaoSecundaria } from "src/app/core/models/lotacao-secundaria.model";
import { LotacaoSecundariaDto } from "src/app/core/models/lotacao-secundaria-dto.model";
import { LotacaoSecundariaService } from "src/app/core/api/lotacao-secundaria.service";
import { SisSegService } from "src/app/core/api/sis-seg.service";
import { Pessoa } from "src/app/core/models/pessoa.model";
import { forkJoin, Observable } from "rxjs";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-lotacao-secundaria",
  templateUrl: "./listar-lotacao-secundaria.component.html",
  styleUrls: ["./listar-lotacao-secundaria.component.css"]
})
export class ListarLotacaoSecundariaComponent extends ListarGenericsComponent<LotacaoSecundaria> {
  public dataSource: MatTableDataSource<LotacaoSecundaria>;


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public apiService: LotacaoSecundariaService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService
    // public SisSegService: SisSegService
    ) {
    super(apiService, messageService, sharedService, toastr, router, "lotacao-secundaria",
      ["nome", "lotacoes",  "removerOpcao"]);
  }

  public listar() {
    this.apiService.list().subscribe((listaLotacaoSecundaria: LotacaoSecundariaDto[]) => {
      this.buscarUsuario(listaLotacaoSecundaria).subscribe((listaPessoas: Pessoa[]) => {
        listaLotacaoSecundaria.forEach(lotacaoSecundaria => {
          const pessoaDaLotacao = listaPessoas.find(pessoa => pessoa.matricula === lotacaoSecundaria.usuario);
          lotacaoSecundaria.usuarioNome = pessoaDaLotacao.nome;
          this.listarSiglas(lotacaoSecundaria);
        });

        listaLotacaoSecundaria.sort((a, b) => (a.usuarioNome < b.usuarioNome) ? -1 : 1);

        this.dataSource = new MatTableDataSource<any>(listaLotacaoSecundaria);
        this.dataSource.paginator = this.paginator;
      });

    },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public editar(documento: string): void {
    this.router.navigate([`sgeap/lotacao-secundaria/editar/${documento}`]);
  }

  buscarUsuario(listaLotacaoSecundaria: LotacaoSecundariaDto[]): Observable<Array<any>> {
    const observableBatch = listaLotacaoSecundaria.map((lotacaoSecundaria, key) => {
      this.messageService.messageWarning("Implementar consulta de servidores por documento.");
      // return this.SisSegService.listServidorByDocumento(lotacaoSecundaria.usuario);
    });

    return forkJoin(observableBatch);
  }

  public listarSiglas(lotacaoSecundaria: LotacaoSecundariaDto) {
    lotacaoSecundaria.listSigla = lotacaoSecundaria.listLotacao.map(officer => officer.orgaoLocal.sigla);
  }
}
