import { Component, OnInit } from "@angular/core";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { ParametroDiverso } from "src/app/core/models/parametro-diverso.model";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ParametroDiversoService } from "src/app/core/api/parametro-diverso.service";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "src/app/global/message/message.service";
import { MatDialog, MatDatepickerInputEvent } from "@angular/material";
import { TipoValorParametroEnumAll } from "src/app/core/models/tipo-valor-parametro-enum.model";
import { Utils } from "src/app/global/util/utils-component";
import moment, * as _moment from "moment";

@Component({
  selector: "app-salvar-parametro-diverso",
  templateUrl: "./salvar-parametro-diverso.component.html",
  styleUrls: ["./salvar-parametro-diverso.component.css"]
})
export class SalvarParametroDiversoComponent extends SalvarGenericsComponent<ParametroDiverso> {

  public ckeConfig: any;

  public tipoValorParametroList: any[] = TipoValorParametroEnumAll;
  public valorDataInput: string;
  public valorData: Date;
  public valorTexto: string;
  public valorNumero: number;
  public valorHtml: string;
  constructor(
      public formBuilder: FormBuilder,
      public router: Router,
      public apiService: ParametroDiversoService,
      public toastr: ToastrService,
      public route: ActivatedRoute,
      public messageService: MessageService,
      public dialog: MatDialog
    ) {
    super(formBuilder, router, apiService, messageService, route, "parametro-diverso", "");
  }

  ngOnInit() {
    this.confiCkEditor();
  }

  afterResponse() {

    if (this.domain && this.domain.id) {
      if (this.domain.tipoValorParametro === 1) {
        const dateParts = this.domain.valor.split("-");
        this.valorData = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      } else {
        this.valorHtml = this.domain.valor;
        this.valorTexto = this.domain.valor;
        this.valorNumero = this.domain.valor;
      }
    }

  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language : "pt",
      font_names: "Arial;Times New Roman;Verdana",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] }
      ],

      removeButtons: "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,"
        + "Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,"
        + "CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,"
        + "Iframe,Maximize,ShowBlocks,About"
    };
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.chave) {
      this.messageService.messageErro("Preencha o campo Chave.");
      validacao = false;
    }
    if (!domain.valor) {
      this.messageService.messageErro("Preencha o campo Valor.");
      validacao = false;
    }
    if (domain.tipoValorParametro === null || domain.tipoValorParametro === undefined) {
      this.messageService.messageErro("Preencha o campo Tipo Valor.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (domain.tipoValorParametro === 3 && this.valorHtml) {
      domain.valor = this.valorHtml;
    }
    if (domain.tipoValorParametro === 1 && this.valorData) {
      domain.valor = Utils.formatDate(this.valorData);
    }
    if (domain.tipoValorParametro === 2 && this.valorTexto) {
      domain.valor = this.valorTexto;
    }
    if (domain.tipoValorParametro === 0 && this.valorNumero) {
      domain.valor = this.valorNumero;
    }

    if (this.validar(domain)) {
      this.submeter();
    }
  }

  compareTipo(obj1: any, obj2: any): boolean {
    return obj1 && obj2 && obj1 === obj2 ? true : false;
  }

  private formatDate(data: Date) {
    const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;
    return "" + dd + "-" + mm + "-" + y;
  }

  private setDataInput(type: string, event: string) {
    this.valorDataInput = event;
  }

  private setDataChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.valorData = event.value;
  }

  private setDataBlur() {
    this.valorData = moment(this.valorDataInput, "DD/MM/YYYY").toDate();
  }

}
