import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-dialog-aviso",
  templateUrl: "./dialog-aviso.component.html",
  styleUrls: ["./dialog-aviso.component.css"]
})
export class DialogAvisoComponent {

  avisos: any[];
 
  constructor(public dialogRef: MatDialogRef<DialogAvisoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]) {
    this.avisos = data;
  }
  
  onDismiss(): void {
    this.dialogRef.close();
  }

}
