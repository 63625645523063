import { Component, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Assunto } from "src/app/core/models/assunto.model";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { MessageService } from "src/app/global/message/message.service";
import { SharedService } from "src/app/global/api/shared.service";
import { DocumentoMotorRegras } from 'src/app/core/models/documento-motor-regras.model';

@Component({
  selector: 'app-listar-documento-motor-regras',
  templateUrl: './listar-documento-motor-regras.component.html',
  styleUrls: ['./listar-documento-motor-regras.component.css']
})
//TODO Verificar se essa classe é utilizada
export class ListarDocumentoMotorRegrasComponent extends ListarGenericsComponent<DocumentoMotorRegras> {
  
  public dataSource: MatTableDataSource<DocumentoMotorRegras>;

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public orgaoService: OrgaoService,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(null,messageService, sharedService, toastr, router, "documento/documentos-solicitante-dependente", ["descricao", "obrigatorio", "removerOpcao"]);
  }

}
