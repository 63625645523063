import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class AssinaturaService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "assinatura", config);
    this.API_URL = config.API_ASSINATURA_DIGITAL_URL;
  }

  getInfosCertificate(cert64: string): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/create-info`, cert64).pipe(map(response => response));
  }

  getAssinaturaByDocumento(id: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/?documento=${id}`).pipe(map(response => response));
  }

  getAssinaturaIndicadoByDocumento(id: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/?documento=${id}&indicado=true`).pipe(map(response => response));
  }

  start(body: any): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/startModuloAssinador`, body).pipe(map(response => response));
  }

  complete(fileToSign: string, transferFileId: string, signature: string): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/complete`, {
        fileToSign,
        transferFileId,
        signature,
      }, {responseType: "arraybuffer"})
      .pipe(map(response => new Blob([response], { type: "application/pdf"})));
  }
}