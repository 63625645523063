import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { FiscalPreferencia } from "src/app/core/models/fiscal-preferencia.model";
import { FiscalPreferenciaService } from "src/app/core/api/fiscal-preferencia.service";
import { ProcessoService } from "src/app/core/api/processo.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-fiscal-preferencia",
  templateUrl: "./listar-fiscal-preferencia.component.html",
  styleUrls: ["./listar-fiscal-preferencia.component.css"]
})
export class ListarFiscalPreferenciaComponent extends ListarGenericsComponent<FiscalPreferencia> {
  public dataSource: MatTableDataSource<FiscalPreferencia>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public apiService: FiscalPreferenciaService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService,
    public processoService: ProcessoService) {
    super(apiService, messageService, sharedService, toastr, router, "fiscal-preferencia",
      ["nome", "atual", "limite", "ativo", "removerOpcao"]);
  }

  afterResponseListar() {
    if (this.dataSource != null && this.dataSource.data != null) {
      
      this.dataSource.data.forEach((fiscalPreferencia: FiscalPreferencia) => {
        this.processoService.getQuantidadeProcessoPorMatricula(fiscalPreferencia.documentoUsuario).subscribe(
          (res: number) => {
            fiscalPreferencia.qtdAtual = res != null ? res : 0;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
      );

      this.dataSource.data = this.ordernarDescricaoOrdemAlfabetica(this.dataSource.data);
    }
  }

  public ordernarDescricaoOrdemAlfabetica(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.usuario.pessoa.nome > obj2.usuario.pessoa.nome) {
        return 1;
      }
      if (obj1.usuario.pessoa.nome < obj2.usuario.pessoa.nome) {
        return -1;
      }
      return 0;
    });

    return list;
  }

  inativar(fiscalPreferencia: FiscalPreferencia) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        fiscalPreferencia.ativo = !fiscalPreferencia.ativo;
        this.apiService.put(fiscalPreferencia).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
            return true;
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
          );
        } else {
          return false;
        }
      });
      
      return false;
  }
}
