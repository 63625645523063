import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";

@Component({
  selector: "app-dialog-data-inicio-fim",
  templateUrl: "./dialog-data-inicio-fim.component.html",
  styleUrls: ["./dialog-data-inicio-fim.component.css"]
})
export class DialogDataInicioFimComponent implements OnInit {

  public dataInicial: Date;
  public dataFinal: Date;

  constructor(
    public dialogRef: MatDialogRef<DialogDataInicioFimComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService) {
  }

  ngOnInit() {
  }

  onConfirm(): void {
    if (this.dataInicial != null && this.dataFinal != null) {
      // tslint:disable-next-line: max-line-length
      this.dialogRef.close({ dataInicial: Utils.formatDateMesDiaAno(this.dataInicial), dataFinal: Utils.formatDateMesDiaAno( this.dataFinal) });
    } else {
      this.messageService.messageInfo("Digite data inicial e final.");
    }
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}
