import { Component, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { AssuntoCategoria } from "src/app/core/models/assunto-categoria.model";
import { CategoriaAssuntoService } from "src/app/core/api/categoria-assunto.service";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-assunto-categoria",
  templateUrl: "./listar-assunto-categoria.component.html",
  styleUrls: ["./listar-assunto-categoria.component.css"]
})
export class ListarAssuntoCategoriaComponent extends ListarGenericsComponent<AssuntoCategoria> {
  public dataSource: MatTableDataSource<AssuntoCategoria>;
  public columns = [];
  public sections = [];
  public buttons = [];

  @ViewChild('child') child: any;

  ngAfterViewInit() {
    this.paginator = this.child.paginator
  }

  constructor(public apiService: CategoriaAssuntoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "assunto-categoria", ["descricao", "icone", "pai","ativo", "removerOpcao"]);

    this.sections = [
      {
        fields: [[
          {
            key: 'x3',
            fieldType: 'textInput',
            label: 'Novo Assunto Categoria',
            cols: [12,12,12,12,12]
          },
        ]]
      }
    ]

    this.buttons = [
      {
        label: 'Novo Assunto Categoria',
        click: () => {
          this.setPageState()
          this.router.navigateByUrl("/sgeap/assunto-categoria/salvar")
        }
      }
    ]

    this.columns =  [
      {
        key: 'descricao',
        label: 'Descrição',
      },
      {
        key: 'icone',
        label: 'Icone',
        type: 'icon',
        props: {
          icon: 'create',
          matTooltip: 'Editar',
          click: (e)=> {

          }
        }
      },
      {
        key: 'assuntoCategoriaPaiDescricao',
        label: 'Pai',
        parseItem: (e) =>  e || 'Não possui Pai'
      },
      {
        key: 'ativo',
        label: 'Ativo',
        type: 'toggle',
        props: {
          matTooltip:"Ativar/Desativar",
          color: "accent",
          click: (e) => this.inativar(e)
        }
      },
      {
        key: 'removerOpcao',
        label: 'Ações',
        type: 'icon',
        props: {
          icon: 'create',
          matTooltip: 'Editar',
          click: (e) => this.edit(e)
        }
      }
    ]
  }

  inativar(assuntoCategoria: AssuntoCategoria) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        assuntoCategoria.ativo = !assuntoCategoria.ativo;
        this.apiService.put(assuntoCategoria).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.");
            return true;
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

}