import { NgModule } from "@angular/core";
import { PreventDoubleClickDirective } from "../../global/directives/preventDoubleClick.directive";
import { NumberDirective } from "./numbers-only.directive";
import { AutofocusDirective } from "./auto-focus.directive";
import { DatecheckDirective } from "./datecheck.directive";
import { ValidadorCpfDirective } from "./validador-cpf.directive";
import { ValidadorEmailDirective } from "./validador-email.directive";

@NgModule({
    declarations: [PreventDoubleClickDirective,
        NumberDirective,
        AutofocusDirective,
        DatecheckDirective,
        ValidadorCpfDirective,
        ValidadorEmailDirective,
    ],
    exports: [
        PreventDoubleClickDirective,
        NumberDirective,
        AutofocusDirective,
        DatecheckDirective,
        ValidadorCpfDirective,
        ValidadorEmailDirective
    ],
    providers: []
})
export class DirectivesModule { }