import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { EmailTemplateService } from "../../api/email-template.service";
import { MessageService } from "src/app/global/message/message.service";



@Component({
  selector: "app-dialog-listar-tags-certificado",
  templateUrl: "./dialog-listar-tags.component.html",
  styleUrls: ["./dialog-listar-tags.component.css"]
})
export class DialogListarTagsComponent implements OnInit{

  listTags: string[];
 
  constructor(public dialogRef: MatDialogRef<DialogListarTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,private emailTemplateService: EmailTemplateService,
    private messageService: MessageService) {

  }
  
  ngOnInit() {
    this.emailTemplateService.listTags().subscribe(
      (res) => {
        this.listTags = res;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  onConfirm(): void {
    this.dialogRef.close(this.data);
  }
 
  onDismiss(): void {
    this.dialogRef.close();
  }

}
