import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Situacao } from "src/app/core/models/situacao.model";
import { SituacaoService } from "src/app/core/api/situacao.service";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-situacao",
  templateUrl: "./listar-situacao.component.html",
  styleUrls: ["./listar-situacao.component.css"]
})
export class ListarSituacaoComponent extends ListarGenericsComponent<Situacao> {
  public dataSource: MatTableDataSource<Situacao>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor( public apiService:  SituacaoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "situacao",
      ["descricao", "somenteStatus", "pendenciaAutor", "situacaoBloqueio", "situacaoFinal", "ativo", "removerOpcao"]);
  }

  inativar(situacao: Situacao) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        situacao.ativo = !situacao.ativo;
        this.apiService.put(situacao).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
            return true;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

}
