import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { ContribuinteService } from "../../api/contribuinte.service";
import { MatDialogRef, MatTableDataSource, MatPaginator, MAT_DIALOG_DATA } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { Responsavel } from "../../models/responsavel.model";
import { SelectionModel } from "@angular/cdk/collections";
import { Utils } from "src/app/global/util/utils-component";
import { SisSegService } from "../../api/sis-seg.service";

@Component({
  selector: "app-dialog-listar-servidores",
  templateUrl: "./dialog-listar-servidores.component.html",
  styleUrls: ["./dialog-listar-servidores.component.css"]
})
export class DialogListarServidoresComponent implements OnInit {

  public dataSourceServidor: MatTableDataSource<Responsavel>;
  public servidores: Responsavel[] = [];
  public servidoresList: Responsavel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public servidorSelecionado: Responsavel;
  public displayedServidorColumns: string[] = ["matricula", "nome"];
  public selection = new SelectionModel<Responsavel>(true, []);
  public buscaNome: string;
  public buscaMatricula: string;
  public titulo = "Selecione o Servidor";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogListarServidoresComponent>,
    private SisSegService: SisSegService,
    private messageService: MessageService
    ) {
    if (data != null && data.titulo != null) {
      this.titulo = data.titulo;
    }
  }

  ngOnInit() {
    this.buscarServidores();
  }

  private buscarServidores() {
    //this.messageService.messageWarning("Implementar consulta de servidores.");
     this.SisSegService.listServidores().subscribe(
       servidores => {
         this.servidores = servidores;
         if (this.data != null && this.data.contribuinte != null) {
           this.servidores.push(this.data.contribuinte);
         }
         this.servidoresList = servidores;
         Utils.ordernarNomeOrdemAlfabetica(this.servidores);
         this.dataSourceServidor = new MatTableDataSource<Responsavel>(servidores),
         this.dataSourceServidor.paginator = this.paginator;
       },
       error => {
         this.messageService.messageErro("Erro ao buscar Servidores: " + error.error.message);
         throw error;
       }
     );
  }

  selecionaServidor(servidor: Responsavel) {
    this.servidorSelecionado = servidor;
  }

  confirmarServidor() {
    this.dialogRef.close(this.servidorSelecionado);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  filtrarServidor() {
    this.selection.clear();
    this.servidores = this.servidoresList;
    if (this.buscaNome && this.buscaNome !== "") {
      const nome = this.buscaNome;
      this.servidores = this.servidores.filter(function (servidor) {
        return servidor.nome.toUpperCase().includes(nome.toUpperCase());
      });
    }
    if (this.buscaMatricula && this.buscaMatricula !== "") {
      const matricula = this.buscaMatricula;
      this.servidores = this.servidores.filter(function (servidor) {
        return servidor.matricula.toUpperCase().includes(matricula.toUpperCase());
      });
    }
    Utils.ordernarNomeOrdemAlfabetica(this.servidores);
    this.dataSourceServidor = new MatTableDataSource<Responsavel>(this.servidores),
    this.dataSourceServidor.paginator = this.paginator;
  }

}
