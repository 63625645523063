import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoadingScreenService } from "../api/loading-screen";
import { SharedService } from "../api/shared.service";


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  activeRequests = 0;

  skippUrls = [
    "/documento/start", "documento/complete"
  ];

  constructor(private loadingScreenService: LoadingScreenService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let displayLoadingScreen = true;
    const carregarLoading = request.params.get("carregarLoading");

    for (const skippUrl of this.skippUrls) {
      // tslint:disable-next-line:triple-equals
      if (new RegExp(skippUrl).test(request.url) &&
        // tslint:disable-next-line:triple-equals
        (carregarLoading != null && carregarLoading == SharedService.CARREGAR_LOADING_ASSINATURA)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (carregarLoading != null) {
      request.params.delete("carregarLoading");
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}