import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Responsavel } from "../../models/responsavel.model";
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { MessageService } from "../../../global/message/message.service";
import { Assinador } from "../../models/assinador.model";
import { ContribuinteService } from "../../api/contribuinte.service";
import { Utils } from "src/app/global/util/utils-component";
import { Processo } from "../../models/processo.model";
import { Documento } from "../../models/documento.model";

@Component({
  selector: "app-dialog-indicar-assinadores",
  templateUrl: "./dialog-indicar-assinadores.component.html",
  styleUrls: ["./dialog-indicar-assinadores.component.css"],
})
export class DialogIndicarAssinadoresComponent implements OnInit {
  public responsaveis: Responsavel[] = [];
  public responsaveisList: Responsavel[] = [];
  public listaAssinador: Assinador[] = [];
  buscaNome: string;
  public filtrarAssinadoresFavoritos = false;
  public displayedResponsavelColumns: string[] = ["select", "matricula", "nome", "favorito"];
  public dataSourceResponsavel: MatTableDataSource<Responsavel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public highlightedResponsavelRows: any[] = [];
  selection = new SelectionModel<Responsavel>(true, []);
  public filteredEvents = new SelectionModel<Responsavel>(true, []);
  public processo: Processo;
  public documento: Documento;

  constructor(
    public dialogRef: MatDialogRef<DialogIndicarAssinadoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contribuinteService: ContribuinteService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.processo = this.data.processo;
    this.documento = this.data.documento;
    this.buscarResponsaveis();
  }

  getPageData() {
    if (this.dataSourceResponsavel != null) {
      return this.dataSourceResponsavel._pageData(this.dataSourceResponsavel._orderData(this.dataSourceResponsavel.filteredData));
    }
    return [];
  }

  isEntirePageSelected() {
    return this.getPageData().every((row) => this.selection.isSelected(row));
  }

  masterToggle(checkboxChange: any) {
    this.isEntirePageSelected() ? this.selection.deselect(...this.getPageData()) : this.selection.select(...this.getPageData());
  }

  checkboxLabel(row): string {
    if (!row) {
      return `${this.isEntirePageSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  private buscarResponsaveis() {
    this.contribuinteService.findServidorEAutorProcesso(this.processo.autor ? this.processo.autor.documento : null).subscribe(
      (responsaveis) => {
        if (this.documento.id) {
          // Para quando documento já existir
          this.documento.listAssinatura.forEach((assinador) => {
            responsaveis = responsaveis.filter((responsavel) => responsavel.documento !== assinador.responsavel.documento);
          }, this);
        } else if (this.documento.listAssinatura) {
          // Para quando for um novo documento
          this.documento.listAssinatura.forEach((assinador) => {
            responsaveis = responsaveis.filter((responsavel) => responsavel.documento !== assinador.assinador.documento);
          }, this);
        }

        this.responsaveis = responsaveis;
        this.responsaveisList = responsaveis;
        (this.dataSourceResponsavel = new MatTableDataSource<Responsavel>(responsaveis)),
          (this.dataSourceResponsavel.paginator = this.paginator);
      },
      (error) => {
        this.messageService.messageErro("Erro na listagem " + error.error.message);
        throw error;
      }
    );
  }

  setAssinadorFavorito(isFavorito: boolean, responsavel: Responsavel): void {
    responsavel.assinadorFavorito = isFavorito;
    this.contribuinteService.atualizarAssinadorFavorito(responsavel).subscribe(
      (data) => {
        let message = "";
        if (responsavel.assinadorFavorito) {
          message = "Assinador " + responsavel.nome + " favoritado com sucesso!";
        } else {
          message = "Assinador " + responsavel.nome + " desfavoritado com sucesso!";
        }
        this.messageService.messageSuccess(message);
      },
      (err) => {
        responsavel.assinadorFavorito = false;
        this.messageService.messageErro("Erro ao favoritar assinador " + responsavel.nome);
        throw err;
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  incluirMarcados() {
    let sucesso = true;
    if (this.selection.selected.length === 0) {
      this.messageService.messageWarning("Nenhum responsável selecionado!");
      sucesso = false;
      return;
    }
    this.selection.selected.forEach((responsavel) => {
      if (this.processo.id) {
      

        const assinador = <any>{
          dataCriacao: new Date(),
          assinado: false,
          responsavel: responsavel,
        };

        this.documento.listAssinatura.push(assinador);
      } else {
        const assinador = new Assinador();
        assinador.nome = responsavel.nome;
        assinador.documento = responsavel.documento;
        assinador.dataHoraIndicacao = new Date();
        this.listaAssinador.push(assinador);
      }
    }, this);
    if (!this.processo.id) {
      this.data = this.listaAssinador;
    }
    if (sucesso) {
      this.dialogRef.close(this.data);
    }
  }

  filtrarResponsavel() {
    this.selection.clear();
    this.responsaveis = this.responsaveisList;
    if (this.buscaNome && this.buscaNome !== "") {
      const nome = this.buscaNome;
      this.responsaveis = this.responsaveis.filter(function (responsavel) {
        return responsavel.nome.toUpperCase().includes(nome.toUpperCase());
      });
    }
    if (this.filtrarAssinadoresFavoritos) {
      this.responsaveis = this.responsaveis.filter((assinador) => assinador.assinadorFavorito);
    }

    Utils.ordernarNomeOrdemAlfabetica(this.responsaveis);
    (this.dataSourceResponsavel = new MatTableDataSource<Responsavel>(this.responsaveis)),
      (this.dataSourceResponsavel.paginator = this.paginator);
  }
}
