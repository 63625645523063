import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-master-home",
  templateUrl: "./master-home.component.html",
  styleUrls: ["./master-home.component.css"]
})
export class MasterHomeComponent implements OnInit {

  public cruds = [
    { titulo: "Parâmetros Diversos", icone: "assets/icones/parametros_diversos.svg", rota: "/sgeap/parametro-diverso" },
    { titulo: "Numeração Documentos", icone: "assets/icones/numeracao_documentos.svg", rota: "/sgeap/numero-documento-detalhe" },
    { titulo: "DAE", icone: "assets/icones/dae.svg", rota: "/sgeap/lista-dae" },
    { titulo: "Reprocessar AMQ", icone: "assets/icones/fila.svg", rota: "/sgeap/reprocessar-amq" },
  ];

  constructor(public router: Router) { }

  ngOnInit(): void {}

}
