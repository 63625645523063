import { Cidadao } from 'src/app/core/models/seguranca/cidadao.model';
import { Domain } from "../../global/models/domain.model";
import { Usuario } from "./usuario.model";
import { GrupoAssunto } from "./grupo-assunto.model";
import { Orgao } from "./orgao.model";

export class Grupo extends Domain {
  id: number;
  nome: string;
  lotacao: number;
  listUsuarios: Usuario[] | Cidadao[];
  listGrupoAssunto: GrupoAssunto[];
  ativo: boolean;
  orgao: Orgao;
}