import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sections-container',
  templateUrl: './sections-container.component.html',
  styleUrls: ['./sections-container.component.css']
})
export class SectionsContainerComponent implements OnInit {

  @Input() sections = [];
  @Input() form = {};
  @Input() defaultXs?: number | 'auto';
  @Input() defaultSm?: number | 'auto';
  @Input() defaultMd?: number | 'auto';
  @Input() defaultLg?: number | 'auto';
  @Input() defaultXl?: number | 'auto';

  constructor() { }

  ngOnInit() {
  }

  handleParam(param: unknown, paramDefault: unknown) {
    return typeof param === 'function' ? param() : param !== undefined ? param : paramDefault
  }

  compare(obj1: any, obj2: any): boolean {
    if (typeof obj1 !== 'object' && typeof obj2 !== 'object') return obj1 === obj2
    if (typeof obj1 !== 'object') return obj1 === obj2.id
    if (typeof obj2 !== 'object')  return obj2 === obj1.id
    return obj1.id === obj2.id
  }

  uploadFile(file, field) {
    if(file && field) {
      field.input(file);
    }
    const fileListArquivoEnviando: FileList = file.target.files;
    const arquivo = fileListArquivoEnviando[0];
  }
}
