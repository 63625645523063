import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { EmailTemplate } from "src/app/core/models/email-template.model";
import { EmailTemplateService } from "src/app/core/api/email-template.service";
import { DialogListarTagsComponent } from "src/app/core/processo/dialog-listar-tags/dialog-listar-tags.component";
import { MatDialog } from "@angular/material";
import { TipoEmailEnumAll } from "src/app/core/models/tipo-email-enum.model";
import { SentidoEmailEnumAll } from "src/app/core/models/sentido-email-enum.model";
import { Utils } from "src/app/global/util/utils-component";

@Component({
  selector: "app-salvar-email-template",
  templateUrl: "./salvar-email-template.component.html",
  styleUrls: ["./salvar-email-template.component.css"],
})
export class SalvarEmailTemplateComponent extends SalvarGenericsComponent<EmailTemplate> {
  public ckeConfig: any;

  public sentidoList: any[] = SentidoEmailEnumAll;
  public tipoEmailList: any[] = TipoEmailEnumAll;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: EmailTemplateService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "email-template", "");
  }

  ngOnInit() {
    this.confiCkEditor();
    Utils.ordernarDescricaoOrdemAlfabetica(this.tipoEmailList);
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  public abrirDialogTagsCorpo() {
    const dialogFase = this.dialog.open(DialogListarTagsComponent, {
      width: "400px",
    });
    dialogFase.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        this.domain.corpo = this.domain.corpo.concat(" {" + processoParam + "}");
      }
    });
  }

  public abrirDialogTagsTitulo() {
    const dialogFase = this.dialog.open(DialogListarTagsComponent, {
      width: "400px",
    });
    dialogFase.afterClosed().subscribe((processoParam) => {
      if (processoParam) {
        if (this.domain.titulo) {
          this.domain.titulo = this.domain.titulo.concat(" {" + processoParam + "}");
        } else {
          this.domain.titulo = "{" + processoParam + "}";
        }
      }
    });
  }

  public validar(domain: any): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (domain.tipo == null) {
      this.messageService.messageErro("Selecione um Tipo de E-mail.");
      validacao = false;
    }
    if (domain.sentido == null) {
      this.messageService.messageErro("Selecione um Sentido.");
      validacao = false;
    }
    if (!domain.titulo || !domain.titulo.trim()) {
      this.messageService.messageErro("Preencha o campo Título.");
      validacao = false;
    }
    if (domain.corpo == null || !domain.corpo.trim()) {
      this.messageService.messageErro("Preencha o campo Corpo do E-mail.");
      validacao = false;
    }

    return validacao;
  }

  public salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 === obj2;
  }

  compareTipo(obj1: any, obj2: any): boolean {
    return obj1 === obj2;
  }
}
