import { Domain } from "../../global/models/domain.model";
import { ResponsavelAssinaturaDTO } from "./responsavel-assinatura-dto.model";

export class MovimentacaoDTO extends Domain {
  public observacao: string;
  public orgaoLocal: number;
  public docAutor: string;
  public faseId: number;
  public situacaoId: number;
  public responsavel: string;
  public listResponsavelAssinatura: ResponsavelAssinaturaDTO[];
  public tipoArquivamentoId: number;
}