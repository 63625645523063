import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({
  providedIn: 'root'
})
export class ParametroDiversoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "parametro-diverso", config);
    this.API_URL = config.API_CADASTRO_URL;
  }
}
