import { Component, OnInit, Input } from "@angular/core";
import { Assunto } from "../../models/assunto.model";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { SharedService } from "../../../global/api/shared.service";
import { ProcessoService } from "../../api/processo.service";
import { DialogProcessoAbertoComponent } from "../dialog-processo-aberto/dialog-processo-aberto.component";
import { MatDialog } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { AssuntoDTO } from "../../models/assunto-dto.model";
import { PublicoAssuntoEnumAll } from "../../models/publico-assunto-enum.model";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { DialogListarServidoresComponent } from "../../processo/dialog-listar-servidores/dialog-listar-servidores.component";
import { Responsavel } from "../../models/responsavel.model";
import { ContribuinteService } from "../../api/contribuinte.service";

@Component({
  selector: "app-listar-assunto",
  templateUrl: "./listar-assunto.component.html",
  styleUrls: ["./listar-assunto.component.css"]
})
export class ListarAssuntoComponent implements OnInit {

  @Input() assuntos: Assunto[];
  private resourceRouterProcesso = environment.CONTEXTO +  "/processo";
  public isFiscal = false;
  public infoAssuntoAutor = new OpcaoAssuntoInterno();

  constructor(public router: Router,
    private processoService: ProcessoService,
    public dialog: MatDialog,
    public contribuinteService: ContribuinteService,
    public messageService: MessageService,
    public sharedService: SharedService) {
    if (environment.VISAO === this.sharedService.VISAO_BENEFICIARIO) {
      this.infoAssuntoAutor.infoAutor = JSON.parse(this.sharedService.getDocumentoRepresentado());
      this.isFiscal = false;
    } else if (environment.VISAO === this.sharedService.VISAO_ANALISTA) {
      this.infoAssuntoAutor.infoAutor = JSON.parse(sharedService.getDocumentoAstra());
      this.isFiscal = true;
    }
  }

  ngOnInit() {}

  confirmarAutor(assuntoDto: AssuntoDTO) {
      this.infoAssuntoAutor.tipo = TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO;
      this.infoAssuntoAutor.infoAssinador = JSON.parse(this.sharedService.getDocumentoRepresentado());
      this.carregarProcesso(assuntoDto);
  }
  selecionarAutor(assuntoDto: AssuntoDTO, contribuinte: Responsavel) {
    const dialogServidor = this.dialog.open(DialogListarServidoresComponent, {
      width: "720px",
      data: { contribuinte: contribuinte, titulo: "Selecione o responsável para ser o assinador dos documentos." }
    });
    dialogServidor.afterClosed().subscribe((pessoa: Responsavel) => {
      if (pessoa != null) {
        this.infoAssuntoAutor.infoAssinador = pessoa.matricula;
        this.carregarProcesso(assuntoDto);
      }
    });
  }

  carregarProcesso(assuntoDto: AssuntoDTO) {
    let id = assuntoDto.id;
    let descricao = assuntoDto.descricao;
    let idProcessoPensao = assuntoDto.pensao;
    
    if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO ||
      TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL) {
      this.processoService.pesquisarProcessoAberto(id, this.infoAssuntoAutor.infoAutor).subscribe(
        res => {
          if (res != null && res.length > 0) {
            const processo = res.slice(-1)[0];
            const processoRequest = {
              id: id,
              tipo: this.infoAssuntoAutor.tipo,
              infoAutor: this.infoAssuntoAutor.infoAutor,
              infoAssinador: this.infoAssuntoAutor.infoAssinador,
              descricao: descricao,
              numeroProcesso: processo.etiquetaProcessoViproc
            };
            this.dialog.open(DialogProcessoAbertoComponent, { 
              width: "400px",
              data: processoRequest
            });
          } else {

            if (idProcessoPensao)
              this.router.navigate([`/sgeap/salvar-processo-pensao`], { queryParams: { idTipoPensao:id } });
            else
              this.router.navigate([`${this.resourceRouterProcesso}/${id}/${this.infoAssuntoAutor.tipo}/${this.infoAssuntoAutor.infoAutor}/${this.infoAssuntoAutor.infoAssinador}/${descricao.replace("/", "\\")}`]);
              
          }
        },
        error => {
          // tslint:disable-next-line:triple-equals
          if (error.status == 204) {
            // tslint:disable-next-line: max-line-length
            this.router.navigate([`${this.resourceRouterProcesso}/${id}/${this.infoAssuntoAutor.tipo}/${this.infoAssuntoAutor.infoAutor}/${this.infoAssuntoAutor.infoAssinador}/${descricao.replace("/", "\\")}`]);
          } else {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        }
      );
    } else {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`${this.resourceRouterProcesso}/${id}/${this.infoAssuntoAutor.tipo}/${this.infoAssuntoAutor.infoAutor}/${this.infoAssuntoAutor.infoAssinador}/${descricao.replace("/", "\\")}`]);
    }
  }

  publicoStr(id: number){
    return PublicoAssuntoEnumAll[id].descricaoMenor;
  }

}
