import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class EnderecoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "endereco", config);
  }

  pesquisarEnderecoPorCep(cep: string) {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json`);
  }
}
