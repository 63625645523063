export enum TipoMetadadoEnum {
  LETRA_NUMERO,
  APENAS_NUMERO,
  DATA,
  BOTAO,
  APENAS_LETRAS,
  MONETARIO
}

export const TipoMetadadoEnumAll = [
  { id: 0, descricao: "Letras e Números" },
  { id: 1, descricao: "Apenas Números" },
  { id: 4, descricao: "Apenas Letras" },
  { id: 3, descricao: "Botão" },
  { id: 2, descricao: "Data" },
  { id: 5, descricao: "Monetário" }
];