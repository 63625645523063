import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { ProcessoService } from "../../api/processo.service";
import { Ocorrencia } from "../../models/ocorrencia-dto.model";
import { TipoDocumentoService } from "src/app/core/api/tipo-documento.service";
import { OcorrenciaService } from "../../api/ocorrencia.service";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { EnvolvidoProcessoDTO } from "../../models/envolvido-processo-dto.model";
import { OcorrenciaListarDTO } from "../../models/ocorrencia-listar-dto";
import { SharedService } from "src/app/global/api/shared.service";
import { ResponderOcorrenciaDTO } from "../../models/responder-ocorrencia-dto.model";
@Component({
  selector: "app-dialog-responder-ocorrencias-processo",
  templateUrl: "./dialog-responder-ocorrencias-processo.component.html",
  styleUrls: ["./dialog-responder-ocorrencias-processo.component.css"]
})
export class DialogResponderOcorrenciaProcesso extends SalvarGenericsComponent<Ocorrencia> implements OnInit {

  
  public buttons = [];
  public sections = [];
  public titleOcorrencia = 'OCORRÊNCIA';
  public form: FormGroup;
  public extensoes = [ ".pdf", ".jpeg", ".png", ".jpg" ];
  public tipoDocumentoModelo: any;
  public fileListArquivoEnviando: FileList;

  public envolvidos: EnvolvidoProcessoDTO[] = [];
  public tipoDocumentos: TipoDocumento[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogResponderOcorrenciaProcesso>,
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: OcorrenciaService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public processoService: ProcessoService,
    public tipoDocumentoService: TipoDocumentoService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public ocorrencia: OcorrenciaListarDTO) {
    super(formBuilder, router, apiService, messageService, route, "ocorrencia", "");
  }


  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.sections = [
      {
        title: "OCORRÊNCIAS",
        fields: [
          this.getFields()
        ],
      },
    ];

    this.buttons = [
      {
        label: "Salvar",
        click: () => this.salvarOcorrencia(),
        color: "primary",
      },
      {
        label: "Cancelar",
        click: () => this.dialogRef.close(),
        color: "secondary",
      },
    ];
    
    this.setValues();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  salvarOcorrencia() {
    if(this.formValido()) {
      if(this.fileListArquivoEnviando) {
        const arquivo = this.fileListArquivoEnviando[0];
        if(this.validarExtensao(arquivo)) {
          const formData: FormData = new FormData();
          formData.append("file", arquivo);
          formData.append("ocorrenciaId", this.domain.id);
          formData.append("descricao", this.domain.descricao);
          formData.append("documentoSolicitante", this.sharedService.decodePayloadJWT().sub);
  
          this.apiService.responderOcorrencia(formData).subscribe(res => {
            this.messageService.messageSuccess("Ocorrência salva com sucesso.");
            this.dialogRef.close();
          },
          error => {
            this.messageService.messageErro(error.error.message);
            this.dialogRef.close();
            throw error;
          });
          this.messageService.messageSuccess("Ocorrência salva com sucesso.");
        } else {
          this.messageService.messageErro("Extensão de arquivo não permitida.");
          return;
        }
      } else {
        const formData: FormData = new FormData();
        formData.append("ocorrenciaId", this.domain.id);
        formData.append("descricao", this.domain.descricao);

        this.apiService.responderOcorrencia(formData).subscribe(res => {
          this.messageService.messageSuccess("Ocorrência salva com sucesso.");
          this.dialogRef.close();
        },
        error => {
          this.messageService.messageErro(error.error.message);
          this.dialogRef.close();
          throw error;
        });
        this.messageService.messageSuccess("Ocorrência salva com sucesso.");
      }      
    } else {
      this.messageService.messageInfo("Informe todos campos obrigatórios.");
    }
  }

  uploadFile(file) {
    file.preventDefault();

    if (file.target.files.length === 0) {
      this.messageService.messageErro(`O arquivo não foi selecionado corretamente, repita a operação.`);
      return;
    }

    this.fileListArquivoEnviando = file.target.files;
  }

  public formValido() {
    let formValido = true;

    if(!this.domain.descricao || this.domain.descricao.length == 0) {
      formValido = false;
    }

    return formValido;
  }

  validarExtensao(arquivo) {
    const extensao = arquivo.name.substring(arquivo.name.lastIndexOf(".")).toLowerCase();

    return this.extensoes.filter(e => e.trim().indexOf(extensao) > -1).length > 0;
  }

  setValues() {
    this.domain.id = this.ocorrencia.id;
    this.domain.envolvidos = [this.ocorrencia.envolvido];
    this.domain.tipoDocumento = this.ocorrencia.tipoDocumento;
    this.domain.descricao = this.ocorrencia.descricao;
  }

  public getFields() {
    const listFields: any = [{
      key: "envolvidos",
      fieldType: "selectInput",
      options: () => [this.ocorrencia.envolvido],
      multiple: true,
      labelKey: "nome",
      label: "Pessoa",
      required: true,
      type: "text",
      disabled: () => true,
      maxLength: 255,
      cols: [12, 12, 12, 12, 12],
    }];

    if(this.ocorrencia.tipoDocumento && this.ocorrencia.tipoDocumento.nomeDocumento) {
      listFields.push({
        key: "tipoDocumento",
        fieldType: "selectInput",
        options: () => [this.ocorrencia.tipoDocumento],
        multiple: false,
        labelKey: "nomeDocumento",
        label: "Documento",
        required: false,
        disabled: () => true,
        type: "text",
        maxLength: 255,
        cols: [12, 12, 12, 12, 12],
      },
      {
        key: "arquivo",
        fieldType: "fileInput",
        label: "Arquivo",
        input: (e) => this.uploadFile(e),
        required: true,
        cols: [12, 12, 12, 12, 12],
      });
    }
    listFields.push({
      key: "descricao",
      fieldType: "textInput",
      label: "Descrição",
      required: true,
      maxLength: 255,
      cols: [12, 12, 12, 12, 12],
    });

    return listFields;
  }

}
