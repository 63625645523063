import { Domain } from "./domain.model";
import { Assunto } from "../../core/models/assunto.model";
import { TipoMetadadoEnum } from "src/app/core/models/tipo-metadado.enum";

export class Metadado extends Domain {
  public titulo: string;
  public nome: string;
  public requerido: boolean;
  public mascara: string;
  public tipo: TipoMetadadoEnum;
  public assunto: Assunto;
  public valor: string;
  public disabled: boolean;
  public acao: string;
  public sistema: boolean;
  public validarQuantidadeCaracteres: boolean;
  public ativo: boolean;
}