import {Directive, ElementRef, HostListener} from "@angular/core";
import {NgControl} from "@angular/forms";

@Directive({
  selector: "[validarEmail]"
})
export class ValidadorEmailDirective {
  
  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener("input", ["$event"]) onInputChange(event) {
    let email: string = this.el.nativeElement.value;
    const abstractControl = this.control.control;
    let padraoEmail = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$");
    // Fix para forçar o css quando inválido
    const input = (<HTMLInputElement>this.el.nativeElement);
    input.parentElement.parentElement.parentElement.parentElement.classList.remove("mat-form-field-invalid")
    
    if (!padraoEmail.test(email)){
      input.parentElement.parentElement.parentElement.parentElement.classList.add("mat-form-field-invalid")
      abstractControl.setErrors(event);
      return {  };
    }
    
    event.stopPropagation();
  }
}
