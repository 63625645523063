import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProcessoPequisa } from "../models/processo-pesquisa.model";
import { MovimentacaoDTO } from "../models/movimentacao-dto.model";
import { Processo } from "../models/processo.model";
import { ItemNaoSimilaridade } from "../models/item-nao-similaridade.model";
import { RegrasProcesso } from "../models/regras-processo.model";
import { ProcessoPensaoDTO } from "../models/pensao/processo-pensao-dto.model";
import { Documento } from "../models/documento.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class ProcessoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "processo", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  public getProcessoContribuinte(processoId: number, representado: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/${processoId}?representado=${representado}`).pipe(map((response) => response));
  }

  public pesquisa(processoPesquisa: ProcessoPequisa, page: number, size: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, processoPesquisa).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent;
        } else {
          return response;
        }
      })
    );
  }

  public getProcessoPorSetorDoServidor(setor: number, page: number, size: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-por-setor-do-servidor/${setor}?page=${page}&size=${size}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent;
        } else {
          return response;
        }
      })
    );
  }

  public getProcessoBySetor(setor: number, page: number, size: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-setor/${setor}?page=${page}&size=${size}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
      })
    );
  }

  public getProcessoDocAssinadoPeriodo(dataInicioDocAssinado: string, dataFimDocAssinado: string) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-processos-assinados-por-periodo/${dataInicioDocAssinado}/${dataFimDocAssinado}`)
      .pipe(
        map((response) => {
          const responseContent: any = response;

          return responseContent;
        })
      );
  }

  public getProcessoSetorQuantidadeDias(dias: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-lotacao-processo-sem-andamento-por-dias/${dias}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      })
    );
  }

  public getProcessoQuantidadeDias(dias: number, lotacao: number, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-processo-sem-andamento-por-dias/${dias}/${lotacao}?page=${page}&size=${size}`)
      .pipe(map((response) => response));
  }

  public getProcessoSemResponsavel(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-setor-sem-responsavel`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      })
    );
  }

  public getProcessoPorSetor(setor: number, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-setor/${setor}?page=${page}&size=${size}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent;
        } else {
          return response;
        }
      })
    );
  }

  public getProcessoNaoLidosPeriodo(dataInicial: string, dataFinal: string, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-processo-setor-periodo/${dataInicial}/${dataFinal}?page=${page}&size=${size}`)
      .pipe(
        map((response) => {
          const responseContent: any = response;
          return responseContent;
        })
      );
  }

  public getProcessoSemAssinatura(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-processo-sem-assinatura`).pipe(
      map((response) => {
        const responseContent: any = response;
        return responseContent;
      })
    );
  }

  public getProcessoSemAssinaturaPorTipoDocumento(idTipoDocumento: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-processo-sem-assinatura/${idTipoDocumento}`).pipe(
      map((response) => {
        const responseContent: any = response;
        return responseContent;
      })
    );
  }

  public getProcessoObservado(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/prateleira/processos-observados`).pipe(
      map((response) => {
        const responseContent: any = response;
        return responseContent;
      })
    );
  }

  public getItemNaoSimilaridade(processoId: number, page: number, size: number): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/listar-paginado-itens-nao-similaridade-processo-id/${processoId}?page=${page}&size=${size}`)
      .pipe(map((response) => response));
  }

  public deferirItemNaoSimilaridade(processo: Processo, itensSelecionados: ItemNaoSimilaridade[]) {
    return this.http
      .post(`${this.API_URL}${this.resource}/deferir-itens-nao-similaridade/${processo.id}`, itensSelecionados)
      .pipe(map((response) => response));
  }

  public indeferirItemNaoSimilaridade(processo: Processo, itensSelecionados: ItemNaoSimilaridade[]) {
    return this.http
      .post(`${this.API_URL}${this.resource}/indeferir-itens-nao-similaridade/${processo.id}`, itensSelecionados)
      .pipe(map((response) => response));
  }

  public deferirSituacaoCertificadoItemNaoSimilaridade(processo: Processo) {
    return this.http
      .post(`${this.API_URL}${this.resource}/deferir-situacao-certificado-itens-nao-similaridade/${processo.id}`, null)
      .pipe(map((response) => response));
  }

  public validarPendenciasPorProcessoId(id: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/validar-pendencias-por-processo-id/${id}`, {}).pipe(map((response) => response));
  }

  public atualizarItemNaoSimilaridade(processo: Processo, itensSelecionados: ItemNaoSimilaridade[]) {
    return this.http
      .post(`${this.API_URL}${this.resource}/atualizar-itens-nao-similaridade/${processo.id}`, itensSelecionados)
      .pipe(map((response) => response));
  }

  public gerarCertificadoItemNaoSimilaridade(justificativa: string, processo: Processo, acaoCertificado: string): any {
    if (acaoCertificado === "DEFERIR") {
      return this.deferirCertificadoItemNaoSimilaridade(processo);
    } else if (acaoCertificado === "INDEFERIR") {
      return this.indeferirCertificadoItemNaoSimilaridade(justificativa, processo);
    }
  }

  public deferirCertificadoItemNaoSimilaridade(processo: Processo): any {
    return this.http
      .post(`${this.API_URL}${this.resource}/deferir-certificado-itens-nao-similaridade/${processo.id}`, null)
      .pipe(map((response) => response));
  }

  public indeferirCertificadoItemNaoSimilaridade(justificativa: string, processo: Processo): any {
    return this.http
      .post(`${this.API_URL}${this.resource}/indeferir-certificado-itens-nao-similaridade/${processo.id}`, justificativa)
      .pipe(map((response) => response));
  }

  public salvarItemNaoSimilaridade(processoId: number, listItemNaoSimilaridade: any[]): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/salvar-itens-nao-similaridade/${processoId}`, listItemNaoSimilaridade)
      .pipe(map((response) => response));
  }

  public consultar(processoPesquisa: ProcessoPequisa, page: number, size: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}`, processoPesquisa)
      .pipe(map((response) => response));
  }

  public consultarOrdenado(
    processoPesquisa: ProcessoPequisa,
    page: number,
    size: number,
    active: string,
    direction: string
  ): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/pesquisar?page=${page}&size=${size}&sort=${active},${direction}`, processoPesquisa)
      .pipe(map((response) => response));
  }

  public getByEtiqueta(numero: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/${numero}`).pipe(map((response) => response));
  }

  public getProcessoEmAndamentoByContribuinte(processoPesquisa: ProcessoPequisa, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post(`${this.API_URL}${this.resource}/pesquisar-processo-em-andamento-contribuinte/?page=${page}&size=${size}`, processoPesquisa)
      .pipe(map((response) => response));
  }

  public getProcessoArquivadoByContribuinte(processoPesquisa: ProcessoPequisa, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post(`${this.API_URL}${this.resource}/pesquisar-processo-arquivado-contribuinte/?page=${page}&size=${size}`, processoPesquisa)
      .pipe(map((response) => response));
  }

  public getQuantidadeProcessoPorMatricula(matricula: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-quantidade-matricula/${matricula}`).pipe(map((response) => response));
  }

  public getProcessoAguardandoManifestacao(documento: string, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-processo-contribuinte-pendente/${documento}?page=${page}&size=${size}`)
      .pipe(map((response) => response));
  }

  public getProcessoByContribuinteWithPendencia(numero: string, page: number, size: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-contribuinte-pendente/${numero}?page=${page}&size=${size}`)
      .pipe(map((response) => response));
  }

  public getDocumentosSemAssinaturaPorTipoDocumento(idProcesso: number, idTipoDocumento: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-documento-sem-assinatura/${idProcesso}/${idTipoDocumento}`).pipe(
      map((response) => {
        const responseContent: any = response;
        return responseContent;
      })
    );
  }

  public movimentar(movimentacaoDTO: MovimentacaoDTO, idProcesso: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/movimentar/${idProcesso}`, movimentacaoDTO).pipe(map((response) => response));
  }

  public movimentarEmLote(movimentacaoDTO: MovimentacaoDTO, idProcesso: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/movimentar-em-lote/${idProcesso}`, movimentacaoDTO)
      .pipe(map((response) => response));
  }

  public movimentarResponsavel(movimentacaoDTO: MovimentacaoDTO, idProcesso: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/movimentar-responsavel/${idProcesso}`, movimentacaoDTO)
      .pipe(map((response) => response));
  }

  public retornarProcesso(idProcesso: number): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/retornar/${idProcesso}`, {}).pipe(map((response) => response));
  }

  public desarquivarProcesso(movimentacaoDTO: MovimentacaoDTO, idProcesso: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/desarquivar-processo/${idProcesso}`, movimentacaoDTO)
      .pipe(map((response) => response));
  }

  public arquivarProcesso(movimentacaoDTO: MovimentacaoDTO, idProcesso: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/arquivar-processo/${idProcesso}`, movimentacaoDTO)
      .pipe(map((response) => response));
  }

  public pesquisarProcessoAberto(assuntoId: number, documentoSolicitante: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pesquisar-processo-aberto/${assuntoId}/${documentoSolicitante}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent != undefined && responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      })
    );
  }

  public observarProcesso(processoId: number, receberEmail: boolean, adicionar: boolean): Observable<any> {
    return this.http
      .put(`${this.API_URL}${this.resource}/observador/${processoId}`, {
        receberEmail,
        adicionar,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public alterarNivelSigilo(processoId: number, nivel: number): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/alterar-nivel-sigilo/${processoId}`, nivel).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public getProcessoEmAndamentoComResponsavel(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-processo-em-andamento-com-responsavel`)
      .pipe(map((response) => response));
  }

  public getLotacoesProcessoQuantidadeDias(dias: number): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-lotacao-processo-sem-andamento-por-dias/${dias}`)
      .pipe(map((response) => response));
  }

  public getTiposDocumentoProcessoDocumentoSemAssinatura(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.API_URL}${this.resource}/prateleira/listar-processo-tipo-documento-sem-assinatura`).pipe(
      map((response) => {
        const responseContent: any = response;
        return responseContent;
      })
    );
  }

  public pesquisarSetoresComProcessoDoServidor(): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-setores-processo-do-servidor`)
      .pipe(map((response) => response));
  }

  public listAgrupamentoAssuntoProcesso(): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-assunto-processo-do-servidor`)
      .pipe(map((response) => response));
  }

  public listAgrupamentoSituacaoProcesso(): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-situacao-processo-do-servidor`)
      .pipe(map((response) => response));
  }

  public listAgrupamentoPeriodosProcesso(inicio: string, fim: string): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-periodos-processo-do-servidor/${inicio}/${fim}`)
      .pipe(map((response) => response));
  }

  public listAgrupamentoFaseProcesso(): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-fase-processo-do-servidor`)
      .pipe(map((response) => response));
  }

  public atualizaProcessoDocumentoDesentranhado(processoId: number, documentoId: number, processo: any): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/processo-doc-desentranhado/${processoId}/${documentoId}`, processo).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public pesquisarSetoresSemResponsabilidade(): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/prateleira/pesquisar-processo-em-andamento-sem-responsavel`)
      .pipe(map((response) => response));
  }

  public listLotacoesUsuarioLogado(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/listar-lotacoes`).pipe(map((response) => response));
  }

  public regrasProcesso(regrasProcesso: RegrasProcesso[]): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/regras/`, regrasProcesso).pipe(map((response) => response));
  }

  public salvarProcessoPensao(processoPensaoDTO: ProcessoPensaoDTO): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/salvar-processo-pensao/`, processoPensaoDTO).pipe(map((response) => response));
  }

  public verificaExistenciaProcesso(processoPensaoDTO: ProcessoPensaoDTO): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/verifica-existencia-processo/`, processoPensaoDTO)
      .pipe(map((response) => response));
  }

  public setDocumentoEnvolvido(envolvido: string, idEnvolvido: number, documentoDoEnvolvido: string, idDocumento: number) {
    return this.http
      .post(`${this.API_URL}${this.resource}/set-documento-envolvido/${envolvido}/${idEnvolvido}/${idDocumento}`, {
        documento: documentoDoEnvolvido,
      })
      .pipe(map((response: Documento) => response));
  }

  public verificarProcessoPertenceLotacaoUsuario(processoId: number, documento: string): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/verificar-processo-pertence-lotacao-usuario?processoId=${processoId}&documento=${documento}`)
      .pipe(map((response) => response));
  }

  public buscarPessoasEnvolvidasProcesso(processoId: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/buscar-envolvidos-por-processo/${processoId}`).pipe(map((response) => response));
  }

  public jaExisteProcessoCriadoParaVincular(processoId: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/buscar-processos-relacionados/${processoId}`).pipe(map((response) => response));
  }

  recuperarSenha(email: string): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/recuperar-senha`, { email: email });
  }

}
