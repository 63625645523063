import { AppConfig, APP_CONFIG } from "src/app.config";
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class SgprevService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "sgprev", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  getMatriculaPorCpf(cpf: string) {
    cpf = cpf.replace(/[.*+\-?^${}()|[\]\\]/g, "").trim();
    return this.http.get(`${this.API_URL}${this.resource}/vinculo-por-cpf/${cpf}`).pipe(map((response) => response));
  }

  getNumeroEdoweb() {
    return this.http
      .post(`${this.API_URL}${this.resource}/capturar-edoweb`, null)
      .pipe(map((response) => response));
  }

  sendAto(processoId: number, edoWeb: string, documentoId: number) {
    return this.http
      .post(`${this.API_URL}${this.resource}/enviar-ato/${processoId}/${edoWeb}/${documentoId}`, null)
      .pipe(map((response) => response));
      
  }
}
