import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { LoadingScreenService } from "src/app/global/api/loading-screen";
import { LacunaWebPKI, CertificateModel } from "web-pki";
import { environment } from "../../../../environments/environment";
import { MessageService } from "../../../global/message/message.service";
import { Certificate } from "../../../global/models/certificate.model";
import { AssinaturaService } from "../../api/assinatura.service";
import { Utils } from "src/app/global/util/utils-component";

@Component({
  selector: "app-modulo-assinador",
  templateUrl: "./modulo-assinador.component.html",
  styleUrls: ["./modulo-assinador.component.css"]
})
export class ModuloAssinadorComponent implements OnInit {
  private static formDataFile = "file";

  public conteudo: any;
  public form: FormGroup;
  public fileName: string;
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate: Certificate;
  public formData = new FormData();
  public file: File;
  certificates: CertificateModel[];

  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(public assinaturaService: AssinaturaService,
    private messageService: MessageService,
    public loadingScreenService: LoadingScreenService,
    private formBuilder: FormBuilder,
    private chRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      selectedCertificate: ["", []]
    });
  }

  ngOnInit() {
    this.getCertificates();
  }

  public getCertificates(): any {
    this.pki.init({
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled,
      defaultFail: this.onWebPkiFail
    });
  }

  onWebPkiNotInstalled(status, message) {
    const pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
    pki.redirectToInstallPage();
    console.error("onWebPkiNotInstalled" + status + message);
  }

  onWebPkiFail(ex) {
    console.error("Web PKI error from " + ex.origin + ": " + ex.message + "(" + ex.code + ")");
  }

  onWebPkiReady = () => {
    console.info("Web PKI ready, loading certificates ...");
    this.pki.listCertificates().success((certs) => {
      console.info("Certificates loaded.");
      this.certificates = certs;
    });
  }

  selectFile(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.file = fileList[0];
      this.fileName = fileList[0].name;
    }
  }

  confirmar(): void {
    this.formData = new FormData();
    const selectedCertificate = this.form.get("selectedCertificate").value;
    this.certificate = selectedCertificate;
    if (!selectedCertificate) {
      this.messageService.messageErro("Selecione um certificado !");
      return;
    }
    if (!this.file) {
      this.messageService.messageErro("Selecione um arquivo!");
      return;
    }
    this.formData.append(ModuloAssinadorComponent.formDataFile, this.file);
    this.pki.readCertificate({
      thumbprint: selectedCertificate.thumbprint
    }).success(certEncoding => {
      this.formData.append("certContent", certEncoding);
      this.startFile();
    }).fail(error => {
      this.messageService.messageErro("Erro na leitura do certificado ");
      throw error;
    });
  }

  public startFile() {
    this.assinaturaService.start(this.formData).subscribe(sigParams => {
      this.pki.signHash({
        thumbprint: this.certificate.thumbprint,
        hash: sigParams.toSignHash,
        digestAlgorithm: sigParams.digestAlgorithm
      }).success(signature => {
        this.chRef.detectChanges();
        this.completeFile(sigParams, signature);
      });
    }, error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  private completeFile(sigParams: any, signature: string) {
    this.assinaturaService.complete(sigParams.fileName,
      sigParams.transferFileId,
      signature).subscribe(blob => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Não foi possível fazer o download do arquivo.");
        }
      }, error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

}
