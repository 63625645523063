import { AssuntoCategoria } from "./assunto-categoria.model";
import { Fase } from "./fase.model";
import { AssuntoMetadado } from "./assunto-metadado.model";
import { Usuario } from "./usuario.model";
import { Metadado } from "src/app/global/models/metadado.model";
import { Domain } from "../../global/models/domain.model";

export class Assunto extends Domain {
  public descricao: string;
  public assuntoCategoria: AssuntoCategoria;
  public grupoRecebedorPadrao: number;
  public nivelSigilo: number;
  public faseInicial: Fase;
  public panelAberto: boolean;
  public listMetadado: AssuntoMetadado[];
  public listTipoDocumento: any[];
  public assuntoViproc: string;
  public categoriaViproc: string;
  public qtdRegistros: number;
  public tipoDistribuicao: number;
  public usuarioRecebedorPadrao: Usuario;
  public ativo: boolean;
  public fatorMultiplicador: Metadado;
  public extensao: string;
  public assuntoId: any;
  public naoSimilaridade: boolean = false;
  public gerarProcessoViproc: boolean = false;
  public gerarDae: boolean = false;
  public pensao: boolean = false;
}