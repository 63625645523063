import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { DocumentoService } from "../../api/documento.service";
import { EnderecoService } from "../../api/endereco.service";
import { ProcessoService } from "../../api/processo.service";
import { RequerenteService } from "../../api/requerente.service";
import { Assunto } from "../../models/assunto.model";
import { Documento } from "../../models/documento.model";
import { CondicoesParaTrazerDocs } from "../../models/pensao/condicoes-para-trazer-docs.model";
import { Endereco } from "../../models/pensao/endereco.model";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { Representante } from "../../models/pensao/representante.model";
import { Requerente } from "../../models/pensao/requerente.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { TipoRepresentanteEnumAll, TipoRepresentanteFunctions } from "../../models/pensao/tipo-representante.enum";
import { TipoRequerenteEnumAll, TipoRequerenteEnumFunctions } from "../../models/pensao/tipo-requerente.enum";
import { Processo } from "../../models/processo.model";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { UploadDocumentoEnvolvidosComponent } from "../upload-documento-envolvidos/upload-documento-envolvidos.component";
import { UploadDocumentoComponent } from "../upload-documento/upload-documento.component";

@Component({
  selector: "app-visualizar-requerente",
  templateUrl: "./visualizar-requerente.component.html",
  styleUrls: ["./visualizar-requerente.component.css"],
})
export class VisualizarRequerenteComponent implements OnInit {
  @ViewChild("uploadDocumentoRequerenteComponent") uploadDocumentoRequerenteComponent: UploadDocumentoComponent;
  private condicoesParaTrazerDocs: CondicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
  @Input() public processo: Processo;
  @Input() public assunto: Assunto;
  @Input() requerentes: Requerente[];
  @Input() solicitante: Solicitante;
  public requerente: Requerente = new Requerente();
  @Input() instituidor: Instituidor;
  public ufs: string[] = Utils.getUfs();
  public tiposRequerente: any[] = TipoRequerenteEnumAll;
  public tiposRepresentantes: any[] = TipoRepresentanteEnumAll;
  public formGroupRequerente: FormGroup;
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];
  public documentoSubstituir: Documento;
  public natureza: number;
  public exibicaoEditor = false;

  @Input() disabledValue: true;
  myGroup: FormGroup;

  constructor(
    private requerenteService: RequerenteService,
    private documentoService: DocumentoService,
    private enderecoService: EnderecoService,
    private messageService: MessageService,
    private processoService: ProcessoService
  ) {}

  ngOnInit() {
    this.myGroup = new FormGroup({
      firstName: new FormControl(),
    });
    this.tiposRequerente.sort();
  }

  atualizarDataTableDocumentosReceiverEventEmitter($event) {
    this.requerenteService.pesquisarPorProcesso(this.processo.id).subscribe(
      (requerentes: Requerente[]) => {
        if (requerentes != null && requerentes.length > 0) {
          requerentes.forEach((requerente) => {
            this.documentoService.pesquisarDocumentosRequerente(requerente.id).subscribe(
              (docs: Documento[]) => {
                if (docs != null && docs.length > 0) requerente.documentos = docs;
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          });
          this.requerentes = requerentes;
        } else {
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  verificarMesmoCpfInstituidor(cpf: string) {
    cpf = cpf.replace(/[.*+\-?^${}()|[\]\\]/g, "").trim();

    if (cpf.length === 11 && cpf === this.instituidor.cpf) {
      this.requerentes.forEach(
        (requerente) => {
          this.requerente.cpf = "";
          this.messageService.messageWarning("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
          throw new Error("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  pesquisarEndereco(cep: string) {
    if (cep.length === 9) {
      this.enderecoService.pesquisarEnderecoPorCep(cep).subscribe(
        (endereco: any) => {
          if (endereco != null) {
            this.requerente.endereco.logradouro = endereco.logradouro;
            this.requerente.endereco.bairro = endereco.bairro;
            this.requerente.endereco.cidade = endereco.localidade;
            this.requerente.endereco.uf = endereco.uf;
          }
          if (endereco.erro) {
            this.messageService.messageWarning("Atenção! CEP não encontrado");
          }
        },
        (error) => {
          this.messageService.messageErro("Não foi possivel carregar o endereço");
          throw error;
        }
      );
    }
  }

  carregarDocumentos() {
    this.requerente.documentosRegra = null;
    this.uploadDocumentoRequerenteComponent.update([]);
    this.requerente.solicitanteTipo = this.requerente.tipoSolicitante.toLowerCase();
    this.getDocumentosRegra();

    if (this.solicitante.representanteLegal && this.requerente.tipoSolicitante != undefined) {
      this.requerente.necessitaRepresentante = TipoRequerenteEnumFunctions.verificarMenorOuInvalido(this.requerente.tipoSolicitante);
      if (this.requerente.necessitaRepresentante) this.preencherRepresentante();
    }
  }

  getDocumentosRegra() {
    if (this.validarCamposObrigatoriosDocumentosMotorRegras() && !this.formGroupRequerente.invalid) {
      this.requerente.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
      this.requerente.condicoesParaTrazerDocs = this.requerente.condicoesParaTrazerDocs.copiarAtributosRequerente(this.requerente);
      this.requerente.documentosRegra = [];
      this.validarProcessoMotorRegras();
    }
  }

  validarProcessoMotorRegras() {
    let regrasProcessos = this.montarRegraProcesso();
    let requerente = this.requerente;
    this.processoService.regrasProcesso(regrasProcessos).subscribe(
      (res) => {
        res.processos[0].requerentes.forEach((req) => {
          if (requerente.cpf === req.cpf) {
            requerente.documentosRegra = req.documentosObrigatorios;
          }
        });

        this.inicializarRequerente();
        this.requerente = requerente;
        this.uploadDocumentoRequerenteComponent.update(this.requerente.documentosRegra);
      },
      (error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos necessários do requerente");
        throw error;
      }
    );
  }

  inicializarRequerente() {
    this.requerente = new Requerente();
    this.requerente.endereco = new Endereco();
    this.requerente.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
    this.requerente.representanteLegal = new Representante();
    this.requerente.representanteLegal.endereco = new Endereco();
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regrasProcessos: RegrasProcesso[] = [];
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    regraProcesso.requerentes = new Array<Requerente>();
    regraProcesso.requerentes.push(this.requerente);
    regrasProcessos.push(regraProcesso);

    return regrasProcessos;
  }

  validarCamposObrigatoriosDocumentosMotorRegras(): boolean {
    if (this.requerente.tipoSolicitante === undefined || this.requerente.tipoSolicitante === "") return false;

    if (this.requerente.dataNascimento === undefined || this.requerente.dataNascimento === "") return false;

    if (this.requerente.dataNascimento.substring(0, 1) != "0") {
      let menorSelecionado = TipoRequerenteEnumFunctions.verificarMenor(this.requerente.tipoSolicitante);
      if (menorSelecionado === true && this.verificarMenoridade(this.requerente.dataNascimento) === false) {
        this.messageService.messageWarning("O requerente não é menor, selecione outro vínculo com o Segurado-Instituidor");
        return false;
      }
      if (menorSelecionado === false && this.verificarMenoridade(this.requerente.dataNascimento) === true) {
        this.messageService.messageWarning("O requerente precisa ser maior de idade.");
        return false;
      }
    }
  }

  private verificarMenoridade(dataNascimento: string): boolean {
    var ageDifMs = Date.now() - Date.parse(dataNascimento);
    var ageDate = new Date(ageDifMs);
    let result = Math.abs(ageDate.getUTCFullYear() - 1970);

    return result < 21 ? true : false;
  }

  preencherRepresentante() {
    if (this.solicitante.representanteLegal) {
      this.requerente.representanteLegal.nome = this.solicitante.nome;
      this.requerente.representanteLegal.tipoRepresentante = this.getEnumTipoRepresentante(this.solicitante.tipoSolicitante);
      this.requerente.representanteLegal.tipoSolicitante = this.solicitante.tipoSolicitante;
      this.requerente.representanteLegal.sexo = this.solicitante.sexo;
      this.requerente.representanteLegal.cpf = this.solicitante.cpf;
      this.requerente.representanteLegal.rg = this.solicitante.rg;
      this.requerente.representanteLegal.orgaoEmissor = this.solicitante.rgOrgaoEmissor;
      this.requerente.representanteLegal.uf = this.solicitante.rgUf;
      this.requerente.representanteLegal.estadoCivil = this.solicitante.estadoCivil;
      this.requerente.representanteLegal.telefone = this.solicitante.telefone;
      this.requerente.representanteLegal.email = this.solicitante.email;
      this.requerente.representanteLegal.endereco = new Endereco();
      this.requerente.representanteLegal.endereco.logradouro = this.solicitante.endereco.logradouro;
      this.requerente.representanteLegal.endereco.numero = this.solicitante.endereco.numero;
      this.requerente.representanteLegal.endereco.complemento = this.solicitante.endereco.complemento;
      this.requerente.representanteLegal.endereco.bairro = this.solicitante.endereco.bairro;
      this.requerente.representanteLegal.endereco.cep = this.solicitante.endereco.cep;
      this.requerente.representanteLegal.endereco.cidade = this.solicitante.endereco.cidade;
      this.requerente.representanteLegal.endereco.uf = this.solicitante.endereco.uf;
    }
  }

  restaurarValoresPadrao() {
    this.requerente.dataCasamento = null;
  }

  getEnumTipoRepresentante(idTipoRepresentante: string) {
    return TipoRepresentanteFunctions.getEnumPorId(idTipoRepresentante);
  }

  substituirDocummentoEditorEventReceiver($event) {
    let documento = $event.documentoSubstituir;
    this.uploadDocumentoRequerenteComponent.documentoSubstituir = documento;
    this.uploadDocumentoRequerenteComponent.natureza = 1;
    this.uploadDocumentoRequerenteComponent.exibicaoEditor == true;
    this.uploadDocumentoRequerenteComponent.adicionarDocumentoEditor();
    this.uploadDocumentoRequerenteComponent.documentoNovo.tipoDocumento = documento.tipoDocumento;
    this.uploadDocumentoRequerenteComponent.documentoNovo.modeloDocumento = documento.tipoDocumento.modeloDocumento;
    this.uploadDocumentoRequerenteComponent.documentoNovo.downloadUsuarioExterno = documento.tipoDocumento.visibilidadeExterna;
    this.uploadDocumentoRequerenteComponent.documentoNovo.descricao = documento.descricao;
  }
}
