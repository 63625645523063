import { PkiBrazilModel } from "web-pki";

export class Certificate {

  public cert64: string;
  public moreInfosOfCert: string; // Informações que não mostra pelo readCertificate() do Lacuna

  public email: string;

  public issuerName: string;
  public pkiBrasil: PkiBrazilModel;
  public subjectName: string;
  public thumbprint: string;

  public validityEnd: Date;
  public validityStart: Date;

}
