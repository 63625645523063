import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "sgeap-seguranca-home",
  templateUrl: "./seguranca-home.component.html",
  styleUrls: ["./seguranca-home.component.css"]
})
export class SegurancaHomeComponent implements OnInit {

  public cruds = [
    { label: "Perfil", icon: "groups", click: () => this.router.navigateByUrl("/sgeap/perfil") },
    { label: "Permissão", icon: "lock", click: () => this.router.navigateByUrl("/sgeap/permissao") },
    { label: "Usuário", icon: "person", click: () => this.router.navigateByUrl("/sgeap/usuario") },
  ];

  constructor(public router: Router) { }

  ngOnInit(): void { }

}
