import { InjectionToken } from "@angular/core"

export class AppConfig {
  API_ASSINATURA_DIGITAL_URL: string
  API_CADASTRO_URL: string
  API_COMUNICACAO_URL: string
  API_DOCUMENTO_URL: string
  API_PROCESSO_URL: string
  API_VIPROC_URL: string
  API_PPREVIDENCIA_URL: string
  API_SGPREV_URL: string
  API_SISSEG_URL: string
  CONTEXTO: string
  BASE_URL: string
  PRODUCAO: boolean
  HOMOLOGACAO: boolean
  DESENVOLVIMENTO: boolean
}

export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG')