import { Component, Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginMainComponent } from '../../autenticacao/login-main';
import { LoginUsuarioSenhaComponent } from '../../autenticacao/login-usuario-senha/login-usuario-senha.component';
import { SharedService } from '../shared.service';
import { TIPO_LOGIN } from './tipo-login.enum';



@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private sharedService: SharedService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (environment.TIPO_LOGIN == TIPO_LOGIN.FACE_ID) {
      return this.verificarRota(route, LoginMainComponent, "login");
    } else if (environment.TIPO_LOGIN == TIPO_LOGIN.USUARIO) {
      return this.verificarRota(route, LoginUsuarioSenhaComponent, "login-us");
    }
  }

  private verificarRota(route: ActivatedRouteSnapshot, component: Type<any>, url: string) {
    if (route.routeConfig.component == component) {
      return true;
    } else {
      this.router.navigate([`/${url}`]);
      return false;
    }
  }

}
