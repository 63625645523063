import { AfterViewInit, Component, Inject, NgZone, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { environment } from "src/environments/environment";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { AutenticacaoService } from "../../api/seguranca/autenticacao.service";
import { SharedService } from "../../api/shared.service";
import { TokenTO } from "../../models/token.model";

declare var $: any;

@Component({
  selector: "app-login-main",
  templateUrl: "./login-main.component.html",
  styleUrls: ["./login-main.component.css"],
})
export class LoginMainComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public message: string;
  public urlPrevidencia: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private autenticacaoService: AutenticacaoService,
    public ngxSmartModalService: NgxSmartModalService,
    private zone: NgZone,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
    this.form = this.formBuilder.group({});
    this.urlPrevidencia = config.API_PPREVIDENCIA_URL;    
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      // deixando o DOM trabalhar
      $(document).on("click", '[data-click="change-bg"]', function (n) {
        n.preventDefault();
        var t = "url(" + $(this).attr("data-img") + ")";
        $('[data-id="login-cover-image"]').css("background-image", t);
        $('[data-click="change-bg"]').closest("li").removeClass("active");
        $(this).closest("li").addClass("active");
      });
    });
  }

  ngOnInit() {
    if (this.sharedService.getToken()) {
      this.sharedService.checkTokenExpirado();
      this.zone.run(() => this.router.navigate([environment.CONTEXTO, "lista-processo-tabela"]));
    }

    var source = new EventSource(this.config.API_PPREVIDENCIA_URL + "event-stream?channel=loginportaladm&t=" + new Date().getTime());

    var urlImgQrCode = this.config.API_PPREVIDENCIA_URL + "api/Login/TwoFactorAuthQrCode/";
    var self = this;

    $(source).handleServerEvents({
      handlers: {
        onConnect: function (sub) {
          $("#qrcodeImage").attr("src", urlImgQrCode + sub.id);
          $("#qrcodeLoading").css("display", "none");
          $("#qrcodeImage").css("display", "block");
        },
        sendCon: function (data) {
          $.ajax({
            type: "POST",
            url: "https://pprevidencia.azurewebsites.net/auth/credentials",
            data: JSON.stringify({
              useTokenCookie: true,
              userName: data.split(",")[0],
              password: data.split(",")[1],
            }),
            contentType: "application/json;",
            dataType: "json",
          }).done(function (responseApiPrevidencia) {
            self.autenticacaoService.gerarToken(responseApiPrevidencia.BearerToken).subscribe((response) => {
              const token: TokenTO = self.sharedService.logar(response.token);
              self.sharedService.setDocumentoRepresentante(token.sub);
              // TODO Verificar tipoUsuario
              self.zone.run(() => self.router.navigate([environment.CONTEXTO, "lista-processo-tabela"]));
            });
          });
        },
      },
    });
  }

  public modalCtrl = {
    open: () => {
      this.ngxSmartModalService.create("modalHelper", "content").open();
    },
    close: () => {
      this.ngxSmartModalService.close("modalHelper");
    },
  };
}
