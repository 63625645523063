
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class FiscalPreferenciaService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "fiscal-preferencia", config);
    this.API_URL = config.API_CADASTRO_URL;
  }


}