import { Component, OnInit, Input, OnDestroy, OnChanges, ElementRef, EventEmitter, Output, ChangeDetectorRef } from "@angular/core";
import { ProcessoService } from "../../api/processo.service";
import { MatTableDataSource, MatDialog } from "@angular/material";
import { ItemNaoSimilaridade } from "../../models/item-nao-similaridade.model";
import { SelectionModel } from "@angular/cdk/collections";
import { SituacaoItemNaoSimilaridadeEnum, SituacaoItemNaoSimilaridadeEnumAll } from "../../models/situacao-item-nao-similaridade-enum.enum";
import { DocumentoService } from "../../api/documento.service";
import { DialogJustificativaComponent } from "../dialog-justificativa/dialog-justificativa.component";
import { DialogDataInicioFimComponent } from "../dialog-data-inicio-fim/dialog-data-inicio-fim.component";
import { DialogConfirmarComponent } from "../dialog-confirmar/dialog-confirmar.component";
import { EventoService } from "../../api/evento.service";
import { ConsultaPublicaService } from "../../api/consulta-publica.service";
import { SharedService } from "../../../../app/global/api/shared.service";
import LacunaWebPKI from "web-pki";
import { environment } from "../../../../environments/environment";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { Certificate } from "../../../global/models/certificate.model";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { ConsultaPublicaDto } from "../../models/documento/consulta-publica-dto.model";
import { RequestCompleteNewDocPadesSignatureExpress } from "../../models/documento/request-complet-new-doc-pades-signature-express.model";
import { Processo } from "../../models/processo.model";
import { MessageService } from "../../../global/message/message.service";
import { LoadingScreenService } from "../../../../app/global/api/loading-screen";

@Component({
  selector: "app-item-nao-similaridade",
  templateUrl: "./item-nao-similaridade.component.html",
  styleUrls: ["./item-nao-similaridade.component.css"]
})
export class ItemNaoSimilaridadeComponent implements OnInit, OnChanges {

  @Input() processo: any;
  @Input() responsavel: any;
  @Output() atualizarDocumentosEvent = new EventEmitter<Processo>();
  @Output() atualizarEventosItemNaoSimilaridadeEvent = new EventEmitter<boolean>();


  displayedColumns: string[] = ["select", "sequencia", "ncm", "descricao", "custo", "situacao"];
  dataSource = new MatTableDataSource<ItemNaoSimilaridade>();
  public selection = new SelectionModel<ItemNaoSimilaridade>(true, []);
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private documentoAssinado: DocumentoDTO;
  private certificate: Certificate;
  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public lengthItemNaoSimilaridade = 0;
  private mensagemFailPki: string;
  private sigParams: any;
  private signature: any;
  private certificadoResponse: any;
  private acaoCertificado: any;

  constructor(
    private chRef: ChangeDetectorRef,
    private processoService: ProcessoService,
    private documentoService: DocumentoService,
    private consultaPublicaService: ConsultaPublicaService,
    private eventoService: EventoService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private eleRef: ElementRef,
    public loadingScreenService: LoadingScreenService,
    private sharedService: SharedService
  ) {
    this.certificate = JSON.parse(this.sharedService.getKeyStoreCertificate());
  }

  ngOnChanges() {
    this.pesquisar(0,this.PAGE_SIZE);
  }

  ngOnInit() {
    this.pesquisar(0, this.PAGE_SIZE);
  }

  public pageEventItemNaoSimilaridade(event) {
    this.pesquisar(event.pageIndex, event.pageSize);
  }

  private pesquisar(pageIndex: number, pageSize: number) {
    this.dataSource.data = [];
    this.processoService.getItemNaoSimilaridade(this.processo.id, pageIndex, pageSize).subscribe((itemNaoSimilaridade) => {
      if (itemNaoSimilaridade.content != null) {
        this.processo.itemNaoSimilaridade = itemNaoSimilaridade.content;
        this.dataSource = new MatTableDataSource<ItemNaoSimilaridade>(itemNaoSimilaridade.content);
        this.lengthItemNaoSimilaridade = itemNaoSimilaridade.totalElements;
        this.chRef.detectChanges();
      }
    });
    this.validarBotoes();
  }

  getDescricaoSituacao(id: number) {
    return SituacaoItemNaoSimilaridadeEnumAll[id].descricao;
  }

  gerarConsultaPublica() {
    const tituloModal = "Digite o período de vigência";

    const dialogRef = this.dialog.open(DialogDataInicioFimComponent, {
      maxWidth: "300px",
      height: "250px",
      data: tituloModal
    });

    let processoPesquisa: any = {};
    processoPesquisa.filtroArquivados = true;
    processoPesquisa.numeroProcessoViproc = this.processo.etiquetaProcessoViproc;
    this.processoService.pesquisa(processoPesquisa, 0, 1).subscribe(
      (processos) => {
        if (processos.content.length > 0) {
          this.messageService.messageErro("Processo arquivado");
          dialogRef.close();
          throw new Error('Processo arquivado');
        }
      }
    );

    dialogRef.afterClosed().subscribe(resultDialogDataInicioFim => {
      if (resultDialogDataInicioFim != null
        && resultDialogDataInicioFim.dataInicial != null
        && resultDialogDataInicioFim.dataFinal != null) {

        const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
          width: "400px"
        });

        openDialogSelecionarCertificado.afterClosed().subscribe(certParam => {
          if (certParam != null && certParam.cert64 != null) {
            this.consultaPublicaService.gerarDocumentoConsultaPublica(this.processo,
              resultDialogDataInicioFim.dataInicial,
              resultDialogDataInicioFim.dataFinal,
              certParam.cert64
               )
              .subscribe((consultaPublicaDto: ConsultaPublicaDto) => {
                this.pki.signHash({
                  thumbprint: certParam.thumbprint,
                  hash: consultaPublicaDto.responseStartPadesSignatureExpress.toSignHash,
                  digestAlgorithm: consultaPublicaDto.responseStartPadesSignatureExpress.digestAlgorithm
                }).success(signature => {
                  const requestCompleteNewDocPadesSignatureExpress = new RequestCompleteNewDocPadesSignatureExpress();

                  requestCompleteNewDocPadesSignatureExpress.fileToSign = consultaPublicaDto.responseStartPadesSignatureExpress.fileName;
                  // tslint:disable-next-line: max-line-length
                  requestCompleteNewDocPadesSignatureExpress.transferFileId = consultaPublicaDto.responseStartPadesSignatureExpress.transferFileId;
                  requestCompleteNewDocPadesSignatureExpress.signature = signature;
                  requestCompleteNewDocPadesSignatureExpress.hashDocumento = consultaPublicaDto.requestCompleteNewDocPadesSignatureExpress.hashDocumento;

                  requestCompleteNewDocPadesSignatureExpress.descricao = consultaPublicaDto.requestCompleteNewDocPadesSignatureExpress.descricao;
                  requestCompleteNewDocPadesSignatureExpress.descricaoAssunto = this.processo.assunto.descricao;
                  requestCompleteNewDocPadesSignatureExpress.tipoDocumentoId = consultaPublicaDto.requestCompleteNewDocPadesSignatureExpress.tipoDocumentoId;

                  requestCompleteNewDocPadesSignatureExpress.autor = JSON.parse(this.sharedService.getDocumentoRepresentado());
                  requestCompleteNewDocPadesSignatureExpress.fileName = consultaPublicaDto.requestCompleteNewDocPadesSignatureExpress.fileName;

                  // tslint:disable-next-line: max-line-length
                  requestCompleteNewDocPadesSignatureExpress.descricaoAssinatura = consultaPublicaDto.responseStartPadesSignatureExpress.descricaoAssinatura;
                  requestCompleteNewDocPadesSignatureExpress.docAssinantes = null; // Backend preenche com o autor

                  requestCompleteNewDocPadesSignatureExpress.processoId = this.processo.id;
                  requestCompleteNewDocPadesSignatureExpress.downloadUsuarioExterno = true;

                  consultaPublicaDto.requestCompleteNewDocPadesSignatureExpress = requestCompleteNewDocPadesSignatureExpress;

                  this.consultaPublicaService.publicarDocumento(consultaPublicaDto)
                    .subscribe(documento => {
                      if (documento != null && documento.id != null) {
                        this.atualizarDocumentosEvent.emit(this.processo);
                        this.messageService.messageSuccess("Consulta Pública gerada com sucesso");
                        this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
                      }
                    },
                    error => {
                      this.messageService.messageErro(error.error.message);
                      throw error;
                    });
                });
              },
              error => {
                this.messageService.messageErro(error.error.message);
                throw error;
              });
          }
        });

      }
    });
  }

  public deferirItens() {
    this.consultaPublicaService.verificaConsultaPublica(this.processo).subscribe((res) => {
      if (res) {
        this.abrirDialogConfirmacao();
      } else {
        this.messageService.messageWarning("É necessário gerar uma consulta pública para o processo antes de Deferir um item.");
      }
    },
    error => {
      if(error.error.message.includes("Não é possível realizar esta ação antes do término do prazo da Consulta Pública")){
        this.messageService.messageWarning(error.error.message);
      } else {
        this.messageService.messageErro("Erro ao buscar Consulta Pública", error.error.message);
      }
      return false;
    });

  }

  private abrirDialogConfirmacao() {
    const mensagem = "Confirma o deferimento do itens selecionados?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "450px",
      height: "170px",
      data: mensagem
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult != null && dialogResult)  {
        this.deferirItemNaoSimilaridade();
      }
    });
  }

  deferirItemNaoSimilaridade() {
    this.processoService.deferirItemNaoSimilaridade(this.processo, this.selection.selected)
    .subscribe(item => {
      if (item) {
        this.limparDataSource();
        this.processo.itemNaoSimilaridade = item;
        this.dataSource = new MatTableDataSource<ItemNaoSimilaridade>(this.processo.itemNaoSimilaridade);
        this.dataSource._updateChangeSubscription;
        this.messageService.messageSuccess("Itens Deferidos com sucesso");
        this.validarBotoes();
        this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
      }
    },
    error => {
      this.pesquisar(0,this.PAGE_SIZE);
      this.messageService.messageErro(error.error.message);
      return false;
    });
  }

  public indeferirItens() {
    this.consultaPublicaService.verificaConsultaPublica(this.processo).subscribe((res) => {
      if (res) {
        this.abrirDialogJustificativa(2);
      } else {
        this.messageService.messageWarning("É necessário gerar uma consulta pública para o processo antes de Indeferir um item.");
      }
    },
    error => {
      if(error.error.message.includes("Não é possível realizar esta ação antes do término do prazo da Consulta Pública")){
        this.messageService.messageWarning(error.error.message);
      } else {
        this.messageService.messageErro("Erro ao buscar Consulta Pública", error.error.message);
      }
      return false;
    });
  }

  private abrirDialogJustificativa(tipo: number) {
    const mensagem = "Digite a justificativa";
    const dialogRef = this.dialog.open(DialogJustificativaComponent, {
      maxWidth: "450px",
      height: "250px",
      data: mensagem
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult != null) {
        switch(tipo){
          case 1: //INDEFERIR PEDIDO
            this.setJustificativaIndeferimento(dialogResult, this.processo.itemNaoSimilaridade);
            this.gerarCertificado(dialogResult, "INDEFERIR");
            break;
          case 2: //INDEFERIR ITEM
            this.setJustificativaIndeferimento(dialogResult, this.selection.selected);
            this.indeferirItemNaoSimilaridade(this.selection.selected);
            break;
          case 3: //DESFAZER MARCADOS DO TIPO DEFERIDO CERTIFICADO
            this.desfazerItemNaoSimilaridade(dialogResult);
            break;
        } 
      } else {
        this.pesquisar(0,this.PAGE_SIZE);
      }
    });
  }

  private setJustificativaIndeferimento(justificativa: string, itens: ItemNaoSimilaridade[]) {
    itens.filter(item => !item.justificativa && SituacaoItemNaoSimilaridadeEnumAll[item.situacao] !== SituacaoItemNaoSimilaridadeEnumAll[3])
          .forEach(itemSelecionado => itemSelecionado.justificativa = justificativa);
  }

  public indeferirItemNaoSimilaridade(itens: ItemNaoSimilaridade[]) {
    this.processoService.indeferirItemNaoSimilaridade(this.processo, itens)
      .subscribe(item => {
        if (item) {
          this.messageService.messageSuccess("Itens Indeferidos com sucesso");
          this.limparDataSource();
          this.processo.itemNaoSimilaridade = item;
          this.dataSource = new MatTableDataSource<ItemNaoSimilaridade>(this.processo.itemNaoSimilaridade);
          this.dataSource._updateChangeSubscription;
          this.validarBotoes();
          this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
        }
      },
      error => {
        this.pesquisar(0,this.PAGE_SIZE);
        this.messageService.messageErro(error.error.message);
        return false;
      });
  }

  public deferirSituacaoCertificadoItemNaoSimilaridade(itens: ItemNaoSimilaridade[]) {
    this.processoService.deferirSituacaoCertificadoItemNaoSimilaridade(this.processo)
      .subscribe(item => {
        if (item) {
          this.messageService.messageSuccess("Certificado deferido com sucesso");
          this.limparDataSource();
          this.processo.itemNaoSimilaridade = item;
          this.dataSource = new MatTableDataSource<ItemNaoSimilaridade>(this.processo.itemNaoSimilaridade);
          this.dataSource._updateChangeSubscription;
          this.validarBotoes();
          this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
        }
      },
      error => {
        this.pesquisar(0, this.PAGE_SIZE);
        this.messageService.messageErro(error.error.message);
        return false;
      });
  }

  desfazerSelecionados(selecionados: ItemNaoSimilaridade[]) {
    const mensagem = "Deseja realmente desfazer a situacão desse(s) iten(s)?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "450px",
      height: "170px",
      data: mensagem
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult != null && dialogResult) {

        if(selecionados.filter(selected => 
          selected.situacao == SituacaoItemNaoSimilaridadeEnumAll.filter(situacao => situacao.descricao == SituacaoItemNaoSimilaridadeEnum.DEFERIDO_CERTIFICADO)[0].id).length > 0){
            this.abrirDialogJustificativa(3);
          } else {
            this.desfazerItemNaoSimilaridade(null);
          }
      }
    });
  }

  desfazerItemNaoSimilaridade(justificativa: string) {
    this.eventoService.desfazerItemNaoSimilaridade(this.processo, this.selection.selected,justificativa)
      .subscribe(item => {
        if (item) {
          this.messageService.messageSuccess("Situação dos iten(s) alterado com sucesso!");
          this.limparDataSource();
          this.processo.itemNaoSimilaridade = item;
          this.dataSource = new MatTableDataSource<ItemNaoSimilaridade>(this.processo.itemNaoSimilaridade);
          this.dataSource._updateChangeSubscription;
          this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
          this.validarBotoes();
        }
      },
      error => {
        this.messageService.messageErro(error.error.message);
        return false;
      });
  }

  validarDeferir() {
    let disabled = false;
    if(this.processo.situacaoAtual.situacaoFinal == true){
      return false;
    }

    if (this.selection.selected.length === 0) {
      return true;
    }

    if (this.selection.selected.length > 0) {
      this.selection.selected.forEach(item => {
        if (SituacaoItemNaoSimilaridadeEnumAll[item.situacao] === SituacaoItemNaoSimilaridadeEnumAll[1]) {
          return disabled = true;
        }
      });
    }

    return disabled;
  }

  validarIndeferir() {
    let disabled = false;

    if (this.selection.selected.length === 0) {
      return true;
    }

    if (this.selection.selected.length > 0) {
      this.selection.selected.forEach(item => {
        if (SituacaoItemNaoSimilaridadeEnumAll[item.situacao] === SituacaoItemNaoSimilaridadeEnumAll[3]) {
          return disabled = true;
        }
      });
    }

    return disabled;
  }

  private limparDataSource() {
    this.dataSource.data = [];
    this.processo.itemNaoSimilaridade = new ItemNaoSimilaridade();
  }

  private validarBotoes() {
    this.selection.clear();
    this.validarDeferir();
    this.validarIndeferir();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ItemNaoSimilaridade): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id}`;
  }


  // GERAR DOCUMENTO - CERTIFICADO DEFERIMENTO e INDEFERIMENTO

  public validaCertificadoDeferimento() {
    this.consultaPublicaService.verificaConsultaPublica(this.processo).subscribe((res) => {
      if (res) {
        if (this.processo.itemNaoSimilaridade.filter(item => SituacaoItemNaoSimilaridadeEnumAll[item.situacao]
          === SituacaoItemNaoSimilaridadeEnumAll[0]).length > 0) {
            this.messageService.messageWarning("É necessario analisar todos os itens antes de gerar um certificado de não similaridade.");
        } else if (this.processo.itemNaoSimilaridade.filter(item => SituacaoItemNaoSimilaridadeEnumAll[item.situacao]
            === SituacaoItemNaoSimilaridadeEnumAll[1]).length > 0) {
          this.gerarCertificado(null, "DEFERIR");
        } else {
          this.messageService.messageWarning("É necessário pelo menos 1 item deferidos para gerar um certificado de não similaridade.");
        }
      } else {
        this.messageService.messageWarning("É necessário gerar uma consulta pública para o"
          + " processo antes de gerar um certificado de não similaridade.");
      }
    },
    error => {
      if(error.error.message.includes("Não é possível realizar esta ação antes do término do prazo da Consulta Pública")){
        this.messageService.messageWarning(error.error.message);
      } else {
        this.messageService.messageErro("Erro ao buscar Consulta Pública", error.error.message);
      }
      return false;
    });
  }

  public validarConsultaPublica() {
    this.consultaPublicaService.verificaConsultaPublica(this.processo).subscribe((res) => {
      if (res) {
        this.messageService.messageWarning("Esse documento já existe no processo!");
      } else {
        this.gerarConsultaPublica();
      }
    },
    error => {
      if(error.error.message.includes("Não é possível realizar esta ação antes do término do prazo da Consulta Pública")){
        this.messageService.messageWarning(error.error.message);
      } else {
        this.messageService.messageErro("Erro ao buscar Consulta Pública", error.error.message);
      }
      return false;
    });
  }

  public validarIndeferirPedido() {
    this.consultaPublicaService.verificaConsultaPublica(this.processo).subscribe((res) => {
      if (res) {
        this.abrirDialogJustificativa(1);
      } else {
        this.messageService.messageWarning("É necessário gerar uma consulta pública para o processo antes de indeferir pedido.");
      }
    },
    error => {
      if(error.error.message.includes("Não é possível realizar esta ação antes do término do prazo da Consulta Pública")){
        this.messageService.messageWarning(error.error.message);
      } else {
        this.messageService.messageErro("Erro ao buscar Consulta Pública", error.error.message);
      }
      return false;
    });
  }

  public gerarCertificado(justificativa: string, acaoCertificado: string) {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate
    });

    openDialogSelecionarCertificado.afterClosed().subscribe(certParam => {
      if (certParam != null && certParam.cert64 != null) {
        const itensInicio = this.processo.itemNaoSimilaridade;

        this.certificate = certParam;
        const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
        this.processoService.gerarCertificadoItemNaoSimilaridade(justificativa, this.processo, acaoCertificado)
          .subscribe(certificadoResponse => {
            this.documentoService.startWithHtmlNumeracao(certificadoResponse.html, certificadoResponse.tipoDocumentoId, cert.cert64,
                this.processo.id, JSON.parse(this.sharedService.getDocumentoAstra()), SharedService.CARREGAR_LOADING)
                .subscribe(sigParams => {
              this.pki.signHash({
                thumbprint: cert.thumbprint,
                hash: sigParams.toSignHash,
                digestAlgorithm: sigParams.digestAlgorithm
              }).success(signature => {
                this.sigParams = sigParams;
                this.signature = signature;
                this.certificadoResponse = certificadoResponse;
                this.acaoCertificado = acaoCertificado;
                const element: HTMLElement = document.getElementById("btnConcluirAssinatura") as HTMLElement;
                element.click();
              }).fail(error => {
                this.mensagemFailPki = error.message;
                const element: HTMLElement = document.getElementById("btnCancelarAssinatura") as HTMLElement;
                element.click();
              });
            },
            error => {
              this.pesquisar(0, this.PAGE_SIZE);
              throw error;
            });
          });
        } else {
          this.pesquisar(0, this.PAGE_SIZE);
        }

      });
    }

    private concluirAssinatura() {
      this.completeEditorTexto(this.sigParams, this.signature, this.certificadoResponse, this.acaoCertificado);
      this.atualizarEventosItemNaoSimilaridadeEvent.emit(true);
      this.validarBotoes();
    }

    private cancelarAssinatura() {
      this.messageService.messageErro(this.mensagemFailPki);
      this.pesquisar(0, this.PAGE_SIZE);
    }

  private completeEditorTexto(sigParams: any, signature: string, certificadoResponse: any, acaoCertificado: string) {

    this.documentoService.completeWithNumeracao(sigParams.fileName,
      sigParams.transferFileId,
      signature,
      certificadoResponse.documentoDescricao,
      this.processo.assunto.descricao,
      certificadoResponse.tipoDocumentoId,
      JSON.parse(this.sharedService.getDocumentoAstra()),
      certificadoResponse.nomeArquivo,
      sigParams.descricaoAssinatura,
      sigParams.hashDocumento,
      this.processo.id,
      sigParams.numeroDocumento,
      sigParams.anoNumeroDocumento,
      null,
      SharedService.CARREGAR_LOADING).subscribe(documento => {

      if (acaoCertificado === "INDEFERIR") {
        this.indeferirItemNaoSimilaridade(this.processo.itemNaoSimilaridade);
      } else {
        this.deferirSituacaoCertificadoItemNaoSimilaridade(this.processo.itemNaoSimilaridade);
      }

      this.documentoAssinado = new DocumentoDTO();
      this.documentoAssinado.documentoId = documento.id;
      this.updateProcessoWithNewDocument();
    }, error => {
      this.messageService.messageErro("Erro no upload do arquivo.");
    });
  }

  private updateProcessoWithNewDocument() {
    const processoNovasInformacoes = new ProcessoDTO();
    processoNovasInformacoes.id = this.processo.id;
    processoNovasInformacoes.listDocumento = [];
    processoNovasInformacoes.listDocumento.push(this.documentoAssinado);
    this.processoService.put(processoNovasInformacoes).subscribe((resp) => {
      this.messageService.messageSuccess("Novo documento inserido.");
      this.loadingScreenService.stopLoading();
      this.atualizarDocumentosEvent.emit(this.processo);
    },
    error => {
      this.messageService.messageErro(error.error.message);
      throw error;
    });
  }

  getNcmFormatado(ncm){
    while (ncm.length < 8) ncm = "0" + ncm;
    return ncm;
  }

  getFormattedPrice(price: string) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(price.replace(/\D/g,''))/100);
  }
}
