import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class ActiveMQService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "activemq", config);
    this.API_URL = config.API_COMUNICACAO_URL;
  }

  public listarFilas(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}`).pipe(map(response => response));
  }

  public listarMensagens(nomeFila: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/${nomeFila}`).pipe(map(response => response));
  }

  public reprocessarMensagens(nomeFila: string, mensagens: string[]): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/${nomeFila}/${mensagens}`, null).pipe(map(response => response));
  }

  public excluirMensagens(nomeFila: string, mensagens: string[]): Observable<any> {
    return this.http.delete(`${this.API_URL}${this.resource}/${nomeFila}/${mensagens}`).pipe(map(response => response));
  }

}