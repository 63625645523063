import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatSort, MatTab } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/global/api/shared.service";
import { MessageService } from "src/app/global/message/message.service";
import { environment } from "../../../../environments/environment";
import { Certificate } from "../../../global/models/certificate.model";
import { ProcessoService } from "../../api/processo.service";
import { ProcessoPequisa } from "../../models/processo-pesquisa.model";
import { ProcessoTabelaDto } from "../../models/processo-tabela-dto.model";
import { HomeComponent } from "src/app/global/home";

@Component({
  selector: "app-listar-meus-processos",
  templateUrl: "./listar-meus-processos.component.html",
  styleUrls: ["./listar-meus-processos.component.css"],
})
export class ListarMeusProcessosComponent implements OnInit {
  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public tabelaMeusProcessos = "tabela-meus-processos";

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedProcessosColumns: string[] = ["id", "numero", "informacoes", "autor", "relacionamentos", "dataCriacao"];
  public resourceConsultarProcesso = environment.CONTEXTO + "/consultar-processo";

  public dataSourceMeusProcessos: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorMeusProcessos") paginatorMeusProcessos: MatPaginator;
  public resultsLengthMeusProcessos = 0;

  private certificate: Certificate;
  sort: MatSort;

  constructor(
    protected route: ActivatedRoute,
    public router: Router,
    public processoService: ProcessoService,
    public messageService: MessageService,
    public home: HomeComponent,
    private sharedService: SharedService
  ) {
    this.certificate = new Certificate();
  }

  public pageEventMeusProcessos(event) {
    this.getMeusProcessos(event.pageIndex, event.pageSize);
  }

  public getMeusProcessos(page: number, size: number) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.cpfCnpj = this.sharedService.decodePayloadJWT().sub;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processosDto) => {
        this.dataSourceMeusProcessos = new MatTableDataSource<ProcessoTabelaDto>(processosDto.content);
        // this.dataSourceMeusProcessos.filter = this.home.usuario.documento;
        this.resultsLengthMeusProcessos = processosDto.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  pesquisarComOrdenacaoMeusProcessos(sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.cpfCnpj = this.home.usuario.documento;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorMeusProcessos.pageIndex,
        this.paginatorMeusProcessos.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processosDto) => {
          this.dataSourceMeusProcessos = new MatTableDataSource<ProcessoTabelaDto>(processosDto.content);
          this.resultsLengthMeusProcessos = processosDto.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  getProcesso(processoId: number) {
    this.router.navigate([`${this.resourceConsultarProcesso}/${processoId}`], {});
  }

  ngOnInit() {
    this.getMeusProcessos(0, this.PAGE_SIZE);
  }
}
