import { Domain } from "../../global/models/domain.model";

export class Assinador extends Domain {
  public nome: string;
  public documento: string;
  public dataHoraIndicacao: Date;
  public dataHoraAssinatura: Date;
  static nome: string;
  public assinado: boolean;
}
