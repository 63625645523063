import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatIconModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatTableModule } from "@angular/material";
import { NgxCpfCnpjModule } from "ngx-cpf-cnpj";
import { NgxMaskModule } from "ngx-mask";
import { CpfPipe } from "src/app/global/pipes/cpf.pipe";
import { PipesModule } from "src/app/global/pipes/pipes.module";
import { CidadaoRoutingModule } from "./cidadao-routing.module";
import { ListarCidadaoComponent } from "./listar-cidadao/listar-cidadao.component";
import { SalvarCidadaoComponent } from "./salvar-cidadao/salvar-cidadao.component";

@NgModule({
    declarations: [
        SalvarCidadaoComponent,
        ListarCidadaoComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatPaginatorModule,
        NgxCpfCnpjModule,
        NgxMaskModule,
        PipesModule,
        CidadaoRoutingModule
    ],
    exports: [
        SalvarCidadaoComponent,
        ListarCidadaoComponent
    ]
})
export class CidadaoModule { }