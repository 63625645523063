import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { CidadaoService } from "src/app/core/api/seguranca/cidadao.service";
import { PerfilService } from "src/app/core/api/seguranca/perfil.service";
import { Orgao } from "src/app/core/models/orgao.model";
import { Cidadao } from "src/app/core/models/seguranca/cidadao.model";
import { Perfil } from "src/app/core/models/seguranca/perfil.model";
import { TipoUsuarioEnumAll } from "src/app/core/models/seguranca/tipo-usuario.enum";
import { SharedService } from "src/app/global/api/shared.service";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { MessageService } from "src/app/global/message/message.service";
import { TokenTO } from "src/app/global/models/token.model";
import { formatarCPF, formatarTelefone, limpaFormatacao } from "src/app/global/util/funcoes";
import { MatDialog, MatTreeNestedDataSource } from "@angular/material";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { MeuPerfilService } from "src/app/core/api/meu-perfil.service";

@Component({
  selector: "sgeap-salvar-cidadao",
  templateUrl: "./salvar-cidadao.component.html",
  styleUrls: ["./salvar-cidadao.component.css"],
})
export class SalvarCidadaoComponent extends SalvarGenericsComponent<Cidadao> implements OnInit {

  @Input() completarCadastro: boolean;
  @Input() myVariable: string;
  perfilList: Perfil[];
  tipoUsuarioList = TipoUsuarioEnumAll;
  confirmacaoSenha: string;
  dataSource = new MatTreeNestedDataSource<any>();
  itemMenuPerfil: boolean;
  public senha: string;
  public senhaVisivel: boolean = false;

  listOrgaos: Orgao[];
  orgaosSelecionados: Orgao[] = [];

  maskTelefone = "(00) 00000-0000";
  maxDate = new Date().toJSON().split("T")[0];
  minDate = new Date(1900, 1, 1).toJSON().split("T")[0];
  

  constructor(
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: CidadaoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    private perfilService: PerfilService,
    private sharedService: SharedService,
    private orgaoService: OrgaoService,
    private menuService: MeuPerfilService
  ) {
    super(formBuilder, router, apiService, messageService, route, "usuario", {});

    // this.myVariable = this.route.snapshot.data.myVariable;
    //   [{ descricao: ['', Validators.required] },
    //   { codigoIdentificacao: ['', Validators.required] }]);
    // this.endpoint = "cidadao";
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.itemMenuPerfil = params["itemMenu"];
    });

    this.carregarPerfis();
    this.carregarDadosUsuario();
    this.carregarListaOrgaos();
  }

  afterResponse(): void {
    this.domain.cpf = formatarCPF(this.domain.cpf);
    this.domain.telefone = formatarTelefone(this.domain.telefone);

    if (this.domain.id) {
      this.orgaoService.getOrgaoUsuario(limpaFormatacao(this.domain.cpf)).subscribe((res) => {
        this.domain.orgaos = res;
      });
    }

  }

  private carregarDadosUsuario() {
    const token: TokenTO = this.sharedService.decodePayloadJWT();
    this.domain.email = token.email;
    this.domain.faceId = token.faceId;
    this.domain.nome = token.name;
    this.domain.orgaos = token.orgaoSigla;
  }

  private carregarPerfis() {
    this.perfilService.list().subscribe(
      (res) => {
        this.perfilList = res;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
  toggleSenhaVisivel() {
    this.senhaVisivel = !this.senhaVisivel;
  }

  salvarValidar(domain: Cidadao): void {
    if (this.validar(domain)) {
      domain.cpf = limpaFormatacao(domain.cpf);
      domain.telefone = limpaFormatacao(domain.telefone);
      domain.documento = domain.cpf;
      this.submeter();
    }
  }

  afterSubmit(): void {
    this.orgaoService.atualizarOrgaosUsuario(this.domain.cpf, this.domain.orgaos).toPromise();
  }

  private validar(domain: Cidadao): boolean {
    let validacao =
      this.valida(domain.nome, "Nome") &&
      this.valida(domain.cpf, "CPF") &&
      this.valida(domain.email, "E-mail") &&
      this.valida(domain.telefone, "Telefone");
  
    // Valida senha
    if (this.itemMenuPerfil) {
      if (domain.senha === "" || this.confirmacaoSenha === "") {
        if (domain.senha != this.confirmacaoSenha) {
          validacao = false;
          this.messageService.messageErro("As senhas devem ser digitadas e iguais");
        }
      }
    }
  
    // Valida CPF
    if (!this.validarCPF(domain.cpf)) {
      validacao = false;
      this.messageService.messageErro("CPF inválido");
    }
  
    return validacao;
  }
  
  private validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
  
    if (cpf.length !== 11) {
      return false;
    }
  
    // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = soma % 11;
    let digitoVerificador1 = resto < 2 ? 0 : 11 - resto;
  
    // Verifica o primeiro dígito verificador
    if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
      return false;
    }
  
    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = soma % 11;
    let digitoVerificador2 = resto < 2 ? 0 : 11 - resto;
  
    // Verifica o segundo dígito verificador
    if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
      return false;
    }
  
    return true;
  }
  

  get usuarioInterno(): boolean {
    return this.domain.perfil != null && this.domain.perfil.nome !== "Cidadão";
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  comparePerfil(p1: Perfil, p2: Perfil): boolean {
    if (p1 == null || p2 == null) {
      return false;
    }
    return p1.id == p2.id;
  }

  compareTipoUsuario(t1, t2): boolean {
    if (t1 == null || t2 == null) {
      return false;
    }
    return t1 == t2;
  }

  valida(valor: any, campo: string) {
    let validacao = true;
    if (!valor) {
      this.messageService.messageErro(`Preencha o campo ${campo}.`);
      validacao = false;
    }
    return validacao;
  }

  private carregarListaOrgaos(): void {
    this.orgaoService.list().subscribe(
      (res) => {
        this.listOrgaos = res;
        this.domain.orgaos = res;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  compareOrgao(obj1: Orgao, obj2: Orgao): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  private openDialogLotacao() {
    const dialogLotacao = this.dialog.open(DialogLotacaoComponent, {
      width: "1200px",
      data:{multiSelect:false, selected:this.domain.orgaos, ehSelect: true}
    });

    dialogLotacao.afterClosed().subscribe((lotacao) => {
      if (lotacao) {
        this.domain.orgaos = [];
        this.domain.orgaos.push(lotacao);
      }
    });
      
  }
}
