import { MetadadoValor } from "./metadado-valor.model";
import { RegrasProcesso } from "./regras-processo.model";

export class GerarPdfUploadAlfrescoRequest {
  html: string;
  descricao: string;
  autor: string;
  descricaoAssunto: string;
  tipoDocumentoId: number;
  processoId: number;
  nomeDocumento: string;
  docAssinantes: string[];
  downloadUsuarioExterno: boolean;
  listMetadadoValor: MetadadoValor[];
  regrasProcesso: RegrasProcesso;
}