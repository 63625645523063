export class ProcessoPequisa {
  public numeroProcessoViproc: string;
  public nomeParte: string;
  public faseId: number;
  public situacaoId: number;
  public assuntoId: number;
  public cpfCnpj: string;
  public dataInicial: string;
  public dataFinal: string;
  public dataInicialArquivamento: string;
  public dataFinalArquivamento: string;
  public cpfCnpjRepresentante: string;
  public assuntoCategoriaId: number;
  public lotacaoAtual: number[] = [];
  public lotacaoResponsavel: number[] = [];
  public filtroArquivados: boolean;
  public filtrarPorObservando: boolean;
  public filtrarPorDocAssinados: boolean;
  public diasSemAndamento: number;
  public tipoDocumentoId: number;
  public filtrarAguardandoSuaAssinatura: boolean;
  public filtrarSemResponsabilidade: boolean;
  public consultaPrincipal: boolean;
  public documentoAssinador: string;
  public tipoArquivamento: number;
  public documentoArquivamento: string;
  public idOrgaoUsuarioLogado: number;
}
