import { Component, OnInit, NgZone, Inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-dialog-processo-aberto",
  templateUrl: "./dialog-processo-aberto.component.html",
  styleUrls: [ "./dialog-processo-aberto.component.css"]
})
export class DialogProcessoAbertoComponent implements OnInit {

  private resourceRouterProcesso = environment.CONTEXTO +  "/processo";

  constructor(
    public dialogRef: MatDialogRef<DialogProcessoAbertoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private ngZone: NgZone) {}

  ngOnInit() {
  }

  continuar(data) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate([`${this.resourceRouterProcesso}/${data.id}/${data.tipo}/${data.infoAutor}/${data.infoAssinador}/${data.descricao.replace("/", "\\")}`]);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.ngZone.run(() => this.router.navigate(["home"])).then();
  }
}
