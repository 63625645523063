import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProcessoService } from "../../api/processo.service";
import { DocumentoService } from "../../api/documento.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { Processo } from "../../models/processo.model";
import { Documento } from "../../models/documento.model";
import { ProcessoPequisa } from "../../models/processo-pesquisa.model";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { Movimentacao } from "../../models/movimentacao.model";
import { Assunto } from "../../models/assunto.model";
import { ResponsavelService } from "../../api/responsavel.service";
import { ToastrService } from "ngx-toastr";
import { SituacaoService } from "../../api/situacao.service";
import { MatDialog } from "@angular/material";
import { IncluirDocumentoComponent } from "../../documento/incluir-documento/incluir-documento.component";
import { ContribuinteService } from "../../api/contribuinte.service";
import { MessageService } from "src/app/global/message/message.service";
import { AssuntoService } from "../../api/assunto.service";
import { SharedService } from "src/app/global/api/shared.service";
import { Utils } from "src/app/global/util/utils-component";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum } from "../../models/tipo-abertura-processo-enum.model";

@Component({
  selector: "app-listar-processo",
  templateUrl: "./listar-processo.component.html",
  styleUrls: ["./listar-processo.component.css"]
})
export class ListarProcessoComponent implements OnInit {

  @ViewChild("paginatorDocumentos") paginatorDocumentos: MatPaginator;
  public dataSourceDocumento: MatTableDataSource<Documento>;

  @ViewChild("paginatorMovimentacao") paginatorMovimentacao: MatPaginator;
  public dataSourceMovimentacao: MatTableDataSource<Movimentacao>;

  @ViewChild("paginatorProcessoEmAndamento") paginatorProcessoEmAndamento: MatPaginator;
  public dataSourceProcesso: MatTableDataSource<Processo>;

  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public PAGE_INITIAL = 0;

  @ViewChild("paginatorProcessoArquivado") paginatorProcessoArquivado: MatPaginator;
  public dataSourceProcessosArquivados: MatTableDataSource<Processo>;

  public responsavel: any;
  public step = 0;
  public abrirAccordion: boolean;

  public isArquivado: boolean = false;

  @ViewChild(MatSort, {read: false}) sort: MatSort;

  public displayedColumns: string[] = ["id", "numero", "tipoDocumento", "descricao"];
  public displayedMovimentacoesColumns: string[] = ["localizacao", "situacao", "permanencia", "datahora"];
  public displayedProcessosColumns: string[] = ["id", "numero", "informacoes", "situacao", "detalhes"];
  public displayedDocumentosColumns: string[] = ["id", "tipoDocumento", "numero", "descricao", "dataHora"];

  public processo: Processo;
  public documentos: Documento[] = [];
  public movimentacoes: Movimentacao[] = [];
  public assuntos: Assunto[] = [];

  public highlightedProcessoRows: any[] = [];

  public processoPesquisa: ProcessoPequisa;

  public infoAssuntoAutor = new OpcaoAssuntoInterno();

  constructor(protected route: ActivatedRoute,
    public router: Router,
    public processoService: ProcessoService,
    public tipoDocumentoService: TipoDocumentoService,
    private documentoService: DocumentoService,
    private contribuinteService: ContribuinteService,
    public responsavelService: ResponsavelService,
    public toastr: ToastrService,
    public situacaoService: SituacaoService,
    public messageService: MessageService,
    public assuntoService: AssuntoService,
    public dialog: MatDialog,
    private sharedService: SharedService) {
      this.dataSourceDocumento = new MatTableDataSource<Documento>([]);
      this.dataSourceMovimentacao = new MatTableDataSource<Movimentacao>();
      this.dataSourceProcesso = new MatTableDataSource<Processo>([]);
      this.dataSourceProcessosArquivados = new MatTableDataSource<Processo>([]);
     }

  ngOnInit() {
    this.processoPesquisa = new ProcessoPequisa();
    this.processoPesquisa.cpfCnpj = JSON.parse(this.sharedService.getDocumentoRepresentado());
    this.infoAssuntoAutor.tipo = TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO;
    this.infoAssuntoAutor.infoAutor = JSON.parse(this.sharedService.getDocumentoRepresentado());

    this.assuntoService.list().subscribe(assuntos => this.assuntos = assuntos);
    this.carregarProcessoEmAndamento(this.PAGE_INITIAL, this.PAGE_SIZE);
    this.carregarProcessoArquivado(this.PAGE_INITIAL, this.PAGE_SIZE);
  }

  pesquisar(page: number, size: number) {
    this.carregarProcessoEmAndamento(page, size);
    this.carregarProcessoArquivado(page, size);
    this.processo = null;
  }

  private limparBusca() {
    this.processoPesquisa = new ProcessoPequisa();
    this.processoPesquisa.cpfCnpj = JSON.parse(this.sharedService.getDocumentoRepresentado());
    this.limparPesquisa();
  }

  private limparPesquisa() {
    this.dataSourceProcesso = new MatTableDataSource<Processo>([]);
    this.dataSourceProcessosArquivados = new MatTableDataSource<Processo>([]);
    this.processo = null;
    this.isArquivado = false;
  }

  public pageEventProcessoAndamento(event) {
    this.carregarProcessoEmAndamento(event.pageIndex, event.pageSize);
  }
  
  public pageEventSetorProcessoArquivado(event) {
    this.isArquivado = true;
    this.carregarProcessoArquivado(event.pageIndex, event.pageSize);
  }
  
  private carregarProcessoEmAndamento(page: number, size: number) {
    this.processoService
    .getProcessoEmAndamentoByContribuinte(this.processoPesquisa, page, size)
    .subscribe(
      (processo) => {
        for (const p of processo.content) {
          this.assuntoService.get(p.assunto.id).subscribe(
            (assunto) => {
              p.assunto = assunto;
            },
            error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
          this.contribuinteService.findByDocumento(p.autor.documento).subscribe(
              (response) => {
                p.autor = response;
              },
              error => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
          );
        }
        this.dataSourceProcesso.data = processo.content;
        this.paginatorProcessoEmAndamento.length = processo.totalElements;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
  
  private carregarProcessoArquivado(page: number, size: number) {
    this.processoService.getProcessoArquivadoByContribuinte(this.processoPesquisa, page, size)
    .subscribe(
      (processoArquivados) => {
        for (const p of processoArquivados.content) {
          this.assuntoService.get(p.assunto.id).subscribe(
            (assunto) => {
              p.assunto = assunto;
            },
            error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
          this.contribuinteService.findByDocumento(p.autor.documento).subscribe(
            (response) => {
              p.autor = response
            }, 
            error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        }
        this.dataSourceProcessosArquivados.data = processoArquivados.content;
        this.paginatorProcessoArquivado.length = processoArquivados.totalElements;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
  
  setStep(index: number) {
    this.step = index;
  }
  
  nextStep() {
    this.step++;
  }
  
  prevStep() {
    this.step--;
  }
  
  getRecord(rowProcesso: any, isArquivado: boolean) {
    this.highlightedProcessoRows.pop();
    this.highlightedProcessoRows.push(rowProcesso);
    this.limparDocumentosEMovimentacoes();
    this.carregarDocumentosEMovimentacoes(rowProcesso.id);
    this.isArquivado = isArquivado;
  }
  
  limparDocumentosEMovimentacoes() {
    this.documentos = [];
    this.dataSourceDocumento.data = [];
    this.dataSourceMovimentacao.data = [];
  }

  carregarDocumentosEMovimentacoes(id: number) {
    this.processoService.getProcessoContribuinte(id,this.infoAssuntoAutor.infoAutor).subscribe((processo) => {

      this.documentoService.listarDocumentosByIdProcesso(processo.id).subscribe(
        documentos => {
          this.documentos = documentos;
          this.dataSourceDocumento = new MatTableDataSource<Documento>(documentos);
          this.dataSourceDocumento.paginator = this.paginatorDocumentos;
        }, 
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );

      this.assuntoService.get(processo.assunto.id).subscribe(
        assunto => {
          processo.assunto = assunto
        }, 
        error => {
          this.messageService.messageErro(error.error.message);
          throw error;
        });

      if (processo.listMovimentacao != null) {
        this.movimentacoes = processo.listMovimentacao.sort(function (m1, m2) {
          return m2.dataCriacao - m1.dataCriacao;
        });
        this.dataSourceMovimentacao = new MatTableDataSource<Movimentacao>(this.movimentacoes);
        this.dataSourceMovimentacao.paginator = this.paginatorMovimentacao;
      }

      this.processo = processo;
      this.abrirAccordion = true;
    });
  }

  adicionarDocumento() {
    this.openDialog(this.processo.id);
  }

  openDialog(id: number): void {
    this.dialog.open(IncluirDocumentoComponent, {
      width: "700px",
      data: id
    }).afterClosed().subscribe(result => {
      this.carregarDocumentosEMovimentacoes(this.processo.id);
    });
  }

  downloadDocumento($event, documento: Documento) {
    $event.preventDefault(); $event.stopPropagation();
    if (documento.downloadUsuarioExterno == false) {
      this.messageService.messageWarning("Você não possui permissão para visualizar o documento.");
    } else {
      if (documento.status == 2) {
        var fileType = documento.nomeDocumento.toLowerCase().indexOf(".pdf") > -1 ? "application/pdf" : "";
        this.documentoService.download(documento.id, fileType).subscribe(blob => {
          if (!Utils.downloadBlob(blob, fileType ? "" : documento.nomeDocumento)) {
            this.messageService.messageWarning("Documento ainda não existe.");
          }
        });
      } else {
        this.messageService.messageWarning("Documento pendente de armazenamento interno na SEFAZ.");
      }
    }
  }

  downloadDocumentoProcesso($event, idProcesso: number) {
    $event.preventDefault(); $event.stopPropagation();
    this.documentoService.downloadDocumentoProcesso(idProcesso).subscribe(blob => {
      if (blob) {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Não existe documento no processo.");
        }
      } else {
        this.messageService.messageWarning("Documento ainda não existe.");
      }
    }, error => {
      const erro = Utils.converterArrayBufferParaJSON(error);
      this.messageService.messageErro(erro.error.message);
    });
  }
}
