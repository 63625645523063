import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { HomeComponent } from "./home.component";
import { MaterialModule } from "src/app/material";
import { Ng7MatBreadcrumbModule } from "ng7-mat-breadcrumb";
import { RouterModule } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { MeuPerfilService } from "src/app/core/api/meu-perfil.service";

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, Ng7MatBreadcrumbModule, RouterModule, AngularSvgIconModule.forRoot()],
  providers: [MeuPerfilService],
  declarations: [HomeComponent],
})
export class HomeModule {}
