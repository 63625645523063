import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DialogProcessoComponent } from "../dialog-processo/dialog-processo.component";
import { ProcessoService } from "../../api/processo.service";
import { MessageService } from "../../../global/message/message.service";
import { MovimentacaoDTO } from "../../models/movimentacao-dto.model";
import { TipoArquivamentoEnumAll } from "../../models/tipo-arquivamento-enum.model";
import { Fase } from "../../models/fase.model";
import { FaseService } from "../../api/fase.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-dialog-arquivar",
  templateUrl: "./dialog-arquivar.component.html",
  styleUrls: ["./dialog-arquivar.component.css"],
})
export class DialogArquivarComponent implements OnInit {
  public fases: Fase[] = [];
  public fase: Fase;
  public observacao: string;
  public tipoArquivamento: any;
  public tipoArquivamentoList: any[] = TipoArquivamentoEnumAll;

  constructor(
    public dialogRef: MatDialogRef<DialogProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private faseService: FaseService,
    private processoService: ProcessoService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {
    this.fase = new Fase();
  }

  ngOnInit() {
    this.faseService.list().subscribe(
      (fases) => {
        this.fases = fases.filter(function (fase) {
          return !fase.situacaoPosVisualizacao.situacaoFinal && fase.ativo;
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  arquivar() {
    const movimentacaoDTO = new MovimentacaoDTO();

    if (this.fase && this.fase.id) {
      movimentacaoDTO.faseId = this.fase.id;
    } else {
      movimentacaoDTO.faseId = null;
    }

    movimentacaoDTO.docAutor = this.sharedService.decodePayloadJWT().sub;
    movimentacaoDTO.observacao = this.observacao;

    if (this.tipoArquivamento && this.tipoArquivamento.id) {
      movimentacaoDTO.tipoArquivamentoId = this.tipoArquivamento.id;
    } else {
      movimentacaoDTO.tipoArquivamentoId = null;
    }

    this.processoService.arquivarProcesso(movimentacaoDTO, this.data.id).subscribe(
      (res) => {
        this.messageService.messageSuccess("Arquivamento realizado com sucesso", res.etiquetaProcessoViproc);
        this.data.listMovimentacao = res.listMovimentacao;
        this.dialogRef.close(this.data);
      },
      (error) => {
        this.messageService.messageErro(error.error.message, "Erro no arquivamento");
        throw error;
      }
    );
  }
}
