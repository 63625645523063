import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/core";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { SituacaoService } from "src/app/core/api/situacao.service.js";
import { MessageService } from "src/app/global/message/message.service";
import { Grupo } from "../../../models/grupo.model";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource, MatDialog, MatDatepickerInputEvent } from "@angular/material";
import { GrupoService } from "src/app/core/api/grupo.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { Assunto } from "src/app/core/models/assunto.model";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { SisSegService } from "src/app/core/api/sis-seg.service";
import { Usuario } from "src/app/core/models/usuario.model";
import { GrupoAssunto } from "src/app/core/models/grupo-assunto.model";
import { DialogLotacaoComponent } from "src/app/core/processo/dialog-lotacao/dialog-lotacao.component";
import { Aviso } from "src/app/core/models/aviso.model";
import { AvisoService } from "src/app/core/api/aviso.service";
import { TipoExibicaooEnumAll } from "src/app/core/models/tipo-exibicao.enum";
import { TelaEnumAll } from "src/app/core/models/tela.enum";
import moment, * as _moment from "moment";

@Component({
  selector: "app-salvar-aviso",
  templateUrl: "./salvar-aviso.component.html",
  styleUrls: ["./salvar-aviso.component.css"],
})
export class SalvarAvisoComponent extends SalvarGenericsComponent<Aviso> {
  public ckeConfig: any;
  public tipoExibicaoList = TipoExibicaooEnumAll;
  public telaList = TelaEnumAll;
  public dataInicioInput: string;
  public dataFinalInput: string;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: AvisoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "aviso", "");
  }

  ngOnInit() {
    this.confiCkEditor();
  }

  afterResponse() {
    this.domain.dataInicio = new Date(this.domain.dataInicio);
    this.domain.dataFim = new Date(this.domain.dataFim);
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.resumo || !domain.resumo.trim()) {
      this.messageService.messageErro("Preencha o campo resumo.");
      validacao = false;
    }
    if (!domain.conteudo || !domain.conteudo.trim()) {
      this.messageService.messageErro("Preencha o campo conteúdo.");
      validacao = false;
    }
    if (domain.tela == null) {
      this.messageService.messageErro("Selecione a Tela de Exibição.");
      validacao = false;
    }
    if (domain.tipoExibicao == null) {
      this.messageService.messageErro("Selecione o Tipo de Exibição.");
      validacao = false;
    }
    if (!domain.dataInicio) {
      this.messageService.messageErro("Preencha o campo Data Início.");
      validacao = false;
    }
    if (!domain.dataFim) {
      this.messageService.messageErro("Preencha o campo Data Fim.");
      validacao = false;
    }
    if (domain.dataInicio.getTime() >= domain.dataFim.getTime()) {
      this.messageService.messageErro("Data Início deve ser anterior à Data Fim.");
      validacao = false;
    }
    if (domain.explodir == null) {
      this.messageService.messageErro("Selecione o campo Explodir na Tela.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }

    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  setDataInicioInput(type: string, event: string) {
    this.dataInicioInput = event;
  }

  private setDataInicioChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.domain.dataInicio = event.value;
  }

  private setDataInicioBlur() {
    this.domain.dataInicio = moment(this.dataInicioInput, "DD/MM/YYYY").toDate();
  }

  private setDataFinalInput(type: string, event: string) {
    this.dataFinalInput = event;
  }

  private setDataFinalChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.domain.dataFim = event.value;
  }

  private setDataFinalBlur() {
    this.domain.dataFim = moment(this.dataFinalInput, "DD/MM/YYYY").toDate();
  }
}
