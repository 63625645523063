import { Component, OnInit, ViewChild } from "@angular/core";
import { Processo } from "../../models/processo.model";
import { ProcessoService } from "../../api/processo.service";
import { ResponsavelService } from "../../api/responsavel.service";
import { AssuntoService } from "../../api/assunto.service";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { Documento } from "../../models/documento.model";
import { SelectionModel } from "@angular/cdk/collections";
import { DocumentoService } from "../../api/documento.service";
import { Assinatura } from "../../models/assinatura.model";
import { AssinaturaService } from "../../api/assinatura.service";
import { Movimentacao } from "../../models/movimentacao.model";
import { MovimentacaoService } from "../../api/movimentacao.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { MessageService } from "src/app/global/message/message.service";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { Certificate } from "src/app/global/models/certificate.model";
import { SharedService } from "src/app/global/api/shared.service";
import { LacunaWebPKI } from "web-pki";
import { environment } from "src/environments/environment";

// Deprecated? Sem uso em 23/05/2020 by: Luiz Lima

@Component({
  selector: "app-detalhamento-processo",
  templateUrl: "./detalhamento-processo.component.html",
  styleUrls: ["./detalhamento-processo.component.css"],
})
export class DetalhamentoProcessoComponent implements OnInit {
  public processo: Processo;
  public responsavel: any;
  public assunto: any;
  public movimentacao: Movimentacao;

  public titleDocumento: string;
  public titleProcesso: string;
  public titleMovimentacao: string;
  public documento: Documento;

  public dataSource: MatTableDataSource<Documento>;
  public dataSourceAssinatura: MatTableDataSource<Assinatura>;
  public dataSourceAssinaturaIndicado: MatTableDataSource<Assinatura>;
  public dataSourceMovimentacao: MatTableDataSource<Movimentacao>;
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<Documento>(true, []);

  public displayedDocumentosColumns: string[] = ["select", "id", "arquivo", "numero", "tipoDocumento", "descricao", "datahora", "marcar"];
  public displayedAssinaturasColumns: string[] = ["assinador", "data"];
  public displayedAssinaturasIndicadorColumns: string[] = ["assinador", "dataIndicacao", "dataAssinatura", "excluir"];
  public displayedMovimentacoesColumns: string[] = ["localizacao", "situacao", "permanencia", "datahora"];
  private certificate: Certificate;

  constructor(
    public processoService: ProcessoService,
    public responsavelService: ResponsavelService,
    public contribuinteService: ContribuinteService,
    public assuntoService: AssuntoService,
    public documentoService: DocumentoService,
    public assinaturaServce: AssinaturaService,
    public messageService: MessageService,
    public movimentacaoService: MovimentacaoService,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // TODO : MOCK
    this.processoService.getByEtiqueta("00015371/2019").subscribe((processo) => {
      this.processo = processo;
      // TODO verificar uso mocado dessa informação possível remoção
      this.contribuinteService.get(2222222222).subscribe((responsavel) => {
        this.responsavel = responsavel[0];
        this.titleDocumento = `DOCUMENTOS DO PROCESSO Nº ${processo.numero} - ${this.responsavel.nome}`;
        this.titleMovimentacao = `MOVIMENTAÇÃO DO PROCESSO Nº ${processo.numero} - ${this.responsavel.nome}`;
      });

      this.dataSourceMovimentacao = new MatTableDataSource<Movimentacao>(processo.listaMovimentacao);
      this.dataSourceMovimentacao.paginator = this.paginator;

      this.assuntoService.get(processo.assunto_id).subscribe(
        (assunto) => {
          this.assunto = assunto;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );

      this.dataSource = new MatTableDataSource<Documento>(processo.listaAnexoDocumento);
      this.dataSource.paginator = this.paginator;
      this.titleProcesso = `PROCESSO Nº ${processo.numero}`;
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Documento): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  getDocumento(row: any) {
    this.documentoService.get(row.documento.id).subscribe(
      (documento) => {
        this.documento = documento;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assinaturaServce.getAssinaturaByDocumento(row.documento.id).subscribe(
      (assinaturas) => {
        this.dataSourceAssinatura = new MatTableDataSource<Assinatura>(assinaturas);
        this.dataSourceAssinatura.paginator = this.paginator;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assinaturaServce.getAssinaturaIndicadoByDocumento(row.documento.id).subscribe(
      (assinaturas) => {
        this.dataSourceAssinaturaIndicado = new MatTableDataSource<Assinatura>(assinaturas);
        this.dataSourceAssinaturaIndicado.paginator = this.paginator;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.selection.toggle(row);
  }

  getMovimentacao(row: any) {
    this.movimentacaoService.get(row.movimentacao.id).subscribe((movimentacao) => (this.movimentacao = movimentacao));
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
  }

  assinarDocumento(): void {
    this.openDialogSelecionarCertificado();
  }

  openDialogSelecionarCertificado(): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate,
    });

    openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
      if (certParam != null && certParam.cert64 != null) {
        this.certificate = certParam;
        this.assinarMarcados();
      }
    });
  }

  private assinarMarcados() {
    // if (this.selection.selected.length === 0) {
    //   this.messageService.messageWarning("Nenhum documento selecionado!");
    //   return;
    // }

    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
    const formData: FormData = new FormData();
    formData.append("certContent", this.certificate.cert64);
    formData.append("documento", this.documento.docAutor); //TO DO - PORUCAR O CERTIFICADO DE QUEM TA LOGADO

    let countDocumentosAtualizados = 0;

    this.documentoService.startWithIdDocumento(this.documento.id, formData, SharedService.CARREGAR_LOADING).subscribe(
      (sigParams) => {
        this.pki
          .signHash({
            thumbprint: cert.thumbprint,
            hash: sigParams.toSignHash,
            digestAlgorithm: sigParams.digestAlgorithm,
          })
          .success((signature) => {
            this.documentoService
              .completeWithIdDocumento(
                this.documento.id,
                sigParams.fileName,
                sigParams.transferFileId,
                signature,
                sigParams.descricaoAssinatura,

                SharedService.CARREGAR_LOADING
              )
              .subscribe(
                (documentoAssinado) => {
                  countDocumentosAtualizados++;
                  if (countDocumentosAtualizados === this.selection.selected.length) {
                    // this.natureza = null;
                    // this.listarDocumentosProcesso(this.processo);
                  }
                  this.messageService.messageSuccess("Documento assinado com sucesso!");
                },
                (error) => {
                  this.messageService.messageErro(error.error.message);
                  throw error;
                }
              );
          });
      },
      (msgExcecao) => {
        countDocumentosAtualizados++;
        if (msgExcecao.error != null && msgExcecao.error.message != null) {
          this.messageService.messageErro(msgExcecao.error.message);
          throw msgExcecao;
        } else {
          this.messageService.messageErro("Erro na assinatura do arquivo.");
        }
      }
    );
  }
}
