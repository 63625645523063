import { HttpErrorResponse } from "@angular/common/http";

export class Utils {
  public static timeBetweenDates(dataFim: Date, dataInicio: Date): string {
    let milissengundos = Math.abs((dataFim.getTime() - dataInicio.getTime()));
    const allHours = Math.floor((milissengundos / (1000 * 60 * 60)));
    const days = Math.floor((allHours / (24)));
    const hours = allHours - (days * 24);

    milissengundos = milissengundos - (allHours * 60 * 60 * 1000);
    const minutes = Math.floor((milissengundos / (60 * 1000)));
    milissengundos = milissengundos - (minutes * 60 * 1000);
    const seconds = Math.floor((milissengundos / 1000));

    let strReturn = "";

    if (days > 0) {
      strReturn = strReturn + days + "d";
    }
    if (hours > 0) {
      if (hours < 10) {
        strReturn = strReturn + " 0" + hours + "h";
      } else {
        strReturn = strReturn + hours + "h";
      }
    }
    if (minutes > 0) {
      if (minutes < 10) {
        strReturn = strReturn + " 0" + minutes + "m";
      } else {
        strReturn = strReturn + " " + minutes + "m";
      }
    }

    if (seconds > 0) {
      if (seconds < 10) {
        strReturn = strReturn + " 0" + seconds + "s";
      } else {
        strReturn = strReturn + " " + seconds + "s";
      }
    }

    if (strReturn === "") {
      strReturn = "0s";
    }

    return strReturn;
  }

  public static getUrlBlob(blob): any {
    return window.URL.createObjectURL(blob);
  }

  public static downloadBlob(blob: any, fileName?): boolean {
    if (blob == null || blob.size <= 4 || blob === undefined || blob === {}) {
      return false;
    }
    const url = this.getUrlBlob(blob);

    if (fileName) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = fileName;
      a.click();

      return true;
    }

    const pwa = window.open(url);
    // tslint:disable-next-line: triple-equals
    if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
      console.error("popup blocked.");
      alert("Desabilite o bloqueio de pop-up de seu navegador.");
    }

    return true;
  }

  // dd/MM/yyyy
  public static formatDate(data: Date) {
    const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;
    return "" + dd + "-" + mm + "-" + y;
  }

  // MM/dd/yyyy
  public static formatDateMesDiaAno(data: Date) {
     const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
     const dd = d < 10 ? "0" + d : d;
     return "" + mm + "-" + dd + "-" + y;
  }
  public static ordernarDescricaoOrdemAlfabetica(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.descricao > obj2.descricao) {
        return 1;
      }
      if (obj1.descricao < obj2.descricao) {
        return -1;
      }
      return 0;
    });
  }

  public static ordemAlfabeticaDescricoesSemCaseSensitive(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.descricao.toUpperCase() > obj2.descricao.toUpperCase()) {
        return 1;
      }
      if (obj1.descricao.toUpperCase() < obj2.descricao.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  }

  public static ordernarNomeOrdemAlfabetica(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.nome > obj2.nome) {
        return 1;
      }
      if (obj1.nome < obj2.nome) {
        return -1;
      }
      return 0;
    });
  }

  public static ordernarNomeDocumentoOrdemAlfabetica(list:any[]): any {
    return list.sort((a,b) => a.nomeDocumento.toUpperCase() > b.nomeDocumento.toUpperCase() ? 1 : -1);
  }

  public static converterArrayBufferParaJSON(error: any) {
    let clonedError = { ...error };
    if (error.error instanceof ArrayBuffer) {
      const decodedString = new TextDecoder("utf-8").decode(new Uint8Array(error.error));
      clonedError.error = JSON.parse(decodedString);
    }
    return new HttpErrorResponse(clonedError);
  }

  public static getUfs() {
    return ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS', 'MG','PA','PB','PR','PE','PI','RJ', 'RN', 'RS', 'RO','RR','SC','SP','SE','TO'];
  }

  public static rolarParaAncora(idAncora: string){
    setTimeout(() => {
      document.getElementById(idAncora).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 500);
  }
}