import { Domain } from "../../global/models/domain.model";

export class Situacao extends Domain {
  id: number;
  descricao: string;
  ativo: boolean;
  somenteStatus: boolean;
  situacaoFinal: boolean;
  panelAberto: boolean;
  pendenciaAutor: boolean;
  situacaoBloqueio: boolean;
  qtdRegistros: number;
}