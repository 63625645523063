import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CardProps } from "src/app/ui/components/templates/card-list-template/card-list-template.component";

@Component({
  selector: "app-cadastro-home",
  templateUrl: "./cadastro-home.component.html",
  styleUrls: ["./cadastro-home.component.css"]
})
export class CadastroHomeComponent implements OnInit {
  public cruds: CardProps[] = [
    { label: "Assunto", icon: "subject", click: ()=> this.router.navigateByUrl("/sgeap/assunto" ) },
    { label: "Assunto Categoria", icon: "checklist", click: ()=> this.router.navigateByUrl("/sgeap/assunto-categoria" ) },
    { label: "Fase", icon: "linear_scale", click: ()=> this.router.navigateByUrl("/sgeap/fase" ) },
    { label: "Metadado", icon: "code", click: ()=> this.router.navigateByUrl("/sgeap/metadado" ) },
    { label: "Situação", icon: "flag", click: ()=> this.router.navigateByUrl("/sgeap/situacao" ) },
    { label: "Tipo Documento", icon: "description", click: ()=> this.router.navigateByUrl("/sgeap/tipo-documento" ) },
    { label: "Modelo", icon: "book", click: ()=> this.router.navigateByUrl("/sgeap/modelo" ) },
    { label: "Grupo", icon: "groups", click: ()=> this.router.navigateByUrl("/sgeap/grupo" ) },
    { label: "E-mail Template", icon: "email", click: ()=> this.router.navigateByUrl("/sgeap/email-template" ) },
    { label: "Gerenciamento de Distribuição", icon: "move_up", click: ()=> this.router.navigateByUrl("/sgeap/fiscal-preferencia" ) },
    { label: "Lotação Secundária", icon: "business", click: ()=> this.router.navigateByUrl("/sgeap/lotacao-secundaria" ) },
    { label: "Órgão", icon: "apartment", click: ()=> this.router.navigateByUrl("/sgeap/orgao" ) },
    { label: "Aviso", icon: "warning", click: ()=> this.router.navigateByUrl("/sgeap/aviso" ) },
    { label: "Documentos Motor Regras", icon: "find_in_page", click: ()=> this.router.navigateByUrl("/sgeap/documento-motor-regras" ) },
  ];
  constructor(
    public router: Router
  ) {
  }

  ngOnInit(): void {}

}
