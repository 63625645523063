export enum Tela {
  LOGIN = ("Tela de Login."),
  PRATELEIRA = ("Tela da Prateleira de Processos."),
  ABERTURA = ("Tela de Abertura de Processos."),
  SERVICOS = ("Tela de Menu de Serviços.")
}

export const TelaEnumAll = [
  { id: 0, descricao: Tela.LOGIN },
  { id: 1, descricao: Tela.PRATELEIRA },
  { id: 2, descricao: Tela.ABERTURA },
  { id: 3, descricao: Tela.SERVICOS }
];
