
import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { AppConfig, APP_CONFIG } from "src/app.config";


@Injectable({ providedIn: "root" })
export class PublicacaoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "publicacao", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  public buscarPublicacoesPorProcessoId(processoId: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/buscar-por-viproc/${processoId}`).pipe((response => response));
  }

}