export enum TipoDocumentoSistema {
  OUTROS = 2,
  REQUERIMENTO_PENSAO_POR_MORTE = 6,
  NÃO_CONHECIMENTO_DE_OUTROS_DEPENDENTES = 7,
  INEXISTENCIA_RELACIONAMENTO_ATUAL = 8,
  INEXISTENCIA_RELACIONAMENTO_ATUAL_CONJUGE_COMPANHEIRO = 13,
  NAO_ACUMULACAO = 9,
  NAO_ACUMULACAO_CONJUGE_COMPANHEIRO = 12,
  ACUMULACAO = 10,
  ACUMULACAO_COMPANHEIRO_OU_CONJUGE = 11,
}

export const TipoDocumentoSistemaEnumAll = [
  { id: "outros", descricao: TipoDocumentoSistema.OUTROS },
  { id: "tp-doc-requerimento-pensao", descricao: TipoDocumentoSistema.REQUERIMENTO_PENSAO_POR_MORTE },
  { id: "tp-doc-requerimento-pensao-demais-requerentes", descricao: TipoDocumentoSistema.REQUERIMENTO_PENSAO_POR_MORTE },
  { id: "tp-doc-declaracao-desconhece-outros-dependentes", descricao: TipoDocumentoSistema.NÃO_CONHECIMENTO_DE_OUTROS_DEPENDENTES },
  { id: "tp-doc-declaracao-vinculo-relacionamento", descricao: TipoDocumentoSistema.INEXISTENCIA_RELACIONAMENTO_ATUAL },
  {
    id: "tp-doc-declaracao-vinculo-relacionamento-conjuge-companheiro",
    descricao: TipoDocumentoSistema.INEXISTENCIA_RELACIONAMENTO_ATUAL_CONJUGE_COMPANHEIRO,
  },
  { id: "tp-doc-declaracao-nao-vinculo-outro-regime", descricao: TipoDocumentoSistema.NAO_ACUMULACAO },
  {
    id: "tp-doc-declaracao-nao-vinculo-outro-regime-conjuge-companheiro",
    descricao: TipoDocumentoSistema.NAO_ACUMULACAO_CONJUGE_COMPANHEIRO,
  },
  { id: "tp-doc-declaracao-vinculo-outro-regime", descricao: TipoDocumentoSistema.ACUMULACAO },
  { id: "tp-doc-declaracao-vinculo-outro-regime-conjuge-companheiro", descricao: TipoDocumentoSistema.ACUMULACAO_COMPANHEIRO_OU_CONJUGE },
];

export namespace TipoDocumentoSistemaFunctions {
  export function getDescricaoPorId(idTipoDocumentoSistema: string) {
    return TipoDocumentoSistemaEnumAll.filter(function (TipoDocumentoSistema) {
      return TipoDocumentoSistema.id.toUpperCase() === idTipoDocumentoSistema.toUpperCase();
    })[0].descricao;
  }
}
