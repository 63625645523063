import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IncluirDocumentoComponent } from "./incluir-documento/incluir-documento.component";
import { MaterialModule } from "../../material";
import { RouterModule, Routes } from "@angular/router";
import { ResponsavelService } from "../api/responsavel.service";
import { TipoDocumentoService } from "../api/tipo-documento.service";
import { ProcessoService } from "../api/processo.service";
import { LocalizarUsuariosComponent } from "./localizar-usuarios/localizar-usuarios.component";
import { CKEditorModule } from "ng2-ckeditor";
import { LoadingScreenService } from "src/app/global/api/loading-screen";


const routes: Routes = [
  { path: "incluir-documento/:id", component: IncluirDocumentoComponent}
];

@NgModule({
  declarations: [IncluirDocumentoComponent, LocalizarUsuariosComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CKEditorModule,
    RouterModule.forChild(routes)
  ],
  providers: [ResponsavelService,
    TipoDocumentoService, ProcessoService,LoadingScreenService],
})
export class DocumentoModule { }
