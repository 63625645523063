import { Documento } from "./documento.model";
import { Domain } from "../../global/models/domain.model";
import { Fase } from "./fase.model";
import { Situacao } from "./situacao.model";
import { Movimentacao } from "./movimentacao.model";
import { MetadadoValor } from "./metadado-valor.model";
import { SolicitanteDTO } from "./solicitante-dto.model";
import { Responsavel } from "./responsavel.model";
import { ProcesosDocumento } from "./processo-documento";
import { ItemNaoSimilaridade } from "./item-nao-similaridade.model";
import { OrgaoLocal } from "./orgao-local.model";
import { Pessoa } from "./pessoa.model";

export class Processo extends Domain {
  public assunto: any;
  public fase: Fase;
  public situacaoAtual: Situacao;
  public lotacaoInicial: string;
  public modeloAssunto: string;
  public descricao: string;
  public numero: number;
  public etiquetaProcessoViproc: string;
  public solicitacaoCadastro: string;
  public dataCriacao: Date;
  public solicitante: SolicitanteDTO;
  public autores: number[];
  public observacoes: string;
  public documentos: Documento[];
  public listMetadadoValor: MetadadoValor[];
  public listaAnexoDocumento: Documento[];
  public listMovimentacao: Movimentacao[];
  public tempoTranscorrido: string;
  public tempoTranscorridoExterno: string;
  public tempoTranscorridoInterno: string;
  public nomeResponsavel: string;
  public autorMovimentacao: Responsavel;
  public faseAtual: Fase;
  public solicitanteProcesso: Responsavel;
  public responsavelAtual: string;
  public autor: Responsavel;
  public representante: Responsavel;
  public listDocumento: ProcesosDocumento[];
  public ultimaMovimentacao: Movimentacao;
  public observando: boolean;
  public listItemNaoSimilaridade: ItemNaoSimilaridade[];
  public qtdItensNaoSimilaridade: number;
  public nivelSigilo: number;
  public lotacaoAtual: number;
  public orgaoLocalResponsavel: OrgaoLocal;
  public pessoaReceberProcesso: Pessoa;
  public vinculado: boolean;

  // Transients
  public lotacaoCexatNuat: number;
  selected: unknown;
}
