import { Component, OnInit, OnChanges } from "@angular/core";
import { Metadado } from "../../../../global/models/metadado.model";
import { SalvarGenericsComponent } from "../../../../global/generics/salvar-generics-component";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MetadadoService } from "../../../api/metadado.service";
import { ToastrService } from "ngx-toastr";
import { MessageService } from "../../../../global/message/message.service";
import { TipoMetadadoEnum, TipoMetadadoEnumAll } from "../../../models/tipo-metadado.enum";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-salvar-metadado",
  templateUrl: "./salvar-metadado.component.html",
  styleUrls: ["./salvar-metadado.component.css"],
})
export class SalvarMetadadoComponent extends SalvarGenericsComponent<Metadado> implements OnInit, OnChanges {
  public isRequerido = false;
  public tipoMetadadoEnum = TipoMetadadoEnumAll.filter((metadado) => metadado.id !== 3);
  public exibirCampoMascara = true;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: MetadadoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(formBuilder, router, apiService, messageService, route, "metadado", "");
  }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  public ngOnInit() {
    const domain: Metadado = this.domain;
  }

  public ngOnChanges(domain: any): void {}

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.titulo || !domain.titulo.trim()) {
      this.messageService.messageErro("Preencha o campo Título.");
      validacao = false;
    }
    if (!domain.nome || !domain.nome.trim()) {
      this.messageService.messageErro("Preencha o campo Nome.");
      validacao = false;
    }
    if (!domain.mascara && domain.tipo !== TipoMetadadoEnum.MONETARIO) {
      this.messageService.messageErro("Preencha o campo Máscara.");
      validacao = false;
    }
    if (domain.tipo == null) {
      this.messageService.messageErro("Selecione o Tipo.");
      validacao = false;
    }
    if (domain.validarQuantidadeCaracteres == null) {
      this.messageService.messageErro("Selecione o campo Validar Preenchimento Completo.");
      validacao = false;
    }
    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    return validacao;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  public selecionarTipo(event: any) {
    this.domain.mascara = "";

    if (this.domain.tipo === TipoMetadadoEnum.DATA) {
      this.domain.mascara = "00/00/0000";
      this.exibirCampoMascara = false;
    } else if (this.domain.tipo === TipoMetadadoEnum.MONETARIO) {
      this.exibirCampoMascara = false;
    } else if (this.domain.tipo === TipoMetadadoEnum.BOTAO) {
      this.exibirCampoMascara = false;
    } else {
      this.exibirCampoMascara = true;
    }
  }

  public validarMascara() {
    if (this.domain.tipo === TipoMetadadoEnum.LETRA_NUMERO) {
      this.domain.mascara = this.domain.mascara
        .replace(/[0-9]/g, "0")
        .replace(/[a-rt-zA-RT-Z]/g, "A")
        .replace(/[s]/g, "S")
        .replace(/\s|[^a-zA-Z0-9\-/.]+/g, "");
    } else if (this.domain.tipo === TipoMetadadoEnum.APENAS_NUMERO) {
      this.domain.mascara = this.domain.mascara
        .replace(/[a-zA-Z]/g, "")
        .replace(/[0-9]/g, "0")
        .replace(/\s|[^a-zA-Z0-9]+/g, "");
    } else if (this.domain.tipo === TipoMetadadoEnum.DATA) {
      this.domain.mascara = this.domain.mascara
        .replace(/[a-zA-Z]/g, "")
        .replace(/[0-9]/g, "0")
        .replace(/\s|[^a-zA-Z0-9\/]+/g, "");
    } else if (this.domain.tipo === TipoMetadadoEnum.APENAS_LETRAS) {
      this.domain.mascara = this.domain.mascara
        .replace(/[0-9]/g, "")
        .replace(/[a-zA-Z]/g, "S")
        .replace(/\s|[^a-zA-Z0-9]+/g, "");
    } else {
      this.domain.mascara = "";
      this.messageService.messageWarning("Primeiro selecione o Tipo do campo");
    }
  }
}
