import { Component, Input, OnInit } from '@angular/core';

export interface CardProps {
  label: string;
  icon: string;
  click: () => any | Promise<any>;
}

@Component({
  selector: 'app-card-list-template',
  templateUrl: './card-list-template.component.html',
  styleUrls: ['./card-list-template.component.css']
})
export class CardListTemplateComponent implements OnInit {

  @Input() data: CardProps[];

  constructor() { }

  ngOnInit() {
  }

}
