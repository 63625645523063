import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CidadaoService } from 'src/app/core/api/seguranca/cidadao.service';
import { PerfilService } from 'src/app/core/api/seguranca/perfil.service';
import { SisSegService } from 'src/app/core/api/sis-seg.service';
import { Cidadao } from 'src/app/core/models/seguranca/cidadao.model';
import { Perfil } from 'src/app/core/models/seguranca/perfil.model';
import { TipoUsuario, TipoUsuarioEnumAll } from 'src/app/core/models/seguranca/tipo-usuario.enum';
import { SalvarGenericsComponent } from 'src/app/global/generics/salvar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';

@Component({
  selector: 'sgeap-completar-cadastro',
  templateUrl: './completar-cadastro.component.html',
  styleUrls: ['./completar-cadastro.component.css']
})
export class CompletarCadastroComponent extends SalvarGenericsComponent<Cidadao> implements OnInit {

  perfilList: Perfil[];
  tipoUsuarioList = TipoUsuarioEnumAll;
  confirmacaoSenha: string;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: CidadaoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService,
    private perfilService: PerfilService
  ) {
    super(formBuilder, router, apiService, messageService, route, "cidadao",
      [{ descricao: ['', Validators.required] },
      { codigoIdentificacao: ['', Validators.required] }]);
  }

  ngOnInit(): void {
    this.perfilService.list().subscribe(
      res => {
        this.perfilList = res;
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    )
  }

  salvarValidar(domain: Cidadao): void {
    if (this.validar(domain)) {
      this.submeter();
    }
  }

  private validar(domain: Cidadao): boolean {
    let validacao =
      this.valida(domain.nome, "Nome") &&
      this.valida(domain.cpf, "CPF") &&
      this.valida(domain.email, "E-mail");

    // se for novo cadastro
    if (!domain.id) {
      validacao = validacao &&
        this.valida(domain.senha, "Senha") &&
        this.valida(this.confirmacaoSenha, "Confirmar Senha");
      if (domain.senha != this.confirmacaoSenha) {
        validacao = false;
        this.messageService.messageErro("As senhas digitadas devem ser iguais");
      }
    }

    return validacao;
  }

  get usuarioInterno(): boolean { return this.domain.tipoUsuario == Object.values(TipoUsuario).indexOf(TipoUsuario.INTERNO) }

  compare(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  comparePerfil(p1: Perfil, p2: Perfil): boolean {
    return p1.id == p2.id;
  }

  valida(valor: any, campo: string) {
    let validacao = true;
    if (!valor) {
      this.messageService.messageErro(`Preencha o campo ${campo}.`);
      validacao = false;
    }
    return validacao;
  }

}
