export enum TipoRepresentante {
  REPRESENTANTE = "Representante",
  CURADOR = "Curador",
  TUTOR = "Tutor",
  PROCURADOR = "Procurador",
  RESPONSAVEL = "Responsável Legal",
  MAE = "Mãe",
  PAI = "Pai",
  GUARDA = "Guarda Judicial",
}

export const TipoRepresentanteEnumAll = [
  { id: "representante", descricao: TipoRepresentante.REPRESENTANTE, enum: "REPRESENTANTE" },
  { id: "curador", descricao: TipoRepresentante.CURADOR, enum: "CURADOR" },
  { id: "tutor", descricao: TipoRepresentante.TUTOR, enum: "TUTOR" },
  { id: "procurador", descricao: TipoRepresentante.PROCURADOR, enum: "PROCURADOR" },
  { id: "responsavel", descricao: TipoRepresentante.RESPONSAVEL, enum: "RESPONSAVEL" },
  { id: "mae", descricao: TipoRepresentante.MAE, enum: "MAE" },
  { id: "pai", descricao: TipoRepresentante.PAI, enum: "PAI" },
  { id: "guarda", descricao: TipoRepresentante.GUARDA, enum: "GUARDA" },
];
export namespace TipoRepresentanteFunctions {
  export function getDescricaoPorId(idTipoRepresentante: string) {
    return TipoRepresentanteEnumAll.filter(function (tipo) {
      return tipo.id.toUpperCase() === idTipoRepresentante.toUpperCase();
    })[0].descricao;
  }

  export function getEnumPorId(idTipoRepresentante: string) {
    return TipoRepresentanteEnumAll.filter(function (tipo) {
      return tipo.id.toUpperCase() === idTipoRepresentante.toUpperCase();
    })[0].enum;
  }
}
