import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class ViprocService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "viproc", config);
    this.API_URL = config.API_VIPROC_URL;
  }

  listarAssuntoViproc(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/listar-assuntos-viproc`).pipe(map(response => response));
  }

  public enviarProcessoParaViproc(
    autor: string,
    etiqueta: string,
    ano: string,
    orgaoOrigem: string,
    usuario: string,
    senha: string
  ) {
    return this.http
      .post(`${this.API_URL}${this.resource}/set-processo-viproc/${autor}/${etiqueta}/${ano}/${orgaoOrigem}/${usuario}/${senha}`, autor)
      .pipe(map((response) => response));
  }

}