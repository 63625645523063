import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig, APP_CONFIG } from "src/app.config";


export class ApiService {
  public API_URL: string;
  protected resource: string;

  constructor(protected http: HttpClient, resource: string, @Inject(APP_CONFIG) public config: AppConfig) {
    this.resource = resource;
    this.API_URL = config.API_CADASTRO_URL;
  }

  public list(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}`).pipe(map(response => response));
  }

  public listPaginacao(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/paginacao`).pipe(map(response => response));
  }

  public get(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/${id}`).pipe(map(response => response));
  }

  public post(body?: { [index: string]: any }): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}`, body).pipe(map(response => response));
  }

  public put(body?: { [index: string]: any }): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/${body.id}`, body).pipe(map(response => response));
  }

  public putJsonServerTemporario(body?: { [index: string]: any }): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/${body.id}`, body).pipe(map(response => response));
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.API_URL}${this.resource}/${id}`).pipe(map(response => response));
  }

  public pesquisar(body?: { [index: string]: any }): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/pesquisar`, body).pipe(map(response => response));
  }
}
