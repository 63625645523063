import { Component, OnInit } from "@angular/core";
import { Contribuinte } from "../../models/contribuinte.model";
import { ContribuinteService } from "../../api/contribuinte.service";
import { MessageService } from "src/app/global/message/message.service";

@Component({
  selector: "app-localizar-usuarios",
  templateUrl: "./localizar-usuarios.component.html",
  styleUrls: ["./localizar-usuarios.component.css"]
})
export class LocalizarUsuariosComponent implements OnInit {

  public contribuintes: Contribuinte[] = [];
  public displayedProcessosColumns: string[] = ["matricula", "nome", "favorito"];

  constructor(public contribuinteService: ContribuinteService,
    private messageService: MessageService) { }

  ngOnInit() {
  }


  confirmar() {
    this.contribuinteService.list().subscribe(
      contribuintes => {
        this.contribuintes = contribuintes;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
