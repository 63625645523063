import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDatepickerInputEvent, MatDialog, MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { SharedService } from "src/app/global/api/shared.service";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import LacunaWebPKI from "web-pki";
import { environment } from "../../../../environments/environment";
import { Certificate } from "../../../global/models/certificate.model";
import { AssuntoService } from "../../api/assunto.service";
import { AvisoService } from "../../api/aviso.service";
import { DocumentoService } from "../../api/documento.service";
import { FaseService } from "../../api/fase.service";
import { ProcessoService } from "../../api/processo.service";
import { ResponsavelService } from "../../api/responsavel.service";
import { SisSegService } from "../../api/sis-seg.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { SituacaoService } from "../../api/situacao.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { AgrupamentoProcessoDTO } from "../../models/agrupamento-processo-dto.model";
import { Fase } from "../../models/fase.model";
import { ProcessoDTO } from "../../models/processo-dto.model";
import { ProcessoPequisa } from "../../models/processo-pesquisa.model";
import { Processo } from "../../models/processo.model";
import { Tela, TelaEnumAll } from "../../models/tela.enum";
import { TipoDocumentoDTO } from "../../models/tipo-documento-dto.model";
import { TipoExibicao, TipoExibicaooEnumAll } from "../../models/tipo-exibicao.enum";
import { DialogAvisoComponent } from "../dialog-aviso/dialog-aviso.component";
import { DialogSelecionarCertificadoComponent } from "../dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { ProcessoTabelaDto } from "../../models/processo-tabela-dto.model";

@Component({
  selector: "app-listar-processo-tabela",
  templateUrl: "./listar-processo-tabela.component.html",
  styleUrls: ["./listar-processo-tabela.component.css"],
})
export class ListarProcessoTabelaComponent implements OnInit {
  public tabelaProcessoPrateleiraAcompanhados = "tabela-processo-prateleira-acompanhados";

  public dataSourceProcessoSetor: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoSetor") paginatorProcessoSetor: MatPaginator;
  public resultsLengthProcessoSetor = 0;
  public isLoadingResultsProcessoSetor = true;
  public isRateLimitReachedProcessoSetor = false;

  public dataSourceProcessoFase: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoFase") paginatorProcessoFase: MatPaginator;
  public resultsLengthProcessoFase = 0;
  public isLoadingResultsProcessoFase = true;
  public isRateLimitReachedProcessoFase = false;

  public dataSourceProcessoAssunto: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoAssunto") paginatorProcessoAssunto: MatPaginator;
  public resultsLengthProcessoAssunto = 0;
  public isLoadingResultsProcessoAssunto = true;
  public isRateLimitReachedProcessoAssunto = false;

  public dataSourceProcessoSituacao: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoSituacao") paginatorProcessoSituacao: MatPaginator;
  public resultsLengthProcessoSituacao = 0;
  public isLoadingResultsProcessoSituacao = true;
  public isRateLimitReachedProcessoSituacao = false;

  public dataSourceProcessoNaoLidoSetorPeriodo: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoNaoLidoSetorPeriodo") paginatorProcessoNaoLidoSetorPeriodo: MatPaginator;
  public resultsLengthProcessoNaoLidoSetorPeriodo = 0;
  public isLoadingResultsProcessoNaoLidoSetorPeriodo = true;
  public isRateLimitReachedProcessoNaoLidoSetorPeriodo = false;

  public dataSourceProcessoAguardandoAssinatura: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoAguardandoAssinatura") paginatorProcessoAguardandoAssinatura: MatPaginator;
  public resultsLengthProcessoAguardandoAssinatura = 0;
  public isLoadingResultsProcessoAguardandoAssinatura = true;
  public isRateLimitReachedProcessoAguardandoAssinatura = false;

  public dataSourceProcessoAguardandoAssinaturaTipoDocumento: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoAguardandoAssinaturaTipoDocumento") paginatorProcessoAguardandoAssinaturaTipoDocumento: MatPaginator;
  public resultsLengthProcessoAguardandoAssinaturaTipoDocumento = 0;
  public isLoadingResultsProcessoAguardandoAssinaturaTipoDocumento = true;
  public isRateLimitReachedProcessoAguardandoAssinaturaTipoDocumento = false;

  public dataSourceProcessoDocumentoAssinadoPeriodo: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoDocumentoAssinadoPeriodo") paginatorProcessoDocumentoAssinadoPeriodo: MatPaginator;
  public resultsLengthProcessoDocumentoAssinadoPeriodo = 0;

  @ViewChild("paginatorProcessoSemAndamento") paginatorProcessoSemAndamento: MatPaginator;
  public resultsLengthProcessoSemAndamento = 0;
  public dataSourceProcessoQtdDias: MatTableDataSource<ProcessoTabelaDto>;

  public dataSourceProcessoSobResponsabilidade: MatTableDataSource<ProcessoTabelaDto>;

  public dataSourceProcessoResponsavel: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoResponsavel") paginatorProcessoResponsavel: MatPaginator;
  public resultsLengthProcessoResponsavel = 0;
  public isLoadingResultsProcessoResponsavel = true;
  public isRateLimitReachedProcessoResponsavel = false;

  public dataSourceProcessoSetorSemResponsabilidade: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoSetor") paginatorProcessoSetorSemResponsabilidade: MatPaginator;
  public resultsLengthProcessoSetorSemResponsabilidade = 0;
  public isLoadingResultsProcessoSetorSemResponsabilidade = true;
  public isRateLimitReachedProcessoSetorSemResponsabilidade = false;

  public dataSourceProcessoObservado: MatTableDataSource<ProcessoTabelaDto>;
  @ViewChild("paginatorProcessoObservado") paginatorProcessoObservado: MatPaginator;
  public resultsLengthProcessoObservado = 0;

  public selection = new SelectionModel<ProcessoTabelaDto>(true, []);
  public setores: AgrupamentoProcessoDTO[] = [];
  public lotacoesProcessoQtdDias: AgrupamentoProcessoDTO[] = [];
  public fases: AgrupamentoProcessoDTO[] = [];
  public assuntos: AgrupamentoProcessoDTO[] = [];
  public situacoes: AgrupamentoProcessoDTO[] = [];
  public periodos: AgrupamentoProcessoDTO[] = [];
  public responsaveisProcesso: AgrupamentoProcessoDTO[];
  public setoresSemResponsabilidade: AgrupamentoProcessoDTO[] = [];
  public tiposDocumento: AgrupamentoProcessoDTO[] = [];
  public listaProcessoTipoDocumento: ProcessoDTO[] = [];
  public highlightedDocumentRows: any[] = [];
  public form: FormGroup;
  public formDate: FormGroup;
  public responsavel: any;
  public step = 0;
  public abrirAccordion: boolean;
  public responsaveis: any[] = [];
  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public PAGE_INICITAL = 0;
  public documentoAstra: string;
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  private certificate: Certificate;

  public dataNaoLidoInput: string;
  public dataIniciaNaoLidoTmp: Date;
  public dataFimNaoLidoTmp: Date;
  public dataIniciaNaoLido: Date;
  public dataFimNaoLido: Date;

  public stepTipoDocumento: any;

  public dataDocAssinadoInput: string;
  public dataIniciaDocAssinadoTmp: Date;
  public dataFimDocAssinadoTmp: Date;
  public dataInicioDocAssinado: Date;
  public dataFimDocAssinado: Date;

  public qtdDiasSemAndamento: number;
  public idTipoDocumentoSelecionado: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public displayedProcessosColumns: string[] = [
    "id",
    "numero",
    "informacoes",
    "autor",
    "fase",
    "situacao",
    "responsavel",
    "origem",
    "entrada",
    "relacionamentos",
    "dataCriacao",
  ];
  public displayedProcessosTipoDocumentoAguardandoAssinaturaColumns: string[] = [
    "select",
    "id",
    "numero",
    "informacoes",
    "autor",
    "origem",
    "Entrada",
    "relacionamentos",
    "dataCriacao",
  ];
  public resourceConsultarProcesso = environment.CONTEXTO + "/consultar-processo";

  public processo: Processo;
  public selectionProcesso: SelectionModel<ProcessoTabelaDto>;

  constructor(
    protected route: ActivatedRoute,
    public router: Router,
    public processoService: ProcessoService,
    public faseService: FaseService,
    public assuntoService: AssuntoService,
    public situacaoService: SituacaoService,
    public SisSegService: SisSegService,
    public documentoService: DocumentoService,
    public tipoDocumentoService: TipoDocumentoService,
    public messageService: MessageService,
    public responsavelService: ResponsavelService,
    public avisoService: AvisoService,
    public contribuinteService: ContribuinteService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.certificate = new Certificate();
    this.dataSourceProcessoSetor = new MatTableDataSource<ProcessoTabelaDto>([]);
    this.dataSourceProcessoSetorSemResponsabilidade = new MatTableDataSource<ProcessoTabelaDto>([]);
    this.dataSourceProcessoFase = new MatTableDataSource<ProcessoTabelaDto>([]);
    this.dataSourceProcessoAssunto = new MatTableDataSource<ProcessoTabelaDto>([]);
    this.dataSourceProcessoSituacao = new MatTableDataSource<ProcessoTabelaDto>([]);
    this.dataSourceProcessoQtdDias = new MatTableDataSource<ProcessoTabelaDto>([]);
  }

  ngOnInit() {
    this.documentoAstra = this.sharedService.decodePayloadJWT().sub;

    this.form = new FormGroup({
      dataIniciaNaoLido: new FormControl(),
      dataFimNaoLido: new FormControl(),
    });

    this.formDate = new FormGroup({
      dataInicioDocAssinado: new FormControl(),
      dataFimDocAssinado: new FormControl(),
    });

    this.checkAvisos();
  }

  private checkAvisos() {
    const tela = TelaEnumAll.filter((telaAux) => telaAux.descricao === Tela.PRATELEIRA).map((telaAux) => telaAux.id)[0];
    const tipoExibicao = TipoExibicaooEnumAll.filter((te) => te.descricao === TipoExibicao.PUBLICO_INTERNO).map((te) => te.id)[0];

    this.avisoService.listarAvisoAtualPorTelaTipoExibicao(tela, tipoExibicao).subscribe(
      (res) => {
        if (res.length > 0) {
          const dialogRef = this.dialog.open(DialogAvisoComponent, {
            maxWidth: "600px",
            data: res,
          });
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private setDataInicioDocAssinadoInput(type: string, event: string) {
    this.dataDocAssinadoInput = event;
  }

  private setDataInicioDocAssinadoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataInicioDocAssinado = event.value;
  }

  private setDataInicioDocAssinadoBlur() {
    this.dataInicioDocAssinado = moment(this.dataDocAssinadoInput, "DD/MM/YYYY").toDate();
  }

  private setDataFimDocAssinadoInput(type: string, event: string) {
    const elementReference: HTMLElement = document.querySelector(".pesquisarDocAssinadosPeriodoDatePicker") as HTMLElement;

    if (event.split("/").join("").length === 8) {
      this.dataDocAssinadoInput = event;
      elementReference.focus();
    }
  }

  private setDataFimDocAssinadoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataFimDocAssinado = event.value;
  }

  private setDataFimDocAssinadoBlur() {
    this.dataFimDocAssinado = moment(this.dataDocAssinadoInput, "DD/MM/YYYY").toDate();
  }

  private setDataInicioNaoLidoInput(type: string, event: string) {
    this.dataNaoLidoInput = event;
  }

  private setDataInicioNaoLidoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataIniciaNaoLido = event.value;
  }

  private setDataInicioNaoLidoBlur() {
    this.dataIniciaNaoLido = moment(this.dataNaoLidoInput, "DD/MM/YYYY").toDate();
  }

  private setDataFimNaoLidoInput(type: string, event: string) {
    const elementReference: HTMLElement = document.querySelector(".pesquisarNaoLidosPeriodoDatePicker") as HTMLElement;

    if (event.split("/").join("").length === 8) {
      this.dataNaoLidoInput = event;
      elementReference.focus();
    }
  }

  private setDataFimNaoLidoChange(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dataFimNaoLido = event.value;
  }

  private setDataFimNaoLidoBlur() {
    this.dataFimNaoLido = moment(this.dataNaoLidoInput, "DD/MM/YYYY").toDate();
  }

  private getSituacoes() {
    if (this.situacoes.length === 0) {
      this.processoService.listAgrupamentoSituacaoProcesso().subscribe(
        (situacoes) => {
          this.situacoes = situacoes;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  private getAssuntos() {
    if (this.assuntos.length === 0) {
      this.processoService.listAgrupamentoAssuntoProcesso().subscribe(
        (assuntos) => {
          this.assuntos = assuntos;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  private getSetores() {
    if (this.setores.length === 0) {
      this.processoService.pesquisarSetoresComProcessoDoServidor().subscribe(
        (setores) => {
          this.setores = setores;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  private getFases() {
    if (this.fases.length === 0) {
      this.processoService.listAgrupamentoFaseProcesso().subscribe(
        (fases) => {
          this.fases = fases;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  public pageEventSetor(setor: number, event) {
    this.getProcessoPorSetor(setor, event.pageIndex, event.pageSize);
  }

  public getProcessoPorSetor(setor: number, page: number, size: number) {
    this.setores.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == setor) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.lotacaoAtual.push(setor);
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoSetor = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoSetor = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeSetores() {
    this.setores.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorSetor(setor: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.lotacaoAtual.push(setor);
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoSetor.pageIndex,
        this.paginatorProcessoSetor.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoSetor = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoSetor = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public pageEventFase(event, id: number) {
    this.getProcessoByFaseId(id, event.pageIndex, event.pageSize);
  }

  public getProcessoByFaseId(id: number, page: number, size: number) {
    this.fases.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == id) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.faseId = id;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoFase = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoFase = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeFases() {
    this.fases.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorFase(id: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.faseId = id;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoFase.pageIndex,
        this.paginatorProcessoFase.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoFase = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoFase = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public pageEventAssunto(event, id: number) {
    this.getProcessoByAssuntoId(id, event.pageIndex, event.pageSize);
  }

  public getProcessoByAssuntoId(id: number, page: number, size: number) {
    this.assuntos.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == id) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.assuntoId = id;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoAssunto.data = processos.content;
        this.resultsLengthProcessoAssunto = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeAssuntos() {
    this.assuntos.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorAssuntoId(id: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.assuntoId = id;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoAssunto.pageIndex,
        this.paginatorProcessoAssunto.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoAssunto.data = processos.content;
          this.resultsLengthProcessoAssunto = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public pageEventSituacao(event, id: number) {
    this.getProcessoBySituacaoId(id, event.pageIndex, event.pageSize);
  }

  public isExibirMensagemNaoEncontrado(agrupamento: AgrupamentoProcessoDTO[]) {
    return agrupamento == null || agrupamento.length == 0;
  }

  selectionProcessoChange(selectionProcesso: any[]) {
    this.selectionProcesso = new SelectionModel<ProcessoTabelaDto>(true, selectionProcesso);
  }

  public pageEventNaoLidosSetorPeriodo(event, inicio: Date, fim: Date) {
    this.getProcessoNaoLidosPeriodo(inicio, fim, event.pageIndex, event.pageSize);
  }

  public getProcessoBySituacaoId(id: number, page: number, size: number) {
    this.situacoes.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == id) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.situacaoId = id;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoSituacao.data = processos.content;
        this.resultsLengthProcessoSituacao = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeSituacoes() {
    this.situacoes.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorSituacaoId(id: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.situacaoId = id;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoSituacao.pageIndex,
        this.paginatorProcessoSituacao.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoSituacao.data = processos.content;
          this.resultsLengthProcessoSituacao = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public getProcessoNaoLidosPeriodo(inicio: Date, fim: Date, page: number, size: number) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.dataInicial = this.formatDate(inicio);
    processoPesquisa.dataFinal = this.formatDate(fim);
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoNaoLidoSetorPeriodo = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoNaoLidoSetorPeriodo = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  pesquisarComOrdenacaoPorNaoLidosPeriodo(inicio: Date, fim: Date, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.dataInicial = this.formatDate(inicio);
    processoPesquisa.dataFinal = this.formatDate(fim);
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoNaoLidoSetorPeriodo.pageIndex,
        this.paginatorProcessoNaoLidoSetorPeriodo.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoNaoLidoSetorPeriodo = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoNaoLidoSetorPeriodo = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  private getPeriodos(inicio: Date, fim: Date) {
    this.processoService.listAgrupamentoPeriodosProcesso(this.formatDate2(inicio), this.formatDate2(fim)).subscribe(
      (periodos) => {
        this.periodos = periodos;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public pageEventProcessoDocAssinadoPeriodo(event, inicio: string, documento: string) {
    this.getProcessoDocAssinadoPeriodo(inicio, documento, event.pageIndex, event.pageSize);
  }

  public getProcessoDocAssinadoPeriodo(dataInicioDocAssinado: string, documento: string, page: number, size: number) {
    this.periodos.forEach((element) => {
      if (element.panelAberto) {
        element.subAgrupamento.forEach((elementSub) => {
          elementSub.panelAberto = false;
          if (documento == elementSub.documento) {
            elementSub.panelAberto = true;
          }
        });
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.filtrarPorDocAssinados = true;
    processoPesquisa.dataInicial = dataInicioDocAssinado;
    processoPesquisa.dataFinal = dataInicioDocAssinado;
    processoPesquisa.documentoAssinador = documento;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoDocumentoAssinadoPeriodo = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoDocumentoAssinadoPeriodo = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public fecharOutrosPeriodos(agrupamentoID: number) {
    this.periodos.forEach((element) => {
      if (agrupamentoID == element.id) {
        element.panelAberto = true;

        let countServidores = 0;
        element.subAgrupamento.forEach((documentoNome) => {
          this.contribuinteService.findResponsavelPorDocumento(documentoNome.documento).subscribe((servidor) => {
            countServidores++;
            documentoNome.descricao = servidor.nome;
            if (countServidores === element.subAgrupamento.length) {
              element.subAgrupamento.sort((a, b) => (a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0));
            }
          });
        });
      } else if (agrupamentoID != element.id && element.panelAberto) {
        element.panelAberto = false;
        element.subAgrupamento.forEach((subElement) => (subElement.panelAberto = false));
      }
    });
  }

  public pesquisarComOrdenacaoDocAssinadoPeriodo(dataInicioDocAssinado: string, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.filtrarPorDocAssinados = true;
    processoPesquisa.dataInicial = dataInicioDocAssinado;
    processoPesquisa.dataFinal = dataInicioDocAssinado;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoDocumentoAssinadoPeriodo.pageIndex,
        this.paginatorProcessoDocumentoAssinadoPeriodo.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoDocumentoAssinadoPeriodo = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoDocumentoAssinadoPeriodo = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public getTiposDocumento() {
    this.processoService.getTiposDocumentoProcessoDocumentoSemAssinatura().subscribe(
      (tiposDocumento) => {
        this.tiposDocumento = tiposDocumento;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public getProcessoComDocumentosAguardandoAssinatura(idTipoDocumento: number, page: number, size: number) {
    this.idTipoDocumentoSelecionado = idTipoDocumento;
    this.selection.clear();
    this.stepTipoDocumento = idTipoDocumento;

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.tipoDocumentoId = idTipoDocumento;
    processoPesquisa.filtrarAguardandoSuaAssinatura = true;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoAguardandoAssinaturaTipoDocumento = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoAguardandoAssinaturaTipoDocumento = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeProcessoTipoDocumento() {
    this.stepTipoDocumento = null;
  }

  public pesquisarComOrdenacaoAguardandoAssinatura(idTipoDocumento: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.tipoDocumentoId = idTipoDocumento;
    processoPesquisa.filtrarAguardandoSuaAssinatura = true;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoAguardandoAssinaturaTipoDocumento.pageIndex,
        this.paginatorProcessoAguardandoAssinaturaTipoDocumento.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoAguardandoAssinaturaTipoDocumento = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoAguardandoAssinaturaTipoDocumento = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public getLotacoesProcessoQuantidadeDias(dias: number) {
    this.processoService.getLotacoesProcessoQuantidadeDias(dias).subscribe(
      (lotacoesProcessoQtdDias) => {
        this.lotacoesProcessoQtdDias = lotacoesProcessoQtdDias;
        if (this.lotacoesProcessoQtdDias.length == 0) {
          let empyt = new AgrupamentoProcessoDTO();
          empyt.descricao = "NENHUM REGISTRO ENCONTRADO.";
          this.lotacoesProcessoQtdDias.push(empyt);
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public pageEventQtdDiasSemAndamento(dias: number, lotacao: number, event) {
    this.getProcessoQuantidadeDias(dias, lotacao, event.pageIndex, event.pageSize);
  }

  assinarDocumento(): void {
    this.openDialogSelecionarCertificado();
  }

  openDialogSelecionarCertificado(): void {
    const openDialogSelecionarCertificado = this.dialog.open(DialogSelecionarCertificadoComponent, {
      width: "400px",
      data: this.certificate,
    });

    openDialogSelecionarCertificado.afterClosed().subscribe((certParam) => {
      if (certParam != null && certParam.cert64 != null) {
        this.certificate = certParam;
        this.assinarMarcados();
      }
    });
  }

  public getProcessoQuantidadeDias(dias: number, lotacao: number, page: number, size: number) {
    this.lotacoesProcessoQtdDias.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == lotacao) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.diasSemAndamento = dias;
    processoPesquisa.lotacaoAtual.push(lotacao);
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoQtdDias = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoSemAndamento = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeProcessoSemAndamento() {
    this.lotacoesProcessoQtdDias.forEach((element) => (element.panelAberto = false));
  }

  public pesquisarComOrdenacaoProcessoQuantidadeDias(dias: number, lotacao: number, sort: MatSort) {
    this.lotacoesProcessoQtdDias.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == lotacao) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.diasSemAndamento = dias;
    processoPesquisa.lotacaoAtual.push(lotacao);
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoSemAndamento.pageIndex,
        this.paginatorProcessoSemAndamento.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoQtdDias = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoSemAndamento = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public marcarDez(): void {
    this.selection.clear();

    this.dataSourceProcessoAguardandoAssinaturaTipoDocumento.data.forEach((row, index) => {
      if (index <= 10) {
        this.selection.select(row);
      }
    });
  }

  private assinarMarcados() {
    if (this.selection.selected.length === 0) {
      this.messageService.messageWarning("Nenhum documento selecionado!");
      return;
    }

    const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
    const formData: FormData = new FormData();
    formData.append("certContent", this.certificate.cert64);
    formData.append("documento", this.documentoAstra); //SUBSTITUIR PELO DOC AUTOR

    let countDocumentosAtualizados = 0;
    this.selection.selected.forEach((prcesso, index) => {
      this.processoService
        .getDocumentosSemAssinaturaPorTipoDocumento(prcesso.id, this.idTipoDocumentoSelecionado)
        .subscribe((documentos) => {
          documentos.forEach((documento) => {
            this.documentoService.startWithIdDocumento(documento.idDocumento, formData, SharedService.CARREGAR_LOADING).subscribe(
              (sigParams) => {
                this.pki
                  .signHash({
                    thumbprint: cert.thumbprint,
                    hash: sigParams.toSignHash,
                    digestAlgorithm: sigParams.digestAlgorithm,
                  })
                  .success((signature) => {
                    this.documentoService
                      .completeWithIdDocumento(
                        documento.idDocumento,
                        sigParams.fileName,
                        sigParams.transferFileId,
                        signature,
                        sigParams.descricaoAssinatura,
                        SharedService.CARREGAR_LOADING
                      )
                      .subscribe(
                        (documentoAssinado) => {
                          countDocumentosAtualizados++;
                          if (countDocumentosAtualizados === this.selection.selected.length) {
                            // this.natureza = null;
                            // this.listarDocumentosProcesso(this.processo);
                          }
                          this.messageService.messageSuccess("Documento assinado com sucesso!");
                        },
                        (error) => {
                          this.messageService.messageErro(error.error.message);
                          throw error;
                        }
                      );
                  });
              },
              (msgExcecao) => {
                countDocumentosAtualizados++;
                if (msgExcecao.error != null && msgExcecao.error.message != null) {
                  this.messageService.messageErro(msgExcecao.error.message);
                  throw msgExcecao;
                } else {
                  this.messageService.messageErro("Erro na assinatura do arquivo.");
                }
              }
            );
          });
        });
    });
  }

  masterToggle() {
    if (this.dataSourceProcessoAguardandoAssinaturaTipoDocumento != null) {
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSourceProcessoAguardandoAssinaturaTipoDocumento.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    if (
      this.dataSourceProcessoAguardandoAssinaturaTipoDocumento != null ||
      this.dataSourceProcessoAguardandoAssinaturaTipoDocumento !== undefined
    ) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSourceProcessoAguardandoAssinaturaTipoDocumento.data.length;
      return numSelected === numRows;
    }
    return false;
  }

  checkboxLabel(row?: ProcessoTabelaDto): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
  }

  public getProcessoSobResponabilidade() {
    // tslint:disable-next-line:max-line-length
    this.processoService.getProcessoEmAndamentoComResponsavel().subscribe(
      (responsaveisProcesso) => {
        this.responsaveisProcesso = responsaveisProcesso;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public pageEventResponsavel(responsavel: number, documento: string, event) {
    this.getProcessoPorResponsavel(responsavel, documento, event.pageIndex, event.pageSize);
  }

  public getProcessoPorResponsavel(responsavel: number, documento: string, page: number, size: number) {
    this.responsaveisProcesso.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == responsavel) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.cpfCnpjRepresentante = documento;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoResponsavel = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoResponsavel = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeSobResponsabilidade() {
    this.responsaveisProcesso.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorResponsavel(responsavel: number, documento: string, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.cpfCnpjRepresentante = documento;
    processoPesquisa.filtroArquivados = false;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoResponsavel.pageIndex,
        this.paginatorProcessoResponsavel.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoResponsavel = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoResponsavel = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  //carlos
  public getSetoresSemResponsabilidade() {
    if (this.setoresSemResponsabilidade.length === 0) {
      this.processoService.pesquisarSetoresSemResponsabilidade().subscribe(
        (setores) => {
          this.setoresSemResponsabilidade = setores;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  public pageEventSetorSemResponsabilidade(setor: number, event) {
    this.getProcessoPorSetorSemResponsabilidade(setor, event.pageIndex, event.pageSize);
  }

  public getProcessoPorSetorSemResponsabilidade(setor: number, page: number, size: number) {
    this.setoresSemResponsabilidade.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.id == setor) {
        element.panelAberto = true;
      } else {
        element.panelAberto = false;
      }
    });

    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.lotacaoAtual.push(setor);
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.filtrarSemResponsabilidade = true;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processos) => {
        this.dataSourceProcessoSetorSemResponsabilidade = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
        this.resultsLengthProcessoSetorSemResponsabilidade = processos.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public closeSetoresSemResponsabilidade() {
    this.setoresSemResponsabilidade.forEach((element) => (element.panelAberto = false));
  }

  pesquisarComOrdenacaoPorSetorSemResponsabilidade(setor: number, sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.lotacaoAtual.push(setor);
    processoPesquisa.cpfCnpj = this.sharedService.decodePayloadJWT().sub;
    processoPesquisa.filtroArquivados = false;
    processoPesquisa.filtrarSemResponsabilidade = true;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoSetorSemResponsabilidade.pageIndex,
        this.paginatorProcessoSetorSemResponsabilidade.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processos) => {
          this.dataSourceProcessoSetorSemResponsabilidade = new MatTableDataSource<ProcessoTabelaDto>(processos.content);
          this.resultsLengthProcessoSetorSemResponsabilidade = processos.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  public pageEventProcessoObservado(event) {
    this.getProcessoObservado(event.pageIndex, event.pageSize);
  }

  public getProcessoObservado(page: number, size: number) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtrarPorObservando = true;
    processoPesquisa.idOrgaoUsuarioLogado = this.sharedService.decodePayloadJWT().idOrgaoDescricao;
    processoPesquisa.filtroArquivados = false;
    this.processoService.pesquisa(processoPesquisa, page, size).subscribe(
      (processosDto) => {
        this.dataSourceProcessoObservado = new MatTableDataSource<ProcessoTabelaDto>(processosDto.content);
        this.resultsLengthProcessoObservado = processosDto.totalElements;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  pesquisarComOrdenacaoPorObservado(sort: MatSort) {
    const processoPesquisa = new ProcessoPequisa();
    processoPesquisa.filtrarPorObservando = true;
    processoPesquisa.filtroArquivados = false;
    this.processoService
      .consultarOrdenado(
        processoPesquisa,
        this.paginatorProcessoObservado.pageIndex,
        this.paginatorProcessoObservado.pageSize,
        sort.active,
        sort.direction
      )
      .subscribe(
        (processosDto) => {
          this.dataSourceProcessoObservado = new MatTableDataSource<ProcessoTabelaDto>(processosDto.content);
          this.resultsLengthProcessoObservado = processosDto.totalElements;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  downloadDocumentoProcesso($event, idProcesso: number) {
    $event.preventDefault();
    $event.stopPropagation();
    this.documentoService.downloadDocumentoProcesso(idProcesso).subscribe(
      (blob) => {
        if (!Utils.downloadBlob(blob)) {
          this.messageService.messageWarning("Não existe documento no processo.");
        }
      },
      (error) => {
        const erro = Utils.converterArrayBufferParaJSON(error);
        this.messageService.messageErro(erro.error.message);
      }
    );
  }

  getProcesso(processoId: number) {
    this.router.navigate([`${this.resourceConsultarProcesso}/${processoId}`], {});
  }

  private formatDate(data: Date) {
    const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;
    return "" + dd + "-" + mm + "-" + y;
  }

  private formatDate2(data: Date) {
    const y = data.getFullYear();
    const m = data.getMonth() + 1;
    const d = data.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;
    return "" + mm + "-" + dd + "-" + y;
  }
}
