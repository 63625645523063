import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CadastroModule } from "./cadastro/cadastro.module";
import { DocumentoModule } from "./documento/documento.module";
import { MasterModule } from "./master/master.module";
import { ModuloAssinadorModule } from "./modulo-assinador/modulo-assinador.module";
import { ProcessoModule } from "./processo/processo.module";
import { SegurancaModule } from "./seguranca/seguranca.module";
import { ServicosModule } from "./servicos/servicos.module";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ServicosModule,
    ProcessoModule,
    DocumentoModule,
    CadastroModule,
    MasterModule,
    ModuloAssinadorModule,
    SegurancaModule
  ]
})
export class CoreModule { }
