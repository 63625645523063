import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from "@angular/material";
import { DocumentoRegras } from "../../models/documento-regras.model";
import { PendenciaProcessoDTO } from "../../models/pendencia-processo-dto.model";
import { Resultado } from "../../models/pensao/resultado.model";
@Component({
  selector: "app-dialog-listar-pendencias-processo",
  templateUrl: "./dialog-listar-pendencias-processo.component.html",
  styleUrls: ["./dialog-listar-pendencias-processo.component.css"]
})
export class DialogListarPendenciasProcesso implements OnInit {

  public dataSourceDocumento: MatTableDataSource<DocumentoRegras>;
  public displayedDocumentosColumns: string[] = ["descricao"];
  public resultado: Resultado;

  constructor(
    public dialogRef: MatDialogRef<DialogListarPendenciasProcesso>,
    @Inject(MAT_DIALOG_DATA) public data: PendenciaProcessoDTO) {
    this.resultado = data.resultado;
      
  }


  ngOnInit() {
    this.dataSourceDocumento = new MatTableDataSource<DocumentoRegras>(this.data.documentosObrigatorios);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
