import { Component, OnInit, Input } from "@angular/core";
import { TipoCampo } from "../models/tipo-campo";
import { AssuntoMetadado } from "src/app/core/models/assunto-metadado.model";
import { Metadado } from "../models/metadado.model";
import { TipoMetadadoEnum } from "src/app/core/models/tipo-metadado.enum";

@Component({
  selector: "app-metadado",
  templateUrl: "./metadado.component.html",
  styleUrls: ["./metadado.component.css"]
})
export class MetadadoComponent implements OnInit {

  @Input() assuntoMetadados: AssuntoMetadado[];
  @Input() funcaoNoPai: Function;

  constructor() {
   }

  ngOnInit() {
    this.assuntoMetadados = this.assuntoMetadados.sort((am1, am2) => {
      if (am1.ordem > am2.ordem) {
        return 1;
      }

      if (am1.ordem < am2.ordem) {
        return -1;
      }

      return 0;
    });

  }

  isTipoInput(tipo: number): boolean {
    return TipoCampo.isData(tipo) || TipoCampo.isNumerico(tipo);
  }

  isTipoInputMask(tipo: number): boolean {
    return TipoCampo.isAlfanumerico(tipo);
  }

  isTipoBotao(tipo: number): boolean {
    return TipoCampo.isBotao(tipo);
  }

  isTipoMonetario(tipo: number): boolean {
    return TipoMetadadoEnum.MONETARIO === tipo;
  }

  isTipoData(tipo: number): boolean {
    return TipoMetadadoEnum.DATA === tipo;
  }

  isTipoLetrasNumero(tipo: number): boolean {
    if (TipoMetadadoEnum.DATA !== tipo && TipoMetadadoEnum.MONETARIO !== tipo && TipoMetadadoEnum.BOTAO !== tipo) {
      return true;
    } else {
      return false;
    }
  }

  getTipoCampo(tipo: number): string {
    return TipoCampo.isData(tipo)
      ? "date" : TipoCampo.isNumerico(tipo) ? "number" : "text";
  }

  desabilitar(): boolean {
    return false;
  }

  voltar(): void {
  }

  blurInput(metadado: Metadado) {
    if (metadado.sistema) {
      this.funcaoNoPai(metadado);
    }
  }
  
  onKeyup(metadado: Metadado) {
    if (metadado.nome === "Nosso Número" && metadado.valor === ""){
      this.assuntoMetadados.forEach(assuntoMetadado => {
        if (assuntoMetadado.metadado.nome === "Gerar DAE") {
          assuntoMetadado.metadado.disabled = false;
        }
      });
    }
  }

  acaoBotao(metadado: Metadado): void {
    this.funcaoNoPai(metadado);
  }

  getTamanhoDoCampo(tamanho: string) {
    return tamanho.length;
  }

}
