import { ApiService } from "./api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class ArquivoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "documento", config);
    this.API_URL = this.config.API_DOCUMENTO_URL;
  }

  public enviar(body: any ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post(`${this.API_URL}${this.resource}`, body).pipe(map(response => response));
  }
}