import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig, APP_CONFIG } from "src/app.config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TipoDocumentoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "tipo-documento", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }

  getByAssunto(id: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/assunto/${id}`).pipe(map(response => response));
  }

  public getByListTipoDocumentoId(listaTipoDocumentoId: number[]): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento?tipoDocumentoIds=${listaTipoDocumentoId}`)
      .pipe(map(response => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      }));
  }

  public getTipoDocumentoDaeId(): any {
    return this.http.get(`${this.API_URL}${this.resource}/dae`).pipe(map(response => response));
  }

  public getTipoDocumentoSomenteAnexo(somenteAnexo: boolean): any{
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento-somente-anexo/${somenteAnexo}`)
    .pipe(map(response => response));
  }

  public getTipoDocumentoEditorPorSetor(): any{
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento-editor-setor-usuario-logado`)
    .pipe(map(response => response));
  }

  public getTipoDocumentoEditorPorLotacao(idLotacao: number, somenteAnexo: boolean, visibilidadeLotacao: boolean, visibilidadeExterna: boolean): Observable<any>{
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento-editor-setor/${idLotacao}?somenteAnexo=${somenteAnexo}&visibilidadeLotacao=${visibilidadeLotacao}&visibilidadeExterna=${visibilidadeExterna}` )
    .pipe(map(response => {
      const responseContent: any = response;
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  public getTipoDocumentoMotorRegra(idTipoDocumentoMotorRegras: string): any{
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento-motor-regras/${idTipoDocumentoMotorRegras.trim().toLowerCase()}`)
    .pipe(map(response => response));
  }

  public listarTipoDocumentoMotorRegra(): any{
    return this.http.get(`${this.API_URL}${this.resource}/listar-tipo-documento-motor-regras`)
    .pipe(map(response => response));
  }

  public listarTiposDocumentosPendenteAssinatura(documento: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pendentesAssinatura/${documento}`).pipe(map(response => {
      const responseContent: any = response;
      // tslint:disable-next-line:triple-equals
      if (responseContent.content != undefined) {
        return responseContent.content;
      } else {
        return response;
      }
    }));
  }

  pesquisarTipoDocumentoSistema(idTipoDocumentoSistema: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/pesquisarTipoDocumentoSistema/${idTipoDocumentoSistema}`).pipe(map(response => response));
  }

  listarLotacoesTipoDocumento(idTipoDocumentoSistema: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/listar-lotacoes-tipo-documento/${idTipoDocumentoSistema}`).pipe(map(response => response));
  }

   listarTipoDocumentoPorLotacao(idLotacaoUsuario: number): any {
    return this.http.get(`${this.API_URL}${this.resource}/listar-documentos-lotacao/${idLotacaoUsuario}`).pipe(map(response => response));
  }
  
  public verificarPermissaoEditarTipoDocumentoPorLotacao() {
    return this.http.get(`${this.API_URL}${this.resource}/verificarPermissaoEditarTipoDocumentoPorLotacao`).pipe(map(response => {return response}));
  }

  public listarTiposDocumentosMotorRegras(idsTipoDocumentoMotorRegras: string[]): Observable<any>{
    return this.http.post(`${this.API_URL}${this.resource}/listar-tipo-documento-motor-regras/`, idsTipoDocumentoMotorRegras)
    .pipe(map(response => response));
  }

  listarTipoDocumento(): any {
    return this.http.get(`${this.API_URL}${this.resource}`).pipe(map(response => response));
  }

}