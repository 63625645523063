import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Contribuinte } from "../../../core/models/contribuinte.model";
import { ContribuinteService } from "../../../core/api/contribuinte.service";
import { MessageService } from "../../message/message.service";
import { Router } from "@angular/router";
import { SharedService } from "../../api/shared.service";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-cadastro-contribuinte-representante",
  templateUrl: "./cadastro-contribuinte-representante.component.html",
  styleUrls: ["./cadastro-contribuinte-representante.component.css"]
})
export class CadastroContribuinteRepresentanteComponent implements OnInit {

  @ViewChild(NgForm) form: NgForm;
  @ViewChild("cpfCnpjv") cnpfCnpjv;
  @ViewChild("email") email;

  public message: string;
  public contribuinte: Contribuinte;
  public disabled: boolean;
  public disabledDocumento = false;
  public opcao: string;

  constructor(
    private contribuinteService: ContribuinteService,
    private messageService: MessageService,
    private router: Router,
    private zone: NgZone,
    public dialog: MatDialog,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.contribuinte = new Contribuinte();
    this.setDocumento();
  }

  private setDocumento() {
    const documento = this.sharedService.getDocumentoRepresentante();

    if (documento) {
      this.contribuinte.documento = documento.replace(/['"]+/g, "");
      this.disabledDocumento = true;
      this.contribuinte.local = true;
    }
  }

  public entrar() {
    if (this.contribuinte.local && this.email.invalid) {
      this.messageService.messageErro("Email inválido!");
      return;
    }

    if (this.contribuinte.local && this.form.invalid) {
      this.messageService.messageErro("Preencha os campos obrigatórios!");
      return;
    }

    this.contribuinteService.post(this.contribuinte).subscribe(
      (contribuinte) => {
        this.messageService.messageSuccess("Certificado cadastrado com sucesso!", contribuinte.nome);
        this.zone.run(() => this.router.navigate(["entrada-contribuinte"]));
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public voltar() {
    this.router.navigate(["login"]);
  }

  public onEnter(event) {
    if (event.key === "Enter") {
      this.entrar();
    }
  }
}