import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { SharedService } from "../../../global/api/shared.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { SisSegService } from "../../api/sis-seg.service";
import { environment } from "../../../../environments/environment";
import { MessageService } from "src/app/global/message/message.service";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { TipoAberturaProcessoEnum, TipoAberturaProcessoEnumAll } from "../../models/tipo-abertura-processo-enum.model";
import { OrgaoLocal } from "../../models/orgao-local.model";
import { OrgaoService } from "../../api/orgao.service";

@Component({
  selector: "app-detalhe-responsavel",
  templateUrl: "./detalhe-responsavel.component.html",
  styleUrls: ["./detalhe-responsavel.component.css"],
})
export class DetalheResponsavelComponent implements OnInit, OnChanges {
  @Input() infoAssuntoAutor: OpcaoAssuntoInterno;
  pessoa: any;
  public lotacao: OrgaoLocal;

  constructor(
    private contribuinteSevice: ContribuinteService,
    private sisSegService: SisSegService,
    private orgaoService: OrgaoService,
    private messageService: MessageService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null && this.infoAssuntoAutor.infoAutor != null) {
      if (
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO ||
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL
      ) {
        //this.messageService.messageWarning("Implementar consulta de servidores por documento.");
        this.sisSegService.consultarServidorByDocumento(this.infoAssuntoAutor.infoAutor).subscribe(
          (servidor) => {
            this.pessoa = servidor;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else if (
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE ||
        TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO
      ) {
        this.contribuinteSevice.findByDocumento(this.infoAssuntoAutor.infoAutor).subscribe(
          (contribuinte) => {
            this.pessoa = contribuinte;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else if (TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO) {
        this.orgaoService.getOrgaoLocalById(+this.infoAssuntoAutor.infoAutor).subscribe(
          (orgaoLocal: OrgaoLocal) => {
            this.lotacao = orgaoLocal;
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    }
  }

  isContribuinteProcessoProprio() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null) {
      // tslint:disable-next-line: max-line-length
      return TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.CONTRIBUINTE_PROCESSO_PROPRIO
        ? true
        : false;
    }
    return false;
  }

  isFiscalProcessoContribuinte() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null) {
      // tslint:disable-next-line: max-line-length
      return TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE
        ? true
        : false;
    }
    return false;
  }

  isFiscalProcessoProprio() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null) {
      // tslint:disable-next-line: max-line-length
      return TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_PROPRIO
        ? true
        : false;
    }
    return false;
  }

  isFiscalProcessoOutroFiscal() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null) {
      // tslint:disable-next-line: max-line-length
      return TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_OUTRO_FISCAL
        ? true
        : false;
    }
    return false;
  }

  isFiscalProcessoLotacao() {
    if (this.infoAssuntoAutor != null && this.infoAssuntoAutor.tipo != null) {
      // tslint:disable-next-line: max-line-length
      return TipoAberturaProcessoEnumAll[this.infoAssuntoAutor.tipo].descricao === TipoAberturaProcessoEnum.FISCAL_PROCESSO_LOTACAO
        ? true
        : false;
    }
    return false;
  }
}
