import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardListTemplateComponent } from './components/templates/card-list-template/card-list-template.component';
import { FormTemplateComponent } from './components/templates/form-template/form-template.component';
import { MaterialModule } from '../material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './components/atoms/text-input/text-input.component';
import { TableComponent } from './components/organisms/table/table.component';
import { CrudListTemplateComponent } from './components/templates/crud-list-template/crud-list-template.component';
import { SectionsContainerComponent } from './containers/sections-container/sections-container.component';
import { PopoverModule } from "ngx-smart-popover";
import { InfoComponent } from './components/atoms/info/info.component';

@NgModule({
  declarations: [CardListTemplateComponent, FormTemplateComponent, TextInputComponent, TableComponent, CrudListTemplateComponent, SectionsContainerComponent, InfoComponent],
  imports: [
    CommonModule, 
    MaterialModule, 
    FormsModule,
    ReactiveFormsModule,
    PopoverModule
  ],
  exports: [
    // Atoms:
    TextInputComponent,

    // Organisms:
    TableComponent,
    
    //Templates:
    CardListTemplateComponent, 
    FormTemplateComponent,
    CrudListTemplateComponent,
  ]
})
export class UiModule { }
