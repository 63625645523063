import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Assunto } from "../../models/assunto.model";

@Component({
  selector: "app-dialog-desativar-tipo-documento",
  templateUrl: "./dialog-desativar-tipo-documento.component.html",
  styleUrls: ["./dialog-desativar-tipo-documento.component.css"]
})
export class DialogDesativiarTipoDocumento {

  assuntoList: Assunto[];

  constructor(public dialogRef: MatDialogRef<DialogDesativiarTipoDocumento>,
    @Inject(MAT_DIALOG_DATA) public data: Assunto[]) {
    this.assuntoList = data;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
