import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CategoriaAssuntoService } from "../api/categoria-assunto.service";
import { MatListModule, MatButtonModule, MatIconModule, MatSelectModule, MatFormFieldModule, MAT_DATE_LOCALE } from "@angular/material";
import { AssuntoService } from "../api/assunto.service";
import { SalvarProcessoComponent } from "./salvar-processo/salvar-processo.component";
import { DetalheResponsavelComponent } from "./detalhe-responsavel/detalhe-responsavel.component";
import { MatCardModule } from "@angular/material/card";
import { ResponsavelService } from "../api/responsavel.service";
import { MetadadoModule } from "../../global/metadado/metadado.module";
import { TipoDocumentoService } from "../api/tipo-documento.service";
import { AnexoProcessoComponent } from "./anexo-processo/anexo-processo.component";
import { AnexoAssuntoService } from "../api/anexo-assunto.service";
import { MaterialModule } from "../../material";
import { ResumoAnexoProcessoComponent } from "./resumo-anexo-processo/resumo-anexo-processo.component";
import { ListarProcessoComponent } from "./listar-processo/listar-processo.component";
import { ListarPendenciaProcessoComponent } from "./listar-pendencia-processo/listar-pendencia-processo.component";
import { ProcessoService } from "../api/processo.service";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { DetalhamentoProcessoComponent } from "./detalhamento-processo/detalhamento-processo.component";
import { DetalheProcessoComponent } from "./detalhe-processo/detalhe-processo.component";
import { TitleViewModule } from "../../global/title-view/title-view.module";
import { AssinaturaService } from "../api/assinatura.service";
import { MovimentacaoService } from "../api/movimentacao.service";
import { ConsultarProcessoComponent } from "./consultar-processo/consultar-processo.component";
import { ArquivoService } from "../api/arquivo.service";
import { DialogProcessoComponent } from "./dialog-processo/dialog-processo.component";
import { FaseService } from "../api/fase.service";
import { SituacaoService } from "../api/situacao.service";
import { CKEditorModule } from "ng2-ckeditor";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DialogAlterarSituacaoComponent } from "./dialog-alterar-situacao/dialog-alterar-situacao.component";
import { DialogArquivarComponent } from "./dialog-arquivar/dialog-arquivar.component";
import { DialogAlterarFaseComponent } from "./dialog-alterar-fase/dialog-alterar-fase.component";
import { DialogMovimentacaoProcessoComponent } from "./dialog-movimentacao-processo/dialog-movimentacao-processo.component";
import { PopoverModule } from "ngx-smart-popover";
import { NgxMaskModule } from "ngx-mask";
import { ListarMeusProcessosComponent } from "./listar-meus-processos/listar-meus-processos.component";
import { ListarProcessoTabelaComponent } from "./listar-processo-tabela/listar-processo-tabela.component";
import { DialogIndicarAssinadoresComponent } from "./dialog-indicar-assinadores/dialog-indicar-assinadores.component";
import { SisSegService } from "../api/sis-seg.service";
import { DocumentoAssinaturasComponent } from "./documento-assinaturas/documento-assinaturas.component";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { DialogTipoDocumentoComponent } from "./dialog-tipo-documento/dialog-tipo-documento.component";
import { ListarAssinadoresIndicadosComponent } from "../processo/listar-assinadores-indicados/listar-assinadores-indicados.component";
import { DialogSelecionarCertificadoComponent } from "./dialog-selecionar-certificado/dialog-selecionar-certificado.component";
import { NgxLoadingModule } from "ngx-loading";
import { DialogConfirmarComponent } from "./dialog-confirmar/dialog-confirmar.component";
import { ContribuinteService } from "../api/contribuinte.service";
import { DialogListarTagsComponent } from "./dialog-listar-tags/dialog-listar-tags.component";
import { DialogLotacaoComponent } from "./dialog-lotacao/dialog-lotacao.component";
import { DialogAvisoComponent } from "./dialog-aviso/dialog-aviso.component";
import { DirectivesModule } from "../../global/directives/directives.module";
import { ItemNaoSimilaridadeComponent } from "./item-nao-similaridade/item-nao-similaridade.component";
import { ItemNaoSimilaridadeEventoComponent } from "./item-nao-similaridade-evento/item-nao-similaridade-evento.component";
import { EventoService } from "../api/evento.service";
import { DialogItenNaoSimilaridadeComponent } from "./dialog-itens-nao-similaridade/dialog-itens-nao-similaridade.component";
import { DialogDataInicioFimComponent } from "./dialog-data-inicio-fim/dialog-data-inicio-fim.component";
import { DocumentoDTO } from "../models/documento-dto.model";
import { TabelaProcessoComponent } from "./tabela-processo/tabela-processo.component";
import { DialogListarServidoresComponent } from "./dialog-listar-servidores/dialog-listar-servidores.component";
import { DialogSelecionarModeloComponent } from "./dialog-selecionar-modelo/dialog-selecionar-modelo.component";
import { DialogJustificativaComponent } from "./dialog-justificativa/dialog-justificativa.component";
import { DialogDesativiarTipoDocumento } from "./dialog-desativar-tipo-documento/dialog-desativar-tipo-documento.component";
import { PensaoModule } from "../pensao/pensao.module";
import { DialogListarPendenciasProcesso } from "./dialog-listar-pendencias-processo/dialog-listar-pendencias-processo.component";
import { DialogAdicionarOcorrenciaProcesso } from "./dialog-adicionar-ocorrencias/dialog-adicionar-ocorrencias-processo.component";
import { UiModule } from "src/app/ui/ui.module";
import { DialogResponderOcorrenciaProcesso } from "./dialog-responder-ocorrencias/dialog-responder-ocorrencias-processo.component";
import { DialogIndicarEdowebComponent } from './dialog-indicar-edoweb/dialog-indicar-edoweb.component';
import { DialogVincularProcessosRelacionadosComponent } from './dialog-vincular-processos-relacionados/dialog-vincular-processos-relacionados.component';

const routes: Routes = [
  { path: "processo", component: SalvarProcessoComponent},
  { path: "processo/:id/:tipoAutor/:infoAutor/:infoAssinador/:descricao", component: SalvarProcessoComponent, data: {
    title: "Processo",
    breadcrumb: [
      {
        label: "{{descricao}}",
        url: "/processo/:id/:tipoAutor/:infoAutor/:infoAssinador/:descricao"
      }
    ]}},
  { path: "solicitacoes", component: ListarProcessoComponent, data: {
    title: "Minhas Solicitações",
    breadcrumb: [
      {
        label: "Minhas Solicitações",
        url: "/solicitacoes"
      }
    ]}},
  { path: "pendencias", component: ListarPendenciaProcessoComponent, data: {
    title: "Minhas Pendências",
    breadcrumb: [
      {
        label: "Minhas Pendências",
        url: "/pendencias"
      }
    ]}},
  { path: "anexo-processo/:id", component: AnexoProcessoComponent},

  { path: "detalhamento-processo", component: DetalhamentoProcessoComponent},

  { path: "consultar-processo/:id", component: ConsultarProcessoComponent, data: {
    title: "Consultar Processo",
    breadcrumb: [
      {
        label: "Detalhes do Processo",
        url: "/consultar-processo/:id"
      }
    ]
  }},

  { path: "consultar-processo", component: ConsultarProcessoComponent, data: {
    title: "Consultar Processo",
    breadcrumb: [
      {
        label: "Consultar Processo",
        url: "/consultar-processo"
      }
    ]}},
  { path: "lista-processo-tabela", component: ListarProcessoTabelaComponent, data: {
    title: "Prateleira de Processos",
    breadcrumb: [
      {
        label: "Prateleira de Processos",
        url: "/lista-processo-tabela"
      }
    ]}},
        
    { path: "lista-meus-processos", component: ListarMeusProcessosComponent, data: {
      title: "Minhas Solicitações",
      breadcrumb: [
        {
          label: "Minhas Solicitações",
          url: "/lista-meus-processos"
        }
      ]}}
];

@NgModule({
  declarations: [SalvarProcessoComponent, DetalheResponsavelComponent, AnexoProcessoComponent,
    ResumoAnexoProcessoComponent, ListarProcessoComponent, ListarPendenciaProcessoComponent,
    DetalhamentoProcessoComponent, DetalheProcessoComponent, ConsultarProcessoComponent,
    DialogProcessoComponent, DialogAlterarSituacaoComponent, DialogAlterarFaseComponent,
    DialogMovimentacaoProcessoComponent, DialogIndicarAssinadoresComponent, DocumentoAssinaturasComponent, DialogTipoDocumentoComponent,
    ListarProcessoTabelaComponent, ListarMeusProcessosComponent, ListarAssinadoresIndicadosComponent, DialogSelecionarCertificadoComponent,
    DialogArquivarComponent, DialogConfirmarComponent, DialogListarTagsComponent, DialogLotacaoComponent, DialogAvisoComponent,
    ItemNaoSimilaridadeComponent, DialogJustificativaComponent, ItemNaoSimilaridadeEventoComponent, DialogItenNaoSimilaridadeComponent,
    DialogDataInicioFimComponent, TabelaProcessoComponent, DialogListarServidoresComponent, DialogSelecionarModeloComponent,
    DialogDesativiarTipoDocumento, DialogListarPendenciasProcesso, DialogAdicionarOcorrenciaProcesso, DialogResponderOcorrenciaProcesso, DialogIndicarEdowebComponent, DialogVincularProcessosRelacionadosComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    CKEditorModule,
    MatCardModule,
    MetadadoModule,
    TitleViewModule,
    MaterialModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxSmartModalModule.forRoot(),
    PopoverModule,
    NgxMaskModule.forRoot(),
    PdfJsViewerModule,
    NgxLoadingModule.forRoot({}),
    DirectivesModule,
    PensaoModule,
    UiModule
  ],
  providers: [CategoriaAssuntoService, AssuntoService, ResponsavelService, EventoService,
    TipoDocumentoService, AnexoAssuntoService, ProcessoService, AssinaturaService, MovimentacaoService,
    ArquivoService, FaseService, SituacaoService, SisSegService, ContribuinteService, {provide: MAT_DATE_LOCALE, useValue: "pt-BR"}],
  entryComponents: [DialogProcessoComponent, DialogAlterarFaseComponent, DialogAlterarSituacaoComponent,
    DialogMovimentacaoProcessoComponent, DialogIndicarAssinadoresComponent, DialogTipoDocumentoComponent,
    DialogSelecionarCertificadoComponent, DialogArquivarComponent, DialogConfirmarComponent,
    DialogListarTagsComponent, DialogLotacaoComponent, DialogAvisoComponent, DialogJustificativaComponent, DialogListarPendenciasProcesso, 
    DialogItenNaoSimilaridadeComponent, DialogDataInicioFimComponent, DialogListarServidoresComponent, DialogSelecionarModeloComponent,
    DialogDesativiarTipoDocumento, DialogResponderOcorrenciaProcesso, DialogAdicionarOcorrenciaProcesso, DialogIndicarEdowebComponent, DialogVincularProcessosRelacionadosComponent],
})
export class ProcessoModule { }
