import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class PerfilService extends ApiService {
    API_URL: string;

    constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
        super(http, "sisseg/perfil", config);
        this.API_URL = config.API_SISSEG_URL;
    }
}