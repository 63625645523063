import { Component, ViewChild } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Assunto } from "src/app/core/models/assunto.model";
import { AssuntoService } from "src/app/core/api/assunto.service";
import { OrgaoService } from "src/app/core/api/orgao.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { MessageService } from "src/app/global/message/message.service";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-basico-assunto",
  templateUrl: "./listar-basico-assunto.component.html",
  styleUrls: ["./listar-basico-assunto.component.css"]
})
export class ListarBasicoAssuntoComponent extends ListarGenericsComponent<Assunto> {
  public dataSource: MatTableDataSource<Assunto>;
  public columns = [];
  public buttons = [];

  @ViewChild('child') child: any;

  ngAfterViewInit() {
    this.paginator = this.child.paginator
  }

  constructor(public apiService: AssuntoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public orgaoService: OrgaoService,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "assunto", ["descricao", "assuntoCategoria", "faseInicial", "ativo", "removerOpcao"]);
    this.columns =  [
      {
        key: 'descricao',
        label: 'Descrição'
      },
      {
        key: 'assuntoCategoria',
        label: 'Assunto Categoria'
      },
      {
        key: 'faseInicial',
        label: 'Fase Inicial'
      },
      {
        key: 'ativo',
        label: 'Ativo',
        type: 'toggle',
        props: {
          matTooltip:"Ativar/Desativar",
          color: "accent",
          click: (e) => this.inativar(e)
        }
      },
      {
        key: 'removerOpcao',
        label: 'Ações',
        type: 'icon',
        props: {
          icon: 'create',
          matTooltip: 'Editar',
          click: (e) => this.edit(e)
        }
      }
    ]
    this.buttons = [ 
      {
        label: 'Novo Assunto',
        click: () => {
          this.setPageState();
          this.router.navigateByUrl('/sgeap/assunto/salvar')
        }
      },
    ]
  }

  inativar(assunto: Assunto){
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult === true) {
          assunto.ativo = !assunto.ativo;
          this.apiService.alternarAtivo(assunto.id).subscribe(
            (res) => {
              this.messageService.messageSuccess("Visibilidade alterada com sucesso.");
              return true;
            },
            error => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        } else {
          return false;
        }
    });
    return false;
  }
}
