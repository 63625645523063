import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { OpcaoAssuntoInterno } from "../../models/opcao-assunto-interno.model";
import { MessageService } from "src/app/global/message/message.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { Contribuinte } from "../../models/contribuinte.model";
import { TipoAberturaProcessoEnum } from "../../models/tipo-abertura-processo-enum.model";
import { NgForm } from "@angular/forms";
import { DialogSelecaoFilialComponent } from "src/app/global/contribuinte/dialog-selecao-filial/dialog-selecao-filial.component";

@Component({
  selector: "app-dialog-fiscal-escolhe-autor-externo",
  templateUrl: "./dialog-fiscal-escolhe-autor-externo.component.html",
  styleUrls: ["./dialog-fiscal-escolhe-autor-externo.component.css"]
})
export class DialogFiscalEscolheAutorExternoComponent implements OnInit {

  public opcaoAssuntoInterno = new OpcaoAssuntoInterno();

  public message: string;
  public contribuinte: Contribuinte;
  public disabled: boolean;
  public disabledDocumento = false;
  public opcao: string;
  public titulo = "Pesquisar Contribuinte";

  @ViewChild(NgForm) form: NgForm;
  @ViewChild("cpfCnpjv") cnpfCnpjv;
  @ViewChild("email") email;

  constructor(
    public dialogRef: MatDialogRef<DialogFiscalEscolheAutorExternoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contribuinteService: ContribuinteService,
    private messageService: MessageService,
    public dialog: MatDialog) {
    this.contribuinte = new Contribuinte();
  }

  ngOnInit() {
    this.disabled = true;
  }

  confirm(): void {
    if (this.cnpfCnpjv.invalid) {
      this.messageService.messageErro("Cpf/Cnpj Inválido!");
      return;
    }

    if (this.contribuinte.local && this.email.invalid) {
      this.messageService.messageErro("Email inválido!");
      return;
    }

    if (this.contribuinte.local && this.form.invalid) {
      this.messageService.messageErro("Preencha os campos obrigatórios!");
      return;
    }

    if (this.contribuinte.local) {
      if (this.contribuinte.id === null || this.contribuinte.id === undefined) {
        this.contribuinteService.post(this.contribuinte).subscribe(
          (contribuinte) => {
            this.messageService.messageSuccess("Contribuinte cadastrado com sucesso!", contribuinte.nome);
            this.setDocumento(contribuinte);
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        this.contribuinte.documento = this.contribuinte.documento.replace(/\D/g, "");
        this.contribuinteService.updateContribuinte(this.contribuinte).subscribe(
          (contribuinte) => {
            this.messageService.messageSuccess("Contribuinte atualizado com sucesso!", contribuinte.nome);
            this.setDocumento(contribuinte);
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      }
    } else {
      this.setDocumento(this.contribuinte);
    }
  }

  private setDocumento(contribuinte: Contribuinte) {
    this.opcaoAssuntoInterno.tipo = TipoAberturaProcessoEnum.FISCAL_PROCESSO_CONTRIBUINTE;
    this.opcaoAssuntoInterno.infoAutor = contribuinte.documento;
    this.dialogRef.close(this.opcaoAssuntoInterno);
  }

  public pesquisar() {
    if (this.cnpfCnpjv.invalid) {
      this.messageService.messageErro("CPF/CNPJ Inválido");
      return;
    }

    // tslint:disable-next-line:quotemark
    this.contribuinteService.findByDocumento(this.cnpfCnpjv.value.replace(/\D/g, '')).subscribe(
      (contribuinte) => {
        if (contribuinte == null) {
          this.disabled = false;
          this.contribuinte = new Contribuinte();
          this.contribuinte.local = true;
          this.contribuinte.documento = this.cnpfCnpjv.value;
          this.titulo = "Cadastrar Contribuinte";
          this.messageService.messageWarning("Cadastro não localizado. Por favor, preencha os campos para prosseguir com o cadastro.");
          this.disabledDocumento = true;
        } else {
          this.titulo = "Contribuinte encontrado";
          this.contribuinte = contribuinte;
          // this.contribuinte.documento = this.cnpfCnpjv.value;
          this.disabled = false;
          this.disabledDocumento = true;
        }
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      });
  }

  public verificaCNPJ() {
    // tslint:disable-next-line:triple-equals
    if (this.contribuinte.documento.length == 18) {
      // tslint:disable-next-line:quotemark
      const documento = this.contribuinte.documento.replace(/\D/g, '');
      // tslint:disable-next-line:max-line-length
      this.contribuinte.documento = documento.substr(0, 2) + "." + documento.substr(2, 3) + "." + documento.substr(5, 3) + "/" + documento.substr(8, 4) + "-" + documento.substr(12, 2);
    }
  }

  public limpar() {
    this.titulo = "Pesquisar Contribuinte";
    this.disabled = true;
    this.disabledDocumento = false;
  }

  public fechar() {
    this.disabled = true;
    this.disabledDocumento = false;
  }

  public onEnterPequisar(event) {
    if (event.key === "Enter") {
      this.pesquisar();
    }
  }

  public onEnter(event) {
    if (event.key === "Enter") {
      this.confirm();
    }
  }

  verificarMatrizAtiva(contribuinte: Contribuinte[]){
    const cnpjDigitado = JSON.stringify(this.cnpfCnpjv.value.replace(/\D/g, ""));
    let matrizAtiva = false;
    contribuinte.forEach( contribuinte => {
      if (cnpjDigitado === JSON.stringify(contribuinte.documento)){
        matrizAtiva = true;
      }
    });

    return matrizAtiva;
  }

  criarContribuinteMatrizInativa(nome: string) {
    const contribuinteMatrizInativa = new Contribuinte;
    contribuinteMatrizInativa.documento = this.cnpfCnpjv.value.replace(/\D/g, "");
    contribuinteMatrizInativa.matrizInativa = true;
    contribuinteMatrizInativa.nome = nome;

    return contribuinteMatrizInativa;
  }

  exibirFormularioCadastro() {
    this.disabled = false;
    this.contribuinte = new Contribuinte();
    this.contribuinte.local = true;
    this.contribuinte.documento = this.cnpfCnpjv.value;
    this.titulo = "Cadastrar Contribuinte";
    this.messageService
    .messageWarning("Cadastro não localizado. Por favor, preencha os campos para prosseguir com o cadastro.");
    this.disabledDocumento = true;
  }

  exibirFormularioDesabilitado(contribuinte: Contribuinte) {
    this.titulo = "Contribuinte encontrado";
    this.contribuinte = contribuinte;
    this.disabled = false;
    this.disabledDocumento = true;
  }

}