import { Inject, Injectable } from "@angular/core";

import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Processo } from "../models/processo.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ItemNaoSimilaridade } from "../models/item-nao-similaridade.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({
  providedIn: "root"
})
export class EventoService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "evento", config);
    this.API_URL = config.API_PROCESSO_URL;
  }

  public getEventosItemNaoSimilaridade(processo: Processo): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/processo/${processo.id}`).pipe(map(response => response));
   }

  public desfazerItemNaoSimilaridade(processo: Processo, itensSelecionados: ItemNaoSimilaridade[], justificativa: string) {
    const idsItens: number[] = [];
    itensSelecionados.forEach(item => idsItens.push(item.id));
    return this.http.post(`${this.API_URL}${this.resource}/item-nao-similaridade/desfazer-marcados/${processo.id}/${idsItens}`, justificativa)
      .pipe(map(response => response));
  }

}