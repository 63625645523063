import { Instituidor } from "./../../models/pensao/instituidor.model";
import { COMMA, ENTER, FF_SEMICOLON, SEMICOLON, TAB } from "@angular/cdk/keycodes";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatChipInputEvent } from "@angular/material";
import moment from "moment";
import { Observable } from "rxjs";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { InstituidorService } from "../../api/instituidor.service";
import { ProcessoService } from "../../api/processo.service";
import { SgprevService } from "../../api/sgprev.service";
import { CondicoesParaTrazerDocs } from "../../models/pensao/condicoes-para-trazer-docs.model";
import { DocumentosRegra } from "../../models/pensao/documentos-regra";
import { Endereco } from "../../models/pensao/endereco.model";
import { FalecidoEm, FalecidoEmEnumAll } from "../../models/pensao/falecido-em.enum";
import { Vinculo } from "../../models/pensao/vinculo.model";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { UploadDocumentoComponent } from "../upload-documento/upload-documento.component";
import { map, startWith } from "rxjs/operators";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { ProcessoPensaoDTO } from "../../models/pensao/processo-pensao-dto.model";
import { Assunto } from "../../models/assunto.model";
import { AssuntoDTO } from "../../models/assunto-dto.model";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-input-dados-instituidor",
  templateUrl: "./input-dados-instituidor.component.html",
  styleUrls: ["./input-dados-instituidor.component.css"],
})
export class InputDadosInstituidorComponent implements OnInit {
  @ViewChild("uploadDocumentoInstituidorComponent") uploadDocumentoInstituidorComponent: UploadDocumentoComponent;
  @Output() instituidorEventEmitter = new EventEmitter<Instituidor>();

  public instituidor: Instituidor = new Instituidor();
  public idTipoPensao;
  public ufs: string[] = Utils.getUfs();
  public formGroupInstituidor: FormGroup;
  public documentosRegra: DocumentosRegra[] = [];
  public listFalecidoEm: any[] = FalecidoEmEnumAll;
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];
  public nowDate = new Date();
  habilaCampoMatricula: boolean = false;

  // Propriedades do compenente "chips"
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB, SEMICOLON, FF_SEMICOLON];
  orgaosOrigem: any[] = [];
  orgaosOrigemVindoDoEndpoint: any[] = [];
  matriculasCtrl = new FormControl(Validators.required);
  matriculasFiltradas: Observable<string[]>;
  matriculasSelecionadas: string[] = [];
  public matriculas: string[];
  @ViewChild("matriculasInput") matriculasInput: ElementRef<HTMLInputElement>;

  constructor(
    protected route: ActivatedRoute,
    private transfereService: TransfereService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private instituidorService: InstituidorService,
    private processoService: ProcessoService,
    private sgprevService: SgprevService,
    private cd: ChangeDetectorRef
  ) {
    if (this.route.snapshot.queryParams.idTipoPensao == 5) {
      this.formGroupInstituidor = this.fb.group({
        nome: ["", Validators.required],
        sexo: ["", Validators.required],
        dataObito: ["", Validators.required],
        cpf: ["", Validators.required],
        rg: [""],
        rgOrgaoEmissor: [""],
        rgUf: [""],
        estadoCivil: ["", Validators.required],
        orgaoOrigem: ["", Validators.required],
        cargo: ["", Validators.required],
        falecidoEm: ["", Validators.required],
        mortePresumida: ["", Validators.required],
        morteAcidental: ["", Validators.required],
        dataNascimento: ["", Validators.required],
      });
    } else {
      this.formGroupInstituidor = this.fb.group({
        nome: ["", Validators.required],
        sexo: ["", Validators.required],
        dataObito: ["", Validators.required],
        cpf: ["", Validators.required],
        rg: [""],
        rgOrgaoEmissor: [""],
        rgUf: [""],
        estadoCivil: ["", Validators.required],
        orgaoOrigem: ["", Validators.required],
        cargo: ["", Validators.required],
        grupo: ["", Validators.required],
        falecidoEm: ["", Validators.required],
        obitoRelacionadoServico: ["", Validators.required],
        mortePresumida: ["", Validators.required],
        morteAcidental: ["", Validators.required],
        dataNascimento: ["", Validators.required],
      });
    }

    this.instituidor.vinculo = [];
    this.instituidor.matriculas = [];
    this.instituidor.matricula = "";
    this.habilaCampoMatricula = false;
    this.orgaosOrigem = [];

    /*this.orgaosOrigemVindoDoEndpoint = [
      { codigo: 1, localSigla: "SEFAZ" },
      { codigo: 2, localSigla: "CEARAPREV" },
      { codigo: 3, localSigla: "TCE" },
    ];*/
  }

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "F2") this.preencher();
  }

  ngOnInit() {
    this.idTipoPensao = this.route.snapshot.queryParams.idTipoPensao;
  }

  emitInstituidor() {
    // this.instituidorEventEmitter.emit(this.instituidor);
  }

  validacaoCPFInstituidorSolicitante() {
    let solicitanteCPF = this.transfereService.getData().solicitante.cpf;

    if (solicitanteCPF === this.instituidor.cpf) {
      this.instituidor.cpf = "";

      this.messageService.messageWarning(
        "Não é possível adicionar o mesmo CPF do Instituidor como Solicitante nesta solicitação. Digite outro CPF."
      );
      return false;
    }
  }

  getDocumentosRegra() {
    if (!this.formGroupInstituidor.invalid && this.instituidor.matricula.length > 0) {
      if (this.validaCamposInstituidor()) {
        this.instituidor.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
        this.instituidor.condicoesParaTrazerDocs = this.instituidor.condicoesParaTrazerDocs.copiarAtributosInstituidor(this.instituidor);
        this.instituidor.documentosRegra = [];
        this.instituidor.documentosFuncionais = [];
        this.validarProcessoMotorRegras();
      }
    }
  }

  public setOrgaoOrigem(sigla: string) {
    this.instituidor.orgaoOrigem = sigla;
  }

  public setMatricula(matricula: string) {
    this.instituidor.matricula = matricula;
    this.getDocumentosRegra();
  }

  validaCamposInstituidor() {
    var nasciData = this.instituidor.dataNascimento.toString();
    var nascimento = nasciData.split("-");
    var ano = parseInt(nascimento[0]);
    var mes = parseInt(nascimento[1]);
    var dia = parseInt(nascimento[2]);

    var verificaMaiorNasc = new Date(ano + 18, mes - 1, dia - 1);
    var verificaMaiorObito = new Date(this.instituidor.dataObito);

    if (this.instituidor.dataObito < nasciData) {
      this.messageService.messageErro("A data de obito não deve anteceder a data de nascimento.");
      this.uploadDocumentoInstituidorComponent.update([]);
      return false;
    }

    if (this.verificarMenoridade(nasciData) === true) {
      this.messageService.messageErro("O Instituidor precisa ser maior de idade.");
      this.uploadDocumentoInstituidorComponent.update([]);
      return false;
    }

    if (verificaMaiorNasc > verificaMaiorObito) {
      this.messageService.messageErro("O Instituidor não é maior de idade. Verifique a data de óbito digitada");
      this.uploadDocumentoInstituidorComponent.update([]);
      return false;
    }

    return true;
  }

  private verificarMenoridade(dataNascimento: string): boolean {
    var ageDifMs = Date.now() - Date.parse(dataNascimento);
    var ageDate = new Date(ageDifMs);
    let result = Math.abs(ageDate.getUTCFullYear() - 1970);

    return result < 21 ? true : false;
  }

  validarProcessoMotorRegras() {
    let regrasProcessos = this.montarRegraProcesso();
    this.documentosRegra = [];

    this.processoService.regrasProcesso(regrasProcessos).subscribe(
      (res) => {
        this.instituidor.documentosRegra = res.processos[0].instituidor.documentosObrigatorios;
        this.instituidor.documentosRequeridosAposAbertura = res.processos[0].instituidor.documentosRequeridosAposAbertura;
        //this.instituidor.documentosFuncionais = res.processos[0].instituidor.documentosFuncionaisObrigatorios;
        this.instituidor.documentosFuncionaisObrigatorios = res.processos[0].instituidor.documentosFuncionaisObrigatorios;
        this.instituidor.documentosFuncionaisFacultativos = res.processos[0].instituidor.documentosFuncionaisFacultativos;
        // this.emitInstituidor();
        this.instituidor.documentosRegra.forEach((documentosObrigatorios) => {
          this.documentosRegra.push(documentosObrigatorios);
        });

        this.instituidor.documentosRequeridosAposAbertura.forEach((documentosRequeridosAposAbertura) => {
          this.documentosRegra.push(documentosRequeridosAposAbertura);
        });

        // this.instituidor.documentosFuncionais.forEach((documentosFuncionais) => {
        //   this.documentosRegra.push(documentosFuncionais);
        // });

        this.instituidor.documentosFuncionaisObrigatorios.forEach((documentosFuncionaisObrigatorios) => {
          this.documentosRegra.push(documentosFuncionaisObrigatorios);
        });

        this.instituidor.documentosFuncionaisFacultativos.forEach((documentosFuncionaisFacultativos) => {
          this.documentosRegra.push(documentosFuncionaisFacultativos);
        });

        let listaNaoDuplicada = [];
        this.documentosRegra.forEach((doc) => {
          let exist = listaNaoDuplicada.filter((el) => el.id === doc.id);
          if (exist.length == 0) listaNaoDuplicada.push(doc);
        });
        this.documentosRegra = listaNaoDuplicada;
        this.uploadDocumentoInstituidorComponent.update(this.documentosRegra);
      },
      (error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos necessários do instituidor");
        throw error;
      }
    );
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    regraProcesso.instituidor = this.instituidor;
    this.instituidor.documentosFuncionaisObrigatorios = [];
    let regrasProcessos: RegrasProcesso[] = [regraProcesso];

    return regrasProcessos;
  }

  salvar() {
    this.instituidorService.salvar(this.instituidor).subscribe(
      (instituidorSalvo: Instituidor) => {
        this.instituidor.id = instituidorSalvo.id;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  obterOrgaosPorCpf(cpf: string) {
    this.orgaosOrigem = [];
    if (cpf != undefined && cpf != "" && cpf.length > 10) {
      this.sgprevService.getMatriculaPorCpf(cpf).subscribe(
        (vinculos: Vinculo[]) => {
          this.instituidor.vinculo = vinculos;
          vinculos.forEach((v) => {
            let siglaJaAdicionada = this.orgaosOrigem.filter((siglaOrgao) => v.localSigla === siglaOrgao);
            if (siglaJaAdicionada === undefined || siglaJaAdicionada.length === 0) this.orgaosOrigem.push({ localSigla: v.localSigla });
            this.obterMatriculasPorOrgao();
          });
          if (vinculos.length === 0) {
            this.instituidor.matriculas = [];
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    }
  }

  obterMatriculasPorOrgao() {
    this.instituidor.matriculas = [];
    this.instituidor.matricula = "";
    if (this.instituidor.vinculo != undefined && this.instituidor.vinculo.length > 0) {
      let vinculoSelecionado: Vinculo[] = this.instituidor.vinculo.filter((v) => {
        return v.localSigla;
      });
      vinculoSelecionado.forEach((m) => {
        this.instituidor.matriculas.push(m.matricula);
      });
    }
  }

  informarMatricula() {
    let matriculaInformada: any[] = [];
    matriculaInformada.push(this.instituidor.matriculas);
    this.instituidor.matriculas = [];
    this.instituidor.matriculas = matriculaInformada;
  }

  preencher() {
    //Instituidor
    if (!this.instituidor.pensaoMilitar) {
      this.instituidor.grupo = "1";
      this.instituidor.obitoRelacionadoServico = false;
    }

    this.instituidor.cargo = "ADM";
    this.instituidor.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
    this.instituidor.condicoesParaTrazerDocs.morteAcidental = false;
    this.instituidor.condicoesParaTrazerDocs.mortePresumida = false;
    this.instituidor.condicoesParaTrazerDocs.pensaoMilitar = false;
    this.instituidor.cpf = "03162761387";
    this.instituidor.dataNascimento = new Date("1980-01-05");
    this.instituidor.dataObito = "2021-12-26";
    this.instituidor.estadoCivil = "Casado";
    this.instituidor.falecidoEm = "1";
    this.instituidor.grupo = "1";
    this.instituidor.matricula = "";
    this.instituidor.morteAcidental = false;
    this.instituidor.mortePresumida = false;
    this.instituidor.nome = "INSTITUIDOR";
    this.instituidor.obitoRelacionadoServico = false;
    this.instituidor.orgaoOrigem = "SEFAZ";
    //this.instituidor.pensaoMilitar = false;
    this.instituidor.rg = "5654645636565";
    this.instituidor.rgOrgaoEmissor = "SSP";
    this.instituidor.rgUf = "CE";
    this.instituidor.sexo = "M";
    this.instituidor.vinculo = [
      {
        checked: false,
        local: "EMPRESA DE ASSISTÊNCIA TÉCNICA E EXTENSÃO RURAL DO CEARÁ",
        localSigla: "EMATERCE",
        matricula: "00020311",
      },
      {
        checked: false,
        local: "FUNDAÇÃO UNIVERSIDADE ESTADUAL DO CEARÁ",
        localSigla: "FUNECE",
        matricula: "00785415",
      },
      {
        checked: false,
        local: "AGÊNCIA DE DEFESA AGROPECUÁRIA DO ESTADO DO CEARÁ",
        localSigla: "ADAGRI",
        matricula: "19981819",
      },
    ];

    this.obterOrgaosPorCpf(this.instituidor.cpf);
    this.tipoPensao();
    this.emitInstituidor();
    this.getDocumentosRegra();
  }

  tipoPensao() {
    if (this.idTipoPensao === "5") {
      this.instituidor.condicoesParaTrazerDocs.pensaoMilitar = true;
      this.instituidor.pensaoMilitar = true;
    } else {
      this.instituidor.condicoesParaTrazerDocs.pensaoMilitar = false;
      this.instituidor.pensaoMilitar = false;
    }
  }

  verificarMudancaCpf(cpf: string) {
    cpf = cpf.replace(/[.*+\-?^${}()|[\]\\]/g, "").trim();

    if (cpf.length < 11) this.instituidor.matriculas = [];

    if (cpf.length === 11 && !this.formGroupInstituidor.controls["cpf"].invalid) {
      this.obterOrgaosPorCpf(cpf);
      this.validacaoCPFInstituidorSolicitante();
      this.getDocumentosRegra();
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    if (value) {
      const index = this.instituidor.matriculas.indexOf(value);
      if (index >= 0) return;

      this.instituidor.matriculas.push(value);
    }

    event.input.value = null;
    this.matriculasCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.instituidor.matriculas.indexOf(fruit);

    if (index >= 0) {
      this.instituidor.matriculas.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.instituidor.matriculas.indexOf(event.option.value);
    if (index >= 0) return;

    this.instituidor.matriculas.push(event.option.value);
    this.matriculasInput.nativeElement.value = "";
    this.matriculasCtrl.setValue(null);
    this.getDocumentosRegra();
  }

  // verificaProcessoExistente() {
  //   const processoPensaoDTO: ProcessoPensaoDTO = new ProcessoPensaoDTO();
  //   processoPensaoDTO.solicitante = this.transfereService.getData().solicitante;

  //   processoPensaoDTO.instituidor = this.instituidor;
  //   this.processoService.verificaExistenciaProcesso(processoPensaoDTO).subscribe(
  //     () => {
  //       this.getDocumentosRegra();
  //     },

  //     (error) => {
  //       this.messageService.messageErro(error.error);
  //       this.uploadDocumentoInstituidorComponent.update([]);

  //       throw error;
  //     }
  //   );
  // }
}
