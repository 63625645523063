export enum AcaoEventoItemNaoSimilaridadeEnum {
    ADD = ("ITEM ADICIONADO"),
	MOD = ("SITUAÇÃO DO ITEM ALTERADA"),
	DEL = ("ITEM EXCLUÍDO")
}

export const AcaoEventoItemNaoSimilaridadeEnumAll = [
    { id: 0, descricao: AcaoEventoItemNaoSimilaridadeEnum.ADD },
    { id: 1, descricao: AcaoEventoItemNaoSimilaridadeEnum.MOD },
    { id: 2, descricao: AcaoEventoItemNaoSimilaridadeEnum.DEL }
  ];