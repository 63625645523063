import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { AutenticacaoService } from "../api/seguranca/autenticacao.service";
import { Observable } from "rxjs";

@Injectable()
export class CertificateResolver implements Resolve<any> {
  constructor(private autenticacaoService: AutenticacaoService) { }

  resolve(): any {
    // return this.autenticacaoService.getCertificates();
  }
}