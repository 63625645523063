import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PerfilService } from 'src/app/core/api/seguranca/perfil.service';
import { Perfil } from 'src/app/core/models/seguranca/perfil.model';
import { SharedService } from 'src/app/global/api/shared.service';
import ListarGenericsComponent from 'src/app/global/generics/listar-generics-component';
import { MessageService } from 'src/app/global/message/message.service';

@Component({
  selector: 'sgeap-listar-perfil',
  templateUrl: './listar-perfil.component.html',
  styleUrls: ['./listar-perfil.component.css']
})
export class ListarPerfilComponent extends ListarGenericsComponent<Perfil> {

  constructor(public apiService: PerfilService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "perfil",
      ["nome", "editar"]);
  }

  manterPermissoes(domain: Perfil): void {
    this.router.navigate([`${this.contexto}/${this.endpoint}/manter-permissoes/${domain.id}`]);
  }


}
