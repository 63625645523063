import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "src/app/material";
import { InputComponent } from "./input.component";
import { InputDirective } from "./input.directive";

@NgModule({
  imports: [ CommonModule, MaterialModule, ReactiveFormsModule ],
  declarations: [ InputComponent, InputDirective ]
})
export class InputModule { }
