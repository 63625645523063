import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DialogProcessoComponent } from "../dialog-processo/dialog-processo.component";
import { FaseService } from "../../api/fase.service";
import { Fase } from "../../models/fase.model";
import { ProcessoService } from "../../api/processo.service";
import { MessageService } from "../../../global/message/message.service";
import { MovimentacaoDTO } from "../../models/movimentacao-dto.model";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-dialog-alterar-fase",
  templateUrl: "./dialog-alterar-fase.component.html",
  styleUrls: ["./dialog-alterar-fase.component.css"],
})
export class DialogAlterarFaseComponent implements OnInit {
  public fases: Fase[] = [];
  public fase: Fase;
  public observacao: string;
  public titulo: string;
  constructor(
    public dialogRef: MatDialogRef<DialogProcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private faseService: FaseService,
    private processoService: ProcessoService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {
    this.fase = new Fase();
  }

  ngOnInit() {
    this.titulo = this.data.arquivar === true ? "DESARQUIVAMENTO" : "ALTERAR FASE";
    this.faseService.list().subscribe(
      (fases) => {
        this.fases = fases.filter(function (fase) {
          return !fase.situacaoPosVisualizacao.situacaoFinal && fase.ativo;
        });
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  alterarFase() {
    if (this.validar()) {
      const movimentacaoDTO = new MovimentacaoDTO();
      movimentacaoDTO.faseId = this.fase.id;
      movimentacaoDTO.docAutor = this.sharedService.decodePayloadJWT().sub;
      movimentacaoDTO.observacao = this.observacao;
      if (this.data.arquivar) {
        movimentacaoDTO.responsavel = this.sharedService.decodePayloadJWT().sub;
        this.processoService.desarquivarProcesso(movimentacaoDTO, this.data.processo.id).subscribe(
          (res) => {
            this.messageService.messageSuccess("Processo desarquivado com sucesso", res.etiquetaProcessoViproc);
            this.data.processo.listMovimentacao = res.listMovimentacao;
            this.dialogRef.close(this.data);
          },
          (error) => {
            this.messageService.messageErro(error.error.message, "Erro no desarquivamento");
            throw error;
          }
        );
      } else {
        this.processoService.movimentar(movimentacaoDTO, this.data.processo.id).subscribe(
          (res) => {
            this.messageService.messageSuccess("Fase alterada com sucesso", res.etiquetaProcessoViproc);
            this.data.processo.listMovimentacao = res.listMovimentacao;
            this.dialogRef.close(this.data);
          },
          (error) => {
            this.messageService.messageErro("Erro na movimentação" + error.error.message);
            throw error;
          }
        );
      }
    }
  }

  validar(): boolean {
    let validacao = true;

    if (!this.fase.id) {
      this.messageService.messageErro("Selecione o campo Fase.");
      validacao = false;
    }
    return validacao;
  }
}
