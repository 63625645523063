import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SalvarGenericsComponent } from "src/app/global/generics/salvar-generics-component";
import { CategoriaAssuntoService } from "src/app/core/api/categoria-assunto.service";
import { AssuntoCategoria } from "src/app/core/models/assunto-categoria.model";
import iconJson from "../../../../global/util/icons.json";
import { AssuntoCategoriaDTO } from "src/app/core/models/assunto-categoria-dto.model.js";
import { MessageService } from "src/app/global/message/message.service";
import { debounceTime, tap, switchMap, finalize } from "rxjs/operators";
import { CategoriaAssuntoPesquisa } from "src/app/core/models/categoriaAssuntoPesquisa.js";
import { Observable } from "rxjs";

@Component({
  selector: "app-salvar-assunto-categoria",
  templateUrl: "./salvar-assunto-categoria.component.html",
  styleUrls: ["./salvar-assunto-categoria.component.css"],
})
export class SalvarAssuntoCategoriaComponent extends SalvarGenericsComponent<AssuntoCategoriaDTO> implements OnInit {
  public assuntoCategoriaList: AssuntoCategoria[] = [];
  public assuntoCategoriaAutoCompleteList: any[];
  public assuntoCategoriaPai: AssuntoCategoria;
  public icons: any[];
  public sections = [];
  public buttons = [];

  searchRespCtrl = new FormControl();
  isLoading = false;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public categoriaAssuntoService: CategoriaAssuntoService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public messageService: MessageService
  ) {
    super(formBuilder, router, categoriaAssuntoService, messageService, route, "assunto-categoria", "");
  }

  ngOnInit() {
    const mapped = JSON.parse(JSON.stringify(iconJson));
    this.icons = Object.keys(mapped).map((key) => ({ type: key, value: mapped[key] }));
    this.assuntoCategoriaPai = new AssuntoCategoria("");
    this.consultar("").subscribe(
      (data) => {
        if (data.content) {
          data.content.unshift(new AssuntoCategoria("Não possui Pai"));
          this.assuntoCategoriaAutoCompleteList = data.content;
        } else {
          data.unshift(new AssuntoCategoria("Não possui Pai"));
          this.assuntoCategoriaAutoCompleteList = data;
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.autoCompleteDescricaoAssuntoCategoria();

    this.buttons = [
      {
        label: "Salvar",
        color: "primary",
        click: () => this.salvarValidator(this.domain),
      },
      {
        label: "Cancelar",
        color: "secondary",
        click: () => this.router.navigateByUrl("/sgeap/assunto-categoria"),
      },
    ];

    this.sections = [
      {
        fields: [
          [
            {
              key: "descricao",
              required: true,
              maxlength: 255,
              fieldType: "textInput",
              label: "Descrição",
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "icone",
              required: true,
              fieldType: "selectInput",
              label: "Icone",
              labelKey: "value",
              valueKey: "value",
              options: () => this.icons,
              parseItem: () => "",
              renderRightIcon: (e) => e.value,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "AssuntoCategoriaPai",
              required: true,
              fieldType: "selectAutoCompleteInput",
              loading: () => this.isLoading,
              label: "Assunto Categoria Pai",
              formControl: this.searchRespCtrl,
              ngModel: () => this.assuntoCategoriaPai.descricao,
              click: (e) => this.assuntoCategoriaPaiSelecionado(e),
              labelKey: "descricao",
              valueKey: "descricao",
              options: () => this.assuntoCategoriaAutoCompleteList,
              cols: [12, 12, 12, 6, 6],
            },
            {
              key: "ativo",
              required: true,
              fieldType: "radioInput",
              label: "Ativo*",
              options: [
                { value: true, label: "Sim" },
                { value: false, label: "Não" },
              ],
              cols: [12, 12, 12, 6, 6],
            },
          ],
        ],
      },
    ];
  }

  private autoCompleteDescricaoAssuntoCategoria() {
    this.searchRespCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.assuntoCategoriaAutoCompleteList = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.consultar(value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data) => {
          if (data.content) {
            data.content.unshift(new AssuntoCategoria("Não possui Pai"));
            this.assuntoCategoriaAutoCompleteList = data.content.filter((categoria) => categoria.ativo);
          } else {
            data.unshift(new AssuntoCategoria("Não possui Pai"));
            this.assuntoCategoriaAutoCompleteList = data.filter((categoria) => categoria.ativo);
          }
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
  }

  private consultar(value: string): Observable<any> {
    const categoriaAssuntoPesquisa = new CategoriaAssuntoPesquisa();
    categoriaAssuntoPesquisa.descricao = value;
    if (value == undefined || value == "") {
      return this.categoriaAssuntoService.list();
    }
    return this.categoriaAssuntoService.consultar(categoriaAssuntoPesquisa, 0, 100);
  }

  validar(domain: any): boolean {
    let validacao = true;

    if (!domain.descricao || !domain.descricao.trim()) {
      this.messageService.messageErro("Preencha o campo descrição.");
      validacao = false;
    }
    if (domain.icone == null) {
      this.messageService.messageErro("Selecione um Icone.");
      validacao = false;
    }

    if (domain.ativo == null) {
      this.messageService.messageErro("Selecione o campo Ativo.");
      validacao = false;
    }
    return validacao;
  }

  assuntoCategoriaPaiSelecionado(assuntoCategoria: AssuntoCategoria) {
    this.assuntoCategoriaPai = assuntoCategoria;
  }

  salvarValidator(domain: any): void {
    if (this.validar(domain)) {
      if (this.assuntoCategoriaPai != null) {
        this.domain.assuntoCategoriaId = this.assuntoCategoriaPai.id;
      }
      this.submeter();
    }
  }

  afterResponse() {
    this.assuntoCategoriaAutoCompleteList = [];
    this.assuntoCategoriaAutoCompleteList[0] = new AssuntoCategoria("Não possui Pai");
    if (this.domain != null && this.domain.assuntoCategoriaId != null) {
      this.categoriaAssuntoService.get(this.domain.assuntoCategoriaId).subscribe((assuntoCategoriaPai) => {
        this.assuntoCategoriaPai = assuntoCategoriaPai;
        this.assuntoCategoriaAutoCompleteList[1] = this.assuntoCategoriaPai;
      });
    }
  }
}
