import { ApiService } from "./api.service";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Responsavel } from "../models/responsavel.model";
import { Contribuinte } from "../models/contribuinte.model";
import { AppConfig, APP_CONFIG } from "src/app.config";


@Injectable({ providedIn: "root" })
export class ContribuinteService extends ApiService {

  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "contribuinte", config);
    this.API_URL = config.API_CADASTRO_URL;
  }

  public findByDocumento(documento: string): Observable<any> {
      return this.http.get(`${this.API_URL}${this.resource}/${documento.replace(/[\(\)\.\s-]+/g, "")}`).
      pipe(map(response => response));
  }

  public updateContribuinte(body?: { [index: string]: any } ): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/${body.documento}`, body).pipe(map(response => response));
  }

  public findResponsavelPorDocumento(documento: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/servidor/${documento}`).pipe(map((response: Contribuinte) => response));
  }

  public loginContribuinte(opcao: string, documento: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/login-contribuinte/${opcao}/${documento}`).pipe(map(response => response));
  }

  //Buscar partes envolvidas no processo
  // @param parteNome - Nome da parte 
  //
  public buscarPartePorNome(parteNome: string): Observable<any> {
    let params = new HttpParams().set('nome', parteNome);
    return this.http.get(`${this.API_URL}${this.resource}/listar-por-nome`,{params: params}).pipe(map(response => response));
  }

  public pesquisarCadastroContribuinte(documento: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/cadastro/${documento}`).pipe(map(response => response));
  }

  public findServidorEAutorProcesso(docAutor: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/servidores-autor/${docAutor}`).pipe(map(response => response));
  }

  public findServidoresAberturaProcesso(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/servidoresAberturaProcesso`).pipe(map(response => response));
  }

  public findContribuintes(): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/contribuintes`).pipe(map(response => response));
  }

  public atualizarAssinadorFavorito(responsavel: Responsavel): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/assinador-favorito`, responsavel).pipe(map(response => response));
  }

  public consultaContruintePorCNPJBase(documento: any): Observable<Contribuinte[]>{
    return this.http.get(`${this.API_URL}${this.resource}/consulta-por-cnpj-base/${documento}`).pipe(map((response: Contribuinte[]) => response));
  }

  public verificarContribuinteAtivo(documento: string):Observable<boolean> {
    return this.http.get(`${this.API_URL}${this.resource}/consulta-cadastro-contribuinte-ativo/${documento}`).pipe(map((response: boolean) => response));
  }
}