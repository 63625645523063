import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MessageService } from "../../../global/message/message.service";
import { SharedService } from "../../api/shared.service";
import { Router } from "@angular/router";


@Component({
  selector: "app-dialog-contribuinte-siget",
  templateUrl: "./dialog-contribuinte-siget.component.html",
  styleUrls: ["./dialog-contribuinte-siget.component.css"]
})
export class DialogContribuinteSigetComponent implements OnInit {
    public contribuintes: any[];
    public contribuinte: any;

  constructor(
    public dialogRef: MatDialogRef<DialogContribuinteSigetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
          private router: Router,
          private messageService: MessageService,
          private sharedService: SharedService) {
    }


  ngOnInit() {
    this.contribuintes = this.data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selecionarContribuinte() {
    this.sharedService.setDocumentoRepresentado(this.contribuinte.documento);
    this.router.navigate(["home"]);
  }

}
