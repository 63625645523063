import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriaAssuntoService } from "../../api/categoria-assunto.service";
import { AssuntoService } from "../../api/assunto.service";
import { AssuntoCategoriaDTO } from "../../models/assunto-categoria-dto.model";

@Component({
  selector: "app-listar-filhos-categoria-assunto",
  templateUrl: "./listar-filhos-categoria-assunto.component.html",
  styleUrls: ["./listar-filhos-categoria-assunto.component.css"]
})
export class ListarFilhosCategoriaAssuntoComponent implements OnInit {
  @Input() categoriaAssuntos: AssuntoCategoriaDTO[];
  @Output() respostaCarregarFilhosAssuntoCategoria = new EventEmitter();

  constructor(public route: ActivatedRoute,
    public categoriaAssuntoService: CategoriaAssuntoService,
    public assuntoService: AssuntoService,
    public router: Router
  ) { }

  ngOnInit() {
  }

  carregarFilhosCategoriaAssunto(assuntoCategoriaDTO: AssuntoCategoriaDTO) {
    this.respostaCarregarFilhosAssuntoCategoria.emit(assuntoCategoriaDTO);
  }
}
