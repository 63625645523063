import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "src/app/global/api/shared.service";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { TransfereService } from "src/app/global/interceptors/transfer.service";
import { MessageService } from "src/app/global/message/message.service";
import { CondicoesParaTrazerDocs } from "../../models/pensao/condicoes-para-trazer-docs.model";
import { RequerenteService } from "../../api/requerente.service";
import { Endereco } from "../../models/pensao/endereco.model";
import { Representante } from "../../models/pensao/representante.model";
import { Requerente } from "../../models/pensao/requerente.model";
import { Solicitante } from "../../models/pensao/solicitante.model";
import { TipoRepresentanteEnumAll, TipoRepresentanteFunctions } from "../../models/pensao/tipo-representante.enum";
import { TipoRequerenteEnumAll, TipoRequerenteEnumFunctions, TipoRequerenteMilitar } from "../../models/pensao/tipo-requerente.enum";
import { TipoSolicitanteEnumAll, TipoSolicitanteFunctions } from "../../models/pensao/tipo-solicitante.enum";
import { UploadDocumentoComponent } from "../upload-documento/upload-documento.component";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ProcessoService } from "../../api/processo.service";
import { RegrasProcesso } from "../../models/regras-processo.model";
import { Utils } from "src/app/global/util/utils-component";
import { EnderecoService } from "../../api/endereco.service";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { DialogConfirmarComponent } from "../../processo/dialog-confirmar/dialog-confirmar.component";
import { I } from "@angular/cdk/keycodes";
import { Assunto } from "../../models/assunto.model";

@Component({
  selector: "app-input-dados-requerente",
  templateUrl: "./input-dados-requerente.component.html",
  styleUrls: ["./input-dados-requerente.component.css"],
})
export class InputDadosRequerenteComponent extends ListarGenericsComponent<Requerente> implements OnInit {
  @ViewChild("uploadDocumentoRequerenteComponent") uploadDocumentoRequerenteComponent: UploadDocumentoComponent;
  @Output() requerenteEventEmitter = new EventEmitter<Requerente[]>();
  @Input() solicitante: Solicitante;
  @Input() instituidor: Instituidor;

  @Input() assunto: Assunto = new Assunto();

  public idTipoPensao;
  public contRequerentes;
  public tiposSolicitante: any[] = TipoSolicitanteEnumAll;
  public tiposRepresentantes: any[] = TipoRepresentanteEnumAll;
  public tiposRequerente: any[] = TipoRequerenteEnumAll;
  public requerente: Requerente = new Requerente();
  public requerentes: Requerente[] = [];
  public exibirFormulario: boolean = true;
  private condicoesParaTrazerDocs: CondicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
  public ufs: string[] = Utils.getUfs();
  public formGroupRequerente: FormGroup;
  public maxDate = new Date().toJSON().split("T")[0];
  public minDate = new Date(1900, 1, 1).toJSON().split("T")[0];
  public listDocumentosPorMatricula: any[] = [];

  constructor(
    protected route: ActivatedRoute,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private transfereService: TransfereService,
    public messageService: MessageService,
    private requerenteService: RequerenteService,
    private processoService: ProcessoService,
    private enderecoService: EnderecoService,
    private cd: ChangeDetectorRef
  ) {
    super(processoService, messageService, sharedService, toastr, router, "tipo-documento", ["cpf", "acoes"]);
  }

  ngOnInit() {
    // inicia como primeiro requerente
    this.contRequerentes = 1;
    this.idTipoPensao = this.route.snapshot.queryParams.idTipoPensao;
    this.ordernarListaOrdemAlfabetica(this.tiposRequerente);
    this.ordernarListaOrdemAlfabetica(this.tiposSolicitante);
    this.ordernarListaOrdemAlfabetica(this.tiposRepresentantes);
    this.inicializarRequerente();
    this.dataSource = new MatTableDataSource<Requerente>(this.requerentes);
    this.dataSource.paginator = this.paginator;
    this.pensaMilitarFIltroTipoSolicitante();

    this.formGroupRequerente = this.fb.group({
      tipoRequerente: ["", Validators.required],
      nome: ["", Validators.required],
      cpf: ["", Validators.required],
      sexo: ["", Validators.required],
      dataNascimento: ["", Validators.required],
      rg: ["", Validators.required],
      rgOrgaoEmissor: ["", Validators.required],
      rgUf: ["", Validators.required],
      pisPasepNit: ["", Validators.nullValidator],
      estadoCivil: ["", Validators.required],
      dataCasamento: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      outroBeneficio: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      outroBeneficioUf: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      recebeOutroBeneficio: ["", Validators.required],
      pensaoDividida: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      possuiOutroDependente: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      possuiRelacionamento: new FormControl({ value: "", disabled: false }, Validators.required),
      universitario: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      tipoRelacionamentoCasamento: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      logradouro: ["", Validators.required],
      numero: ["", Validators.required],
      complemento: ["", Validators.nullValidator],
      bairro: ["", Validators.required],
      cep: ["", Validators.required],
      cidade: ["", Validators.required],
      uf: ["", Validators.required],
      telefone: ["", Validators.required],
      telefone2: ["", Validators.nullValidator],
      email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$")]),
      agencia: ["", Validators.nullValidator],
      conta: ["", Validators.nullValidator],
      observacao: ["", Validators.nullValidator],
      representNome: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representTipo: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representSexo: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representCpf: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representRg: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representOrgaoEmissor: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representRgUf: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representEstadoCivil: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representTelefone: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      representEmail: new FormControl({ value: "", disabled: false }, [
        Validators.nullValidator,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$"),
      ]),
      cepRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      logradouroRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      numeroRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      complementoRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      bairroRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      cidadeRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
      ufRepresentante: new FormControl({ value: "", disabled: false }, Validators.nullValidator),
    });
  }

  getDocumentosRegra() {
    if (this.validarCamposObrigatoriosDocumentosMotorRegras()) {
      this.requerente.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
      this.requerente.condicoesParaTrazerDocs = this.requerente.condicoesParaTrazerDocs.copiarAtributosRequerente(this.requerente);
      if (this.idTipoPensao === "5") {
        this.requerente.condicoesParaTrazerDocs.pensaoMilitar = true;
      } else {
        this.requerente.condicoesParaTrazerDocs.pensaoMilitar = false;
      }
      this.requerente.documentosRegra = [];
      this.requerente.documentosObrigatorios = [];
      if (!this.requerente.agencia || !this.requerente.conta) {
        this.dialogConfirmar();
      } else {
        this.validarProcessoMotorRegras();
      }
    }
  }

  dialogConfirmar() {
    const message =
      "ATENÇÃO! Os dados da conta corrente Bradesco do(a) requerente não foram informados. Esses dados são" +
      " essenciais para o pagamento da pensão por morte. Tem certeza que não quer informá-los agora?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.validarProcessoMotorRegras();
      }
    });
  }

  public ordernarListaOrdemAlfabetica(list: any[]): any {
    list.sort((obj1, obj2) => {
      if (obj1.descricao > obj2.descricao) {
        return 1;
      }
      if (obj1.descricao < obj2.descricao) {
        return -1;
      }
      return 0;
    });
    return list;
  }

  validarCamposObrigatoriosDocumentosMotorRegras(): boolean {
    if (
      this.requerente.recebeOutroBeneficio === true &&
      (this.requerente.beneficio === undefined ||
        this.requerente.beneficio === "" ||
        this.requerente.beneficioUf === undefined ||
        this.requerente.beneficioUf === "")
    )
      return false;

    if (this.requerente.tipoSolicitante === undefined || this.requerente.tipoSolicitante === "") return false;

    if (this.requerente.dataNascimento === undefined || this.requerente.dataNascimento === "") return false;

    if (this.requerente.dataNascimento.substring(0, 1) != "0") {
      let menorSelecionado = TipoRequerenteEnumFunctions.verificarMenorPorEnum(this.requerente.tipoSolicitante);
      if (menorSelecionado === true && this.verificarMenoridade(this.requerente.dataNascimento) === false) {
        this.messageService.messageWarning("O requerente não é menor, selecione outro vínculo com o Segurado-Instituidor");
        return false;
      }
      if (menorSelecionado === false && this.verificarMenoridade(this.requerente.dataNascimento) === true) {
        this.messageService.messageWarning("O requerente precisa ser maior de idade.");
        return false;
      }
    }

    if (this.requerente.dataCasamento <= this.requerente.dataNascimento.toString()) {
      this.messageService.messageErro("A data de casamento não deve anteceder a data de nascimento.");
      return false;
    }

    if (this.requerente.estadoCivil === undefined || this.requerente.estadoCivil === "") return false;

    if (
      (this.requerente.tipoSolicitante === "conjuge".toUpperCase() || this.requerente.tipoSolicitante === "companheiro".toUpperCase()) &&
      (this.requerente.dataCasamento === undefined || this.requerente.dataCasamento === "")
    )
      return false;

    if (this.requerente.recebeOutroBeneficio === undefined) return false;

    if (
      (this.requerente.tipoSolicitante === "ex_conjuge_pensao_alimenticia".toUpperCase() ||
        this.requerente.tipoSolicitante === "ex_companheiro_pensao_alimenticia".toUpperCase()) &&
      this.requerente.pensaoDividida === undefined
    )
      return false;

    if (
      this.ehMilitar() &&
      this.requerente.tipoSolicitante === "filho_menor_21_anos".toUpperCase() &&
      this.requerente.universitario === undefined
    )
      return false;

    if (
      (this.requerente.tipoSolicitante === "irmaos".toUpperCase() ||
        this.requerente.tipoSolicitante === "pai".toUpperCase() ||
        this.requerente.tipoSolicitante === "mae".toUpperCase() ||
        this.requerente.tipoSolicitante === "irmao_menor_21_anos".toUpperCase() ||
        this.requerente.tipoSolicitante === "irmao_invalido_ou_deficiente".toUpperCase()) &&
      this.requerente.possuiOutroDependente === undefined
    )
      return false;

    if (
      (this.requerente.tipoSolicitante != "pai".toUpperCase() || this.requerente.tipoSolicitante != "mae".toUpperCase()) &&
      this.requerente.possuiRelacionamento === undefined
    )
      return false;

    if (this.requerente.possuiRelacionamento === true && this.requerente.tipoRelacionamentoCasamento === undefined) return false;

    return true;
  }

  validarProcessoMotorRegras() {
    let regrasProcessos = this.montarRegraProcesso();
    let requerente = this.requerente;

    this.processoService.regrasProcesso(regrasProcessos).subscribe(
      (res) => {
        res.processos[0].requerentes.forEach((req) => {
          if (this.requerente.cpf === req.cpf) {
            this.requerente.documentosObrigatorios = req.documentosObrigatorios;
            this.requerente.documentosRequeridosAposAbertura = req.documentosRequeridosAposAbertura;
          }
        });

        this.requerente.documentosObrigatorios.forEach((documentosObrigatorios) => {
          this.requerente.documentosRegra.push(documentosObrigatorios);
        });

        this.requerente.documentosRequeridosAposAbertura.forEach((documentosRequeridosAposAbertura) => {
          this.requerente.documentosRegra.push(documentosRequeridosAposAbertura);
        });

        this.transfereService.getData().requerentes[0] = this.requerente;

        let listaNaoDuplicada = [];
        this.requerente.documentosRegra.forEach((doc) => {
          let exist = listaNaoDuplicada.filter((el) => el.id === doc.id);
          if (exist.length == 0) listaNaoDuplicada.push(doc);
        });
        requerente.documentosRegra = listaNaoDuplicada;
        this.uploadDocumentoRequerenteComponent.update(this.requerente.documentosRegra);
      },
      (error) => {
        this.messageService.messageErro("Não foi possivel carregar os documentos necessários do requerente");
        throw error;
      }
    );
  }

  private montarRegraProcesso(): RegrasProcesso[] {
    let regrasProcessos: RegrasProcesso[] = [];
    let regraProcesso: RegrasProcesso = new RegrasProcesso();
    regraProcesso.dataAberturaProcesso = new Date();
    regraProcesso.contRequerentes = this.contRequerentes;
    regraProcesso.requerentes = new Array<Requerente>();
    regraProcesso.requerentes.push(this.requerente);
    regraProcesso.requerentes.forEach((req) => {
      if (req.solicitanteTipo) {
        req.solicitanteTipo = req.solicitanteTipo.toUpperCase();
      }
    });
    regrasProcessos.push(regraProcesso);

    return regrasProcessos;
  }

  requerenteAtual() {
    return this.requerente;
  }

  resetDataTable() {
    this.listDocumentosPorMatricula = [];
    this.dataSource = new MatTableDataSource<any>([]);
    this.dataSource._updateChangeSubscription;
  }

  addRequerente() {
    if (!this.validarRequerente()) {
      throw new Error("Requerente inválido");
    }

    this.resetDataTable();

    this.requerente.documentos = this.uploadDocumentoRequerenteComponent.listDocumentos;
    this.listDocumentosPorMatricula.push.apply(
      this.listDocumentosPorMatricula,
      this.uploadDocumentoRequerenteComponent.listDocumentosPorMatricula
    );

    this.requerentes.push(this.requerente);
    this.dataSource = new MatTableDataSource<Requerente>(this.requerentes);
    this.requerente = new Requerente();
    this.dataSource._updateChangeSubscription;
    this.exibirFormulario = false;
    this.contRequerentes += 1;
  }

  addOutroRequerente() {
    this.inicializarRequerente();
    this.exibirFormulario = true;
  }

  inicializarRequerente() {
    this.requerente = new Requerente();
    this.requerente.endereco = new Endereco();
    this.requerente.condicoesParaTrazerDocs = new CondicoesParaTrazerDocs();
    this.requerente.representanteLegal = new Representante();
    this.requerente.representanteLegal.endereco = new Endereco();
  }

  validarRequerente() {
    if (this.formGroupRequerente.invalid) {
      const invalid = [];
      const controls = this.formGroupRequerente.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }

      this.messageService.messageWarning("Preencha todos os campos do requerente. * " + invalid);
      return false;
    }

    let cpfRepetido = this.requerentes.filter((r) => r.cpf === this.requerente.cpf).length > 0;

    if (this.requerente.cpf.length === 11 && this.requerente.cpf === this.instituidor.cpf) {
      this.requerente.cpf = "";
      this.messageService.messageWarning("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
      throw new Error("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
    }

    if (cpfRepetido) {
      this.messageService.messageWarning("Requerente já foi adicionado: " + this.requerente.cpf);
      return false;
    }

    if (this.requerente.tipoSolicitante === "" || this.requerente.tipoSolicitante === undefined) {
      this.messageService.messageWarning("Selecione o vínculo com o instituidor");
      return false;
    }

    if (!this.uploadDocumentoRequerenteComponent.validarDocumentos()) {
      this.messageService.messageWarning("Anexe todos os documentos obrigatórios do requerente");
      return false;
    }

    return true;
  }

  removerRequerente(requerente: Requerente) {
    // if (this.solicitante.cpf === requerente.cpf /*|| this.solicitante.representanteLegal === true*/) {
    //   this.messageService.messageWarning(
    //     "Não é possível remover o Solicitante quando o mesmo é um Requerente ou Representante legal nesta solicitação. Altere o solicitante para 'Não Requerente'."
    //   );
    // } else {
    this.requerentes.forEach((item, index) => {
      if (item === requerente) this.requerentes.splice(index, 1);
    });
    this.dataSource = new MatTableDataSource<Requerente>(this.requerentes);
    this.dataSource._updateChangeSubscription;

    if (this.requerentes.length == 0) {
      this.inicializarRequerente();
      this.exibirFormulario = true;
    }
    // }
  }

  async editarRequerente(requerente: Requerente) {
    this.removerRequerente(requerente);
    this.inicializarRequerente();
    this.exibirFormulario = true;
    this.requerente = requerente;
    if (this.solicitante.representanteLegal && this.requerente.tipoSolicitante != undefined) {
      this.requerente.necessitaRepresentante = TipoRequerenteEnumFunctions.verificarMenorEnumOuInvalido(this.requerente.tipoSolicitante);
      if (this.requerente.necessitaRepresentante) this.preencherRepresentante();
    }
    this.cd.detectChanges();
    await new Promise((done) =>
      setTimeout(() => {
        // this.uploadDocumentoRequerenteComponent.update(this.requerente.documentosRegra);
        this.uploadDocumentoRequerenteComponent.listDocumentos = this.requerente.documentos;
        this.requerente.documentos.forEach((d) => {
          this.uploadDocumentoRequerenteComponent.carregarArquivosSalvos(d, d, d.fileByte, d.descricao);
        });
      }, 1000)
    );
  }

  preencherComDadosSolicitante(solicitante: Solicitante) {
    if (solicitante === null) {
      this.inicializarRequerente();
      return;
    }

    let cpfRepetido = this.requerentes.filter((r) => r.cpf === this.requerente.cpf).length > 0;
    if (cpfRepetido) return;

    this.requerente.cpf = solicitante.cpf;
    this.requerente.nome = solicitante.nome;
    this.requerente.sexo = solicitante.sexo;
    this.requerente.estadoCivil = solicitante.estadoCivil;
    this.requerente.dataNascimento = solicitante.dataNascimento;
    this.requerente.rg = solicitante.rg;
    this.requerente.rgOrgaoEmissor = solicitante.rgOrgaoEmissor;
    this.requerente.rgUf = solicitante.rgUf;
    this.requerente.telefone = solicitante.telefone;
    this.requerente.email = solicitante.email;
    this.requerente.endereco = solicitante.endereco;
  }

  preencherRepresentante() {
    if (this.solicitante.representanteLegal) {
      this.requerente.representanteLegal.nome = this.solicitante.nome;
      this.requerente.representanteLegal.tipoRepresentante = this.getEnumTipoRepresentante(this.solicitante.tipoSolicitante);
      this.requerente.representanteLegal.tipoSolicitante = this.solicitante.tipoSolicitante;
      this.requerente.representanteLegal.sexo = this.solicitante.sexo;
      this.requerente.representanteLegal.cpf = this.solicitante.cpf;
      this.requerente.representanteLegal.rg = this.solicitante.rg;
      this.requerente.representanteLegal.orgaoEmissor = this.solicitante.rgOrgaoEmissor;
      this.requerente.representanteLegal.uf = this.solicitante.rgUf;
      this.requerente.representanteLegal.estadoCivil = this.solicitante.estadoCivil;
      this.requerente.representanteLegal.telefone = this.solicitante.telefone;
      this.requerente.representanteLegal.email = this.solicitante.email;
      this.requerente.representanteLegal.endereco = new Endereco();
      this.requerente.representanteLegal.endereco.logradouro = this.solicitante.endereco.logradouro;
      this.requerente.representanteLegal.endereco.numero = this.solicitante.endereco.numero;
      this.requerente.representanteLegal.endereco.complemento = this.solicitante.endereco.complemento;
      this.requerente.representanteLegal.endereco.bairro = this.solicitante.endereco.bairro;
      this.requerente.representanteLegal.endereco.cep = this.solicitante.endereco.cep;
      this.requerente.representanteLegal.endereco.cidade = this.solicitante.endereco.cidade;
      this.requerente.representanteLegal.endereco.uf = this.solicitante.endereco.uf;
    }
  }

  getDescricaoTipoSolicitantePorEnum(idTipoRepresentante: string) {
    if (this.ehMilitar()) {
      return TipoRequerenteEnumFunctions.getDescricaoPorIdMilitar(idTipoRepresentante);
    } else {
      return TipoRequerenteEnumFunctions.getDescricaoPorId(idTipoRepresentante);
    }
  }

  getDescricaoTipoRepresentante(idTipoRepresentante: string) {
    return TipoRepresentanteFunctions.getDescricaoPorId(idTipoRepresentante);
  }

  getEnumTipoRepresentante(idTipoRepresentante: string) {
    return TipoRepresentanteFunctions.getEnumPorId(idTipoRepresentante);
  }

  carregarDocumentos() {
    this.resetDataTable();
    let cpfRepetido = this.requerentes.filter((r) => r.cpf === this.requerente.cpf).length > 0;
    if (cpfRepetido) {
      this.messageService.messageWarning(
        "Não é possível adicionar o mesmo CPF de um Requerente já informado (CPF: " + this.requerente.cpf + ")"
      );
      this.requerente.cpf = "";
      throw new Error("Não é possível adicionar o mesmo CPF de um Requerente já informado.");
    }
    // this.requerente.documentosRegra = null;
    this.uploadDocumentoRequerenteComponent.update([]);
    this.requerente.solicitanteTipo = this.requerente.tipoSolicitante.toLowerCase();
    this.getDocumentosRegra();

    if (this.solicitante.representanteLegal && this.requerente.tipoSolicitante != undefined) {
      this.requerente.necessitaRepresentante = TipoRequerenteEnumFunctions.verificarMenorEnumOuInvalido(this.requerente.tipoSolicitante);
      //if (this.requerente.necessitaRepresentante) this.preencherRepresentante();
    }
  }

  private verificarMenoridade(dataNascimento: string): boolean {
    var ageDifMs = Date.now() - Date.parse(dataNascimento);
    var ageDate = new Date(ageDifMs);
    let result = Math.abs(ageDate.getUTCFullYear() - 1970);

    return result < 21 ? true : false;
  }

  salvar() {
    this.requerentes.forEach((requerente) => {
      this.requerenteService.salvar(requerente).subscribe(
        (requerenteSalvo: Requerente) => {
          requerente.id = requerenteSalvo.id;
        },
        (error) => {
          this.messageService.messageErro(error.error.message);
          throw error;
        }
      );
    });
  }

  pesquisarEndereco(cep: string) {
    if (cep.length === 9) {
      this.enderecoService.pesquisarEnderecoPorCep(cep).subscribe(
        (endereco: any) => {
          if (endereco != null) {
            this.requerente.endereco.logradouro = endereco.logradouro;
            this.requerente.endereco.bairro = endereco.bairro;
            this.requerente.endereco.cidade = endereco.localidade;
            this.requerente.endereco.uf = endereco.uf;
          }
          if (endereco.erro) {
            this.messageService.messageWarning("Atenção! CEP não encontrado");
          }
        },
        (error) => {
          this.messageService.messageErro("Não foi possivel carregar o endereço");
          throw error;
        }
      );
    }
  }

  preencherRequerente() {
    this.requerente.nome = "TESTE SOLICITANTE";
    this.requerente.sexo = "F";
    this.requerente.dataNascimento = "08/12/1985";
    this.requerente.cpf = "05060255456";
    this.requerente.rg = "2545858594578";
    this.requerente.rgOrgaoEmissor = "SSP";
    this.requerente.rgUf = "CE";
    this.requerente.estadoCivil = "Viúvo";
    this.requerente.telefone = "85789654252";
    this.requerente.telefone2 = "85789654253";
    this.requerente.email = "testesolicitante@email.com";
    this.requerente.endereco.cep = "60000000";
    this.requerente.endereco.logradouro = "RUA A";
    this.requerente.endereco.numero = "20";
    this.requerente.endereco.complemento = "CASA";
    this.requerente.endereco.cidade = "FORTALEZA";
    this.requerente.endereco.bairro = "CURIÓ";
    this.requerente.endereco.uf = "CE";
  }

  restaurarValoresPadrao() {
    delete this.requerente.dataCasamento;
  }

  verificarMesmoCpfInstituidor(cpf: string) {
    cpf = cpf.replace(/[.*+\-?^${}()|[\]\\]/g, "").trim();

    if (cpf.length === 11 && cpf === this.instituidor.cpf) {
      this.requerente.cpf = "";
      this.messageService.messageWarning("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
      throw new Error("Não é possível adicionar o mesmo CPF do Instituidor como Requerente nesta solicitação");
    }
  }

  ehMilitar() {
    return this.assunto.id !== null && this.assunto.id == 5;
  }

  pensaMilitarFIltroTipoSolicitante() {
    if (this.ehMilitar()) {
      this.tiposRequerente = TipoRequerenteMilitar;
    } else {
      this.tiposRequerente = TipoRequerenteEnumAll;
    }
  }

  get mask() {
    if (this.requerente.telefone && this.requerente.telefone.length > 10) return "(00) 0 0000-0000";
    return "(00) 0000-00000";
  }

  get cellphoneValidation() {
    return !this.requerente.telefone || this.requerente.telefone.length < 10;
  }
}
