import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CategoriaAssuntoService } from "../../api/categoria-assunto.service";
import { environment } from "../../../../environments/environment";
import { AssuntoCategoria } from "../../models/assunto-categoria.model";
import { TelaEnumAll, Tela } from "../../models/tela.enum";
import { TipoExibicaooEnumAll, TipoExibicao } from "../../models/tipo-exibicao.enum";
import { DialogAvisoComponent } from "../../processo/dialog-aviso/dialog-aviso.component";
import { AvisoService } from "../../api/aviso.service";
import { MatDialog } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { ServicosDto } from "../../models/servicos-dto.model";
import { deprecate } from "util";
import { AssuntoEAssuntoCategoriaDto } from "../../models/assunto-e-assunto-categoria-dto";
import { SharedService } from "src/app/global/api/shared.service";
import { CardProps } from "src/app/ui/components/templates/card-list-template/card-list-template.component";

@Component({
  selector: "app-listar-categoria-assunto",
  templateUrl: "./listar-categoria-assunto.component.html",
  styleUrls: ["./listar-categoria-assunto.component.css"]
})
export class ListarCategoriaAssuntoComponent implements OnInit {

  public displayedColumns: string[];
  // public categoriaAssuntos: AssuntoCategoria[];

  public listaServico: ServicosDto;
  public assuntoEAssuntoCategoriaPaiDto;
  public mostrarServicos = false;
  public data: CardProps[] = [];
  public resourceCategoriaAssunto = environment.CONTEXTO + "/listar-categoria-assunto";

  constructor(public categoriaAssuntoService: CategoriaAssuntoService,
    public messageService: MessageService,
    public toastr: ToastrService,
    public router: Router,
    public avisoService: AvisoService,
    public dialog: MatDialog,
    private sharedService: SharedService) {

  }

  ngOnInit(): void {
    this.listar();
    this.checkAvisos();
  }

  public listar() {
    this.categoriaAssuntoService.listarServicos().subscribe (
      (listaServico: ServicosDto) => {
        this.listaServico = listaServico;

        this.data = this.listaServico.listaAssuntoAssuntoCategoria.map((item)=> ({
          label: item.assuntoCategoriaDtoPai.descricao,
          icon: item.assuntoCategoriaDtoPai.icone,
          click: () => this.listarFilhosCategoriaAssunto(item)
        }))

      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  listarFilhosCategoriaAssunto(assuntoEAssuntoCategoriaDto: AssuntoEAssuntoCategoriaDto) {
    this.assuntoEAssuntoCategoriaPaiDto = assuntoEAssuntoCategoriaDto;
    this.mostrarServicos = true;
  }

  mostrarServicosPai(teste: boolean) {
    this.assuntoEAssuntoCategoriaPaiDto = undefined;
    this.mostrarServicos = false;
  }

  //deprecated FIXME Remove
  // carregarFilhosCategoriaAssunto(id: number, descricao: string) {
  //   this.router.navigate([`${this.resourceCategoriaAssunto}/${id}/${descricao.replace("/", "\\")}`]);
  // }

  private checkAvisos() {
    const tela = TelaEnumAll.filter( tela => tela.descricao == Tela.SERVICOS).map(tela => tela.id)[0];
    let tipoExibicao: number;

    if (JSON.parse(this.sharedService.getDocumentoAstra())) {
      tipoExibicao = TipoExibicaooEnumAll.filter(te => te.descricao === TipoExibicao.PUBLICO_INTERNO).map(te => te.id)[0];;
    } else {
      tipoExibicao = TipoExibicaooEnumAll.filter(te => te.descricao === TipoExibicao.PUBLICO_EXTERNO).map(te => te.id)[0];;
    }

    this.avisoService.listarAvisoAtualPorTelaTipoExibicao(tela,tipoExibicao).subscribe(
      (res) => {
        if(res.length > 0){
          const dialogRef = this.dialog.open(DialogAvisoComponent, {
            maxWidth: "600px",
            data: res
          });
        }
      },
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }
}
