import { Component, OnInit, ViewChild } from "@angular/core";
import { Metadado } from "../../../../global/models/metadado.model";
import { MetadadoService } from "../../../api/metadado.service";
import ListarGenericsComponent from "../../../../global/generics/listar-generics-component";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MatDialog, MatTableDataSource, MatPaginator } from "@angular/material";
import { MessageService } from "src/app/global/message/message.service";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-listar-metadado",
  templateUrl: "./listar-metadado.component.html",
  styleUrls: ["./listar-metadado.component.css"]
})
export class ListarMetadadoComponent implements OnInit {

  public contexto = environment.CONTEXTO;
  public dataSource: MatTableDataSource<Metadado>;
  public displayedColumns: string[] = ["titulo", "nome", "requerido", "mascara", "tipo", "ativo", "removerOpcao"];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public metadadoService: MetadadoService,
    public toastr: ToastrService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {}

  ngOnInit(){
    this.metadadoService.sistema().subscribe(
      res => {
        this.dataSource = new MatTableDataSource<Metadado>(res);
        this.dataSource.paginator = this.paginator;
      }, 
      error => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  inativar(metadado: Metadado) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        metadado.ativo = !metadado.ativo;
        this.metadadoService.put(metadado).subscribe(
          (res) => {
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
          }, 
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

  public edit(domain: Metadado): void {
    this.router.navigate([`${this.contexto}/metadado/editar/${domain.id}`]);
  }
}
