import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import ListarGenericsComponent from "src/app/global/generics/listar-generics-component";
import { Aviso } from "src/app/core/models/aviso.model";
import { AvisoService } from "src/app/core/api/aviso.service";
import { TipoExibicaooEnumAll } from "src/app/core/models/tipo-exibicao.enum";
import { TelaEnumAll } from "src/app/core/models/tela.enum";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { MessageService } from "src/app/global/message/message.service";
import { Utils } from "src/app/global/util/utils-component";
import { DialogConfirmarComponent } from "src/app/core/processo/dialog-confirmar/dialog-confirmar.component";
import { SharedService } from "src/app/global/api/shared.service";

@Component({
  selector: "app-listar-aviso",
  templateUrl: "./listar-aviso.component.html",
  styleUrls: ["./listar-aviso.component.css"]
})
export class ListarAvisoComponent extends ListarGenericsComponent<Aviso> {
  public dataSource: MatTableDataSource<Aviso>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public tipoExibicaoList: any[] = TipoExibicaooEnumAll;
  public telaList: any[] = TelaEnumAll;

  constructor( public apiService:  AvisoService,
    public toastr: ToastrService,
    public sharedService: SharedService,
    public router: Router,
    public dialog: MatDialog,
    public messageService: MessageService) {
    super(apiService, messageService, sharedService, toastr, router, "aviso",
      ["resumo", "tela","tipoExibicao","dataInicio","dataFim","explodir","ativo", "removerOpcao"]);
      Utils.ordernarDescricaoOrdemAlfabetica(this.telaList);
  }

  public pesquisaTipoExibicao(tipoExibicao: any) {
    return this.tipoExibicaoList.filter(tipoExibi => tipoExibi.id == tipoExibicao)[0].descricao;
  }

  public pesquisaTela(tela: any) {
    return this.telaList.filter(telaRes => telaRes.id == tela)[0].descricao;
  }

  inativar(aviso: Aviso) {
    const message = "Realmente deseja alterar a visibilidade?";
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: message
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true) {
        aviso.ativo = !aviso.ativo;
        this.apiService.put(aviso).subscribe(
          (res) =>{
            this.messageService.messageSuccess("Visibilidade alterada com sucesso.")
            return true;
          },
          error => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
      } else {
        return false;
      }
    });

    return false;
  }

}
