import { Component, OnInit, Input, OnChanges, ViewChild, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { Assinador } from "../../models/assinador.model";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { DialogConfirmarComponent } from "../dialog-confirmar/dialog-confirmar.component";

import { Processo } from "../../models/processo.model";

@Component({
  selector: "app-listar-assinadores-indicados",
  templateUrl: "./listar-assinadores-indicados.component.html",
  styleUrls: ["./listar-assinadores-indicados.component.css"],
})
export class ListarAssinadoresIndicadosComponent implements OnInit, OnChanges {
  public displayedAssinadoresColumns: string[] = ["assinador", "datahoraIndicacao", "assinado", "datahoraAssinatura", "delete"];
  @Input() listaAssinador: Assinador[] = [];
  @Output() listaAssinadorChange = new EventEmitter<Assinador[]>();
  public dataSourceListaAssinadores: MatTableDataSource<Assinador>;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.dataSourceListaAssinadores = new MatTableDataSource<any>(this.listaAssinador);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataSourceListaAssinadores) {
      this.dataSourceListaAssinadores.data = this.listaAssinador;
    }
  }

  deleteAssinador(assinador) {
    this.listaAssinador = this.listaAssinador.filter((obj) => obj.documento != assinador.documento);
    this.dataSourceListaAssinadores.data = this.listaAssinador;
    this.listaAssinadorChange.emit(this.listaAssinador);
  }

  deleteConfirm(assinador) {
    const dialogRef = this.dialog.open(DialogConfirmarComponent, {
      maxWidth: "400px",
      data: "Realmente deseja excluir o assinador da lista?",
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === true) {
        this.deleteAssinador(assinador);
      }
    });
  }
}
