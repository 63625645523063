import { SelectionModel } from "@angular/cdk/collections";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatPaginator, MatTableDataSource } from "@angular/material";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { LoadingScreenService } from "src/app/global/api/loading-screen";
import { SharedService } from "src/app/global/api/shared.service";
import { MessageService } from "src/app/global/message/message.service";
import { Metadado } from "src/app/global/models/metadado.model";
import { environment } from "src/environments/environment";
import LacunaWebPKI from "web-pki";
import { AssuntoService } from "../../api/assunto.service";
import { CategoriaAssuntoService } from "../../api/categoria-assunto.service";
import { ContribuinteService } from "../../api/contribuinte.service";
import { DocumentoService } from "../../api/documento.service";
import { FaseService } from "../../api/fase.service";
import { LotacaoSecundariaService } from "../../api/lotacao-secundaria.service";
import { MovimentacaoService } from "../../api/movimentacao.service";
import { OrgaoService } from "../../api/orgao.service";
import { ProcessoService } from "../../api/processo.service";
import { SisSegService } from "../../api/sis-seg.service";
import { SituacaoService } from "../../api/situacao.service";
import { TipoDocumentoService } from "../../api/tipo-documento.service";
import { Assinador } from "../../models/assinador.model";
import { Assunto } from "../../models/assunto.model";
import { Contribuinte } from "../../models/contribuinte.model";
import { DocumentoDTO } from "../../models/documento-dto.model";
import { Documento } from "../../models/documento.model";
import { Fase } from "../../models/fase.model";
import { GerarPdfUploadAlfrescoRequest } from "../../models/gerar-pdf-upload-alfresco-request.model";
import { MetadadoValor } from "../../models/metadado-valor.model";
import { Modelo } from "../../models/modelo.model";
import { Movimentacao } from "../../models/movimentacao.model";
import { NivelSigiloEnumAll } from "../../models/nivel-sigilo.enum";
import { Instituidor } from "../../models/pensao/instituidor.model";
import { ProcessoPequisa } from "../../models/processo-pesquisa.model";
import { ProcessoTabelaDto } from "../../models/processo-tabela-dto.model";
import { Processo } from "../../models/processo.model";
import { Responsavel } from "../../models/responsavel.model";
import { Situacao } from "../../models/situacao.model";
import { TipoArquivamentoEnumAll } from "../../models/tipo-arquivamento-enum.model";
import { TipoDocumentoMetadado } from "../../models/tipo-documento-metadado.model";
import { TipoDocumento } from "../../models/tipo-documento.model";
import { DialogSelecionarModeloComponent } from "../../processo/dialog-selecionar-modelo/dialog-selecionar-modelo.component";
import { DialogTipoDocumentoComponent } from "../../processo/dialog-tipo-documento/dialog-tipo-documento.component";

@Component({
  selector: "app-upload-documento-envolvidos",
  templateUrl: "./upload-documento-envolvidos.component.html",
  styleUrls: ["./upload-documento-envolvidos.component.css"],
})
export class UploadDocumentoEnvolvidosComponent implements OnInit, OnChanges {
  @Input() public processo: Processo;
  @Input() public assunto: Assunto;
  @Input() tipoEnvolvido: string;
  @Input() envolvido: any;
  @Input() instituidor: Instituidor;
  @Input() indiceRequerente: number;

  @Output() atualizarDataTableDocumentosEventEmitter = new EventEmitter<any>();
  @ViewChild("ckeditor") ckeditor: any;
  @ViewChild("pdfDocumento") pdfDocumento;
  @ViewChild("file") file;
  @ViewChild("selectResponsavel") selectResponsavel;
  @ViewChild("invisibleButton") invisibleButton: ElementRef;

  public PAGE_SIZE = SharedService.PAGE_SIZE;
  public fases: Fase[] = [];
  public situacoes: Situacao[] = [];
  public processoPesquisa: ProcessoPequisa;
  public dataInicioInput: string;
  public dataFinalInput: string;
  public dataInicioArquivamentoInput: string;
  public dataFimArquivamentoInput: string;
  public dataInicial: Date;
  public dataFinal: Date;
  public dataInicialArquivamento: Date;
  public dataFinalArquivamento: Date;
  public processos: Processo[] = [];
  public categoriaAssuntoList: any[] = [];
  public titleProcesso: string;
  public titleDocumento: string;
  public titleMovimentacao: string;
  public titleItemNaoSimilaridade: string;
  public assuntos: Assunto[] = [];
  public documentos: Documento[] = [];
  public documentoNovo: Documento = new Documento();
  public documentoSelecionado: Documento;
  public tiposDocumentos: TipoDocumento[] = [];
  public movimentacao: Movimentacao;
  public responsavel: Contribuinte;
  public forcarOrdemAssinatura = false;
  public natureza: number;
  public exibicaoEditor = false;
  public isDocumentoAssinado = false;
  public listaMetadado: Metadado[] = [];
  public autor: string;
  public exibirPdfDocumento = false;
  public exibirVIsualizadorDocumento = true;
  public urlDocumentoPdf: string;
  public listaAssinador: Assinador[] = [];
  public safeUrl: SafeResourceUrl;
  public documentoAssinado: DocumentoDTO;
  public listMetadadoId: number[] = [];
  public listMetadado: Metadado[] = [];
  public orgaoLocalList: any[] = [];
  public responsaveisList: Responsavel[] = [];
  public documentoAstra: string;
  public validaInputResponsavelAtual = false;
  public updateEventosItemNaoSimilaridade = false;
  searchRespCtrl = new FormControl();
  searchRespAtualCtrl = new FormControl();
  isLoading = false;
  responsaveis: any[];
  responsaveisAtual: any[];
  responsavelAtualObjeto: Responsavel;
  nenhumResponsavelAtualObjeto: Responsavel;
  searchRespArquivamentoCtrl = new FormControl();
  isLoadingArquivamento = false;
  responsaveisArquivamento: any[];
  private nomeArquivo: string;
  public temPermissao: boolean = false;

  public displayedProcessosColumns: string[] = ["select", "id", "numero", "informacoes", "autor", "lotacao", "dataCriacao", "observado"];
  public displayedDocumentosColumns: string[] = [
    "select",
    "id",
    "arquivo",
    "numero",
    "tipoDocumento",
    "descricao",
    "dataHora",
    "delete",
    "visibilidade",
    "could",
  ];
  public displayedMovimentacoesColumns: string[] = ["localizacao", "informacoes", "situacao", "permanencia", "datahora"];

  public selection = new SelectionModel<Documento>(true, []);
  public selectionProcesso = new SelectionModel<ProcessoTabelaDto>(true, []);

  public abaAtiva = new FormControl(0);
  public style = "tabela-processo";

  public dataSourceDocumento: MatTableDataSource<Documento>;
  @ViewChild("paginatorDocumento") paginatorDocumento: MatPaginator;

  public dataSourceMovimentacao: MatTableDataSource<Movimentacao>;
  @ViewChild("paginatorMovimentacao") paginatorMovimentacao: MatPaginator;

  public resultsLengthProcesso = 0;
  public hiddenResultsProcesso = true;
  public isRateLimitReachedProcesso = false;
  public highlightedMovimentacaoRows: any[] = [];
  public highlighteProcessoRows: any[] = [];
  protected _onDestroy = new Subject<void>();
  public ckeConfig: any;
  public mycontent: string;
  public abaConsultaProcesso = true;
  public nivelSigiloEnumAll: any[] = NivelSigiloEnumAll;
  public pki = new LacunaWebPKI(environment.LAKUNA_CERT_KEY);
  public form: FormGroup;
  public nivelSigiloProcesso: any;
  public tipoArquivamento: any;
  public tipoArquivamentoList: any[] = TipoArquivamentoEnumAll;
  public documentoSubstituir: Documento;
  public ancora: string;
  public notUsuarioExterno = false;

  constructor(
    private faseService: FaseService,
    private situacaoService: SituacaoService,
    private assuntoService: AssuntoService,
    private documentoService: DocumentoService,
    private processoService: ProcessoService,
    private categoriaService: CategoriaAssuntoService,
    private formBuilder: FormBuilder,
    private tipoDocumentoService: TipoDocumentoService,
    public movimentacaoService: MovimentacaoService,
    public contribuinteService: ContribuinteService,
    public orgaoService: OrgaoService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private contribuinteSevice: ContribuinteService,
    private sissegService: SisSegService,
    private lotacaoSecundariaService: LotacaoSecundariaService,
    private sharedService: SharedService,
    protected route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private loadingScreenService: LoadingScreenService,
    private _elementRef: ElementRef
  ) {}

  ngOnChanges() {}
  ngOnInit() {
    this.verificarPermissaoMovimentarProcesso(this.processo.id);
    this.ancora = "editorHtml" + this.tipoEnvolvido;
    if (this.tipoEnvolvido === "REQUERENTE") this.ancora += this.indiceRequerente;

    this.nenhumResponsavelAtualObjeto = new Responsavel();
    this.nenhumResponsavelAtualObjeto.nome = "NENHUM";
    this.nenhumResponsavelAtualObjeto.id = 0;
    this.nenhumResponsavelAtualObjeto.matricula = "0";
    this.notUsuarioExterno = this.sharedService.verificaRolePessoaLogada("frontend.perfil-user-externo");

    // const id = this.route.snapshot.params.id;
    this.sharedService.decodePayloadJWT().sub = JSON.parse(this.sharedService.getDocumentoAstra());
    // tslint:disable-next-line:triple-equals
    // if (id != null || id != undefined) {
    //   this.atualizarInformacoesComProcesso(id, 0);
    //   this.abaConsultaProcesso = false;
    // } else {
    //   this.abaAtiva.setValue(0);
    // }

    this.faseService.list().subscribe(
      (fases) => {
        this.fases = fases;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.situacaoService.list().subscribe(
      (situacoes) => {
        this.situacoes = situacoes;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.tipoDocumentoService.list().subscribe(
      (tiposDocumentos) => {
        this.tiposDocumentos = tiposDocumentos;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.assuntoService.list().subscribe(
      (assuntos) => {
        this.assuntos = assuntos;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );

    this.categoriaService.listarAtivas().subscribe(
      (categoriaAssunto) => {
        this.categoriaAssuntoList = categoriaAssunto;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
    // this.orgaoService.getOrgaoLocal().subscribe(
    //   orgaoLocal => {
    //     this.orgaoLocalList = orgaoLocal;
    //   },
    //   error => {
    //     this.messageService.messageErro(error.error.message);
    //     throw error;
    //   }
    // );
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;

    this.exibirPdfDocumento = false;

    this.confiCkEditor();
  }

  public adicionarDocumento() {
    this.novoDocumento();
    this.exibicaoEditor = false;
  }

  public adicionarDocumentoEditor() {
    this.novoDocumento();
    this.exibicaoEditor = true;
  }

  public novoDocumento() {
    this.limparDocumentoSelecionado();
    this.confiCkEditor();
    this.isDocumentoAssinado = false;
    this.documentoNovo = new Documento();
    const tipoDocumento = new TipoDocumento();
    this.documentoNovo.tipoDocumento = tipoDocumento;
    this.listaAssinador = [];
    this.processo.modeloAssunto = "";
  }

  private confiCkEditor() {
    this.ckeConfig = {
      allowedContent: false,
      forcePasteAsPlainText: false,
      language: "pt",
      font_names: "Arial;Times New Roman;Verdana",
      height: "500px",
      toolbarGroups: [
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
        { name: "forms", groups: ["forms"] },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
        { name: "links", groups: ["links"] },
        { name: "insert", groups: ["insert"] },
        { name: "styles", groups: ["styles"] },
        { name: "colors", groups: ["colors"] },
        { name: "tools", groups: ["tools"] },
        { name: "others", groups: ["others"] },
        { name: "about", groups: ["about"] },
      ],
      // tslint:disable-next-line:max-line-length
      removeButtons:
        "Source,Save,NewPage,Preview,Print,Templates,Find,PasteFromWord,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,RemoveFormat,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About",
    };
  }

  private limparDocumentoSelecionado() {
    this.documentoSelecionado = null;
  }

  openDialogTipoDocumento(natureza: any): void {
    const dialogTipoDocumento = this.dialog.open(DialogTipoDocumentoComponent, {
      width: "500px",
      height: "600px",
      data: { anexo: natureza == 2 ? true : false, assunto: this.processo.assunto, relacionadoEnvolvido: true },
    });

    dialogTipoDocumento.afterClosed().subscribe((tipoDocumentoParam) => {
      // tslint:disable-next-line:triple-equals
      if (tipoDocumentoParam == undefined) {
        this.documentoNovo.tipoDocumento = new TipoDocumento();
      } else {
        this.documentoNovo.tipoDocumento = tipoDocumentoParam;
        this.documentoNovo.modeloDocumento = tipoDocumentoParam.modeloDocumento;
        this.documentoNovo.downloadUsuarioExterno = tipoDocumentoParam.visibilidadeExterna;
      }
    });
  }

  openDialogModelo(): void {
    const dialogModelo = this.dialog.open(DialogSelecionarModeloComponent, {
      width: "500px",
      data: this.documentoNovo.tipoDocumento,
    });

    dialogModelo.afterClosed().subscribe((modelo: Modelo) => {
      if (modelo) {
        this.documentoNovo.modeloDocumento = modelo.conteudo;
      }
    });
  }

  incluirDocumentoNovoAnexo(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (!this.validarExtensao(fileList[0].name)) {
        const extensoes = !this.assunto.extensao ? ".pdf" : this.assunto.extensao;
        this.messageService.messageErro(`Extensão de arquivo não permitida para este assunto. Extensões permitidas: ${extensoes}`);
        return;
      }

      let fileName = fileList[0].name;
      let arquivoPdf = fileName.substring(fileName.lastIndexOf(".")).toLowerCase().indexOf("pdf") > -1;
      if (this.validar(arquivoPdf)) {
        const cert = JSON.parse(this.sharedService.getKeyStoreCertificate());
        const formData: FormData = new FormData();
        const file: File = fileList[0];
        formData.append("file", file);

        formData.append("fileName", file.name);
        formData.append("descricao", this.documentoNovo.descricao);
        formData.append("descricaoAssunto", this.processo.assunto.descricao);
        formData.append("tipoDocumentoId", this.documentoNovo.tipoDocumento.id.toString());
        formData.append("docAutor", this.sharedService.decodePayloadJWT().sub);
        formData.append("processoId", this.processo.id.toString());
        formData.append(
          "docAssinantes",
          this.listaAssinador
            .map(function (assinador) {
              return assinador.documento.toString();
            })
            .join(",")
        );
        formData.append("downloadUsuarioExterno", this.documentoNovo.downloadUsuarioExterno.toString());

        const listMetadadoValor: MetadadoValor[] = [];
        var metadadoValido = true;
        this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
          if (!this.validarMetadado(tipoDocumentoMetadado)) {
            metadadoValido = false;
          }
          const metadadoValor = new MetadadoValor();
          metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
          metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
          listMetadadoValor.push(metadadoValor);
        });
        // Salvando informações digitados dos metadados
        this.documentoNovo.listMetadadoValor = listMetadadoValor;

        formData.append("listMetadadoValorJson", JSON.stringify(listMetadadoValor));

        if (metadadoValido)
          this.documentoService.post(formData).subscribe(
            (documento) => {
              this.associarDocumentoEnvolvido(documento);
            },
            (error) => {
              this.messageService.messageErro(error.error.message);
              throw error;
            }
          );
        //   if (arquivoPdf) {
        //     this.documentoService.post(formData).subscribe(documento => {
        //       this.processoService.get(this.processo.id).subscribe((resp) => {
        //         this.natureza = null;
        //         this.listarDocumentosProcesso(resp);
        //         this.messageService.messageSuccess("Documento inserido com sucesso!");
        //       },
        //         error => {
        //           this.messageService.messageErro(error.error.message);
        //           throw error;
        //         });
        //     },
        //       error => {
        //         this.messageService.messageErro(error.error.message);
        //         throw error;
        //       });
        //   } else {
        //     this.documentoService.salvarDocumentoNaoPDF(formData).subscribe(documento => {
        //       this.processoService.get(this.processo.id).subscribe((resp) => {
        //         this.natureza = null;
        //         this.listarDocumentosProcesso(resp);
        //         this.messageService.messageSuccess("Documento inserido com sucesso!");
        //       },
        //         error => {
        //           this.messageService.messageErro(error.error.message);
        //           throw error;
        //         });
        //     },
        //       error => {
        //         this.messageService.messageErro(error.error.message);
        //         throw error;
        //       });
        //   }
      }
    }
  }

  validarMetadado(tipoDocumentoMetadado: TipoDocumentoMetadado): boolean {
    if (
      tipoDocumentoMetadado.metadado.valor !== undefined &&
      tipoDocumentoMetadado.metadado.valor !== null &&
      tipoDocumentoMetadado.metadado.valor.length !== 0
    ) {
      if (
        tipoDocumentoMetadado.metadado.mascara !== undefined &&
        tipoDocumentoMetadado.metadado.mascara !== null &&
        tipoDocumentoMetadado.metadado.validarQuantidadeCaracteres &&
        tipoDocumentoMetadado.metadado.valor.length !== tipoDocumentoMetadado.metadado.mascara.length
      ) {
        this.messageService.messageErro(
          "Preencha o campo " + tipoDocumentoMetadado.metadado.nome + " conforme a máscara " + tipoDocumentoMetadado.metadado.mascara + "."
        );
        return false;
      }
    } else if (tipoDocumentoMetadado.requerido) {
      this.messageService.messageErro("Preencha o campo " + tipoDocumentoMetadado.metadado.nome);
      return false;
    }

    return true;
  }

  validarExtensao(arquivo) {
    let extensao = arquivo.substring(arquivo.lastIndexOf(".")).toLowerCase();
    let extensoes = !this.assunto.extensao ? [".pdf"] : this.assunto.extensao.split(",");

    return extensoes.filter((e) => e.trim().indexOf(extensao) > -1).length > 0;
  }

  public incluirDocumentoNovoEditor() {
    if (this.validar(true)) {
      const gerarPdfUploadAlfrescoRequest = new GerarPdfUploadAlfrescoRequest();
      gerarPdfUploadAlfrescoRequest.autor = this.sharedService.decodePayloadJWT().sub;
      gerarPdfUploadAlfrescoRequest.descricao = this.documentoNovo.descricao;
      gerarPdfUploadAlfrescoRequest.descricaoAssunto = this.processo.assunto.descricao;
      gerarPdfUploadAlfrescoRequest.html = this.documentoNovo.modeloDocumento;
      gerarPdfUploadAlfrescoRequest.nomeDocumento = "documento_anexo_editor.pdf";
      gerarPdfUploadAlfrescoRequest.tipoDocumentoId = this.documentoNovo.tipoDocumento.id;
      gerarPdfUploadAlfrescoRequest.processoId = this.processo.id;
      gerarPdfUploadAlfrescoRequest.docAssinantes = this.listaAssinador.map(function (assinador) {
        return assinador.documento.toString();
      });
      gerarPdfUploadAlfrescoRequest.downloadUsuarioExterno = this.documentoNovo.downloadUsuarioExterno;

      const listMetadadoValor: MetadadoValor[] = [];
      var metadadoValido = true;
      this.documentoNovo.tipoDocumento.listMetadado.forEach((tipoDocumentoMetadado) => {
        const metadadoValor = new MetadadoValor();
        if (!this.validarMetadado(tipoDocumentoMetadado)) {
          metadadoValido = false;
        }

        metadadoValor.metadadoId = tipoDocumentoMetadado.metadado.id;
        metadadoValor.valor = tipoDocumentoMetadado.metadado.valor;
        listMetadadoValor.push(metadadoValor);
      });
      // Salvando informações digitados dos metadados
      this.documentoNovo.listMetadadoValor = listMetadadoValor;

      gerarPdfUploadAlfrescoRequest.listMetadadoValor = listMetadadoValor;

      if (metadadoValido)
        this.documentoService.gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest).subscribe(
          (documento) => {
            this.associarDocumentoEnvolvido(documento);

            this.processoService.get(this.processo.id).subscribe(
              (processoAtualizado: Processo) => {
                this.natureza = null;
                this.exibicaoEditor = false;
                this.atualizarDataTableDocumentosEventEmitter.emit({ atualizar: true, envolvido: this.tipoEnvolvido });
              },
              (error) => {
                this.messageService.messageErro(error.error.message);
                throw error;
              }
            );
          },
          (error) => {
            this.messageService.messageErro(error.error.message);
            throw error;
          }
        );
    }
  }

  private validar(arquivoPdf): boolean {
    let validacao = true;

    if (this.documentoNovo.tipoDocumento.nomeDocumento == null) {
      this.messageService.messageWarning("Selecione o tipo de documento.");
      validacao = false;
    }
    if (!this.documentoNovo.descricao) {
      this.messageService.messageWarning("Preencha o campo descrição.");
      validacao = false;
    }

    if (!this.documentoNovo.modeloDocumento && this.natureza === 1) {
      this.messageService.messageWarning("Preencha o campo conteúdo.");
      validacao = false;
    }

    // if (arquivoPdf) {
    //   if (this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeMinimaAssinatura + " assinadores.");
    //     validacao = false;
    //   }

    //   if (this.documentoNovo.tipoDocumento.qtdeExataAssinatura > 0
    //       && this.documentoNovo.tipoDocumento.qtdeExataAssinatura > this.listaAssinador.length) {
    //     this.messageService.messageWarning("Necessário indicar no mínimo "
    //       + this.documentoNovo.tipoDocumento.qtdeExataAssinatura + " assinadores.");
    //     validacao = false;
    //   }
    // }

    return validacao;
  }

  getNivelSigilo(nivel) {
    if (nivel != null) {
      return NivelSigiloEnumAll[nivel].descricao;
    } else {
      return "Nivel de Sigilo nulo";
    }
  }

  verificarPermissaoMovimentarProcesso(id: number) {
    this.processoService.verificarProcessoPertenceLotacaoUsuario(id, this.sharedService.decodePayloadJWT().sub).subscribe(
      (response) => {
        if (response == null) {
          this.messageService.messageInfo("Não foi possível consultar motor de regras");
          return;
        }
        this.temPermissao = response;
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private associarDocumentoEnvolvido(documento: Documento) {
    switch (this.tipoEnvolvido) {
      case "SOLICITANTE":
        this.setDocumentoEnvolvido("SOLICITANTE", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
      case "INSTITUIDOR":
        this.setDocumentoEnvolvido("INSTITUIDOR", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
      case "REQUERENTE":
        this.setDocumentoEnvolvido("REQUERENTE", this.envolvido.id, this.envolvido.cpf, documento.id);
        break;
    }
  }

  private setDocumentoEnvolvido(envolvido: string, idEnvolvido: number, documentoDoEnvolvido: string, idDocumento: number) {
    this.documentoService.setDocumentoEnvolvido(envolvido, idEnvolvido, documentoDoEnvolvido, idDocumento).subscribe(
      (resp) => {
        if (this.documentoSubstituir === undefined || this.documentoSubstituir === null) {
          this.atualizarDataTableDocumentosEventEmitter.emit({ atualizar: true, envolvido: this.tipoEnvolvido });
          this.messageService.messageSuccess("Documento inserido com sucesso!");
        } else {
          this.deleteDocumentoSubstituido(this.documentoSubstituir);
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  private deleteDocumentoSubstituido(documento: any) {
    this.documentoService.deleteDocumentoById(documento.id).subscribe(
      (resp) => {
        this.documentoSubstituir = null;
        if (this.documentoSubstituir === undefined || this.documentoSubstituir === null) {
          this.messageService.messageSuccess("Documento substituído com sucesso!");
          this.atualizarDataTableDocumentosEventEmitter.emit({ atualizar: true, envolvido: this.tipoEnvolvido });
        }
      },
      (error) => {
        this.messageService.messageErro(error.error.message);
        throw error;
      }
    );
  }

  public cancelarSubstituicao() {
    this.novoDocumento();
    if (!this.documentoSubstituir.tipoDocumento.somenteAnexo) this.adicionarDocumentoEditor();
    this.atualizarDataTableDocumentosEventEmitter.emit({ atualizar: true, envolvido: this.tipoEnvolvido });
    this.messageService.messageSuccess("Substituição de documento cancelada!");
  }
}
