import { ApiService } from "./api.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GerarPdfUploadAlfrescoRequest } from "../models/gerar-pdf-upload-alfresco-request.model";
import { Documento } from "../models/documento.model";
import { Processo } from "../models/processo.model";
import { AppConfig, APP_CONFIG } from "src/app.config";

@Injectable({ providedIn: "root" })
export class DocumentoService extends ApiService {
  API_URL: string;

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    super(http, "documento", config);
    this.API_URL = config.API_DOCUMENTO_URL;
  }

  salvarDocumentoNaoPDF(documentoSaveRequest: any): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/nao-pdf`, documentoSaveRequest).pipe(map((response) => response));
  }

  atualizarMetadados(documentoSaveRequest: any, id: number): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/atualizar-metadados/${id}`, documentoSaveRequest)
      .pipe(map((response) => response));
  }

  gerarPdf(html: string): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/gerar-pdf`, html).pipe(map((response) => response));
  }

  gerarPdfUploadAlfresco(gerarPdfUploadAlfrescoRequest: GerarPdfUploadAlfrescoRequest): Observable<any> {
    return this.http
      .post(`${this.API_URL}${this.resource}/gerar-pdf-upload-alfresco`, gerarPdfUploadAlfrescoRequest)
      .pipe(map((response) => response));
  }

  atualizarPdfUploadAlfresco(id: number, gerarPdfUploadAlfrescoRequest: GerarPdfUploadAlfrescoRequest): Observable<any> {
    return this.http
      .put(`${this.API_URL}${this.resource}/gerar-pdf-upload-alfresco/${id}`, gerarPdfUploadAlfrescoRequest)
      .pipe(map((response) => response));
  }

  download(idDocumento: number, fileType?: string): Observable<Blob> {
    return this.http
      .get(`${this.API_URL}${this.resource}/download/${idDocumento}`, { responseType: "arraybuffer" })
      .pipe(map((response) => new Blob([response], { type: fileType ? fileType : "application/pdf" })));
  }

  public listarDocumentosByIdProcesso(idProcesso: number): Observable<Documento[]> {
    return this.http.get(`${this.API_URL}${this.resource}/lista/${idProcesso}`).pipe(
      map((response: Documento[]) => {
        return response;
      })
    );
  }

  public listarDocumentosPendenteAssinatura(documento: string): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pendentesAssinatura/${documento}`).pipe(
      map((response) => {
        const responseContent: any = response;
        // tslint:disable-next-line:triple-equals
        if (responseContent.content != undefined) {
          return responseContent.content;
        } else {
          return response;
        }
      })
    );
  }

  public downloadDocumentoProcesso(idProcesso: number): Observable<any> {
    return this.http
      .get(`${this.API_URL}${this.resource}/downloadDocumentoProcesso/${idProcesso}`, { responseType: "arraybuffer" })
      .pipe(map((response) => new Blob([response], { type: "application/pdf" })));
  }

  public getDocumentoByProcessoId(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/processo/${id}`).pipe(map((response) => response));
  }

  getDocumentosPendentesPorProcessos(processoIDs: any): Observable<any> {
    return this.http.get(`${this.API_URL}${this.resource}/pendente?processosids=${processoIDs}`).pipe(map((response) => response));
  }

  getDocumentosPendentesProcessosResponsavelAtual(processoId: any, responsavelAtual: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http
      .get(`${this.API_URL}${this.resource}/pendente-responsavel-atual/${processoId}/${responsavelAtual}`)
      .pipe(map((response) => response));
  }

  start(body: any, carregarLoading: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };
    return this.http.post(`${this.API_URL}${this.resource}/start`, body, httpOptions).pipe(map((response) => response));
  }

  complete(
    fileToSign: string,
    transferFileId: string,
    signature: string,
    descricao: string,
    descricaoAssunto: string,
    tipoDocumentoId: number,
    autor: string,
    fileName: string,
    descricaoAssinatura: string,
    hashDocumento: string,
    processoId: number,
    modeloDocumento: string,
    carregarLoading: string
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };

    return this.http
      .post(
        `${this.API_URL}${this.resource}/complete`,
        {
          fileToSign,
          transferFileId,
          signature,
          descricao,
          descricaoAssunto,
          tipoDocumentoId,
          autor,
          fileName,
          descricaoAssinatura,
          hashDocumento,
          processoId,
          modeloDocumento,
        },
        httpOptions
      )
      .pipe(map((response) => response));
  }

  completeWithNumeracao(
    fileToSign: string,
    transferFileId: string,
    signature: string,
    descricao: string,
    descricaoAssunto: string,
    tipoDocumentoId: number,
    autor: string,
    fileName: string,
    descricaoAssinatura: string,
    hashDocumento: string,
    processoId: number,
    numeroDocumento: number,
    anoNumeroDocumento: number,
    modeloDocumento: string,
    carregarLoading: string
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };

    return this.http
      .post(
        `${this.API_URL}${this.resource}/completeWithNumeracao`,
        {
          fileToSign,
          transferFileId,
          signature,
          descricao,
          descricaoAssunto,
          tipoDocumentoId,
          autor,
          fileName,
          descricaoAssinatura,
          hashDocumento,
          processoId,
          numeroDocumento,
          anoNumeroDocumento,
          modeloDocumento,
        },
        httpOptions
      )
      .pipe(map((response) => response));
  }

  startWithHtml(
    html: string,
    certContent: string,
    processoId: number,
    docAutor: string,
    quantidadePaginasParaCriarNoDocumento: number,
    carregarLoading: string
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };
    return this.http
      .post(
        `${this.API_URL}${this.resource}/startWithHtml`,
        {
          html,
          certContent,
          processoId,
          docAutor,
          quantidadePaginasParaCriarNoDocumento,
        },
        httpOptions
      )
      .pipe(map((response) => response));
  }

  startWithHtmlNumeracao(
    html: string,
    tipoDocumentoId: number,
    certContent: string,
    processoId: number,
    docAutor: string,
    carregarLoading: string
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };
    return this.http
      .post(
        `${this.API_URL}${this.resource}/startWithHtmlNumeracao`,
        {
          html,
          certContent,
          processoId,
          tipoDocumentoId,
          docAutor,
        },
        httpOptions
      )
      .pipe(map((response) => response));
  }

  startWithIdDocumento(id: number, body: any, carregarLoading: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };
    return this.http.post(`${this.API_URL}${this.resource}/startWithId/${id}`, body, httpOptions).pipe(map((response) => response));
  }

  completeWithIdDocumento(
    id: number,
    fileToSign: string,
    transferFileId: string,
    signature: string,
    descricaoAssinatura: string,
    carregarLoading: string
  ): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set("carregarLoading", carregarLoading),
    };
    return this.http
      .post(
        `${this.API_URL}${this.resource}/completeWithId/${id}`,
        {
          fileToSign,
          transferFileId,
          signature,
          descricaoAssinatura,
        },
        httpOptions
      )
      .pipe(map((response) => response));
  }

  public deleteDocumentoById(id: number): Observable<any> {
    return this.http.put(`${this.API_URL}${this.resource}/excluirDocumento/${id}`, id).pipe(map((response) => response));
  }

  gerarConsultaPublica(processo: Processo): Observable<any> {
    throw new Error("Method not implemented.");
  }

  validaXlsNaoSimilaridade(documentoSaveRequest: any): Observable<any> {
    return this.http.post(`${this.API_URL}${this.resource}/xls-nao-similaridade`, documentoSaveRequest).pipe(map((response) => response));
  }

  public atualizarDownloadExternoDocumento(id: number, visibilidade: boolean): Observable<Documento> {
    const headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

    return this.http
      .put(`${this.API_URL}${this.resource}/atualizar-download-externo/${id}`, visibilidade, { headers })
      .pipe(map((response: Documento) => response));
  }

  desentranhaDocumento(documentoId: number, motivo: string, etiqueta: string): Observable<any> {
    const body = { motivo: motivo, etiqueta: etiqueta };
    return this.http.post(`${this.API_URL}${this.resource}/desentranhar-documento/${documentoId}`, body).pipe(map((response) => response));
  }

  pesquisarDocumentosSolicitante(idSolicitante: number) {
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-documentos-solicitante/${idSolicitante}`)
      .pipe(map((response) => response));
  }

  pesquisarDocumentosInstituidor(idInstituidor: number) {
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-documentos-instituidor/${idInstituidor}`)
      .pipe(map((response) => response));
  }

  pesquisarDocumentosRequerente(idRequerente: number) {
    return this.http
      .get(`${this.API_URL}${this.resource}/pesquisar-documentos-requerente/${idRequerente}`)
      .pipe(map((response) => response));
  }

  listarDocumentosGeraisProcessoPensao(idProcesso: number): Observable<Documento[]> {
    return this.http.get(`${this.API_URL}${this.resource}/lista-pensao-geral/${idProcesso}`).pipe(
      map((response: Documento[]) => {
        return response;
      })
    );
  }

  public setDocumentoEnvolvido(tipoEnvolvido: string, idEnvolvido: number, documentoDoEnvolvido: string, idDocumento: number) {
    return this.http
      .post(`${this.API_URL}${this.resource}/set-documento-envolvido/${tipoEnvolvido}/${idEnvolvido}/${idDocumento}`, {
        documento: documentoDoEnvolvido,
      })
      .pipe(map((response: Documento) => response));
  }
}
