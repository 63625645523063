import { Situacao } from "./situacao.model";
import { Domain } from "../../global/models/domain.model";
import { TipoFase } from "./tipo-fase.model";

export class Fase extends Domain {
  situacaoInicial: Situacao;
  situacaoPosVisualizacao: Situacao;
  listSituacao: Situacao[];
  descricao: string;
  ativo: boolean;
  tipoFase: TipoFase;
  panelAberto: boolean;
  acaoMudancaSituacao: number;
  qtdRegistros: number;
}